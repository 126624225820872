import { Button, UncontrolledTooltip } from 'reactstrap'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { X } from 'react-feather'
import React, { useEffect, useState } from 'react'
import CustomDropdown from '~/components/CustomDropdown'
import Link from '~/components/Link'
import { colors } from '~/components/ApexCharts/ApexLineChart/chartOptions'
import { useStore } from '~/dataStore'

interface IProps {
  goToControlGroup: () => void
  variants: { id: string }[]
  onSelect: (variant: string) => void
  selected: string
  addVariant: (type: 'duplicate' | 'blank') => Promise<void>
  removeVariant: (variantId: string) => void
  removeCG: () => void
  controlGroup: number | undefined
}

function calculatePercentage(variantsCount: number, cg = 0) {
  return Math.floor((100 - cg) / variantsCount)
}

export default function VariantTabs({
  variants,
  onSelect,
  selected,
  goToControlGroup,
  controlGroup,
  addVariant,
  removeVariant,
  removeCG
}: IProps): React.ReactElement {
  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      }
    }
  } = useStore()
  const [variantPercentage, setVariantPercentage] = useState(
    calculatePercentage(variants.length, controlGroup)
  )

  function handleClick(variant: string): () => void {
    return () => onSelect(variant)
  }
  function handleRemove(variant: string): () => void {
    return () => removeVariant(variant)
  }

  useEffect(() => {
    setVariantPercentage(calculatePercentage(variants.length, controlGroup))
  }, [controlGroup, variants.length])

  return (
    <div className="d-flex variants w-100">
      {variants.map((variant, index) => (
        <React.Fragment key={variant.id}>
          <div
            id={`variant-${variant.id}`}
            className={cn(
              'variants__tab text-center',
              {
                'variants__tab--active bg-white': selected === variant.id
              },
              { 'variants__tab--hover': variants.length > 1 }
            )}>
            <Button
              color=""
              style={{
                color: selected === variant.id ? colors[index][0] : '#93a8c5'
              }}
              className="variants__btn w-100"
              onClick={handleClick(variant.id)}>
              <strong className="fw-medium variants__id">{variant.id}</strong>{' '}
              {variantPercentage}%
            </Button>
            {variants.length > 1 && (
              <Button
                color=""
                className="p-0 variants__btn variants__remove"
                onClick={handleRemove(variant.id)}>
                <X className="cursor-pointer remove-icon" />
              </Button>
            )}
          </div>
          <UncontrolledTooltip
            placement="bottom"
            target={`variant-${variant.id}`}>
            Variant {variant.id}
          </UncontrolledTooltip>
        </React.Fragment>
      ))}

      {controlGroup && campaignsGoals && (
        <div className="text-center variants__tab variants__tab--cg variants__tab--hover">
          <Button
            id="control-group"
            color=""
            onClick={goToControlGroup}
            className="w-100 variants__btn text-white">
            <strong className="variants__id fw-medium">CG</strong>{' '}
            {controlGroup}%
          </Button>
          <Button
            color=""
            className="p-0 variants__btn variants__remove"
            onClick={removeCG}>
            <X className="cursor-pointer remove-icon text-white" />
          </Button>
          <UncontrolledTooltip placement="bottom" target="control-group">
            Control Group
          </UncontrolledTooltip>
        </div>
      )}

      {(variants.length < 3 || !controlGroup) && (
        <CustomDropdown
          right
          dropdownToggle={
            <div className="px-3 o-50 variants__dropdown truncate">
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="color-blue me-1"
              />
              Add Variant
            </div>
          }
          // disabled={disabled}
          options={[
            {
              hidden: variants.length === 3 && !controlGroup,
              name: 'Duplicate from original',
              action: () => addVariant('duplicate')
            },
            {
              hidden: variants.length === 3 && !controlGroup,
              name: 'New Blank Variant',
              action: () => addVariant('blank')
            },
            {
              hidden: !!controlGroup || !campaignsGoals,
              name: 'Control Group',
              action: goToControlGroup
            },
            {
              name: (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  target="_blank"
                  className="no-decoration text-nowrap"
                  route="https://pulsate.readme.io/v4.1/docs/campaign-goals">
                  <div className="mx-3 my-2 py-2 d-block btn btn--hover btn-outline-body text-center bg-white d-flex align-items-center justify-content-center">
                    <span className="icon icon--med icon--variants me-2" />
                    Learn more
                  </div>
                </Link>
              ),
              isCustom: true
            }
          ]}
        />
      )}
    </div>
  )
}
