import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { getLinksFromEmailNotification } from '~/pages/Campaign/Notification/Notification.connector'
import GoalList from '~/pages/Campaign/Notification/NotificationGoals/UI/GoalList'
import BuilderErrors from '~/pages/CampaignBuilder/BottomActions/BuilderErrors'
import Modal from '~/components/modals/Modal'
import { CustomErrors } from '~/common.interface'
import { isString } from '~/utils/utilities'
import { NotificationType, showNotification } from '~/utils/Notification'
import { useStore, withStore } from '~/dataStore'
import NotificationGoals from '~/pages/Campaign/Notification/NotificationGoals'
import EmailNotification from '~/pages/Campaign/Notification/EmailNotification/Model/EmailNotification'

interface IProps {
  resetError: () => void
  save: () => void
  close: () => void
  goals: NotificationGoals
  errorMessages: string[]
}

function EmailNotificationGoalsModalRender(
  props: IProps & {
    closeModal: () => void
    store: EmailNotification | undefined
  }
): React.ReactElement {
  const {
    resetError,
    save,
    close,
    goals,
    errorMessages,
    closeModal,
    store
  } = props

  const { ui, app } = useStore()

  const [isLoading, setIsLoading] = useState(false)
  const [showErrorsTooltip, setShowErrorsTooltip] = useState(true)
  const [actionLinks, setActionLinks] = useState<string[]>([])
  const [errors, setErrors] = useState<string[]>([])

  async function saveNotification(): Promise<void> {
    if (!actionLinks.length) {
      setErrors(['You need to have at least one link / button'])
      setShowErrorsTooltip(true)
      return
    }

    try {
      setIsLoading(true)
      await save()
      setIsLoading(false)
      closeModal()
      close()
    } catch (error) {
      if ((error as Error).message !== CustomErrors.INVALID) {
        console.error(error)

        if (isString((error as Error).message)) {
          showNotification((error as Error).message, NotificationType.ERROR)
        }
      }
      setShowErrorsTooltip(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    async function fetchLinks(body: string) {
      try {
        const response = await getLinksFromEmailNotification(
          app.currentApp.id,
          body
        )

        setActionLinks(response.links)
        goals.goals.forEach((goal) => {
          if (
            goal.reference &&
            !response.links.some((link) => link === goal.reference)
          ) {
            goals.removeGoalByReference(goal.reference)
          }
        })
      } catch (error) {
        setActionLinks([])
      }
    }

    if (store?.html && ui.isModalOpen('emailGoals')) {
      fetchLinks(store.html)
    } else {
      setActionLinks([])
    }
  }, [ui.isModalOpen('emailGoals'), store?.html])

  useEffect(() => {
    const validationErrors = errorMessages || []

    setErrors(validationErrors)
    setShowErrorsTooltip(!!validationErrors.length)
  }, [errorMessages.length])

  return (
    <>
      <GoalList
        actions={actionLinks}
        emptyListText="Return to the email builder and add at least one link or button"
        goals={goals}
      />
      <div className="mt-5 d-flex justify-content-end">
        <Button
          color=""
          disabled={isLoading}
          onClick={() => {
            resetError()
            closeModal()
          }}>
          Cancel
        </Button>
        <Button
          color="body"
          id="save-notification-btn"
          disabled={isLoading || !!errors.length}
          onClick={saveNotification}
          className="ms-2 btn--hover d-flex align-items-center">
          Save & Continue
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-1" />
          ) : (
            <FontAwesomeIcon className="ms-2" icon={faChevronRight} />
          )}
        </Button>
        {ui.isModalOpen('emailGoals') && errors.length > 0 && (
          <BuilderErrors
            key={`${errors.length}`}
            targetId="save-notification-btn"
            messages={errors}
            close={() => {
              setShowErrorsTooltip(false)
            }}
            inModal
            isOpen={showErrorsTooltip}
          />
        )}
      </div>
    </>
  )
}

function EmailNotificationGoalsModal(props: IProps): React.ReactElement {
  const { resetError } = props
  return (
    <Modal
      id="emailGoals"
      size="lg"
      closeCallback={resetError}
      renderHeader={() => 'Email Goals'}
      render={(
        closeModal: () => void,
        params?: { store: EmailNotification }
      ) => (
        <EmailNotificationGoalsModalRender
          {...props}
          closeModal={closeModal}
          store={params?.store}
        />
      )}
    />
  )
}

export default withStore(EmailNotificationGoalsModal)
