import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import Link from '~/components/Link'

interface IProps {
  classes?: string
  iconClasses?: string
  download?: string
  url: string
}

export default function DownloadLink({
  classes,
  iconClasses,
  url,
  children,
  ...rest
}: PropsWithChildren<IProps>): React.ReactElement {
  return (
    <Link
      route={url}
      className={`${classes} btn download-btn btn--hover bg-white btn-outline-body`}
      ariaLabel="Download Link"
      {...rest}>
      <FontAwesomeIcon
        fixedWidth
        icon={faFileDownload}
        className={cn('me-1', iconClasses)}
      />
      {children}
    </Link>
  )
}
