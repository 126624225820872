import { memo, ReactElement } from 'react'
import {
  DeviceType,
  IPreviewItem,
  PreviewFieldType
} from '~/dataStore/Campaign/Campaign.interface'
import ActionButtons from '../Parts/ActionButtons'
import PreviewParts from '../Parts/PreviewParts'
import PreviewCard from '../UI/PreviewCard'
import useCollapseContext from '../../../../../components/CollapseWithToggle/CollapseWithToggle.context'

interface IProps {
  deviceType: DeviceType
  parts: IPreviewItem[]
}

function PushPreview({ deviceType, parts }: IProps): ReactElement {
  const collapseContext = useCollapseContext()

  let pushParts = parts.slice()

  if (['ios', 'watch'].includes(deviceType)) {
    pushParts.sort((a, b) => {
      if (
        [
          PreviewFieldType.TEXT,
          PreviewFieldType.HEADLINE,
          PreviewFieldType.SUBHEADLINE
        ].includes(b.type) &&
        a.type === PreviewFieldType.ATTACHMENT
      ) {
        return -1
      }
      return 0
    })
  } else if (deviceType === 'android') {
    pushParts = pushParts.filter((part) => {
      if (part.type === PreviewFieldType.SUBHEADLINE) {
        return false
      }
      if (
        part.type === PreviewFieldType.ATTACHMENT &&
        part.mediaType !== 'image'
      ) {
        return false
      }

      return true
    })
  }

  //! display audio as a video on iWatch
  if (deviceType === 'watch') {
    const attachmentIndex = pushParts.findIndex(
      (part) => part.type === PreviewFieldType.ATTACHMENT
    )

    if (attachmentIndex !== -1) {
      const { mediaType } = pushParts[attachmentIndex]
      if (mediaType === 'audio') {
        pushParts = [
          ...pushParts.slice(0, attachmentIndex),
          { ...pushParts[attachmentIndex], mediaType: 'video' },
          ...pushParts.slice(attachmentIndex + 1)
        ]
      }
    }
  }

  let buttons = pushParts.find((part) => part.type === 'button')
  if (
    buttons?.value?.length === 1 &&
    buttons.value[0].label === 'Reply' &&
    deviceType !== 'android'
  ) {
    buttons = undefined
  }

  return (
    <>
      {deviceType === 'ios' && parts.length > 0 && (
        <div className="preview__white-exit" />
      )}
      <PreviewCard deviceType={deviceType} className="preview__card--push">
        <PreviewParts
          parts={pushParts.filter(
            (part) => part.type !== PreviewFieldType.BUTTON
          )}
        />
      </PreviewCard>

      {buttons && (
        <ActionButtons
          onClick={() => collapseContext?.setOpened(PreviewFieldType.BUTTON)}
          deviceType={deviceType}
          key="actionButtons"
          buttons={buttons.value as string[]}
        />
      )}
    </>
  )
}

export default memo(PushPreview)
