import React, { useState } from 'react'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

interface IProps {
  name: string
  action?: () => void
  options: { name: string; action?: () => void; options?: any }[]
  toggleParent: () => void
}

const Submenu = ({ name, action, options, toggleParent }: IProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = (newState?: boolean) => {
    setDropdownOpen((prevState) => {
      return typeof newState !== 'undefined' ? newState : !prevState
    })
  }

  return (
    <DropdownItem
      className="btn-no-outline text-dark px-0 py-0"
      toggle={options.length === 0}>
      <UncontrolledDropdown
        className="dropdown__submenu"
        toggle={() => {
          if (action) {
            action()
          }
          toggle()
        }}
        isOpen={dropdownOpen}>
        <DropdownToggle
          color=""
          className={cn(
            'rounded  px-3 py-1 w-100 text-start d-flex justify-content-between align-items-center'
          )}>
          {name}
          <FontAwesomeIcon icon={faChevronRight} size="xs" className="me-1" />
        </DropdownToggle>
        <DropdownMenu
          modifiers={[
            {
              name: 'maxHeight',
              enabled: true,
              phase: 'beforeWrite',
              fn({ state }) {
                return {
                  ...state,
                  styles: {
                    ...state.styles,
                    popper: {
                      ...state.styles.popper,
                      ...(options.filter((o) => o.options).length === 0 && {
                        maxHeight: `200px`,
                        overflowY: `auto`,
                        inset: 'unset'
                      })
                    }
                  }
                }
              }
            }
          ]}>
          {options.map((option) => {
            return option.options ? (
              <Submenu
                options={option.options}
                name={option.name}
                toggleParent={toggleParent}
                action={option.action}
              />
            ) : (
              <DropdownItem
                onClick={() => {
                  toggleParent()
                  option?.action()
                }}>
                {option.name}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </DropdownItem>
  )
}

export default Submenu
