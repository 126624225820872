import { getRequest } from '~/api/requests'
import { get, post, put } from '~/api/v2'
import { ID, IMetaData } from '~/common.interface'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import {
  NotificationPayload,
  NotificationReportDTO
} from './Notification.interface'

export function fetchNotification(
  appId: ID,
  notificationID: ID,
  abortSignal?: AbortSignal
): Promise<Required<NotificationPayload>> {
  return get(`${appId}/notifications/${notificationID}`, {}, abortSignal)
}

export function saveNotification(
  appId: string,
  data: NotificationPayload & { journeyId: ID }
): Promise<Required<NotificationPayload>> {
  return post(`${appId}/notifications`, data)
}

export function updateNotification(
  appId: ID,
  notificationId: ID,
  data: NotificationPayload & { journeyId: ID }
): Promise<Required<NotificationPayload>> {
  return put(`${appId}/notifications/${notificationId}`, data)
}

export function getLinksFromEmailNotification(
  appId: ID,
  html: string
): Promise<{ links: string[] }> {
  return post(`${appId}/notifications/email_links`, {
    html
  })
}

export function fetchNotificationStats(
  appId: ID,
  notificationID: ID
): Promise<NotificationReportDTO> {
  return get(`${appId}/notifications/${notificationID}/stats`)
}

export function fetchNotificationTemplate(
  appId: ID,
  notificationTemplateId: ID,
  abortSignal?: AbortSignal
): Promise<NotificationPayload> {
  return get(
    `${appId}/notification_templates/${notificationTemplateId}`,
    {},
    abortSignal
  )
}

export function fetchDefaultNotificationTemplates<T = NotificationPayload>(
  type: CampaignType,
  abortSignal?: AbortSignal
): Promise<{
  data: T[]
  metadata: IMetaData
}> {
  return getRequest(
    `/api/v2/default_notification_templates`,
    { filterTypes: type },
    abortSignal
  )
}

export function fetchDefaultNotificationTemplate(
  notificationTemplateId: ID,
  abortSignal?: AbortSignal
): Promise<NotificationPayload> {
  return getRequest(
    `/api/v2/default_notification_templates/${notificationTemplateId}`,
    {},
    abortSignal
  )
}
