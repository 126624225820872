import React from 'react'

const IconDelete = () => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
    <circle cx="13.4355" cy="13.5" r="13" fill="#28518C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.13184 8.19663C7.35079 8.97767 7.35079 10.244 8.13184 11.0251L10.6067 13.4999L8.13168 15.9749C7.35064 16.7559 7.35064 18.0222 8.13168 18.8033C8.91273 19.5843 10.1791 19.5843 10.9601 18.8033L13.4351 16.3283L15.91 18.8032C16.6911 19.5843 17.9574 19.5843 18.7384 18.8032C19.5195 18.0222 19.5195 16.7558 18.7384 15.9748L16.2635 13.4999L18.7383 11.0251C19.5193 10.2441 19.5193 8.97773 18.7383 8.19668C17.9572 7.41564 16.6909 7.41564 15.9099 8.19668L13.4351 10.6715L10.9603 8.19663C10.1792 7.41558 8.91289 7.41558 8.13184 8.19663Z"
      fill="white"
    />
  </svg>
)

export default IconDelete
