import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import Steps from '~/components/BuilderSteps'
import { useStore, withStore } from '~/dataStore'
import EmailNotification from './EmailNotification/EmailNotification'
import BuilderErrors from '~/pages/CampaignBuilder/BottomActions/BuilderErrors'
import JourneyEmailNotificationStore from './Store/JourneyEmailNotificationStore'
import EmailTemplateBuilder from '~/pages/Campaign/Notification/EmailNotification/UI/EmaiTemplateBuilder'
import EmailNotificationGoalsModal from './EmailNotificationGoalsModal'

interface IProps {
  emailNotification: JourneyEmailNotificationStore
  close: () => void
  cancelCallback: () => void
  save: () => void
}

const EmailNotificationSidebar = ({
  emailNotification,
  close,
  cancelCallback,
  save
}: IProps) => {
  const {
    getStoreByNumber,
    getStepByNumber,
    setStep,
    steps,
    getSteps,
    currentStep,
    shared: { fetchMergeTags },
    templates: { fetchFromNames },
    goals,
    getErrorMessages,
    resetError
  } = emailNotification

  const {
    ui: { showModal }
  } = useStore()

  const [showErrorsTooltip, setShowErrorsTooltip] = useState(false)
  const store = getStoreByNumber(currentStep.number)

  const { appId } = useParams<{ appId: string }>()

  const backBtnText =
    currentStep.number === 1 ? 'Back to Message' : 'Choose Template'
  const nextBtnText = currentStep.number === 1 ? 'Save & Continue' : 'Save'

  function onSaveClick(): void {
    store?.validateStep()
    if (store?.isStepValid) {
      if (currentStep.number === 1) {
        setStep(steps[1])
      } else {
        // ? it is workaround, I has to pass a store because there is a small delay before design update (asynchronous?)
        showModal('emailGoals', { store })
      }
    } else {
      setShowErrorsTooltip(true)
    }
  }

  const onStepClick = (stepNumber: number) => {
    getStoreByNumber(stepNumber)?.validateStep()
    if (getStoreByNumber(stepNumber)?.isStepValid) {
      setStep(getStepByNumber(stepNumber) || steps[0])
    }
  }

  const goBack = () => {
    if (currentStep.number === 1) {
      cancelCallback()
      close()
    } else {
      setStep(steps[0])
    }
  }

  useEffect(() => {
    fetchMergeTags(appId)
    fetchFromNames(appId)
  }, [])

  return (
    <div className="h-100">
      {currentStep?.number === 1 && (
        <div className="px-4 pb-6">
          <EmailTemplateBuilder
            templates={emailNotification.templates}
            mergeTags={emailNotification.shared.mergeTags}
            setEditorData={emailNotification.email.setEditorData}
          />
        </div>
      )}
      {currentStep?.number === 2 && (
        <div className="pb-3 h-100">
          <EmailNotification emailNotification={emailNotification} />
        </div>
      )}
      <div
        className={cn(
          'position-fixed w-100 bottom-0 fixed-bottom p-4 bg-water-gray'
        )}>
        <Row className="align-items-center">
          <Col xs={4}>
            <Steps
              steps={getSteps}
              currentStep={currentStep}
              onStepClick={onStepClick}
            />
          </Col>
          <Col xs={8}>
            <div
              className={cn('d-flex justify-content-end align-items-center')}>
              <Button color="" onClick={goBack}>
                {backBtnText}
              </Button>
              <Button
                id="save-block-btn"
                color="body"
                disabled={
                  currentStep?.number === 2 &&
                  !emailNotification.email.getEditorReady()
                }
                onClick={onSaveClick}
                className="btn--hover px-4">
                {nextBtnText}
              </Button>
              {store?.getErrorMessages()?.length > 0 && (
                <BuilderErrors
                  key={`${store?.getErrorMessages().length}`}
                  targetId="save-block-btn"
                  messages={store?.getErrorMessages()}
                  close={() => {
                    setShowErrorsTooltip(false)
                  }}
                  isOpen={!store?.isStepValid && showErrorsTooltip}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>

      <EmailNotificationGoalsModal
        close={close}
        goals={goals}
        save={save}
        resetError={resetError}
        errorMessages={getErrorMessages()}
      />
    </div>
  )
}

export default withStore(EmailNotificationSidebar)
