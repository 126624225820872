import React from 'react'

import { Row, Col } from 'reactstrap'
import ApexLineChart from '~/pages/App/Dashboard/UI/ApexLineChart'

interface IProps {
  users: {
    total: { dataGraph: any[] }
    active: { dataGraph: any[] }
    lost: { dataGraph: any[] }
    new: { dataGraph: any[] }
  }
}

export default function UserCharts({
  users: { total, active, lost, ...other }
}: IProps): React.ReactElement {
  return (
    <Row>
      <Col xl="6" className="d-flex">
        <ApexLineChart
          title="Total Users / Active Users"
          chartData={[
            {
              name: 'Total Users',
              data: total.dataGraph,
              color: 'rgba(59,125,221, 0.8)'
            },
            {
              name: 'Active Users',
              data: active.dataGraph,
              color: 'rgba(40,167,69, 0.8)'
            }
          ]}
        />
      </Col>
      <Col xl="6" className="d-flex">
        <ApexLineChart
          title="New Users / Unreachable Users"
          chartData={[
            {
              name: 'New',
              data: other.new.dataGraph,
              color: 'rgba(40,167,69, 0.8)'
            },
            {
              name: 'Unreachable',
              color: 'rgba(220.0, 53.0, 69.0, 1.0)',
              data: lost.dataGraph
            }
          ]}
        />
      </Col>
    </Row>
  )
}
