export const ERRORS_DICT = {
  name: {
    'Missing parameter name': 'Application name is missing'
  }
}

export interface FormValues {
  name: string | undefined
  company: string
  appImage: {
    objectURL: string
    fileName: string
    file: File | null
    valid: boolean
  }
}
