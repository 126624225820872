import React from 'react'

const IconNotification = ({
  className,
  fill
}: {
  className?: string
  fill: string
}): React.ReactElement => {
  return (
    <svg
      width="13"
      height="21"
      viewBox="0 0 18 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V20.94C0 22.5969 1.34315 23.94 3 23.94H14.1C15.7569 23.94 17.1 22.5969 17.1 20.94V3C17.1 1.34315 15.7569 0 14.1 0H3ZM1.80078 4.80078C1.80078 3.69621 2.69621 2.80078 3.80078 2.80078H13.3008C14.4054 2.80078 15.3008 3.69621 15.3008 4.80078V7.10078C15.3008 8.20535 14.4054 9.10078 13.3008 9.10078H3.80078C2.69621 9.10078 1.80078 8.20535 1.80078 7.10078V4.80078ZM6.83984 20.5225C6.83984 19.5781 7.60544 18.8125 8.54984 18.8125C9.49425 18.8125 10.2598 19.5781 10.2598 20.5225C10.2598 21.4669 9.49425 22.2325 8.54984 22.2325C7.60544 22.2325 6.83984 21.4669 6.83984 20.5225Z"
        fill={fill}
      />
    </svg>
  )
}
export default IconNotification
