import { makeObservable, override } from 'mobx'
import { ID } from '~/common.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import { isEmpty } from '~/utils/utilities'
import {
  IBlock,
  IBlockRedirect,
  JourneyBlockType
} from '../Store/JourneyBuilder.interface'
import Block from './Block.model'

type RedirectBlockDTO = Pick<IBlockRedirect, 'redirectReferences' | 'name'>

export default class Redirect extends Block<RedirectBlockDTO> {
  public redirectTo: NewRegisteredField<
    ID | undefined
  > = new NewRegisteredField(undefined)

  public guid = crypto.randomUUID()

  public name = ''

  constructor(public parent: IBlock) {
    super(JourneyBlockType.REDIRECT, parent)

    makeObservable(this, {
      childrenNodes: override
    })
  }

  get filled(): boolean {
    return !isEmpty(this.redirectTo.value)
  }

  // eslint-disable-next-line class-methods-use-this
  public get childrenNodes(): IBlock[] {
    return []
  }

  public getBlockPayload(): RedirectBlockDTO {
    return {
      name: this.name,
      redirectReferences: [
        {
          toBlockId: this.redirectTo.value,
          guid: this.guid
        }
      ]
    }
  }

  protected fillBlockPayload(data: RedirectBlockDTO): void {
    this.name = ''

    this.redirectTo.setValue(data.redirectReferences?.[0]?.toBlockId)
    this.guid = data.redirectReferences?.[0]?.guid || this.guid

    this.setReady()
  }

  public setRedirectTo(block: IBlock): void {
    this.redirectTo.setValue(block.blockID)
  }

  public validateBlock(): boolean {
    if (!this.redirectTo.validate()) {
      this.isValid = false
      this.errors.push('You must select a child')
    }

    if (!this.isValid) {
      this.launchErrors = ['You must set redirect path']
    }

    return this.isValid
  }

  protected getNodeData(): { label: string } & Record<string, unknown> {
    return { label: this.name }
  }

  public resetError(): void {
    super.resetError()
    this.redirectTo.resetError()
  }
}
