import { IGeofence } from '~/pages/Geofences/Geofences.interface'
import polygonIcon from './components/GeofencesMap/icons/polygon.svg'
import circleIcon from './components/GeofencesMap/icons/circle.svg'
import handIcon from './components/GeofencesMap/icons/hand.svg'
import fullScreenIcon from './components/GeofencesMap/icons/fullScreen.svg'
import zoomInIcon from './components/GeofencesMap/icons/zoomIn.svg'
import zoomOutIcon from './components/GeofencesMap/icons/zoomOut.svg'

export const formatLocationDecimalPlaceLength = (
  location: [number, number] | [[number, number], [number, number]]
) => {
  return location.map((loc) => {
    if (Array.isArray(loc)) {
      return loc.map((l) => parseFloat(l.toFixed(8)))
    }
    return parseFloat(loc.toFixed(8))
  })
}

export const locationToLatLng = (
  geofence: IGeofence
): { lat: number; lng: number } => ({
  lat: geofence?.location[0],
  lng: geofence?.location[1]
})

export const getGeofenceLatLng = (
  geofence: IGeofence
): google.maps.LatLng | undefined => {
  if (geofence.location) {
    return new google.maps.LatLng(
      locationToLatLng(geofence).lat,
      locationToLatLng(geofence).lng
    )
  }
  if (geofence.path) {
    return new google.maps.LatLng(geofence?.path[0][0], geofence?.path[0][1])
  }
  return undefined
}

export const radiusToMinMaxLimit = (
  radius: number,
  minRadius: number,
  maxRadius: number
): number => {
  if (radius < minRadius) {
    return minRadius
  }
  if (radius > maxRadius) {
    return maxRadius
  }

  return Math.round(radius)
}

export const getPolygonPath = (
  polygon: google.maps.Polygon
): Array<[number, number]> =>
  polygon
    .getPath()
    .getArray()
    .map((latLng) => {
      return [latLng.lat(), latLng.lng()]
    })

export const pickColor = (number: number): string => {
  let color = `#${Math.floor(
    Math.abs(Math.cos(number * 50) * 16777215) % 16777215
  ).toString(16)}`

  while (color.length < 7) {
    color += '0'
  }

  return color
}

export const getPolygonCenter = (
  cords: Array<Array<number>>
): { lat: number; lng: number } => {
  const bounds = new google.maps.LatLngBounds()

  cords.forEach((cord) => {
    const c = new google.maps.LatLng(cord[0], cord[1])
    bounds.extend(c)
  })

  return {
    lat: bounds.getCenter().lat(),
    lng: bounds.getCenter().lng()
  }
}

export function drawDrawingControls(
  wrapperDiv: HTMLElement,
  setDrawingMode: (value: google.maps.drawing.OverlayType | null) => void,
  polygonsActive: boolean
): void {
  const circleControlUI = document.createElement('div')
  circleControlUI.className = 'map-control map-control--circle'
  circleControlUI.title = 'Click to draw circle'
  circleControlUI.innerHTML = `<img src="${circleIcon}" alt='Draw circle' />`

  circleControlUI.addEventListener('click', () => {
    setDrawingMode('circle')
  })

  const resetDrawingControlUI = document.createElement('div')
  resetDrawingControlUI.className = 'map-control map-control--reset-drawing'
  resetDrawingControlUI.title = 'Click to exit drawing mode'
  resetDrawingControlUI.index = 2
  resetDrawingControlUI.innerHTML = `<img src="${handIcon}" alt="Reset" />`

  resetDrawingControlUI.addEventListener('click', () => {
    setDrawingMode()
  })

  if (polygonsActive) {
    const polygonControlUI = document.createElement('div')
    polygonControlUI.className = 'map-control map-control--polygon'
    polygonControlUI.title = 'Click to draw polygon'
    polygonControlUI.innerHTML = `<img src="${polygonIcon}" alt="Reset" />`

    polygonControlUI.addEventListener('click', () => {
      setDrawingMode('polygon')
    })
    wrapperDiv.appendChild(polygonControlUI)
  }

  wrapperDiv.appendChild(circleControlUI)
  wrapperDiv.appendChild(resetDrawingControlUI)
}

export function drawFullScreenControl(
  controlDiv: Element,
  toggleFullScreen: (value: boolean) => void
): void {
  const controlUI = document.createElement('div')
  controlUI.className = 'map-control map-control--full-screen'
  controlUI.title = 'Click to enter full screen'
  controlDiv.appendChild(controlUI)

  const controlText = document.createElement('div')
  controlText.className = 'map-control__inner'
  controlText.innerHTML = `<img src="${fullScreenIcon}" alt="Enter full screen" />`
  controlUI.appendChild(controlText)
  // eslint-disable-next-line no-param-reassign
  controlDiv.index = 1
  controlUI.addEventListener('click', () => {
    toggleFullScreen()
  })
}

export function drawZoomControls(
  controlDiv: Element,
  map: google.maps.Map
): void {
  const zoomInDiv = document.createElement('div')
  zoomInDiv.className = 'map-control map-control--zoom-in'
  zoomInDiv.title = 'Click to zoom in'
  zoomInDiv.innerHTML = `<img src="${zoomInIcon}" alt="Zoom in" />`
  zoomInDiv.addEventListener('click', () => {
    map.setZoom(map.zoom + 1)
  })

  controlDiv.appendChild(zoomInDiv)

  const zoomOutDiv = document.createElement('div')
  zoomOutDiv.className = 'map-control map-control--zoom-out'
  zoomOutDiv.title = 'Click to zoom out'
  zoomOutDiv.innerHTML = `<img src="${zoomOutIcon}" alt="Zoom out" />`
  zoomOutDiv.addEventListener('click', () => {
    map.setZoom(map.zoom - 1)
  })

  controlDiv.appendChild(zoomOutDiv)
}

export function metersToFeet(meters: number): number {
  return meters / 0.3048
}

export function feetToMeters(feets: number): number {
  return feets * 0.3048
}
