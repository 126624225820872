import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip
} from 'reactstrap'
import { uniqueId } from 'lodash'
import RadioOption from './components/RadioOption'
import Option from './components/Option'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import {
  STYLE_OVERRIDES,
  DEFAULT_DROPDOWN_HEIGHT,
  DEFAULT_DROPDOWN_WIDTH,
  DEFAULT_MENU_LIST_HEIGHT,
  MENU_LIST_OFFSET_TOP,
  THEMES
} from './const'

import './SelectWithSearch.scss'

type Options = ReadonlyArray<IOption>

interface IProps {
  controlShouldRenderValue?: boolean
  dropdownHeight?: number
  dropdownWidth?: number
  loading?: boolean
  onChange?: (value: IOption) => void
  onBlur?: () => void
  onInputChange?: (newValue: string) => void
  className?: string
  isOptionSelected?(option: IOption, selectValue: Options): boolean
  options: Options
  placeholder?: string
  selectPlaceholder?: string
  theme?: typeof THEMES.TRANSPARENT
  value?: string | number
  invalid?: boolean
  closeMenuOnSelect?: boolean
  id?: string
  disabled?: boolean
  right?: boolean
  grouped?: boolean
  errorTooltip?: string
  direction?: 'up' | 'down'
  showAllOption?: boolean
  radioOption?: boolean
}

const SelectWithSearch = ({
  controlShouldRenderValue,
  dropdownHeight,
  dropdownWidth,
  loading,
  onChange,
  onBlur,
  onInputChange,
  className,
  toggleClassName,
  isOptionSelected,
  id,
  options = [],
  placeholder,
  selectPlaceholder,
  theme,
  value,
  radioOption,
  invalid,
  closeMenuOnSelect,
  disabled,
  showAllOption,
  right = false,
  grouped = false,
  errorTooltip,
  direction = 'down'
}: IProps): React.ReactElement => {
  const uniqId = useRef(uniqueId('select-with-search'))
  const inputId = id || uniqId.current
  const [dropdownOpen, setOpen] = useState(false)
  const isThemeTransparent = () => theme === THEMES.TRANSPARENT
  const toggle = (e?: React.MouseEvent<HTMLElement>) => {
    if (!e && closeMenuOnSelect === false) {
      return
    }

    if (onBlur && dropdownOpen === true) {
      onBlur()
    }
    setOpen((prevState) => !prevState)
  }

  const handleChange = (item, event) => {
    if (onChange) onChange(item, event)
    toggle()
  }

  const findSelectOption = (value = '') => {
    const valueInString = value.toString()

    const findOption = (option) =>
      option.value.toString().localeCompare(valueInString, undefined, {
        sensitivity: 'case'
      }) === 0

    if (grouped) {
      return options.flatMap((option) => option.options).find(findOption)
    }

    return options.find(findOption)
  }

  const getSelectPlaceholder = () => (
    <div className="select-with-search__select-placeholder">
      <FontAwesomeIcon icon={faSearch} className="text-blue" />
      <span className="ms-2">{selectPlaceholder || 'Search...'} </span>
    </div>
  )

  const noResultsMessage = ({ inputValue }: { inputValue: string }) => {
    if (!inputValue) return null
    return (
      <span className="text-blue">
        {`We couldn't find any results for "${inputValue}"`}
      </span>
    )
  }

  const renderValue = () => {
    if (controlShouldRenderValue === false || !value || !options.length) {
      return placeholder
    }

    return findSelectOption(value)?.label
  }

  const getMaxMenuHeight = () =>
    dropdownHeight
      ? dropdownHeight - MENU_LIST_OFFSET_TOP
      : DEFAULT_MENU_LIST_HEIGHT

  const getOptions = () => {
    let selectOptions = options
    if (showAllOption) {
      selectOptions = [
        { label: 'Show All', value: '', dataTestId: 'ShowAllItem' },
        ...options
      ]
    }
    return selectOptions
  }

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className={cn(
          'select-with-search',
          'form-control',
          'p-0',
          'text-start',
          'position-relative',
          className,
          { 'error-input': invalid },
          { 'select-with-search--transparent': isThemeTransparent() },
          { 'disabled-field': disabled }
        )}>
        <DropdownToggle
          id={inputId}
          disabled={disabled}
          caret
          color=""
          className={cn(
            toggleClassName,
            'select-with-search__dropdown-toggle',
            {
              'o-50': typeof value !== 'undefined' && !value,
              'dropdown-toggle--invert': direction === 'up'
            },
            { 'error-label': invalid && isThemeTransparent() }
          )}>
          <span className="text-truncate">{renderValue()}</span>
        </DropdownToggle>
        <DropdownMenu
          end={right}
          className={cn(
            'select-with-search__dropdown-menu w-100 pt-1 overflow-hidden',
            { 'select-with-search__dropdown-menu--up': direction === 'up' }
          )}
          style={{
            minHeight: dropdownHeight || DEFAULT_DROPDOWN_HEIGHT,
            minWidth: dropdownWidth || DEFAULT_DROPDOWN_WIDTH
          }}>
          <DropdownItem header>
            <Select
              controlShouldRenderValue={false}
              autoFocus={dropdownOpen}
              className="react-select-container"
              placeholder={getSelectPlaceholder() || ''}
              closeMenuOnSelect={closeMenuOnSelect}
              onChange={handleChange}
              isOptionSelected={isOptionSelected}
              onBlur={onBlur}
              onInputChange={onInputChange}
              options={getOptions()}
              styles={STYLE_OVERRIDES}
              maxMenuHeight={getMaxMenuHeight()}
              components={{
                ...(radioOption && { Option: RadioOption }),
                Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
              }}
              // isSearchable
              tabSelectsValue={false}
              menuIsOpen
              value={{ value: `${value ?? ''}`, label: `${value ?? ''}` }}
              isLoading={loading}
              noOptionsMessage={noResultsMessage}
            />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {errorTooltip && (
        <UncontrolledTooltip
          className="tooltip-validation-error"
          placement="top-start"
          target={inputId}>
          {errorTooltip}
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default SelectWithSearch
