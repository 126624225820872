import React from 'react'
import cn from 'classnames'

import Skeleton from 'react-loading-skeleton'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import JourneyTemplateIcon from '~/pages/Journeys/UI/JourneyTemplateIcon/JourneyTemplateIcon'
import { withStore } from '~/dataStore'

import Start from '~/pages/Campaign/CampaignReview/ReviewTopBar/Start'
import { ExpiryType } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  name: string
  icon: string
  displayType?: string
  isDetailsInPast: boolean
  deliver: {
    isCampaignStartingEndingSameDay: boolean
    startAt: Date | string | undefined | null
    endAt: Date | string | undefined | null
    startNow?: boolean | string
    expiryType?: ExpiryType
    expiryDate?: Date | string | undefined
    expiryTimeFrame?: string
    expiryTimeValue?: string | number | null
    campaignExpiry?: boolean
    isDeliveryFuture: boolean
  }
  status?: JourneyStatus
}

function ReviewTopBar({
  name,
  icon,
  displayType,
  isDetailsInPast,
  deliver,
  status
}: IProps) {
  return (
    <header
      className={cn(
        'campaign-header d-flex justify-content-between align-items-center w-100'
      )}>
      <div className="campaign-header__col d-flex align-items-center">
        {name ? (
          <>
            <JourneyTemplateIcon src={icon} size="sm" center={false} />
            <div className="d-flex flex-column ms-4">
              <span>{name}</span>
              <span className="muted">{displayType}</span>
            </div>
          </>
        ) : (
          <Skeleton width={200} count={2} />
        )}
      </div>

      <div className="campaign-header__col d-flex flex-column align-items-end">
        {name ? (
          <Start
            isDetailsInPast={isDetailsInPast}
            deliver={deliver}
            type="Journey"
            status={status}
          />
        ) : (
          <Skeleton width={200} count={2} className="d-block my-2" />
        )}
      </div>
    </header>
  )
}

export default withStore(ReviewTopBar)
