import { Button, Col, Row } from 'reactstrap'
import Input from '~/components/forms/Input'
import IconDelete from '~/components/icons/IconDelete'
import { withStore } from '~/dataStore'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'

interface IProps {
  row: NewRegisteredField<IOption & { id: string }>
  remove: (id: string) => void
  showRemoveIcon: boolean
  onBlur?: () => boolean
}

function CampaignBuilderTableRow({
  row,
  remove,
  showRemoveIcon,
  onBlur
}: IProps): React.ReactElement {
  function handleChange(key: 'value' | 'label', value: string): void {
    row.setValue({ ...row.value, [key]: value })
  }

  return (
    <Row className="align-items-center mb-3">
      <Col>
        <Input
          name="cellHeader"
          value={row.value.label}
          onChange={(e) => handleChange('label', e.target.value)}
          onBlur={onBlur}
          placeholder="Eg. Monday"
        />
      </Col>
      <Col>
        <Input
          name="cellValue"
          value={row.value.value}
          onChange={(e) => handleChange('value', e.target.value)}
          onBlur={onBlur}
          placeholder="Eg. 9 a.m. - 6 p.m. "
        />
      </Col>
      {showRemoveIcon && (
        <Col className="ps-0 flex-grow-0">
          <Button color="" className="p-0" onClick={() => remove(row.value.id)}>
            <IconDelete />
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default withStore(CampaignBuilderTableRow)
