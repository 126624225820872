import { Collapse, Button } from 'reactstrap'
import LearnMoreButton from '~/components/common/NoticeBarSecondary/components/LearnMoreButton'

import closeIcon from '~/components/icons/close-circle.svg'

const LearnMoreTopBanner = ({
  isOpen,
  closeBanner
}: {
  isOpen: boolean
  closeBanner: () => void
}): React.ReactElement => {
  return (
    <Collapse isOpen={isOpen}>
      <div className="new-journey__banner">
        <Button
          color=""
          onClick={() => closeBanner()}
          className="new-journey__banner-close-icon p-0">
          <img src={closeIcon} alt="Close modal" className="mw-100" />
        </Button>
        <div>
          <p className="new-journey__banner-header">
            Deliver great experiences with{' '}
            <span className="fw-medium">Customer Journeys</span>
          </p>
          <p className="mt-3 new-journey__banner-sub-header">
            Automated marketing helps you reach more customers with less effort
            and more results.
          </p>
          <div className="mt-5 d-inline-block">
            <LearnMoreButton
              purple
              href="https://docs.pulsatehq.com/reference/journeys"
            />
          </div>
        </div>
      </div>
    </Collapse>
  )
}

export default LearnMoreTopBanner
