import { uniqueId } from 'lodash'
import { action, makeAutoObservable } from 'mobx'
import { ID } from '~/common.interface'
import {
  BlockTypeToNodeType,
  INodeModel,
  JourneyBlockType,
  NodeType
} from '../Store/JourneyBuilder.interface'

export default class Node implements INodeModel {
  public readonly id: ID = uniqueId()

  public column = 0

  public level = 0

  public cx = 0

  public last = false

  /**
   * Represents node in a block
   * @param blockType block type
   */
  constructor(public blockType: JourneyBlockType) {
    makeAutoObservable(this, {
      setLevel: action.bound,
      setColumn: action.bound,
      calculatePosition: action.bound,
      column: false
      // cx: false
    })

    this.last = !!(this.nodeType && [NodeType.EXIT].includes(this.nodeType))
  }

  public get nodeType(): NodeType | undefined {
    return BlockTypeToNodeType.get(this.blockType)
  }

  public setColumn(value: number): void {
    this.column = value
  }

  public setLevel(value: number): void {
    this.level = value
  }

  public calculatePosition(children: { node: INodeModel }[]): void {
    if (!children.length) {
      this.cx = 500 * this.column + window.innerWidth / 2
    } else {
      this.cx =
        children.reduce((cx, child) => cx + child.node.cx, 0) / children.length
    }
  }

  private getPosition(): { x: number; y: number } {
    let x
    let y

    const YStep = 240

    switch (this.nodeType) {
      case NodeType.ENTRY:
        x = this.cx
        y = this.level * YStep - 40
        break
      case NodeType.EXIT:
        x = this.cx + 164.5
        y = this.level * YStep
        break
      case NodeType.REDIRECT:
        x = this.cx + 135
        y = this.level * YStep
        break
      default:
        x = this.cx
        y = this.level * YStep
    }

    return {
      x,
      y
    }
  }

  public getNode(): {
    type: NodeType | undefined
    position: { x: number; y: number }
    selectable: false
  } {
    return {
      type: this.nodeType,
      position: this.getPosition(),
      selectable: false
      // sourcePosition: NodeType.REDIRECT === this.nodeType ? 'left' : 'right'
    }
  }
}
