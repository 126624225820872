import { Collapse } from 'reactstrap'

import { useStore, withStore } from '~/dataStore'

import DeliveryOnCertainDays from './components/DeliveryOnCertainDays'
import CampaignStartsEndsAt from './components/CampaignStartsEndsAt'
import Expiry from './components/Expiry/Expiry'
import Section from './components/Section'
import TimeZonePicker from '~/components/forms/TimeZonePicker'
import { ExpiryType } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import Panel from '~/components/forms/Panel/Panel'

const CampaignStart = () => {
  const {
    campaign: {
      isSended,
      deliver: {
        setStartNow,
        startNow,
        validateStartAt,
        validateCampaignActivation,
        timeZoneName,
        expiryType,
        expiryDate,
        setExpiryType,
        validateExpiryDate,
        setTimeZoneName,
        deliverUi: {
          isStartPanelBodyCollapsed,
          isStartEndSectionCollapsed,
          isExpiryDateSectionCollapsed,
          isDeliveryFutureOrDefault
        }
      }
    }
  } = useStore()

  const onTimeZoneChange = (value: { timeZoneName: string | undefined }) => {
    setTimeZoneName(value.timeZoneName)
    validateStartAt()
  }

  const onToggleSwitch = (e) => {
    setStartNow(e.target.value)
    validateCampaignActivation()
  }

  const onExpiryTypeChange = (value: ExpiryType) => {
    setExpiryType(value)
    validateExpiryDate()
  }

  return (
    <>
      <Panel
        className="bg-light"
        disabled={isSended}
        isCollapsed={isStartPanelBodyCollapsed}
        minHeight={80}
        onChange={onToggleSwitch}
        title="When should this campaign be activated?"
        selected={`${startNow.value}`}
        switchLabels={[
          {
            label: 'Immediately',
            value: 'true',
            name: 'startNow',
            width: 175,
            tooltipText:
              'Your campaign will be sent immediately after you review your campaign'
          },
          {
            label: 'In the Future',
            value: 'false',
            name: 'startNow',
            width: 175,
            tooltipText: 'Your campaign will be sent at a future date and time'
          }
        ]}>
        <div>
          <Collapse isOpen={isStartEndSectionCollapsed}>
            <Section
              divider={
                isDeliveryFutureOrDefault || isExpiryDateSectionCollapsed
              }>
              <CampaignStartsEndsAt />
            </Section>
          </Collapse>

          <Collapse isOpen={isExpiryDateSectionCollapsed}>
            <Section divider={isDeliveryFutureOrDefault}>
              <Expiry
                expiryDateInvalid={!expiryDate.isValid}
                disabled={isSended}
                value={expiryType}
                onChange={onExpiryTypeChange}
                label="Set campaign expiry from user's device"
                name="expiry"
              />
            </Section>
          </Collapse>

          <Collapse isOpen={isDeliveryFutureOrDefault}>
            <Section>
              <DeliveryOnCertainDays />
            </Section>
          </Collapse>
        </div>
      </Panel>
      <div className="pt-4 ps-4 pe-4 pb-1 mt-3 bg-light">
        <Section>
          <div className="d-inline-block">
            <TimeZonePicker
              disabled={isSended}
              invalid={!timeZoneName.isValid}
              value={timeZoneName.value}
              onChange={onTimeZoneChange}
            />
          </div>
        </Section>
      </div>
    </>
  )
}

export default withStore(CampaignStart)
