import { ApexOptions } from 'apexcharts'

export const colors = [
  { start: '#D0D9F7', end: '#D0D9F7', legend: '#93A8C5' },
  { start: '#3E95D1', end: '#5E85BA', legend: '#4195D0' },
  { start: '#C050BA', end: '#6643B4', legend: '#C051BB' },
  { start: '#F5C324', end: '#C1960C', legend: '#F9861C' },
  { start: '#5ABF9D', end: '#347C64', legend: '#469D7F' },
  { start: '#EE2650', end: '#AA203D', legend: '#AA203D' },
  { start: '#FF6B00', end: '#CA641A', legend: '#CA641A' },
  { start: '#26EEE2', end: '#1DA8A0', legend: '#1DA8A0' },
  { start: '#FFAECF', end: '#E57CA7', legend: '#E57CA7' },
  { start: '#240101', end: '#240101', legend: '#240101' },
  { start: '#175742', end: '#175742', legend: '#175742' },
  { start: '#EBB991', end: '#EBB991', legend: '#EBB991' },
  { start: '#F0BFF4', end: '#F0BFF4', legend: '#F0BFF4' }
]

export const chartOptions: ApexOptions = {
  chart: {
    zoom: {
      enabled: true
    },
    toolbar: {
      show: true,
      tools: {
        download: false,
        zoom: '<span class="icon icon--select"></span>',
        zoomin: '<span class="icon icon--zoom-in"></span>',
        zoomout: '<span class="icon icon--zoom-out"></span>',
        pan: '<span class="icon icon--pan"></span>',
        reset: '<span class="icon icon--reset-zoom"></span>'
      }
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 3
    }
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.7
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  markers: {
    size: 1,
    strokeWidth: 0
  },
  xaxis: {
    type: 'datetime',
    labels: {
      trim: true
    }
  },
  tooltip: {},
  grid: {
    borderColor: '#f1f1f1'
  },
  colors: colors.map((color) => color.start),
  legend: {
    show: false
  }
}
