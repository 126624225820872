import React from 'react'
import TableNew from '~/components/Table/TableNew'
import { IColumn } from '~/components/Table/Table.interface'
import ColWithDiff from './ConversionRateCol'
import { IGoalsPerformanceRow } from './Model/Report.interface'

interface IProps {
  title: string
  rows: IGoalsPerformanceRow[]
}

export default function GoalTable({ title, rows }: IProps): React.ReactElement {
  const columns: IColumn<keyof IGoalsPerformanceRow>[] = [
    {
      fieldName: 'name',
      displayName: 'Conversion Goal'
    },
    {
      fieldName: 'conversionRate',
      displayName: 'Conversion Rate',
      render: ColWithDiff<IGoalsPerformanceRow>(
        'conversionRate',
        'conversionDiff'
      ),
      classes: 'text-end'
    }
  ]

  return (
    <>
      <h3 className="table-name my-4 counter__item">{title}</h3>
      <TableNew
        className="table--big"
        columns={columns}
        rows={rows}
        rowKey="name"
      />
    </>
  )
}
