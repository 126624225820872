import { Container } from 'reactstrap'
import React from 'react'
import cn from 'classnames'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { withStore } from '~/dataStore'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import useQueryParams from '~/hooks/useQueryParams'
import MessagesList from '~/pages/Messages/MessagesList/MessagesList'
import Chat from '~/pages/Messages/Chat'
import useMessages from '~/pages/Messages/useMessages'
import TableHeader from '~/components/Table/TableHeader'
import NewMessage from '~/pages/Messages/NewMessage'
import routes from '~/routes'
import styles from './Messages.scss'

function Messages(): React.ReactElement | null {
  const { path } = useRouteMatch()
  const paginationQuery = usePaginationQuery({ perPage: 50 })
  const queryParams = useQueryParams({ type: 'all' })

  const {
    data: messages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useMessages({
    ...queryParams,
    ...paginationQuery.getPaginationQuery()
  })
  const unreadCount = messages?.unreadCount
  const goBack = () => {
    queryParams.id = ''
  }

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Container fluid>
            <TableHeader text="New Message" route={routes.newMessage}>
              <>
                Messages{' '}
                {messages?.metadata?.dataCount > 0 &&
                  `(${messages.metadata.dataCount})`}
              </>
            </TableHeader>
            <div className={cn(styles.messagesContainerWrapper, 'card')}>
              <div className="row g-0">
                <div className="col-12">
                  {queryParams.id ? (
                    <Chat id={queryParams.id} goBack={goBack} />
                  ) : (
                    <MessagesList
                      unreadCount={unreadCount}
                      handleSearch={paginationQuery.handleSearch}
                      searchQuery={paginationQuery.searchQuery}
                      queryParams={queryParams}
                      hasMore={hasNextPage}
                      onLoadMore={fetchNextPage}
                      messages={messages?.pages || []}
                      isLoading={isFetchingNextPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </Container>
        </Route>
        <Route exact path={routes.newMessage.path}>
          <NewMessage />
        </Route>
      </Switch>
    </>
  )
}

export default withStore(Messages)
