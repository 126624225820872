import { useRef } from 'react'
import { uniqueId } from 'lodash'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { ISegmentsTargetListItem } from './Segments.interface'
import SegmentDetailsBtnWIthModal from '~/pages/Segment/SegmentDetailsBtnWIthModal'

export default function SegmentNameCol({
  row,
  selectSegment
}: {
  row: ISegmentsTargetListItem
  selectSegment: (row: ISegmentsTargetListItem, selected: boolean) => void
}): React.ReactElement {
  const id = useRef(uniqueId('segmentName'))
  return (
    <>
      <Button
        className="p-0 w-100 text-start shadow-none d-flex align-items-center mb-0"
        onClick={() => selectSegment(row, !row.isSelected)}
        color="">
        <span tabIndex={0} role="button" className="truncate" id={id.current}>
          {row.name}
          {/* {handleLongName(row.name, 80)} */}
        </span>
        <UncontrolledTooltip placement="top" target={id.current}>
          {row.name}
        </UncontrolledTooltip>
        <SegmentDetailsBtnWIthModal segmentId={row.id} />
      </Button>
    </>
  )
}
