import React, { MouseEvent } from 'react'
import { Button, ModalBody } from 'reactstrap'
import { isString } from '~/utils/utilities'
import { BatchUploadErrors } from '~/common.interface'
import UploadButton from '~/components/UploadButton'

import './BulkUploadMethodModal.scss'

interface IProps {
  onManualUpload: (e: MouseEvent) => void
  onCsvUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
  csvUploadText: string
  manualUploadText: string
  errors?: BatchUploadErrors
  helpImage: string
}

const BulkUploadMethodModal = ({
  csvUploadText,
  manualUploadText,
  onManualUpload,
  errors,
  onCsvUpload,
  helpImage
}: IProps): React.ReactElement => {
  return (
    <ModalBody>
      <p className="text-center h5 fw-normal pb-3">
        How would you like to do it?
      </p>
      <div className="d-flex justify-content-between align-items-center mt-5 px-4 position-relative">
        <div className="bulk-upload-method-modal__add-option text-center">
          <p className="text-muted">{csvUploadText}</p>
          <UploadButton
            onChange={onCsvUpload}
            accept="text/csv"
            label="Upload .CSV File"
            id="upload-csv"
            buttonClassName="mt-2"
          />
          {isString(errors) && (
            <p className="text-center mt-4 mb-0 color-danger">{errors}</p>
          )}
        </div>
        <h5 className="bulk-upload-method-modal__add-options-divider text-center fw-normal">
          or
        </h5>
        <div className="bulk-upload-method-modal__add-option text-center">
          <p className="text-muted">{manualUploadText}</p>
          <Button className="mt-2" color="primary" onClick={onManualUpload}>
            Add Manually
          </Button>
        </div>
      </div>

      <div className="mt-6 bg-concrete p-3">
        <p className="text-muted mb-2">
          Recreate those columns in your .CSV file
        </p>
        <img className="w-100" src={helpImage} alt="" />
      </div>
    </ModalBody>
  )
}

export default BulkUploadMethodModal
