import { action, makeObservable, observable } from 'mobx'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { deleteRequest, postRequest } from '~/api/requests'
import {
  createGroup,
  editGroup,
  removeGroup
} from '~/dataStore/Groups/Groups.connector'
import { NotificationType, showNotification } from '~/utils/Notification'
import Ui from '../ui/ui'
import App from '../App'

// TODO: Refactor

export default class Groups {
  public rootStore: { ui: Ui; app: App }

  constructor(rootStore: { ui: Ui; app: App }) {
    makeObservable(this, {
      groups: observable,
      isPerforming: observable,
      selectedBeacons: observable,
      setGroups: action.bound,
      addElementsToGroup: action.bound,
      removeElementsFromGroup: action.bound,
      editGroup: action.bound,
      removeGroup: action.bound,
      createGroup: action.bound
    })

    this.rootStore = rootStore
  }

  groups: IGroup[] = []

  isPerforming = false

  selectedBeacons: Map<string, IGroup> = new Map()

  setGroups(groups: IGroup[]) {
    this.groups = groups
  }

  public async addElementsToGroup({
    url,
    groupId,
    resourceIds,
    elements,
    successCallback
  }: {
    url: string
    groupId: string
    resourceIds: Array<string>
    elements: Array<any>
    successCallback: () => void
  }): Promise<Array<unknown>> {
    this.isPerforming = true
    try {
      await postRequest(url, {
        groupId,
        resourceIds
      })
      const updatedElements = [...elements]
      const group = this.groups.find((g: IGroup) => g.id === groupId)
      updatedElements.forEach((c) => {
        if (resourceIds.includes(c.id))
          c.groups.push({ ...group, id: group?.id })
      })
      this.rootStore?.ui?.unregisterModal('addElementsToGroup')
      successCallback()
      showNotification('Added to group!', NotificationType.SUCCESS)
      return updatedElements
    } catch (error) {
      showNotification('Unable to add to group!', NotificationType.ERROR)
    } finally {
      this.isPerforming = false
    }
  }

  public async removeElementsFromGroup({
    url,
    resourceIds,
    selectedGroups,
    elements,
    successCallback
  }: {
    url: string
    resourceIds: Array<string>
    selectedGroups: Array<string>
    elements: Array<any>
    successCallback: () => void
  }): Promise<Array<any>> {
    this.isPerforming = true
    try {
      await Promise.all(
        selectedGroups.map((g) =>
          deleteRequest(url, { groupId: g, resourceIds })
        )
      )
      const updatedElements = [...elements]
      updatedElements.forEach((e, i) => {
        if (resourceIds.includes(e.id)) {
          updatedElements[i].groups = e.groups.filter(
            (g) => !selectedGroups.includes(g.id)
          )
        }
      })
      this.rootStore?.ui?.unregisterModal('removeElementsFromGroup')
      showNotification('Removed from group!', NotificationType.SUCCESS)
      successCallback()
    } catch {
      showNotification('Unable to remove from group!', NotificationType.ERROR)
    } finally {
      this.isPerforming = false
    }
  }

  public async editGroup(name?: string, group?: IGroup) {
    if (!group || !name) return
    try {
      this.isPerforming = true
      await editGroup(this.rootStore?.app?.currentApp.id, group.id, name)
      this.groups.forEach((g: IGroup) => {
        if (g.id === group.id) {
          g.name = name
        }
      })
      showNotification('Group edited!', NotificationType.SUCCESS)
      this.rootStore?.ui?.unregisterModal('editGroupModal')
    } catch (error) {
      showNotification('Unable to edit group!', NotificationType.ERROR)
    } finally {
      this.isPerforming = false
    }
  }

  public async removeGroup(groupId: string) {
    try {
      this.isPerforming = true
      await removeGroup(this.rootStore?.app?.currentApp.id, groupId)
      this.groups = this.groups.filter((g) => g.id !== groupId)
      showNotification('Group deleted!', NotificationType.SUCCESS)
      this.rootStore?.ui?.unregisterModal('removeGroupModal')
    } catch (error) {
      showNotification('Unable to remove group!', NotificationType.ERROR)
    } finally {
      this.isPerforming = false
    }
  }

  public async createGroup(name: string, resourceType: string) {
    try {
      this.isPerforming = true
      const group = await createGroup(
        this.rootStore?.app?.currentApp.id,
        name,
        resourceType
      )
      this.groups = [...this.groups, group]
      showNotification('Group created!', NotificationType.SUCCESS)
      this.rootStore?.ui?.unregisterModal('createGroupModal')
    } catch (error) {
      if ('name' in error.body?.errors) {
        showNotification('Group name already taken.', NotificationType.ERROR)
      } else {
        showNotification('Unable to create group!', NotificationType.ERROR)
      }
    } finally {
      this.isPerforming = false
    }
  }
}
