import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import Checkbox from '~/components/forms/Checkbox'
import useInfiniteScroll from '~/hooks/useInfiniteScroll'
import {
  IHandleFilterProps,
  PER_PAGE_COUNT
} from '../CampaignsAndGroupsMultiSelect.interface'
import { handleLongName } from '~/utils/string'

interface IProps {
  groups?: Array<{ id: string; name: string }>
  selectedGroupsIds?: Set<string>
  handleFilter: ({ checked, value, reset }: IHandleFilterProps) => void
}

const GroupsTab = ({
  groups,
  selectedGroupsIds,
  handleFilter
}: IProps): React.ReactElement => {
  const [perPageCount, setPerPageCount] = useState(PER_PAGE_COUNT)
  const [groupsPaged, setGroupsPaged] = useState(
    groups?.slice(0, PER_PAGE_COUNT)
  )

  const bottomRef = useInfiniteScroll({
    hasMore: groups ? perPageCount <= groups?.length : false,
    onLoadMore: () => {
      setPerPageCount((prev) => prev + PER_PAGE_COUNT)
    }
  })

  useEffect(() => {
    setGroupsPaged(groups?.slice(0, perPageCount))
  }, [perPageCount, groups])

  return (
    <div className="campaigns-groups-multi-select__list">
      <Button
        color=""
        className="mb-2 p-0"
        onClick={() => handleFilter({ reset: true })}>
        Show all
      </Button>
      {groups
        ?.filter((group) => selectedGroupsIds?.has(group.id))
        .map((group: { id: string; name: string }) => (
          <Checkbox
            key={group.id}
            checked
            data-testid={`resource-selected-group-${group.id}`}
            wrapperClass="mt-2"
            onChange={(checked) => {
              handleFilter({ checked, value: group.id })
            }}
            label={group.name}
          />
        ))}
      {selectedGroupsIds && selectedGroupsIds.size > 0 && (
        <div className="campaigns-groups-multi-select__selected-separator" />
      )}
      {groupsPaged
        ?.filter((group) => !selectedGroupsIds?.has(group.id))
        .map((group: { id: string; name: string }) => (
          <Checkbox
            key={group.id}
            wrapperClass="mt-2"
            data-testid={`resource-group-${group.id}`}
            onChange={(checked) => {
              handleFilter({ checked, value: group.id })
            }}
            label={handleLongName(group.name, 30)}
          />
        ))}
      <div ref={bottomRef} />
    </div>
  )
}

export default GroupsTab
