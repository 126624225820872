import { uniqueId } from 'lodash'
import React, { PropsWithChildren, useMemo } from 'react'
import cn from 'classnames'

interface IProps {
  columnCount: number | string
  className?: string
  border?: boolean
  ariaLabel?: string
}

export default function Columns({
  columnCount,
  children,
  className,
  border,
  ariaLabel
}: PropsWithChildren<IProps>): React.ReactElement {
  const childrenWithBorder = useMemo(
    () =>
      children.flat().length -
      Math.floor(children.flat().length / Number(columnCount)),
    [children, columnCount]
  )
  return (
    <div
      className={cn('column', [`column-${columnCount}`], className, {
        'column--custom-border': border
      })}
      aria-label={ariaLabel || `Columns with ${columnCount} columns`}>
      {children.flat().map((child, index) => {
        return (
          <div
            key={uniqueId()}
            className={cn('column__item', {
              'column__item--border': border && index < childrenWithBorder
            })}
            aria-label={`Column item ${index + 1}`}>
            {child}
          </div>
        )
      })}
    </div>
  )
}
