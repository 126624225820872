import { ID } from '~/common.interface'
import {
  ActionTypes,
  CTAButton,
  FileExtension,
  Provider
} from '~/dataStore/Campaign/Campaign.interface'
import {
  IDesign,
  IOption
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'
import {
  IGraphData,
  ILinkReport
} from '../CampaignReports/Model/Report.interface'
import { INotificationGoalDTO } from './NotificationGoals/NotificationGoals.interface'

/**
 * GENERAL
 */
export enum NotificationType {
  CARD = 'card',
  PUSH = 'push',
  IN_APP = 'in_app',
  SMS = 'sms',
  EMAIL = 'email'
}

/**
 * BUILDER
 */

export type NotificationPayload =
  | IPushDTO
  | IInappLargeDTO
  | IInappSmallDTO
  | ICardDTO
  | IEmailDTO

export interface IPushDTO {
  frontParts: {
    title: {
      active: boolean
      text: string
      position: 0
      side: 'front'
    }
    subTitle: {
      active: boolean
      text: string
      position: 1
      side: 'front'
    }
    pushBody: {
      active: true
      text: string
      position: 2
      side: 'front'
      destination?: string
      destinationType?: ActionTypes
    }
    attachment: {
      active: boolean
      url?: string | null
      provider: Provider
      name?: string
      position: 3
      side: 'front'
      customData?: {
        name: string | null
        type: FileExtension
      } | null
    }
    callToAction: {
      position: 4
      active: boolean
      side: 'front'
      buttonCategoryId?: string
      buttons: CTAButton[]
    }
  }
  type: NotificationType.PUSH | undefined
  id: ID | undefined
  goals?: INotificationGoalDTO[] | undefined
  template?: boolean
  name?: string
}

export type IInappDTO = IInappSmallDTO | IInappLargeDTO
export interface IInappSmallDTO {
  callToAction: {
    buttons: [CTAButton]
  }
  imageHeaderWithMessage: {
    url: string
    name: string
    message: string
  }
  adminHeaderWithMessage: {
    position: number
    active: boolean
    message: string
    admin: Pick<IAdmin, 'jobTitle' | 'name' | 'avatarUrl' | 'id'>
  }
  position: 'top' | 'bottom'
  type: 'corporate' | 'personal'
  // TODO: set type of notification
  // type: NotificationType.IN_APP | undefined
  id: ID | undefined
  goals: INotificationGoalDTO[] | undefined
  size: 'small'
}

export interface IInappLargeDTO {
  frontParts: IInappCardDTO
  type: NotificationType.IN_APP | undefined
  id: ID | undefined
  goals: INotificationGoalDTO[] | undefined
  size: 'large'
  name?: string
  template?: boolean
}

export interface ICardDTO {
  frontParts: IInappCardDTO
  backParts?: ICardBackDTO
  type: NotificationType.CARD | undefined
  id: ID | undefined
  goals: INotificationGoalDTO[] | undefined
  name?: string
  template: boolean
}

export interface IInappCardDTO {
  adminHeaderWithMessage: {
    position: number
    active: boolean
    message: string
    side: 'front'
    admin: Pick<IAdmin, 'jobTitle' | 'name' | 'avatarUrl' | 'id'>
  }
  headline: {
    active: boolean
    text: string
    position: number
    side: 'front'
  }
  text: {
    active: boolean
    text: string
    position: number
    side: 'front'
  }
  image: {
    active: boolean
    url: string
    name: string
    position: number
    side: 'front'
  }
  callToAction: {
    side: 'front'
    buttons: CTAButton[]
  }
}

export interface ICardBackDTO {
  headline: {
    active: boolean
    text: string
    position: number
    side: 'back'
  }
  text: {
    active: boolean
    text: string
    position: number
    side: 'back'
  }
  image: {
    active: boolean
    url: string
    name: string
    message?: string
    position: number
    side: 'back'
  }
  heading: {
    text: string | undefined
    active: boolean
    position: number
    side: 'back'
  }
  table: {
    active: boolean
    position: number
    rows: IOption[] | undefined
    side: 'back'
  }
  callToAction: {
    active: boolean
    buttons: CTAButton[]
    side: 'back'
  }
}

export interface IEmailDTO {
  frontParts: {
    emailBody: {
      active: true
      source: string
      subject: string
      design: IDesign | undefined | string
      html: string
      fromName: string
      originTemplateId?: string
    }
  }
  id: ID | undefined
  type: NotificationType.EMAIL | undefined
  goals: INotificationGoalDTO[] | undefined
  name?: string
  template?: boolean
}

/**
 * REPORTS
 */
export interface IChartReport {
  totalUniq?: number
  uniqGraph?: IGraphData[]
  totalUniqRate?: number
  uniqRateGraph?: IGraphData[]
}

export type NotificationReportDTO =
  | IPushReport
  | IEmailReport
  | IInAppReport
  | ICardReport

export interface GoalNotificationDTO {
  actions: {
    action: string
    conversions: {
      primary: IChartReport | null
      secondary: IChartReport | null
    }
  }[]
  conversions: {
    primary: IChartReport | null
    secondary: IChartReport | null
  }
  clicks: IChartReport
  delivery: IChartReport
  impression: IChartReport
}

export interface INotificationReport {
  exportUrl: string | null
  goals: GoalNotificationDTO
  send: IChartReport
}

export interface IPushReport extends INotificationReport {
  type: NotificationType.PUSH
  delivery: IChartReport
  pendingDelivery?: IChartReport
  bounce?: IChartReport
  buttonClickOne?: IChartReport
  buttonClickTwo?: IChartReport
  buttonClickThree?: IChartReport
  buttonClickReply?: IChartReport
  dismiss: IChartReport
  impression?: IChartReport
  engagement?: IChartReport
  pushClick: IChartReport
  clicks: IChartReport
}

export interface IEmailReport extends INotificationReport {
  type: NotificationType.EMAIL
  bounce: IChartReport
  clicks: IChartReport
  delivery: IChartReport
  engagement: IChartReport
  linkClick: IChartReport
  links: ILinkReport[]
  open: IChartReport
  unsubscribe: IChartReport
}

export interface IInAppReport extends INotificationReport {
  type: NotificationType.IN_APP
  delivery: IChartReport
  bounce?: IChartReport
  dismiss: IChartReport
  impression: IChartReport
  clicks: IChartReport
  buttonClickOne?: IChartReport
  buttonClickTwo?: IChartReport
  buttonClickThree?: IChartReport
  buttonClick?: IChartReport
  timeOut?: IChartReport
}

export interface ICardReport extends INotificationReport {
  type: NotificationType.CARD
  delivery: IChartReport
  delete?: IChartReport
  clicks: IChartReport
  front: {
    frontButtonClickOne: IChartReport
    frontButtonClickTwo?: IChartReport
    frontButtonClickReply?: IChartReport
    frontImpression: IChartReport
    frontButtonClick?: IChartReport
  }
  back?: {
    backButtonClickOne?: IChartReport
    backButtonClickTwo?: IChartReport
    backButtonClickReply?: IChartReport
    backImpression?: IChartReport
    backButtonClick?: IChartReport
  }
}

export interface ISMSReport extends INotificationReport {
  type: NotificationType.SMS
}
