/* eslint-disable import/prefer-default-export */
import { get, put, post, upload } from '~/api/v2'

import {
  IBeacon,
  IBeaconsDTO,
  IBeaconsQueryParams,
  IBeaconPayload
} from '~/pages/Beacons/Beacons.interface'

export function fetchBeacons(
  appId: string,
  params?: IBeaconsQueryParams
): Promise<IBeaconsDTO> {
  return get(`${appId}/beacons`, params)
}

export function editBeacon(
  appId: string,
  beaconId: string,
  data: IBeaconPayload
): Promise<IBeacon> {
  return put(`${appId}/beacons/${beaconId}`, { beacon: data })
}

export function addBeacon(
  appId: string,
  data: IBeaconPayload
): Promise<IBeacon> {
  return post(`${appId}/beacons`, data)
}

export function exportBeacons(
  appId: string,
  from: string,
  to: string
): Promise<unknown> {
  return get(`${appId}/beacons/export`, { from, to })
}

export function batchAddBeacons(
  appId: string,
  beaconsCsvFile: FormData
): Promise<IBeaconsDTO> {
  return upload(`${appId}/beacons/batch_create`, beaconsCsvFile)
}
