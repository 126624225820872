import { Handle, Position } from 'reactflow'
import { NodeWithData } from '../Store/JourneyBuilder.interface'

export default function ExitNode({ id }: NodeWithData): React.ReactElement {
  return (
    <div className="flow-node--exit" id={id}>
      <Handle type="target" position={Position.Top} className="custom-handle" />
      Exit
    </div>
  )
}
