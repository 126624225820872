import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from 'reactstrap'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import geofenceSectionLogo from './geofence-section-logo.svg'
import { updateGeofenceMetricSettings } from '~/api/appSettings'
import { useStore, withStore } from '~/dataStore'
import { showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'
import ToggleRadioSwitch from '~/components/forms/ToggleRadioSwitch'

import styles from './GeofenceSection.scss'

interface IFormValues {
  geofenceFeetUnit: 'true' | 'false'
}

const GeofenceSection = () => {
  const {
    app: {
      setAppDetails,
      appDetails,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm<IFormValues>({
    values: {
      geofenceFeetUnit:
        appDetails?.geofenceSetting.geofenceFeetUnit.toString() as
          | 'true'
          | 'false'
    }
  })

  const { appId } = useParams<{ appId: string }>()

  const onSubmit = async (data: IFormValues) => {
    try {
      const response = await updateGeofenceMetricSettings(appId, data)
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
      setAppDetails({ geofenceSetting: response })
    } catch (error) {
      showGeneralError()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="ms-auto mt-5">
        <div className="bg-body-main rounded p-4">
          <h3 className="text-white mb-0">Geofence</h3>
        </div>
      </div>
      <div className="mt-4">
        <Card>
          <CardHeader>
            <CardTitle tag="h3" className="mb-0 mt-2">
              Geofence Settings
            </CardTitle>
          </CardHeader>
          <CardBody className="pt-0 pb-4 mt-3">
            <Row>
              <Col xs={7}>
                <div className="d-flex align-items-center">
                  <p className="mb-0">Use</p>
                  <Controller
                    control={control}
                    name="geofenceFeetUnit"
                    register={register}
                    render={({
                      field: { onChange, value },
                      fieldState: { invalid }
                    }) => (
                      <>
                        <ToggleRadioSwitch
                          options={[
                            {
                              value: 'true',
                              name: 'imperial',
                              label: 'Imperial (ft)'
                            },
                            {
                              value: 'false',
                              name: 'metric',
                              label: 'Metric (m)'
                            }
                          ]}
                          className={cn('mx-3', styles.switch, {
                            'error-input': invalid
                          })}
                          onChange={onChange}
                          selected={`${value}`}
                        />
                      </>
                    )}
                  />
                  <p className="mb-0">units</p>
                </div>
                <div className="mt-4">
                  <Button
                    disabled={isSubmitting || isDemoAdmin}
                    color="primary"
                    type="submit"
                    className="d-block mt-2">
                    Save Changes
                    {isSubmitting && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="xs"
                        className="ms-1"
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col
                xs={5}
                className="d-flex align-items-center justify-content-center">
                <img
                  src={geofenceSectionLogo}
                  className="mt-n5"
                  height={128}
                  alt="Geofence Settings"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </form>
  )
}

export default withStore(GeofenceSection)
