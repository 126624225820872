import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCopyright } from '@fortawesome/free-regular-svg-icons'

import moment from 'moment'
import style from './style.scss'

const Footer = (): React.ReactElement => {
  return (
    <footer className={style.footer} aria-label="Footer">
      <FontAwesomeIcon
        icon={faCopyright}
        fixedWidth
        aria-label="Copyright icon"
      />
      <span aria-label={`Year: ${moment().year()}`}>{moment().year()}</span>{' '}
      Pulsate <br />
      <span aria-label="All rights reserved">All rights reserved</span>
    </footer>
  )
}

export default Footer
