import { ReactElement, ReactNode } from 'react'

export default function DynamicContainer({
  tagName,
  children,
  ...otherProps
}: {
  tagName: keyof JSX.IntrinsicElements
  children: ReactNode
  [x: string]: any
}): ReactElement {
  const Tag = tagName
  return <Tag {...otherProps}>{children}</Tag>
}
