import { customRuleTypes, RuleDTO } from '../SegmentBuilder.interface'
import RuleBoolean from './DataType/RuleBoolean'
import RuleDateAgo from './DataType/RuleDateAgo'
import RuleDeviceIP from './Custom/RuleDeviceIP'
import RuleDevicePushPermission from './Custom/RuleDevicePushPermission'
import RuleDeviceType from './Custom/RuleDeviceType'
import RuleLastBeenAt from './Custom/RuleLastBeenAt'
import RuleLastCampaignOpen from './Custom/RuleLastCampaignOpen'
import RuleNumeric from './DataType/RuleNumeric'
import RuleString from './DataType/RuleString'
import SegmentRule from './SegmentRule.model'
import RuleLastInappEvent from './Custom/RuleLastInappEvent'
import RuleLocation from './Custom/RuleLocation'
import RuleDateAgoSignup from './Custom/RuleDateAgoSignup'
import RuleDateAgoLastActive from './Custom/RuleDateAgoLastActive'

interface IProps {
  rule: SegmentRule
}

export default function Rules({ rule }: IProps): React.ReactElement {
  let key: (typeof customRuleTypes)[number] | RuleDTO['type'] = rule.rule.type

  if (customRuleTypes.includes(rule.rule.key)) {
    key = rule.rule.key as (typeof customRuleTypes)[number]
  }

  switch (key) {
    case 'last_campaign_open':
      return <RuleLastCampaignOpen rule={rule} />
    case 'device_type':
      return <RuleDeviceType rule={rule} />
    case 'ip':
      return <RuleDeviceIP rule={rule} />
    case 'device_push_permission':
    case 'device_location_permission':
      return <RuleDevicePushPermission rule={rule} />
    case 'last_been_at':
    case 'number_of_visits':
      return <RuleLastBeenAt rule={rule} />
    case 'last_in_app_event':
    case 'number_of_event_clicks':
    case 'event_recorded':
      return <RuleLastInappEvent rule={rule} />
    case 'all_users':
      return <></>
    case 'location':
      return <RuleLocation rule={rule} />
    case 'string':
      return <RuleString rule={rule} />
    case 'numeric':
    case 'integer':
      return <RuleNumeric rule={rule} />
    case 'date_ago':
      return <RuleDateAgo rule={rule} />
    case 'signup':
      return <RuleDateAgoSignup rule={rule} />
    case 'last_active':
      return <RuleDateAgoLastActive rule={rule} />
    case 'boolean':
      return <RuleBoolean rule={rule} />
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = key
      throw new Error(exhaustiveCheck)
  }
}
