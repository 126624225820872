import { ReactElement } from 'react'
import InappNotificationBuilder from '~/pages/Campaign/Notification/InAppNotification/UI/InappNotificationBuilder'
import { useStore, withStore } from '~/dataStore'

function InAppStep(): ReactElement {
  const {
    campaign: { isCardInAppCampaign, inApp, isSended }
  } = useStore()

  return (
    <InappNotificationBuilder
      inApp={inApp}
      isCardInAppCampaign={isCardInAppCampaign}
      isSended={isSended}
      colsWidth={{ left: { xxl: { size: 8 } }, right: { xxl: { size: 4 } } }}
    />
  )
}

export default withStore(InAppStep)
