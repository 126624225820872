import React from 'react'
import { Controls, ControlButton, useReactFlow } from 'reactflow'
import zoomInIcon from '~/components/icons/zoom_in_magnifying_glass.svg'
import zoomOutIcon from '~/components/icons/zoom_out_magnifying_glass.svg'

const JourneyBuilderControls = (): React.ReactElement => {
  const { zoomIn, zoomOut } = useReactFlow()
  return (
    <Controls showInteractive={false} showZoom={false} showFitView={false}>
      <ControlButton onClick={() => zoomIn({ duration: 350 })}>
        <img src={zoomInIcon} alt="Zoom in" />
      </ControlButton>
      <ControlButton onClick={() => zoomOut({ duration: 350 })}>
        <img src={zoomOutIcon} alt="Zoom out" />
      </ControlButton>
    </Controls>
  )
}

export default JourneyBuilderControls
