import { useEffect } from 'react'
import { Container } from 'reactstrap'
import Header from '~/components/Header'
import { useStore, withStore } from '~/dataStore'
import EngagementSummary from './EngagementSummary'
import useMatchMedia from '~/hooks/useMatchMedia'
import PerformanceRow from './PerformanceRow'
import NextBestOpportunities from './NextBestOpportunities/NextBestOpportunities'

import './OpportunitiesDashboard.scss'

const OpportunitiesDashboard = () => {
  const {
    ui: { setShowSidebar },
    app: {
      currentAdmin: { companies }
    }
  } = useStore()

  const isLaptop = useMatchMedia('(max-width: 1280px)')
  useEffect(() => {
    if (isLaptop) {
      setShowSidebar(false)
    }

    return () => {
      setShowSidebar(true)
    }
  }, [setShowSidebar, isLaptop])

  return (
    <div className="opportunities-dashboard-page">
      <Container fluid>
        <Header>
          <p className="mb-0 text-24 text-black">{companies?.[0].name}</p>
          <h1 className="text-black fs-1">Opportunities Engine</h1>
        </Header>
        <div className="mt-6">
          <EngagementSummary />
        </div>

        <section className="mt-6 pt-5">
          <NextBestOpportunities />
        </section>

        <section className="mt-6 pt-5">
          <h2>Performance</h2>
          <PerformanceRow />
        </section>
      </Container>
    </div>
  )
}

export default withStore(OpportunitiesDashboard)
