import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import React, { useRef, useState } from 'react'
import { uniqueId } from 'lodash'
import { Preview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { fetchCampaignTemplatePreview } from '~/api/campaigns'
import {
  getCampaignTypeOptions,
  getPreviewByOptionType
} from '~/dataStore/CampaignTypeFacade'
import PreviewPopover from '~/pages/Campaign/Notification/Preview/PreviewPopover'
import { useStore } from '~/dataStore'
import { ID } from '~/common.interface'

const PreviewBtn = ({ campaignId }: { campaignId: ID }) => {
  const {
    app: {
      currentApp: { id }
    }
  } = useStore()
  const targetId = useRef(uniqueId('campaign-preview-'))

  const [preview, setPreview] = useState<Preview>()

  async function getPreview(): Promise<void> {
    if (preview) {
      return
    }

    try {
      const response = await fetchCampaignTemplatePreview(id, campaignId)
      const type = getCampaignTypeOptions(response.type, response)
      setPreview({
        ...response,
        type
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Button
        color=""
        data-testid="previewTemplateBtn"
        className="p-0 btn-outline-body d-flex align-items-center"
        onMouseEnter={() => getPreview()}>
        <FontAwesomeIcon
          id={targetId.current}
          icon={faEye}
          className="px-2 py-2"
        />
        <PreviewPopover
          target={targetId.current}
          campaignType={preview?.type[0].original}
          parts={
            preview?.type[0] && getPreviewByOptionType(preview.type[0], preview)
          }
          size="sm"
          deviceType="ios"
        />
      </Button>
    </>
  )
}

export default PreviewBtn
