import React from 'react'
import cn from 'classnames'
import { DeviceType } from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  buttons: Array<{ label: string; txtColor: string; btnColor: string }>
  deviceType: DeviceType
  onClick?: () => void
}

const emojis = [
  'emojiupvote',
  'emojidownvote',
  'emojilike',
  'emojineutral',
  'emojidislike'
]

export default function ActionButtons({
  buttons,
  deviceType,
  onClick
}: IProps): React.ReactElement {
  return (
    <div
      className={cn('action-btns', `action-btns--${deviceType}`, {
        'cursor-pointer': !!onClick
      })}
      onClick={onClick}>
      {buttons.length > 0 &&
        buttons.map((button) => (
          <div key={button.label} className="action-btns__item">
            {emojis.includes(button.label.toLowerCase()) ? (
              <span
                className={`action-btns__icon icon icon--${button.label.toLowerCase()}`}
              />
            ) : (
              button.label
            )}
          </div>
        ))}
    </div>
  )
}
