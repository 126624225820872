import React from 'react'
import LineChart from '~/components/ApexCharts/ApexLineChart'
import StatCard from '~/components/reports/StatCard'
import { IChartData } from '~/dataStore/reports/Report.interface'

interface IProps {
  charts: IChartData[]
}

export default React.memo(function CampaignPerformance({
  charts
}: IProps): React.ReactElement {
  return (
    <StatCard title="Campaign Performance">
      <LineChart chartData={charts} />
    </StatCard>
  )
})
