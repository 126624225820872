import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  fetchEngagementSummary,
  fetchTopPerformance,
  fetchTopNotifications,
  exportAppOpps
} from '~/api/opportunitiesDashboard'
import { ID } from '~/common.interface'
import {
  IPerformanceTopCampaign,
  IPerformanceTopNotifications
} from './OpportunitiesDashboard.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import { showGeneralError } from '~/utils/validations'
import { useStore } from '~/dataStore'
import { getRequest } from '~/api/requests'

export enum ServerStateKeysEnum {
  EngagementSummary = 'engagementSummary',
  CampaignsPerformance = 'campaignsPerformance',
  NotificationsPerformance = 'notificationsPerformance'
}

export const useGetEngagementSummary = (
  appId: ID,
  queryParams: { range: number | undefined }
) => {
  return useQuery(
    [ServerStateKeysEnum.EngagementSummary, { ...queryParams }],
    () => fetchEngagementSummary(appId, { ...queryParams }),
    {
      enabled: queryParams.range !== undefined,
      refetchOnWindowFocus: true
    }
  )
}

export const useGetTopCampaigns = (
  appId: ID
): UseQueryResult<IPerformanceTopCampaign[]> => {
  return useQuery(
    [ServerStateKeysEnum.CampaignsPerformance],
    () => fetchTopPerformance(appId),
    {
      refetchOnWindowFocus: false,
      placeholderData: () => [{}, {}, {}, {}, {}]
    }
  )
}

export const useGetTopNotifications = (
  appId: ID
): UseQueryResult<IPerformanceTopNotifications[]> => {
  return useQuery(
    [ServerStateKeysEnum.NotificationsPerformance],
    () => fetchTopNotifications(appId),
    {
      refetchOnWindowFocus: false,
      placeholderData: () => [{}, {}, {}, {}, {}]
    }
  )
}

export const useExportAppOops = () => {
  const {
    app: {
      appDetails: { downloadOpportunityFiles },
      setAppDetails,
      currentAdmin: { email },
      currentApp: { id: appId }
    }
  } = useStore()

  return useMutation(
    (data: { range: number | undefined }) => exportAppOpps(appId, data.range),
    {
      onSuccess: () => {
        showNotification(
          `Your export has started. We'll email ${email} when it's completed.`,
          NotificationType.SUCCESS
        )
        setAppDetails({
          downloadOpportunityFiles: {
            ...downloadOpportunityFiles,
            appFile: false
          }
        })
      },
      onError: () => {
        showGeneralError()
      }
    }
  )
}

export const useExportPerformanceOops = () => {
  const {
    app: {
      appDetails: { downloadOpportunityFiles },
      setAppDetails,
      currentAdmin: { email }
    }
  } = useStore()

  return useMutation(
    (data: { exportPath: string }) => getRequest(data.exportPath),
    {
      onSuccess: () => {
        showNotification(
          `Your export has started. We'll email ${email} when it's completed.`,
          NotificationType.SUCCESS
        )
        setAppDetails({
          downloadOpportunityFiles: {
            ...downloadOpportunityFiles,
            performanceFiles: false
          }
        })
      },
      onError: () => {
        showGeneralError()
      }
    }
  )
}
export default useGetEngagementSummary
