import { PropsWithChildren } from 'react'
import cn from 'classnames'
import { Collapse } from 'reactstrap'
import { toggle } from './style.scss'
import SingleRadio from '../Radio/SingleRadio.field'

interface IProps {
  name: string
  opened: boolean
  checked: boolean
  id: string
  radioName: string
  onTitleClick: (isOpened: boolean) => void
  isValid?: boolean
  className?: string
}

export default function RadioCollapse({
  name,
  onTitleClick,
  className,
  children,
  opened,
  isValid,
  checked,
  radioName,
  id
}: PropsWithChildren<IProps>): React.ReactElement {
  function handleTitleClick(): void {
    onTitleClick(!opened)
  }

  return (
    <>
      <div className={cn(`d-flex justify-content-between align-items-center `)}>
        <button
          onClick={handleTitleClick}
          type="button"
          className={cn(
            `w-100 text-capitalize btn btn--transparent text-start ps-0 ${
              !isValid ? 'error-label' : ''
            }`,
            toggle,
            className
          )}>
          {name}
        </button>
        <SingleRadio
          name={radioName}
          value={id}
          onChange={handleTitleClick}
          checked={checked}
          id={id}
        />
      </div>
      <Collapse isOpen={opened}>{children}</Collapse>
    </>
  )
}
