import React from 'react'
import { components } from 'react-select'
import SingleRadio from '~/components/forms/Radio/SingleRadio.field'

interface IProps {
  isDisabled: boolean
  isFocused: boolean
  isSelected: boolean
  label: string
  innerProps: {
    id: string
    key: string
    onClick: React.MouseEventHandler<HTMLDivElement>
    onMouseMove: React.MouseEventHandler<HTMLDivElement>
    onMouseOver: React.MouseEventHandler<HTMLDivElement>
    tabIndex: number
  }
  children: React.ReactNode
  getStyles: (name: string, props: any) => Record<string, unknown>
}

const RadioOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  label,
  children,
  innerProps,
  ...rest
}: IProps): React.ReactElement => {
  let bg = 'transparent'
  if (isFocused) bg = '#DEEBFF'

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex '
  }

  const props = {
    ...innerProps,
    style
  }

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}>
      {label !== 'Show All' && (
        <SingleRadio name="" checked={isSelected} className="me-2" />
      )}
      {children}
    </components.Option>
  )
}

export default RadioOption
