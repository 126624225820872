import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ExportByDate from '~/components/ExportByDate/ExportByDate'
import { useStore, withStore } from '~/dataStore'
import useGeofencesStore from '../Geofences.context'

const GeofencesActions = () => {
  const { exportGeofences } = useGeofencesStore()
  const {
    ui: { showModal }
  } = useStore()

  return (
    <div className="geofences__actions d-flex ms-auto">
      <ExportByDate onSubmit={exportGeofences} label="Export All" />
      <Button
        color="primary"
        className="btn--hover btn--wide"
        size="lg"
        onClick={() => showModal('batchUploadModal')}>
        <span>
          <FontAwesomeIcon icon={faPlus} size="xs" className="me-1" /> Bulk
          Upload
        </span>
      </Button>
    </div>
  )
}

export default withStore(GeofencesActions)
