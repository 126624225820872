import { ReactElement, useEffect } from 'react'
import { useStore, withStore } from '~/dataStore'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import CardNotificationBuilder from '~/pages/Campaign/Notification/CardNotification/UI/CardNotificationBuilder'

interface IProps {
  type: CampaignType.CARD_FRONT | CampaignType.CARD_BACK
}

function CardStep({ type }: IProps): ReactElement {
  const {
    campaign: { inApp, card, isSended, toggleCardBackStore }
  } = useStore()

  useEffect(() => {
    if (inApp?.isSmallPersonal()) {
      card.cardFront.fillAdmin(
        inApp.notification.adminHeader.value,
        inApp.notification.admin
      )
    }
  }, [inApp?.notification.adminHeader.value, inApp?.notification.admin])

  return (
    <CardNotificationBuilder
      adminHeaderEditable={!inApp?.isSmallPersonal()}
      card={card}
      type={type}
      isSended={isSended}
      toggleCardBackStore={toggleCardBackStore}
      withEvent
      colsWidth={{ left: { xxl: { size: 8 } }, right: { xxl: { size: 4 } } }}
    />
  )
}

export default withStore(CardStep)
