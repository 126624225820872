import React from 'react'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'
import IconButton from '~/components/IconButton'
import { handleLongName } from '~/utils/string'

interface IProps {
  id: string
  name: string
  setEditGroup: () => void
  setDeleteGroup: () => void
}

function GroupsOption(props: IProps) {
  const { id, name, setEditGroup, setDeleteGroup } = props
  return (
    <div className="d-inline-flex justify-content-start align-items-center w-100">
      <span className="pe-3">{handleLongName(name, 15)}</span>
      <IconButton
        icon={faPen}
        onClick={() => setEditGroup(props)}
        className="ms-auto me-1"
        size="1x"
        id={`edit-${id}`}
        ariaLabel={`Edit group ${name}`}
      />
      <UncontrolledTooltip placement="top" target={`edit-${id}`}>
        Edit
      </UncontrolledTooltip>
      <IconButton
        onClick={() => setDeleteGroup(props)}
        icon={faTrash}
        size="1x"
        id={`delete-${id}`}
        ariaLabel={`Delete group ${name}`}
      />
      <UncontrolledTooltip placement="top" target={`delete-${id}`}>
        Delete
      </UncontrolledTooltip>
    </div>
  )
}

export default GroupsOption
