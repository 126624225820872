import { ReactElement } from 'react'
import cn from 'classnames'

interface IProps {
  editable?: boolean
  mergeTag: string
  defaultTag?: string
}

export default function MergeTagLabel({
  editable,
  mergeTag,
  defaultTag
}: IProps): ReactElement {
  return (
    <span
      contentEditable={false}
      className={cn('preview__tag', {
        'preview__tag--editable': editable
      })}>
      <span className="d-none">{'{{'}</span>
      {mergeTag}
      <span className="d-none">{`${
        defaultTag ? ` | default: ${defaultTag}` : ''
      }}}`}</span>
    </span>
  )
}
