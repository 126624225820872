import React, { useRef } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import color from '~/utils/color'
import { BatteryStatus } from '../../Beacons.interface'

import './BatteryStatusDot.scss'

const statusColorsAndDescription: {
  [x in BatteryStatus]: { color: string; description: string }
} = {
  active: {
    color: color.info,
    description: 'Last Recorded Beacon activity was less than 7 days ago.'
  },
  check: {
    color: color.warning,
    description: 'Last Recorded Beacon activity was less than 7-14 days ago.'
  },
  inactive: {
    color: color.danger,
    description: 'Last Recorded Beacon activity was more than 14 days ago.'
  }
}

const BatteryStatusDot = ({
  batteryStatus,
  slim
}: {
  batteryStatus: BatteryStatus
  slim?: boolean
}): React.ReactElement => {
  const batteryStatusRef = useRef(null)

  return (
    <>
      <div
        ref={batteryStatusRef}
        className={`beacons-battery-status-dot ${
          slim ? 'beacons-battery-status-dot--slim' : ''
        }`}
        style={{
          backgroundColor:
            statusColorsAndDescription[batteryStatus?.toLowerCase()].color
        }}
      />
      <UncontrolledTooltip target={batteryStatusRef}>
        {statusColorsAndDescription[batteryStatus?.toLowerCase()].description}
      </UncontrolledTooltip>
    </>
  )
}

export default BatteryStatusDot
