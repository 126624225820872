import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from '@tanstack/react-query'
import {
  fetchIntegrationGoalFiles,
  uploadIntegrationGoalFile
} from '~/api/appSettings'
import { ID } from '~/common.interface'
import { showGeneralError } from '~/utils/validations'
import { IntegrationFilesDTO } from './IntegrationGoalFiles.interface'

export enum ServerStateKeysEnum {
  IntegrationGoalFiles = 'integrationGoalFiles'
}

export default function useIntegrationGoalFiles(
  appId: ID
): UseQueryResult<IntegrationFilesDTO['data']> {
  return useQuery<IntegrationFilesDTO, unknown, IntegrationFilesDTO['data']>(
    [ServerStateKeysEnum.IntegrationGoalFiles],
    () => fetchIntegrationGoalFiles(appId),
    {
      staleTime: Infinity,
      select: (data) => {
        return data.data
      }
    }
  )
}

export function useUploadFile(
  appId: ID
): UseMutationResult<unknown, unknown, File> {
  const cache = useQueryClient()
  return useMutation({
    mutationFn: (file: File) => uploadIntegrationGoalFile(appId, file),
    onSuccess: () => {
      cache.invalidateQueries([ServerStateKeysEnum.IntegrationGoalFiles])
    },
    onError: (error) => {
      showGeneralError(error.body?.error)
    }
  })
}
