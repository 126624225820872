import { ApexOptions } from 'apexcharts'

export const GREEN_CHART_OPTIONS = {
  fill: {
    opacity: 1,
    gradient: {
      type: 'vertical',
      gradientToColors: ['#C050BA80'],
      inverseColors: true,
      shadeIntensity: 1,
      colorStops: [
        {
          offset: 0,
          color: '#5ABF9D4D',
          opacity: 1
        },
        {
          offset: 50,
          color: '#5ABF9D4D',
          opacity: 1
        },
        {
          offset: 75,
          color: '#5ABF9D4D',
          opacity: 0.7
        },
        {
          offset: 100,
          color: '#5ABF9D4D',
          opacity: 0.5
        }
      ]
    }
  },
  markers: {
    colors: ['white'],
    strokeColors: '#5ABF9D',
    strokeWidth: 3
  },
  stroke: {
    width: 3,
    curve: 'smooth',
    lineCap: 'round',
    colors: ['#349776']
  }
}

export const DEFAULT_OPTIONS: ApexOptions = {
  colors: ['#914AB8', '#6643B4'],
  chart: {
    type: 'area',
    width: 100,
    sparkline: {
      enabled: true
    }
  },
  stroke: {
    width: 3,
    curve: 'smooth',
    lineCap: 'round',
    colors: ['#984EB4']
  },
  markers: {
    colors: ['white'],
    strokeColors: '#914AB8',
    strokeWidth: 3
  },
  fill: {
    opacity: 1,
    gradient: {
      type: 'vertical',
      gradientToColors: ['#C050BA80'],
      inverseColors: true,
      shadeIntensity: 1,
      colorStops: [
        {
          offset: 0,
          color: '#C050BA80',
          opacity: 1
        },
        {
          offset: 50,
          color: '#C050BA80',
          opacity: 1
        },
        {
          offset: 75,
          color: '#C050BA80',
          opacity: 0.7
        },
        {
          offset: 100,
          color: '#C050BA80',
          opacity: 0.5
        }
      ]
    }
  },
  xaxis: {
    crosshairs: {
      width: 1
    },
    categories: []
  },
  tooltip: {
    theme: 'shadow',
    custom: undefined
  },
  yaxis: {
    min: 0
  }
}
