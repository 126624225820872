import React, { useState } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { NotificationType, showNotification } from '~/utils/Notification'
import { updateAccessTokens } from '~/api/admins'
import { ID } from '~/common.interface'
import apiIcon from '~/components/icons/api.svg'
import { useStore } from '~/dataStore'

const AccountSettingsApiSection = ({
  token,
  onSuccess,
  appId
}: {
  token: string
  onSuccess: ({ adminAccessToken }: { adminAccessToken: string }) => void
  appId: ID
}): React.ReactElement => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async () => {
    try {
      setIsLoading(true)
      const { message, adminAccessToken } = await updateAccessTokens(appId)
      showNotification(message, NotificationType.SUCCESS)
      onSuccess({ adminAccessToken })
    } catch (error) {
      showNotification(
        'Failed to update admin access token',
        NotificationType.ERROR
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="w-100">
        <Row>
          <Col xs={9}>
            <p className="my-2">Admin Access Token:</p>
            <p className="text-muted">Token token={token}</p>
          </Col>
          <Col xs={3} className="mb-4 ps-0 position-relative">
            <img
              src={apiIcon}
              alt="Admin Access Token"
              className="mt-n3 me-3 pe-3 position-absolute"
            />
          </Col>
        </Row>
      </div>
      <Button
        disabled={isLoading || isDemoAdmin}
        onClick={onSubmit}
        color="primary"
        className="d-block mt-4">
        Generate New
      </Button>
    </>
  )
}

export default AccountSettingsApiSection
