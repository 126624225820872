import React from 'react'
import './InAppIcon.scss'

const InAppIcon = ({
  className
}: {
  className: string
}): React.ReactElement => (
  <svg
    id="inAppAnimatedIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 136"
    width={72}
    height={136}
    className={className}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <rect
      width="70"
      height="134"
      rx="7"
      ry="7"
      transform="translate(1 1)"
      fill="#fff"
      stroke="#93a8c5"
      strokeWidth="2"
    />
    <rect
      width="58"
      height="102"
      rx="0"
      ry="0"
      transform="translate(7 17)"
      fill="#28518c"
      fillOpacity="0.3"
      stroke="#93a8c5"
      strokeWidth="2"
    />
    <g id="animatedInAppIcon1_to" transform="translate(90,26.5)">
      <line
        id="animatedInAppIcon1"
        x1="41"
        y1="26.5"
        x2="59"
        y2="26.5"
        transform="translate(-50,-26.5)"
        opacity="0"
        fill="none"
        stroke="#93a8c5"
      />
    </g>
    <g id="animatedInAppIcon2_to" transform="translate(36,103.5)">
      <g id="animatedInAppIcon2_ts" transform="scale(0.6,0.6)">
        <g id="animatedInAppIcon2" transform="translate(-36,-82.5)" opacity="0">
          <rect
            width="45"
            height="56"
            rx="0"
            ry="0"
            transform="translate(13.5 54.5)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <rect
            width="45"
            height="20"
            rx="0"
            ry="0"
            transform="translate(13.5 54.5)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <rect
            id="animatedInAppIcon3"
            width="39"
            height="9"
            rx="2.5"
            ry="2.5"
            transform="translate(16.5 97.5)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="21"
            y1="80.5"
            x2="49"
            y2="80.5"
            fill="none"
            stroke="#93a8c5"
          />
          <line
            x1="18"
            y1="85.5"
            x2="53"
            y2="85.5"
            fill="none"
            stroke="#93a8c5"
          />
        </g>
      </g>
    </g>
    <g id="animatedInAppIcon4_to" transform="translate(-5,27)">
      <circle
        id="animatedInAppIcon4"
        r="3.5"
        transform="translate(0,0)"
        opacity="0"
        fill="#93a8c5"
        stroke="#93a8c5"
      />
    </g>
    <g id="animatedInAppIcon5" transform="translate(0 1)" opacity="0">
      <circle
        r="4"
        transform="translate(54.5 48.5)"
        fill="#fff"
        stroke="#93a8c5"
      />
      <g clipPath="url(#animatedInAppIcon6)">
        <g>
          <path
            d="M55.4134,48.6042l.995-.9121c.1221-.112.1221-.2934,0-.4054l-.2211-.2028c-.1221-.1119-.3201-.1119-.4423,0l-.995.9121-.995-.9121c-.1221-.1119-.3201-.1119-.4423,0l-.2211.2028c-.1221.1119-.1221.2934,0,.4054l.995.9121-.995.9121c-.1221.1119-.1221.2934,0,.4054l.2211.2027c.1221.1119.3202.1119.4423,0l.995-.9121.995.9121c.1221.1119.3202.1119.4423,0l.2211-.2027c.1221-.1119.1221-.2934,0-.4054l-.995-.9121Z"
            fill="#93a8c5"
          />
        </g>
        <clipPath id="animatedInAppIcon6">
          <rect
            width="3.5"
            height="4.66667"
            rx="0"
            ry="0"
            transform="translate(53 47)"
            fill="#fff"
          />
        </clipPath>
      </g>
    </g>
    <g id="animatedInAppIcon7" opacity="0">
      <rect
        width="17"
        height="5"
        rx="2.5"
        ry="2.5"
        transform="translate(12.5 35.5)"
        fill="#93a8c5"
        stroke="#93a8c5"
      />
      <rect
        width="17"
        height="5"
        rx="2.5"
        ry="2.5"
        transform="translate(32.5 35.5)"
        fill="#93a8c5"
        stroke="#93a8c5"
      />
      <path
        d="M56,35.5h7.5v5h-7.5c-1.3807,0-2.5-1.1193-2.5-2.5s1.1193-2.5,2.5-2.5Z"
        fill="#93a8c5"
        stroke="#93a8c5"
      />
    </g>
  </svg>
)

export default InAppIcon
