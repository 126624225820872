import React, { useRef } from 'react'
import cn from 'classnames'
import moment from 'moment'
import IconCalendar from '~/components/icons/IconCalendar'
import CustomDropdown from '~/components/CustomDropdown'
import RangeDatepicker from './RangeDatePicker'

import './style.scss'

interface IProps {
  startDateFilter: Date
  endDateFilter: Date
  setFilter: ({
    startDate,
    endDate
  }: {
    startDate: Date
    endDate: Date
  }) => void
  disabled?: boolean
}

export default function RangeDatePickerDropdown({
  startDateFilter,
  endDateFilter,
  setFilter,
  disabled = false
}: IProps): React.ReactElement {
  const minDate = useRef(startDateFilter)
  const maxDate = useRef(endDateFilter)

  return (
    <CustomDropdown
      right
      withoutModifiers
      toggleAsButton
      dropdownToggleClassname={cn('btn--wide')}
      wrapperClassname="range-datepicker-dropdown"
      disabled={disabled}
      dropdownToggle={
        <span className="d-flex align-items-center">
          <IconCalendar className="range-datepicker-dropdown__icon" />
          {`${moment(startDateFilter).format('DD MMM')} - ${moment(
            endDateFilter
          ).format('DD MMM')}`}
        </span>
      }
      options={[
        {
          name(toggleDropdown) {
            return (
              <RangeDatepicker
                startDateFilter={startDateFilter}
                endDateFilter={endDateFilter}
                setFilter={setFilter}
                toggleDropdown={toggleDropdown}
                minDate={minDate.current}
                maxDate={maxDate.current}
              />
            )
          },
          isCustom: true
        }
      ]}
    />
  )
}
