import React from 'react'
import { Button, ModalBody } from 'reactstrap'
import Modal from '~/components/modals/Modal/Modal'
import { withStore, useStore } from '~/dataStore'

interface IProps {
  onSubmit: (groupId: string) => void
}

const AddElementsToGroupModal = ({ onSubmit }: IProps) => {
  const {
    groups: { groups },
    ui: { showModal }
  } = useStore()

  return (
    <Modal
      id="addElementsToGroup"
      size="sm"
      className="add-to-group-modal"
      renderHeader={() => 'Choose Group'}
      render={(close: () => void) => (
        <ModalBody className="m-3">
          {groups.length === 0 && (
            <div>
              <p className="text-muted text-center">No groups</p>
              <Button
                onClick={() => showModal('createGroupModal')}
                color="primary"
                data-testid="createGroupNameBtn"
                className="mx-3 my-2 w-100 text-nowrap">
                + Create Group Name
              </Button>
            </div>
          )}
          {groups?.map((g) => (
            <Button
              color="primary"
              key={g.id}
              onClick={() => onSubmit(g.id)}
              className="m-2 mw-100 text-truncate">
              {g.name}
            </Button>
          ))}
        </ModalBody>
      )}
    />
  )
}

export default withStore(AddElementsToGroupModal)
