import React, { useState } from 'react'
import cn from 'classnames'

import { Input, Collapse } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import ToggleSwitch from '~/components/forms/ToggleSwitch'
import blockNegativeVal from '~/utils/blockNegativeVal'

const ControlGroupForm = () => {
  const [descriptionVisible, setDescriptionVisible] = useState(false)

  const {
    campaign: {
      isSended,
      setUp: { controlGroup, setControlGroupActive, validateControlGroup }
    }
  } = useStore()

  function labelClasses() {
    const classes = ['ms-2', 'ps-1']
    if (!isSended) {
      classes.push('cursor-pointer')
    }

    return classes.join(' ')
  }

  return (
    <>
      <div className="d-flex align-items-center mt-3 pt-2">
        <ToggleSwitch
          disabled={isSended}
          checked={controlGroup.active}
          onChange={(value) => {
            setControlGroupActive(value)
            validateControlGroup()
          }}
          controlId="toggleInput"
        />
        &nbsp;
        <label htmlFor="toggleInput" className={labelClasses()}>
          Enable Control Group
        </label>
      </div>
      <Collapse isOpen={!!controlGroup.active}>
        <div className="mt-3 d-flex align-items-center">
          <span className="o-50 text-dark">Do not send this campaign to</span>
          <Input
            disabled={isSended}
            type="number"
            max={80}
            min={1}
            className={`mx-3 text-center ${
              !controlGroup.quantity.isValid ? 'error-input' : ''
            }`}
            style={{ width: 65 }}
            onBlur={validateControlGroup}
            value={controlGroup.quantity.value || ''}
            onKeyDown={blockNegativeVal}
            onChange={(e) => {
              let val: number | undefined
              if (e.target.value) {
                val = parseInt(e.target.value, 10)
              }
              controlGroup.quantity.setValue(val)
            }}
          />
          <span className="o-50 text-dark">% of eligible users</span>
        </div>
      </Collapse>
      <button
        type="button"
        onClick={() => {
          setDescriptionVisible((prev) => !prev)
        }}
        className={cn(
          { 'o-50': !descriptionVisible },
          'btn p-0 mt-3 text-dark'
        )}>
        Why do you need a control group?
      </button>
      <Collapse isOpen={descriptionVisible}>
        <p className="o-50 text-dark mt-3">
          A control group, is a subset of the customers we are targeting with a
          particular campaign, who will not receive the campaign communication.
          Control group serves as a baseline for experiment group to compare
          performance of communication. When one compares the variation with
          control, one can check the impact of campaign.
        </p>
      </Collapse>
    </>
  )
}

export default withStore(ControlGroupForm)
