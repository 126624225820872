import React, { useRef } from 'react'
import { Polygon } from '@react-google-maps/api'
import { POLYGON_OPTIONS } from '~/pages/Geofences/consts'
import { IGeofence } from '~/pages/Geofences/Geofences.interface'
import { getPolygonPath, getPolygonCenter } from '~/pages/Geofences/utils'
import PolygonInfoWindow from './PolygonInfoWindow'
import { withStore } from '~/dataStore'

interface IProps {
  geofence: IGeofence
  selectGeofence: (id: string) => void
  setInfoWindowOpen: (value: boolean) => void
  removeGeofence: (geofence: IGeofence) => void
  saveGeofence: (geofence: IGeofence) => void
  isGeofenceSaving: boolean
  showInfoWindow: boolean
  map: google.maps.Map | null
}

const PolygonGeofence = ({
  geofence,
  selectGeofence,
  showInfoWindow,
  removeGeofence,
  setInfoWindowOpen,
  saveGeofence,
  isGeofenceSaving,
  map
}: IProps): React.ReactElement => {
  const polygonRef = useRef<Polygon>(null)

  const onPolygonClick = (e: google.maps.MapMouseEvent, g: IGeofence) => {
    selectGeofence(g.id)
  }

  const onPolygonDragEnd = () => {
    if (polygonRef.current !== null) {
      const { polygon } = polygonRef.current.state
      const path = getPolygonPath(polygon || null!)
      const payload = {
        ...geofence,
        location: [getPolygonCenter(path).lat, getPolygonCenter(path).lng],
        path
      }
      saveGeofence(payload)
      selectGeofence(geofence.id)
      map?.panTo(
        new google.maps.LatLng(
          getPolygonCenter(path).lat,
          getPolygonCenter(path).lng
        )
      )
      setInfoWindowOpen(true)
    }
  }

  return (
    <>
      <Polygon
        ref={polygonRef}
        onDragEnd={onPolygonDragEnd}
        onClick={(e) => onPolygonClick(e, geofence)}
        path={geofence?.path?.map((path) => ({ lat: path[0], lng: path[1] }))}
        onDragStart={() => setInfoWindowOpen(false)}
        options={{
          ...POLYGON_OPTIONS,
          editable: geofence.isEditable,
          draggable: geofence.isDraggable,
          fillColor: geofence.color,
          strokeColor: geofence.color
        }}
      />
      {showInfoWindow && (
        <PolygonInfoWindow
          isGeofenceSaving={isGeofenceSaving}
          saveGeofence={saveGeofence}
          removeGeofence={removeGeofence}
          geofence={geofence}
        />
      )}
    </>
  )
}

export default withStore(PolygonGeofence)
