import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { toJS } from 'mobx'
import EmailEditor, { ExportHtmlCallback } from 'react-email-editor'
import IEmailEditor, {
  EditorData,
  MergeTag,
  TestEmailData,
  UnlayerImage,
  UserImage
} from './UnlayerEditor.interface'
import SendTestEmailModal from './components/SendTestEmailModal'
import SaveTemplateModal from './components/SaveTemplateModal'
import ImageUploadModal from './components/ImageUploadModal'
import blankTemplate from '~/pages/CampaignBuilder/Email/blankEmailTemplate.json'
import Modal from '~/components/modals/Modal/Modal'
import HeaderTitle from '~/components/HeaderTitle'
import Header from '~/components/Header'
import { useStore } from '~/dataStore'
import { destroy, get } from '~/api/v2'
import './UnlayerEditor.scss'
// eslint-disable-next-line import/no-webpack-loader-syntax
import customCSS from '!!css-loader?{"sourceMap":false,"exportType":"string"}!./UnlayerEditor.css'
import { IEmailTemplate } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import MergeTagsModal from '~/components/modals/MergeTagsModal'

const getUnlayerOptions = (mergeTags: MergeTag[], appId: string) => ({
  customCSS,
  specialLinks: [
    {
      name: 'Unsubscribe link',
      href: '$opt-out$',
      target: '_self'
    }
  ],
  mergeTags,
  features: {
    smartMergeTags: false
  },
  user: { id: appId },
  tabs: {
    body: {
      position: 1,
      active: true
    },
    blocks: {
      position: 2
    },
    content: {
      position: 3
    },
    images: {
      position: 4
    }
  }
})

interface IUnlayerEditorProps {
  designChangedCallback: ExportHtmlCallback
  testEmailData: TestEmailData
  editorData: EditorData
  currentTemplate: IEmailTemplate | null
  isBlankTemplate: boolean
  mergeTags: string[]
  setCurrentTemplate: (template: IEmailTemplate) => void
  saveTemplateCallback?: () => void
  setBeforeExitStep?: (beforeExitAction: () => void) => void
}

const UnlayerEditor = ({
  currentTemplate,
  designChangedCallback,
  editorData,
  isBlankTemplate,
  mergeTags,
  setCurrentTemplate,
  saveTemplateCallback,
  testEmailData,
  setBeforeExitStep
}: IUnlayerEditorProps): React.ReactElement => {
  const emailEditorRef = useRef<IEmailEditor>(null)
  const [loaded, setLoaded] = useState(false)

  const {
    app: { openCurtain },
    ui: { showModal, unregisterAllModals }
  } = useStore()

  const { appId }: { appId: string } = useParams()

  useEffect(() => {
    openCurtain()
    return () => {
      unregisterAllModals()
    }
  }, [openCurtain])

  const handleImageDeletion = async (image: UnlayerImage, done: () => void) => {
    await destroy(`${appId}/email_images/${image.id}`)
    done()
  }

  const showImageUploadModal = (
    data: Record<string, unknown>,
    done: (imageUrl: string) => void
  ) => {
    showModal('imageUploadModal', { done })
  }

  const onReadyEditor = useCallback(() => {
    if (emailEditorRef?.current?.editor && !loaded) {
      emailEditorRef.current.addEventListener('design:loaded', () => {
        emailEditorRef.current?.exportHtml(editorData.setData)
        setLoaded(true)
      })

      const design = editorData.data?.design
        ? toJS(editorData.data?.design)
        : blankTemplate.design

      emailEditorRef.current.editor.loadDesign(design)

      emailEditorRef.current.addEventListener('design:updated', () => {
        emailEditorRef.current?.exportHtml(designChangedCallback)
      })

      emailEditorRef.current.registerCallback(
        'image:removed',
        handleImageDeletion
      )
      emailEditorRef.current.registerCallback(
        'selectImage',
        showImageUploadModal
      )
      emailEditorRef.current.registerCallback(
        'mergeTag',
        function (data, done) {
          // data - contains current mergeTagGroup, current mergeTagRule and a list of mergeTags
          // done - callback function
          showModal('mergeTagsModal', {
            mergeTags: Object.values(data.mergeTags).map((m) => m.name),
            onSelect: done
          })
        }
      )
      emailEditorRef.current.editor.registerProvider(
        'userUploads',
        async (params, done) => {
          const data = await get(`${appId}/email_images`)
          done(
            data.data.map((image: UserImage) => ({
              ...image,
              location: image.imageUrl,
              source: 'user'
            }))
          )
        }
      )

      setBeforeExitStep?.(() => {
        editorData.setReady(false)
        emailEditorRef.current?.exportHtml(designChangedCallback)
        //! ugly workaround to make sure html is exported before save. Will be removed when rewrite campaigns to notifications
        return new Promise((resolve) => {
          const timer = setInterval(() => {
            if (editorData.isReady()) {
              clearInterval(timer)
              resolve(true)
            }
          }, 100)
        })
      })
    }
  }, [emailEditorRef?.current, loaded])

  const prepareMergeTags = (): MergeTag[] =>
    mergeTags.map((mergeTag: string) => ({
      name: mergeTag,
      value: `{{${mergeTag}}}`
    }))

  const editorOptions = useRef(getUnlayerOptions(prepareMergeTags(), appId))

  useEffect(() => {
    if (mergeTags)
      emailEditorRef?.current?.editor?.setMergeTags(prepareMergeTags())
  }, [mergeTags])

  useEffect(() => {
    editorData.setReady(loaded)
  }, [loaded])

  return (
    <>
      <div className="unlayer-editor">
        <div className="unlayer-editor__header d-flex justify-content-between align-items-center">
          <Header className="unlayer-editor-title mb-0">
            <HeaderTitle className="mb-0">Email Builder</HeaderTitle>
          </Header>
          <div className="unlayer-editor__header-actions">
            <Button
              color=""
              disabled={!editorData.isReady()}
              onClick={() => showModal('sendTestEmailModal')}
              className="bg-white px-4 btn me-2 btn btn-outline-body btn--hover">
              Send Test Email
            </Button>
            <Button
              className="btn--hover"
              onClick={() => showModal('saveAsTemplateModal')}
              disabled={!editorData.isReady()}
              color="primary">
              Save as a Template
            </Button>
          </div>
        </div>
        <EmailEditor
          projectId={5747}
          options={editorOptions?.current}
          onReady={onReadyEditor}
          style={{
            pointerEvents: 'auto'
          }}
          ref={emailEditorRef}
        />
      </div>

      <Modal
        id="sendTestEmailModal"
        size="md"
        className="send-test-email-modal"
        renderHeader={() => 'Send Test Email'}
        render={(close: () => void) => (
          <SendTestEmailModal
            closeSendTestEmailModal={close}
            testEmailData={testEmailData}
          />
        )}
      />

      <Modal
        id="saveAsTemplateModal"
        size="md"
        className="send-test-email-modal"
        renderHeader={() => 'Save Template'}
        render={(close: () => void) => (
          <SaveTemplateModal
            templateDesign={editorData.data}
            template={currentTemplate}
            isBlankTemplate={isBlankTemplate}
            onClose={close}
            setTemplate={setCurrentTemplate}
            saveTemplateCallback={saveTemplateCallback}
          />
        )}
      />

      <MergeTagsModal />
      <Modal
        id="imageUploadModal"
        size="lg"
        className="email-image-upload"
        renderHeader={() => 'Upload Media'}
        render={(close: () => void, params) => (
          <ImageUploadModal
            appId={appId}
            done={(url) => {
              params?.done({ url })
              close()
            }}
          />
        )}
      />
    </>
  )
}

export default UnlayerEditor
