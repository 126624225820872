import { computed, observable, action, makeObservable, override } from 'mobx'
import { IRegisteredField } from './EmailBuilder.interface'
import StepStore from './StepStore'

export default class Review extends StepStore {
  isValid = false

  constructor() {
    super()

    makeObservable(this, {
      isValid: observable,
      setStepValid: action.bound,
      isStepValid: override,
      validateStep: action.bound,
      registeredFields: computed
    })
  }

  setStepValid(value: boolean): void {
    this.isValid = value
    this.beenValid = true
  }

  get isStepValid(): boolean {
    return this.isValid
  }

  validateStep(): void {
    this.beenValid = true
  }

  public get registeredFields(): IRegisteredField[] {
    return []
  }

  public fillStore(): void {
    //
  }

  public getPayload(): void {
    //
  }
}
