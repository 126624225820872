import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'

import color from '~/utils/color'
import { withStore } from '~/dataStore'
import { IStep } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { ICurrentStep } from '~/dataStore/Campaign/Campaign.interface'

import './style.scss'

interface IProps extends IStep {
  isPreviousStepValid: boolean
  amount: number
  isLoading?: boolean | null
  currentStep: ICurrentStep
  onStepClick?: (step: number) => void
  number: number
}

const StepItem = ({
  amount,
  currentStep,
  isLoading,
  isPreviousStepValid,
  number,
  onStepClick,
  title
}: IProps) => {
  const isActive = currentStep.number === number
  const buttonDisabled = !isPreviousStepValid || isActive
  const isSaving = isLoading && isActive

  return (
    <div className="d-flex align-items-center flex-fill">
      <button
        type="button"
        onClick={() => (onStepClick ? onStepClick(number) : null)}
        disabled={buttonDisabled}
        className={cn(
          'd-flex align-items-center step',
          { 'step--active': isActive },
          {
            'step--enabled': isPreviousStepValid
          }
        )}>
        <div className="step__number-wrapper">
          {isSaving ? (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" />
          ) : (
            <span className="step__number">{number}</span>
          )}
        </div>
        <span className="step__title">{title}</span>
      </button>
      <div
        className={cn('flex-fill step__arrow', {
          'd-none': number === amount
        })}>
        <div className="step__line" />
        <svg
          width="5px"
          height="9px"
          viewBox="0 0 5 9"
          className="step__arrowhead">
          <path
            fill={color.pulsateBlue}
            d="M4.341,4.90336 C4.536,4.7081 4.536,4.39151 4.341,4.19625 L1.159,1.01427 C0.964,0.819009 0.647,0.819009 0.452,1.01427 C0.257,1.20953 0.257,1.52612 0.452,1.72138 L3.28,4.5498 L0.452,7.37823 C0.257,7.57349 0.257,7.89008 0.452,8.08534 C0.647,8.2806 0.964,8.2806 1.159,8.08534 L4.341,4.90336 Z"
          />
        </svg>
      </div>
    </div>
  )
}

export default withStore(StepItem)
