import React from 'react'
import cn from 'classnames'
import styles from './UploadAvatar.scss'
import UploadImage from '~/components/uploadImage/UploadImage'

interface IProps {
  error: string | undefined
  onChange: ({
    objectURL,
    fileName,
    file,
    valid
  }: {
    objectURL: string
    fileName: string
    file: File | null
    valid: boolean
  }) => void
  image: string
  fileName: string | undefined
  className?: string
}

const UploadAvatar = ({
  error,
  onChange,
  image,
  fileName,
  className
}: IProps): React.ReactElement => {
  return (
    <div className={className}>
      <UploadImage
        onChange={(objectURL, name, file, valid) => {
          onChange({ objectURL, fileName: name, file, valid })
        }}
        image={image}
        fileName={fileName}
        allowedTypes={['jpg', 'png', 'jpeg', 'gif']}
        withLabel
        fileType="image"
        small
        className={styles.uploadAvatar}
        rounded
        maxFileSizes={[{ type: 'image', maxSize: 1_000_000, error: '1MB' }]}
      />
      <p
        className={cn(
          'text-center text-danger text-13',
          styles.imageErrorWrapper
        )}>
        {error}
      </p>
    </div>
  )
}

export default UploadAvatar
