import cn from 'classnames'
import { useEffect, useState } from 'react'
import PreviewDesktop from '~/pages/Campaign/Notification/Preview/PreviewDesktop'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import PreviewMobile from '~/pages/Campaign/Notification/Preview/PreviewMobile'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { getPreviewByOptionType } from '~/dataStore/CampaignTypeFacade'
import CampaignTypeSwitch from '~/pages/Campaign/CampaignReports/CampaignTypeSwitch'
import styles from './JourneyNotificationPreview.scss'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  preview: PartialPreview
  bottom?: boolean
}

export default function JourneyNotificationPreview({
  preview,
  bottom
}: IProps): React.ReactElement | null {
  const [active, setActive] = useState<
    undefined | (IOption & { original: CampaignType })
  >(preview.type[0])

  function changePreview(previewType: string) {
    const activeOption = preview.type.find((t) => t.value === previewType)

    setActive(activeOption)
  }

  const campaignType = active ? active.original : undefined
  const withTooltip = active?.value === CampaignType.CARD_FRONT

  useEffect(() => {
    setActive(preview.type.at(0))
  }, [preview.type.length])

  if (!preview?.type) {
    return null
  }

  return (
    <div className="h-100 position-relative d-flex flex-column">
      {campaignType === CampaignType.EMAIL && preview.email ? (
        <div className="flex-1-1 d-flex align-items-center justify-content-center">
          <div className={cn(styles.desktopBox)}>
            <PreviewDesktop size="xl" srcDoc={preview.email.html} />
          </div>
        </div>
      ) : (
        <div
          className={cn('flex-1-1', {
            'position-relative':
              campaignType &&
              ![
                CampaignType.PUSH,
                CampaignType.CARD_FRONT,
                CampaignType.CARD_BACK
              ].includes(campaignType)
          })}>
          <PreviewMobile
            deviceSize="xs"
            device="ios"
            preview={
              active ? getPreviewByOptionType(active, preview) : undefined
            }
            campaignType={campaignType}
            withTooltip={withTooltip}
          />
        </div>
      )}

      <div className={cn({ 'position-absolute': !bottom }, styles.switch)}>
        <CampaignTypeSwitch
          tabs={preview.type.map((type) => {
            const prev = getPreviewByOptionType(type, preview)

            return {
              ...type,
              goals: prev.goals
            }
          })}
          activeTab={active?.value}
          classes={cn('tabs--button', { 'tabs--row mt-3': bottom })}
          toggle={changePreview}
          withCardSwitch
        />
      </div>
    </div>
  )
}
