import { FileItem } from '~/dataStore/emailBuilder/EmailBuilder.interface'

export enum ACTIONS {
  ADD_FILES = 'addFiles',
  UPDATE_FILE = 'updateFile',
  UPLOAD_ERROR = 'uploadError'
}

type Payload = {
  id?: string
  name?: string
  files?: Array<FileItem>
  isLoading?: boolean
  isUploaded?: boolean
  hasError?: boolean
}

const filesReducer = (
  files: Array<FileItem>,
  action: { type: string; payload: Payload }
) => {
  switch (action.type) {
    case ACTIONS.ADD_FILES:
      return [...files, ...action.payload.files]
    case ACTIONS.UPDATE_FILE:
      return files.map((file: FileItem) => {
        if (file.id === action.payload.id) {
          file = { ...file, ...action.payload }
        }
        return file
      })
    case ACTIONS.UPLOAD_ERROR:
      return files.map((file: FileItem) => {
        if (file.id === action.payload.id) {
          file = {
            ...file,
            hasError: true,
            isUploaded: false,
            isLoading: false
          }
        }
        return file
      })
    default:
      throw new Error('Unexpected action')
  }
}

export default filesReducer
