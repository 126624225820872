import React from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStore, useStore } from '~/dataStore'
import { ID } from '~/common.interface'

const RemoveFromNameButton = ({ id }: { id: ID }) => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    },
    ui: { showModal }
  } = useStore()

  return (
    <Button
      onClick={() => showModal('confirmFromNameDeletion', { id })}
      className="float-end text-body px-0 py-0 ms-3"
      disabled={isDemoAdmin}
      color="white">
      <FontAwesomeIcon size="lg" icon={faTrash} />
    </Button>
  )
}

export default withStore(RemoveFromNameButton)
