import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { useParams, useHistory } from 'react-router-dom'
import {
  fetchUserDetails,
  fetchUserSubscriptions,
  deleteUser,
  unsubscribeUser,
  subscribeUser,
  fetchUserGeofenceEvents,
  fetchUserBeaconEvents,
  addUserNote,
  fetchUserSegments
} from '~/api/users'
import { IMetaData } from '~/common.interface'
import {
  IUserBeaconEvent,
  IUserGeofenceEvent,
  IUserSubscription
} from '~/pages/Users/Users.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import { ServerStateKeysEnum as UsersServerStateKeysEnum } from '../UsersList/useUsersList'

export enum ServerStateKeysEnum {
  UserDetails = 'userDetails',
  UserSegments = 'userSegments',
  UserSubscriptions = 'userSubscriptions',
  UserGeofenceEvents = 'userGeofenceEvents',
  UserBeaconEvents = 'userBeaconEvents'
}

export const useUserDetails = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  return useQuery([ServerStateKeysEnum.UserDetails, userId], () =>
    fetchUserDetails(appId, userId)
  )
}

export const useUserSegments = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  return useQuery([ServerStateKeysEnum.UserSegments, userId], () =>
    fetchUserSegments(appId, userId)
  )
}

export const useDeleteUser = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutation(() => deleteUser(appId, userId), {
    onSuccess: () => {
      queryClient.invalidateQueries([UsersServerStateKeysEnum.Users])
      history.push(`/mobile/apps/${appId}/users`)
    },
    onError: () => {
      showNotification(
        'Failed to delete user, please try again later.',
        NotificationType.ERROR
      )
    }
  })
}

export const useUnsubscribeUser = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutation(() => unsubscribeUser(appId, userId), {
    onSuccess: () => {
      queryClient.invalidateQueries([UsersServerStateKeysEnum.Users])
      history.push(`/mobile/apps/${appId}/users`)
    },
    onError: () => {
      showNotification(
        'Failed to unsubscribe user, please try again later.',
        NotificationType.ERROR
      )
    }
  })
}

export const useSubscribeUser = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  return useMutation(() => subscribeUser(appId, userId), {
    onSuccess: () => {
      queryClient.invalidateQueries([UsersServerStateKeysEnum.Users])
      history.push(`/mobile/apps/${appId}/users`)
    },
    onError: () => {
      showNotification(
        'Failed to subscribe user, please try again later.',
        NotificationType.ERROR
      )
    }
  })
}

export const useUserSubscriptions = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  return useInfiniteQuery<
    { data: IUserSubscription[]; metadata: Omit<IMetaData, 'groups'> },
    unknown,
    IUserSubscription
  >(
    [ServerStateKeysEnum.UserSubscriptions, userId],
    ({ pageParam = 1 }) =>
      fetchUserSubscriptions(appId, userId, {
        perPage: 25,
        page: pageParam
      }),
    {
      select: (data) => ({
        pages: data.pages.flatMap((page) => page.data),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata
      }),
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export const useUserGeofenceEvents = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  return useInfiniteQuery<
    { data: IUserGeofenceEvent[]; metadata: Omit<IMetaData, 'groups'> },
    unknown,
    IUserGeofenceEvent
  >(
    [ServerStateKeysEnum.UserGeofenceEvents, userId],
    ({ pageParam = 1 }) =>
      fetchUserGeofenceEvents(appId, userId, {
        perPage: 25,
        page: pageParam
      }),
    {
      select: (data) => ({
        pages: data.pages.flatMap((page) => page.data),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata
      }),
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export const useUserBeaconEvents = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()
  return useInfiniteQuery<
    { data: IUserBeaconEvent[]; metadata: Omit<IMetaData, 'groups'> },
    unknown,
    IUserBeaconEvent
  >(
    [ServerStateKeysEnum.UserBeaconEvents, userId],
    ({ pageParam = 1 }) =>
      fetchUserBeaconEvents(appId, userId, {
        perPage: 25,
        page: pageParam
      }),
    {
      select: (data) => ({
        pages: data.pages.flatMap((page) => page.data),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata
      }),
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export const useAddUserNote = () => {
  const { appId, userId } = useParams<{ appId: string; userId: string }>()

  return useMutation(({ content }: { content: string }) =>
    addUserNote(appId, userId, content)
  )
}

export default useUserDetails
