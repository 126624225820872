/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useRef, useState } from 'react'
import cn from 'classnames'
import { FileMimeType } from '~/dataStore/Campaign/Campaign.interface'

function Video({ video }: { video: string }): React.ReactElement {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  function playPause() {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play()
        setIsPlaying(true)
      } else {
        videoRef.current.pause()
        setIsPlaying(false)
      }
    }
  }

  return (
    <div className="position-relative video-preview">
      <video
        data-testid="video-tag"
        className="h-100 w-100"
        src={video}
        ref={videoRef}
      />
      <button
        type="button"
        onClick={playPause}
        className="position-absolute absolute-center video-preview__btn btn--transparent">
        <span
          className={cn(
            'position-absolute absolute-center video-preview__control',
            { 'video-preview__control--play': !isPlaying },
            { 'video-preview__control--pause': isPlaying }
          )}>
          {!isPlaying ? (
            <svg
              className="w-100 h-100 video-preview__icon"
              viewBox="0 0 30 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M29.3242 17.1038L0.804739 33.5695L0.804741 0.638076L29.3242 17.1038Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              className="w-100 h-100 video-preview__icon"
              viewBox="0 0 61 61"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="17" y="11" width="10" height="40" fill="white" />
              <rect x="35" y="11" width="10" height="40" fill="white" />
            </svg>
          )}
        </span>
      </button>
    </div>
  )
}

function Audio({ audio }: { audio: string }): React.ReactElement {
  return (
    <audio
      controls
      src={audio}
      className="w-100 audio-preview"
      controlsList="nodownload"
    />
  )
}

function MediaType({
  mediaFile,
  mediaType
}: {
  mediaFile: string
  mediaType?: FileMimeType | 'gif'
}): ReactElement {
  if (mediaType === 'audio') {
    return <Audio audio={mediaFile} />
  }

  if (mediaType === 'video') {
    return <Video video={mediaFile} />
  }

  return <img src={mediaFile} alt="" className="preview__attachment" />
}

const Media = ({
  mediaFile,
  mediaType,
  onClick
}: {
  mediaFile: string | undefined
  mediaType?: FileMimeType | 'gif'
  onClick?: () => void
}): ReactElement | null => {
  if (!mediaFile) {
    return null
  }

  return (
    <div
      onClick={onClick}
      data-testid="preview-part"
      className={cn('preview__attachment-box', {
        'cursor-pointer': !!onClick
      })}>
      <MediaType mediaFile={mediaFile} mediaType={mediaType} />
    </div>
  )
}

export default Media
