import React from 'react'
import { MODES } from '../AppManager.interface'
import ModeDot from './ModeDot'

const ModeColumn = ({ mode }: { mode: MODES }) => {
  return (
    <p className="d-flex align-items-center justify-content-between mb-0">
      {mode} Mode <ModeDot mode={mode} />
    </p>
  )
}

export default ModeColumn
