import { generatePath, NavLink, Link as Newlink } from 'react-router-dom'

import { ReactElement, ReactNode } from 'react'
import { store, useStore, withStore } from '~/dataStore'
import { SingleRoute } from '~/dataStore/Store.interface'
import { paramsToStr } from '~/api/requests.service'

interface IProps {
  route: string | SingleRoute
  params?: Record<string, any>
  queryParams?: Record<string, any>
  state?: Record<string, unknown>
  children: ReactNode
  navigationLink?: boolean
  ariaLabel?: string
  [x: string]: any
}

function Link({
  route,
  params,
  children,
  queryParams = {},
  state,
  navigationLink = false,
  ariaLabel,
  ...otherProps
}: IProps): ReactElement {
  const { app } = useStore()
  if (typeof route === 'string' || route == null) {
    // This is not a router route, so let's render a normal <a>
    return (
      <a
        href={route ? `${route}${paramsToStr(params)}` : undefined}
        aria-label={ariaLabel}
        // eslint-disable-next-line
        {...otherProps}>
        {children}{' '}
      </a>
    )
  }

  if (!store?.history) {
    return (
      <a
        href={`${generatePath(route.path, params)}${paramsToStr(queryParams)}`}
        aria-label={ariaLabel}
        // eslint-disable-next-line
        {...otherProps}>
        {children}
      </a>
    )
  }

  if (navigationLink) {
    return (
      <NavLink
        to={{
          pathname: generatePath(route.path, {
            appId: app.currentApp.id,
            ...params
          }),
          search: paramsToStr(queryParams),
          state
        }}
        aria-label={ariaLabel}
        {...otherProps}>
        {children}
      </NavLink>
    )
  }

  return (
    <Newlink
      to={{
        pathname: generatePath(route.path, {
          appId: app.currentApp.id,
          ...params
        }),
        search: paramsToStr(queryParams),
        state
      }}
      aria-label={ariaLabel}
      {...otherProps}>
      {children}
    </Newlink>
  )
}

export default withStore(Link)
