import React from 'react'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'
import StatusPill from './StatusPill'

const StatusOption = ({
  name,
  status
}: {
  name: string
  status: CampaignStatus | JourneyStatus
}) => (
  <div className="d-inline-flex justify-content-start align-items-center w-100">
    <span className="pe-3">{name}</span>
    <StatusPill
      mini
      status={status}
      className="ms-auto me-1"
      height="10"
      width="20">
      <>&nbsp;&nbsp;</>
    </StatusPill>
  </div>
)

export default StatusOption
