import { uniqueId } from 'lodash'
import React from 'react'
import cn from 'classnames'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  rows: IOption[]
  onClick?: () => void
}

const TablePreview = ({ rows, onClick }: IProps): React.ReactElement => {
  return (
    <div
      className={cn('preview-table', { 'cursor-pointer': !!onClick })}
      onClick={onClick}>
      {rows.map((row) => {
        return (
          <div key={uniqueId()} className="d-flex">
            <div className="preview-table__item">{row.label}</div>
            <div className="preview-table__item">{row.value}</div>
          </div>
        )
      })}
    </div>
  )
}

export default TablePreview
