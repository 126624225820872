import React, { useState } from 'react'
import { Card, Col, Row } from 'reactstrap'

import cn from 'classnames'
import { useStore, withStore } from '~/dataStore'

import Segments from './Segments'
import Beacons from './Beacons'
import Geofences from './Geofences'
import Events from './Events'
import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'

import { targetWrapper } from './Step5.scss'
import TargetingPreview from '~/pages/Campaign/UI/TargetingPreview'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import DisabledFeatureWarning from '~/components/common/DisabledFeatureWarning'

const TargetingStep = () => {
  const [openPicker, setOpenPicker] = useState<Exclude<
    TARGETS,
    TARGETS.SUBSCRIPTIONS | TARGETS.IN_APP_EVENTS
  > | null>()
  const {
    app: {
      appDetails: { featureFlags }
    },
    ui
  } = useStore()
  const {
    campaign: {
      isSended,
      targeting,
      mode,
      targeting: {
        segments,
        beacons,
        geofences,
        events,
        targetingOption: { isValid }
      }
    }
  } = useStore()

  const pickerProps = (
    id: Exclude<TARGETS, TARGETS.SUBSCRIPTIONS | TARGETS.IN_APP_EVENTS>
  ) => {
    return {
      name: id,
      disabled:
        isSended ||
        (id === TARGETS.BEACONS && !featureFlags.beacons) ||
        (id === TARGETS.GEOFENCES && !featureFlags.geofences),
      opened: openPicker === id,
      isValid,
      setOpened: (open: boolean) => {
        setOpenPicker(open ? id : null)
      },
      className: 'px-20p',
      checked: targeting[id].isActive,
      setChecked: (check: boolean) => {
        if (!check) {
          targeting.reset(id)
          targeting.validateStep()
        } else {
          targeting[id].setActive(true)
          targeting.validateTargetingOption()
        }
      }
    }
  }

  return (
    <>
      <Row className="align-items-start">
        <Col
          widths={['md', 'xxl']}
          md={{ size: 6 }}
          xxl={{ size: 5, offset: 2 }}
          className="mb-4">
          <TargetingPreview
            mode={mode}
            segments={segments}
            beacons={beacons}
            geofences={geofences}
            events={events}
          />
        </Col>
        <Col
          widths={['md', 'xxl']}
          md={{ size: 5, offset: 1 }}
          xxl={{ size: 4, offset: 1 }}
          className={cn('sticky', { 'z-index-20': ui.getHintWindowActive() })}>
          <Card className={cn(targetWrapper)}>
            <CollapseWithToggle {...pickerProps(TARGETS.SEGMENTS)}>
              <Segments isSended={isSended} targeting={targeting} />
            </CollapseWithToggle>

            <CollapseWithToggle {...pickerProps(TARGETS.BEACONS)}>
              {featureFlags.beacons ? (
                <Beacons isSended={isSended} targeting={targeting} />
              ) : (
                <p className="p-3 text-blue-gray text-13">
                  <DisabledFeatureWarning feature="Beacons" />
                </p>
              )}
            </CollapseWithToggle>

            <CollapseWithToggle {...pickerProps(TARGETS.GEOFENCES)}>
              {featureFlags.geofences ? (
                <Geofences isSended={isSended} targeting={targeting} />
              ) : (
                <p className="p-3 text-blue-gray text-13">
                  <DisabledFeatureWarning feature="Geofences" />
                </p>
              )}
            </CollapseWithToggle>

            <CollapseWithToggle {...pickerProps(TARGETS.EVENTS)}>
              <Events />
            </CollapseWithToggle>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default withStore(TargetingStep)
