import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import AreaCharts from '~/pages/OpportunitiesDashboard/EngagementSummary/AreaCharts'
import useGetEngagementSummary from '~/pages/OpportunitiesDashboard/useOpportunitiesDashboard'
import RadialCharts from '~/pages/OpportunitiesDashboard/EngagementSummary/RadialCharts'
import EngagementSummaryCarousel from './Carousel'
import Toolbar from './Toolbar'

const EngagementSummary = () => {
  const [range, setRange] = useState<number>()
  const [channel, setChannel] = useState('All Channels')
  const { appId } = useParams<{ appId: string }>()
  const { data } = useGetEngagementSummary(appId, {
    range
  })
  const {
    app: {
      appDetails: { oeRangeInDays }
    }
  } = useStore()

  useEffect(() => {
    if (oeRangeInDays) {
      setRange(oeRangeInDays[0])
    }
  }, [oeRangeInDays])

  return (
    <div>
      <h2>Engagement Summary</h2>
      <div className="mt-4">
        <Toolbar
          channel={channel}
          setChannel={setChannel}
          rangeDays={oeRangeInDays}
          setRange={setRange}
          range={range}
        />
      </div>

      <div className="mt-4">
        {/*  1280 Resolution */}
        <Row className="d-xxl-none">
          <Col xs={12} className="mb-4">
            <AreaCharts summaryData={data} currentRange={range} />
          </Col>
          <Col xs={12} className="mb-4">
            <RadialCharts summaryData={data} currentRange={range} />
          </Col>
          <Col xs={12}>
            <EngagementSummaryCarousel />
          </Col>
        </Row>

        {/*  1352 Resolution */}
        <Row className="d-none d-xxl-flex d-xxxl-none">
          <Col xs={12} xxl={9} className="mb-4">
            <AreaCharts summaryData={data} currentRange={range} />
          </Col>
          <Col xs={12} xxl={3} className="mb-4">
            <RadialCharts
              direction="vertical"
              summaryData={data}
              currentRange={range}
            />
          </Col>
          <Col xs={12}>
            <EngagementSummaryCarousel />
          </Col>
        </Row>

        {/*  1600 Resolution */}
        <Row className="d-none d-xxxl-flex">
          <Col xxxl={6} widths={['xxxl']} className="mb-4">
            <AreaCharts summaryData={data} currentRange={range} />
          </Col>
          <Col xxxl={6} widths={['xxxl']} className="mb-4">
            <div className="justify-content-between h-100 flex-column d-flex">
              <EngagementSummaryCarousel />
              <RadialCharts summaryData={data} currentRange={range} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default withStore(EngagementSummary)
