import { useEffect, useRef } from 'react'
import { IObservableObject, observable, reaction } from 'mobx'

import { omitBy } from 'lodash'
import { useParams } from 'react-router-dom'
import { camelizeKeys } from 'humps'
import { useStore } from '~/dataStore'
import { getObjectFromSearchParams, isEmpty, useQuery } from '~/utils/utilities'

export default function useQueryParams(defaultValues = {}): IObservableObject {
  const store = useStore()
  const queryParams = useQuery()
  const params = useParams()

  const queryParamsWithDefaults = useRef(
    observable({
      ...defaultValues,
      ...camelizeKeys(getObjectFromSearchParams(queryParams))
    })
  )

  useEffect(() => {
    const disposer = reaction(
      () => Object.values(queryParamsWithDefaults.current),
      () => {
        const { sort, order, ...newParams } = queryParamsWithDefaults.current
        //* it overrides all current queryParams
        store.goTo(undefined, params, {
          ...omitBy(newParams, isEmpty)
        })
      }
    )

    return () => disposer()
  }, [])

  return queryParamsWithDefaults.current
}
