import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import debounce from 'lodash/debounce'

export default function DebouncedInput({
  initialValue,
  onChange,
  debounceTime,
  ...otherProps
}) {
  const [value, setValue] = useState(initialValue)

  const delayedOnChange = useCallback(
    // eslint-disable-line react-hooks/exhaustive-deps
    debounce((str) => onChange(str), debounceTime),
    [onChange]
  )

  useEffect(() => {
    if (value !== initialValue) setValue(initialValue)
    // we only want to run this when initialValue changes
  }, [initialValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const internalOnChange = useCallback(
    (e) => {
      setValue(e.target.value)
      delayedOnChange(e.target.value)
    },
    [delayedOnChange]
  )

  return <Input value={value} onChange={internalOnChange} {...otherProps} />
}

DebouncedInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  debounceTime: PropTypes.number
}

DebouncedInput.defaultProps = {
  initialValue: '',
  debounceTime: 300
}
