import pluralize from 'pluralize'
import { action, makeObservable, observable, override } from 'mobx'
import { TimeUnit } from '~/common.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import {
  IBlock,
  IBlockDelay,
  JourneyBlockType
} from '../Store/JourneyBuilder.interface'
import Block from './Block.model'

type DelayBlockDTO = Pick<IBlockDelay, 'timeFrame' | 'timeValue'>

export default class Delay extends Block<DelayBlockDTO> {
  public timeValue = new NewRegisteredField<string | number>('')

  public timeFrame = new NewRegisteredField<TimeUnit>('hours')

  constructor(public parent: IBlock) {
    super(JourneyBlockType.DELAY, parent)

    makeObservable(this, {
      timeFrame: observable,
      timeValue: observable,
      setTimeValue: action.bound,
      setTimeFrame: action.bound,
      validateTimeValue: action.bound,
      resetError: override
    })
  }

  get filled(): boolean {
    return !!(this.timeValue.value && this.timeFrame.value)
  }

  public getBlockPayload(): DelayBlockDTO {
    return {
      timeFrame: this.timeFrame.value,
      timeValue: Number(this.timeValue.value)
    }
  }

  protected fillBlockPayload(data: DelayBlockDTO): void {
    this.timeValue.setValue(data.timeValue || '')
    this.timeFrame.setValue(data.timeFrame || 'hours')

    this.setReady()
  }

  public validateBlock(): boolean {
    this.validateTimeValue()
    if (!this.isValid) {
      this.launchErrors = ['You must set dwell time']
    }
    return this.isValid
  }

  public validateTimeValue(): void {
    const value = Number(this.timeValue.value)
    if (!this.timeValue.validate()) {
      this.isValid = false
      this.errors = ['Enter delay time']
    } else if (value < 1 || !Number.isInteger(value)) {
      this.timeValue.isValid = false
      this.isValid = false
      this.errors = ['Enter a whole number greater than 0']
    } else if (value > 99) {
      this.timeValue.isValid = false
      this.isValid = false
      this.errors = ['Enter a whole number below 100']
    }
  }

  public resetError(): void {
    super.resetError()
    this.timeValue.resetError()
  }

  public setTimeValue(value: string): void {
    this.timeValue.setValue(value)

    const newValue = Number(value)
    if (newValue > 0 && newValue < 100 && Number.isInteger(newValue)) {
      this.resetError()
    }
  }

  public setTimeFrame(value: TimeUnit): void {
    this.timeFrame.setValue(value)
  }

  protected getNodeData(): { label: string } {
    return {
      label: this.filled
        ? `Wait ${this.timeValue.value} ${pluralize(
            this.timeFrame.value,
            Number(this.timeValue.value)
          )}`
        : 'Set Delay'
    }
  }
}
