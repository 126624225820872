import React, { useState } from 'react'
import { Button, FormGroup, Label } from 'reactstrap'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { useStore, withStore } from '~/dataStore'
import { NotificationType, showNotification } from '~/utils/Notification'
import DatepickerInput from '~/components/forms/DatePicker'
import CustomDropdown from '~/components/CustomDropdown'

import './ExportByDate.scss'

const ExportByDate = ({
  onSubmit,
  label,
  dateFormat = 'YYYY-MM-DD'
}: {
  onSubmit: ({ from, to }: { from: string; to: string }) => void
  label?: string
  dateFormat?: string
}) => {
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const {
    app: {
      currentAdmin: { email }
    }
  } = useStore()

  const handleExport = async () => {
    try {
      await onSubmit({
        from: moment(fromDate).format(dateFormat),
        to: moment(toDate).format(dateFormat)
      })
      showNotification(
        `Your export has started. We'll email ${email} when it's completed.`,
        NotificationType.SUCCESS
      )
    } catch (error) {
      showNotification(`Something went wrong.`, NotificationType.ERROR)
    }
  }

  return (
    <div className="export-by-date">
      <CustomDropdown
        withoutModifiers
        options={[
          {
            name: (
              <div className="p-3 export-by-date__form">
                <FormGroup>
                  <Label>From</Label>
                  <div className="d-block">
                    <DatepickerInput
                      minDate={moment().subtract(1, 'y').toDate()}
                      maxDate={toDate || moment().toDate()}
                      value={fromDate}
                      onChange={setFromDate}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="mt-3">
                  <Label>To</Label>
                  <div className="d-block">
                    <DatepickerInput
                      minDate={fromDate}
                      maxDate={moment().toDate()}
                      value={toDate}
                      onChange={setToDate}
                    />
                  </div>
                </FormGroup>

                <Button
                  onClick={handleExport}
                  color="primary"
                  className="w-100">
                  Export
                </Button>
              </div>
            ),
            isCustom: true
          }
        ]}
        dropdownToggle={
          <span className="btn btn-lg bg-white btn--wide btn-hover-shadow">
            <FontAwesomeIcon icon={faFileDownload} size="lg" className="me-2" />{' '}
            {label || 'Export'}
          </span>
        }
        dropdownToggleClassname="border rounded me-3"
        visible
      />
    </div>
  )
}

export default withStore(ExportByDate)
