import { capitalize } from 'lodash'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal'
import notificationImg from './notificationImg.svg'

const RequestNotificationSendingApproval = ({
  notificationType
}: {
  notificationType: 'campaign' | 'journey'
}): React.ReactElement => {
  return (
    <>
      <Modal
        id="requestNotificationSendingApproval"
        size="md"
        className="register-interest-modal"
        render={(close: () => void, args) => (
          <ConfirmationModal
            onClose={close}
            onSubmit={args?.requestSend}
            okText="Yes, Send It"
            cancelText="Cancel">
            <>
              <div className="register-interest-modal__media">
                <img alt="Register interest" src={notificationImg} />
              </div>
              <div className="mt-4">
                <h2 className="font-weight-medium">
                  Ready to Send Your {capitalize(notificationType)}?
                </h2>
              </div>
              <p className="mt-3">
                Please confirm that this {notificationType}, including
                segmentation, content and scheduling, is approved to send
              </p>
            </>
          </ConfirmationModal>
        )}
      />
    </>
  )
}

export default RequestNotificationSendingApproval
