/* eslint-disable jsx-a11y/media-has-caption */

import { Button } from 'reactstrap'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCropAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useRef } from 'react'
import { FileMimeType } from '~/dataStore/Campaign/Campaign.interface'
import Modal from '~/components/modals/Modal'
import CropImage from '../../cropimage'
import { useStore, withStore } from '~/dataStore'
import { blobToFile, convertToBlob } from '~/utils/file.service'

interface IProps {
  small?: boolean
  mediaFile: string
  mediaType: FileMimeType
  fileName: string
  withCrop?: boolean
  onChange: (
    dataURL: string,
    fileName: string,
    file: File | null,
    valid: boolean
  ) => void
  cropTooltip?: string | React.ReactNode
}

function UploadMediaPreview({
  small,
  mediaFile,
  mediaType,
  fileName,
  withCrop = true,
  onChange,
  cropTooltip
}: IProps): React.ReactElement {
  const originalFile = useRef(mediaFile)

  const { ui } = useStore()

  function handleCrop(): void {
    if (!withCrop) {
      return
    }

    if (originalFile) {
      ui.showModal('cropImageModal')
    }
  }

  function handleRemove(): void {
    URL.revokeObjectURL(mediaFile)
    onChange('', '', null, true)
  }

  async function cropImage(base64: string, close: () => void): Promise<void> {
    try {
      const blob = await convertToBlob(base64)
      const file = blobToFile(blob, fileName)
      onChange(base64, fileName as string, file, true)
      close()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <div
        className={cn(
          'upload-image__form d-flex flex-column justify-content-center align-items-center',
          { 'upload-image__form--small': small }
        )}>
        {mediaType === 'audio' && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <span className="icon icon--big icon--notes mb-4" />
            <audio controls src={mediaFile} />
          </div>
        )}
        {mediaType === 'video' && (
          <video className="h-100" src={mediaFile} controls />
        )}

        {mediaType === 'image' && (
          <Button
            onClick={handleCrop}
            color=""
            className="upload-image__thumb-wrapper px-0 py-0 no-wrap shadow-none h-100">
            <img
              data-testid="image"
              src={mediaFile}
              alt=""
              className="upload-image__thumb"
            />
          </Button>
        )}
      </div>
      <div
        className={cn('d-flex justify-content-end mt-2', {
          'upload-image__btns': small
        })}>
        {!small && (
          <div
            className="me-auto w-50 text-truncate text-blue-gray"
            title={fileName}>
            {fileName}
          </div>
        )}
        {withCrop && (
          <Button
            data-testid="cropBtn"
            onClick={handleCrop}
            color="white"
            className="text-body px-0 py-0 no-wrap btn--hover-primary">
            <FontAwesomeIcon fixedWidth icon={faCropAlt} />
            Crop
          </Button>
        )}
        <Button
          data-testid="removeBtn"
          onClick={handleRemove}
          color="white"
          className="ms-3 text-body px-0 py-0 no-wrap btn--hover-primary">
          <FontAwesomeIcon fixedWidth icon={faTrash} />
          Remove
        </Button>
      </div>

      {withCrop && (
        <Modal
          id="cropImageModal"
          size="lg"
          contentClassName="crop-wrapper"
          renderHeader={() => 'Crop your image'}
          render={(close: () => void) => (
            <CropImage
              fileName={fileName || ''}
              minHeight={small ? 160 : undefined}
              minWidth={small ? 160 : 320}
              aspect={small ? 1 / 1 : undefined}
              tooltip={
                small ? (
                  'Images must have a minimum width and height of 160px'
                ) : (
                  <>
                    {cropTooltip || (
                      <>
                        Image must have a minimum width of 320px <br />
                        Image in portrait orientation takes most of In-App space
                      </>
                    )}
                  </>
                )
              }
              src={originalFile.current}
              onClose={close}
              onSubmit={(base64: string) => {
                cropImage(base64, close)
              }}
            />
          )}
        />
      )}
    </>
  )
}

export default withStore(UploadMediaPreview)
