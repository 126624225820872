import { action, computed, observable, makeObservable } from 'mobx'
import {
  MAX_DWELL_TIME,
  MIN_DWELL_TIME
} from '~/pages/CampaignBuilder/Email/consts'
import {
  IGeofenceTarget,
  Trigger,
  ValidationTypes
} from '../EmailBuilder.interface'
import { MTrigger } from '../EmailBuilder.map'
import NewRegisteredField from '../RegisteredField.model'

export default class Geofence implements IGeofenceTarget {
  createdAt: string

  groupIds: any[]

  guid: string

  id: string

  location: [number, number]

  name: string

  path: string | null

  radius: number

  shape: string

  trigger: Trigger | undefined | null = undefined

  // empty string is used to clean up input without losing control of it
  dwellTime = new NewRegisteredField<string>('')

  selectGeofence: (
    geofence: Geofence,
    isSelected: boolean,
    trigger?: Trigger
  ) => void

  constructor(
    geofence: IGeofenceTarget,
    select: (x: Geofence, isSelected: boolean, trigger?: Trigger) => void
  ) {
    makeObservable(this, {
      trigger: observable,
      dwellTime: observable,
      isEnterTrigger: computed,
      isExitTrigger: computed,
      isDwellTrigger: computed,
      setTrigger: action.bound,
      triggerLabel: computed,
      toggleEntering: action.bound,
      toggleLeaving: action.bound,
      toggleDwelling: action.bound,
      setDwellTime: action.bound,
      validateDwellTime: action.bound
    })

    this.createdAt = geofence.createdAt
    this.groupIds = geofence.groupIds
    this.guid = geofence.guid
    this.id = geofence.id
    this.location = geofence.location
    this.name = geofence.name
    this.path = geofence.path
    this.radius = geofence.radius
    this.shape = geofence.shape
    this.selectGeofence = select
  }

  public get isEnterTrigger(): boolean {
    return this.trigger === Trigger.ENTER
  }

  public get isExitTrigger(): boolean {
    return this.trigger === Trigger.EXIT
  }

  public get isDwellTrigger(): boolean {
    return this.trigger === Trigger.DWELL
  }

  public setTrigger(trigger: Trigger | null): void {
    this.trigger = trigger
  }

  get triggerLabel(): string | undefined {
    if (this.trigger) {
      let additional = ''
      if (this.trigger === Trigger.DWELL && this.dwellTime?.value) {
        additional = `: ${this.dwellTime.value} minutes`
      }
      return `${MTrigger.get(this.trigger)}${additional}`
    }

    return undefined
  }

  public toggleEntering(): void {
    this.dwellTime.setValue('')
    if (this.trigger === Trigger.ENTER) {
      this.selectGeofence(this, false)
    } else {
      this.selectGeofence(this, true, Trigger.ENTER)
    }
  }

  public toggleLeaving(): void {
    this.dwellTime.setValue('')
    if (this.trigger === Trigger.EXIT) {
      this.selectGeofence(this, false)
    } else {
      this.selectGeofence(this, true, Trigger.EXIT)
    }
  }

  public toggleDwelling(): void {
    if (this.trigger === Trigger.DWELL) {
      this.selectGeofence(this, false)
      this.dwellTime.setValue('')
    } else {
      this.dwellTime.setValue('10')
      this.selectGeofence(this, true, Trigger.DWELL)
    }
  }

  public setDwellTime(value: number | string): void {
    this.dwellTime.setValue(value === '00' ? '0' : value)
  }

  public validateDwellTime(): void {
    this.dwellTime.resetError()
    if (this.dwellTime.value !== 0 && !this.dwellTime.value) {
      this.dwellTime.isValid = false
      this.dwellTime.errors.push({
        type: ValidationTypes.REQUIRED,
        message: `Enter a whole number between ${MIN_DWELL_TIME} and ${MAX_DWELL_TIME}`
      })
      return
    }
    const value = Number(this.dwellTime.value)

    if (
      value < MIN_DWELL_TIME ||
      !Number.isInteger(value) ||
      value > MAX_DWELL_TIME
    ) {
      this.dwellTime.isValid = false
      this.dwellTime.errors.push({
        type: ValidationTypes.MIN_MAX,
        message: `Enter a whole number between ${MIN_DWELL_TIME} and ${MAX_DWELL_TIME}`
      })
    }
  }
}
