import moment from 'moment'
import React, { useState } from 'react'
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Datepicker from './DatePicker'
import useDropdownNavTabs from '~/hooks/useDropdownNavTabs'

interface IProps {
  startDateFilter: Date
  endDateFilter: Date
  setFilter: ({
    startDate,
    endDate
  }: {
    startDate: Date
    endDate: Date
  }) => void
  toggleDropdown?: () => void
  minDate: Date
  maxDate: Date
}

enum Presets {
  LAST_24_HOURS = 1,
  LAST_7_DAYS = 7,
  LAST_30_DAYS = 30,
  LAST_90_DAYS = 90
}

enum Panel {
  CALENDAR,
  PRESETS
}

export default function RangeDatepicker({
  startDateFilter,
  endDateFilter,
  setFilter,
  toggleDropdown,
  minDate,
  maxDate
}: IProps): React.ReactElement {
  const [startDate, setStartDate] = useState<Date>(startDateFilter)
  const [endDate, setEndDate] = useState<Date>(endDateFilter)
  const [activeTab, toggle, getTabClassName] = useDropdownNavTabs(
    Panel.CALENDAR
  )

  function onStartDateChange(date: Date): void {
    setStartDate(date)
  }
  function onEndDateChange(date: Date): void {
    setEndDate(date)
  }

  function handleApply(start: Date, end: Date): void {
    setFilter({ startDate: start, endDate: end })
  }

  function setPreset(type: Presets) {
    const end = new Date(maxDate)
    const start = new Date(maxDate)

    start.setDate(end.getDate() - type)
    setEndDate(end)
    const correctedStart = start < minDate ? new Date(minDate) : start
    setStartDate(correctedStart)
    handleApply(correctedStart, end)
    toggleDropdown?.()
  }

  function isOutOfRange(type: Presets): boolean {
    return moment().subtract(type, 'days').isBefore(minDate)
  }

  return (
    <div className="range-datepicker">
      <Nav tabs className="dropdown-nav-tabs">
        <NavItem>
          <NavLink
            tag="button"
            className={getTabClassName(Panel.CALENDAR)}
            onClick={() => {
              toggle(Panel.CALENDAR)
            }}>
            Calendar
          </NavLink>
        </NavItem>
        <li className="dropdown-nav-tabs__separator" />
        <NavItem>
          <NavLink
            tag="button"
            className={getTabClassName(Panel.PRESETS)}
            onClick={() => {
              toggle(Panel.PRESETS)
            }}>
            Presets
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Panel.CALENDAR}>
          <div className="range-datepicker__calendar-wrapper">
            <div>
              <div className="range-datepicker__header">Start date</div>
              <Datepicker
                value={startDate}
                onChange={onStartDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsStart
                minDate={minDate}
                maxDate={endDate}
                shouldCloseOnSelect={false}
                inline
                calendarClassName="range-datepicker__calendar"
                showMonthYearDropdown={false}
              />
            </div>
            <div>
              <div className="range-datepicker__header">End date</div>
              <Datepicker
                value={endDate}
                onChange={onEndDateChange}
                startDate={startDate}
                minDate={startDate}
                maxDate={maxDate}
                endDate={endDate}
                selectsEnd
                shouldCloseOnSelect={false}
                inline
                calendarClassName="range-datepicker__calendar"
                showMonthYearDropdown={false}
              />
            </div>
          </div>
          <Button
            color="body"
            onClick={() => {
              handleApply(startDate, endDate)
              toggleDropdown?.()
            }}
            className="m-3">
            Apply
          </Button>
        </TabPane>
        <TabPane tabId={Panel.PRESETS}>
          <ul className="range-datepicker__presets">
            <li>
              <button
                onClick={() => {
                  setPreset(Presets.LAST_24_HOURS)
                }}
                className="range-datepicker__presets-item"
                disabled={isOutOfRange(Presets.LAST_24_HOURS)}
                type="button">
                Last 24 Hours
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPreset(Presets.LAST_7_DAYS)
                }}
                className="range-datepicker__presets-item"
                disabled={isOutOfRange(Presets.LAST_7_DAYS)}
                type="button">
                Last 7 Days
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPreset(Presets.LAST_30_DAYS)
                }}
                className="range-datepicker__presets-item"
                disabled={isOutOfRange(Presets.LAST_30_DAYS)}
                type="button">
                Last 30 Days
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPreset(Presets.LAST_90_DAYS)
                }}
                className="range-datepicker__presets-item"
                disabled={isOutOfRange(Presets.LAST_90_DAYS)}
                type="button">
                Last 90 Days
              </button>
            </li>
          </ul>
        </TabPane>
      </TabContent>
    </div>
  )
}
