import React, { useEffect, useRef } from 'react'

import { withStore, useStore } from '~/dataStore'

import Triggers from '../Triggers'

import TargetToggle from '../TargetToggle/TargetToggle'
import { Trigger } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import Targeting from '~/dataStore/emailBuilder/Targeting/Targeting'
import Beacon from '~/dataStore/emailBuilder/Targeting/Beacon.model'
import BeaconNameCol from './BeaconNameCol'

const columns = [
  {
    fieldName: 'nickname',
    displayName: 'Beacon name',
    // classes: 'text-truncate',
    render: ({
      row,
      onSelectRow,
      isSelected
    }: {
      row: Beacon & { isSelected: boolean }
      onSelectRow: (row: Beacon, selected: boolean) => void
      isSelected?: boolean
    }) => {
      return (
        <BeaconNameCol row={row} select={onSelectRow} isSelected={isSelected} />
      )
    }
  },
  {
    fieldName: 'triggers',
    displayName: 'Set trigger',
    render: Triggers,
    classes: 'text-end'
  }
]

interface IProps {
  isSended: boolean
  targeting: Targeting
  contentScrollableBlue?: boolean
  onSelectCallback?: (selected: boolean) => void
}

function Beacons({
  isSended,
  targeting,
  contentScrollableBlue,
  onSelectCallback
}: IProps) {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()

  const {
    validateTargetingChoosenOptions,
    beacons: {
      collection,
      fetchBeacons,
      hasMore,
      groups,
      fetchGroups,
      selectedGroups,
      setSelectedGroups,
      searchQuery,
      setSearchQuery,
      selected,
      selectBeacon,
      totalCount,
      setBatchTriger,
      isValid
    }
  } = targeting

  useEffect(() => {
    fetchGroups(appId)
  }, [fetchGroups, appId])

  return (
    <TargetToggle
      fetchResources={() => fetchBeacons(appId, true)}
      disabled={isSended}
      searchPlaceholder="Search for a beacon"
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      groups={groups}
      selectedGroups={selectedGroups}
      setGroups={setSelectedGroups}
      columns={columns}
      rows={collection}
      selectedRows={selected}
      selectRow={(row: any, selected: boolean) => {
        selectBeacon(row, selected)
        onSelectCallback?.(selected)
        if (selected) validateTargetingChoosenOptions()
      }}
      hasMore={hasMore}
      onLoadMore={fetchBeacons}
      totalCount={totalCount}
      invalid={!isValid}
      batchActions={[
        {
          name: 'Set to "On entry"',
          action: setBatchTriger.bind(null, Trigger.ENTER)
        },
        {
          name: 'Set to "On exit"',
          action: setBatchTriger.bind(null, Trigger.EXIT)
        }
      ]}
      contentScrollableBlue={contentScrollableBlue}
    />
  )
}

export default withStore(Beacons)
