import { ReactElement, PropsWithChildren } from 'react'
import { Card } from 'reactstrap'
import cn from 'classnames'
import { matchPath, useLocation } from 'react-router-dom'
import { bg1, bg2, bg3, bg4, bg5 } from './backgrounds'
import pulsateLogo from '~/assets/img/pulsate_logo_blue.svg'

import styles from './AuthPageLayout.scss'
import PulseBanner from '../PulseBanner/PulseBanner'

const AuthPageLayout = ({ children }: PropsWithChildren): ReactElement => {
  const location = useLocation()
  const isRegistrationRoute = () =>
    !!matchPath(location?.pathname, {
      path: ['/admins/registration'],
      exact: true
    })

  const isSigninRoute = () =>
    !!matchPath(location?.pathname, {
      path: ['/admins/sign_in'],
      exact: true
    })

  return (
    <div
      className={cn(styles.authPage, {
        [styles.authPageRegistration]: isRegistrationRoute()
      })}
      style={{
        backgroundImage: `url(${
          [bg1, bg2, bg3, bg4, bg5][Math.round(Math.random() * 4)]
        })`
      }}>
      <Card className={cn(styles.formWrapper, 'mb-0')}>
        <img src={pulsateLogo} alt="Pulsate" width={216} />
        <div className={styles.form}>{children}</div>
      </Card>
      {isSigninRoute() && <PulseBanner />}
    </div>
  )
}

export default AuthPageLayout
