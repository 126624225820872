import { ReactElement } from 'react'
import {
  Card,
  CardBody,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormFeedback
} from 'reactstrap'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAddUserNote, useUserDetails } from '../useUserDetails'
import DebouncedInput from '~/components/forms/DebouncedInput'

const UserNotes = (): ReactElement => {
  const { data: user } = useUserDetails()
  const {
    mutateAsync: addNote,
    isLoading,
    isSuccess,
    isError
  } = useAddUserNote()

  return (
    <Card aria-label="User Notes Section">
      <CardBody className="p-0" aria-label="Notes Body">
        <UncontrolledAccordion defaultOpen="1" aria-label="Notes Accordion">
          <AccordionItem aria-label="Notes Accordion Item">
            <AccordionHeader
              targetId="1"
              className="mb-0"
              aria-label="Notes Header">
              <h3 className="fw-medium" aria-label="Notes Title">
                Notes
              </h3>
            </AccordionHeader>
            <AccordionBody accordionId="1" aria-label="Notes Content">
              <div className="mt-n2" aria-label="Notes Input Section">
                <DebouncedInput
                  debounceTime={500}
                  initialValue={user?.note}
                  placeholder="Add useful notes on individual users in this section."
                  type="textarea"
                  onChange={(value: string) => addNote({ content: value })}
                  aria-label="Notes Input"
                />
                <FormFeedback className="d-block mt-2" valid>
                  {isLoading && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="xs"
                      className=""
                    />
                  )}
                  {isSuccess && 'Saved'}
                </FormFeedback>
                {isError && (
                  <FormFeedback
                    className="d-block mt-2"
                    aria-label="Error Feedback">
                    Failed to save note, please try again later.
                  </FormFeedback>
                )}
              </div>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </CardBody>
    </Card>
  )
}

export default UserNotes
