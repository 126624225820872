import { ID } from '~/common.interface'

export enum JourneyStatus {
  DRAFT = 'Draft',
  PREPARING = 'Preparing',
  SCHEDULED = 'Scheduled',
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  STOPPED = 'Stopped',
  DELIVERED = 'Delivered'
}

export const journeyFields = [
  'name',
  'startAt',
  'endAt',
  'segments',
  'beacons',
  'beaconEvents',
  'geofences',
  'geofenceEvents',
  'geofenceDwellingTimes',
  'timeZoneName',
  'timeZoneOffset',
  'childBlocks',
  'notifications',
  'goals',
  'goalsErrors',
  'options',
  'timeValue',
  'timeFrame',
  'conditions',
  'condition',
  'subType'
] as const

export type JourneyFields = typeof journeyFields[number]
export interface JourneyErrors {
  errors: {
    journey: { errors: Record<JourneyFields, string[]> }
    blocks: { id: ID; errors: Record<JourneyFields, string[]> }[]
  }
}
