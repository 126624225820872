import React from 'react'
import cn from 'classnames'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import style from './style.scss'
import SingleRadio from './SingleRadio.field'

interface IProps<T extends number | string> {
  withDivider?: boolean
  disabled?: boolean
  name: string
  options: (IOption<T> & { className?: string })[]
  value: T
  onChange: (value: T) => void
}

export default function Radio<T extends number | string>({
  withDivider,
  disabled,
  name,
  options,
  value,
  onChange
}: IProps<T>): React.ReactElement {
  return (
    <div className={cn('d-flex align-items-center', { 'o-50': disabled })}>
      {options.map((option, index, { length }) => {
        const checked = option.value === value
        const id = `${option.value || name}-${index}`
        return (
          <React.Fragment key={option.value}>
            <SingleRadio
              id={id}
              name={name}
              onChange={onChange}
              value={option.value}
              checked={checked}
              className={option.className}
              disabled={disabled}
              label={option.label}
            />
            {withDivider && length > index + 1 && (
              <span className={style.checkboxDivider} />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
