import React from 'react'
import { Alert } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import pluralize from 'pluralize'
import { putRequest } from '~/api/requests'
import { NotificationType, showNotification } from '~/utils/Notification'
import Modal from '~/components/modals/Modal/Modal'
import ConfirmationListModal from '~/components/modals/ConfirmationListModal'
import { ID } from '~/common.interface'

type ElementType = 'Journey' | 'Campaign'

interface IStopElementsModalProps {
  elements: Array<{
    id: string
    name: string
  }>
  url: string
  action: (resourceId: ID) => Promise<unknown>
  onSuccess: () => void
  nameKey?: string
  elementType: ElementType
  onClose: () => void
}

const StopElements = ({
  url,
  action,
  elements,
  onSuccess,
  onClose,
  elementType,
  nameKey = 'name'
}: IStopElementsModalProps) => {
  const handleSave = async () => {
    const ids = elements.map((c) => c.id)
    try {
      if (ids.length === 1) {
        await action(ids[0])
      } else {
        await putRequest(url, { resourceIds: ids })
      }
      onSuccess()
      onClose()
      showNotification(
        `${pluralize(elementType, elements.length)} stopped`,
        NotificationType.SUCCESS
      )
    } catch (error) {
      showNotification(
        `Failed to stop ${pluralize(elementType, elements.length)}`,
        NotificationType.ERROR
      )
    }
  }

  if (!elements) return null

  return (
    <ConfirmationListModal
      actionType="stop"
      elementType="Journey"
      elements={elements}
      onClose={onClose}
      onSubmit={handleSave}
      nameKey={nameKey}>
      <Alert
        fade={false}
        closeClassName="d-none"
        color=""
        className="mb-0 mt-4 bg-blue-gray">
        <div className="d-flex align-items-center p-2">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size="2x"
            className="me-3"
          />
          The stop action is irreversible and it will end the{' '}
          {pluralize(elementType, elements.length)} listed above
        </div>
      </Alert>
    </ConfirmationListModal>
  )
}

export default function StopElementsModal({
  elementType
}: {
  elementType: ElementType
}): React.ReactElement {
  return (
    <Modal
      id="stopElementsModal"
      size="md"
      renderHeader={(_, params) =>
        `Stop ${pluralize(elementType, params?.elements?.length || 1)}`
      }
      render={(close: () => void, params) => {
        return (
          <StopElements onClose={close} elementType={elementType} {...params} />
        )
      }}
    />
  )
}
