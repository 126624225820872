import React from 'react'
import Chart from 'react-apexcharts'
import cn from 'classnames'
import { GREEN_CHART_OPTIONS, DEFAULT_OPTIONS } from './chartOptions'
import { IGraphData } from '~/pages/Campaign/CampaignReports/Model/Report.interface'

import './ApexAreaChart.scss'

interface ITooltip {
  series: [][]
  seriesIndex: number
  dataPointIndex: number
  w: unknown
}

interface ICustomTooltip extends ITooltip {
  customLabel: (label: string | number) => void
  themeGreen: boolean | undefined
}

interface IProps {
  graphData: IGraphData[]
  height?: number
  tooltipCustomLabel?: (label: string | number) => void
  options?: any
  themeGreen?: boolean
}

const getCustomTooltip = ({
  series,
  seriesIndex,
  dataPointIndex,
  w,
  customLabel,
  themeGreen
}: ICustomTooltip) => {
  const tooltipClass = cn('apex-area-chart-tooltip', {
    'apex-area-chart-tooltip--theme-green': themeGreen
  })

  return `<div class="${tooltipClass}">
  <span class="apex-area-chart-tooltip__label">${
    customLabel
      ? customLabel(w.globals.categoryLabels[dataPointIndex])
      : w.globals.categoryLabels[dataPointIndex]
  }</span>
  <span class="apex-area-chart-tooltip__value">
    ${series[seriesIndex][dataPointIndex]}
  </span>
</div>`
}

const ApexAreaChart = ({
  graphData,
  tooltipCustomLabel,
  height = 160,
  themeGreen
}: IProps): React.ReactElement => {
  return (
    <Chart
      type="area"
      series={[{ data: graphData }]}
      options={{
        ...DEFAULT_OPTIONS,
        tooltip: {
          custom: (props: ITooltip) =>
            getCustomTooltip({
              ...props,
              themeGreen,
              ...(tooltipCustomLabel && {
                customLabel: tooltipCustomLabel
              })
            })
        },
        ...(themeGreen ? GREEN_CHART_OPTIONS : {})
      }}
      height={height}
    />
  )
}

export default ApexAreaChart
