import { useState } from 'react'
import { ID } from '~/common.interface'

const useSelectListItems = <T extends { id: ID }>(): {
  selectedListItems: Map<ID, T>
  selectListItems: (row: T, isSelected?: boolean) => void
  clearSelectedItems: () => void
} => {
  const [selectedListItems, setSelectedItems] = useState<Map<ID, T>>(new Map())

  const selectListItems = (row: T, isSelected?: boolean): void => {
    if (!isSelected) {
      setSelectedItems((prev) => {
        prev.delete(row.id)
        return new Map(prev)
      })
    } else {
      setSelectedItems((prev) => new Map(prev).set(row.id, row))
    }
  }

  const clearSelectedItems = () => {
    setSelectedItems(new Map())
  }

  return {
    selectedListItems,
    selectListItems,
    clearSelectedItems
  }
}

export default useSelectListItems
