import { Col, FormGroup, Label, Row } from 'reactstrap'
import { HtmlExport } from 'react-email-editor'
import EmailSubjectInput from './EmailSubjectInput'
import FromNameSelect from './FromNameSelect'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import SelectWithSearch from '~/components/forms/SelectWithSearch'
import { createSelectOptions } from '~/utils/createSelectOptions'
import Templates from './Templates'
import EmailTemplates from '../Model/EmailTemplates'
import { useStore, withStore } from '~/dataStore'

interface IProps {
  templates: EmailTemplates
  mergeTags: string[]
  setEditorData: (data: HtmlExport) => void
  templatesSelectionDisabled?: boolean
}

function EmailTemplateBuilder({
  templates,
  mergeTags,
  setEditorData,
  templatesSelectionDisabled = false
}: IProps): React.ReactElement {
  const {
    app: { appDetails }
  } = useStore()

  return (
    <div>
      <Header>
        <HeaderTitle>Choose Template</HeaderTitle>
      </Header>
      <Row>
        <Col widths={['xs', 'xxxl']} xxxl={{ size: 8 }}>
          <EmailSubjectInput
            mergeTags={mergeTags}
            subject={templates.subject}
            setEmailSubject={templates.setEmailSubject}
            validateEmailSubject={templates.validateEmailSubject}
          />
          <Row className="mt-4">
            <Col xs={6}>
              <Label
                className={`fw-medium ${
                  !templates.fromEmailAddress.isValid ? 'error-label' : ''
                }`}>
                From Email Address
              </Label>
              <SelectWithSearch
                placeholder="Address the email will be sent from..."
                value={templates.fromEmailAddress.value?.value}
                options={createSelectOptions(
                  appDetails?.verifiedEmailAddresses
                )}
                onChange={templates.setFromEmailAddress}
                onBlur={templates.validateFromEmailAddress}
                invalid={!templates.fromEmailAddress.isValid}
              />
            </Col>
            <Col xs={6}>
              <FormGroup
                className={
                  !templates.fromName.isValid ? 'form-group--error' : ''
                }>
                <Label className="mt-0 fw-medium">From name</Label>
                <FromNameSelect
                  fromNames={templates.fromNames}
                  fromName={templates.fromName}
                  setFromName={templates.setFromName}
                  addFromName={templates.addFromName}
                  validateFromName={templates.validateFromName}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      <Templates
        isChanged={templates.isChanged}
        layouts={templates.layouts}
        templates={templates.templates}
        fetchLayouts={templates.fetchLayouts}
        currentTemplate={templates.currentTemplate}
        setEditorData={setEditorData}
        validateTemplate={templates.validateTemplate}
        setCurrentTemplate={templates.setCurrentTemplate}
        deleteEmailTemplate={templates.deleteEmailTemplate}
        fetchEmailTemplates={templates.fetchEmailTemplates}
        getCurrentTemplateType={templates.getCurrentTemplateType}
        templatesSelectionDisabled={templatesSelectionDisabled}
      />
    </div>
  )
}

export default withStore(EmailTemplateBuilder)
