import { useState } from 'react'
import { DeviceType } from '~/dataStore/Campaign/Campaign.interface'

export default function useDeviceTypeSwitch(
  _deviceType: DeviceType
): {
  deviceType: DeviceType
  setDeviceType: (device: DeviceType) => void
} {
  const [deviceType, setDeviceType] = useState<DeviceType>(_deviceType)

  return {
    deviceType,
    setDeviceType
  }
}
