import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import AddToSegment from '../Blocks/AddToSegment'
import Input from '~/components/forms/Input'

interface IProps {
  block: AddToSegment
  disabled: boolean
}

export default function AddToSegmentPanel({
  block,
  disabled
}: IProps): React.ReactElement {
  return (
    <div className="px-4">
      <Header className="mb-6">
        <HeaderTitle tag="h2">Auto Segment</HeaderTitle>
        <p className="color-subheader">
          A segment will be created automatically
        </p>
      </Header>

      <Input
        disabled={disabled}
        label="Segment Name"
        placeholder="Enter Segment Name"
        invalid={!block.name.isValid}
        value={block.name.value}
        onChange={(e) => block.setName(e.target.value)}
        maxLength={64}
      />
    </div>
  )
}
