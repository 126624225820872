import React, { useMemo } from 'react'
import MultiSelect from '~/components/forms/MultiSelect'
import { handleLongName } from '~/utils/string'
import { NotificationType, showNotification } from '~/utils/Notification'
import { deleteRequest, postRequest } from '~/api/requests'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import getGroupsColumnOptions from './GroupsColumnOptions'
import useMatchMedia from '~/hooks/useMatchMedia'
import { useStore, withStore } from '~/dataStore'

interface IRow {
  id: string
  groups: IGroup[]
  onUpdateRow: (row: unknown) => void
  batchActionUrls: {
    addToGroup: string
    removeFromGroup: string
  }
  ariaLabel?: string
}

function getLabel(groups: IGroup[]) {
  const isDesktopLarge = useMatchMedia('(min-width: 1600px)')
  let label
  switch (groups.length) {
    case 0:
      label = 'No group'
      break
    case 1:
      label = handleLongName(groups[0].name, isDesktopLarge ? 18 : 10)
      break
    default:
      label = `${handleLongName(groups[0].name, isDesktopLarge ? 14 : 6)} (+${
        groups.length - 1
      })`
  }
  return <span className="overflow-hidden">{label}</span>
}

const GroupsColumn = (
  props: IRow & { onUpdateRow: (row: IRow) => void }
): React.ReactElement => {
  const {
    groups: { groups: allGroups }
  } = useStore()

  const { id, batchActionUrls, groups, ariaLabel } = props
  const unselectedGroups = useMemo(
    () => allGroups.filter((g) => !groups.find((selGrp) => g.id === selGrp.id)),
    [groups, allGroups]
  )

  const addToGroup = async (
    groupId: string | undefined,
    params: { groupId: string | undefined; resourceIds: string[] }
  ) => {
    try {
      await postRequest(batchActionUrls.addToGroup, params)
      props.onUpdateRow({
        ...props,
        groups: [
          ...groups,
          {
            id: groupId,
            name: allGroups.find((g: IGroup) => g.id === groupId)?.name
          }
        ]
      })
      showNotification('Added to group!', NotificationType.SUCCESS)
    } catch (error) {
      showNotification(
        'Failed to add to group, please try again later.',
        NotificationType.ERROR
      )
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const deleteFromGroup = async (
    groupId: string | undefined,
    params: { groupId: string | undefined; resourceIds: string[] }
  ) => {
    try {
      await deleteRequest(batchActionUrls.removeFromGroup, params)
      props.onUpdateRow({
        ...props,
        groups: groups.filter((g) => g.id !== groupId)
      })
      showNotification('Removed from group!', NotificationType.SUCCESS)
    } catch (error) {
      showNotification(
        'Failed to remove from group, please try again later.',
        NotificationType.ERROR
      )
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const toggleGroup = async (
    selectedGroupIds: string[],
    toggledGroupId?: string
  ) => {
    const params = { groupId: toggledGroupId, resourceIds: [id] }

    if (selectedGroupIds.length > groups.length) {
      await addToGroup(toggledGroupId, params)
    } else {
      await deleteFromGroup(toggledGroupId, params)
    }
  }

  return (
    <div className="mw-100 groups-table-column">
      <MultiSelect
        title={getLabel(groups)}
        selectedOptions={groups.map((g) => g.id)}
        onSelect={toggleGroup}
        options={getGroupsColumnOptions(groups, unselectedGroups)}
        className="border-0 ps-0 text-start"
        buttonDropdownStyle={{ width: '100%' }}
        aria-label={ariaLabel}
      />
    </div>
  )
}

export default withStore(GroupsColumn)
