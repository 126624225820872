import { ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Input from '~/components/forms/Input'
import {
  getFormServerSideErrors,
  hookFormValidation,
  showGeneralError
} from '~/utils/validations'
import { resetPassword } from '~/api/auth'
import ProtectedSiteInfo from '~/pages/Auth/components/ProtectedSiteInfo'
import SuccessMessage from '../components/SuccessMessage'

const ForgotPassword = (): ReactElement => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    clearErrors,
    setError,
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful }
  } = useForm<{ email: string }>()

  const onSubmit = async (data: { email: string }) => {
    if (!executeRecaptcha) return
    const token = await executeRecaptcha('forgotPassword')
    try {
      await resetPassword({ ...data, 'g-recaptcha-response': token })
    } catch (error) {
      if (error.body.errors) {
        getFormServerSideErrors(error.body.errors).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <>
      {!isSubmitSuccessful && (
        <form
          onSubmit={(e) => {
            clearErrors()
            handleSubmit(onSubmit)(e)
          }}>
          <h2 className="" aria-label="Forgot your password">
            Forgot your password
          </h2>
          <Input
            name="email"
            placeholder="Enter Email"
            aria-label="Email"
            className={cn('my-4 py-2', {
              'form-group--error': errors.email
            })}
            errorTooltip={errors?.email?.message}
            validation={hookFormValidation.email}
            label="Email"
            register={register}
          />
          <div className="mb-3 form-general-error">
            {errors.password?.message}
          </div>
          <Button
            type="submit"
            aria-label="Reset your password"
            color="body"
            disabled={isSubmitting}
            className={cn(
              'd-flex align-items-center justify-content-center w-100 btn--hover',
              { disabled: !isValid || !executeRecaptcha }
            )}>
            Reset your password{' '}
            {isSubmitting && (
              <FontAwesomeIcon
                className="ms-2"
                icon={faSpinner}
                spin
                size="xs"
              />
            )}
          </Button>
          <ProtectedSiteInfo />
        </form>
      )}
      {isSubmitSuccessful && (
        <SuccessMessage aria-label="Success message">
          You will receive an email with instructions on how to reset your
          password in a few minutes.
        </SuccessMessage>
      )}
    </>
  )
}

export default ForgotPassword
