import React from 'react'
import { Handle, Position } from 'reactflow'
import cn from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import NodeIcon from '~/pages/Journeys/JourneyBuilder/Nodes/NodeIcon'
import { NodeWithData } from '../Store/JourneyBuilder.interface'

export default function Entry({
  id,
  data,
  selected
}: NodeWithData): React.ReactElement {
  return (
    <div
      id={id}
      className={cn('flow-node flow-node--entry', {
        'flow-node--active': selected,
        'flow-node--filled-fuchsia': data.filled,
        'flow-node--error': !data.block.isValid
      })}>
      <div className="flow-node__icon-wrapper">
        <NodeIcon />
      </div>
      <div className="flow-node__hover">
        <div className="flow-node__wrapper">
          <Handle
            type="source"
            position={Position.Bottom}
            className="custom-handle"
          />
          <div className="flow-node__label">
            {data.multiLabel && data.multiLabel.length > 0 ? (
              data.multiLabel.map((item) => (
                <React.Fragment key={item.header}>
                  <p className="text-truncate mb-0" id={item.header}>
                    <span className="o-50">{item.header}:</span> {item.value}
                  </p>
                  <UncontrolledTooltip
                    fade={false}
                    delay={300}
                    placement="top"
                    target={item.header}>
                    {item.value}
                  </UncontrolledTooltip>
                </React.Fragment>
              ))
            ) : (
              <p className="text-truncate mb-0" id="entry-segments">
                {data.label}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
