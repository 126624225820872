import { ReactElement, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { Button } from 'reactstrap'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from '~/components/forms/Input'
import { getFormServerSideErrors } from '~/utils/validations'
import {
  ERRORS_DICT,
  TStep2FormFields,
  STEP2_FORM_FIELDS
} from '~/pages/Auth/NewAccountRegistration/NewAccountRegistration.interface'

interface IProps {
  goToPreviousStep: () => void
  saveForm: (data: TStep2FormFields) => void
  isActive: boolean
  serverSideErrors: unknown
  isLoading: boolean
}

const Step2 = ({
  goToPreviousStep,
  isActive,
  saveForm,
  serverSideErrors,
  isLoading
}: IProps): ReactElement => {
  const {
    clearErrors,
    setError,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<TStep2FormFields>({ mode: 'onBlur' })

  const onSubmit = (data: TStep2FormFields) => {
    saveForm(data)
  }

  useEffect(() => {
    if (serverSideErrors) {
      getFormServerSideErrors(serverSideErrors, ERRORS_DICT).forEach((er) => {
        if (STEP2_FORM_FIELDS.includes(er.name)) {
          setError(er.name, { type: 'server', message: er.message })
        }
      })
    } else {
      clearErrors()
    }
  }, [serverSideErrors, setError])

  return (
    <form
      onSubmit={(e) => {
        clearErrors()
        handleSubmit(onSubmit)(e)
      }}>
      <Input
        name="companyName"
        validation={{
          required: {
            value: true,
            message: 'Company is missing'
          }
        }}
        errorTooltip={errors?.companyName?.message}
        className={cn('mt-3', { 'form-group--error': errors.companyName })}
        tabIndex={isActive ? 0 : -1}
        placeholder="Enter Company Name"
        label="Company Name"
        register={register}
        aria-label="Company Name"
      />
      <Input
        name="appName"
        validation={{
          required: {
            value: true,
            message: 'App Name is missing'
          }
        }}
        errorTooltip={errors?.appName?.message}
        className={cn('mt-3', { 'form-group--error': errors.appName })}
        tabIndex={isActive ? 0 : -1}
        placeholder="Enter App Name"
        label="App Name"
        register={register}
        aria-label="App Name"
      />
      <Input
        name="activationCode"
        validation={{
          required: {
            value: true,
            message: 'Activation Code is missing (length 36 or 40)'
          }
        }}
        errorTooltip={errors?.activationCode?.message}
        className={cn('mt-3', { 'form-group--error': errors.activationCode })}
        tabIndex={isActive ? 0 : -1}
        placeholder="Enter Activation Code"
        label="Activation Code"
        register={register}
        aria-label="Activation Code"
      />
      <Button
        disabled={isLoading}
        tabIndex={isActive ? 0 : -1}
        onClick={goToPreviousStep}
        color=""
        className="d-block mt-4 w-100 btn-outline-body btn--hover"
        aria-label="Previous">
        Previous
      </Button>
      <Button
        tabIndex={isActive ? 0 : -1}
        disabled={isLoading}
        type="submit"
        color="body"
        className="d-flex align-items-center justify-content-center mt-2 w-100 btn--hover"
        aria-label="Create account">
        Create account{' '}
        {isLoading && (
          <FontAwesomeIcon className="ms-2" icon={faSpinner} spin size="xs" />
        )}
      </Button>
    </form>
  )
}

export default Step2
