import { get, post, put, destroy } from './v2'
import { getRequest } from '~/api/requests'
import { IEmailTemplate } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { IMetaData } from '~/common.interface'

export function getEmailTemplates(
  appId: string
): Promise<{ data: IEmailTemplate[]; metadata: IMetaData }> {
  return get(`${appId}/email_templates`)
}

export function getLayouts(): Promise<{
  data: IEmailTemplate[]
  metadata: IMetaData
}> {
  return getRequest('/api/v2/default_email_templates')
}

export function saveEmailTemplate(
  appId: string,
  design: {
    name?: string
    design: string | null
    html?: string
  },
  signal: AbortSignal | undefined = undefined
): Promise<IEmailTemplate> {
  return post(`${appId}/email_templates`, design, signal)
}

export function overwriteEmailTemplate(
  appId: string,
  templateId: string | undefined,
  design: { name?: string; design: string | null; html?: string; image: string }
): Promise<void> {
  return put(`${appId}/email_templates/${templateId}`, design)
}

export function deleteEmailTemplate(
  appId: string,
  templateId: string
): Promise<void> {
  return destroy(`${appId}/email_templates/${templateId}`)
}
