import React from 'react'
import useInfiniteScroll from '~/hooks/useInfiniteScroll'
import styles from '~/pages/Messages/Messages.scss'
import Message from '~/pages/Messages/components/Message'
import { IMessage } from '~/pages/Messages/Messages.interface'
import Toolbar from '~/pages/Messages/components/Toolbar'

interface IProps {
  queryParams: { [key: string]: string }
  handleSearch: (searchQuery: string) => void
  searchQuery: string
  hasMore: boolean | undefined
  onLoadMore: () => void
  messages: IMessage[]
  isLoading: boolean
  unreadCount: number
}

const MessagesList = ({
  handleSearch,
  searchQuery,
  queryParams,
  hasMore,
  onLoadMore,
  messages,
  isLoading,
  unreadCount
}: IProps) => {
  const bottomRef = useInfiniteScroll({
    hasMore,
    onLoadMore
  })

  return (
    <>
      <Toolbar
        unreadCount={unreadCount}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        queryParams={queryParams}
      />
      <div className={styles.messagesWrapper}>
        {messages.map((message) => (
          <Message
            queryParams={queryParams}
            message={message}
            key={message.id}
          />
        ))}
        {messages.length === 0 && (
          <div className="d-flex justify-content-center my-2">
            <div className="text-muted">No messages found</div>
          </div>
        )}
        {/* height prop for making bottomRef visible on the screen */}
        <div ref={bottomRef} style={{ height: '1px' }} />
        {isLoading && (
          <div className="d-flex justify-content-center my-2">
            <div
              className="spinner-border text-primary spinner-border-sm"
              role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MessagesList
