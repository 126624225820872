import React from 'react'
import cn from 'classnames'
import { BATTERY_STATUS, IBeaconRow } from '../../Beacons.interface'
import BatteryStatusDot from '../BatteryStatusDot'
import BeaconsTableRowActions from './BeaconsTableRowActions'
import GroupsColumn from '~/components/Table/components/GroupsColumn'
import { IColumn } from '~/components/Table/Table.interface'

import { breakLine, col, colWide } from '~/components/Table/Table.scss'

const groupsColumn = ({
  row,
  onUpdateRow
}: {
  row: IBeaconRow
  onUpdateRow: (beacon: IBeaconRow) => void
}) => <GroupsColumn {...row} onUpdateRow={onUpdateRow} />

const beaconsTableColumns = ({
  onEditRow
}: {
  onEditRow: (row: IBeaconRow) => void
}): IColumn<string>[] => [
  {
    fieldName: 'nickname',
    displayName: 'Beacon name',
    render: ({ row: { nickname } }: { row: { nickname: string } }) => (
      <div className={cn(col, colWide, breakLine)}>{nickname}</div>
    ),
    sortable: true
  },
  {
    fieldName: 'major',
    displayName: 'Major',
    sortable: false
  },
  {
    fieldName: 'minor',
    displayName: 'Minor',
    sortable: false
  },
  {
    fieldName: 'cachedBeaconEventsCount',
    displayName: 'Beacon Impressions',
    sortable: true
  },
  {
    fieldName: 'battery',
    displayName: 'Battery Status',
    render: ({ row: { battery } }: { row: { battery: BATTERY_STATUS } }) => (
      <BatteryStatusDot batteryStatus={battery} />
    ),
    sortable: false
  },
  {
    fieldName: 'groupsIds',
    displayName: 'Groups',
    render: groupsColumn,
    sortable: false,
    classes: 'table__col--wide table__col--groups-header'
  },
  {
    displayName: 'Actions',
    sortable: false,
    render: ({
      onDeleteRow,
      row
    }: {
      onDeleteRow: (row: IBeaconRow) => void
      row: IBeaconRow
    }) => (
      <BeaconsTableRowActions
        onDeleteRow={() => {
          onDeleteRow(row)
        }}
        onEditRow={() => {
          onEditRow(row)
        }}
        row={row}
      />
    )
  }
]

export default beaconsTableColumns
