import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/react-query'
import { ID } from '~/common.interface'
import { showGeneralError } from '~/utils/validations'
import { fetchJourneys } from '../Connector/Journeys.connector'
import {
  IJourney,
  IJourneysListDTO,
  IJourneysQueryParams
} from './JourneysList.interface'

export enum ServerStateKeysEnum {
  Journeys = 'journeys'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useJourneys(
  appId: ID,
  queryParams: IJourneysQueryParams
) {
  return useInfiniteQuery<IJourneysListDTO, unknown, IJourney>(
    [
      ServerStateKeysEnum.Journeys,
      {
        ...queryParams
      }
    ],
    ({ pageParam = 1 }) =>
      fetchJourneys(appId, { ...queryParams, page: pageParam }),
    {
      select: (data): InfiniteData<IJourney> & { bulkActions: any } => {
        const metadata = data.pages.at(-1)?.metadata || { totalPages: 0 }
        return {
          ...data,
          pages: data.pages.flatMap((page) => page.data),
          metadata,
          bulkActions: data.pages.at(-1)?.bulkActions
        }
      },
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.totalPages > lastPage.metadata.page
          ? lastPage.metadata.page + 1
          : undefined,
      onError: (error) => {
        console.error(error)
        showGeneralError()
      }
    }
  )
}

export const useInvalidateJourneys = () => {
  const cache = useQueryClient()
  return () => cache.invalidateQueries([ServerStateKeysEnum.Journeys])
}
