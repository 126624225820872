import { Button } from 'reactstrap'
import cn from 'classnames'
import Template from '~/pages/Campaign/CampaignReview/Template'
import styles from '../../JourneyBuilderSidebar/Sidebar.scss'
import JourneyEmailNotificationStore from './Store/JourneyEmailNotificationStore'

interface IProps {
  close: () => void
  emailNotification: JourneyEmailNotificationStore
}

export default function EmailNotificationDetailsPreview({
  emailNotification,
  close
}: IProps): React.ReactElement {
  const { goals, html, fromName, source, subject } =
    emailNotification.generatePreview().email

  return (
    <div
      className={cn(
        'h-100 d-flex align-items-center justify-content-center',
        styles.sidebarBlock
      )}>
      <Template
        fromName={fromName}
        subject={subject}
        fromEmailAddress={source}
        html={html}
        goals={goals}
      />
      <div
        className={cn(
          styles.sidebarBtns,
          'd-flex justify-content-end align-items-center'
        )}>
        <Button
          color=""
          onClick={() => {
            close()
          }}>
          Back to Message
        </Button>
      </div>
    </div>
  )
}
