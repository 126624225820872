import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap'
import cn from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Input from '~/components/forms/Input'
import CircleIcon from '~/components/CircleIcon'
import { useStore, withStore } from '~/dataStore'
import { updateApplicationSettings } from '~/api/appSettings'
import { NotificationType, showNotification } from '~/utils/Notification'
import { ERRORS_DICT, FormValues } from './ApplicationDetailsSection.interface'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import defaultAppLogo from '~/assets/img/default-app-logo.svg'
import { convertImageToUrl } from '~/utils/file.service'
import UploadAvatar from '~/components/UploadAvatar'

const ApplicationDetailsSection = () => {
  const {
    app: {
      appDetails,
      setAppDetails,
      currentApp,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    control,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    values: {
      name: appDetails?.name,
      company: appDetails?.company,
      ...(!appDetails?.isDefaultImage && {
        appImage: {
          objectURL: appDetails?.image,
          fileName: appDetails?.image,
          valid: true
        }
      })
    },
    defaultValues: {
      appImage: { objectURL: '', fileName: '', file: null, valid: true }
    }
  })

  const onUpdateError = (err) => {
    if (err.body?.errors) {
      getFormServerSideErrors(err.body.errors, ERRORS_DICT).forEach((er) =>
        setError(er.name, { type: 'server', message: er.message })
      )
    } else {
      showGeneralError()
    }
  }

  const onSubmit = async (data: FormValues) => {
    const { appImage } = data
    const payload: { name: string; reset: string; imageS3Url?: string } = {
      name: data.name || '',
      reset: `${!appImage.file && !appImage.objectURL}`
    }
    if (appImage.file) {
      try {
        payload.imageS3Url = await convertImageToUrl(
          currentApp.id,
          appImage.file
        )
      } catch (e) {
        setError('uploadError', { type: 'server', message: e.body.error })
        return
      }
    }

    try {
      const response = await updateApplicationSettings(currentApp.id, payload)
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
      setAppDetails({
        name: data.name,
        isDefaultImage: !appImage.file && !appImage.objectURL,
        image: response.image || defaultAppLogo,
        icon: response.icon || defaultAppLogo
      })
    } catch (err) {
      onUpdateError(err)
    }
  }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h3" className="mb-0 mt-2">
          Configurations
        </CardTitle>
        {appDetails?.installed && (
          <div className="d-flex align-items-center mt-2">
            <span className="fw-medium" id="installed-info">
              App installed
            </span>
            <CircleIcon className="ms-3" icon={faCheck} bg="info" />
            <UncontrolledTooltip placement="top" target="installed-info">
              The app has at least one user who logs in to a mobile bank app
              integrated with our SDK
            </UncontrolledTooltip>
          </div>
        )}
      </CardHeader>
      <CardBody>
        <form
          className="w-100 py-3"
          onSubmit={(e) => {
            clearErrors()
            handleSubmit(onSubmit)(e)
          }}>
          <Row>
            <Col xs={8}>
              <Input
                label="App Name"
                register={register}
                name="name"
                errorTooltip={errors.name?.message}
                className={cn('mb-3', {
                  'form-group--error': !!errors.name
                })}
              />
              <Input
                register={register}
                label="Company"
                name="company"
                disabled
                className="mb-3"
              />
              <Button
                color="primary"
                disabled={
                  isSubmitting ||
                  watch('appImage')?.valid === false ||
                  isDemoAdmin
                }
                className="mt-4 d-block">
                Save Changes
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-2"
                  />
                )}
              </Button>
            </Col>
            <Col xs={4} className="d-flex justify-content-center pe-5">
              <div className="mt-3 position-relative">
                <Controller
                  control={control}
                  name="appImage"
                  register={register}
                  render={({ field: { onChange, value } }) => (
                    <UploadAvatar
                      onChange={({ objectURL, fileName, file, valid }) => {
                        clearErrors('uploadError')
                        onChange({ objectURL, fileName, file, valid })
                      }}
                      image={value.objectURL}
                      fileName={value.fileName}
                      error={errors.uploadError?.message}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  )
}

export default withStore(ApplicationDetailsSection)
