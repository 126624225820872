import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import DomainsTable from './components/DomainsTable'
import {
  useGenerateKey,
  useGetWebSDKCredentials
} from '~/pages/App/Settings/sections/WebSDKSection/useWebSDKCredentials'
import { useStore } from '~/dataStore'

const WebSDKSection = (): React.ReactElement => {
  const { mutate: generate, isLoading: isGenerating } = useGenerateKey()
  const {
    app: {
      currentApp,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const { data: webSDKCredentials } = useGetWebSDKCredentials(currentApp.id)

  const key = webSDKCredentials?.webSdkAccessKey?.key

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className="mb-0 mt-2">
          Web SDK Credentials
        </CardTitle>
      </CardHeader>
      <CardBody className="pb-5 pt-3">
        <Row>
          <Col xs={12}>
            <DomainsTable domains={webSDKCredentials?.webSdkDomains || []} />
            <div className="divider border-top border-1 mt-5" />
            <p className="fw-medium mb-1 mt-4 pt-1">Web SDK key</p>
            <Button
              color="primary"
              disabled={isGenerating || isDemoAdmin}
              className="mt-2"
              onClick={() => generate()}>
              {key ? 'Regenerate' : 'Generate'} key
              {isGenerating && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="xs"
                  className="ms-2"
                />
              )}
            </Button>
            <p className="mt-3">{key}</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default WebSDKSection
