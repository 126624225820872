import { Container } from 'reactstrap'
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'
import { useStore, withStore } from '~/dataStore'

import routes from '~/routes'
import { JourneyProviderWithStore } from './JourneyBuilder/Journey.context'
import JourneysList from './JourneysList'
import NewJourney from './NewJourney'
import JourneyBuilder from './JourneyBuilder'
import JourneyTeaser from './JourneyTeaser/JourneyTeaser'
import JourneyReport from './JourneyReport/JourneyReport'
import { JourneyReportProviderWithStore } from './JourneyReport/JourneyReport.context'

function Journeys(): React.ReactElement | null {
  const { path } = useRouteMatch()
  const {
    app: { appDetails, currentApp }
  } = useStore()

  if (!appDetails?.id) {
    return null
  }

  if (!appDetails?.featureFlags.journeys) {
    return (
      <Switch>
        <Route exact path={path}>
          <JourneyTeaser />
        </Route>
        <Route
          path="*"
          render={() => (
            <Redirect
              to={generatePath(routes.journeys.path, { appId: currentApp.id })}
            />
          )}
        />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Container fluid>
          <JourneysList />
        </Container>
      </Route>
      <Route exact path={routes.journeysBuilder.path}>
        <JourneyProviderWithStore>
          <ReactFlowProvider>
            <JourneyBuilder />
          </ReactFlowProvider>
        </JourneyProviderWithStore>
      </Route>
      <Route exact path={routes.journeysNew.path}>
        <Container fluid>
          <NewJourney />
        </Container>
      </Route>
      <Route exact path={routes.journeysReport.path}>
        <Container fluid>
          <JourneyReportProviderWithStore>
            <JourneyReport />
          </JourneyReportProviderWithStore>
        </Container>
      </Route>
      <Route
        path="*"
        render={() => (
          <Redirect
            to={generatePath(routes.journeys.path, { appId: currentApp.id })}
          />
        )}
      />
    </Switch>
  )
}

export default withStore(Journeys)
