import moment from 'moment-timezone'
import cn from 'classnames'

import { useEffect, useState } from 'react'
import DatepickerInput from '~/components/forms/DatePicker'
import TimePicker from '~/components/forms/TimePicker'
import { setHours, setMinutes } from '~/utils/utilities'

interface IProps {
  className?: string
  value: Date | null
  label?: string
  labelClassName?: string
  onChange: (value: Date) => void
  minDate?: Date | null | undefined
  invalid?: boolean
  disabled?: boolean
  [x: string]: unknown
}

const DateAndTimeInput = ({
  label,
  labelClassName,
  className,
  onChange,
  value,
  minDate,
  invalid,
  disabled,
  ...rest
}: IProps): React.ReactElement => {
  const [state, setState] = useState({ date: value, time: value })

  function handleChange(date: Date | null, time: Date | null): void {
    setState({ date, time })

    if (!date || !time) {
      return
    }

    onChange(
      moment(date)
        .set({
          hour: moment(time).get('hour'),
          minute: moment(time).get('minute')
        })
        .toDate()
    )
  }

  const onDateChange = (date: Date): void => {
    handleChange(date, state.time)
  }

  const onTimeChange = (time: Date): void => {
    handleChange(state.date, time)
  }

  useEffect(() => {
    setState({ date: value, time: value })
  }, [value])

  function getMinTime() {
    if (!minDate) {
      return undefined
    }

    const date = minDate
    if (
      state.date &&
      !moment(minDate).startOf('day').isSame(state.date, 'day')
    ) {
      return setHours(setMinutes(date, 0), 0)
    }

    return setHours(
      setMinutes(minDate, minDate.getMinutes()),
      minDate.getHours() || 0
    )
  }

  function getMaxTime() {
    if (!minDate) {
      return undefined
    }

    return setHours(setMinutes(new Date(), 59), 23)
  }

  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <span
          className={cn(
            labelClassName,
            invalid ? 'error-label o-100' : '',
            'o-50 fw-medium'
          )}>
          {label}
        </span>
        <DatepickerInput
          disabled={disabled}
          value={state.date}
          minDate={minDate}
          onChange={onDateChange}
          name="start"
          invalid={invalid}
          {...rest}
        />
      </div>
      <div className="ms-2">
        <TimePicker
          disabled={disabled}
          value={state.time}
          minTime={getMinTime()}
          maxTime={getMaxTime()}
          onChange={onTimeChange}
        />
      </div>
    </div>
  )
}

export default DateAndTimeInput
