import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import JourneyTemplateIcon from '~/pages/Journeys/UI/JourneyTemplateIcon/JourneyTemplateIcon'
import Input from '~/components/forms/Input'
import { withStore } from '~/dataStore'
import { JourneyTemplate } from '../../Store/JourneyBuilder.interface'
import { ID } from '~/common.interface'

interface IProps {
  description?: string
  goToTemplates: () => void
  journeyId?: ID
  isSaving: boolean
  name: string
  saveJourney: () => void
  setDescription: (value: string) => void
  setName: (value: string) => void
  template?: JourneyTemplate
  disabled: boolean
  close: () => void
}

const Setup = ({
  description,
  goToTemplates,
  journeyId,
  isSaving,
  name,
  saveJourney,
  setDescription,
  setName,
  template,
  disabled,
  close
}: IProps) => {
  return (
    <>
      <Row className="mt-4 pt-1">
        <Col xs={6}>
          <Input
            name="name"
            label="Journey Name"
            type="text"
            value={name}
            disabled={disabled}
            className="mb-3"
            maxLength={80}
            onChange={(e) => {
              setName(e.target.value)
            }}
            placeholder="Enter journey name"
          />
          <Input
            className="pt-3"
            name="description"
            value={description}
            label="Description"
            disabled={disabled}
            rows={8}
            onChange={(e) => {
              setDescription(e.target.value)
            }}
            type="textarea"
            placeholder="Enter journey description"
          />
        </Col>
        {template && (
          <Col xs={6} className="ps-4">
            <JourneyTemplateIcon src={template.smallImage} />
            <p className="text-18 fw-medium mt-4">{template.name}</p>
            <p className="fw-medium color-subheader">{template.description}</p>
          </Col>
        )}
      </Row>
      {disabled && (
        <div className={cn('mt-4 pt-2 d-flex justify-content-end')}>
          <Button
            color=""
            onClick={close}
            data-testid="journeySetupModalCloseBtn">
            Close
          </Button>
        </div>
      )}

      {!disabled && (
        <div
          className={cn(
            'mt-4 pt-2 d-flex',
            { 'justify-content-between': !journeyId },
            { 'justify-content-end': journeyId }
          )}>
          <>
            {!journeyId && (
              <Button
                color=""
                className="ps-0"
                onClick={goToTemplates}
                data-testid="chooseDifferentTemplateBtn">
                {'< Choose different template'}
              </Button>
            )}

            <Button
              data-testid="save-journey"
              color="body"
              disabled={!name || isSaving}
              onClick={saveJourney}
              className="ms-2 btn--hover d-flex align-items-center">
              {journeyId ? 'Save changes' : 'Start building'}
              {isSaving && (
                <FontAwesomeIcon
                  className="ms-2"
                  icon={faSpinner}
                  spin
                  size="xs"
                />
              )}
            </Button>
          </>
        </div>
      )}
    </>
  )
}

export default withStore(Setup)
