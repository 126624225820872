import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import { withStore, useStore } from '~/dataStore'
import { isoDate } from '~/utils/time'
import { getRequest } from '~/api/requests'
import useQueryParams from '~/hooks/useQueryParams'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import SelectDays from '~/pages/App/Dashboard/UI/SelectDays'

import AppStats from './AppStats'
import DauMauCharts from './DauMauCharts'
import UserCharts from './UserCharts'
import UserStats from './UserStats'

const AppDashboard = () => {
  const [data, setData] = useState()
  const queryParams = useQueryParams({ days: 7 })
  const history = useHistory()
  const {
    goTo,
    app: {
      openCurtain,
      appDetails: { featureFlags }
    }
  } = useStore()

  const { appId } = useParams<{ appId: string }>()

  useEffect(() => {
    const { days } = queryParams

    if (!days) return

    getRequest(`/mobile/apps/${appId}/dashboards.json`, {
      from: isoDate(moment().subtract(days, 'days')),
      to: isoDate()
    }).then((response) => {
      setData(response)
      openCurtain()
    })
  }, [appId, queryParams.days, openCurtain])

  useEffect(() => {
    if (featureFlags.oldAppAndDeviceStats === false) {
      if (featureFlags.dashboardBeta) {
        goTo('dashboard', { appId })
      } else {
        history.push('/')
      }
    }
  }, [featureFlags.oldAppAndDeviceStats, featureFlags.dashboardBeta])

  return (
    <>
      <Container fluid>
        <Header className="d-flex justify-content-between align-items-end mt-0 pt-3 mb-4">
          <HeaderTitle className="mb-0">App Usage</HeaderTitle>
          <SelectDays
            queryParams={queryParams}
            className="d-flex justify-content-end align-items-center"
          />
        </Header>
        {data && (
          <>
            <AppStats
              totalUsers={data.users.total}
              dauMau={data.dauMau}
              totalCampaignSends={data.campaigns.sent}
              days={queryParams.days}
            />
            <DauMauCharts {...data.dauMau} />
            <UserStats users={data.users} days={queryParams.days} />
            <UserCharts users={data.users} />
          </>
        )}
      </Container>
    </>
  )
}

export default withStore(AppDashboard)
