import { Handle, Position } from 'reactflow'
import cn from 'classnames'
import GoalIcon from '~/pages/Campaign/Notification/NotificationGoals/UI/GoalIcon'
import NodeIcon from './NodeIcon'
import {
  NodeType,
  NodeWithData
} from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'

export default function SplitNode({
  id,
  data
}: NodeWithData): React.ReactElement {
  return (
    <div
      id={id}
      className={cn('flow-node flow-node--split', {
        'flow-node--filled': data.filled,
        'flow-node--error': !data.block.isValid
      })}>
      <div className="flow-node__icon-wrapper">
        <NodeIcon type={NodeType.SPLIT} />
      </div>
      <div className="flow-node__hover">
        <div className="flow-node__wrapper">
          <Handle
            type="target"
            position={Position.Top}
            className="custom-handle"
          />
          <p className="flow-node__label">
            {data.label} {data.goalType && <GoalIcon type={data.goalType} />}
          </p>
          <Handle
            type="source"
            position={Position.Bottom}
            className="custom-handle"
          />
        </div>
      </div>
    </div>
  )
}
