import React, { Fragment } from 'react'
import cn from 'classnames'
import { getPreviewByOptionType } from '~/dataStore/CampaignTypeFacade'
import {
  CampaignType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import { Preview } from '../../CampaignReview/CampaignReview.interface'
import DeviceTypeSwitch from './DeviceTypeSwitch'
import useDeviceTypeSwitch from './useDeviceTypeSwitch'
import PreviewMobile from './PreviewMobile'
import { isDeviceSpecificCampaign } from './Preview.service'
import PreviewPopover from './PreviewPopover'

interface IProps {
  preview: Preview
}

export default function AllMobilePreviews({
  preview
}: IProps): React.ReactElement {
  const { deviceType, setDeviceType } = useDeviceTypeSwitch('ios')

  return (
    <>
      <div className="d-flex justify-content-center position-relative">
        {preview.type.map((type) => {
          const parts: IPreviewParts = getPreviewByOptionType(type, preview)
          const deviceSpecificCampaign = isDeviceSpecificCampaign(type.original)

          const campaignType = type.original

          return (
            <Fragment key={type.value}>
              <div
                className={cn('mx-4', {
                  'position-relative': ![
                    CampaignType.PUSH,
                    CampaignType.CARD_FRONT,
                    CampaignType.CARD_BACK
                  ].includes(campaignType)
                })}>
                <h3 className="text-center">{type.label}</h3>
                <PreviewMobile
                  deviceSize="sm"
                  device={deviceType}
                  preview={parts}
                  id={`all-preview-${type.value}`}
                  campaignType={campaignType}
                />

                {deviceSpecificCampaign && (
                  <div className="position-absolute preview-switch-wrapper preview-switch-wrapper--under">
                    <DeviceTypeSwitch
                      activeDevice={deviceType}
                      setDeviceType={setDeviceType}
                    />
                  </div>
                )}
              </div>

              <PreviewPopover
                target={`all-preview-${type.value}`}
                campaignType={campaignType}
                deviceType={deviceType}
                parts={parts}
              />
            </Fragment>
          )
        })}
      </div>
    </>
  )
}
