import React, { useEffect, useState } from 'react'
import moment from 'moment'
import LineChart from '~/components/ApexCharts/ApexLineChart/ApexLineChart'
import { IActiveGraphsData } from '~/pages/Dashboard/Types/Dashboard.interface'

const TotalAudienceGraph = ({
  graph
}: {
  graph: IActiveGraphsData | undefined
}) => {
  const [chartData, setChartData] = useState<IActiveGraphsData>()
  const getPlaceholderMonths = () => {
    const givenMonth = moment(graph[graph.length - 1].x).subtract(1, 'months')

    const previousMonthsArray = []

    for (let i = 0; i < 12 - graph.length; i++) {
      const previousMonth = givenMonth.clone().subtract(i, 'months')
      previousMonthsArray.push(previousMonth.format('YYYY-MM'))
    }

    return previousMonthsArray
      .map((month) => ({
        x: moment(month).format('MMM'),
        y: 0,
        fillColor: '#93A8C5'
      }))
      .reverse()
  }

  const monthsWithDataToGraph = () =>
    graph.map((item, index) => ({
      x: moment(item.x).format('MMM'),
      y: item.y,
      fillColor:
        index === graph?.length - 1 ? 'rgba(147, 168, 197, .3)' : '#93A8C5'
    }))

  useEffect(() => {
    if (graph && graph?.length > 0) {
      setChartData([...getPlaceholderMonths(), ...monthsWithDataToGraph()])
    }
  }, [graph])

  if (graph?.length === 0) return null

  return (
    <LineChart
      legend={false}
      customFill
      chartData={[
        {
          name: 'Audience',
          data: chartData,
          dataType: 0,
          dataChartType: 'column',
          valueCurrent: ''
        }
      ]}
      minimumActiveSeries={3}
      xAxisConfig={{
        xAxisType: 'category',
        hideOverlappingLabels: false
      }}
      chartType="bar"
    />
  )
}

export default TotalAudienceGraph
