export interface FormValues {
  beaconEventWebhookEnabled: boolean
  beaconEventUrl: string
}

export const ERRORS_DICT = {
  beaconEventUrl: {
    'Missing parameter beacon_event_url': 'Beacon event url is missing'
  }
}
