import { withStore } from '~/dataStore'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

function RuleBoolean({ rule }: IProps): React.ReactElement {
  return (
    <>
      <span aria-label="is">is</span>
      <RuleDropdown
        selected={rule.value.matchValue}
        options={['false', 'true'].map((elem) => ({ name: elem, value: elem }))}
        action={(value) => rule.edit({ matchValue: value })}
        aria-label="Boolean Value"
      />
    </>
  )
}

export default withStore(RuleBoolean)
