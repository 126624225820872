import { makeAutoObservable } from 'mobx'
import EmailNotification from '~/pages/Campaign/Notification/EmailNotification/Model/EmailNotification'
import EmailTemplates from '~/pages/Campaign/Notification/EmailNotification/Model/EmailTemplates'
import {
  CampaignType,
  ICurrentStep
} from '~/dataStore/Campaign/Campaign.interface'
import Shared from '~/dataStore/Campaign/Shared'
import NotificationGoals from '~/pages/Campaign/Notification/NotificationGoals'
import { getCampaignTypeOptions } from '~/dataStore/CampaignTypeFacade'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import {
  IEmailDTO,
  NotificationType
} from '~/pages/Campaign/Notification/Notification.interface'
import { ID } from '~/common.interface'

type Step = {
  number: number
  title: string
  store: EmailNotification | EmailTemplates
  isValid: boolean
}

export default class JourneyEmailNotificationStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public notificationId: ID | undefined = undefined

  public email = new EmailNotification()

  public templates = new EmailTemplates()

  public shared = new Shared()

  public goals = new NotificationGoals()

  public steps: Step[] = [
    {
      number: 1,
      title: 'Templates',
      store: this.templates,
      isValid: this.templates.isStepValid
    },
    {
      number: 2,
      title: 'Email',
      store: this.email,
      isValid: this.email.isStepValid
    }
  ]

  public currentStep: ICurrentStep = this.steps[0]

  public fillStore(data: IEmailDTO): void {
    if (!data.template) {
      this.notificationId = data.id
    }
    this.email.fillStore({ emailNotification: data })
    this.templates.fillStore({ emailNotification: data })

    this.goals.fillGoals(data.goals || [])

    this.templates.validateStep()
  }

  public getStoreByNumber(
    number: number
  ): EmailNotification | EmailTemplates | undefined {
    return this.steps.find((step: Step) => step.number === number)?.store
  }

  public getStepByNumber(number: number): Step | undefined {
    return this.steps.find((step: Step) => step.number === number)
  }

  public setStep(step: ICurrentStep): void {
    this.currentStep = step
  }

  get getSteps(): Step[] {
    return this.steps.map((step) => ({
      number: step.number,
      title: step.title,
      store: step.store,
      isValid: step.store.isStepValid
    }))
  }

  public async getPayload(): Promise<IEmailDTO> {
    return {
      id: this.notificationId,
      frontParts: {
        emailBody: {
          ...this.templates.getPayload(),
          ...this.email.getPayload()
        }
      },
      goals: this.goals.getPayload() || [],
      type: NotificationType.EMAIL
    }
  }

  public validateStep(): void {
    this.templates.validateStep()
    this.email.validateStep()
    this.goals.validate()
  }

  public getErrorMessages(): string[] {
    return [
      this.templates.getErrorMessages(),
      this.email.getErrorMessages(),
      this.goals.errors.map((error) => error.message)
    ].flat()
  }

  public get isStepValid(): boolean {
    return (
      this.email.isStepValid && this.templates.isStepValid && this.goals.isValid
    )
  }

  public resetError(): void {
    this.email.resetError()
    this.templates.resetError()
    this.goals.resetError()
  }

  public generatePreview(): PartialPreview {
    return {
      email: {
        ...this.email.getPayload(),
        ...this.templates.getPayload(),
        goals: this.goals.getPayload()
      },
      type: getCampaignTypeOptions([CampaignType.EMAIL])
    }
  }
}
