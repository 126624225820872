import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'
import SingleTargetNameAndCount from './SingleTargetNameAndCount'

interface IProps {
  isSingleTarget: boolean
  getTargetCount: (target: TARGETS) => number
  activeTargetName: string | null
  isTargetActive: (target: TARGETS) => boolean | undefined
  segmentTooltip?: string[]
}

const TargetDescriptionTooltip = ({
  isSingleTarget,
  getTargetCount,
  activeTargetName,
  isTargetActive,
  segmentTooltip
}: IProps): React.ReactElement => {
  const isSegmentsTargetActive = isTargetActive(TARGETS.SEGMENTS)

  return (
    <UncontrolledTooltip
      target="target-description"
      placement="bottom"
      innerClassName="p-2">
      {!isSingleTarget && isSegmentsTargetActive && (
        <div className="text-start">
          <p>
            Eligible app users in your selected segments when they trigger any
            of the following
          </p>
          {[TARGETS.EVENTS, TARGETS.BEACONS, TARGETS.GEOFENCES].map(
            (target) => {
              if (getTargetCount(target) === 0 || !isTargetActive(target))
                return null
              return (
                <p key={target} className="mb-0">
                  <SingleTargetNameAndCount
                    count={getTargetCount(target)}
                    target={target}
                  />
                </p>
              )
            }
          )}
        </div>
      )}
      {!isSingleTarget && !isSegmentsTargetActive && (
        <div className="text-start">
          <p>
            Eligible app users will receive this campaign when they trigger any
            of the following:
          </p>
          {[TARGETS.EVENTS, TARGETS.BEACONS, TARGETS.GEOFENCES].map(
            (target) => {
              if (getTargetCount(target) === 0 || !isTargetActive(target))
                return null
              return (
                <p key={target} className="mb-0">
                  <SingleTargetNameAndCount
                    count={getTargetCount(target)}
                    target={target}
                  />
                </p>
              )
            }
          )}
        </div>
      )}

      {isSingleTarget && !isSegmentsTargetActive && (
        <p className="mb-0">
          Eligible app users will receive this campaign when they enter any of
          selected {activeTargetName}.
        </p>
      )}

      {isSingleTarget && isSegmentsTargetActive && (
        <>
          {segmentTooltip?.length &&
            segmentTooltip.map((text: string) => (
              <p key={text} className="my-3 text-start">
                {text}
              </p>
            ))}
        </>
      )}
    </UncontrolledTooltip>
  )
}

export default TargetDescriptionTooltip
