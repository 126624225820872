import Skeleton from 'react-loading-skeleton'
import { ReactNode } from 'react'
import { isEmpty, isNotEmpty } from '~/utils/utilities'
import Diff from './Diff'

interface IProps {
  value: string | number | undefined
  icon: ReactNode
  rate: string
  range: string
  diff?: string
}

export default function Stat({
  value,
  rate,
  icon,
  diff,
  range
}: IProps): React.ReactElement | null {
  return (
    <div className="d-flex justify-content-center w-100 py-4">
      <div className="d-flex gap-4 align-items-center">
        <div className="ps-3">{icon}</div>
        <div>
          <p className="h1 fw-normal d-flex">
            {isEmpty(rate) ? <Skeleton width={100} height={20} /> : `${rate}%`}
          </p>
          <p className="h5 fw-normal">
            {isEmpty(value) ? (
              <Skeleton width={100} height={20} />
            ) : (
              `${value?.toLocaleString()} Users`
            )}
          </p>
          {isNotEmpty(diff) && diff !== '- -' && (
            <div className="mt-3">
              <Diff value={diff} range={range} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
