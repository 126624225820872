import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import { useStore } from '~/dataStore'
import {
  IMessage,
  IMessagesQueryParams
} from '~/pages/Messages/Messages.interface'
import getMessages, {
  assignMessageToAdmin,
  createChat,
  getChat,
  markRead,
  sendMessage
} from '~/api/messages'
import { ID, IMetaData } from '~/common.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import { IChatMessage } from '~/pages/Messages/Chat/Chat.interface'
import { showGeneralError } from '~/utils/validations'

export enum ServerStateKeysEnum {
  Messages = 'messages',
  Chat = 'chat'
}

export const useInvalidateChat = () => {
  const cache = useQueryClient()
  return () => cache.removeQueries([ServerStateKeysEnum.Chat])
}

const useMessages = (
  queryParams: Partial<Omit<IMessagesQueryParams, 'page' | 'per_page'>>
) => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  return useInfiniteQuery<
    { data: IMessage[]; metadata: IMetaData },
    unknown,
    IMessage
  >(
    [
      ServerStateKeysEnum.Messages,
      {
        ...queryParams
      }
    ],
    ({ pageParam = 1 }) =>
      getMessages(appId, {
        ...queryParams,
        page: pageParam
      }),
    {
      select: (data) => ({
        pages: data.pages.flatMap((page) => page.data),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata,
        unreadCount: data.pages[0].unreadTalksCountAdmin
      }),
      refetchOnMount: true,
      refetchInterval: 10000,
      staleTime: 10000,
      placeholderData: {
        pages: [
          {
            data: Array.from({ length: 6 }, (item, i) => ({
              id: i + 1
            })),
            metadata: {}
          }
        ],
        pageParams: []
      },
      getNextPageParam: (lastPage, pages) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export const useChat = (chatId: string | null, params) => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  return useQuery<
    { data: IChatMessage[]; metadata: IMetaData; userName: string },
    unknown,
    IChatMessage
  >([ServerStateKeysEnum.Chat, params], () => getChat(chatId, appId, params), {
    placeholderData: {
      data: [
        { id: 1, sentByAdmin: false },
        { id: 2, sentByAdmin: true },
        { id: 3, sentByAdmin: false },
        { id: 4, sentByAdmin: true }
      ]
    },
    refetchOnMount: true,
    refetchInterval: 10000,
    staleTime: 10000
  })
}

export const useSendMessage = (chatId: ID | null) => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  const cache = useQueryClient()
  return useMutation(
    (data: { message: string }) => sendMessage(appId, chatId, data.message),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.Chat])
      },
      onError: () => {
        showNotification(
          "Couldn't send message, please try again later.",
          NotificationType.ERROR
        )
      }
    }
  )
}

export const useCreateChat = () => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  const cache = useQueryClient()
  return useMutation(
    (data: { userId: string; subject: string; content: string }) =>
      createChat(appId, data),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.Chat])
      },
      onError: () => {
        showNotification(
          "Couldn't send message, please try again later.",
          NotificationType.ERROR
        )
      }
    }
  )
}
export const useAssignMessage = (chatId: string) => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  const cache = useQueryClient()
  return useMutation(
    (data: string) => assignMessageToAdmin(appId, chatId, data),
    {
      onSuccess: (data) => {
        showNotification(
          `Successfully assigned message to ${data.adminName}`,
          NotificationType.SUCCESS
        )
        cache.invalidateQueries([ServerStateKeysEnum.Chat])
      },
      onError: (err) => {
        if (err.body?.errors) {
          showNotification(err.body.errors.admin[0], NotificationType.ERROR)
        } else {
          showGeneralError()
        }
      }
    }
  )
}

export const useMarkRead = (chatId: string) => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  const cache = useQueryClient()
  return useMutation(() => markRead(appId, chatId), {
    onSuccess: () => {
      cache.invalidateQueries([ServerStateKeysEnum.Messages])
    }
  })
}
export default useMessages
