import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { getGoalsRowsForMessage } from '~/pages/Journeys/Journey.service'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import {
  fetchJourneyOverview,
  fetchJourneyStats
} from '~/pages/Journeys/Connector/Journeys.connector'
import { ID } from '~/common.interface'
import {
  IGoalPerformance,
  JourneyReportDTO,
  JourneyStatsDTO
} from './JourneyReport.interface'
import JourneyNotificationReport from './JourneyNotificationReport'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { ISummary } from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { IChartReport } from '~/pages/Campaign/Notification/Notification.interface'

export default class JourneyReportStore {
  public report: JourneyReportDTO | undefined = undefined

  public stats: JourneyStatsDTO | undefined = undefined

  public notificationReport: JourneyNotificationReport

  constructor(private appID: ID, private journeyId: ID) {
    makeAutoObservable(this, undefined, { autoBind: true })

    this.notificationReport = new JourneyNotificationReport(this.appID)
  }

  public async fetchOverview(): Promise<void> {
    const report = await fetchJourneyOverview(this.appID, this.journeyId)
    if (
      [
        JourneyStatus.DRAFT,
        JourneyStatus.SCHEDULED,
        JourneyStatus.PREPARING
      ].includes(report.status)
    ) {
      throw new Error('Wrong status')
    }

    //* : ugly workaround but stats will be reworked so then need to think about better solution
    report.messageBlocks = report.messageBlocks.map((block) => ({
      ...block,
      options: block.options.map((option) => ({
        ...option,
        notifications: option.notifications.map((notification) => ({
          ...notification,
          notificationSides: report.notificationSides.find(
            (item) => item.notificationId === notification.id
          )?.notificationSides
        }))
      }))
    }))

    this.report = report
  }

  public async fetchStats(): Promise<void> {
    this.stats = await fetchJourneyStats(this.appID, this.journeyId)
  }

  public initNotificationReport(): void {
    if (this.report) {
      this.notificationReport = new JourneyNotificationReport(this.appID)
      this.notificationReport.selectMessage(this.report.messageBlocks[0])
    }
  }

  public get startAtDay(): Date {
    if (!this.report) {
      return new Date()
    }

    return moment.tz(this.report.startAt, this.report.timeZoneName).toDate()
  }

  public get summary(): ISummary[] {
    if (!this.report)
      return [
        {
          label: 'Start Date'
        },
        {
          label: 'End Date'
        },
        {
          label: 'Time Zone'
        },
        {
          label: 'Notification Types'
        },
        {
          label: 'From Email'
        },
        {
          label: 'From Name'
        },
        {
          label: 'Target'
        }
      ]

    const summary: ISummary[] = [
      {
        label: 'Start Date',
        content: moment
          .tz(this.report.startAt, this.report.timeZoneName)
          .format('HH:mma, MMM DD YYYY')
      },
      {
        label: 'End Date',
        content: this.report.endAt
          ? moment
              .tz(this.report.endAt, this.report.timeZoneName)
              .format('HH:mma, MMM DD YYYY')
          : '- -'
      },
      {
        label: 'Time Zone',
        content: this.report.timeZoneName
      },
      {
        label: 'Notification Types',
        content: this.report.notificationTypes.length
          ? this.report.notificationTypes
              .map((n) => MCampaignTypeToName.get(n) || '')
              .join(', ')
          : '- -'
      },
      this.report.emailNotifications && {
        label: 'From Email',
        className: 'summary-field--truncate',
        content: this.report.emailNotifications.fromEmails.join(', ')
      },
      this.report.emailNotifications && {
        label: 'From Name',
        className: 'summary-field--truncate',
        content: this.report.emailNotifications.fromNames.join(', ')
      },
      {
        label: 'Last Edited By',
        className: 'summary-field--truncate',
        content: this.report.authorName
      }
    ].filter<ISummary>(Boolean)

    return summary
  }

  public get numbers(): ISummary[] {
    if (!this.stats) {
      return [
        { label: 'Sent' },
        { label: 'Delivered' },
        { label: 'Engagement' },
        { label: 'Bounce' }
      ]
    }
    const { stats } = this

    return [
      {
        label: 'Sent',
        content: stats.send.totalUniq,
        tooltip:
          'Total number of notifications sent to reachable users. Examples of unreachable: email unsubscribe, missing / wrong email, push opt-out, sms opt-out.'
      },
      {
        label: 'Delivered',
        content: stats.delivery.totalUniq,
        tooltip:
          'Total number of notifications that were delivered successfully.',
        rateLabel: 'Delivery Rate',
        rate: stats.delivery.totalUniqRate,
        rateTooltip:
          'Total number of delivered notifications divided by the total number of notifications sent.'
      },
      {
        label: 'Engagement',
        content: stats.engagement.totalUniq ?? '- -',
        tooltip:
          'Sum of all interactions with sent notifications (i.e email clicks, push clicks, in-app impressions).',
        rateLabel: 'Engagement Rate',
        rate: stats.engagement.totalUniqRate,
        rateTooltip:
          'Sum of all interactions with sent notifications divided by total delivery.'
      },
      {
        label: 'Bounce',
        content: stats.bounce.totalUniq ?? '- -',
        tooltip:
          'Sent notifications were rejected by the intended recipient’s infrastructure. There could be several reasons for this, for more information see the documentation.',
        rateLabel: 'Bounce Rate',
        rate: stats.bounce.totalUniqRate,
        rateTooltip:
          'Total number of rejected notifications divided by total sent notifications'
      }
    ]
  }

  public get goals(): IGoalPerformance[] {
    if (!this.stats?.goals.messages) {
      return []
    }

    return getGoalsRowsForMessage(this.stats.goals.messages)
  }

  public get external():
    | {
        conversions: IChartReport
      }[]
    | undefined {
    if (!this.stats?.goals?.external) {
      return undefined
    }

    return this.stats.goals.external
  }
}
