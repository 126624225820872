import { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export default function useInfiniteScroll({ hasMore, onLoadMore }) {
  const [ref, inView, _entry] = useInView()
  const [lastInView, setLastInView] = useState(inView)

  useEffect(() => {
    if (!hasMore || inView === lastInView) return
    if (inView) onLoadMore()
    setLastInView(inView)
  }, [lastInView, inView, hasMore, onLoadMore])

  return ref
}
