import { useEffect, useState } from 'react'
import { useStore, withStore } from '~/dataStore'
import { fetchJourneyTemplates } from '~/pages/Journeys/Connector/Journeys.connector'
import { JourneyTemplate } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import OpportunityTemplate from './OpportunityTemplate'

function NextBestOpportunities() {
  const { app } = useStore()
  const [templates, setTemplates] = useState<JourneyTemplate[]>()
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const personal = await fetchJourneyTemplates(app.currentApp.id)
        setTemplates(personal.data)
        setTotal(
          personal.data
            .filter((template) => template.opportunitiesType === 'primary')
            .reduce((acc, curr) => acc + (curr?.opportunityProfit || 0), 0)
        )
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    fetchTemplates()
  }, [app.currentApp.id])

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h2>Next-Best Opportunities</h2>
        <div className="opportunity-total px-3 px-xxl-3 px-xxxl-4 text-black">
          Total Opportunities{' '}
          <span className="opportunity-total__number">
            {total?.toLocaleString('en-US') || 0}
          </span>
        </div>
      </div>

      <div className="my-4">
        <h3>Top-3 Opportunities</h3>
        <div className="d-flex flex-wrap gap-4">
          {templates
            ?.filter((template) => template.opportunitiesType === 'primary')
            .map((template) => (
              <OpportunityTemplate
                key={template.id}
                name={template.name}
                icon={template.smallImage}
                members={template.opportunityProfit}
                journeyTemplate={template}
              />
            ))}
          {/* <OpportunityTemplate
            name="CD is Maturing"
            icon={cd}
            members={12132}
          />
          <OpportunityTemplate
            name="Missing Payment"
            icon={payment}
            members={122}
          />
          <OpportunityTemplate
            name="Auto Loan Opportunity"
            icon={auto}
            members={9321}
          /> */}
        </div>
      </div>

      <div className="my-4">
        <h3>Missed Opportunities</h3>
        <div className="d-flex flex-wrap gap-4">
          {templates
            ?.filter((template) => template.opportunitiesType === 'missed')
            .map((template) => (
              <OpportunityTemplate
                key={template.id}
                name={template.name}
                icon={template.smallImage}
                members={template.opportunityProfit}
                journeyTemplate={template}
              />
            ))}
          {/* <OpportunityTemplate
            name="Credit Card Opportunity"
            icon={credit}
            members={123123}
          />
          <OpportunityTemplate
            name="E-statement not enabled"
            icon={estatement}
            members={12312}
          /> */}
        </div>
      </div>
      <div className="my-4">
        <h3>Pulsate Recommends</h3>
        <div className="d-flex flex-wrap gap-4">
          {templates
            ?.filter((template) => template.opportunitiesType === 'recommended')
            .map((template) => (
              <OpportunityTemplate
                key={template.id}
                name={template.name}
                icon={template.smallImage}
                members={template.opportunityProfit}
                recommended
                journeyTemplate={template}
              />
            ))}
          {/* <OpportunityTemplate
            name="Attrition Risk >50%"
            icon={attri}
            recommended
            members={122}
          />
          <OpportunityTemplate
            name="Un-Engaged"
            icon={unEngaged}
            recommended
            members={23442}
          /> */}
        </div>
      </div>
    </>
  )
}

export default withStore(NextBestOpportunities)
