import { TimeUnit } from '~/common.interface'
import { hookFormValidation } from '~/utils/validations'

export interface CampaignGlobalSettingsPayload {
  settingAttributes: {
    sendValue: number | string
    timeValue: number | string
    timeFrame: string
    silentPush: boolean
    waitValue: number | string
    waitFrame: string
  }
}

export interface CampaignGlobalSettingsFormValue {
  time: {
    value: string
    frame: TimeUnit
  }
  waitTime: {
    value: string
    frame: TimeUnit
  }
  silentPush: boolean
  sendValue: string
}

const ONLY_VALID_TIME_FRAMES_MESSAGE =
  'Only minutes, hours, days, weeks, months, years are allowed'

export const ERRORS_DICT = {
  timeFrame: {
    'Must be one of: <code>minutes</code>, <code>hours</code>, <code>days</code>, <code>weeks</code>, <code>months</code>, <code>years</code>.': ONLY_VALID_TIME_FRAMES_MESSAGE
  },
  waitFrame: {
    'Must be one of: <code>minutes</code>, <code>hours</code>, <code>days</code>, <code>weeks</code>, <code>months</code>, <code>years</code>.': ONLY_VALID_TIME_FRAMES_MESSAGE
  },
  waitValue: {
    'Must be greater than 0 or empty': hookFormValidation.minOne.min.message
  },
  sendValue: {
    'Must be greater than 0 or empty': hookFormValidation.minOne.min.message
  },
  timeValue: {
    'Must be greater than 0 or empty': hookFormValidation.minOne.min.message
  }
}

export const BE_ERRORS_MAP: Record<
  string,
  | 'time.value'
  | 'time.frame'
  | 'waitTime.value'
  | 'waitTime.frame'
  | 'silentPush'
  | 'sendValue'
  | 'time'
  | 'waitTime'
> = {
  timeValue: 'time.value',
  timeFrame: 'time.frame',
  waitValue: 'waitTime.value',
  waitFrame: 'waitTime.frame',
  silentPush: 'silentPush',
  sendValue: 'sendValue'
}
