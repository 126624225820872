import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import GoalIconWithPlaceholder from '../Notification/NotificationGoals/UI/GoalIconWithPlaceholder'
import { INotificationGoal } from '../Notification/NotificationGoals/NotificationGoals.interface'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import ConditionalWrapper from '~/components/ConditionalWrapper'

interface IProps<T extends CampaignType> {
  tabs: { label: string; value: T; goals?: INotificationGoal[] }[]
  activeTab: T | undefined
  toggle: (tab: T) => void
  classes?: string
  withCardSwitch?: boolean
}

function CampaignTypeSwitch<T extends CampaignType>({
  tabs,
  activeTab,
  toggle,
  classes,
  withCardSwitch = false
}: IProps<T>): React.ReactElement {
  const [tabsWithCardSwitch, setTabsWithCardSwitch] = useState(tabs)

  const [currentCardSwitch, setCurrentCardSwitch] = useState<
    CampaignType.CARD_FRONT | CampaignType.CARD_BACK
  >(CampaignType.CARD_FRONT)

  function isCardSubtype(tab: T): boolean {
    return tab.includes('card')
  }

  function handleToggle(newTab: T) {
    if (newTab === activeTab) {
      return
    }

    toggle(newTab)
  }

  function getOppositCardSide(
    current: CampaignType.CARD_FRONT | CampaignType.CARD_BACK
  ) {
    return current === CampaignType.CARD_FRONT
      ? CampaignType.CARD_BACK
      : CampaignType.CARD_FRONT
  }

  function hasCardBackTab(): boolean {
    return tabs.some((tab) => tab.value === CampaignType.CARD_BACK)
  }

  function toggleCardPreview(
    cardType: CampaignType.CARD_FRONT | CampaignType.CARD_BACK
  ) {
    const card = getOppositCardSide(cardType)
    toggle(card)
    setCurrentCardSwitch(card)
  }

  function isActiveTab(tabValue: T): boolean {
    return activeTab === tabValue
  }

  useEffect(() => {
    let filteredTabs = tabs
    if (withCardSwitch) {
      filteredTabs = tabs.filter(
        (t) => !t.value.includes(getOppositCardSide(currentCardSwitch))
      )
    }
    setTabsWithCardSwitch(filteredTabs)
  }, [tabs, withCardSwitch, currentCardSwitch])

  return (
    <div
      className={cn('tabs', classes, { 'tabs--card-switch': withCardSwitch })}>
      {tabsWithCardSwitch.map((tab) => {
        const goals = tab.goals?.map((g) => g.type)

        return (
          <ConditionalWrapper
            key={`${tab.value}`}
            condition={withCardSwitch}
            wrapper={(children) => <div className="d-flex">{children}</div>}>
            <>
              <button
                type="button"
                className={cn('tabs__item', {
                  'tabs__item--active': isActiveTab(tab.value),
                  'd-flex align-items-center justify-content-center flex-nowrap px-1': !!tab.goals
                })}
                onClick={(e) => {
                  e.stopPropagation()
                  handleToggle(tab.value)
                }}>
                <span className="tabs__item-label">{tab.label}</span>
                {goals && (
                  <div className="d-flex flex-nowrap">
                    <GoalIconWithPlaceholder
                      goals={goals}
                      type="primary"
                      noBorder
                    />
                    <GoalIconWithPlaceholder
                      goals={goals}
                      type="secondary"
                      noBorder
                    />
                  </div>
                )}
              </button>
              {hasCardBackTab() && withCardSwitch && isCardSubtype(tab.value) && (
                <button
                  type="button"
                  className={cn('tabs__item tabs__arrows', {
                    'tabs__item--active': isActiveTab(tab.value)
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleCardPreview(tab.value)
                  }}>
                  <span className="icon icon--small icon--arrow-switch" />
                </button>
              )}
            </>
          </ConditionalWrapper>
        )
      })}
    </div>
  )
}

export default CampaignTypeSwitch
