// Please keep this file in sync with frontend/src/assets/css-modules/_colors.scss
// Pretty color names from http://chir.ag/projects/name-that-color

export default {
  bodyColor: '#28518c',

  primary: '#3e95d1',
  secondary: '#9f72c7',
  tertiary: '#669ae5',
  success: '#28a745',
  info: '#5abf9d',
  warning: '#f9861c',
  danger: '#ee2650',
  dangerLight: 'rgba(238, 38, 80, 0.8)',
  light: '#e9ecef',
  dark: '#153d77',

  alabaster: '#f9f9f9',
  gray: '#adb5bd',
  linkWaterGray: '#f4f7fc',
  silverGray: '#bababa',
  mysticGray: '#d9dfe8',
  blueGray: '#93a8c5',
  pigeonPost: '#b7c6da',
  azure: '#0E7AFE',

  pulsateBlue: '#28518c',
  lightPulsateBlue: 'rgba(40, 81, 140, 0.5)', // same as pulsateBlue but 50% transparent
  baliHaiBlue: '#7f95b3',
  $baliHai: '#879bb7',

  $fuchsiaPink: '#c050ba'
}
