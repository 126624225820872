import cn from 'classnames'
import React, { PropsWithChildren } from 'react'

import styles from './NoticeBar.scss'

interface IProps {
  className?: string
}

const NoticeBar = ({
  children,
  className
}: PropsWithChildren<IProps>): React.ReactElement => {
  return (
    <div
      className={cn(
        'bg-fuchsia-pink d-flex align-items-center justify-content-between text-white align-items-center',
        styles.bar,
        className
      )}>
      {children}
    </div>
  )
}

export default NoticeBar
