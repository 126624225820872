import { getSmoothStepPath, EdgeProps } from 'reactflow'

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
  markerStart
}: EdgeProps): React.ReactElement {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  // const markerEndID = getMarkerEnd(arrowHeadType, markerEnd)

  return (
    <>
      <path
        id={id}
        style={style}
        className="custom-edge react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      {data?.label && (
        <g
          className="react-flow__edge-textwrapper"
          transform={`translate(${labelX - 10}, ${labelY})`}>
          <text className="react-flow__edge-text" dy="-0.3em">
            {data.label}
          </text>
        </g>
      )}
      {/* {!data?.last && (
        <circle
          id={id}
          className="circle"
          cx={targetX}
          cy={position[1]}
          r="10"
        />
      )} */}
    </>
  )
}
