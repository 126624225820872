import React from 'react'
import StatCard from '~/components/reports/StatCard'
import LineChart from '~/components/ApexCharts/ApexLineChart'
import { IChartData } from '~/dataStore/reports/Report.interface'

const DeliveryVsOpenVsClickChart = ({
  chartData
}: {
  chartData: IChartData[]
}): React.ReactElement => {
  return (
    <StatCard
      cardClasses="mb-0 h-100"
      tooltip="This graph shows the change in Total Delivery, Unique Click and Unique Open Rates over the selected period."
      title="Total Delivery vs Unique Open Rate vs Unique Click Rate">
      <LineChart
        includeOverviewColumn
        chartData={chartData}
        minimumActiveSeries={3}
      />
    </StatCard>
  )
}

export default DeliveryVsOpenVsClickChart
