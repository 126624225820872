import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

export function isPrimitive(a: unknown): a is number | string | boolean {
  return a !== Object(a)
}

export function isOption(a: unknown): a is IOption {
  if (isPrimitive(a)) {
    return false
  }
  return 'value' in a
}

export function isEmpty(value?: unknown): boolean {
  if (typeof value === 'undefined') {
    return true
  }

  if (isPrimitive(value)) {
    const v = typeof value === 'string' ? value.trim() : value
    switch (v) {
      case '':
      case null:
      case undefined:
        return true
      default:
        return false
    }
  }

  if (isOption(value) && isEmpty(value.value) && isEmpty(value.label)) {
    return true
  }

  return false
}

export function isNotEmpty<T>(value: T | undefined | null): value is T {
  return !isEmpty(value)
}

export function isType<T>(
  val: unknown,
  callback: (val: unknown) => boolean
): val is T {
  return callback(val)
}

export function isString(a: any): a is string {
  return typeof a === 'string'
}

export function isNumber(a: any): a is number {
  return typeof a === 'number'
}

export function setHours(date: Date, hours: number): Date {
  return moment(date).set('hour', hours).toDate()
}
export function setMinutes(date: Date, minutes: number): Date {
  return moment(date).set('minutes', minutes).toDate()
}

export function getTimeNow(): Date {
  return new Date()
}

export function getTimeNowPlusHour(): Date {
  return moment().add(1, 'hours').toDate()
}

export function tagHotjarRecording(tags: Array<string>): void {
  window.hj =
    window.hj ||
    function () {
      ;(hj.q = hj.q || []).push(arguments)
    }
  hj('tagRecording', tags)
}

export function randomColor(): string {
  return Math.floor(Math.random() * 16777215).toString(16)
}

export function saveSelection(containerEl: HTMLElement): {
  start: number
  end: number
} {
  if (window.getSelection && document.createRange) {
    const selection = window.getSelection()
    if (selection?.rangeCount && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const preSelectionRange = range.cloneRange()
      preSelectionRange.selectNodeContents(containerEl)
      preSelectionRange.setEnd(range.startContainer, range.startOffset)
      const start = preSelectionRange.toString().length

      return {
        start,
        end: start + range.toString().length
      }
    }
  }
  if (document.selection) {
    const selectedTextRange = document.selection.createRange()
    const preSelectionTextRange = document.body.createTextRange()
    preSelectionTextRange.moveToElementText(containerEl)
    preSelectionTextRange.setEndPoint('EndToStart', selectedTextRange)
    const start = preSelectionTextRange.text.length

    return {
      start,
      end: start + selectedTextRange.text.length
    }
  }

  return {
    start: 0,
    end: 0
  }
}

export function restoreSelection(
  containerEl: HTMLElement,
  savedSel: { start: number; end: number }
): void {
  if (window.getSelection && document.createRange) {
    let charIndex = 0
    const range = document.createRange()
    range.setStart(containerEl, 0)
    range.collapse(true)
    const nodeStack = [containerEl]
    let node
    let foundStart = false
    let stop = false

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType == 3) {
        const nextCharIndex = charIndex + node.length
        if (
          !foundStart &&
          savedSel.start >= charIndex &&
          savedSel.start <= nextCharIndex
        ) {
          range.setStart(node, savedSel.start - charIndex)
          foundStart = true
        }
        if (
          foundStart &&
          savedSel.end >= charIndex &&
          savedSel.end <= nextCharIndex
        ) {
          range.setEnd(node, savedSel.end - charIndex)
          stop = true
        }
        charIndex = nextCharIndex
      } else {
        let i = node.childNodes.length
        while (i--) {
          nodeStack.push(node.childNodes[i])
        }
      }
    }
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  } else if (document.selection) {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(containerEl)
    textRange.collapse(true)
    textRange.moveEnd('character', savedSel.end)
    textRange.moveStart('character', savedSel.start)
    textRange.select()
  }
}

export function splitByMergeTag(text: string): string[] {
  return text.split(/({{.*?}})/g).filter(Boolean)
}

export function splitMergeTag(mergeTag: string): {
  tag: string
  default: string
} {
  const modified = mergeTag.replace(/{{(.*?)}}/, '$1')
  const modifiedSplit = modified.split('| default: ')

  return {
    tag: modifiedSplit[0].trim(),
    default: modifiedSplit[1]
  }
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export function getObjectFromSearchParams(searchParams: URLSearchParams): {
  [key: string]: any
} {
  return Object.fromEntries(searchParams.entries())
}
