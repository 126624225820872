import React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import sendedImage from './sended.svg'

const SuccessfulSendModal = ({
  emailAddresses,
  adminEmailAddress,
  closeModal
}: {
  emailAddresses: Array<string>
  adminEmailAddress: string
  closeModal: () => void
}): React.ReactElement => {
  const getEmailAddress = (emailAddress: string) => {
    const isAdminEmailAddress = emailAddress === adminEmailAddress

    return (
      <li
        className={isAdminEmailAddress ? 'text-muted' : ''}
        key={emailAddress}>
        <h5 className="fw-light no-wrap overflow-hidden text-truncate">
          {isAdminEmailAddress ? (
            <>
              <span className="text-muted">Yourself </span>({adminEmailAddress})
            </>
          ) : (
            emailAddress
          )}
        </h5>
      </li>
    )
  }

  return (
    <>
      <ModalBody className="pb-0 pt-0">
        <div className="d-flex">
          <div className="pe-3">
            <img src={sendedImage} alt="Email sended" />
          </div>
          <div className="ps-4 pt-4 text-start text-truncate">
            <p>Test Mail was successfully sent to</p>
            <ul className="ps-3">{emailAddresses?.map(getEmailAddress)}</ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="pt-0">
        <Button
          color=""
          className="btn--hover border px-4"
          onClick={closeModal}>
          Exit
        </Button>
      </ModalFooter>
    </>
  )
}

export default SuccessfulSendModal
