import React from 'react'

const IconGraduationCap = ({ className }) => (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M7.4375 9.68354V16.3462H20.5625V9.68354C18.551 8.68057 16.2815 8.15027 14 8.15027C11.7185 8.15027 9.44984 8.68057 7.4375 9.68354Z" />
    <path d="M26.3047 8.24737L28 7.32271L14 0L0 7.32271L5.79688 10.3801V8.66775C6.25849 8.49199 9.3642 6.50962 14 6.50962C18.7004 6.50962 21.7062 8.4793 22.2031 8.66857V10.3801L24.6641 9.10044V15.332L22.9688 18.9689L25.4844 21.6077L28 18.9689L26.3047 15.332V8.24737Z" />
  </svg>
)

export default IconGraduationCap
