import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'

import { IEmailTemplate } from '~/dataStore/emailBuilder/EmailBuilder.interface'

import './Template.scss'
import PreviewDesktop from '~/pages/Campaign/Notification/Preview/PreviewDesktop'

interface IProps {
  template: IEmailTemplate
  isBlank?: boolean
  isLayout?: boolean
  isActive?: boolean
  onSelect: (template: IEmailTemplate) => void
  disabled?: boolean
  isLoading?: boolean
  deleteTemplate?: (id: string) => void
  showPreview?: (template: IEmailTemplate) => void
}

const Template = ({
  template,
  isBlank = false,
  isLayout,
  isActive,
  onSelect,
  disabled,
  isLoading,
  deleteTemplate,
  showPreview
}: IProps): React.ReactElement | null => {
  const getWrapperClassNames = () => {
    const classes = ['template']
    if (isLoading) classes.push('template--disabled')
    if (isActive) classes.push('template--active')
    if (isBlank) classes.push('template--blank')
    return classes.join(' ')
  }

  if (!template) return null

  return (
    <div className={getWrapperClassNames()}>
      <div className="template__body">
        <PreviewDesktop size="md" srcDoc={template.html}>
          <img
            src={template.thumbnailUrl}
            className="template__media"
            alt={template.name}
          />
        </PreviewDesktop>
        {isBlank && <span className="template__plus-icon">+</span>}
        <div className="template__toolbar p-3">
          {!isBlank && showPreview && (
            <div
              className={`template__top-actions-wrapper w-100 ${
                isLayout ? 'justify-content-center' : ' '
              }`}>
              <Button
                color=""
                data-testid="previewTemplateBtn"
                className="btn template__action"
                onClick={() => showPreview(template)}>
                <FontAwesomeIcon
                  id={`template-id-${template.id}-preview`}
                  icon={faEye}
                  className="template__action-content"
                />
              </Button>
              {!isLayout && (
                <Button
                  color=""
                  className="template__action"
                  data-testid="deleteTemplateBtn"
                  onClick={() => deleteTemplate?.(template.id)}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="template__action-content"
                  />
                </Button>
              )}
            </div>
          )}
          <Button
            className="template__action template__action--select-template bg-white btn-primary"
            color=""
            disabled={disabled}
            onClick={() => onSelect(template)}>
            <span className="template__action-content">Select</span>
          </Button>
        </div>
      </div>
      <p
        className="template__name mt-1 text-center"
        id={`template-${template.id}-name`}>
        {template.name || 'Blank'}
      </p>
      {!isBlank && template.name.length > 50 && (
        <UncontrolledTooltip
          direction="up"
          target={`template-${template.id}-name`}>
          {template.name}
        </UncontrolledTooltip>
      )}
    </div>
  )
}

export default Template
