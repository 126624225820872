import React, { useCallback, useEffect, useRef } from 'react'
import PreviewDevice from './UI/PreviewDevice'

interface IProps {
  url?: string | undefined
  srcDoc?: string
  size?: 'sm' | 'lg' | 'xl' | 'xxl'
}

function PreviewDesktopImage({ url }: { url: string }): React.ReactElement {
  return <img src={url} alt="Campaign preview" className="preview-img" />
}

function PreviewDesktopHtml({
  srcDoc
}: {
  srcDoc: string
}): React.ReactElement {
  const iframe = useRef<HTMLIFrameElement | null>(null)

  function dispatchClick(): void {
    iframe.current?.dispatchEvent(new Event('click', { bubbles: true }))
  }

  const ref = useCallback(() => {
    if (iframe.current?.contentWindow) {
      if (iframe.current.contentWindow.document.body.firstElementChild) {
        iframe.current.contentWindow.document.body.firstElementChild.style.pointerEvents =
          'none'
      }

      iframe.current.contentWindow.document.body.addEventListener(
        'click',
        dispatchClick
      )
    }
  }, [])

  useEffect(() => {
    return () => {
      if (iframe.current?.contentWindow) {
        iframe.current.contentWindow.document.body.removeEventListener(
          'click',
          dispatchClick
        )
      }
    }
  }, [])

  return (
    <iframe
      ref={iframe}
      onLoad={ref}
      className="email-iframe"
      title="email notification"
      srcDoc={srcDoc}
      frameBorder="0"
    />
  )
}

function PreviewDesktopContent({
  url,
  srcDoc,
  children
}: React.PropsWithChildren<IProps>): React.ReactElement | null {
  if (srcDoc) {
    return <PreviewDesktopHtml srcDoc={srcDoc} />
  }

  if (url) {
    return <PreviewDesktopImage url={url} />
  }

  if (children) {
    return <>{children}</>
  }

  return null
}

export default function PreviewDesktop({
  url,
  size = 'lg',
  srcDoc,
  children
}: React.PropsWithChildren<IProps>): React.ReactElement {
  return (
    <PreviewDevice deviceType="desktop" deviceSize={size}>
      <PreviewDesktopContent url={url} srcDoc={srcDoc}>
        {children}
      </PreviewDesktopContent>
    </PreviewDevice>
  )
}
