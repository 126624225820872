import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import { Button } from 'reactstrap'
import { convertURLToBase64 } from '~/utils/file.service'
import './cropImage.scss'
import {
  getCroppedImg,
  getInitialCropArea,
  getNewCropArea
} from './CropImage.service'

interface IProps {
  src: string
  fileName: string
  tooltip: string | ReactNode
  minWidth: number
  minHeight?: number
  aspect?: number
  onClose: () => void
  onSubmit: (image: string) => void
}

export default function CropImage({
  src,
  fileName,
  tooltip,
  minWidth,
  minHeight,
  aspect,
  onClose,
  onSubmit
}: IProps): ReactElement {
  const imageRef = useRef<HTMLImageElement | undefined>()
  const [base64Image, setBase64Image] = useState(src)
  const [crop, setCrop] = useState<Crop>()
  const [localMinWidth, setLocalMinWidth] = useState(minWidth)
  const [localminHeight, setLocalMinHeight] = useState(minHeight)

  function onImageLoaded(e: React.SyntheticEvent<HTMLImageElement>): void {
    const initial = getInitialCropArea(
      e.currentTarget,
      minWidth,
      minHeight,
      aspect
    )

    setCrop(initial.crop)
    setLocalMinWidth(initial.scaledMinWidth)
    setLocalMinHeight(initial.scaledMinHeight)
  }

  function handleComplete(newCrop: Crop): void {
    if (!imageRef.current) {
      setCrop(newCrop)
      return
    }

    setCrop(
      getNewCropArea(newCrop, imageRef.current, localMinWidth, localminHeight)
    )
  }

  function handleSubmit(): void {
    if (!imageRef.current || !crop) {
      return
    }

    onSubmit(getCroppedImg(imageRef.current, crop))
  }

  useEffect(() => {
    if (!(src.startsWith('data:') || src.startsWith('blob:'))) {
      convertURLToBase64(src).then((response) => {
        setBase64Image(response.base64)
      })
    }
  }, [src])

  return (
    <div className="crop" data-testid="cropModal">
      <ReactCrop
        crop={crop}
        ruleOfThirds
        aspect={aspect}
        className="w-100"
        onChange={(newCrop) => setCrop(newCrop)}
        onComplete={handleComplete}>
        <img
          ref={imageRef}
          alt="Crop"
          src={base64Image}
          style={{
            maxWidth: 'none',
            width: '100%'
          }}
          onLoad={onImageLoaded}
        />
      </ReactCrop>
      <p className="text-blue-gray">{fileName}</p>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex align-items-center ">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="o-50 d-block "
            size="lg"
          />
          <p className="ms-2 m-0 text-blue-gray text-13">{tooltip}</p>
        </div>
        <div>
          <Button color="" onClick={onClose} className="btn--hover">
            Cancel
          </Button>
          <Button
            data-testid="submit"
            color="primary"
            className="px-4 btn--hover ms-3"
            onClick={handleSubmit}>
            Crop
          </Button>
        </div>
      </div>
    </div>
  )
}
