import React from 'react'

interface IProps {
  icon: string
  time: string | Array<string>
  description: string
  className: string
}

const Time = ({ icon, time, description, className = '' }: IProps) => {
  const times = () => {
    if (Array.isArray(time)) {
      return time
    }
    return [time]
  }
  return (
    <div
      className={`deliver__time d-flex align-items-center ${className}`}
      style={{ width: '477px' }}>
      <img src={icon} className="me-4" alt={description} />
      <div className="d-flex flex-column">
        {time &&
          times().map((timeItem) => (
            <div key={timeItem} className="deliver__time__header mb-2">
              {timeItem}
            </div>
          ))}
        <div className="deliver__time__sub-header muted">{description}</div>
      </div>
    </div>
  )
}

export default Time
