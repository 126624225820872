import { Collapse } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import SelectWithSearch from '~/components/forms/SelectWithSearch'
import TimeFrameForm from '~/components/forms/TimeFrameForm'
import { GOALS_TIME_UNITS } from '~/pages/CampaignBuilder/Email/consts'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  timeFramePanelActive: boolean
  events: IOption<string>[]
}

const PrimaryGoalForm = ({
  timeFramePanelActive = false,
  events
}: IProps): React.ReactElement => {
  const {
    campaign: {
      isSended,
      setUp: {
        primary,
        setPrimaryEventName,
        setPrimaryExpiryTimeUnit,
        setPrimaryExpiryTimeValue,
        setSearchQuery,
        validatePrimaryGoal,
        validatePrimaryGoalTime
      }
    }
  } = useStore()

  return (
    <>
      <SelectWithSearch
        placeholder="Select a primary conversion goal"
        selectPlaceholder="Search for an event"
        value={primary.eventName.value?.value}
        options={events}
        onChange={setPrimaryEventName}
        onBlur={validatePrimaryGoal}
        onInputChange={setSearchQuery}
        invalid={!primary.eventName.isValid}
        disabled={isSended}
      />
      <Collapse
        isOpen={!!primary.eventName.value.value && timeFramePanelActive}
        className="mt-3">
        <TimeFrameForm
          disabled={isSended}
          initialTimeFrame={primary.expiryTimeUnit.value}
          initialTimeValue={primary.expiryTimeValue.value || ''}
          onTimeValueChange={setPrimaryExpiryTimeValue}
          onTimeValueBlur={validatePrimaryGoalTime}
          onTimeFrameChange={setPrimaryExpiryTimeUnit}
          timeValueInvalid={!primary.expiryTimeValue.isValid}
          inputType="number"
          timeLabel={<span className="o-50 text-dark">Within</span>}
          unitLabel={
            <span className="o-50 text-dark">of receiving campaign</span>
          }
          units={GOALS_TIME_UNITS}
          max={primary.expiryTimeUnit.value === 'hours' ? 72 : undefined}
        />
      </Collapse>
    </>
  )
}

export default withStore(PrimaryGoalForm)
