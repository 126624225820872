import { ReactElement } from 'react'

import IOSLogo from '~/components/icons/ios_logo.svg'
import AndroidLogo from '~/components/icons/android_logo.svg'
import useMatchMedia from '~/hooks/useMatchMedia'
import MobileDevicesCarousel from './UI/MobileDevicesCarousel'
import MobileDevicesInRow from './UI/MobileDevicesInRow'
import {
  CampaignType,
  DeviceType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  parts: IPreviewParts
  campaignType: CampaignType
  onlyCarousel?: boolean
}

const devices: {
  [key in CampaignType]: {
    name: string
    logo: string
    deviceType: DeviceType
    className?: string
  }[]
} = {
  sms: [
    {
      name: 'Watch',
      logo: IOSLogo,
      deviceType: 'watch',
      className: 'd-flex flex-column'
    },
    {
      name: 'iOS',
      logo: IOSLogo,
      deviceType: 'ios'
    },
    {
      name: 'Android',
      logo: AndroidLogo,
      deviceType: 'android'
    }
  ],
  in_app: [
    {
      name: 'iOS',
      logo: IOSLogo,
      deviceType: 'ios'
    },
    {
      name: 'Android',
      logo: AndroidLogo,
      deviceType: 'android'
    }
  ],
  push: [
    {
      name: 'Watch',
      logo: IOSLogo,
      deviceType: 'watch',
      className: 'd-flex flex-column'
    },
    {
      name: 'iOS',
      logo: IOSLogo,
      deviceType: 'ios'
    },
    {
      name: 'Android',
      logo: AndroidLogo,
      deviceType: 'android'
    }
  ],
  cardfront: [
    {
      name: 'iOS',
      logo: IOSLogo,
      deviceType: 'ios'
    },
    {
      name: 'Android',
      logo: AndroidLogo,
      deviceType: 'android'
    }
  ],
  cardback: [
    {
      name: 'iOS',
      logo: IOSLogo,
      deviceType: 'ios'
    },
    {
      name: 'Android',
      logo: AndroidLogo,
      deviceType: 'android'
    }
  ]
}

export default function AllMobileDevicesPreviews({
  parts,
  campaignType,
  onlyCarousel = false
}: IProps): ReactElement {
  const isDesktopLarge = useMatchMedia('(min-width: 1600px)')

  if (isDesktopLarge && !onlyCarousel) {
    return (
      <MobileDevicesInRow
        devices={devices[campaignType]}
        parts={parts}
        campaignType={campaignType}
      />
    )
  }

  return (
    <MobileDevicesCarousel
      devices={devices[campaignType]}
      parts={parts}
      campaignType={campaignType}
    />
  )
}
