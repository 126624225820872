import pluralize from 'pluralize'
import cn from 'classnames'
import Targeting from '~/dataStore/emailBuilder/Targeting/Targeting'
import SingleTargetNameAndCount from '../ReviewTopBar/SingleTargetNameAndCount'
import TargetDescriptionTooltip from '../ReviewTopBar/TargetDescriptionTooltip'
import targetingTooltips from '~/dataStore/Campaign/TargetingTooltips'

interface IProps {
  targeting: Targeting
  withUsers?: boolean
  className?: string
}

function ReachNumber({
  min,
  max
}: {
  min: number
  max: number
}): React.ReactElement {
  if (min === max) {
    return <>{min.toLocaleString()}</>
  }

  return (
    <>
      {min.toLocaleString()} -{max.toLocaleString()}
    </>
  )
}

export default function TargetingSummary({
  targeting,
  withUsers,
  className
}: IProps): React.ReactElement {
  let segmentsReachText = 'View more'

  if (targeting.segments.segmentsReach !== null) {
    segmentsReachText = 'Potential Reach'
  }

  return (
    <>
      <div className={cn('campaign-header__target', className)}>
        {targeting.segments.segmentsReach && (
          <>
            <ReachNumber
              min={targeting.segments.segmentsReach.min}
              max={targeting.segments.segmentsReach.max}
            />{' '}
          </>
        )}
        {withUsers &&
          targeting.segments.isActive &&
          pluralize('User', targeting.segments.segmentsReach?.min || 0)}
        {targeting.isMultipleAndNotSegmentType && 'Multiple Targeting Option'}
        {!targeting.segments.isActive && targeting.isSingleTarget && (
          <div className="text-capitalize">
            <SingleTargetNameAndCount
              target={targeting.getOnlyActiveTargetName}
              count={targeting.getTargetCount(
                targeting.getOnlyActiveTargetName
              )}
            />
          </div>
        )}
      </div>
      <div className="muted mt-2">
        <span id="target-description">{segmentsReachText}</span>
        <TargetDescriptionTooltip
          segmentTooltip={targetingTooltips.card.potential}
          isTargetActive={targeting.isTargetActive}
          getTargetCount={targeting.getTargetCount}
          isSingleTarget={targeting.isSingleTarget}
          activeTargetName={targeting.getOnlyActiveTargetName}
        />
      </div>
    </>
  )
}
