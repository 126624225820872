import React from 'react'
import StatCard from '~/components/reports/StatCard'
import LineChart from '~/components/ApexCharts/ApexLineChart/ApexLineChart'
import { IChartData } from '~/dataStore/reports/Report.interface'

const TrafficByTheWeekDay = ({
  chartData
}: {
  chartData: IChartData[]
}): React.ReactElement => {
  return (
    <StatCard
      cardClasses="mb-0 h-100"
      tooltip="This graph shows the click and open rate trends prevailing during different days of the week"
      title="Email Traffic by the day of the week">
      <LineChart
        chartData={chartData}
        minimumActiveSeries={3}
        xAxisConfig={{ xAxisType: 'category', hideOverlappingLabels: false }}
        includeOverviewColumn
        chartType="bar"
      />
    </StatCard>
  )
}

export default TrafficByTheWeekDay
