import React from 'react'
import {
  Row,
  Col,
  ModalFooter,
  ModalBody,
  Button,
  FormFeedback
} from 'reactstrap'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import cn from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from '@tanstack/react-query'
import { destroy } from '~/api/v2'
import {
  IApplication,
  ERRORS_DICT,
  DeleteAppFormValues
} from '../AppManager.interface'
import ModeDot from '../ApplicationsTable/ModeDot'
import Input from '~/components/forms/Input'
import colors from '~/utils/color'
import { NotificationType, showNotification } from '~/utils/Notification'
import { useStore } from '~/dataStore'
import { showGeneralError } from '~/utils/validations'
import { ServerStateKeysEnum } from '../useAppManager'

interface IProps {
  application: IApplication
  closeModal: () => void
}

const DeleteAppModal = ({
  application,
  closeModal
}: IProps): React.ReactElement | null => {
  const queryClient = useQueryClient()
  const {
    app: { currentApp, getSessionData }
  } = useStore()
  const {
    setError,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<DeleteAppFormValues>({})

  const handleErrors = (errors: {
    errors: Record<'name' | 'password' | 'error', string[]>
  }) => {
    Object.keys(errors).forEach((error) => {
      if (error === 'error') {
        setError('general', { message: errors[error] })
      } else {
        setError(error, {
          type: 'server',
          message: ERRORS_DICT[error][errors[error]]
        })
      }
    })
  }

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      await destroy(application.id, data)
      queryClient.invalidateQueries([ServerStateKeysEnum.Apps])
      showNotification(
        `Application was successfully removed`,
        NotificationType.SUCCESS
      )
      if (application.id === currentApp.id) await getSessionData()
      closeModal()
    } catch (error) {
      if (error.body?.errors) {
        handleErrors(error.body?.errors)
      } else {
        showGeneralError()
      }
    }
  }

  if (!application) return null

  return (
    <>
      <form
        onSubmit={(e) => {
          clearErrors('general')
          handleSubmit(onSubmit)(e)
        }}>
        <ModalBody>
          <div className="px-5 mx-3">
            <p className="text-base">
              Are you sure you want to delete this app? This will delete app
              data and cannot be undone!
            </p>
            <div className="mt-4 pt-2 flex pe-5">
              <Row>
                <Col xs={4}>
                  <span className="text-base text-muted fw-medium">
                    App Name:
                  </span>
                </Col>
                <Col xs={8}>
                  <span className="text-base">{application?.name}</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={4}>
                  <span className="text-base text-muted fw-medium">
                    App Type:
                  </span>
                </Col>
                <Col xs={8}>
                  <span className="text-base d-flex align-items-center">
                    <span className="pe-2">{application?.mode} Mode</span>{' '}
                    <ModeDot mode={application?.mode} />
                  </span>
                </Col>
              </Row>
            </div>
            <div className="mt-4 pt-2">
              <Input
                className={cn({ 'form-group--error': errors.name })}
                register={register}
                errorTooltip={errors.name?.message}
                name="name"
                validation={{
                  required: {
                    value: true,
                    message: 'Please enter App Name'
                  }
                }}
                label="Name"
                placeholder="Enter App Name"
              />
              <Input
                className={cn('mt-4', {
                  'form-group--error': errors.password
                })}
                register={register}
                errorTooltip={errors.password?.message}
                validation={{
                  required: {
                    value: true,
                    message: 'Please enter App password'
                  }
                }}
                name="password"
                type="password"
                label="Admin Password"
                placeholder="Enter Admin Password"
              />
            </div>
            {errors.general?.message && (
              <FormFeedback className="d-block" color={colors.danger}>
                {errors.general.message}
              </FormFeedback>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="danger" type="submit" disabled={isSubmitting}>
            Delete{' '}
            {isSubmitting && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="xs"
                className="ms-1"
              />
            )}
          </Button>
        </ModalFooter>
      </form>
    </>
  )
}

export default DeleteAppModal
