import React from 'react'
import cn from 'classnames'
import Switch from 'react-switch'

import colors from '~/utils/color'

interface IProps {
  checked: boolean
  className?: string
  controlId?: string
  onChange: (checked: boolean, event: any, id: string) => void
  disabled?: boolean
}

export default function ToggleSwitch({
  checked,
  className,
  controlId,
  onChange,
  disabled
}: IProps): React.ReactElement {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div onClick={(e) => e.stopPropagation()} className="d-flex flex-column">
        <Switch
          data-testid="collapseSwitch"
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          id={controlId}
          className={cn('react-switch', className)}
          onColor={colors.primary}
          width={50}
          height={24}
          handleDiameter={20}
          uncheckedIcon={false}
        />
      </div>
    </>
  )
}
