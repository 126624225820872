import React, { useState, useEffect, useMemo } from 'react'

import SelectWithSearch from '~/components/forms/SelectWithSearch'
import { getTimezones } from '~/api/timezones'

interface IProps {
  onChange: (value: { timeZoneName: string | undefined }) => void
  value: string
  disabled?: boolean
  direction?: 'up' | 'down'
  invalid?: boolean
}

const TimeZonePicker = ({
  onChange,
  value,
  disabled,
  direction,
  invalid = false
}: IProps) => {
  const [loading, setLoading] = useState(false)
  const [zones, setZones] = useState<
    { gmtOffsetPretty: string; ianaName: string }[]
  >([])

  useEffect(() => {
    setLoading(true)
    getTimezones()
      .then((_response: { gmtOffsetPretty: string; ianaName: string }[]) => {
        setZones(_response)
        setLoading(false)

        if (!_response.some((r) => r.ianaName === value)) {
          onChange({ timeZoneName: '' })
        }
      })
      .catch((_error) => {
        setLoading(false)
      })
  }, [])

  const options = useMemo(
    () =>
      zones.map((zone) => ({
        label: `${zone.gmtOffsetPretty} ${zone.ianaName}`,
        value: zone.ianaName
      })),
    [zones]
  )

  return (
    <SelectWithSearch
      disabled={disabled}
      value={value}
      invalid={invalid}
      isOptionSelected={(option, selectValue) =>
        option.value === selectValue[0].label
      }
      onChange={(timeZoneName) =>
        onChange({
          timeZoneName: timeZoneName.value
        })
      }
      theme="transparent"
      options={options}
      loading={loading}
      placeholder="Time Zone"
      direction={direction}
    />
  )
}

export default TimeZonePicker
