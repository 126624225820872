import React from 'react'

import './CardIcon.scss'

const CardIcon = ({ className }: { className: string }): React.ReactElement => (
  <svg
    id="eVdz01YvgjJ1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 136"
    width="72"
    className={className}
    height="136"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <rect
      width="70"
      height="134"
      rx="7"
      ry="7"
      transform="translate(1 1)"
      fill="#fff"
      stroke="#93a8c5"
      strokeWidth="2"
    />
    <rect
      width="58"
      height="102"
      rx="0"
      ry="0"
      transform="translate(7 17)"
      fill="#f4f7fc"
      stroke="#93a8c5"
      strokeWidth="2"
    />
    <g id="eVdz01YvgjJ4_to" transform="translate(95.5,52)">
      <g id="eVdz01YvgjJ4" transform="translate(-36,-52)" opacity="0">
        <rect
          width="45"
          height="35"
          rx="0"
          ry="0"
          transform="translate(13.5 34.5)"
          fill="#fff"
          stroke="#93a8c5"
        />
        <line
          x1="18"
          y1="63.5"
          x2="53"
          y2="63.5"
          fill="none"
          stroke="#93a8c5"
        />
        <rect
          width="45"
          height="16"
          rx="0"
          ry="0"
          transform="translate(13.5 34.5)"
          fill="#fff"
          stroke="#93a8c5"
        />
        <line
          x1="21"
          y1="58.5"
          x2="49"
          y2="58.5"
          fill="none"
          stroke="#93a8c5"
        />
      </g>
    </g>
    <g id="eVdz01YvgjJ9_to" transform="translate(-24,94)">
      <g id="eVdz01YvgjJ9" transform="translate(-36,-94)" opacity="0">
        <rect
          width="45"
          height="35"
          rx="0"
          ry="0"
          transform="translate(13.5 76.5)"
          fill="#fff"
          stroke="#93a8c5"
        />
        <line
          x1="18"
          y1="105.5"
          x2="53"
          y2="105.5"
          fill="none"
          stroke="#93a8c5"
        />
        <rect
          width="45"
          height="16"
          rx="0"
          ry="0"
          transform="translate(13.5 76.5)"
          fill="#fff"
          stroke="#93a8c5"
        />
        <line
          x1="21"
          y1="100.5"
          x2="49"
          y2="100.5"
          fill="none"
          stroke="#93a8c5"
        />
      </g>
    </g>
    <g id="eVdz01YvgjJ14_ts" transform="translate(36.2221,26.824) scale(0.2,1)">
      <path
        id="eVdz01YvgjJ14"
        d="M28.1732,22.66v1.116h-3.54v2.46h2.76v1.116h-2.76v3.648h-1.368v-8.34h4.908Zm2.6358,1.104v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm6.293,0v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm7.649-1.104c.888,0,1.664.172,2.328.516.672.336,1.188.824,1.548,1.464.368.632.552,1.372.552,2.22s-.184,1.584-.552,2.208c-.36.624-.876,1.104-1.548,1.44-.664.328-1.44.492-2.328.492h-2.724v-8.34h2.724Zm0,7.224c.976,0,1.724-.264,2.244-.792s.78-1.272.78-2.232c0-.968-.26-1.724-.78-2.268s-1.268-.816-2.244-.816h-1.356v6.108h1.356Z"
        transform="translate(-36.2221,-26.824)"
        opacity="0"
        fill="#93a8c5"
      />
    </g>
  </svg>
)

export default CardIcon
