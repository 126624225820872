import Skeleton from 'react-loading-skeleton'
import cn from 'classnames'
import { isEmpty, isNotEmpty } from '~/utils/utilities'
import Diff from './Diff'
import Tooltip from '~/components/reports/Tooltip'

import styles from '../Dashboard.scss'

interface IProps {
  title?: string
  value: string | number | undefined
  icon: string
  range: string | undefined
  diff?: string
  tooltip?: string
}

export default function UserStat({
  title,
  tooltip,
  value,
  icon,
  diff,
  range
}: IProps): React.ReactElement | null {
  return (
    <div
      className={cn(
        'd-flex justify-content-center align-items-end gap-4',
        styles.statUser
      )}>
      <div className="ps-3">
        <div
          className={cn(
            `icon icon--huge icon--${icon}`,
            styles.statIcon,
            styles.statIconUser
          )}
        />
      </div>
      <div className={cn(styles.statBox)}>
        <div className="d-flex">
          {title && <span className="fw-medium">{title}</span>}
          {tooltip && (
            <div className="ms-3 mt-n1">
              <Tooltip inline tooltip={tooltip} />
            </div>
          )}
        </div>
        {isEmpty(value) && <Skeleton />}
        {isNotEmpty(value) && (
          <p className="h1 mb-0 fw-normal">{value?.toLocaleString('en-US')}</p>
        )}
        {isNotEmpty(diff) && diff !== '- -' && (
          <div className="mb-1 mt-3">
            <Diff value={diff} range={range} />
          </div>
        )}
      </div>
    </div>
  )
}
