import { withStore } from '~/dataStore'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchValueOptions: {
  name: string
  value: 'any' | 'ios' | 'android' | 'web'
}[] = [
  {
    name: 'Any',
    value: 'any'
  },
  {
    name: 'Web',
    value: 'web'
  },
  {
    name: 'iOS',
    value: 'ios'
  },
  {
    name: 'Android',
    value: 'android'
  }
]

function RuleDeviceType({ rule }: IProps): React.ReactElement {
  return (
    <>
      <span aria-label="is">is</span>
      <RuleDropdown
        selected={
          matchValueOptions.find(
            (option) => option.value === rule.value.matchValue
          )?.name
        }
        options={matchValueOptions}
        action={(value) => rule.edit({ matchValue: value })}
        aria-label="Device Type"
      />
    </>
  )
}

export default withStore(RuleDeviceType)
