import { Col, Row } from 'reactstrap'
import CampaignIcon from '~/pages/Campaign/UI/CampaignIcon'
import DynamicContainer from '~/components/DynamicContainer'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { SingleRoute } from '~/dataStore/Store.interface'

export type NotificationTypeTile = {
  route: SingleRoute
  params?: Record<string, unknown>
  name: string
  onClick?: () => void
  description: string
  campaignType: CampaignType
  isDisabled?: boolean
}

interface IProps {
  type: NotificationTypeTile
}

export default function CampaignSubtile({ type }: IProps): React.ReactElement {
  return (
    <DynamicContainer
      data-testid={`subtile-${type.campaignType}`}
      tagName={type.onClick ? 'button' : 'div'}
      type="button"
      className="btn w-100 px-2 campaign-subtile text-start position-relative group"
      onClick={type.onClick}>
      <Row className="align-items-center">
        {type.isDisabled && (
          <div className="campaign-tile__label campaign-tile__label--gray">
            Coming Soon
          </div>
        )}
        <Col xs={6} className="d-flex align-items-center pe-5">
          <CampaignIcon
            type={type.campaignType}
            className="campaign-tile__icon mx-4"
          />
          <div className="flex-grow-1">
            <div className="d-flex align-items-center mb-2">
              <div className="campaign-tile__name m-0">{type.name}</div>
            </div>
            <p className="campaign-tile__description">{type.description}</p>
          </div>
        </Col>
        <Col xs={1}>
          <span className="campaign-tile__plus-icon text-muted">+</span>
        </Col>
        <Col xs={5}>
          <div className="d-flex flex-grow-1 align-items-center">
            <CampaignIcon
              type={CampaignType.CARD}
              className="campaign-tile__icon mx-4"
            />
            <div>
              <div className="m-0 campaign-tile__name">Feed Post</div>
              <p className="mb-0 campaign-tile__description">
                Appears in the feed
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </DynamicContainer>
  )
}
