import colors from '~/utils/color'

export const MENU_LIST_OFFSET_TOP = 70 // px
export const DEFAULT_DROPDOWN_HEIGHT = 270 // px
export const DEFAULT_DROPDOWN_WIDTH = 300 // px
export const DEFAULT_MENU_LIST_HEIGHT =
  DEFAULT_DROPDOWN_HEIGHT - MENU_LIST_OFFSET_TOP
export const THEMES = { TRANSPARENT: 'transparent' } as const

export const STYLE_OVERRIDES = {
  menu: (provided) => ({
    ...provided,
    background: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    borderRadius: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.pulsateBlue
  }),
  control: (provided) => ({
    ...provided,
    paddingLeft: '10px',
    paddingRight: '10px',
    borderColor: colors.baliHaiBlue,
    svg: {
      color: colors.dark
    }
  }),
  option: (provided, state) => {
    const asLabel = {
      opacity: state.data.asLabel ? 0.5 : 1,
      fontSize: state.data.asLabel ? '14px' : '15px',
      color: colors.pulsateBlue,
      ...(state.isDisabled && { color: colors.dark }),
      ...(state.isSelected && { color: 'white' }),
      paddingBottom: state.data.asLabel && '10px',
      fontWeight: 400
    }
    return { ...provided, ...asLabel }
  }
}
