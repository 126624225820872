import React, { useState } from 'react'
import { Button, Input, ModalBody, ModalFooter } from 'reactstrap'

import Modal from '~/components/modals/Modal'

interface IProps {
  onSubmit: (name: string, groupType: string) => void
  groupType: string
  isSaving: boolean
  closeModal: () => void
}

const CreateGroup = ({ closeModal, onSubmit, isSaving, groupType }: IProps) => {
  const [name, setName] = useState('')
  const handleChange = (e) => setName(e.target.value)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(name, groupType)
      }}>
      <ModalBody className="m-3">
        <Input placeholder="Group Name" value={name} onChange={handleChange} />
      </ModalBody>
      <ModalFooter>
        <Button color="" onClick={closeModal} disabled={isSaving}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => onSubmit(name, groupType)}
          disabled={isSaving}>
          Save
        </Button>
      </ModalFooter>
    </form>
  )
}

const CreateGroupModal = (props: Omit<IProps, 'closeModal'>) => (
  <Modal
    id="createGroupModal"
    size="sm"
    className="add-group-modal"
    renderHeader={() => 'Create New Group'}
    render={(close: () => void) => (
      <CreateGroup closeModal={close} {...props} />
    )}
  />
)

export default CreateGroupModal
