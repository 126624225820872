export interface FormValues {
  fcmSenderId: string
  fcmServerKey: string
}

export const ERRORS_DICT = {
  fcmServerKey: {
    'Must be a String': 'Server key is missing',
    'Missing parameter fcm_server_key': 'Server key is missing'
  },
  fcmSenderId: {
    'Must be a String': 'Sender ID is missing',
    'Missing parameter fcm_sender_id': 'Sender ID is missing'
  }
}
