import { useRef, useState } from 'react'
import { Button, Collapse } from 'reactstrap'
import cn from 'classnames'
import { DELIVERY, TARGETS } from '~/pages/CampaignBuilder/Email/consts'
import { Start } from '../../Blocks'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import SeparatorLine from '~/components/SeparatorLine'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import Segments from '~/pages/CampaignBuilder/TargetingStep/Segments'
import Geofences from '~/pages/CampaignBuilder/TargetingStep/Geofences'
import Beacons from '~/pages/CampaignBuilder/TargetingStep/Beacons'
import { withStore } from '~/dataStore'
import HintBtn from '~/HintWindow/HintBtn'
import styles from './EntryPanel.scss'
import usersIcon from '~/assets/img/users.svg'
import usersIconFuture from '~/assets/img/users-future.svg'
import OngoingSegment from './ongoingsegment.png'
import HintModal from '~/HintWindow/HintModal'

interface IProps {
  block: Start
  disabled: boolean
}

function EntryPanel({ block, disabled }: IProps): React.ReactElement {
  const hintRef = useRef<HTMLDivElement>(null)
  const [openPicker, setOpenPicker] = useState<Exclude<
    TARGETS,
    TARGETS.SUBSCRIPTIONS | TARGETS.IN_APP_EVENTS | TARGETS.EVENTS
  > | null>()

  const pickerProps = (
    targetingId: Exclude<
      TARGETS,
      TARGETS.SUBSCRIPTIONS | TARGETS.IN_APP_EVENTS | TARGETS.EVENTS
    >
  ) => ({
    disabled,
    name: targetingId,
    opened: openPicker === targetingId,
    isValid: block.targeting.targetingOption.isValid,
    setOpened: (open: boolean) => {
      setOpenPicker(open ? targetingId : null)
    },
    className: 'px-20p',
    checked: block.targeting[targetingId].isActive,
    setChecked: (check: boolean) => {
      block.resetError()
      if (!check) {
        block.targeting.reset(targetingId)
      } else {
        block.targeting[targetingId].setActive(true)
        block.targeting.validateTargetingOption()
      }
    }
  })

  return (
    <div>
      <Header className="mb-4 px-4">
        <HeaderTitle tag="h2">Starting Point</HeaderTitle>
        <p className="color-subheader">Chose who you want to target</p>
      </Header>

      <CollapseWithToggle {...pickerProps(TARGETS.SEGMENTS)}>
        <Segments
          isSended={disabled}
          targeting={block.targeting}
          contentScrollableBlue
          onSelectCallback={block.selectTarget}
        />
      </CollapseWithToggle>
      <SeparatorLine spaceBottom={0} spaceTop={0} />

      <CollapseWithToggle {...pickerProps(TARGETS.BEACONS)}>
        <Beacons
          isSended={disabled}
          targeting={block.targeting}
          contentScrollableBlue
          onSelectCallback={block.selectTarget}
        />
      </CollapseWithToggle>

      <SeparatorLine spaceBottom={0} spaceTop={0} />
      <CollapseWithToggle {...pickerProps(TARGETS.GEOFENCES)}>
        <Geofences
          isSended={disabled}
          targeting={block.targeting}
          contentScrollableBlue
          onSelectCallback={block.selectTarget}
        />
      </CollapseWithToggle>

      <Collapse isOpen={block.targeting.isSegmentsOnlyActiveTarget}>
        <div className="mb-6 px-4 mt-6" ref={hintRef}>
          <div className="d-flex align-items-center mb-2">
            <h5 className="color-body me-2 mb-0">
              Which users should receive this campaign?
            </h5>
            <HintBtn>How segments works</HintBtn>
          </div>
          <Button
            color=""
            className={cn(
              styles.futureBtn,
              'mb-1 w-100 d-flex align-items-center bg-white fw-medium px-4 btn--hover btn-outline-body text-start gap-3',
              {
                [styles.futureBtnActive]:
                  block.delivery.value === DELIVERY.CURRENT
              },
              {
                'error-input': !block.delivery.isValid
              }
            )}
            onClick={() => {
              block.setDelivery(DELIVERY.CURRENT)
            }}>
            <div className="me-2">
              <img
                src={usersIcon}
                alt="current"
                className={styles.futureIcon}
              />
            </div>
            <div>One-time Segment</div>
          </Button>
          <Button
            color=""
            className={cn(
              styles.futureBtn,
              'w-100 d-flex align-items-center bg-white fw-medium px-4 btn--hover btn-outline-body text-start gap-3',
              {
                'error-input': !block.delivery.isValid
              },
              {
                [styles.futureBtnActive]:
                  block.delivery.value === DELIVERY.CURRENT_FUTURE
              }
            )}
            onClick={() => {
              block.setDelivery(DELIVERY.CURRENT_FUTURE)
            }}>
            <div className="me-2">
              <img
                src={usersIconFuture}
                alt="future"
                className={styles.futureIcon}
              />
            </div>
            <div>Live Segment</div>
          </Button>
        </div>
      </Collapse>
      <HintModal elem={hintRef} offsetTop={250} mediaFile={OngoingSegment}>
        <h5>What is a One-time Segment?</h5>
        <p>A One-time Segment includes users who are in this segment now</p>

        <h5 className="mt-4">What is a Live Segment?</h5>
        <p>
          A Live Segment includes users who are in this segment now and users
          who enter this segment in the future
        </p>
      </HintModal>
    </div>
  )
}

export default withStore(EntryPanel)
