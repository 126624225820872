import { ReactElement } from 'react'

import InAppSmallModel from '~/pages/Campaign/Notification/InAppNotification/Model/InAppSmall.model'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import CampaignCTAButton from '../../../../CampaignBuilder/CTA/CampaignCTAButton'
import UploadImage from '~/components/uploadImage/UploadImage'
import { withStore } from '~/dataStore'
import SeparatorLine from '~/components/SeparatorLine'
import { ActionTypes } from '~/dataStore/Campaign/Campaign.interface'
import RecordEvent from '../../../../CampaignBuilder/RecordEvent'
import InAppPositionSwitch from './InAppPositionSwitch'
import Shared from '~/dataStore/Campaign/Shared'

function InAppSmallCorporate({
  notification,
  isWithCardCampaign = false,
  shared,
  disabled = false
}: {
  notification: InAppSmallModel
  isWithCardCampaign?: boolean
  shared: Shared
  disabled?: boolean
}): ReactElement {
  const { mergeTags, deeplinksOptions, eventsOptions } = shared

  return (
    <>
      <div className="d-flex">
        <UploadImage
          small
          maxFileSizes={[{ type: 'image', maxSize: 1_000_000, error: '1MB' }]}
          onChange={notification.image.addInternalFile}
          image={notification.image.value}
          fileName={notification.image.name}
          invalid={!notification.image.isValid}
          allowedTypes={['jpg', 'png', 'jpeg', 'gif']}
          className="mb-3"
        />
        <Textarea
          max={78}
          className="ms-3"
          onChange={notification.text.setValue}
          value={notification.text.value}
          invalid={!notification.text.isValid}
          required
          withoutLineBreaks
          disabled={disabled}
          onBlur={() => {
            notification.validateText()
            notification.validateTextInvalidChars()
          }}
          placeholder="Add an In-App notification... "
          mergeTags={mergeTags}
        />
      </div>
      {!isWithCardCampaign && (
        <CampaignCTAButton
          deeplinks={deeplinksOptions}
          field={notification.cta}
          onlyNotificationAction
          types={[
            ActionTypes.DEEPLINK,
            ActionTypes.URL,
            ActionTypes.OPEN_FEED,
            ActionTypes.DISMISS
          ]}
        />
      )}

      <SeparatorLine />

      <InAppPositionSwitch
        notification={notification}
        radioName="position-corparate"
      />

      {isWithCardCampaign && (
        <div className="py-3">
          <RecordEvent eventsOptions={eventsOptions} field={notification.cta} />
        </div>
      )}
    </>
  )
}

export default withStore(InAppSmallCorporate)
