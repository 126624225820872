import { useState, useEffect } from 'react'
import { Container } from 'reactstrap'

import { Prompt, useParams } from 'react-router-dom'
import cn from 'classnames'
import EmailErrors from '~/pages/CampaignBuilder/BottomActions/EmailErrors'
import { withStore, useStore } from '~/dataStore'
import useExitPrompt from '~/hooks/useExitPrompt'

import BottomActions from './BottomActions'
import Steps from '~/components/BuilderSteps'
import { getObjectFromSearchParams, useQuery } from '~/utils/utilities'
import './styles.scss'
import useAbortController from '~/hooks/useAbortController'
import ExitCampaign from './Email/ExitCampaign'
import CurrentStepComponent from './CurrentStepComponent'
import {
  CampaignType,
  InAppSize
} from '~/dataStore/Campaign/Campaign.interface'
import Modal from '~/components/modals/Modal'
import CampaignBuilderStatus from '~/components/Navbar/BuilderStatus'
import {
  isNotificationBuilderNotAllowed,
  NotificationType,
  showNotification
} from '~/utils/Notification'

function CampaignBuilder() {
  const [beforeExitStep, setBeforeExitStep] = useState<null>()

  const prompt = useExitPrompt()

  const {
    ui: { setShowSidebar, showSidebar, showModal },
    app: { openCurtain, closeCurtain, appDetails },
    campaign,
    campaign: {
      status,
      shared,
      campaignType,
      hasChanged,
      currentStep,
      initExistingCampaign,
      setStepByNumber,
      isCampaignSaving,
      isAutosaveActive,
      autoSaveCampaign,
      isSendedOrScheduled,
      steps,
      getStepNameByNumber,
      setForceShowTooltip,
      setUp: { name }
    },
    resetCampaign,
    goTo
  } = useStore()

  const {
    appId,
    campaignId,
    campaignType: paramCampaignType,
    notificationType,
    size: inAppSize
  } = useParams<{
    appId: string
    campaignId: string
    campaignType: CampaignType
    notificationType: CampaignType
    size: InAppSize
  }>()

  const query = useQuery()

  const abortController = useAbortController()

  useEffect(() => {
    if (!appDetails.id) return
    if (
      isNotificationBuilderNotAllowed(
        paramCampaignType,
        notificationType,
        inAppSize,
        appDetails.featureFlags
      )
    ) {
      goTo('campaignList', { appId })
    }
  }, [paramCampaignType, notificationType, appDetails, inAppSize])

  async function setBuilder(): Promise<void> {
    if (campaignId) {
      closeCurtain()
      try {
        await initExistingCampaign(
          appId,
          campaignId,
          false,
          false,
          abortController.getAbortController('fetchCampaign').signal
        )
      } catch (e) {
        if (e.name === 'AbortError') {
          console.error('Campaign fetching aborted')
          goTo('campaignList', { appId })
        }
      }
    } else if (!campaignType) {
      const newCampaign = resetCampaign(paramCampaignType, notificationType)
      newCampaign.shared.fetchMergeTags(appId)
      return
    }

    shared.fetchMergeTags(appId)
  }

  useEffect(() => {
    const queryParams = getObjectFromSearchParams(query)

    if (queryParams.step) {
      setStepByNumber(Number(queryParams.step))
    } else {
      setStepByNumber(1)
    }
  }, [query.toString()])

  useEffect(() => {
    setBuilder().then(() => {
      openCurtain()
    })
  }, [openCurtain, paramCampaignType])

  useEffect(() => {
    return () => {
      if (campaign.isAutosaveActive) {
        autoSaveCampaign(appId)
      }

      campaign.disposeReactions()
    }
  }, [paramCampaignType, campaign])

  useEffect(() => {
    return () => {
      resetCampaign()
    }
  }, [])

  useEffect(() => {
    function handleExit(e) {
      e.preventDefault()
      return (e.returnValue = 'Are you sure you want to exit?')
    }

    if (process.env.NODE_CUSTOM_ENV !== 'development') {
      if (hasChanged) {
        window.addEventListener('beforeunload', handleExit, { capture: true })
      } else {
        window.removeEventListener('beforeunload', handleExit, {
          capture: true
        })
      }

      return () =>
        window.removeEventListener('beforeunload', handleExit, {
          capture: true
        })
    }
  }, [hasChanged])

  const saveAsDraft = async (stepNumber: number) => {
    if (isAutosaveActive || !status) {
      try {
        if (stepNumber > currentStep.number) {
          currentStep.store.validateStep()
          if (!currentStep.store.isStepValid) {
            throw new Error('validation')
          }
        }

        await autoSaveCampaign(appId)
        setBeforeExitStep(null)
        setForceShowTooltip(false)
        goTo(undefined, {}, { step: stepNumber })
      } catch (error) {
        if (error.message !== 'validation') {
          showNotification(
            <EmailErrors errors={error.body?.errors || error.body?.details} />,
            NotificationType.ERROR
          )
        }
        setForceShowTooltip(true)
      }
    } else {
      goTo(undefined, {}, { step: stepNumber })
    }
  }

  useEffect(() => {
    setShowSidebar(false)

    return () => {
      setShowSidebar(true)
    }
  }, [setShowSidebar])

  if (!campaignType) {
    return null
  }

  return (
    <>
      <CampaignBuilderStatus
        autoSave
        loading={isCampaignSaving}
        exitRoute="campaignList"
        name={name.value}
        status={status}
      />
      <Container
        className={cn('step-content-wrapper pb-6', {
          'step-content-wrapper--full':
            getStepNameByNumber(currentStep.number) === 'email'
        })}>
        <CurrentStepComponent
          name={getStepNameByNumber(currentStep.number)}
          setBeforeExitStep={(beforeExitAction: () => void) =>
            setBeforeExitStep(() => beforeExitAction)
          }
        />
      </Container>

      <div
        className={`d-flex justify-content-between campaign-footer ${
          showSidebar ? 'campaign-footer--shrink' : ''
        }`}>
        <Steps
          currentStep={currentStep}
          isLoading={isCampaignSaving}
          steps={steps}
          onStepClick={saveAsDraft}
        />
        <BottomActions
          className="ms-3"
          beforeExitStep={beforeExitStep}
          setBeforeExitStep={setBeforeExitStep}
        />
        <Prompt
          when={hasChanged && isSendedOrScheduled}
          message={(location) => {
            if (
              campaign.hasChanged &&
              !location.pathname.includes('new_builder') &&
              !prompt.confirmedNavigation
            ) {
              prompt.setNewLocation(location)
              showModal('confirmExitCampaignModal')

              return false
            }

            return true
          }}
        />
      </div>

      <Modal
        id="confirmExitCampaignModal"
        renderHeader={() => 'Exit Campaign Builder'}
        render={(close: () => void) => (
          <ExitCampaign
            onSubmit={() => {
              close()
              prompt.setConfirmedNavigation(true)
            }}
            onClose={close}
          />
        )}
      />
    </>
  )
}

export default withStore(CampaignBuilder)
