import React, { useState } from 'react'
import { UncontrolledTooltip, Fade } from 'reactstrap'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
  faTimesCircle,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons'

import { useStore, withStore } from '~/dataStore'

import PrimaryGoalForm from './PrimaryGoalForm'
import SecondaryGoalForm from './SecondaryGoalForm'

import styles from './styles.scss'

const GoalsForm = () => {
  const [primaryGoalActive, setPrimaryGoalActive] = useState(true)
  const [secondaryGoalActive, setSecondaryGoalActive] = useState(false)

  const {
    campaign: {
      isSended,
      setUp: {
        events,
        primary,
        secondary,
        addSecondaryGoal,
        removeSecondaryGoal,
        validatePrimaryGoal,
        validateSecondaryGoal
      }
    }
  } = useStore()

  const togglePrimaryGoalForm = () => {
    validateSecondaryGoal()
    setPrimaryGoalActive(true)
    setSecondaryGoalActive(false)
  }

  const toggleSecondaryGoalForm = () => {
    addSecondaryGoal()
    validatePrimaryGoal()
    setPrimaryGoalActive(false)
    setSecondaryGoalActive(true)
  }

  const removeSecondary = () => {
    togglePrimaryGoalForm()
    removeSecondaryGoal()
  }

  const resetSecondaryGoalIcon = (): React.ReactElement => (
    <button className="btn p-0" type="button">
      <FontAwesomeIcon
        icon={faTimesCircle}
        onClick={removeSecondary}
        className="color-blue ms-1 mt-1"
      />
    </button>
  )

  const addSecondaryGoalIcon = () => (
    <FontAwesomeIcon
      icon={faPlusCircle}
      className={cn({ 'o-50': !secondaryGoalActive }, 'color-blue me-1')}
    />
  )

  const toggleButtonClassNames = cn(
    { disabled: !primaryGoalActive },
    `btn p-0 fw-medium mb-0 ${!primary.groupIsValid ? 'error-label' : ''}`
  )

  return (
    <>
      <div className="d-flex align-items-center mt-3 pt-1 pb-2">
        <button
          type="button"
          onClick={togglePrimaryGoalForm}
          className={toggleButtonClassNames}>
          Primary Goal
        </button>
        {(secondary || !isSended) && (
          <>
            <span className={cn(styles.toggleButtonsSeparator, 'ms-3 me-2')} />
            <button
              type="button"
              className={cn(
                { disabled: !secondaryGoalActive },
                `btn d-inline d-flex align-items-center text-blue p-0 fw-medium ms-2 ${
                  secondary && !secondary.groupIsValid ? 'error-label' : ''
                }`
              )}
              onClick={toggleSecondaryGoalForm}>
              {!secondary && addSecondaryGoalIcon()}{' '}
              <span style={{ lineHeight: '10px' }}>
                {secondary ? 'Secondary Goal' : 'Add Secondary'}
              </span>
            </button>
            {secondary && resetSecondaryGoalIcon()}
          </>
        )}
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="o-50 ms-auto"
          id="info"
          size="lg"
        />
        <UncontrolledTooltip placement="top" target="info">
          Set a Primary Goal to learn how the campaign performs and remember
          that you can also set a Secondary Goal to find out even more.
        </UncontrolledTooltip>
      </div>
      <Fade in={primaryGoalActive}>
        {primaryGoalActive && (
          <PrimaryGoalForm
            events={events}
            timeFramePanelActive={primaryGoalActive}
          />
        )}
      </Fade>
      <Fade in={secondaryGoalActive}>
        {secondaryGoalActive && (
          <SecondaryGoalForm
            events={events}
            timeFramePanelActive={secondaryGoalActive}
          />
        )}
      </Fade>
    </>
  )
}

export default withStore(GoalsForm)
