import React from 'react'
import { Col } from 'reactstrap'

import { withStore } from '~/dataStore'
import Select from '~/components/forms/Select'

function SelectDays({
  className,
  queryParams
}: {
  className: string
  queryParams: { days: number }
}) {
  return (
    <Col className={className}>
      <span className="text-nowrap">Time Period&nbsp;</span>
      <Select
        dataTestId="timeDropdownButton"
        right
        title=""
        options={[
          { name: '1 day', value: 1 },
          { name: '7 days', value: 7 },
          { name: '30 days', value: 30 }
        ]}
        selectedOption={queryParams.days}
        onSelect={(value) => {
          queryParams.days = value
        }}
      />
    </Col>
  )
}

export default withStore(SelectDays)
