import { computed, observable, makeObservable } from 'mobx'
import { ICampaign, IStepStore } from '../Campaign/Campaign.interface'
import { IRegisteredField } from './EmailBuilder.interface'

export default abstract class StepStore implements IStepStore {
  public beenValid = false

  public abstract validateStep(): void

  public abstract get registeredFields(): IRegisteredField[]

  public abstract fillStore<T extends ICampaign>(data: T): void

  public abstract getPayload(): unknown

  public getErrorMessages(): string[] {
    return this.registeredFields.flatMap((field) =>
      field.errors.map((error) => error.message)
    )
  }

  get isStepValid(): boolean {
    return this.registeredFields.every((field) => field.isValid)
  }

  public resetError(): void {
    this.registeredFields.forEach((field) => {
      field.resetError()
    })
  }

  constructor() {
    makeObservable(this, {
      beenValid: observable,
      isStepValid: computed
    })
  }
}
