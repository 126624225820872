import { DeviceType } from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  withWatch?: boolean
  activeDevice: DeviceType
  setDeviceType: (deviceType: DeviceType) => void
}

export default function DeviceTypeSwitch({
  activeDevice,
  setDeviceType,
  withWatch
}: IProps): React.ReactElement {
  function handleClick(device: DeviceType): () => void {
    return () => {
      setDeviceType(device)
    }
  }

  function getClassNames(device: DeviceType): string {
    const classes = ['mx-2', 'btn', 'btn-shadow', 'preview-switch', 'icon']

    classes.push(
      `icon--${device}-${activeDevice === device ? 'blue' : 'white'}`
    )

    if (activeDevice === device) {
      classes.push('preview-switch--active')
    }

    return classes.join(' ')
  }

  return (
    <div
      data-testid="device-switch"
      className="d-flex justify-content-center my-3">
      <button
        data-testid="ios-device-switch"
        className={getClassNames('ios')}
        type="button"
        aria-label="ios"
        onClick={handleClick('ios')}
      />
      <button
        data-testid="android-device-switch"
        className={getClassNames('android')}
        type="button"
        aria-label="android"
        onClick={handleClick('android')}
      />
      {withWatch && (
        <button
          data-testid="watch-device-switch"
          className={getClassNames('watch')}
          type="button"
          aria-label="iWatch"
          onClick={handleClick('watch')}
        />
      )}
    </div>
  )
}
