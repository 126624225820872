import React, { useEffect, useState } from 'react'
import { orderBy } from 'lodash'

import TableNew from '~/components/Table/TableNew'
import GeofenceItem from './GeofenceItem'
import { IGeofence, ICommonProps } from '~/pages/Geofences/Geofences.interface'
import { withStore } from '~/dataStore'

import './GeofencesList.scss'

interface IProps {
  geofencesList: IGeofence[]
  checked: Map<string, IGeofence>
  onCheckGeofence: (geofence: IGeofence, isSelected?: boolean) => void
  order: undefined | 'asc' | 'desc'
  sortField: string
  handleSort: (sortField: string) => void
  onDeleteRow: (geofence: IGeofence) => void
  editGeofence: (geofence: IGeofence) => void
}

const PER_PAGE_COUNT = 50

const GeofencesList = ({
  checked,
  onCheckGeofence,
  selectGeofence,
  selectedGeofence,
  editGeofence,
  geofencesList,
  onDeleteRow,
  handleSort,
  sortField,
  order
}: IProps & ICommonProps) => {
  const formatGeofences = (
    perPage: number = PER_PAGE_COUNT,
    sortOrder: 'asc' | 'desc' = 'desc',
    sortName = 'createdAt'
  ) =>
    orderBy(
      geofencesList.filter((geofence) => geofence.id !== 'temp'),
      [
        (geofence) => {
          if (sortName === 'name') {
            return geofence.name.toLowerCase()
          }

          return geofence[sortName]
        }
      ],
      [sortOrder]
    ).slice(0, perPage)

  const [perPageCount, setPerPageCount] = useState(PER_PAGE_COUNT)
  const [geofences, setGeofences] = useState(formatGeofences())

  useEffect(() => {
    if (!sortField) {
      setGeofences(formatGeofences(perPageCount))
    } else {
      setGeofences(formatGeofences(perPageCount, order, 'name'))
    }
  }, [order, sortField, geofencesList, perPageCount])

  const columns = [
    {
      fieldName: 'name',
      displayName: 'Geofence Name',
      classes: 'w-5 sort-container--inverted',
      sortable: true,
      // eslint-disable-next-line react/display-name
      render: ({ row }: { row: IGeofence }) => (
        <GeofenceItem
          row={row}
          key={row.id}
          onDeleteRow={onDeleteRow}
          selectGeofence={selectGeofence}
          editGeofence={editGeofence}
        />
      )
    }
  ]

  if (!geofences) return null

  return (
    <div className="geofences-list">
      <TableNew
        onLoadMore={() => {
          setPerPageCount((prev) => prev + PER_PAGE_COUNT)
        }}
        hasMore={perPageCount <= geofences.length}
        selectedRows={checked}
        selectedRow={selectedGeofence}
        onDeleteRow={onDeleteRow}
        borderless
        sortOrder={order}
        sortColumn={sortField}
        onSort={handleSort}
        onSelect={onCheckGeofence}
        rows={geofences}
        columns={columns}
      />
    </div>
  )
}

export default withStore(GeofencesList)
