import { ReactElement } from 'react'
import { Col, Row } from 'reactstrap'
import {
  CampaignType,
  DeviceType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'

import DeviceLogo from './DeviceLogo'
import PreviewMobile from '../PreviewMobile'

interface IProps {
  campaignType: CampaignType
  devices: {
    name: string
    logo: string
    deviceType: DeviceType
    className?: string
  }[]
  parts: IPreviewParts
}

export default function MobileDevicesInRow({
  campaignType,
  parts,
  devices
}: IProps): ReactElement {
  return (
    <Row>
      {devices.length === 2 && <Col xs={2} />}
      {devices.map((device) => (
        <Col
          xs={device.deviceType === 'watch' ? '3' : '5'}
          xxxl={device.deviceType === 'watch' ? '3' : '4'}
          key={device.deviceType}
          widths={['xs', 'xxxl']}
          className={device.className}>
          <DeviceLogo logo={device.logo} name={device.name} className="mb-4" />
          <PreviewMobile
            device={device.deviceType}
            deviceSize="lg"
            preview={parts}
            campaignType={campaignType}
            withTooltip={campaignType === CampaignType.CARD_FRONT}
          />
        </Col>
      ))}
    </Row>
  )
}
