import React from 'react'
import { MODES } from '../AppManager.interface'
import styles from '../AppManager.scss'
import color from '~/utils/color'

const ModeDot = ({ mode }: { mode: MODES.DEVELOPMENT | MODES.PRODUCTION }) => {
  const applicationModeColor = (state: MODES.DEVELOPMENT | MODES.PRODUCTION) =>
    state.toLocaleLowerCase() === MODES.PRODUCTION ? color.success : color.gray

  return (
    <span
      className={styles.modeIndicator}
      style={{ background: applicationModeColor(mode) }}
    />
  )
}

export default ModeDot
