import { getRequest } from '~/api/requests'
import { IDesign } from '~/dataStore/emailBuilder/EmailBuilder.interface'

export function getStockPhotos(
  searchQuery: string,
  page: number
): Promise<any> {
  return getRequest(
    `https://api.unlayer.com/v1/editor/5747/images/search?query=${searchQuery}&page=${page}`
  )
}

export function fetchDesignHTML(
  data: {
    displayMode?: 'email' | 'web'
    design: IDesign | undefined
    mergeTags: Record<string, unknown>
  },
  accessToken: string
): Promise<any> {
  return fetch('https://api.unlayer.com/v2/export/html', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}
