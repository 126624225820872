import React, { useState, useRef } from 'react'
import { GoogleMap, DrawingManager } from '@react-google-maps/api'
import CircleGeofence from './Circle/CircleGeofence'
import PolygonGeofence from './Polygon/PolygonGeofence'
import { DEFAULT_ZOOM, DRAWING_MANGER_OPTIONS } from '~/pages/Geofences/consts'
import {
  getPolygonPath,
  drawFullScreenControl,
  drawDrawingControls,
  drawZoomControls,
  radiusToMinMaxLimit,
  metersToFeet
} from '~/pages/Geofences/utils'
import {
  IGeofenceMapProps,
  ICommonProps
} from '~/pages/Geofences/Geofences.interface'

import './GeofencesMap.scss'

const GeofencesMap = ({
  addTempGeofence,
  isGeofenceSaving,
  isGoogleMapsLoaded,
  isInfoWindowOpen,
  disableAllShapesEdition,
  mapCenter,
  minRadius,
  maxRadius,
  unitSystem,
  setShapeEditable,
  polygonalGeofences,
  radialGeofences,
  removeGeofence,
  removeTempGeofence,
  selectGeofence,
  selectedGeofence,
  setInfoWindowOpen,
  toggleFullScreen,
  polygonsActive,
  saveGeofence,
  setMap,
  map
}: IGeofenceMapProps & ICommonProps): React.ReactElement => {
  const drawingManagerRef = useRef(null)
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null)
  const [isRadiusSliderDragged, setIsRadiusSliderDragged] = useState(false)

  const onLoad = React.useCallback(function callback(map) {
    const controlsWrapperDiv = document.createElement('div')
    controlsWrapperDiv.className = 'map-controls map-controls--drawing'
    const fullScreenControlDiv = document.createElement('div')
    fullScreenControlDiv.className = 'map-controls map-controls--full-screen'
    const zoomControlDiv = document.createElement('div')
    zoomControlDiv.className = 'map-controls map-controls--zoom'
    drawDrawingControls(controlsWrapperDiv, setDrawingMode, polygonsActive)
    drawFullScreenControl(fullScreenControlDiv, toggleFullScreen)
    drawZoomControls(zoomControlDiv, map)
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlsWrapperDiv)
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
      fullScreenControlDiv
    )
    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(zoomControlDiv)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  const onCircleComplete = (circle: google.maps.Circle) => {
    const {
      center: { lat, lng }
    } = circle
    const radius =
      unitSystem === 'imperial' ? metersToFeet(circle.radius) : circle.radius
    circle.setMap(null)
    setDrawingMode(null)
    addTempGeofence({
      radius: radiusToMinMaxLimit(radius, minRadius, maxRadius),
      location: [lat(), lng()],
      shape: 'circle'
    })
    selectGeofence('temp')
  }

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    polygon.setMap(null)
    setDrawingMode(null)
    addTempGeofence({
      shape: 'polygon',
      path: getPolygonPath(polygon)
    })
    selectGeofence('temp')
  }

  return (
    <div
      className={`geofences-map ${
        drawingMode ? `drawing-mode-${drawingMode}` : 'drawing-mode-reset'
      }`}>
      {isGoogleMapsLoaded && (
        <GoogleMap
          options={{
            fullscreenControl: false,
            zoomControl: false,
            streetViewControl: false,
            clickableIcons: false
          }}
          mapContainerStyle={{
            width: '100%',
            height: '100%'
          }}
          center={mapCenter}
          zoom={DEFAULT_ZOOM}
          onClick={() => {
            if (isRadiusSliderDragged) return
            disableAllShapesEdition()
            selectGeofence()
            removeTempGeofence()
            setInfoWindowOpen(false)
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}>
          <DrawingManager
            ref={drawingManagerRef}
            drawingMode={drawingMode}
            onCircleComplete={onCircleComplete}
            onPolygonComplete={onPolygonComplete}
            options={{
              ...DRAWING_MANGER_OPTIONS,
              drawingControlOptions: {
                position: google?.maps.ControlPosition.TOP_RIGHT,
                drawingModes: []
              }
            }}
          />
          {radialGeofences.map((g) => (
            <CircleGeofence
              key={g.id}
              setShapeEditable={setShapeEditable}
              maxRadius={maxRadius}
              minRadius={minRadius}
              selectGeofence={selectGeofence}
              setIsRadiusSliderDragged={setIsRadiusSliderDragged}
              geofence={g}
              saveGeofence={saveGeofence}
              removeGeofence={removeGeofence}
              setInfoWindowOpen={setInfoWindowOpen}
              showInfoWindow={isInfoWindowOpen && selectedGeofence === g.id}
              isGeofenceSaving={isGeofenceSaving}
              unitSystem={unitSystem}
              map={map}
            />
          ))}
          {polygonalGeofences.map((g) => (
            <PolygonGeofence
              key={g.id}
              geofence={g}
              selectGeofence={selectGeofence}
              showInfoWindow={isInfoWindowOpen && selectedGeofence === g.id}
              saveGeofence={saveGeofence}
              removeGeofence={removeGeofence}
              setInfoWindowOpen={setInfoWindowOpen}
              unitSystem={unitSystem}
              isGeofenceSaving={isGeofenceSaving}
              map={map}
            />
          ))}
        </GoogleMap>
      )}
    </div>
  )
}

export default GeofencesMap
