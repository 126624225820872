import React, { useEffect } from 'react'
import { Button, Container, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faSpinner } from '@fortawesome/free-solid-svg-icons'
import AsyncSelect from 'react-select/async'
import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import { useStore } from '~/dataStore'
import Input from '~/components/forms/Input'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import { fetchUsers } from '~/api/users'
import { useCreateChat } from '~/pages/Messages/useMessages'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import TableHeader from '~/components/Table/TableHeader'

interface FormValues {
  user: {
    value: string
    label: string
  }
  subject: string
  content: string
}

const NewMessage = () => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    mode: 'onBlur'
  })
  const { mutateAsync: createChat } = useCreateChat()
  const history = useHistory()
  const {
    goTo,
    app: {
      currentApp: { id: appId }
    }
  } = useStore()

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await createChat({
        subject: data.subject,
        content: data.content,
        userId: data.user.value
      })
      goTo('messages', { appId }, { id: response.id })
    } catch (e) {
      console.error(e)
    }
  }

  const loadOptions = async (
    inputValue: string,
    callback: (options: IOption[]) => void
  ) => {
    const response = await fetchUsers(appId, { searchQuery: inputValue })
    callback(
      response.data.map((user) => ({
        label: user.fullName,
        value: user.id
      }))
    )
  }

  useEffect(() => {
    if (history.location.state) {
      setValue('user', {
        value: history.location.state.userId,
        label: history.location.state.userName
      })
    }
  }, [])

  return (
    <Container fluid>
      <TableHeader>New Message</TableHeader>
      <div className="card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-center mt-3">
            <Button
              className="ms-2"
              color=""
              onClick={() => goTo('messages', { appId })}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </Button>

            <div className="d-flex align-items-center ms-2">
              <Label
                className={`mb-0 text-muted fw-medium fw-medium ${
                  errors?.user?.message ? 'error-label' : ''
                }`}>
                To:
              </Label>
              <Controller
                control={control}
                name="user"
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, value } }) => (
                  <AsyncSelect
                    value={value}
                    placeholder="Select user"
                    classNamePrefix="custom-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: 0
                      }),
                      input: (provided) => ({
                        ...provided,
                        width: '200px',
                        border: 0
                      })
                    }}
                    isClearable
                    noOptionsMessage={() => 'Type to search user'}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }}
                    onChange={(val) => onChange(val)}
                    loadOptions={loadOptions}
                    blurInputOnSelect
                  />
                )}
              />
            </div>
          </div>
          <Input
            name="subject"
            type="text"
            validation={{
              required: {
                value: true,
                message: 'Subject is missing'
              }
            }}
            className={cn('mt-3 py-2 border-top border-bottom', {
              'form-group--error': errors.subject
            })}
            errorTooltip={errors?.subject?.message}
            controlClassName="border-0 px-3"
            placeholder="Subject"
            register={register}
          />
          <Controller
            control={control}
            name="content"
            rules={{
              required: { value: true, message: 'Message content is missing' }
            }}
            render={({ field: { onChange } }) => (
              <Textarea
                invalid={errors?.content?.message}
                onChange={onChange}
                className="mx-3 py-2"
                placeholder="Message"
              />
            )}
          />

          <Button
            color="primary"
            className={cn('me-3 btn--wide mb-3 float-end', {
              disabled: isSubmitting
            })}>
            Send
            {isSubmitting && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="xs"
                className="ms-2"
              />
            )}
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default NewMessage
