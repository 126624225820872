import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import './Loading.scss'

const LOADING_ITEMS = 18

const Loading = () => (
  <>
    {[...Array(LOADING_ITEMS)].map((value, index) => (
      <div className="template-loading-indicator" key={index}>
        <div className="pb-3">
          <SkeletonTheme baseColor="#F0A0B1">
            <Skeleton circle height={50} width={50} />
          </SkeletonTheme>
        </div>
        <SkeletonTheme baseColor="#E1E4E9">
          <Skeleton count={4} width={110} />
        </SkeletonTheme>
      </div>
    ))}
  </>
)

export default Loading
