import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { RevenueFormValues } from './RevenueSection.interface'
import {
  addRevenueEvent,
  editRevenueEvent,
  fetchRevenueEvents,
  removeRevenueEvent
} from '~/api/appSettings'
import { hideSplashScreen } from '~/utils/splashScreen'
import { NotificationType, showNotification } from '~/utils/Notification'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { ID } from '~/common.interface'

const ERRORS_DICT = {
  currency: {
    'Missing parameter currency': 'Please select currency'
  },
  eventName: {
    'Missing parameter event_name': 'Please select event'
  },
  amount: {
    'Must be a Float': 'Invalid value, must be a number',
    'Must be greater than 0': 'Amount must be greater than 0',
    'must be a number and greater than 0':
      'Amount must be a number and be greater than 0'
  }
}

export enum ServerStateKeysEnum {
  RevenueEvents = 'revenueEvents'
}

export const useGetRevenueEvents = (appId: ID) =>
  useQuery([ServerStateKeysEnum.RevenueEvents], () =>
    fetchRevenueEvents(appId).finally(() => {
      hideSplashScreen()
    })
  )

export const useEditRevenueEvent = (close: () => void, setError: unknown) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (data: { data: RevenueFormValues; id: ID }) =>
      editRevenueEvent(appId, data.data, data.id),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.RevenueEvents])
        showNotification(
          'Successfully edited revenue event',
          NotificationType.SUCCESS
        )
        close()
      },
      onError: (errors) => {
        if (errors.body.errors) {
          getFormServerSideErrors(errors.body.errors, ERRORS_DICT).forEach(
            (er) => setError(er.name, { type: 'server', message: er.message })
          )
        } else {
          showGeneralError()
        }
      }
    }
  )
}

export const useAddRevenueEvent = (close: () => void, setError: unknown) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (revenue: RevenueFormValues) => addRevenueEvent(appId, revenue),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.RevenueEvents])
        showNotification(
          'Successfully added revenue event',
          NotificationType.SUCCESS
        )
        close()
      },
      onError: (errors) => {
        if (errors.body.errors) {
          getFormServerSideErrors(errors.body.errors, ERRORS_DICT).forEach(
            (er) => setError(er.name, { type: 'server', message: er.message })
          )
        } else {
          showGeneralError()
        }
      }
    }
  )
}

export const useRemoveRevenueEvent = () => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (data: { id: ID; closeModal: () => void }) =>
      removeRevenueEvent(appId, data.id),
    {
      onSuccess: (data, variables) => {
        cache.invalidateQueries([ServerStateKeysEnum.RevenueEvents])
        variables.closeModal()
        showNotification(
          'Successfully removed revenue event',
          NotificationType.SUCCESS
        )
      },
      onError: () => {
        showGeneralError()
      }
    }
  )
}
