import { Button } from 'reactstrap'
import { useState } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { withStore } from '~/dataStore'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import BuilderErrors from '~/pages/CampaignBuilder/BottomActions/BuilderErrors'
import styles from '../JourneyBuilderSidebar/Sidebar.scss'
import { isString } from '~/utils/utilities'
import { NotificationType, showNotification } from '~/utils/Notification'
import { CustomErrors } from '~/common.interface'
import InappNotificationBuilder from '~/pages/Campaign/Notification/InAppNotification/UI/InappNotificationBuilder'
import InAppNotification from '~/pages/Campaign/Notification/InAppNotification/Model/InAppNotification'

interface IProps {
  notification: InAppNotification
  close: () => void
  save: () => Promise<void>
  cancelCallback: () => void
  disabled?: boolean
}

const InappNotificationSidebar = ({
  notification,
  close,
  save,
  cancelCallback,
  disabled = false
}: IProps): React.ReactElement | null => {
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorsTooltip, setShowErrorsTooltip] = useState(false)

  async function onSaveClick(): Promise<void> {
    try {
      setIsLoading(true)
      await save()
      close()
    } catch (error) {
      if ((error as Error).message !== CustomErrors.INVALID) {
        console.error(error)

        if (isString((error as Error).message)) {
          showNotification((error as Error).message, NotificationType.ERROR)
        }
      }
      setShowErrorsTooltip(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className={cn('h-100 position-relative', styles.sidebarBlock)}>
        <div className="px-4">
          <Header className="mb-6">
            <HeaderTitle tag="h2" ariaLabel="In-App Notification Title">
              In-App
            </HeaderTitle>
            <p className="color-subheader">
              Prepare your message and remember that you can use other types of
              notification to reach a bigger audience
            </p>
          </Header>
          <InappNotificationBuilder
            inApp={notification}
            withGoals
            isSended={disabled}
          />
        </div>
      </div>
      <div
        className={cn(
          styles.sidebarBtns,
          'd-flex justify-content-end align-items-center'
        )}>
        {disabled ? (
          <Button
            color=""
            onClick={() => {
              close()
            }}>
            Back to Message
          </Button>
        ) : (
          <>
            <Button
              color=""
              disabled={isLoading}
              onClick={() => {
                cancelCallback()
                close()
              }}>
              Back to Message
            </Button>
            <Button
              id="save-block-btn"
              color="body"
              onClick={onSaveClick}
              disabled={isLoading}
              className="btn--hover px-4">
              Save
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="xs"
                  className="ms-1"
                />
              )}
            </Button>
            {notification.getErrorMessages().length > 0 && (
              <BuilderErrors
                key={`${notification.getErrorMessages()?.length}`}
                targetId="save-block-btn"
                messages={notification.getErrorMessages()}
                close={() => {
                  setShowErrorsTooltip(false)
                }}
                isOpen={!notification.isStepValid && showErrorsTooltip}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default withStore(InappNotificationSidebar)
