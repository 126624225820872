import { action, computed, observable, makeObservable } from 'mobx'
import { ButtonCategory } from '~/dataStore/App.interface'
import { CTAButton } from '~/dataStore/Campaign/Campaign.interface'
import { IRegisteredField, ValidationTypes } from '../EmailBuilder.interface'
import CTAField from './CTA.field'
import NewRegisteredField from '../RegisteredField.model'
import { GoalSide } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'

export default class CTAGroup {
  isActive = true

  buttons: CTAField[] = []

  buttonCategory = new NewRegisteredField<ButtonCategory | undefined>(undefined)

  buttonLabelsEquality = new NewRegisteredField<null>(null, true)

  private errorMessagePrefix: string | undefined

  constructor(prefix?: string, private withLabel = true) {
    makeObservable(this, {
      isActive: observable,
      buttons: observable,
      buttonCategory: observable,
      selectGroup: action.bound,
      setActive: action.bound,
      validate: action.bound,
      registeredFields: computed,
      payload: computed,
      isValid: computed
    })

    this.errorMessagePrefix = prefix || ''

    this.fillField = this.fillField.bind(this)
    this.validateCategory = this.validateCategory.bind(this)
    this.validateLabels = this.validateLabels.bind(this)
  }

  public selectGroup(value?: ButtonCategory): void {
    this.buttonCategory.setValue(value)
    this.buttons =
      value?.buttonLabels.map((button, index, list) => {
        let errorMessage = ''

        if (list.length > 1) {
          errorMessage = `button ${index + 1}`
        }

        if (this.errorMessagePrefix) {
          errorMessage = `${this.errorMessagePrefix} ${errorMessage}`
        }

        if (errorMessage) {
          errorMessage = `${errorMessage.trim()}: `
        }

        const field = new CTAField(errorMessage)
        field.withLabel = this.withLabel
        field.setValue(button)
        return field
      }) || []
  }

  public fillField({
    isActive,
    buttons,
    side = 'front'
  }: {
    isActive: boolean
    buttons?: any[]
    side?: GoalSide
  }): void {
    if (buttons?.length) {
      this.buttons.forEach((button, index) => {
        button.fillField(buttons[index], side)
      })
    }

    this.setActive(isActive)
  }

  public setActive(value: boolean): void {
    this.isActive = value
  }

  public validateCategory(): void {
    this.buttonCategory.validate(
      `${this.errorMessagePrefix}${
        this.errorMessagePrefix ? ': ' : ''
      }Select action buttons category`
    )
  }

  public validate(): void {
    this.buttonLabelsEquality.resetError()
    if (!this.isActive) {
      this.buttonCategory.resetError()
      this.buttons.forEach((button) => button.resetError())
      return
    }

    this.validateCategory()
    this.buttons.forEach((button) => button.validate())
  }

  public validateLabels(): void {
    this.buttonLabelsEquality.resetError()
    const btnWithLabels = this.buttons.filter((button) => button.withLabel)
    if (btnWithLabels.length < 2) {
      return
    }

    const labels = btnWithLabels.map((button) => button.value)

    if (new Set(labels).size !== labels.length) {
      this.buttonLabelsEquality.isValid = false
      this.buttonLabelsEquality.errors.push({
        type: ValidationTypes.REQUIRED,
        message: `You can not set the same labels on buttons`
      })
    }
  }

  public get registeredFields(): IRegisteredField[] {
    return [
      this.buttonCategory,
      ...this.buttons.flatMap(
        (button) =>
          [
            button.txtColor,
            button.btnColor,
            this.withLabel ? button.label : undefined,
            button.destinationType,
            button.isUrl ? button.destinationUrl : undefined,
            button.isDeeplink ? button.destination : undefined,
            button.isInAppEventsActive ? button.inAppEvents : undefined
          ].filter(Boolean) as IRegisteredField[]
      ),
      this.buttonLabelsEquality
    ]
  }

  public get payload(): CTAButton[] {
    if (!this.isActive) {
      return []
    }

    return this.buttons.map((button) => button.payload)
  }

  public get isValid(): boolean {
    if (!this.isActive) {
      return true
    }

    return (
      this.buttonCategory.isValid &&
      this.buttonLabelsEquality.isValid &&
      this.buttons.every((button) => button.isValid)
    )
  }
}
