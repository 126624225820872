import { ReactElement, useEffect, useState } from 'react'
import { Col, Collapse, Row } from 'reactstrap'
import cn from 'classnames'
import Input from '~/components/forms/Input'
import { useStore, withStore } from '~/dataStore'
import {
  ActionTypes,
  CampaignType
} from '~/dataStore/Campaign/Campaign.interface'
import cardBack from '~/components/icons/card_back.svg'
import thisCard from '~/components/icons/this_card.svg'
import deepLink from '~/components/icons/deeplink.svg'
import url from '~/components/icons/url.svg'
import feed from '~/components/icons/feed.svg'
import openApp from '~/components/icons/open_app.svg'
import dismiss from '~/components/icons/dismiss.svg'
import Deeplink from '../Deeplink'
import CTAField from '~/dataStore/emailBuilder/Fields/CTA.field'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import CTAButtonColorPicker from './CTAButtonColorPicker'

interface IProps {
  field: CTAField
  onlyNotificationAction?: boolean
  deeplinks: { value: string; name: string }[]
  types: ActionTypes[]
  readonlyLabel?: string
  buttonInputLabel?: string
  onDestinationTypeChange?: (value: ActionTypes) => void
  disabled?: boolean
  defaultBtnColor: string
  defaultTxtColor: string
  onBlur?: () => void
}
interface IAction {
  text: string
  icon: string
  type: ActionTypes
}

const actions: IAction[] = [
  {
    text: 'This Card',
    icon: thisCard,
    type: ActionTypes.THIS_CARD
  },
  {
    text: MCampaignTypeToName.get(CampaignType.CARD_BACK) || '',
    icon: cardBack,
    type: ActionTypes.CARD_BACK
  },
  {
    text: 'Deeplink',
    icon: deepLink,
    type: ActionTypes.DEEPLINK
  },
  {
    text: 'URL',
    icon: url,
    type: ActionTypes.URL
  },
  {
    text: 'Open Feed',
    icon: feed,
    type: ActionTypes.OPEN_FEED
  },
  {
    text: 'Open App',
    icon: openApp,
    type: ActionTypes.OPEN_APP
  },
  {
    text: 'Dismiss',
    icon: dismiss,
    type: ActionTypes.DISMISS
  }
]

function CampaignCTAButton({
  field,
  onlyNotificationAction,
  types,
  deeplinks,
  readonlyLabel,
  buttonInputLabel,
  onDestinationTypeChange,
  disabled = false,
  defaultTxtColor,
  defaultBtnColor,
  onBlur
}: IProps): ReactElement {
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()

  function filterActions() {
    return actions.filter((action) => types.includes(action.type))
  }

  const [actionList, setActionList] = useState(filterActions())

  useEffect(() => {
    setActionList(filterActions())
  }, [types])

  return (
    <>
      {!onlyNotificationAction && (
        <div className="my-3">
          {buttonInputLabel && (
            <span className="d-block mb-2 text-muted">{buttonInputLabel}</span>
          )}
          <Input
            disabled={disabled}
            value={field.value}
            placeholder="Button Text"
            onBlur={() => {
              field.validateLabel()
              field.validateLabelInvalidChars()
              onBlur?.()
            }}
            invalid={
              !field.label.isValid ||
              !field.txtColor.isValid ||
              !field.btnColor.isValid
            }
            className="mb-4"
            onChange={(e) => {
              field.setValue(e.target.value)
            }}
            suffix={
              featureFlags.changeNotificationButtonColors && (
                <CTAButtonColorPicker
                  button={field}
                  defaultTxtColor={defaultTxtColor}
                  defaultBtnColor={defaultBtnColor}
                />
              )
            }
          />
        </div>
      )}

      {readonlyLabel && (
        <p className="my-3">
          {readonlyLabel.toLowerCase().includes('emoji') ? (
            <span
              className={`icon icon--${readonlyLabel.toLowerCase()} me-2`}
            />
          ) : (
            readonlyLabel
          )}
        </p>
      )}
      <p
        className={cn('text-blue-gray mt-2 mb-2', {
          'error-label': !field.isValid
        })}>
        Notification Action
      </p>
      <Row className="mb-3 flex-nowrap" data-testid="cta-buttons">
        {actionList.map((action) => (
          <Col key={action.text}>
            <button
              disabled={disabled}
              onClick={() => {
                field.setType(action.type)
                field.validateType()
                onDestinationTypeChange?.(action.type)
              }}
              data-testid="cta-button"
              type="button"
              className={cn(
                'w-100',
                'btn',
                'bg-white-lilac',
                'cta__item',
                'd-flex flex-column justify-content-flex-end align-items-center',
                {
                  'cta__item--active':
                    action.type === field.destinationType.value
                },
                {
                  'error-input': !field.destinationType.isValid
                }
              )}>
              <span
                style={{ backgroundImage: `url(${action.icon})` }}
                className="cta__icon mb-3"
              />
              {action.text}
            </button>
          </Col>
        ))}
      </Row>

      <Collapse isOpen={field.destinationType.value === ActionTypes.DEEPLINK}>
        <Deeplink
          disabled={disabled}
          placeholder="Select Deeplink"
          options={deeplinks}
          selected={field.destination.value}
          onSelect={(value: string) => {
            field.setDestination(value)
            field.validateDeeplink()
          }}
        />
      </Collapse>

      <Collapse isOpen={field.destinationType.value === ActionTypes.URL}>
        <Input
          disabled={disabled}
          className="mb-0"
          value={field.destinationUrl.value}
          placeholder="https://www.example.com"
          onBlur={field.validateUrl}
          invalid={!field.destinationUrl.isValid}
          onChange={(e) => {
            field.setUrl(e.target.value)
          }}
        />
      </Collapse>

      {(field.destinationType.value === ActionTypes.DEEPLINK ||
        field.destinationType.value === ActionTypes.URL) && (
        <div className="mb-3" />
      )}
    </>
  )
}

export default withStore(CampaignCTAButton)
