import IconDelay from '../Nodes/NodeIcon/icons/IconDelay'
import IconNotification from '../Nodes/NodeIcon/icons/IconNotification'
import IconRedirect from '../Nodes/NodeIcon/icons/IconRedirect'
import IconSegment from '../Nodes/NodeIcon/icons/IconSegment'
import IconSplit from '../Nodes/NodeIcon/icons/IconSplit'
import { JourneyBlockType } from '../Store/JourneyBuilder.interface'

const colorMap = new Map([
  ['white', '#FFFFFF'],
  ['blueGray', '#93A8C5'],
  ['blue', '#28518C']
])

export default function BlockIcon({
  blockType,
  color = 'blueGray'
}: {
  blockType: JourneyBlockType
  color?: 'white' | 'blueGray' | 'blue'
}): React.ReactElement | null {
  const fill = colorMap.get(color)!
  switch (blockType) {
    case JourneyBlockType.ADDTOSEGMENT:
      return <IconSegment fill={fill} />
    case JourneyBlockType.SPLIT:
      return <IconSplit fill={fill} />
    case JourneyBlockType.MESSAGE:
      return <IconNotification fill={fill} />
    case JourneyBlockType.DELAY:
      return <IconDelay fill={fill} />
    case JourneyBlockType.REDIRECT:
      return <IconRedirect fill={fill} />
    default:
      return <>START</>
  }
}
