import { ID } from '~/common.interface'

export interface IEmailAddress {
  id: ID
  email: string
  expired: boolean
  verified: boolean
}

export const EMAIL_VERIFICATION_LIST_QUERY = 'emailVerificationList'

export const ERRORS_DICT = {
  email: {
    'is already taken': 'This email address is already taken',
    "can't be blank": 'Email is missing'
  }
}
