import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement } from 'react'
import { Col, Row } from 'reactstrap'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import AllMobileDevicesPreviews from '~/pages/Campaign/Notification/Preview/AllMobileDevicesPreview'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import SeparatorLine from '~/components/SeparatorLine'
import { useStore, withStore } from '~/dataStore'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import MobileCampaignPanel from '../MobileCampaignPanel/MobileCampaignPanel'

function SMSStep(): ReactElement {
  const {
    campaign: { shared, isSended, sms }
  } = useStore()

  return (
    <Row className="align-items-start">
      <Col
        widths={['md', 'xxl']}
        md={{ size: 7 }}
        xxl={{ size: 7 }}
        className="mb-4">
        <AllMobileDevicesPreviews
          parts={sms.generatePreview().sms}
          campaignType={CampaignType.SMS}
        />
      </Col>
      <Col
        widths={['md', 'xxl']}
        md={{ size: 5 }}
        xxl={{ size: 4, offset: 1 }}
        className="sticky">
        <MobileCampaignPanel
          campaignType={CampaignType.SMS}
          name="SMS Notification">
          <Textarea
            onChange={sms.bodyTemplate.setValue}
            value={sms.bodyTemplate.value}
            label="Notification text:"
            invalid={!sms.bodyTemplate.isValid}
            required
            max={600}
            withoutLineBreaks
            disabled={isSended}
            onBlur={sms.validateMessage}
            placeholder="Place for your message..."
            mergeTags={shared.mergeTags}
          />

          <SeparatorLine spaceBottom="2" spaceTop="2" />
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="o-50 d-block "
              size="lg"
            />
            <p className="ms-2 m-0 text-blue-gray text-13">
              Large messages might be segmented into 153 character segments and
              sent individually. There is no possibility to give exact character
              numbers in cases when merge tags or emojis are used.
            </p>
          </div>
        </MobileCampaignPanel>
      </Col>
    </Row>
  )
}

export default withStore(SMSStep)
