import cn from 'classnames'
import { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { ID } from '~/common.interface'
import {
  GoalSide,
  GoalSourceType,
  GoalType
} from '../NotificationGoals.interface'
import GoalCheckbox from './GoalCheckbox'

interface IProps {
  buttonID?: ID
  sourceType: GoalSourceType
  side?: GoalSide
  buttonLabel?: string
  reference?: string | ID
  checkedGoalType: GoalType | undefined
  selected: GoalType[]
  setGoal: (goal: {
    type: GoalType
    checked: boolean
    source: GoalSourceType
    side: GoalSide
    buttonLabel?: string
    reference?: string | ID
    buttonID?: ID
  }) => void
  updateBtnLabelGoal?: (
    actionId: string,
    buttonLabel: string | undefined
  ) => void
  listView?: boolean
  disabled?: boolean
}

export default function Goals(props: IProps): React.ReactElement {
  const {
    buttonID,
    sourceType,
    side = 'front',
    setGoal,
    updateBtnLabelGoal,
    checkedGoalType,
    buttonLabel,
    selected,
    reference,
    listView = false,
    disabled = false
  } = props

  useEffect(() => {
    if (updateBtnLabelGoal && buttonID) {
      updateBtnLabelGoal(buttonID, buttonLabel)
    }
  }, [buttonLabel, updateBtnLabelGoal, buttonID])

  return (
    <>
      {!listView && (
        <p className={cn('text-blue-gray mt-2 mb-2')}>Set in Journey as</p>
      )}

      <Row className="flex-1-1">
        <Col>
          <GoalCheckbox
            listView={listView}
            label="Primary Goal"
            invalid={false}
            readonly={disabled}
            disabled={
              (selected.includes('primary') && checkedGoalType !== 'primary') ||
              disabled
            }
            checked={checkedGoalType === 'primary'}
            type="primary"
            onChange={(checked) => {
              setGoal({
                type: 'primary',
                checked,
                side,
                source: sourceType,
                buttonLabel,
                reference,
                buttonID
              })
            }}
          />
        </Col>
        <Col>
          <GoalCheckbox
            listView={listView}
            label="Secondary Goal"
            invalid={false}
            readonly={disabled}
            disabled={
              (selected.includes('secondary') &&
                checkedGoalType !== 'secondary') ||
              disabled
            }
            checked={checkedGoalType === 'secondary'}
            type="secondary"
            onChange={(checked) => {
              setGoal({
                type: 'secondary',
                checked,
                side,
                source: sourceType,
                buttonLabel,
                reference,
                buttonID
              })
            }}
          />
        </Col>
      </Row>
    </>
  )
}
