import React, { useEffect, useState } from 'react'
import { uniqueId } from 'lodash'
import { ButtonCategory } from '~/dataStore/App.interface'
import { ActionTypes } from '~/dataStore/Campaign/Campaign.interface'
import SeparatorLine from '~/components/SeparatorLine'
import CTAField from '~/dataStore/emailBuilder/Fields/CTA.field'
import CampaignCTAButton from './CampaignCTAButton'
import Deeplink from './Deeplink'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import RecordEvent from '../RecordEvent'
import Goals from '../../Campaign/Notification/NotificationGoals/UI'
import NotificationGoals from '~/pages/Campaign/Notification/NotificationGoals'
import { useStore, withStore } from '~/dataStore'
import { GoalSide } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import Input from '~/components/forms/Input'
import colors from '~/utils/color'
import CTAButtonColorPicker from './CampaignCTAButton/CTAButtonColorPicker'

interface IProps {
  categoryOptions: { value: string; name: string }[]
  selected?: ButtonCategory
  onSelect: (value: string) => void
  deeplinks: (IOption & { name: string })[]
  buttons: CTAField[]
  eventsOptions: IOption[]
  notificationActionTypes: ActionTypes[]
  onlyNotificationAction?: boolean
  withGoals?: boolean
  notificationGoals?: NotificationGoals
  disabled?: boolean
  side?: GoalSide
  onDestinationTypeChange?: (value: ActionTypes) => void
  firstOnlyLabel?: boolean
  withEvent?: boolean
  onBlur?: () => void
}

function CampaignCTAGroupButton({
  categoryOptions,
  selected,
  onSelect,
  deeplinks,
  buttons,
  eventsOptions,
  notificationActionTypes,
  onlyNotificationAction = true,
  withGoals = false,
  notificationGoals,
  disabled = false,
  side = 'front',
  firstOnlyLabel = false,
  withEvent = false,
  onDestinationTypeChange,
  onBlur
}: IProps): React.ReactElement {
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()
  function parseOptions() {
    return categoryOptions.map((option) => {
      if (option.name.includes('Emoji')) {
        const emojis = option.name.split(' & ')
        return {
          ...option,
          name: (
            <>
              {emojis.map((emoji) => (
                <span
                  key={emoji}
                  className={`icon icon--${emoji.toLowerCase()} me-2`}
                />
              ))}
            </>
          )
        }
      }

      return option
    })
  }

  const [optionsWithIcons, setOptionsWithIcons] = useState(parseOptions())

  const [buttonsIds, setButtonsIds] = useState<string[]>(
    buttons?.map((_) => uniqueId('cta'))
  )

  useEffect(() => {
    setOptionsWithIcons(parseOptions())
  }, [categoryOptions])

  useEffect(() => {
    setButtonsIds(() => {
      return buttons.map((_) => uniqueId('cta'))
    })
  }, [buttons?.length])

  return (
    <>
      <div className="my-3">
        <Deeplink
          disabled={disabled}
          placeholder="Select Button Categories"
          options={optionsWithIcons}
          selected={selected?.id}
          onSelect={onSelect}
        />
      </div>

      {buttons.map((button, index) => (
        <React.Fragment key={`${buttonsIds?.[index]}${index}`}>
          {index > 0 && <SeparatorLine dashed />}
          {firstOnlyLabel && index === 0 ? (
            <>
              <span className="d-block mb-2 text-muted">
                Button 1 - This button opens the Feed Post
              </span>
              <Input
                value={button.value}
                placeholder="Button Text"
                onBlur={() => {
                  button.validateLabel()
                  button.validateLabelInvalidChars()
                }}
                invalid={!button.label.isValid}
                className="mb-4"
                onChange={(e) => {
                  button.setValue(e.target.value)
                }}
                suffix={
                  featureFlags.changeNotificationButtonColors && (
                    <CTAButtonColorPicker
                      button={button}
                      defaultTxtColor={index === 0 ? '#FFFFFF' : colors.azure}
                      defaultBtnColor={index === 0 ? colors.azure : '#FFFFFF'}
                    />
                  )
                }
              />
            </>
          ) : (
            <CampaignCTAButton
              disabled={disabled}
              field={button}
              defaultTxtColor={index === 0 ? '#FFFFFF' : colors.azure}
              defaultBtnColor={index === 0 ? colors.azure : '#FFFFFF'}
              buttonInputLabel={`Button ${index + 1}`}
              onlyNotificationAction={onlyNotificationAction}
              readonlyLabel={
                onlyNotificationAction && buttons.length > 1
                  ? button.label.value
                  : undefined
              }
              onBlur={onBlur}
              deeplinks={deeplinks}
              types={notificationActionTypes}
              onDestinationTypeChange={onDestinationTypeChange}
            />
          )}
          {withEvent && (
            <RecordEvent eventsOptions={eventsOptions} field={button} />
          )}

          {withGoals && notificationGoals && (
            <div className="mb-3">
              <Goals
                disabled={disabled}
                setGoal={notificationGoals.setGoal}
                sourceType="button"
                side={side}
                buttonID={button.buttonID}
                buttonLabel={button.label.value}
                updateBtnLabelGoal={notificationGoals.updateBtnLabelGoal}
                selected={notificationGoals.selectedGoalTypes}
                checkedGoalType={notificationGoals.isChecked({
                  sourceType: 'button',
                  buttonLabel: button.buttonID
                })}
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default withStore(CampaignCTAGroupButton)
