import cn from 'classnames'
import { Button } from 'reactstrap'
import styles from '../JourneyTeaser.scss'

export default function TeaserCTA(): React.ReactElement {
  return (
    <div className={cn(styles.cta)}>
      <h2 className={cn(styles.ctaHeader)}>
        Do you want to see Journeys in action?
      </h2>
      <p className={cn(styles.ctaDescription, styles.textContainer)}>
        Learn about all features and combine them in automation flows to create
        the most complete and precise marketing campaigns.{' '}
      </p>
      <Button
        tag="a"
        className={cn(styles.ctaBtn)}
        href="https://info.pulsatehq.com/pulsate-overview"
        target="_blank"
        rel="noreferrer">
        Register Interest
        <span className={cn(styles.ctaStrap)} />
        <span className={cn(styles.ctaStrap)} />
        <span className={cn(styles.ctaStrap)} />
        <span className={cn(styles.ctaStrap)} />
        <span className={cn(styles.ctaStrap)} />
        <span className={cn(styles.ctaStrap)} />
      </Button>
    </div>
  )
}
