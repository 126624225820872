import React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { isString } from '~/utils/utilities'
import UploadButton from '~/components/UploadButton'
import { BatchUploadErrors } from '~/common.interface'
import { getFormServerSideErrors } from '~/utils/validations'

interface IProps {
  errors?: BatchUploadErrors
  onClose: () => void
  onReUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
  entitiesName: string
  errorsDictionary: { [key: string]: Record<string, string> }
}

const BulkUploadErrorsModals = ({
  errors,
  onClose,
  onReUpload,
  entitiesName,
  errorsDictionary
}: IProps): React.ReactElement | null => {
  if (isString(errors)) return null
  return (
    <>
      <ModalBody className="pt-0 ps-0">
        {errors && (
          <>
            <p className="text-muted">
              The following {entitiesName} needs to be corrected.
            </p>
            <ul className="ps-4">
              {errors?.map((errorRow) => (
                <li
                  key={errorRow.row}
                  className="add-beacon-modal__upload-error-item color-danger mt-3 ps-0 mb-0 color-danger fw-normal">
                  Row {errorRow.row} <span className="px-1">-</span>{' '}
                  <ul>
                    {getFormServerSideErrors(
                      errorRow.error[0],
                      errorsDictionary
                    ).map((error) => (
                      <li key={error.name}>{error.message}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
            <p className="text-muted float-end mt-4 text-end">
              In order to add following {entitiesName} please upload <br />{' '}
              corrected versions in a separate .CSV file{' '}
            </p>
          </>
        )}
      </ModalBody>
      <div className="clearfix" />
      <ModalFooter>
        <div className="d-flex align-items-center">
          <Button color="" onClick={onClose}>
            Cancel
          </Button>
          <UploadButton
            onChange={onReUpload}
            buttonClassName="px-3"
            wrapperClassName="d-inline-block"
            label="Upload .CSV File"
          />
        </div>
      </ModalFooter>
    </>
  )
}

export default BulkUploadErrorsModals
