import React from 'react'

const DisabledFeatureWarning = ({
  feature
}: {
  feature: string
}): React.ReactElement => (
  <>
    Sorry, but {feature} are not enabled on your app. Please contact{' '}
    <a href="mailto:support@pulsatehq.com">support@pulsatehq.com</a> for more
    information.
  </>
)

export default DisabledFeatureWarning
