import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { ID } from '~/common.interface'
import { useStore, withStore } from '~/dataStore'
import { NotificationType, showNotification } from '~/utils/Notification'
import JourneyReportStore from './Store/JourneyReportStore'

const JourneyReportContext = createContext<undefined | JourneyReportStore>(
  undefined
)

function JourneyReportProvider({
  children
}: PropsWithChildren<unknown>): React.ReactElement | null {
  const { app, goTo } = useStore()
  const { journeyId } = useParams<{ journeyId: ID }>()

  const [store, setStore] = useState<JourneyReportStore | undefined>(undefined)

  useEffect(() => {
    if (!journeyId) {
      goTo('journeys', { appId: app.currentApp.id })
    }

    setStore(new JourneyReportStore(app.currentApp.id, journeyId))
  }, [app.currentApp.id, journeyId])

  useEffect(() => {
    if (!store) {
      return
    }

    store
      .fetchOverview()
      .then(() => store.fetchStats())
      .then(() => store.initNotificationReport())
      .catch((error) => {
        if (
          (error as Error).message === 'Wrong status' ||
          (error.status >= 400 && error.status < 500)
        ) {
          goTo('journeys', { appId: app.currentApp.id })
        } else {
          showNotification(
            'Something went wrong, please try again later.',
            NotificationType.ERROR
          )
          console.error(error)
        }
      })
  }, [store])

  if (!app.currentApp?.id || !store) {
    return null
  }

  return (
    <JourneyReportContext.Provider value={store}>
      {children}
    </JourneyReportContext.Provider>
  )
}

export const JourneyReportProviderWithStore = withStore(JourneyReportProvider)

const useJourneyReportStore = (): JourneyReportStore => {
  const context = useContext(JourneyReportContext)
  if (!context) {
    throw new Error('There is no Provider for this context')
  }

  return context
}

export default useJourneyReportStore
