import { uniqueId } from 'lodash'
import { PropsWithChildren, useRef } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

interface IProps {
  text: string | undefined
}

export default function TooltipNoIcon({
  text,
  children
}: PropsWithChildren<IProps>): React.ReactElement {
  const id = useRef(uniqueId('tooltip'))

  if (!text) {
    return <>{children}</>
  }

  return (
    <>
      <span id={id.current}>{children}</span>
      <UncontrolledTooltip placement="top" target={id.current}>
        {text}
      </UncontrolledTooltip>
    </>
  )
}
