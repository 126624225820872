import React from 'react'
import cn from 'classnames'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../AddNewBlockEdge.scss'
import { JourneyBlockType } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import TooltipNoIcon from '~/components/TooltipNoIcon'
import BlockIcon from '../../../components/BlockIcons'

interface IProps {
  blockType: JourneyBlockType
  isLoading: boolean
  onClick: () => void
  tooltip: string
  disabled: boolean
}

const AddNewBlockButton = ({
  blockType,
  isLoading,
  onClick,
  tooltip,
  disabled
}: IProps): React.ReactElement => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      disabled={disabled}
      data-testid="journeyAddNewBlockBtn"
      className={cn(
        'border-0 d-flex align-items-center justify-content-center',
        styles.menuItem,
        { [styles.menuItemDisabled]: disabled }
      )}>
      <TooltipNoIcon text={tooltip}>
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin size="1x" className="ms-1" />
        ) : (
          <BlockIcon blockType={blockType} color="white" />
        )}
      </TooltipNoIcon>
    </button>
  )
}

export default AddNewBlockButton
