import React from 'react'
import MasterCurrencyForm from './MasterCurrencyForm'
import RevenueEvent from './RevenueEvent'

const RevenueSection = () => (
  <>
    <div className="ms-auto mt-5">
      <div className="bg-body-main rounded d-flex justify-content-between p-4 align-items-center">
        <h3 className="text-white mb-0">Revenue</h3>
        <a
          href="https://pulsate.readme.io/v4.1/docs/average-revenue"
          className="text-nowrap d-inline-block text-white"
          target="_blank"
          rel="noreferrer">
          Learn more
        </a>
      </div>
    </div>
    <div className="mt-4">
      <MasterCurrencyForm />
      <RevenueEvent />
    </div>
  </>
)

export default RevenueSection
