import { withStore } from '~/dataStore'
import JourneyEmailNotificationStore from '../Store/JourneyEmailNotificationStore'

import './EmailNotification.scss'
import EmailNotificationBuilder from '~/pages/Campaign/Notification/EmailNotification/UI/EmailNotificationBuilder'

const EmailNotification = ({
  emailNotification
}: {
  emailNotification: JourneyEmailNotificationStore
}): React.ReactElement | null => {
  const {
    shared: { mergeTags },
    templates: {
      setCurrentTemplate,
      currentTemplate,
      isCurrentTemplateBlank,
      subject,
      fromName
    },
    email: { setEditorData, editorData, setEditorReady, getEditorReady }
  } = emailNotification

  return (
    <div className="journey-email-template-builder">
      <EmailNotificationBuilder
        currentTemplate={currentTemplate.value}
        mergeTags={mergeTags}
        designChangedCallback={setEditorData}
        editorData={editorData}
        isCurrentTemplateBlank={isCurrentTemplateBlank}
        setCurrentTemplate={setCurrentTemplate}
        setEditorData={setEditorData}
        setEditorReady={setEditorReady}
        getEditorReady={getEditorReady}
        testEmailData={{
          subject: subject.value,
          fromName: fromName.value.label,
          designData: editorData?.design
        }}
      />
    </div>
  )
}

export default withStore(EmailNotification)
