import React from 'react'
import { FormGroup, UncontrolledTooltip } from 'reactstrap'
import cn from 'classnames'

import {
  radioButtonToolbar,
  radioButton,
  checkedIndicator,
  selectedFirst,
  selectedSecond,
  divider
} from './style.scss'
import { isEmpty } from '~/utils/utilities'

interface IProps {
  className?: string
  selected: string
  options: {
    id?: string
    name: string
    label: string
    value: string
    tooltipText?: string
    width?: number
  }[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export default function ToggleRadioSwitch({
  selected,
  className,
  options,
  disabled,
  onChange
}: IProps): React.ReactElement {
  const selectedOptionIndex = options.findIndex(
    (option) => option.value === selected
  )

  const widths = options.map((o) => {
    if (!isEmpty(o.width)) {
      return `${o.width}px`
    }

    const ratio = (1 / options.length) * 100
    return `${ratio}%`
  })

  const translation = !isEmpty(options[0].width)
    ? `${selectedOptionIndex * options[0].width}px`
    : `${selectedOptionIndex * 100}%`

  const checkedIndicatorStyles = {
    width: widths[selectedOptionIndex],
    transform: `translateX(${translation})`
  }

  return (
    <div className={cn(radioButtonToolbar, className)}>
      {options.map((option, index) => (
        <React.Fragment key={`${option.id || option.name}-${index}`}>
          <FormGroup
            check
            className={cn(radioButton)}
            style={{
              width: widths[index]
            }}>
            <input
              disabled={disabled}
              type="radio"
              value={option.value}
              checked={selected === option.value}
              id={`${option.id || option.name}-${index}`}
              name={option.name}
              onChange={onChange}
            />
            <label
              htmlFor={`${option.id || option.name}-${index}`}
              data-testid={`${option.name}ToggleSwitch`}
              id={`${option.id || option.name}-${index}-label`}
              style={{ width: `${option.width}px` }}>
              <span>{option.label}</span>
            </label>
          </FormGroup>
          {option.tooltipText && (
            <UncontrolledTooltip
              placement="bottom"
              target={`${option.id || option.name}-${index}-label`}>
              {option.tooltipText}
            </UncontrolledTooltip>
          )}
        </React.Fragment>
      ))}
      {!options.some((option) => option.value === selected) && (
        <div className={divider} />
      )}
      <div
        className={cn(
          {
            [selectedFirst]: selectedOptionIndex === 0,
            [selectedSecond]: selectedOptionIndex === 1,
            'd-none': !options.some((option) => option.value === selected)
          },
          checkedIndicator
        )}
        style={checkedIndicatorStyles}
      />
    </div>
  )
}
