import React from 'react'
import { Button } from 'reactstrap'
import IconDelete from '~/components/icons/IconDelete'
import NotificationRow from '../NotificationRow'
import NotificationSelect from '../NotificationSelect'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { INotification } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import { ID } from '~/common.interface'

import styles from './NotificationListItem.scss'
import { withStore } from '~/dataStore'

interface IProps {
  notification: INotification
  setNotification: (notification: CampaignType, id: ID) => void
  setSelectedNotification: (notification: INotification) => void
  notificationSelectOptions: Array<{ name: string; value: CampaignType }>
  removeNotification: (id: ID) => void
  disabled?: boolean
}

const NotificationListItem = ({
  notification,
  setNotification,
  setSelectedNotification,
  notificationSelectOptions,
  removeNotification,
  disabled
}: IProps): React.ReactElement => {
  return (
    <div className={styles.notification}>
      {!disabled && <div className={styles.notificationDrag} />}
      <div className={styles.notificationPosition}>
        {notification.position + 1}
      </div>
      <div key={notification.id} className="d-flex flex-grow-1">
        {notification.type ? (
          <NotificationRow
            readonly={disabled}
            notification={notification}
            setNotification={setSelectedNotification}
          />
        ) : (
          <NotificationSelect
            options={notificationSelectOptions}
            isValid={notification.isValid}
            onSelect={(value: CampaignType) =>
              setNotification(value, notification.id)
            }
          />
        )}
        {!disabled && (
          <Button
            color=""
            className="p-0 ms-3"
            onClick={() => removeNotification(notification.id)}>
            <IconDelete />
          </Button>
        )}
      </div>
    </div>
  )
}

export default withStore(NotificationListItem)
