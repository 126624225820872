import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/react-query'
import { get } from '~/api/v2'
import {
  IApplication,
  IAppsDTO
} from '~/pages/App/Manager/AppManager.interface'
import { hideSplashScreen } from '~/utils/splashScreen'

export enum ServerStateKeysEnum {
  Apps = 'apps'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useAppManager() {
  return useInfiniteQuery<IAppsDTO, unknown, IApplication>(
    [ServerStateKeysEnum.Apps],
    ({ pageParam = 1 }) =>
      get('', {
        page: pageParam,
        sort: 'created_at',
        order: 'desc'
      }).then((response) => {
        hideSplashScreen()
        return response
      }),
    {
      select: (data): InfiniteData<IApplication> => ({
        pages: data.pages.flatMap((page) => page.data),
        pageParams: data.pageParams
      }),
      getNextPageParam: (lastPage, pages) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export const useInvalidateApps = () => {
  const cache = useQueryClient()
  return () => cache.invalidateQueries([ServerStateKeysEnum.Apps])
}
