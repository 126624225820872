import { get, post, put } from './v2'
import {
  IMessage,
  IMessagesQueryParams
} from '~/pages/Messages/Messages.interface'
import { IMetaData } from '~/common.interface'
import {
  IChatMessage,
  ICreatedChat
} from '~/pages/Messages/Chat/Chat.interface'

export default function getMessages(
  appId: string,
  params?: IMessagesQueryParams,
  abortSignal?: AbortSignal
): Promise<{ data: IMessage[]; metadata: IMetaData }> {
  return get(`${appId}/talks`, params, abortSignal)
}

export function getChat(
  chatId: string,
  appId: string,
  params?: IMessagesQueryParams,
  abortSignal?: AbortSignal
): Promise<{
  data: IChatMessage[]
  metadata: IMetaData
  userName: string
  unreadTalksCountAdmin: number
}> {
  return get(`${appId}/talks/${chatId}`, params, abortSignal)
}

export function createChat(
  appId: string,
  data: { userId: string; subject: string; content: string }
): Promise<ICreatedChat> {
  return post(`${appId}/talks`, data)
}

export function sendMessage(
  appId: string,
  chatId: string,
  message: string
): Promise<void> {
  return post(`${appId}/talks/${chatId}/messages`, { content: message })
}
export function assignMessageToAdmin(
  appId: string,
  chatId: string,
  adminId: string
): Promise<void> {
  return put(`${appId}/talks/${chatId}/update_admin`, { adminId })
}

export function markRead(appId: string, chatId: string): Promise<void> {
  return put(`${appId}/talks/${chatId}/update_talk_as_read`)
}
