import React from 'react'
import { Button } from 'reactstrap'
import cn from 'classnames'

interface IProps {
  iconSrc: any
  title: string
  description: string
  borderTop?: boolean
  borderRight?: boolean
  borderLeft?: boolean
  onSelect: () => void
  active: boolean
  className?: string
  wrapperClassName: string
  isDeliverySelected: boolean
}

const OptionBox = ({
  iconSrc,
  title,
  description,
  borderTop,
  borderRight,
  borderLeft,
  onSelect,
  active,
  wrapperClassName,
  className,
  isDeliverySelected
}: IProps): React.ReactElement => {
  return (
    <div
      className={cn(
        wrapperClassName,
        {
          'border-start': borderLeft,
          'border-end': borderRight,
          'border-top': borderTop,
          'border-bottom-transparent': active
        },
        'p-6 w-50 d-flex flex-wrap align-items-center border-body' +
          ' cursor-pointer transition-all'
      )}
      onClick={() => {
        if (active) return
        onSelect()
      }}>
      <div
        className={cn(
          className,
          'text-center pt-1 px-3 mx-auto card-img-hover'
        )}>
        <img src={iconSrc} className="d-block mx-auto" alt={title} />
        <p className="fw-medium mt-2">{title}</p>
        <p className="text-blue o-50">{description}</p>
        {!isDeliverySelected && (
          <Button className="mt-3 px-4 bg-white text-body border rounded border-body btn-hover">
            Select
          </Button>
        )}
      </div>
    </div>
  )
}

export default OptionBox
