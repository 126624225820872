import { makeAutoObservable } from 'mobx'
import { getGoalsRowsForNotification } from '~/pages/Journeys/Journey.service'
import {
  ChartDataType,
  IChartData,
  ILinkReport,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import { IEmailReport } from '../../Notification.interface'
import { EmailParts, IPreview } from '~/dataStore/Campaign/Campaign.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

export default class EmailReport {
  constructor(
    public report: IEmailReport,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send, unsubscribe } = this.report

    return [
      {
        label: 'Sent',
        content: send.totalUniq ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Total number of unique campaign sends, minus those who previously unsubscribed.',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      },
      {
        label: 'Unsubscribed',
        content: unsubscribe.totalUniq ?? '- -',
        className: 'summary-field--red',
        tooltip: 'Number of unsubscriptions generated by this campaign.',
        icon: 'icon--unsubscribe'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []

    const { delivery, open, bounce, clicks } = this.report

    return [
      {
        label: 'Delivered',
        content: delivery.totalUniq,
        tooltip:
          'Total number of emails that were delivered successfully to the receiving server.',
        rateLabel: 'Delivery Rate',
        rate: delivery.totalUniqRate,
        rateTooltip:
          'Total number of delivered emails, divided by the total number of emails sent. Similarly to the bounce rate, the delivery rate can give information about inaccurate or out-of-date emails.'
      },
      {
        label: 'Total Opens',
        content: open.totalUniq,
        tooltip:
          'Total number of times this email was opened. Sometimes this number is bigger than ‘Delivered’, as a recipient may open an email several times.',
        rateLabel: 'Open Rate',
        rate: open.totalUniqRate,
        rateTooltip:
          'Total number of email opens, divided by the number of emails delivered. Open Rate is a strong indicator of the effectiveness of a campaign.'
      },
      {
        label: 'Total Clicks',
        content: clicks.totalUniq,
        tooltip: 'Total number of clicks on links in this email.',
        rateLabel: 'Click Rate',
        rate: clicks.totalUniqRate,
        rateTooltip:
          'Percentage of delivered messages registering at least one click. Similar to open rate, click rate is a high-level indicator of audience engagement. '
      },
      {
        label: 'Bounced',
        content: bounce.totalUniq,
        tooltip:
          'The receiving server has refused delivery of the email. Several reasons this might happen, for more information see the documentation.',
        rateLabel: 'Bounce Rate',
        rate: bounce.totalUniqRate,
        rateTooltip:
          'Total number of email opens, divided by the number of emails delivered. Open Rate is a strong indicator of the effectiveness of a campaign.'
      }
    ]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    const { send, delivery, open, clicks } = this.report

    return [
      {
        name: 'Sent',
        valueCurrent: send.totalUniq,
        data: send.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Delivered',
        valueCurrent: delivery.totalUniq,
        data: delivery.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Total Clicks',
        valueCurrent: clicks.totalUniq ?? 0,
        data: clicks.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Total Opens',
        valueCurrent: open.totalUniq ?? 0,
        data: open.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Click Rate',
        valueCurrent: `${clicks.totalUniqRate}%`,
        data: clicks.uniqRateGraph?.slice() || [],
        dataType: ChartDataType.PERCENTAGE
      },
      {
        name: 'Open Rate',
        valueCurrent: `${open.totalUniqRate}%`,
        data: open.uniqRateGraph?.slice() || [],
        dataType: ChartDataType.PERCENTAGE
      }
    ]
  }

  public get clickPerformance(): ILinkReport[] {
    if (!this.report) return []
    return this.report.links
  }

  public get goals(): IGoalPerformance[] {
    if (!this.report) {
      return []
    }

    return getGoalsRowsForNotification({
      name: MCampaignTypeToName.get(this.report.type) || '- -',
      goals: this.report.goals
    })
  }

  public get preview(): EmailParts | undefined {
    if (!this.notificationPreview) return undefined

    return this.notificationPreview.email
  }
}
