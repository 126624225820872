import React from 'react'
import { Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from '~/components/modals/Modal/Modal'
import ConfirmationListModal from '~/components/modals/ConfirmationListModal'
import { useHideCampaignsFromFeed } from '~/pages/Campaign/CampaignsList/useCampaigns'

const HideFromFeedModal = () => {
  const { mutate: hideFromFeedHandle } = useHideCampaignsFromFeed()

  return (
    <Modal
      id="hideFromFeedModal"
      size="md"
      renderHeader={() => 'Hide from feed'}
      render={(close: () => void, params) => (
        <ConfirmationListModal
          actionType="hide from feed"
          elementType="Campaign"
          elements={params?.campaignsToHide || []}
          onClose={close}
          nameKey="name"
          onSubmit={() =>
            hideFromFeedHandle({
              ids: params?.campaignsToHide.map((c) => c.id),
              onSuccess: () => {
                close()
                params?.onSuccess()
              }
            })
          }>
          <Alert
            fade={false}
            closeClassName="d-none"
            color="warning"
            className="mb-0 mt-4">
            <div className="d-flex align-items-center p-2">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="2x"
                className="me-3"
              />
              <ul className="mb-0">
                <li>This action is not reversible.</li>
                <li>Hiding action may take up to 15 min </li>
              </ul>
            </div>
          </Alert>
        </ConfirmationListModal>
      )}
    />
  )
}

export default HideFromFeedModal
