import React from 'react'
import pluralize from 'pluralize'

import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'

const SingleTargetNameAndCount = ({
  target,
  count
}: {
  target: TARGETS | null
  count: number
}): React.ReactElement => {
  return (
    <span>
      {count} {pluralize(target, count)}
    </span>
  )
}

export default SingleTargetNameAndCount
