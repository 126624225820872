import cn from 'classnames'
import { useState } from 'react'
import { Button } from 'reactstrap'
import { JourneyTypeAsKey } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import { fetchAutoSegment } from '~/pages/Journeys/Connector/Journeys.connector'
import { ID } from '~/common.interface'
import { col, colWide, breakLine, colName } from '~/components/Table/Table.scss'
import { useStore, withStore } from '~/dataStore'
import { IAutoSegment } from '../JourneysList.interface'
import styles from './JourneyNameCell.scss'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import Link from '~/components/Link'
import routes from '~/routes'
import SegmentDetailsBtnWIthModal from '~/pages/Segment/SegmentDetailsBtnWIthModal'

interface IProps {
  name: string
  id: ID
  status: JourneyStatus
  childBlocksTypes: Record<JourneyTypeAsKey, boolean>
}

function JourneyNameCell({
  name,
  id,
  status,
  childBlocksTypes
}: IProps): React.ReactElement {
  const { app } = useStore()

  const [autoSegments, setAutoSegments] = useState<IAutoSegment[] | undefined>(
    undefined
  )

  const [active, setActive] = useState(false)

  async function fetchAutoSegments(): Promise<void> {
    if (autoSegments?.length) {
      setActive((prev) => !prev)
      return
    }

    try {
      const response = await fetchAutoSegment(app.currentApp.id, id)
      setActive((prev) => !prev)
      setAutoSegments(response)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      {status !== JourneyStatus.DRAFT ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          className={cn(col, colWide, colName, breakLine)}
          route={routes.journeysBuilder}
          params={{ journeyId: id, mode: 'details' }}>
          {name}
        </Link>
      ) : (
        <div title={name} className={cn(col, colWide, breakLine)}>
          {name}
        </div>
      )}
      {status !== JourneyStatus.DRAFT && childBlocksTypes.addToSegment && (
        <div>
          <Button
            onClick={fetchAutoSegments}
            color=""
            data-testid="autoSegmentsBtn"
            outline
            className={cn('p-0 btn-no-outline', styles.showMoreBtn, {
              [styles.showMoreBtnActive]: active
            })}>
            {!active && 'Show auto segments'}
            {active && 'Hide'}
          </Button>
          {autoSegments && active && (
            <div className={cn(styles.autoSegments)}>
              {autoSegments.map((segment) => (
                <div className={cn(styles.autoSegmentsItem)} key={segment.id}>
                  <SegmentDetailsBtnWIthModal segmentId={segment.id}>
                    <span
                      className={cn(col, colWide, 'truncate')}
                      title={segment.name}>
                      {segment.name}
                    </span>
                  </SegmentDetailsBtnWIthModal>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default withStore(JourneyNameCell)
