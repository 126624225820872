import React from 'react'
import { Collapse } from 'reactstrap'

import Checkbox from '~/components/forms/Checkbox'
import Schedule from './Schedule'
import { useStore, withStore } from '~/dataStore'

const DeliveryOnCertainDays = () => {
  const {
    campaign: {
      isSended,
      deliver: { timeWindows, timeWindowsActive, setTimeWindowsActive }
    }
  } = useStore()

  return (
    <div>
      <Checkbox
        disabled={isSended}
        checked={timeWindowsActive}
        onChange={() => {
          setTimeWindowsActive(!timeWindowsActive)
        }}
        label="Delivery on certain days"
        invalid={!timeWindows.isValid}
      />
      <Collapse isOpen={timeWindowsActive}>
        <div>
          <Schedule disabled={isSended} />
        </div>
      </Collapse>
    </div>
  )
}

export default withStore(DeliveryOnCertainDays)
