import React from 'react'
import { IStep } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import StepItem from './StepItem'
import { withStore } from '~/dataStore'
import { ICurrentStep } from '~/dataStore/Campaign/Campaign.interface'

const Steps = ({
  steps,
  currentStep,
  isLoading,
  onStepClick
}: {
  currentStep: ICurrentStep
  steps: IStep[]
  isLoading?: boolean | null
  onStepClick?: (number: number) => void
}): React.ReactElement => {
  const isPreviousStepValid = (number: number): boolean => {
    if (number === 1) return true

    const prevStep = steps.filter((step) => step.number < number)
    if (!prevStep.length) return false

    return prevStep.every((step) => step.isValid && step.beenValid)
  }

  return (
    <div className="d-flex justify-content-between align-item-center flex-fill steps-box">
      {steps.map((step) => (
        <StepItem
          onStepClick={onStepClick}
          isLoading={isLoading}
          currentStep={currentStep}
          amount={steps.length}
          isPreviousStepValid={isPreviousStepValid(step.number)}
          isValid={step.isValid}
          title={step.title}
          number={step.number}
          key={`${step.number}${step.title}`}
        />
      ))}
    </div>
  )
}

export default withStore(Steps)
