import React, {
  PropsWithChildren,
  useEffect,
  useState,
  useReducer,
  useRef
} from 'react'
import cn from 'classnames'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip
} from 'reactstrap'
import uniq from 'lodash/uniq'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { uniqueId } from 'lodash'
import styles from './ColorPicker.scss'
import Input from '~/components/forms/Input'
import colorPickerReducer, { ACTIONS } from './colorPickerReducer'
import { hexToRGB, isValidHex } from '~/components/ColorPicker/helpers'
import { IColor } from './ColorPicker.interface'

interface IProps {
  colors: string[]
  title: string
  className?: string
  currentColor?: string
  toggleClassName?: string
  onChange: ({ hex, r, g, b }: IColor) => void
}

const ColorPicker = ({
  onChange,
  className,
  toggleClassName,
  currentColor,
  title,
  children,
  colors
}: PropsWithChildren<IProps>): React.ReactElement => {
  const targetID = useRef(uniqueId('color-picker-'))
  const [color, dispatch] = useReducer(colorPickerReducer, {
    hex: currentColor || '',
    r: 0,
    g: 0,
    b: 0,
    ...(currentColor && { ...hexToRGB(currentColor) })
  })
  const [dropdownOpen, setOpen] = useState(false)

  const toggle = () => {
    setOpen((prevState) => !prevState)
  }

  useEffect(() => {
    if (color.hex !== currentColor) {
      onChange(color)
    }
  }, [color, onChange, currentColor])

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className={cn(className)}
        direction="down">
        <DropdownToggle
          id={targetID.current}
          color=""
          className={cn(styles.colorPickerToggle, toggleClassName)}>
          {children}
        </DropdownToggle>
        <DropdownMenu className={styles.dropdown}>
          <DropdownItem header className="text-18 text-muted mb-0">
            {title}
          </DropdownItem>
          <DropdownItem header className="pt-0">
            <div className="d-flex flex-wrap gap-2">
              {uniq(colors)?.map((clr) => (
                <div
                  key={clr}
                  onClick={() => {
                    dispatch({
                      type: ACTIONS.UPDATE_HEX,
                      payload: { hex: clr }
                    })
                  }}
                  className={cn(styles.swatchColor, 'cursor-pointer', {
                    [styles.swatchColorWhite]: clr === '#FFFFFF'
                  })}
                  style={{
                    backgroundColor: clr
                  }}>
                  {clr === color?.hex && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={clr === '#FFFFFF' ? 'text-body' : 'text-white'}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <Input
                className={styles.hex}
                label="HEX"
                labelClassName="text-muted"
                value={color.hex}
                invalid={!isValidHex(color.hex)}
                onChange={(e) => {
                  dispatch({
                    type: ACTIONS.UPDATE_HEX,
                    payload: { hex: e.target.value }
                  })
                }}
              />
              <div className="d-flex gap-2">
                <Input
                  value={color.r}
                  label="R"
                  max={255}
                  onChange={(e) => {
                    dispatch({
                      type: ACTIONS.UPDATE_R_VALUE,
                      payload: { r: e.target.value }
                    })
                  }}
                  className={styles.rgbInput}
                  labelClassName="text-muted"
                />
                <Input
                  value={color.g}
                  onChange={(e) => {
                    dispatch({
                      type: ACTIONS.UPDATE_G_VALUE,
                      payload: { g: e.target.value }
                    })
                  }}
                  label="G"
                  className={styles.rgbInput}
                  labelClassName="text-muted"
                />
                <Input
                  value={color.b}
                  onChange={(e) => {
                    dispatch({
                      type: ACTIONS.UPDATE_B_VALUE,
                      payload: { b: e.target.value }
                    })
                  }}
                  label="B"
                  className={styles.rgbInput}
                  labelClassName="text-muted"
                />
              </div>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {title && (
        <UncontrolledTooltip placement="top" target={targetID.current}>
          {title}
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default ColorPicker
