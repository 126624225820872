import React, { PropsWithChildren } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Link from '~/components/Link'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'

interface IProps {
  text?: string
  route?: any
  params?: any
}

export default function TableHeader({
  children,
  text,
  route,
  params
}: PropsWithChildren<IProps>): React.ReactElement {
  return (
    <Header className="d-flex justify-content-between align-items-end mt-0 pt-3 mb-4">
      <HeaderTitle className="mb-0" ariaLabel="Table Header">
        {children}
      </HeaderTitle>
      {text && route && (
        <Button
          tag={Link}
          params={params}
          route={route}
          size="lg"
          color="primary"
          className="btn--wide btn--hover">
          <FontAwesomeIcon size="xs" icon={faPlus} className="me-2" /> {text}
        </Button>
      )}
    </Header>
  )
}
