import React from 'react'
import { components, OptionProps } from 'react-select'

const Option = ({
  children,
  ...props
}: OptionProps<{ dataTestId: string }>): React.ReactElement => {
  const { data } = props
  return (
    <components.Option {...props} data-testid={data.dataTestId}>
      <span>{children}</span>
    </components.Option>
  )
}

export default Option
