import React from 'react'
import cn from 'classnames'
import { Pie } from 'react-chartjs-2'
import { ChartOptions } from 'chart.js'
import 'chart.js/auto'

import { CardBody, Card, CardHeader, CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import defaultColors from '~/utils/color'
import Tooltip from '~/components/reports/Tooltip'

interface IProps {
  title: string
  data: any[]
  labels: string[]
  colors: any[]
  thickness: number
  className?: string
  tooltip?: string
}

export default function PieChart({
  title,
  labels,
  data,
  colors = [
    defaultColors.primary,
    defaultColors.warning,
    defaultColors.success,
    defaultColors.danger,
    defaultColors.light
  ],
  thickness = 100,
  className,
  tooltip
}: IProps): React.ReactElement {
  const chartData = {
    title,
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderColor: 'transparent'
      }
    ]
  }

  const options: ChartOptions<'pie'> = {
    responsive: !window.MSInputMethodContext,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false }
    },
    cutout: 100 - thickness
  }

  return (
    <Card className={cn('flex-fill w-100', className)}>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h5" className="mb-0">
          {title}
        </CardTitle>
        {tooltip && <Tooltip tooltip={tooltip} />}
      </CardHeader>
      <CardBody className="d-flex">
        <div className="align-self-start w-100">
          <div className="py-3">
            <div className="chart chart-xs">
              <Pie data={chartData} options={options} />
            </div>
          </div>

          <Table className="mb-0">
            <tbody>
              {labels.map((label, i) => (
                <tr key={label}>
                  <td>
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: colors[i] }}
                    />{' '}
                    {label}
                  </td>
                  <td className="text-end">{data[i]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}
