import {
  hexToRGB,
  rgbToHex,
  isValidHex
} from '~/components/ColorPicker/helpers'
import { IColor } from './ColorPicker.interface'

export enum ACTIONS {
  UPDATE_HEX = 'updateHex',
  UPDATE_R_VALUE = 'updateRValue',
  UPDATE_G_VALUE = 'updateGValue',
  UPDATE_B_VALUE = 'updateBValue'
}

type UpdateHex = { type: ACTIONS.UPDATE_HEX; payload: { hex: string } }
type UpdateR = { type: ACTIONS.UPDATE_R_VALUE; payload: { r: string } }
type UpdateG = { type: ACTIONS.UPDATE_G_VALUE; payload: { g: string } }
type UpdateB = { type: ACTIONS.UPDATE_B_VALUE; payload: { b: string } }

type ReducerActions = UpdateHex | UpdateR | UpdateG | UpdateB

const colorPickerReducer = (state: IColor, action: ReducerActions): IColor => {
  let hex = null
  let r = null
  let g = null
  let b = null

  switch (action.type) {
    case ACTIONS.UPDATE_HEX:
      return {
        ...state,
        hex: action.payload.hex,
        ...(isValidHex(action.payload.hex) && {
          ...hexToRGB(action.payload.hex)
        })
      }
    case ACTIONS.UPDATE_R_VALUE:
      r = parseInt(action.payload.r, 10) || 0
      hex = rgbToHex(r, state.g, state.b)

      return {
        ...state,
        r,
        hex: isValidHex(hex) ? hex : state.hex
      }
    case ACTIONS.UPDATE_G_VALUE:
      g = parseInt(action.payload.g, 10) || 0
      hex = rgbToHex(state.r, g, state.b)

      return {
        ...state,
        g,
        hex: isValidHex(hex) ? hex : state.hex
      }
    case ACTIONS.UPDATE_B_VALUE:
      b = parseInt(action.payload.b, 10) || 0
      hex = rgbToHex(state.r, state.g, b)

      return {
        ...state,
        b,
        hex: isValidHex(hex) ? hex : state.hex
      }
    default:
      throw new Error('Unexpected action')
  }
}

export default colorPickerReducer
