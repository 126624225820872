import React from 'react'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IRevenueEvent } from '~/pages/App/ChannelSettings/sections/Revenue/RevenueSection.interface'
import { withStore, useStore } from '~/dataStore'

const ActionsColumn = ({ revenueEvent }: { revenueEvent: IRevenueEvent }) => {
  const {
    ui: { showModal }
  } = useStore()

  return (
    <div className="clearfix mt-2">
      <Button
        onClick={() =>
          showModal('confirmRevenueEventDeletion', { id: revenueEvent.id })
        }
        className="float-end text-body px-0 py-0 ms-3"
        color="white">
        <FontAwesomeIcon size="lg" icon={faTrash} />
      </Button>
      <Button
        className="float-end text-body px-0 py-0"
        color="white"
        onClick={() => showModal('editAddRevenueEventModal', { revenueEvent })}>
        <FontAwesomeIcon size="lg" icon={faPen} />
      </Button>
    </div>
  )
}

export default withStore(ActionsColumn)
