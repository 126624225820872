import { Button, ModalBody, ModalFooter, Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import Input from '~/components/forms/Input/Input'
import BatteryStatusBlock from '../BatteryStatusBlock'
import {
  IBeacon,
  NAME_FIELD_VALIDATION,
  MINOR_FIELD_VALIDATION,
  MAJOR_FIELD_VALIDATION,
  UUID_FIELD_VALIDATION,
  BEACON_ID_VALIDATION,
  BeaconFormValues
} from '../../Beacons.interface'
import { useAddBeacon, useEditBeacon } from '~/pages/Beacons/useBeacons'
import { useStore } from '~/dataStore'

interface IProps {
  closeModal: () => void
  beacon?: IBeacon
}

const EditAddBeaconModal = ({
  closeModal,
  beacon
}: IProps): React.ReactElement => {
  const {
    ui: { hideModal }
  } = useStore()
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm<BeaconFormValues>({
    mode: 'onChange'
  })

  const { mutate: editBeacon, isLoading: isEditLoading } = useEditBeacon(
    closeModal,
    setError
  )
  const { mutate: addBeacon, isLoading: isAddLoading } = useAddBeacon(
    hideModal,
    setError
  )

  const isEdit = !!beacon
  const onSubmit = (data: BeaconFormValues) => {
    const newBeacon = {
      ...data,
      major: parseInt(data.major),
      minor: parseInt(data.minor),
      uuid: data.uuid.toUpperCase()
    }
    if (isEdit) {
      editBeacon({ id: beacon.id, data: newBeacon })
    } else {
      addBeacon(newBeacon)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="px-0">
          <Row>
            <Col xs={6}>
              <Input
                name="nickname"
                defaultValue={beacon?.nickname}
                validation={NAME_FIELD_VALIDATION}
                register={register}
                className={errors.nickname ? 'form-group--error' : ''}
                label="Beacon Name"
                placeholder="Enter Beacon Name"
                tooltip="You can add a nickname to each beacon to help identify its location and function e.g 'Front door on the first floor'"
                errorTooltip={errors?.nickname?.message}
              />
            </Col>
            <Col xs={6}>
              <Input
                defaultValue={beacon?.beaconId}
                name="beacon_id"
                className={errors?.beaconId ? 'form-group--error' : ''}
                label="Beacon ID"
                register={register}
                validation={BEACON_ID_VALIDATION}
                tooltip="The beacon ID is a Pulsate identifier that is attached to each beacon label"
                placeholder="Enter Beacon ID"
                errorTooltip={errors?.beaconId?.message}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={3}>
              <Input
                className={errors?.major ? 'form-group--error' : ''}
                defaultValue={beacon?.major}
                name="major"
                register={register}
                label="Major"
                type="number"
                placeholder="Enter Major"
                validation={MAJOR_FIELD_VALIDATION}
                tooltip="The Major numbers is used when more than one beacon is used within a venue"
                errorTooltip={errors?.major?.message}
              />
            </Col>
            <Col xs={3}>
              <Input
                className={errors?.minor ? 'form-group--error' : ''}
                defaultValue={beacon?.minor}
                name="minor"
                register={register}
                label="Minor"
                type="number"
                placeholder="Enter Minor"
                validation={MINOR_FIELD_VALIDATION}
                tooltip="The Minor numbers is to distinguish a subspace within an area"
                errorTooltip={errors?.minor?.message}
              />
            </Col>
            <Col xs={6}>
              <Input
                className={errors?.uuid ? 'form-group--error' : ''}
                defaultValue={beacon?.uuid}
                name="uuid"
                tooltip="The UUID is a unique identifier for each app"
                errorTooltip={errors?.uuid?.message}
                register={register}
                placeholder="Enter UUID"
                validation={UUID_FIELD_VALIDATION}
                label="UUID"
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter
          className={`${isEdit && 'justify-content-between ps-0'} pe-0`}>
          {isEdit && <BatteryStatusBlock status={beacon?.battery} />}
          <div>
            <Button color="" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              className={`px-4 ${!isValid ? 'btn--error' : ''}`}
              color="body"
              type="submit"
              disabled={(!isEdit && !isDirty) || !isValid}>
              {(isAddLoading || isEditLoading) && (
                <FontAwesomeIcon
                  className="me-1"
                  icon={faSpinner}
                  spin
                  size="xs"
                />
              )}
              Save
            </Button>
          </div>
        </ModalFooter>
      </form>
    </>
  )
}

export default EditAddBeaconModal
