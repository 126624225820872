import cn from 'classnames'
import { Button } from 'reactstrap'
import Link from '~/components/Link'
import { JourneyTemplate } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import routes from '~/routes'

interface IProps {
  name: string
  members?: number
  journeyTemplate: JourneyTemplate
  icon: string
  recommended?: boolean
}

export default function OpportunityTemplate({
  name,
  icon,
  recommended,
  members,
  journeyTemplate
}: IProps) {
  return (
    <div className="col-4 opportunity-col">
      <div
        className={cn(
          'h-100 card d-flex py-5 opportunity-template flex-row mb-0',
          {
            'opportunity-template--recommended': recommended
          }
        )}>
        <div className="ps-5 px-2 col-7">
          <img src={icon} alt="" height={60} />
          <h4 className="fw-medium my-3">{name}</h4>
          <Link
            route={routes.journeysBuilder}
            params={{ mode: 'builder' }}
            state={{
              template: journeyTemplate,
              baseRoute: 'opportunitiesDashboard',
              segmentId: journeyTemplate?.opportunitySegmentId,
              missOpportunitySegmentId:
                journeyTemplate?.missOpportunitySegmentId
            }}
            color="">
            <Button
              color="transparent"
              className="rounded-lg px-3 px-xxl-3 px-xxxl-4 text-base fw-medium border text-black bg-white shadow-md border-mine-shaft">
              Start Campaign
            </Button>
          </Link>
        </div>
        <div className="col-4 ms-auto d-flex flex-column">
          <p>Audience size</p>
          <div className="opportunity-members py-3 ps-3 flex-1-1 d-flex justify-content-center align-items-start flex-column">
            <p className="mb-0 opportunity-members__number">
              {members?.toLocaleString('en-US')}
            </p>
            <p className="mb-0">Members</p>
          </div>
        </div>
      </div>
    </div>
  )
}
