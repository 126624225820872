import React from 'react'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStore, useStore } from '~/dataStore'
import { IWebSDKDomain } from '~/pages/App/Settings/sections/WebSDKSection/WebSDKSection.interface'

const ActionsColumn = ({ domain }: { domain: IWebSDKDomain }) => {
  const {
    ui: { showModal }
  } = useStore()

  return (
    <>
      <Button
        onClick={() => showModal('confirmDomainDeletion', { id: domain.id })}
        className="float-end text-body px-0 py-0 ms-3"
        color="white">
        <FontAwesomeIcon size="lg" icon={faTrash} />
      </Button>
      <Button
        className="float-end text-body px-0 py-0"
        color="white"
        onClick={() => {
          showModal('editAddDomainModal', { domain })
        }}>
        <FontAwesomeIcon size="lg" icon={faPen} />
      </Button>
    </>
  )
}

export default withStore(ActionsColumn)
