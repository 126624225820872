import { UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { humanDateTime } from '~/utils/time'
import ReportBtn from '../ReportBtn'
import routes from '~/routes'
import StatusColumn from '~/components/Table/components/StatusColumn'
import CampaignNameColumn from '~/components/Table/components/CampaignNameColumn'
import { ICampaignReportDTO } from './CampaignReportList.interface'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { generateListsDisplayType } from '~/pages/Campaign/Notification/Notification.service'

import { colMed, colType } from '../style.scss'

function formatValue(
  value: string | number | null | undefined
): string | number {
  return value ?? '- -'
}

const columns = [
  {
    fieldName: 'name',
    displayName: 'Name',
    sortable: true,
    // eslint-disable-next-line react/display-name
    render: ({
      row: { name, id }
    }: {
      row: ICampaignReportDTO
    }): React.ReactElement => (
      <CampaignNameColumn
        name={name}
        isRedirectActive={false}
        campaignId={id}
      />
    )
  },
  {
    fieldName: 'createdAt',
    displayName: 'Date Created',
    sortable: true,
    render: ({ row: { createdAt } }: { row: ICampaignReportDTO }): string =>
      humanDateTime(createdAt)
  },
  {
    fieldName: 'type',
    displayName: 'Type',
    sortable: true,
    classes: colType,
    render: ({ row: { typeList } }: { row: ICampaignReportDTO }) =>
      generateListsDisplayType(typeList)
  },
  {
    fieldName: 'status',
    displayName: 'Status',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: ICampaignReportDTO }): React.ReactElement => (
      <StatusColumn row={row} pauseStatues={[]} />
    )
  },
  {
    fieldName: 'deliveryCount',
    displayName: 'Delivered',
    sortable: true,
    classes: colMed,
    render: ({
      row: {
        delivery: { totalUniq }
      }
    }: {
      row: ICampaignReportDTO
    }): string | number => formatValue(totalUniq)
  },
  {
    fieldName: 'impressionsCount',
    displayName: 'Impressions',
    sortable: true,
    classes: colMed,
    render: ({
      row: {
        impression: { totalUniq },
        type,
        id
      }
    }: {
      row: ICampaignReportDTO
    }): React.ReactNode | string | number => {
      if (
        type.toLowerCase() === CampaignType.PUSH ||
        type.toLowerCase() === CampaignType.EMAIL
      ) {
        return (
          <>
            <span id={`impression-col-${id}`}>{formatValue(totalUniq)}</span>
            <UncontrolledTooltip
              placement="top"
              target={`impression-col-${id}`}>
              Not Applicable
            </UncontrolledTooltip>
          </>
        )
      }
      return formatValue(totalUniq)
    }
  },
  {
    fieldName: 'clicksCount',
    displayName: 'Clicks',
    classes: colMed,
    render: ({
      row: {
        clicks: { totalUniq }
      }
    }: {
      row: ICampaignReportDTO
    }): string | number => formatValue(totalUniq)
  },
  {
    fieldName: 'clickThroughRate',
    displayName: 'CTR',
    classes: colMed,
    render: ({
      row: {
        clicks: { totalUniqRate }
      }
    }: {
      row: ICampaignReportDTO
    }): string => {
      const ctr = formatValue(totalUniqRate)
      if (ctr === '- -') {
        return ctr
      }
      return `${ctr}%`
    }
  },
  {
    displayName: 'Report',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: ICampaignReportDTO }): React.ReactElement => (
      <ReportBtn
        status={row.status}
        id={row.id}
        paramName="campaignId"
        route={routes.campaignReport}
      />
    )
  }
]

export default columns
