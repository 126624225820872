/* Role types: master_admin, account_admin, app_admin
    *************************
    ** DIFFERENT ACCESSES: **
    *************************

    master_admin:
    All pages
    Able to add new users in Account Settings --> Admins

    account_admin:
    All pages
    Only restriction is that they are unable to add/edit users in the Account Settings --> Admins

    app_admin:
    - Account Settings
    - Documentation in settings drop down as per production
    - Logout
*/

// This object includes permissions used to show links for both the navbar and sidebar
// AccountDropdown.js and Sidebar.js respectively

export default {
  master_admin: ['all'],
  account_admin: [
    'app settings',
    'account settings',
    'app manager',
    'documentation',
    'sign out',
    'analyze',
    'targeting',
    'campaigns',
    'journeys',
    'users',
    'messages'
  ],
  app_admin: [
    'account settings',
    'documentation',
    'sign out',
    'analyze',
    'targeting',
    'campaigns',
    'journeys',
    'users',
    'messages'
  ],
  demo_admin: [
    'app settings',
    'channel settings',
    'account settings',
    'sign out',
    'analyze',
    'targeting',
    'campaigns',
    'journeys',
    'users'
  ]
}
