import { ReactElement, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Input from '~/components/forms/Input'
import { fetchCurrentAdmin, signInAdmin } from '~/api/admins'
import { isAdminAuthorized } from '~/api/auth'
import { hookFormValidation, showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'
import { IFormFields } from './Auth.interface'
import PasswordInput from '~/components/forms/PasswordInput'
import Link from '~/components/Link'
import ProtectedSiteInfo from '~/pages/Auth/components/ProtectedSiteInfo'

const getAppIdFromPath = (path: string) => path.split('/')[3]

const SignIn = (): ReactElement => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    control,
    clearErrors,
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting }
  } = useForm<IFormFields>({
    mode: 'onChange'
  })
  const history = useHistory()

  const redirectUser = (path: string) => {
    if (
      history.location.state?.from &&
      getAppIdFromPath(path) === getAppIdFromPath(history.location.state.from)
    ) {
      window.location.href = history.location.state.from
    } else if (path.includes('talks')) {
      window.location.href = path
    } else {
      history.push(path)
    }
  }

  const handleErrors = (error: unknown) => {
    if (error.body.errors) {
      if ('credentials' in error.body.errors) {
        setError('credentials', {
          type: 'server',
          message: error.body.errors.credentials[0]
        })
      }
    } else {
      showGeneralError()
    }
  }

  const onSubmit = async (data: IFormFields) => {
    if (executeRecaptcha) {
      const token = await executeRecaptcha('login')
      clearErrors()
      try {
        const { path } = await signInAdmin({
          ...data,
          'g-recaptcha-response': token
        })
        redirectUser(path)
      } catch (error) {
        handleErrors(error)
      }
    } else {
      showNotification(
        'Something went wrong, please reload the page.',
        NotificationType.ERROR
      )
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('active-session')) {
      showNotification(
        "You've been logged out due to inactivity. Please log in again.",
        NotificationType.ERROR
      )
      sessionStorage.removeItem('active-session')
    }
    // Redirect user away if he is already logged in
    isAdminAuthorized().then((response) => {
      if (response.success) {
        fetchCurrentAdmin().then((data) => {
          redirectUser(data.path)
        })
      }
    })
  }, [])

  return (
    <div>
      <form
        onSubmit={(e) => {
          clearErrors()
          handleSubmit(onSubmit)(e)
        }}>
        <Input
          validation={{
            required: {
              value: true,
              message: 'Username or Email is missing'
            }
          }}
          errorTooltip={errors?.credentials?.message || errors.login?.message}
          className={cn({
            'form-group--error': errors.credentials || errors.login
          })}
          name="login"
          placeholder="Enter Username or Email "
          label="Username or Email"
          register={register}
          aria-label="Username or Email"
        />
        <Controller
          control={control}
          name="password"
          rules={{ required: hookFormValidation.password.required }}
          render={({ field: { onChange } }) => (
            <PasswordInput
              className="mt-4 pt-2"
              name="password"
              onChange={onChange}
              error={errors?.credentials?.message || errors.password?.message}
              label={
                <div className="d-flex justify-content-between">
                  <span>Password</span>
                  <Link
                    tabIndex={-1}
                    className=""
                    href={{ pathname: '/admins/forgot_password' }}
                    route={{ path: '/admins/forgot_password' }}>
                    Forgot your password?
                  </Link>
                </div>
              }
              aria-label="Password"
            />
          )}
        />
        <Button
          color=""
          type="submit"
          className={cn('mt-4 d-block w-100 btn-body', {
            disabled: !isValid,
            'border border-danger': errors?.credentials?.message
          })}
          disabled={isSubmitting}
          id="sign-in-btn">
          Sign in{' '}
          {isSubmitting && (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-1" />
          )}
        </Button>
        {errors?.credentials?.message && (
          <UncontrolledTooltip
            placement="top-start"
            target="sign-in-btn"
            className="tooltip-validation-error">
            {errors?.credentials?.message}
          </UncontrolledTooltip>
        )}
        <ProtectedSiteInfo />
      </form>
    </div>
  )
}

export default SignIn
