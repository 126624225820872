import cn from 'classnames'
import { isEmpty, isNumber } from '~/utils/utilities'

import styles from '../Dashboard.scss'

interface IProps {
  value: string | number | undefined
  range: string | undefined
}

export default function Diff({
  value,
  range
}: IProps): React.ReactElement | null {
  if (isEmpty(value) || value === '- -') {
    return null
  }

  return (
    <div className={styles.diff}>
      {isNumber(value) && value > 0 ? (
        <span className={cn(styles.diffPositive)}>{value}%</span>
      ) : (
        <span className={cn(styles.diffNegative)}>{value}%</span>
      )}
      vs. {range} days ago
    </div>
  )
}
