import React, { useState } from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { HtmlExport } from 'react-email-editor'
import Input from '~/components/forms/Input/Input'
import color from '~/utils/color'
import { withStore } from '~/dataStore'
import { saveEmailTemplate, overwriteEmailTemplate } from '~/api/emailTemplates'
import {
  NotificationType,
  showNotification,
  clearNotifications
} from '~/utils/Notification'
import useAbortController from '~/hooks/useAbortController'
import { IEmailTemplate } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  onClose: () => void
  templateDesign: HtmlExport | null
  template: IEmailTemplate | null
  setTemplate: (template: IEmailTemplate) => void
  isBlankTemplate: boolean
  saveTemplateCallback: () => void
}

const SaveTemplateModal = ({
  onClose,
  templateDesign,
  template,
  setTemplate,
  isBlankTemplate,
  saveTemplateCallback
}: IProps) => {
  const { appId } = useParams()
  const [templateName, setTemplateName] = useState(template?.name)
  const [isSaving, setIsSaving] = useState(false)
  const [isOverwriting, setIsOverwriting] = useState(false)

  const abortController = useAbortController()

  const saveTemplate = async () => {
    clearNotifications(NotificationType.ERROR)
    setIsSaving(true)
    try {
      const newTemplate = await saveEmailTemplate(
        appId,
        {
          name: templateName,
          design: JSON.stringify(templateDesign?.design),
          html: templateDesign?.html
        },
        abortController.getAbortController('saveTemplate').signal
      )
      showNotification(
        'Your template has been saved, and will appear under "Saved Templates".',
        NotificationType.SUCCESS
      )

      setTemplate(newTemplate)
      if (saveTemplateCallback) saveTemplateCallback()
    } catch (exception) {
      if (!abortController.getAbortController('saveTemplate').signal.aborted) {
        showNotification(
          'There was a problem with saving template. Please try again later.',
          NotificationType.ERROR
        )
      }
    } finally {
      setIsSaving(false)
      onClose()
    }
  }

  const overwriteTemplate = async () => {
    clearNotifications(NotificationType.ERROR)
    setIsOverwriting(true)
    try {
      await overwriteEmailTemplate(appId, template?.id, {
        name: templateName,
        design: JSON.stringify(templateDesign?.design),
        html: templateDesign?.html,
        image: ''
      })
      showNotification(
        'The template has been successfully overwritten.',
        NotificationType.SUCCESS
      )
    } catch (exception) {
      showNotification(
        'There was a problem with overwriting template. Please try again later.',
        NotificationType.ERROR
      )
    } finally {
      setIsOverwriting(false)
      onClose()
    }
  }

  return (
    <>
      <ModalBody className="">
        <Input
          onChange={(e) => setTemplateName(e.target.value)}
          value={templateName}
          label="Template Name"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn--hover"
          color=""
          disabled={isSaving || isOverwriting}
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="btn--hover"
          color="body"
          onClick={overwriteTemplate}
          outline
          disabled={
            isBlankTemplate ||
            !template ||
            !templateName ||
            template.isDefault ||
            isOverwriting ||
            isSaving
          }>
          {isOverwriting && (
            <FontAwesomeIcon
              color={color.pulsateBlue}
              className="me-1"
              icon={faSpinner}
              spin
              size="xs"
            />
          )}
          {isOverwriting ? 'Overwriting...' : 'Overwrite'}
        </Button>
        <Button
          color="primary"
          className="btn--hover"
          onClick={saveTemplate}
          disabled={
            !templateDesign || !templateName || isSaving || isOverwriting
          }>
          {isSaving && (
            <FontAwesomeIcon className="me-1" icon={faSpinner} spin size="xs" />
          )}
          {isSaving ? 'Saving...' : 'Save New'}
        </Button>
      </ModalFooter>
    </>
  )
}

export default withStore(SaveTemplateModal)
