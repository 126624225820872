import React from 'react'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { Button } from 'reactstrap'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IMessage } from '~/pages/Messages/Messages.interface'
import defaultUserAvatar from '~/assets/img/default-user-avatar.svg'

interface IProps {
  message: IMessage
  queryParams: { [key: string]: string }
}
const Message = ({ message, queryParams }: IProps) => {
  return (
    <Button
      color=""
      onClick={() => {
        queryParams.id = message.id
      }}
      className="list-group-item list-group-item-action border-0 border-bottom p-3 d-flex align-items-center">
      {message.adminMessagesUnread > 0 && (
        <FontAwesomeIcon
          icon={faCircle}
          size="xs"
          className="me-3 text-primary"
        />
      )}
      <div className="w-100">
        {message.lastMessageAt ? (
          <div className="badge bg-water-gray float-end text-blue-gray">
            {moment(message.lastMessageAt).utc().format('DD MMM YYYY')}
          </div>
        ) : (
          <div className="badge float-end">
            <Skeleton width={100} height={15} baseColor="#f4f7fc" />
          </div>
        )}
        <div className="d-flex align-items-center">
          <img
            src={defaultUserAvatar}
            className="rounded-circle me-1"
            alt="Default user avatar"
            width="63"
            height="63"
          />
          <div className="flex-grow-1 ms-3">
            <p className="mb-0 text-base fw-medium">
              {message.userName || <Skeleton width={100} />}
            </p>
            <p className="my-1 text-sm text-muted">
              {message.subject || <Skeleton width={100} />}
            </p>
            <p className="mb-0 text-sm">
              {message.messageContent || <Skeleton />}
            </p>
          </div>
        </div>
      </div>
    </Button>
  )
}

export default Message
