import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap'
import cn from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  BE_ERRORS_MAP,
  ERRORS_DICT,
  CampaignGlobalSettingsFormValue
} from './GlobalSettings.interface'
import Input from '~/components/forms/Input'
import SeparatorLine from '~/components/SeparatorLine'
import blockNegativeVal from '~/utils/blockNegativeVal'
import TimeFrameForm from '~/components/forms/TimeFrameForm'
import Tooltip from '~/components/reports/Tooltip'
import ToggleSwitch from '~/components/forms/ToggleSwitch'
import { updateGlobalCampaignSettings } from '~/api/appSettings'
import { useStore, withStore } from '~/dataStore'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'

const GlobalSettingsSection = () => {
  const {
    app: {
      appDetails,
      currentApp,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setError,
    formState: { isSubmitting, errors }
  } = useForm<CampaignGlobalSettingsFormValue>({
    values: {
      time: {
        value: appDetails?.campaignGlobalSettings.timeValue?.toString() || '',
        frame: appDetails?.campaignGlobalSettings.timeFrame || 'minutes'
      },
      waitTime: {
        value: appDetails?.campaignGlobalSettings.waitValue?.toString() || '',
        frame: appDetails?.campaignGlobalSettings.waitFrame || 'minutes'
      },
      silentPush: appDetails?.campaignGlobalSettings.silentPush || false,
      sendValue: appDetails?.campaignGlobalSettings.sendValue?.toString() || ''
    }
  })

  const onSubmit = async (data: CampaignGlobalSettingsFormValue) => {
    const payload = {
      settingAttributes: {
        timeValue: data.time.value !== '' ? Number(data.time.value) : null,
        timeFrame: data.time.frame,
        waitValue:
          data.waitTime.value !== '' ? Number(data.waitTime.value) : null,
        waitFrame: data.waitTime.frame,
        silentPush: `${data.silentPush}`,
        sendValue: data.sendValue !== '' ? Number(data.sendValue) : null
      }
    }
    try {
      await updateGlobalCampaignSettings(currentApp.id, payload)
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
    } catch (err) {
      if (err.body?.errors) {
        getFormServerSideErrors(err.body.errors, ERRORS_DICT).forEach((er) =>
          setError(BE_ERRORS_MAP[er.name], {
            type: 'server',
            message: er.message
          })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className="mb-0 mt-2">
          Global Campaign Settings
        </CardTitle>
      </CardHeader>
      <CardBody>
        <form
          noValidate
          className="w-100 py-3"
          onSubmit={(e) => {
            clearErrors()
            handleSubmit(onSubmit)(e)
          }}>
          <Row>
            <Col xs={12}>
              <div className="d-flex align-items-center">
                <p className="mb-0">Do not send more than</p>
                <Input
                  register={register}
                  type="number"
                  name="sendValue"
                  className={cn('mx-3 mb-0 form-control-number', {
                    'form-group--error': errors.sendValue
                  })}
                  onKeyDown={blockNegativeVal}
                  errorTooltip={errors.sendValue?.message}
                  step="1"
                  min="1"
                />
                <Controller
                  control={control}
                  defaultValue={{ frame: 'minutes', value: '' }}
                  name="time"
                  register={register}
                  render={({ field: { onChange, value } }) => (
                    <TimeFrameForm
                      onTimeValueChange={(e) =>
                        onChange({ ...value, value: e.target.value })
                      }
                      onTimeFrameChange={(timeUnit) =>
                        onChange({ ...value, frame: timeUnit })
                      }
                      initialTimeFrame={value.frame || 'minutes'}
                      initialTimeValue={value.value}
                      timeLabel="campaigns to a user every "
                      inputType="number"
                      timeValueInvalid={!!errors.time?.value}
                      timeValueError={errors.time?.value?.message}
                      timeFrameInvalid={!!errors.time?.frame}
                      timeFrameError={errors.time?.frame?.message}
                    />
                  )}
                />
                <Tooltip tooltip="Sending Limit" />
              </div>
              <SeparatorLine />
              <div className="d-flex align-items-center">
                <Controller
                  control={control}
                  name="waitTime"
                  defaultValue={{ frame: 'minutes', value: '' }}
                  register={register}
                  render={({ field: { onChange, value } }) => (
                    <TimeFrameForm
                      onTimeValueChange={(e) =>
                        onChange({ ...value, value: e.target.value })
                      }
                      onTimeFrameChange={(timeUnit) =>
                        onChange({ ...value, frame: timeUnit })
                      }
                      initialTimeFrame={value.frame || 'minutes'}
                      initialTimeValue={value.value}
                      timeLabel="Wait"
                      unitLabel="between sending campaigns"
                      inputType="number"
                      timeValueInvalid={!!errors.waitTime?.value}
                      timeValueError={errors.waitTime?.value?.message}
                      timeFrameInvalid={!!errors.waitTime?.frame}
                      timeFrameError={errors.waitTime?.frame?.message}
                    />
                  )}
                />
                <Tooltip tooltip="Sending Interval" />
              </div>
              <SeparatorLine />
              <div className="d-flex align-items-center mb-1">
                <Controller
                  control={control}
                  name="silentPush"
                  register={register}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <ToggleSwitch
                        checked={value || false}
                        onChange={(value) => onChange(value)}
                        controlId="silent-push-switch"
                      />
                      <label htmlFor="silent-push-switch" className="mb-0 ms-3">
                        activate silent campaign after global limit is reached
                      </label>
                    </>
                  )}
                />
                <Tooltip tooltip="Send Campaigns directly to user's inbox" />
              </div>
              <Button
                disabled={isSubmitting || isDemoAdmin}
                color="primary"
                type="submit"
                className="mt-4">
                Save Changes
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-2"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  )
}

export default withStore(GlobalSettingsSection)
