import React from 'react'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal/Modal'
import { ID } from '~/common.interface'

const RemoveFromNameModal = ({
  removeFromName
}: {
  removeFromName: (id: ID, closeModal: () => void) => void
}): React.ReactElement => {
  return (
    <Modal
      id="confirmFromNameDeletion"
      className=""
      renderHeader={() => 'Delete From Name'}
      render={(close: () => void, params) => (
        <ConfirmationModal
          onSubmit={() => {
            removeFromName(params?.id, close)
          }}
          onClose={close}
          okText="Delete"
          cancelText="Cancel"
          body="Are you sure you want to delete this from name?"
        />
      )}
    />
  )
}

export default RemoveFromNameModal
