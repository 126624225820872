import React, { useState } from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  Row,
  Col,
  Card
} from 'reactstrap'
import styles from './EngagementSummaryCarousel.scss'
import {
  gettingStartedImage,
  engageLikeProImage,
  yourNextMoveImage,
  oppYouCantMissImage,
  performanceImage,
  levelUpImage,
  exploreImage
} from './slides'

const items = [
  {
    src: gettingStartedImage,
    header: 'Getting Started with Your Opps Engine',
    getSlideContent: () => (
      <>
        <p>
          Explore best practices to drive engagement and conversion within your
          campaigns.
        </p>
        <p className="mb-0">
          <strong>Learn more</strong>{' '}
          <a
            href="https://dash.readme.com/project/pulsate/v4.1/refs/get-started-with-opportunities-engine"
            target="blank"
            className="text-secondary">
            <strong>here</strong>
          </a>
        </p>
      </>
    )
  },
  {
    src: engageLikeProImage,
    header: 'Engage Like a Pro',
    getSlideContent: () => (
      <>
        <p>
          Track who’s interacting like never before! Users, impressions, and
          (CTR) all in one place.
        </p>
        <p className="mb-0">
          <strong>Why It Matters</strong>: Knowing who's loving your content
          (and who’s not) makes all the difference.
        </p>
      </>
    )
  },
  {
    src: yourNextMoveImage,
    header: 'Your Next Move',
    getSlideContent: () => (
      <>
        <p>
          We’ve crunched the numbers to deliver the best opportunity for
          conversion. Spot those for follow-ups.
        </p>
        <p className="mb-0">
          <strong>Why It Matters</strong>: Retarget your audience and boost your
          campaign’s success.
        </p>
      </>
    )
  },
  {
    src: oppYouCantMissImage,
    header: 'Opportunities You Can’t Miss',
    getSlideContent: () => (
      <>
        <p>
          See what's hot and what’s missed in a full list of opportunities.
          Because every click counts.
        </p>
        <p className="mb-0">
          <strong>Why It Matters</strong>: Don’t let a potential customer slip
          through.
        </p>
      </>
    )
  },
  {
    src: performanceImage,
    header: 'Performance Power-Up',
    getSlideContent: () => (
      <>
        <p>
          Track CTR, impressions, and goals to see what’s working and what’s
          not.
        </p>
        <p className="mb-0">
          <strong>Why It Matters</strong>: Use insights to maximise your ROI and
          boost your strategy.
        </p>
      </>
    )
  },
  {
    src: levelUpImage,
    header: 'Level Up Your Game',
    getSlideContent: () => (
      <>
        <p>
          Master the Opportunities Engine with tips and best practices to boost
          your campaigns.
        </p>
        <p className="mb-0">
          <strong>Why It Matters</strong>: Become a marketing rockstar and hit
          your goals.
        </p>
      </>
    )
  },
  {
    src: exploreImage,
    header: 'Explore Segments & Campaigns',
    getSlideContent: () => (
      <>
        <p>Explore tailored segments and campaigns for maximum impact.</p>
        <p className="mb-0">
          <strong>Why It Matters</strong>: Custom targeting increases engagement
          and drives results.
        </p>
      </>
    )
  }
]

function EngagementSummaryCarousel() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  return (
    <Card className="engagement-summary-carousel px-4 d-flex align-items-center">
      <Carousel
        className=""
        activeIndex={activeIndex}
        next={next}
        previous={previous}>
        {items.map((item) => {
          return (
            <CarouselItem
              key={item.src}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}>
              <Row className="align-items-center">
                <Col xs="3" className="text-center">
                  <img
                    src={item.src}
                    alt={item.header}
                    className="carousel__image rounded-circle"
                  />
                </Col>
                <Col
                  xs="9"
                  className="d-flex flex-column justify-content-center">
                  <h3>{item.header}</h3>
                  <div className="text-18 line lh-lg">
                    {item.getSlideContent()}
                  </div>
                </Col>
              </Row>
            </CarouselItem>
          )
        })}
        <CarouselControl
          cssModule={styles.carouselControlPrev}
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </Card>
  )
}

export default EngagementSummaryCarousel
