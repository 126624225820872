import React, { useState, useCallback } from 'react'
import cn from 'classnames'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import Link from '~/components/Link'

import './IconButton.scss'

interface IProps {
  onClick?: (setSpinner: (value: boolean) => void) => void
  icon: any
  disabled?: boolean
  url?: any
  params?: Record<string, any>
  className?: string
  id: string
  dataTestId?: string
  size?: SizeProp
  ariaLabel?: string
}

const IconButton = ({
  url,
  onClick,
  icon,
  disabled,
  className,
  id,
  params,
  dataTestId,
  size,
  ariaLabel
}: IProps): React.ReactElement => {
  const [spinner, setSpinner] = useState(false)
  const isDisabled = disabled || !(url || onClick) || spinner

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      onClick?.(setSpinner)
    },
    [onClick]
  )

  return (
    <Button
      data-testid={dataTestId}
      tag={Link}
      route={url}
      params={params}
      onClick={onClick ? handleClick : undefined}
      color="white"
      id={id}
      className={cn('text-body px-0 py-0 icon-button', className)}
      disabled={isDisabled}
      aria-label={ariaLabel || 'Icon Button'}>
      <FontAwesomeIcon
        size={size}
        fixedWidth
        icon={spinner ? faSpinner : icon}
        className={cn({
          'text-gray': isDisabled,
          'fa-spin': spinner
        })}
      />
    </Button>
  )
}

export default IconButton
