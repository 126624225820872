import { Handle, Position } from 'reactflow'
import cn from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import { uniqueId } from 'lodash'
import { useRef } from 'react'
import NodeIcon from './NodeIcon'
import {
  JourneyBlockType,
  NodeType,
  NodeWithData
} from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'

export default function AddToSegment({
  id,
  data
}: NodeWithData): React.ReactElement {
  const tooltipId = useRef(uniqueId('add-to-segment'))
  return (
    <div
      id={id}
      className={cn('flow-node flow-node-segment', {
        'flow-node--filled': data.filled,
        'flow-node--error': !data.block.isValid
      })}>
      <div className="flow-node__icon-wrapper">
        <NodeIcon
          type={NodeType.ADDTOSEGMENT}
          blockType={JourneyBlockType.ADDTOSEGMENT}
        />
      </div>
      <div className="flow-node__hover">
        <div className="flow-node__wrapper">
          <Handle
            type="target"
            position={Position.Top}
            className="custom-handle"
          />
          <Handle
            type="target"
            position={Position.Left}
            className="custom-handle"
            id="left"
          />
          <Handle
            type="target"
            position={Position.Right}
            className="custom-handle"
            id="right"
          />
          <p
            className="flow-node__label truncate truncate--two-lines"
            id={tooltipId.current}>
            {data.label}
          </p>
          <UncontrolledTooltip
            fade={false}
            delay={300}
            placement="top"
            target={tooltipId.current}>
            {data.label}
          </UncontrolledTooltip>
          <Handle
            type="source"
            position={Position.Bottom}
            className="custom-handle"
          />
        </div>
      </div>
    </div>
  )
}
