import cn from 'classnames'
import PreviewDesktop from '~/pages/Campaign/Notification/Preview/PreviewDesktop'
import {
  IEmailTemplate,
  IRegisteredField
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import Loading from './Loading'
import { withStore, useStore } from '~/dataStore'
import blankTemplate from '~/pages/CampaignBuilder/Email/blankEmailTemplate.json'
import Template from './Template'
import Modal from '~/components/modals/Modal/Modal'

import { gridContainer } from './TemplatesList.scss'

interface IProps {
  currentTemplate: IRegisteredField<IEmailTemplate | null>
  deleteTemplate?: (templateId: string) => void
  hasBlankTemplate?: boolean
  isChanged: boolean | null
  isLayout?: boolean
  loading?: { loading: boolean; firstRun: boolean }
  overwriteTemplate?: (templateId: IEmailTemplate) => void
  setTemplateData: (template: IEmailTemplate) => void
  templates: IEmailTemplate[]
  templatesSelectionDisabled: boolean
}

const TemplatesList = ({
  templates,
  hasBlankTemplate,
  isLayout,
  loading,
  deleteTemplate,
  overwriteTemplate,
  setTemplateData,
  currentTemplate,
  isChanged,
  templatesSelectionDisabled
}: IProps) => {
  const {
    ui: { showModal }
  } = useStore()

  const onTemplateSelect = (template: IEmailTemplate) => {
    if (template.id !== currentTemplate.value?.id) {
      if (isChanged === false) {
        overwriteTemplate?.(template)
        return
      }
      setTemplateData(template)
    }
  }

  return (
    <>
      <div className={cn(gridContainer)}>
        {loading?.firstRun && <Loading />}
        {hasBlankTemplate && !loading?.firstRun && (
          <Template
            isBlank
            template={blankTemplate}
            isActive={currentTemplate.value?.id === blankTemplate?.id}
            onSelect={onTemplateSelect}
          />
        )}
        {templates.map((template) => (
          <Template
            disabled={templatesSelectionDisabled}
            isLoading={loading?.loading}
            deleteTemplate={() => deleteTemplate?.(template.id)}
            key={template.id}
            template={template}
            isLayout={isLayout}
            isActive={currentTemplate.value?.id === template?.id}
            onSelect={onTemplateSelect}
            showPreview={(t: IEmailTemplate) =>
              showModal('previewTemplateModal', { template: t })
            }
          />
        ))}
      </div>
      <Modal
        id="previewTemplateModal"
        className="template-preview-modal"
        render={(close: () => void, params: { template: IEmailTemplate }) => (
          <PreviewDesktop size="xxl" srcDoc={params?.template?.html}>
            <img
              className="mw-100 d-block mx-auto"
              src={params?.template?.imageUrl}
              alt="Template preview"
            />
          </PreviewDesktop>
        )}
      />
    </>
  )
}

export default withStore(TemplatesList)
