import { uniqueId } from 'lodash'
import React from 'react'
import cn from 'classnames'

interface IProps {
  buttons: Array<{ label: string; txtColor: string; btnColor: string }>
  onClick?: () => void
}

const CallToActionButtons = ({
  buttons,
  onClick
}: IProps): React.ReactElement | null => {
  if (!buttons.filter(Boolean).length) {
    return null
  }

  return (
    <div
      className={cn('cta-btns d-flex', { 'cursor-pointer': !!onClick })}
      onClick={onClick}>
      {buttons.map((btn) => (
        <div
          key={uniqueId()}
          className="cta-btns__item"
          style={{
            ...(btn.btnColor && { backgroundColor: btn.btnColor, border: 0 }),
            ...(btn.txtColor && { color: btn.txtColor })
          }}>
          {btn.label}
        </div>
      ))}
    </div>
  )
}

export default CallToActionButtons
