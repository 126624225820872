import { uniqueId } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { ID } from '~/common.interface'
import { GoalSide } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import { hasInvalidBuilderCharacters, isValidURL } from '~/utils/validations'
import { ActionTypes, CTAButton } from '../../Campaign/Campaign.interface'
import { IOption, ValidationTypes } from '../EmailBuilder.interface'
import NewRegisteredField from '../RegisteredField.model'
import { isValidHex } from '~/components/ColorPicker/helpers'

export default class CTAField {
  buttonID: ID = uniqueId('button')

  side: GoalSide = 'front'

  label = new NewRegisteredField<string>('')

  txtColor = new NewRegisteredField<string>('')

  btnColor = new NewRegisteredField<string>('')

  destinationType = new NewRegisteredField<ActionTypes | undefined>(undefined)

  destinationUrl = new NewRegisteredField<string>('https://')

  destination = new NewRegisteredField<string>('')

  isInAppEventsActive = false

  inAppEvents = new NewRegisteredField<IOption[] | null>(null)

  withLabel = true

  errorMessagePrefix: string | undefined

  constructor(prefix?: string) {
    makeAutoObservable(this, undefined, { autoBind: true })

    this.errorMessagePrefix = prefix ? `${prefix} ` : ''
  }

  public setValue(value: string): void {
    this.label.value = value
  }

  public get value(): string {
    return this.label.value
  }

  public setTextColor(value: string): void {
    this.txtColor.value = value
  }

  public setBtnColor(value: string): void {
    this.btnColor.value = value
  }

  public setType(value: ActionTypes): void {
    this.destinationType.value = value
  }

  public setUrl(value: string): void {
    this.destinationUrl.value = value
  }

  public setDestination(value: string): void {
    this.destination.value = value
  }

  public setInAppEventsActive(value: boolean): void {
    this.isInAppEventsActive = value
  }

  public setEvents(value: IOption[]): void {
    this.inAppEvents.setValue(value?.length ? value : null)
  }

  public get isValid(): boolean {
    let isValid = true

    if (this.withLabel && !this.label.isValid) {
      isValid = false
    }
    if (!this.destinationType.isValid) {
      isValid = false
    }

    if (this.isUrl && !this.destinationUrl.isValid) {
      isValid = false
    }

    if (this.isDeeplink && !this.destination.isValid) {
      isValid = false
    }

    return isValid
  }

  public get isDeeplink(): boolean {
    return this.destinationType.value === ActionTypes.DEEPLINK
  }

  public get isUrl(): boolean {
    return this.destinationType.value === ActionTypes.URL
  }

  public get isCardBack(): boolean {
    return this.destinationType.value === ActionTypes.CARD_BACK
  }

  public fillField(button: CTAButton | undefined, side?: GoalSide): void {
    if (!button) {
      return
    }
    this.label.fillField({ value: button.label })

    this.destinationType.fillField({ value: button.destinationType })

    this.btnColor.fillField({ value: button.btnColor })

    this.txtColor.fillField({ value: button.txtColor })

    if (this.isUrl) {
      this.destinationUrl.fillField({
        value: button.destination
      })
    }

    if (this.isDeeplink) {
      this.destination.fillField({ value: button.destination })
    }

    if (button.inAppEvents) {
      this.isInAppEventsActive = true
      // this.inAppEvents.setValue(createSelectOptions(button.inAppEvents))
      this.inAppEvents.setValue(
        button.inAppEvents.map((event) => ({
          value: event ?? '',
          label: event ?? ''
        }))
      )
    }

    if (side) {
      this.side = side
    }
  }

  public validateBackgroundColor(): boolean {
    this.btnColor.resetError()
    if (this.btnColor.value && !isValidHex(this.btnColor.value)) {
      this.btnColor.setError(
        ValidationTypes.REQUIRED,
        `${this.errorMessagePrefix}Please set correct button background color HEX value`
      )

      return false
    }

    return true
  }

  public validateTextColor(): boolean {
    this.txtColor.resetError()
    if (this.txtColor.value && !isValidHex(this.txtColor.value)) {
      this.txtColor.setError(
        ValidationTypes.REQUIRED,
        `${this.errorMessagePrefix}Please set correct button text color HEX value`
      )

      return false
    }

    return true
  }

  public validateLabel(): boolean {
    this.label.resetError()
    if (!this.withLabel) {
      return true
    }

    return this.label.validate(
      `${this.errorMessagePrefix}You must set a Call to Action`
    )
  }

  public validateLabelInvalidChars(): boolean {
    if (!this.withLabel) {
      return true
    }

    if (this.label.isValid && hasInvalidBuilderCharacters(this.label.value)) {
      this.label.setError(
        ValidationTypes.FORMAT,
        `Call to Action: The following characters are not allowed: '<', '>', '[', ']'.`
      )
    }

    return this.label.isValid
  }

  public validateType(): boolean {
    return this.destinationType.validate(
      `${this.errorMessagePrefix}Select the button destination`
    )
  }

  public validateUrl(): boolean {
    this.destinationUrl.resetError()
    if (this.isUrl) {
      if (
        !this.destinationUrl.validate(
          `${this.errorMessagePrefix}Provide an URL`
        )
      ) {
        return false
      }

      if (!isValidURL(this.destinationUrl.value)) {
        this.destinationUrl.isValid = false
        this.destinationUrl.errors.push({
          type: ValidationTypes.FORMAT,
          message: `${this.errorMessagePrefix}Provide a valid URL`
        })

        return false
      }
    }

    return true
  }

  public validateDeeplink(): boolean {
    this.destination.resetError()
    if (this.isDeeplink) {
      return this.destination.validate(
        `${this.errorMessagePrefix}Select a Deeplink`
      )
    }

    return true
  }

  public validateInappEvents(): boolean {
    this.inAppEvents.resetError()
    if (this.isInAppEventsActive) {
      return this.inAppEvents.validate(
        `${this.errorMessagePrefix}You must choose an Event to record or switch it off`
      )
    }

    return true
  }

  public resetError(): void {
    this.label.resetError()
    this.destinationType.resetError()
    this.destinationUrl.resetError()
    this.destination.resetError()
  }

  public validate(): boolean {
    return [
      this.validateLabel(),
      this.validateLabelInvalidChars(),
      this.validateType(),
      this.validateDeeplink(),
      this.validateUrl(),
      this.validateInappEvents(),
      this.validateBackgroundColor(),
      this.validateTextColor()
    ].every(Boolean)
  }

  private getInAppEvents(): { inAppEvents: string[] | null } | {} {
    let inAppEvents = {}
    if (this.isInAppEventsActive) {
      inAppEvents = { inAppEvents: null }

      if (this.inAppEvents.value?.length) {
        inAppEvents = {
          inAppEvents: this.inAppEvents.value.map((event) => event.value)
        }
      }
    }

    return inAppEvents
  }

  public get payload(): CTAButton {
    let destination

    if (this.isUrl) {
      destination = this.destinationUrl.value
    } else if (this.isDeeplink) {
      destination = this.destination.value
    }

    const inAppEvents = this.getInAppEvents()

    if (this.withLabel) {
      return {
        label: this.value,
        destinationType: this.destinationType.value,
        destination,
        ...inAppEvents,
        txtColor: this.txtColor.value,
        btnColor: this.btnColor.value
      }
    }
    return {
      destinationType: this.destinationType.value,
      destination,
      txtColor: this.txtColor.value,
      btnColor: this.btnColor.value,
      ...inAppEvents
    }
  }
}
