import { Card } from 'reactstrap'
import { withStore } from '~/dataStore'
import CampaignNumbers from '~/pages/Campaign/CampaignReports/UI/CampaignNumbers'
import JourneyReportStore from '../../Store/JourneyReportStore'
import GoalsPerformance from '../GoalsPerformance/GoalsPerformance'
import JourneyOverview from './JourneyOverview'
import statsIcon from './stats_icon.svg'
import NoticeBarSecondary from '~/components/common/NoticeBarSecondary/NoticeBarSecondary'
import NoticeBarSecondaryHeader from '~/components/common/NoticeBarSecondary/components/NoticeBarSecondaryHeader'
import LearnMoreButton from '~/components/common/NoticeBarSecondary/components/LearnMoreButton'

interface IProps {
  journey: JourneyReportStore
}

function JourneyOverviewReport({ journey }: IProps): React.ReactElement {
  return (
    <>
      <JourneyOverview summary={journey.summary} report={journey.report} />
      <CampaignNumbers numbers={journey.numbers} />
      <GoalsPerformance goals={journey.goals} externalGoal={journey.external} />
      <NoticeBarSecondary>
        <NoticeBarSecondaryHeader
          icon={statsIcon}
          header="Learn more about statistics in Journeys"
          subHeader='By clicking "Learn more" you can check the exact formulas for each
              data we show'
        />
        <LearnMoreButton href="https://docs.pulsatehq.com/reference/journeys-statistics" />
      </NoticeBarSecondary>
    </>
  )
}

export default withStore(JourneyOverviewReport)
