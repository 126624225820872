import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import React from 'react'
import { IBeaconsStatus, BATTERY_STATUS } from '../Beacons.interface'
import BatteryStatusDot from './BatteryStatusDot'
import colors from '~/utils/color'

const BeaconsStatus = ({
  statuses,
  isLoading
}: {
  statuses: IBeaconsStatus | undefined
  isLoading: boolean
}): React.ReactElement => {
  return (
    <div className="border rounded p-3 d-flex align-items-center">
      <div className="d-flex align-items-center">
        {!isLoading ? (
          <>
            <div className="me-2">
              <BatteryStatusDot batteryStatus={BATTERY_STATUS.ACTIVE} slim />
            </div>
            <span className="">{statuses?.active} are Active</span>
          </>
        ) : (
          <>
            <SkeletonTheme baseColor={colors.info}>
              <Skeleton width={16} height={16} circle className="me-2" />
            </SkeletonTheme>
            <Skeleton width={100} height={15} />
          </>
        )}
      </div>
      <div className="beacons__status-divider ms-3" />
      <div className="d-flex align-items-center ms-3">
        {!isLoading ? (
          <>
            <div className="me-2">
              <BatteryStatusDot batteryStatus={BATTERY_STATUS.CHECK} slim />
            </div>
            <span className="">{statuses?.check} need to be Checked</span>
          </>
        ) : (
          <>
            <SkeletonTheme baseColor={colors.warning}>
              <Skeleton width={16} height={16} circle className="me-2" />
            </SkeletonTheme>
            <Skeleton width={155} height={15} />
          </>
        )}
      </div>
      <div className="beacons__status-divider ms-3" />
      <div className="d-flex align-items-center ms-3">
        {!isLoading ? (
          <>
            <div className="me-2">
              <BatteryStatusDot batteryStatus={BATTERY_STATUS.INACTIVE} slim />
            </div>
            <span className="">{statuses?.inactive} are Inactive</span>
          </>
        ) : (
          <>
            <SkeletonTheme baseColor={colors.danger}>
              <Skeleton width={16} height={16} circle className="me-2" />
            </SkeletonTheme>
            <Skeleton width={100} height={15} />
          </>
        )}
      </div>
    </div>
  )
}

export default BeaconsStatus
