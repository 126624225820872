import React from 'react'
import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'
import { ChartOptions } from 'chart.js'

import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import Tooltip from '~/components/reports/Tooltip'

interface IProps {
  title: string
  horizontal?: boolean
  dataSets: any[]
  tooltip?: string
  style?: Record<string, any>
}

const BarChart = ({
  title,
  horizontal,
  dataSets,
  style,
  tooltip
}: IProps): React.ReactElement => {
  const data = {
    labels: dataSets[0]?.data?.map(({ x }) => x),
    datasets: dataSets?.map(({ label, data: da, color, thickness }) => ({
      label,
      data: da.map((d) => d.y),
      backgroundColor: color,
      borderColor: color,
      hoverBackgroundColor: color,
      hoverBorderColor: color,
      barPercentage: thickness || 0.75,
      categoryPercentage: 0.75
    }))
  }

  const options: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      }
    },
    indexAxis: horizontal ? 'y' : 'x',
    scales: {
      y: {
        grid: {
          display: false
        },
        stacked: false,
        min: 0,
        beginAtZero: true,
        ticks: {
          stepSize: 20
        }
      },
      x: {
        stacked: false,
        grid: {
          color: 'transparent'
        },
        min: 0,
        beginAtZero: true
      }
    }
  }

  return (
    <Card className="flex-fill w-100" style={style}>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h5" className="mb-0" style={{ flexGrow: 1 }}>
          {title}
        </CardTitle>
        {tooltip && <Tooltip tooltip={tooltip} />}
      </CardHeader>
      <CardBody className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Bar data={data} options={options} />
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default BarChart
