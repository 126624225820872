import React from 'react'
import { Row } from 'reactstrap'
import NumberStat from '~/components/reports/NumberStat'
import ApexAreaChart from '~/components/ApexCharts/ApexAreaChart'
import { ISummaryRate } from '~/dataStore/reports/Report.interface'
import { getTooltipLabelAsDateRange } from '~/utils/apexChart'

const CampaignSummaryRates = ({
  summaryRates
}: {
  summaryRates: ISummaryRate[]
}): React.ReactElement => (
  <Row>
    {summaryRates.map((rate: ISummaryRate) => (
      <NumberStat
        key={rate.label}
        col={{ md: 6, xxl: '3', widths: ['md', 'xxl'] }}
        value={rate.rate}
        title={rate.label}
        tooltip={rate.tooltip}>
        {rate.chartData && (
          <ApexAreaChart
            height={90}
            tooltipCustomLabel={getTooltipLabelAsDateRange}
            graphData={rate.chartData}
          />
        )}
      </NumberStat>
    ))}
  </Row>
)

export default CampaignSummaryRates
