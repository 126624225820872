import cn from 'classnames'
import { Button } from 'reactstrap'
import styles from './PulseBanner.scss'
import Banner from './Banner.png'
import ArrowDown from './arrow_down.svg'

export default function PulseBanner(): React.ReactElement | null {
  return (
    <div className={cn(styles.banner)}>
      <img src={Banner} alt="On The Pulse" className={cn(styles.bannerImg)} />
      <div className={cn(styles.bannerContent)}>
        <div className={cn(styles.bannerTextBox)}>
          <p className={cn(styles.bannerText)}>
            Engagement Inspiration from Pulsate
          </p>
        </div>
        <div
          className={cn(
            'd-flex justify-content-center align-items-center py-4'
          )}>
          <Button
            tag="a"
            color="body"
            target="_blank"
            className={cn(
              styles.bannerBtn,
              'd-flex justify-content-center align-items-center'
            )}
            href="https://info.pulsatehq.com/on-the-pulse-2-24">
            Download Your Guide Now
            <img src={ArrowDown} alt="arrow" className="ms-2 icon icon--med" />
          </Button>
        </div>
      </div>
    </div>
  )
}
