import {
  useInfiniteQuery,
  useQueryClient,
  useMutation
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { ID } from '~/common.interface'
import { fetchBeacons, editBeacon, addBeacon } from '~/api/beacons'
import {
  IBeaconsQueryParams,
  ERRORS_DICT,
  IBeaconsDTO,
  IBeaconPayload
} from './Beacons.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'

export enum ServerStateKeysEnum {
  Beacons = 'beacons'
}

export const useInvalidateBeacons = () => {
  const cache = useQueryClient()
  return () => cache.invalidateQueries([ServerStateKeysEnum.Beacons])
}

export const useEditBeacon = (close: () => void, setError: unknown) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (data: { data: IBeaconPayload; id: ID }) =>
      editBeacon(appId, data.id, data.data),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.Beacons])
        showNotification('Successfully edited beacon', NotificationType.SUCCESS)
        close()
      },
      onError: (errors) => {
        if (errors.body.errors) {
          getFormServerSideErrors(
            errors.body.errors,
            ERRORS_DICT
          ).forEach((er) =>
            setError(er.name, { type: 'server', message: er.message })
          )
        } else {
          showGeneralError()
        }
      }
    }
  )
}

export const useAddBeacon = (
  hideModal: (id: string) => void,
  setError: unknown
) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation((beacon: IBeaconPayload) => addBeacon(appId, beacon), {
    onSuccess: () => {
      cache.invalidateQueries([ServerStateKeysEnum.Beacons])
      showNotification('Successfully added beacon', NotificationType.SUCCESS)
      hideModal('editAddBeaconModal')
      hideModal('batchUploadModal')
    },
    onError: (errors) => {
      if (errors.body.errors) {
        getFormServerSideErrors(errors.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  })
}

const useBeacons = (
  appId: ID,
  queryParams: IBeaconsQueryParams,
  enabled = true
) => {
  return useInfiniteQuery(
    [
      ServerStateKeysEnum.Beacons,
      {
        per_page: 50,
        ...queryParams
      }
    ],
    ({ pageParam = 1 }) =>
      fetchBeacons(appId, {
        perPage: 50,
        ...queryParams,
        page: pageParam
      }),
    {
      select: (data: {
        pages: Array<IBeaconsDTO>
        pageParams: Array<number | undefined>
      }) => ({
        pages: data.pages
          .flatMap((page) => page.data)
          .map((beacon) => ({
            ...beacon,
            batchActionUrls: data.pages[0].bulkActions
          })),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata,
        bulkActions: data.pages[0].bulkActions
      }),
      enabled,
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export default useBeacons
