import { HtmlExport } from 'react-email-editor'
import UnlayerEditor from '~/pages/Campaign/Notification/EmailNotification/UI/UnlayerEditor'
import {
  IEmailEditorData,
  IEmailTemplate
} from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  mergeTags: string[]
  designChangedCallback: (data: HtmlExport) => void
  setEditorData: (data: HtmlExport) => void
  currentTemplate: IEmailTemplate | null
  setCurrentTemplate: (template: IEmailTemplate) => void
  isCurrentTemplateBlank: boolean
  editorData: HtmlExport | null
  setEditorReady: (state: boolean) => void
  getEditorReady: () => boolean
  saveTemplateCallback?: () => void
  testEmailData: {
    subject: string
    fromName: string
    designData?: IEmailEditorData
  }
  setBeforeExitStep?: (beforeExitAction: () => void) => void
}

export default function EmailNotificationBuilder(
  props: IProps
): React.ReactElement {
  const {
    mergeTags,
    designChangedCallback,
    testEmailData,
    setEditorData,
    currentTemplate,
    setCurrentTemplate,
    isCurrentTemplateBlank,
    editorData,
    setEditorReady,
    getEditorReady,
    saveTemplateCallback,
    setBeforeExitStep
  } = props
  return (
    <UnlayerEditor
      mergeTags={mergeTags}
      designChangedCallback={designChangedCallback}
      currentTemplate={currentTemplate}
      setCurrentTemplate={setCurrentTemplate}
      isBlankTemplate={isCurrentTemplateBlank}
      editorData={{
        data: editorData,
        setData: setEditorData,
        isReady: getEditorReady,
        setReady: setEditorReady
      }}
      testEmailData={testEmailData}
      saveTemplateCallback={saveTemplateCallback}
      setBeforeExitStep={setBeforeExitStep}
    />
  )
}
