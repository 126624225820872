import React from 'react'
import { uniqueId } from 'lodash'
import cn from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { IColumn } from '~/components/Table/Table.interface'

interface IProps {
  className?: string
  count?: number
  height?: number
  withSelectCheckbox?: boolean
  tableColumns: IColumn<string>[]
}

const TableSkeletonPlaceholder = ({
  tableColumns,
  className,
  count = 6,
  height = 20,
  withSelectCheckbox
}: IProps): React.ReactElement => {
  return (
    <>
      {Array.from({ length: count }, () => ({
        id: uniqueId()
      })).map((skeletonRow) => (
        <tr key={skeletonRow.id}>
          {withSelectCheckbox && (
            <td className="table__col px-3">
              <Skeleton
                width={23}
                height={23}
                inline
                className={cn('react-loading-skeleton--select', className)}
              />
            </td>
          )}
          {tableColumns.map((val, index) => (
            <td
              key={tableColumns[index].displayName}
              className="table__col px-1">
              <Skeleton height={height} className={className} inline />
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

export default TableSkeletonPlaceholder
