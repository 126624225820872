import { ReactElement } from 'react'
import { Col, Row } from 'reactstrap'
import AllMobileDevicesPreviews from '~/pages/Campaign/Notification/Preview/AllMobileDevicesPreview'
import { useStore, withStore } from '~/dataStore'
import {
  ActionTypes,
  CampaignType
} from '~/dataStore/Campaign/Campaign.interface'
import MobileCampaignPanel from '../../../../CampaignBuilder/MobileCampaignPanel/MobileCampaignPanel'
import { CollapseContextProvider } from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import InAppLarge from '~/pages/Campaign/Notification/InAppNotification/UI/InAppLarge'
import CardNotification from '../Model/CardNotification'
import InAppLargeTwoBtns from '../../InAppNotification/UI/InAppLargeTwoBtns'

interface IProps {
  toggleCardBackStore: (destinationType: ActionTypes | undefined) => void
  card: CardNotification
  isSended: boolean
  onlyCarousel?: boolean
  withEvent?: boolean
  withGoals?: boolean
  colsWidth?: {
    left: { xxl: { size: number } }
    right: { xxl: { size: number } }
  }
  adminHeaderEditable: boolean
}

function CardFrontStep(props: IProps): ReactElement {
  const {
    app: { appDetails }
  } = useStore()

  const {
    toggleCardBackStore,
    card,
    isSended,
    onlyCarousel,
    colsWidth,
    withEvent = true,
    withGoals,
    adminHeaderEditable
  } = props

  const fieldsDictionary = {
    admin_header_with_message: 'adminHeader',
    image: 'image',
    headline: 'headline',
    text: 'text',
    button: 'actionButtons'
  }

  return (
    <CollapseContextProvider fieldMapper={fieldsDictionary}>
      <Row className="align-items-start">
        <Col
          widths={['md', 'xxl']}
          md={{ size: 7 }}
          {...colsWidth?.left}
          className="mb-4">
          <AllMobileDevicesPreviews
            parts={card.generatePreview().card.front}
            campaignType={CampaignType.CARD_FRONT}
            onlyCarousel={onlyCarousel}
          />
        </Col>
        <Col widths={['md', 'xxl']} md={{ size: 5 }} {...colsWidth?.right}>
          <MobileCampaignPanel>
            {appDetails.featureFlags.notificationsExtraButtons ? (
              <InAppLargeTwoBtns
                notification={card.cardFront}
                shared={card.shared}
                isSended={isSended}
                withEvent={withEvent}
                withGoals={withGoals}
                notificationGoals={card.goals}
                adminHeaderEditable={adminHeaderEditable}
                onDestinationTypeChange={(ctaAction: ActionTypes) => {
                  if (
                    ctaAction === ActionTypes.CARD_BACK ||
                    card.cardFront.actionButtons.buttons.some(
                      (btn) => btn.isCardBack
                    )
                  ) {
                    toggleCardBackStore(ActionTypes.CARD_BACK)
                  } else {
                    toggleCardBackStore(ctaAction)
                  }
                }}
                ctaActions={[
                  ActionTypes.CARD_BACK,
                  ActionTypes.DEEPLINK,
                  ActionTypes.URL
                ]}
              />
            ) : (
              <InAppLarge
                notification={card.cardFront}
                shared={card.shared}
                isSended={isSended}
                withEvent={withEvent}
                withGoals={withGoals}
                notificationGoals={card.goals}
                adminHeaderEditable={adminHeaderEditable}
                onDestinationTypeChange={toggleCardBackStore}
                ctaActions={[
                  ActionTypes.CARD_BACK,
                  ActionTypes.DEEPLINK,
                  ActionTypes.URL
                ]}
              />
            )}
          </MobileCampaignPanel>
        </Col>
      </Row>
    </CollapseContextProvider>
  )
}

export default withStore(CardFrontStep)
