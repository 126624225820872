import { ReactElement } from 'react'
import {
  DeviceType,
  IPreviewItem
} from '~/dataStore/Campaign/Campaign.interface'
import SMSHeader from '../Parts/SMSheader'
import PreviewParts from '../Parts/PreviewParts'
import PreviewCard from '../UI/PreviewCard'

import avatar from '~/components/icons/sms_avatar.svg'

interface IProps {
  deviceType: DeviceType
  parts: IPreviewItem[]
}

export default function SMSPreview({
  deviceType,
  parts
}: IProps): ReactElement {
  return (
    <PreviewCard deviceType={deviceType} className="preview__card--sms">
      <SMSHeader deviceType={deviceType} />
      {deviceType === 'android' ? (
        <div className="d-flex align-items-start">
          <img src={avatar} alt="Avatar" className="me-1" />
          <div>
            <PreviewParts parts={parts} />
          </div>
        </div>
      ) : (
        <PreviewParts parts={parts} />
      )}
      {deviceType === 'watch' && (
        <span className="sms-header__time">9 min ago</span>
      )}
    </PreviewCard>
  )
}
