import React from 'react'
import cn from 'classnames'
import { Badge } from 'reactstrap'

interface IProps {
  children: React.ReactNode
  color: string
  outline?: boolean
  className: string
  [x: string]: any
}

const Pill = ({
  children,
  color = 'light',
  outline,
  className,
  ...otherProps
}: IProps): React.ReactElement => (
  <Badge
    pill
    color={outline ? 'white' : color}
    className={cn(className, {
      [`border border-${color} text-${color}`]: outline
    })}
    {...otherProps}>
    {children}
  </Badge>
)

export default Pill
