import React from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ActionsColumn from './components/ActionsColumn'
import TableNew from '~/components/Table/TableNew'
import { useStore, withStore } from '~/dataStore'
import EditAddDeeplinkModal from './components/EditAddDeeplinkModal'
import DeleteDeeplinkModal from './components/DeleteDeeplinkModal'
import { IDeeplink } from './Deeplink.interface'
import { useGetDeepLinks } from './useDeeplinks'

import styles from './DeeplinkSection.scss'

const columns = [
  {
    fieldName: 'nickname',
    displayName: 'Deeplink',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IDeeplink }) => (
      <p className="mb-0">{row.nickname}</p>
    ),
    sortable: false,
    classes: styles.column
  },
  {
    fieldName: 'target',
    displayName: 'Deeplink URL',
    classes: styles.column,
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IDeeplink }) => (
      <p className="mb-0">{row.target}</p>
    ),
    sortable: false
  },
  {
    displayName: 'Actions',
    classes: styles.actionsColumn,
    // eslint-disable-next-line react/display-name
    render: ({ row }) => <ActionsColumn deeplink={row} />,
    sortable: false
  }
]

const DeeplinkSection = () => {
  const {
    ui: { showModal },
    app: {
      currentApp,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const { data: deepLinks, isLoading } = useGetDeepLinks(currentApp.id)

  return (
    <>
      <Card className="flex-fill w-100">
        <CardHeader className="d-flex justify-content-between">
          <CardTitle tag="h3" className="mb-0 mt-2">
            Deeplink
          </CardTitle>
          <Button
            color="primary"
            className="btn--wide btn--hover mt-2"
            size="lg"
            disabled={isDemoAdmin}
            onClick={() => showModal('editAddDeeplink')}>
            <FontAwesomeIcon icon={faPlus} size="xs" className="me-2" /> Add
            Deeplink
          </Button>
        </CardHeader>
        <CardBody className="pt-0 pb-4">
          <TableNew
            isLoading={isLoading}
            columns={columns}
            rows={deepLinks?.data || []}
          />
        </CardBody>
      </Card>
      <DeleteDeeplinkModal />
      <EditAddDeeplinkModal />
    </>
  )
}

export default withStore(DeeplinkSection)
