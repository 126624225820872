import { action, computed, observable, makeObservable } from 'mobx'
import { Preview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import {
  ICampaign,
  ICampaignModel,
  ISMSPayload,
  CampaignType,
  PreviewFieldType
} from '~/dataStore/Campaign/Campaign.interface'
import { getCampaignTypeOptions } from '~/dataStore/CampaignTypeFacade'

import NewRegisteredField from '../RegisteredField.model'
import StepStore from '../StepStore'

export default class SMS extends StepStore {
  appName: string

  public bodyTemplate = new NewRegisteredField<string>('')

  constructor(campaign?: ICampaignModel) {
    super()

    makeObservable(this, {
      bodyTemplate: observable,
      validateMessage: action.bound,
      validateStep: action.bound,
      registeredFields: computed
    })

    this.appName = campaign?.rootStore.app.appDetails.name
  }

  public validateMessage(): void {
    this.bodyTemplate.validate('Enter a message')
  }

  validateStep(): void {
    this.validateMessage()
    this.beenValid = true
  }

  public get registeredFields(): NewRegisteredField[] {
    return [this.bodyTemplate]
  }

  public fillStore(data: ICampaign): void {
    this.bodyTemplate.fillField({ value: data.smsNotification?.bodyTemplate })
  }

  public getPayload(): ISMSPayload {
    return {
      bodyTemplate: this.bodyTemplate.value
    }
  }

  public generatePreview(): Preview {
    return {
      sms: {
        parts: [
          {
            type: PreviewFieldType.HEADLINE,
            value: this.appName
          },
          {
            type: PreviewFieldType.TEXT,
            value: this.bodyTemplate.value
          }
        ]
      },
      type: getCampaignTypeOptions([CampaignType.SMS])
    }
  }
}
