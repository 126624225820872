import { useCallback, useState } from 'react'
import { decamelize } from 'humps'
import { IMetaData } from '~/common.interface'

export interface IPaginationQuery {
  page?: number
  perPage?: number
  sortField?: string
  order?: 'asc' | 'desc'
  searchQuery?: string
}

interface IProps {
  perPage?: number
  sortField?: string
}

export type ReturnType<T> = {
  sortField: string
  setSortField: (sortField: string) => void
  order: 'asc' | 'desc'
  setOrder: (order: 'asc' | 'desc') => void
  hasMore: boolean
  searchQuery: string
  handleSearch: (value: string) => void
  handleSort: (value: string) => void
  handleLoadMore: () => void
  getPaginationQuery: () => IPaginationQuery
  checkPage: (
    metadata: IMetaData,
    currentCollection: Array<T>,
    newCollection: Array<T>
  ) => Array<T>
  setPage: (value: number) => void
  page: number
}

export default function usePaginationQuery<T extends IProps = IProps>({
  perPage: initialPerPage,
  sortField: initialSortField
}: IProps): ReturnType<T> {
  const [page, setPage] = useState(1)
  const [perPage] = useState(initialPerPage)
  const [hasMore, setHasMore] = useState(false)
  const [sortField, setSortField] = useState(initialSortField)
  const [order, setOrder] = useState<'asc' | 'desc'>('desc')
  const [searchQuery, setSearchQuery] = useState('')

  function handleSearch(value: string) {
    // debounce input breaks automatic batching - added in react 18
    setPage(1)
    setSearchQuery(value)
  }

  function handleSort(value: string) {
    if (value === sortField) {
      if (order === 'asc') {
        setOrder('desc')
      } else {
        setOrder('asc')
      }
    } else {
      setSortField(value)
      setOrder('asc')
    }

    setPage(1)
  }

  function handleLoadMore() {
    setPage((prev) => prev + 1)
  }

  function checkPage(
    metadata: IMetaData,
    currentCollection: Array<T>,
    newCollection: Array<T>
  ): Array<T> {
    setHasMore(page < metadata.totalPages)

    if (page === 1) return newCollection

    return currentCollection.concat(newCollection)
  }

  const getPaginationQuery = useCallback(() => {
    return {
      ...(page > 0 && { page }),
      ...(perPage && { perPage }),
      ...(sortField && { sort: decamelize(sortField) }),
      ...(order && { order }),
      ...(searchQuery && { searchQuery })
    }
  }, [page, perPage, sortField, order, searchQuery])

  return {
    sortField,
    setSortField,
    order,
    setOrder,
    hasMore,
    searchQuery,
    handleSearch,
    handleSort,
    handleLoadMore,
    getPaginationQuery,
    checkPage,
    setPage,
    page
  }
}
