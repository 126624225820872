import React from 'react'
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import DisabledFeatureWarning from '~/components/common/DisabledFeatureWarning'

const DisabledFeatureCard = ({
  cardTitle,
  feature
}: {
  cardTitle: string
  feature: 'Beacons' | 'Geofences'
}): React.ReactElement => {
  return (
    <Card className="flex-fill w-100">
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {cardTitle}
        </CardTitle>
      </CardHeader>
      <CardBody className="align-items-center d-flex">
        <p className="p-2 text-blue-gray text-13 text-center mb-0">
          <DisabledFeatureWarning feature={feature} />
        </p>
      </CardBody>
    </Card>
  )
}

export default DisabledFeatureCard
