import React from 'react'
import TableNew from '~/components/Table/TableNew'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import StatusPill from '~/components/Filters/StatusPill'
import { ICampaign } from '~/pages/Campaign/CampaignsDashboard/Store/CampaignsDashboard.interface'
import { IMetaData } from '~/common.interface'
import CampaignNameColumn from '~/components/Table/components/CampaignNameColumn'
import ReportBtn from '~/pages/Campaign/CampaignReports/ReportBtn'
import routes from '~/routes'
import { ICampaignsListItem } from '~/pages/Campaign/CampaignsList/CampaignsList.interface'
import { useStore, withStore } from '~/dataStore'

interface IProps {
  campaigns: ICampaign[] | undefined
  metadata: IMetaData | undefined
  campaignsFetching: boolean
  onSort: (sortField: string) => void
  onLoadMore: () => void
  page: number
  sortField?: string
  order: 'asc' | 'desc' | undefined
}

const CampaignsDashboardCampaignsList = ({
  campaigns,
  metadata,
  campaignsFetching,
  onSort,
  onLoadMore,
  page,
  sortField,
  order
}: IProps): React.ReactElement | null => {
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()

  if (!campaigns) return null

  const columns = [
    {
      fieldName: 'name',
      displayName: 'Campaign name',
      sortable: true,
      // eslint-disable-next-line react/display-name
      render: ({
        row: { name, id, hiddenFromFeed }
      }: {
        row: ICampaignsListItem
      }) => (
        <CampaignNameColumn
          hiddenFromFeed={hiddenFromFeed}
          campaignId={id}
          name={name}
          isRedirectActive
        />
      )
    },
    {
      fieldName: 'status',
      displayName: 'Status',
      render({ row }: { row: ICampaign }) {
        return <StatusPill status={row.status} />
      },
      sortable: false
    },
    {
      fieldName: 'sentCount',
      displayName: 'Sent',
      sortable: true
    },
    {
      fieldName: 'uniqOpenRate',
      displayName: 'Unique Open Rate',
      sortable: false
    },
    {
      fieldName: 'uniqClickRate',
      displayName: 'Unique Click Rate',
      sortable: false
    },
    ...(featureFlags.campaignsGoals
      ? [
          {
            fieldName: 'primaryConversionRate',
            displayName: 'Primary Conversion Rate',
            sortable: false
          },
          {
            fieldName: 'secondaryConversionRate',
            displayName: 'Secondary Conversion Rate',
            sortable: false
          }
        ]
      : []),
    {
      fieldName: undefined,
      displayName: 'Report',
      render({ row }: { row: ICampaign }) {
        return (
          <ReportBtn
            id={row.id}
            status={row.status}
            route={routes.campaignReport}
            paramName="campaignId"
          />
        )
      }
    }
  ]

  return (
    <div className="card mt-3 px-4 pt-4 pb-3">
      <Header className="mb-3 mt-2">
        <HeaderTitle>
          Campaigns <span className="text-muted">({metadata?.dataCount})</span>
        </HeaderTitle>
      </Header>
      <TableNew
        columns={columns}
        isLoading={campaignsFetching}
        onSort={onSort}
        sortOrder={order}
        sortColumn={sortField}
        rows={campaigns}
        onLoadMore={onLoadMore}
        hasMore={metadata ? metadata?.totalPages > page : false}
      />
    </div>
  )
}

export default withStore(CampaignsDashboardCampaignsList)
