import React from 'react'

interface ConditionalWrapperProps {
  condition: boolean
  wrapper: (children: React.ReactElement) => React.ReactElement
  children: React.ReactElement
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children
}: ConditionalWrapperProps): React.ReactElement =>
  condition ? wrapper(children) : children

export default ConditionalWrapper
