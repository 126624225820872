import { ReactElement } from 'react'
import { Button } from 'reactstrap'
import styles from '../UserDetails.scss'
import {
  useSubscribeUser,
  useUserDetails
} from '~/pages/Users/UserDetails/useUserDetails'

const UserStatusOverlay = (): ReactElement | null | undefined => {
  const { mutateAsync: subscribeUser } = useSubscribeUser()
  const { data: user } = useUserDetails()

  if (user?.status.deleted) return null

  if (user?.status.unsubscribed) {
    return (
      <div className={styles.resubscribeOverlay} aria-label="User Unsubscribed">
        <div className="text-center">
          <Button
            color="primary"
            onClick={() => subscribeUser()}
            aria-label="Resubscribe User">
            Resubscribe
          </Button>
          <p
            className="text-white mt-4"
            aria-label="User has been unsubscribed">
            This user has been unsubscribed
          </p>
        </div>
      </div>
    )
  }

  if (user?.status.uninstalled) {
    return (
      <div className={styles.resubscribeOverlay} aria-label="User Uninstalled">
        <p className="text-white mt-4" aria-label="User has been uninstalled">
          This user has been uninstalled
        </p>
      </div>
    )
  }

  return null
}

export default UserStatusOverlay
