import React from 'react'
import pluralize from 'pluralize'

import Skeleton from 'react-loading-skeleton'
import Goal from './Goal'
import { withStore } from '~/dataStore'

import { controlGroup as controlGroupStyle, title } from './SetUp.scss'
import SetUpStore from '~/dataStore/emailBuilder/SetUp'
import { isEmpty } from '~/utils/utilities'

interface IProps {
  setUp: SetUpStore
}

function SetUp({ setUp }: IProps) {
  return (
    <div>
      <div className="section-card">
        <Goal
          type="primary"
          goal={setUp.primary.eventName.value?.label}
          time={`${setUp.primary.expiryTimeValue.value} ${pluralize(
            setUp.primary.expiryTimeUnit.value,
            setUp.primary.expiryTimeValue.value
          )}`}
        />
        {setUp.secondary && <div className="goals-divider" />}
        {setUp.secondary && (
          <Goal
            type="secondary"
            goal={setUp.secondary.eventName.value?.label}
            time={`${setUp.secondary.expiryTimeValue.value} ${pluralize(
              setUp.secondary.expiryTimeUnit.value,
              setUp.secondary.expiryTimeValue.value
            )}`}
          />
        )}
      </div>

      {setUp.controlGroup.active && (
        <div className={controlGroupStyle}>
          {!isEmpty(setUp.controlGroup.quantity.value) ? (
            <>
              <span className={title}>Control Group -&nbsp;</span>A random
              sample of&nbsp;
              <strong>{setUp.controlGroup.quantity.value}%</strong>
              &nbsp;of users will not receive this campaign
            </>
          ) : (
            <Skeleton width={300} count={1} />
          )}
        </div>
      )}
    </div>
  )
}

export default withStore(SetUp)
