import { Col, Row } from 'reactstrap'
import { useEffect, PropsWithChildren } from 'react'
import AllMobileDevicesPreviews from '~/pages/Campaign/Notification/Preview/AllMobileDevicesPreview'
import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import MobileCampaignPanel from '~/pages/CampaignBuilder/MobileCampaignPanel/MobileCampaignPanel'
import PushVariantStep from './PushVariantStep'
import LimitedPushVariantStep from './LimitedPushVariantStep'
import { CollapseContextProvider } from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { useStore, withStore } from '~/dataStore'

interface IProps {
  pushNotification: PushNotification
  onlyCarousel?: boolean
  isCardCampaign?: boolean
  isSended?: boolean
  colsWidth?: {
    left: { xxl: { size: number } }
    right: { xxl: { size: number } }
  }
  withGoals?: boolean
}

const PushNotificationBuilder = ({
  pushNotification,
  onlyCarousel = false,
  isCardCampaign = false,
  isSended = false,
  colsWidth,
  children,
  withGoals = false
}: PropsWithChildren<IProps>): React.ReactElement | null => {
  const {
    app: {
      currentApp,
      appDetails: { featureFlags }
    }
  } = useStore()

  useEffect(() => {
    pushNotification.shared
      .fetchMergeTags(currentApp.id)
      .catch((error) => console.error(error))

    pushNotification.shared
      .fetchDeeplinks(currentApp.id)
      .catch((error) => console.error(error))

    pushNotification.shared
      .fetchButtonCategories()
      .catch((error) => console.error(error))

    pushNotification.shared
      .fetchEvents(currentApp.id)
      .catch((error) => console.error(error))
  }, [currentApp.id])

  if (!pushNotification.currentVariant) {
    return null
  }

  const fieldsDictionary = {
    headline: 'title',
    subheadline: 'subTitle',
    text: 'notification',
    attachment: 'richMedia',
    button: 'actionButtons'
  }

  return (
    <CollapseContextProvider fieldMapper={fieldsDictionary}>
      <Row className="align-items-start">
        <Col
          widths={['md', 'xxl']}
          md={{ size: 7 }}
          {...colsWidth?.left}
          className="mb-4">
          <AllMobileDevicesPreviews
            onlyCarousel={onlyCarousel}
            parts={
              pushNotification.generateCurrentVariantPreview().push.variants[0]
            }
            campaignType={CampaignType.PUSH}
          />
        </Col>
        <Col widths={['md', 'xxl']} md={{ size: 5 }} {...colsWidth?.right}>
          {children}
          <MobileCampaignPanel>
            {featureFlags?.pushNotificationsFullAccess ? (
              <PushVariantStep
                pushNotification={pushNotification}
                isCardCampaign={isCardCampaign}
                isSended={isSended}
                withGoals={withGoals}
              />
            ) : (
              <LimitedPushVariantStep
                pushNotification={pushNotification}
                isCardCampaign={isCardCampaign}
                isSended={isSended}
                withGoals={withGoals}
              />
            )}
          </MobileCampaignPanel>
        </Col>
      </Row>
    </CollapseContextProvider>
  )
}

export default withStore(PushNotificationBuilder)
