import React from 'react'
import './InAppSmallAnimatedIcon.scss'

const InAppSmallAnimatedIcon = ({
  className
}: {
  className: string
}): React.ReactElement => (
  <svg
    id="animatedInAppSmallIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 207 194"
    className={className}
    width={207}
    height={194}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <path
      d="M2,0l203,.000037L205,182c0,5.523-4.477,10-10,10h-183c-5.52288,0-10.00003-4.477-10.00003-10L2,0Z"
      fill="#fff"
    />
    <g mask="url(#e7u9b2e8DmV5)">
      <path
        d="M2,0l203,.000037L2,0ZM207,182c0,6.627-5.373,12-12,12h-183c-6.62745,0-12.000032-5.373-12.000031-12h4.000001c0,4.418,3.58172,8,8.00003,8h183c4.418,0,8-3.582,8-8h4ZM12,194c-6.62745,0-12.000032-5.373-12.000031-12L0,0h4L3.99997,182c0,4.418,3.58172,8,8.00003,8v4ZM207,0.000037L207,182c0,6.627-5.373,12-12,12v-4c4.418,0,8-3.582,8-8v-181.999963h4Z"
        fill="#93a8c5"
      />
      <mask
        id="e7u9b2e8DmV5"
        mask-type="luminance"
        x="-150%"
        y="-150%"
        height="400%"
        width="400%">
        <rect width="207" height="194" rx="0" ry="0" fill="#fff" />
        <path d="M2,0l203,.000037L205,182c0,5.523-4.477,10-10,10h-183c-5.52288,0-10.00003-4.477-10.00003-10L2,0Z" />
      </mask>
    </g>
    <path
      d="M15,0l177,.000032L192,142c0,4.418-3.582,8-8,8h-161c-4.4183,0-8-3.582-8-8L15,0Z"
      fill="#bfcbdd"
    />
    <g mask="url(#e7u9b2e8DmV11)">
      <path
        d="M15,0l177,.000032L15,0ZM194,142c0,5.523-4.477,10-10,10h-161c-5.5229,0-10-4.477-10-10h4c0,3.314,2.6863,6,6,6h161c3.314,0,6-2.686,6-6h4ZM23,152c-5.5229,0-10-4.477-10-10L13,0h4v142c0,3.314,2.6863,6,6,6v4ZM194,0.000033L194,142c0,5.523-4.477,10-10,10v-4c3.314,0,6-2.686,6-6v-141.999968l4,.000001Z"
        fill="#93a8c5"
      />
      <mask
        id="e7u9b2e8DmV11"
        mask-type="luminance"
        x="-150%"
        y="-150%"
        height="400%"
        width="400%">
        <rect
          width="181"
          height="152"
          rx="0"
          ry="0"
          transform="translate(13 0)"
          fill="#fff"
        />
        <path d="M15,0l177,.000032L192,142c0,4.418-3.582,8-8,8h-161c-4.4183,0-8-3.582-8-8L15,0Z" />
      </mask>
    </g>
    <g
      id="animateInAppSmalldObject1_to"
      style={{
        offsetPath:
          "path('M96.65,203.15C89.054085,158.242704,58.066117,145.412003,103.5,114.912003')",
        offsetRotate: '0deg'
      }}>
      <g
        id="animateInAppSmalldObject1_ts"
        transform="rotate(-180) scale(-0.3,0.3)">
        <rect
          id="animateInAppSmalldObject1"
          width="169"
          height="61"
          rx="5"
          ry="5"
          transform="translate(-84.5,-30.5)"
          opacity="0"
          fill="#fff"
          stroke="#93a8c5"
          strokeWidth="2"
        />
      </g>
    </g>
    <g id="animateInAppSmalldObject3" opacity="0">
      <rect
        width="82"
        height="9"
        rx="2"
        ry="2"
        transform="matrix(1 0 0-1 80 135)"
        fill="#93a8c5"
      />
      <rect
        width="40"
        height="40"
        rx="4"
        ry="4"
        transform="matrix(1 0 0-1 29 135)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <rect
        width="37"
        height="8"
        rx="2"
        ry="2"
        transform="matrix(1 0 0-1 80 119)"
        fill="#93a8c5"
      />
      <rect
        width="37"
        height="8"
        rx="2"
        ry="2"
        transform="matrix(1 0 0-1 125 119)"
        fill="#93a8c5"
      />
      <rect
        width="82"
        height="9"
        rx="2"
        ry="2"
        transform="matrix(1 0 0-1 80 104)"
        fill="#93a8c5"
      />
      <g
        id="animateInAppSmalldObject2_to"
        transform="translate(176.095215,114.911999)">
        <path
          d="M174.066,121.475c-.636.55-1.665.55-2.301,0-.635-.549-.635-1.441,0-1.991l5.283-4.572-5.283-4.572c-.635-.55-.635-1.441,0-1.991.636-.55,1.665-.55,2.301,0l6.359,5.505c.336.291.495.677.475,1.058.02.381-.139.768-.475,1.059l-6.359,5.504Z"
          transform="translate(-176.095215,-114.911999)"
          clipRule="evenodd"
          fill="#93a8c5"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
)

export default InAppSmallAnimatedIcon
