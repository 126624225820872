import { memo, ReactElement } from 'react'
import cn from 'classnames'
import {
  DeviceType,
  IPreviewItem,
  PreviewFieldType
} from '~/dataStore/Campaign/Campaign.interface'
import PreviewParts from '../Parts/PreviewParts'
import PreviewCard from '../UI/PreviewCard'
import AdminHeader from '../Parts/AdminHeader'
import CallToActionButtons from '../Parts/CallToActionButtons'
import useCollapseContext from '../../../../../components/CollapseWithToggle/CollapseWithToggle.context'

interface IProps {
  deviceType: DeviceType
  parts: IPreviewItem[]
  reply: boolean
}

function CardBackPreview({ deviceType, parts, reply }: IProps): ReactElement {
  const collapseContext = useCollapseContext()

  const adminPart = parts.find(
    (part) => part.type === PreviewFieldType.ADMIN_HEADER
  )

  const buttons = parts.find((part) => part.type === PreviewFieldType.BUTTON)

  return (
    <div
      className={cn(
        'card-back d-flex flex-column h-100',
        `card-back--${deviceType}`,
        { 'card-back--reply': reply }
      )}>
      {adminPart && (
        <div className="card-back__fixed">
          <AdminHeader
            image={adminPart.admin?.avatarUrl || ''}
            name={adminPart.admin?.name || ''}
            jobTitle={adminPart.admin?.jobTitle || ''}
            message={(adminPart.value as string) || ''}
          />
        </div>
      )}
      <div className="card-back__scrollable flex-grow-1">
        <PreviewCard
          side="back"
          emptyPreview={!parts.length}
          deviceType={deviceType}>
          <PreviewParts
            parts={parts.filter(
              (part) =>
                ![
                  PreviewFieldType.BUTTON,
                  PreviewFieldType.ADMIN_HEADER
                ].includes(part.type)
            )}
          />
        </PreviewCard>
      </div>

      {buttons && (
        <div className="mt-2">
          <CallToActionButtons
            buttons={buttons.value as string[]}
            onClick={() => collapseContext?.setOpened(PreviewFieldType.BUTTON)}
          />
        </div>
      )}
    </div>
  )
}

export default memo(CardBackPreview)
