import React, { useState, useEffect } from 'react'
import {
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledButtonDropdown
} from 'reactstrap'
import useDropdownNavTabs from '~/hooks/useDropdownNavTabs'
import CampaignsTab from './components/CampaignsTab'
import GroupsTab from './components/GroupsTab'
import { IHandleFilterProps } from './CampaignsAndGroupsMultiSelect.interface'
import { IFilterResources } from '~/pages/Campaign/CampaignsDashboard/Store/CampaignsDashboard.interface'

import './CampaignsAndGroupsMultiSelect.scss'

interface IProps {
  filterResources: IFilterResources | undefined
  handleFilterCampaigns: ({ checked, value, reset }: IHandleFilterProps) => void
  handleFilterGroups: ({ checked, value, reset }: IHandleFilterProps) => void
  selectedCampaigns: string
  selectedGroups: string
}

enum Panel {
  GROUPS,
  CAMPAIGNS
}

function idsToSet(entities: string) {
  return new Set(entities.split(',').filter(String))
}

const CampaignsMultiSelect = ({
  selectedCampaigns,
  selectedGroups,
  handleFilterGroups,
  handleFilterCampaigns,
  filterResources
}: IProps): React.ReactElement => {
  const [activeTab, toggle, getTabClassName] = useDropdownNavTabs(
    Panel.CAMPAIGNS
  )

  const [selectedCampaignsIds, setSelectedCampaignsIds] = useState<Set<string>>(
    idsToSet(selectedCampaigns)
  )

  const [selectedGroupsIds, setSelectedGroupsIds] = useState<Set<string>>(
    idsToSet(selectedGroups)
  )

  useEffect(() => {
    setSelectedCampaignsIds(idsToSet(selectedCampaigns))
  }, [selectedCampaigns])

  useEffect(() => {
    setSelectedGroupsIds(idsToSet(selectedGroups))
  }, [selectedGroups])

  return (
    <UncontrolledButtonDropdown>
      <div className="campaigns-groups-multi-select">
        <DropdownToggle
          caret
          color=""
          outline
          className="border border-input rounded bg-white">
          Groups / Campaigns
        </DropdownToggle>
        <DropdownMenu
          persist
          end
          modifiers={[
            {
              name: 'maxHeight',
              enabled: true,
              phase: 'beforeWrite',
              fn({ state }) {
                return {
                  ...state,
                  styles: {
                    ...state.styles,
                    popper: {
                      ...state.styles.popper,
                      maxHeight: `200px`,
                      overflowY: `auto`,
                      paddingBottom: '15px'
                    }
                  }
                }
              }
            }
          ]}>
          <Nav tabs className="dropdown-nav-tabs">
            <NavItem>
              <NavLink
                tag="button"
                data-testid="groupsDropdownTab"
                className={getTabClassName(Panel.GROUPS)}
                onClick={() => {
                  toggle(Panel.GROUPS)
                }}>
                Groups
              </NavLink>
            </NavItem>
            <li className="dropdown-nav-tabs__separator" />
            <NavItem>
              <NavLink
                tag="button"
                data-testid="campaignsDropdownTab"
                className={getTabClassName(Panel.CAMPAIGNS)}
                onClick={() => {
                  toggle(Panel.CAMPAIGNS)
                }}>
                Campaigns
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={Panel.GROUPS}>
              <div className="pt-1 px-3">
                <GroupsTab
                  groups={filterResources?.groups}
                  selectedGroupsIds={selectedGroupsIds}
                  handleFilter={handleFilterGroups}
                />
              </div>
            </TabPane>
            <TabPane tabId={Panel.CAMPAIGNS}>
              <div className="pt-1 px-3">
                <CampaignsTab
                  campaigns={filterResources?.campaigns}
                  handleFilter={handleFilterCampaigns}
                  selectedCampaignIds={selectedCampaignsIds}
                />
              </div>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </div>
    </UncontrolledButtonDropdown>
  )
}

export default CampaignsMultiSelect
