import React from 'react'
import pluralize from 'pluralize'

interface IProps {
  limits?: string | boolean
  timeFrame?: string
  timeValue?: string
}

const CampaignDeliveryLimits = ({
  limits,
  timeFrame,
  timeValue
}: IProps): React.ReactElement => {
  const isMultipleTimes = limits

  return (
    <div className="deliver__limits">
      <div className="section-card section-card--limits">
        <div className="deliver__limits-container d-flex">
          {isMultipleTimes && (
            <p className="deliver__limits-left-block me-4 text-end text-secondary">
              Once per <br />{' '}
              <span className="fw-bold mt-2 pt-1 d-block">{`${timeValue} ${pluralize(
                timeFrame,
                timeValue
              )}`}</span>
            </p>
          )}
          <div className="d-flex flex-column">
            <p className="mb-2">
              User may receive their campaign{' '}
              {isMultipleTimes ? 'Multiple Times' : 'One Time'}
            </p>
            <div className="mt-1 muted">
              {isMultipleTimes
                ? 'Users will receive this campaign every time they trigger the targeting criteria.'
                : 'A user will only receive this campaign the first time they trigger the targeting criteria.'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignDeliveryLimits
