import CampaignPerformance from '~/pages/Campaign/CampaignReports/CampaignPerformance'
import GoalsPerformance from '../../../../Journeys/JourneyReport/UI/GoalsPerformance/GoalsPerformance'
import CampaignNumbers from '~/pages/Campaign/CampaignReports/UI/CampaignNumbers'
import ClickPerformance from '~/pages/Campaign/CampaignReports/ClickPerformance'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import {
  IChartData,
  ILinkReport,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'

interface IProps {
  numbers: ISummary[]
  charts: IChartData[]
  clickPerformance?: ILinkReport[]
  goals: IGoalPerformance[]
}

export default function NotificationReport({
  numbers,
  charts,
  clickPerformance,
  goals
}: IProps): React.ReactElement {
  return (
    <>
      <CampaignNumbers numbers={numbers} />
      <CampaignPerformance charts={charts} />
      {clickPerformance && <ClickPerformance rows={clickPerformance} />}
      <GoalsPerformance goals={goals} />
    </>
  )
}
