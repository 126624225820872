import { ReactElement } from 'react'

const ProtectedSiteInfo = (): ReactElement => {
  return (
    <div className="mt-3 text-muted text-11">
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy" tabIndex={-1}>
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://policies.google.com/terms" tabIndex={-1}>
        Terms of Service
      </a>{' '}
      apply.
    </div>
  )
}

export default ProtectedSiteInfo
