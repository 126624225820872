import React from 'react'
import {
  faTimes,
  faCheck,
  faPaperPlane,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { TBadgeVariations } from '~/common.interface'
import CircleIcon from '~/components/CircleIcon'

type TStatus = {
  bg: TBadgeVariations
  icon: IconDefinition
  tooltip: string
}

const STATUSES = {
  VERIFIED: {
    bg: 'info',
    icon: faCheck,
    tooltip: 'Email has been verified'
  },
  EXPIRED: {
    bg: 'danger',
    icon: faTimes,
    tooltip: 'Verification email has expired'
  },
  SENDED: {
    bg: 'primary',
    icon: faPaperPlane,
    tooltip: 'Verification email has been sent'
  }
}

const VerificationStatusColumn = ({
  verified,
  expired
}: {
  verified: boolean
  expired: boolean
}): React.ReactElement => {
  function getStatus() {
    if (verified) return STATUSES.VERIFIED as TStatus
    if (expired) return STATUSES.EXPIRED as TStatus
    return STATUSES.SENDED as TStatus
  }

  const status = getStatus()

  return (
    <CircleIcon bg={status.bg} icon={status.icon} tooltip={status.tooltip} />
  )
}

export default VerificationStatusColumn
