import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import { updateCurrentAdmin } from '~/api/admins'
import Input from '~/components/forms/Input'
import { convertImageToUrl } from '~/utils/file.service'
import {
  ADMINS_LIST_QUERY,
  ERRORS_DICT,
  PersonalFormFieldValues
} from '../AccountSettings.interface'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'
import UploadAvatar from '~/components/UploadAvatar'

import styles from './AccountSettingsPersonal.scss'
import { useStore } from '~/dataStore'

interface IProps {
  username: string
  name: string
  jobTitle: string | null
  avatarUrl: string
  isDefaultAvatar: boolean
  appId: string
  onSubmit: (data: {
    avatarUrl: string
    jobTitle: string
    name: string
  }) => void
}

const AccountSettingsPersonal = ({
  username,
  name,
  jobTitle,
  avatarUrl,
  appId,
  onSubmit,
  isDefaultAvatar
}: IProps): React.ReactElement => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const queryClient = useQueryClient()
  const {
    setError,
    register,
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<PersonalFormFieldValues>({
    mode: 'onChange',
    defaultValues: {
      adminAvatar: {
        objectURL: isDefaultAvatar ? '' : avatarUrl,
        fileName: avatarUrl || '',
        file: null,
        valid: true
      }
    }
  })

  const onSubmitForm: SubmitHandler<PersonalFormFieldValues> = async (
    data: PersonalFormFieldValues
  ) => {
    const payload: {
      jobTitle: string
      name: string
      reset: string
      avatarS3Url?: string
    } = {
      jobTitle: data.jobTitle,
      name: data.name,
      reset: `${!data.adminAvatar.file && !data.adminAvatar.objectURL}`
    }

    if (data.adminAvatar.file) {
      try {
        payload.avatarS3Url = await convertImageToUrl(
          appId,
          data.adminAvatar.file
        )
      } catch (error) {
        if (error?.body.error) {
          setError('uploadError', { type: 'server', message: error.body.error })
        } else {
          showGeneralError()
        }
        return
      }
    }
    try {
      await updateCurrentAdmin(appId, payload)
      onSubmit({
        jobTitle: payload.jobTitle,
        name: payload.name,
        avatarUrl: payload.avatarS3Url || ''
      })
      queryClient.invalidateQueries([ADMINS_LIST_QUERY])
      showNotification(
        'Successfully updated admin personal information',
        NotificationType.SUCCESS
      )
    } catch (error: Error & { body: any }) {
      if (error.body.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <form
      className="w-100 account-settings-personal"
      onSubmit={handleSubmit(onSubmitForm)}>
      <Row>
        <Col xs={8}>
          <Input
            defaultValue={username}
            label="User Name"
            name="username"
            disabled
            className="mb-3"
          />
          <Input
            defaultValue={name}
            register={register}
            label="Full name"
            placeholder="Enter Full Name"
            className={cn('mb-3', { 'form-group--error': errors.name })}
            required
            validation={{
              required: {
                value: true,
                message: ERRORS_DICT.name["can't be blank"]
              }
            }}
            errorTooltip={errors?.name?.message}
            tooltip=""
            name="name"
          />
          <Input
            defaultValue={jobTitle}
            register={register}
            label="Job title"
            name="jobTitle"
            placeholder="Enter Job Title"
            className="mb-3"
          />
          <Button
            color="primary"
            type="submit"
            className="mt-4 d-block"
            disabled={
              isSubmitting || !watch('adminAvatar')?.valid || isDemoAdmin
            }>
            Save Changes{' '}
            {isSubmitting && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="xs"
                className="ms-1"
              />
            )}
          </Button>
        </Col>
        <Col xs={4} className="d-flex justify-content-center pe-5">
          <div className={styles.adminAvatarWrapper}>
            <Controller
              control={control}
              name="adminAvatar"
              register={register}
              render={({ field: { onChange, value } }) => (
                <UploadAvatar
                  onChange={({ objectURL, fileName, file, valid }) => {
                    clearErrors('uploadError')
                    onChange({ objectURL, fileName, file, valid })
                  }}
                  fileName={value.fileName}
                  image={value.objectURL}
                  error={errors.uploadError?.message}
                />
              )}
            />
          </div>
        </Col>
      </Row>
    </form>
  )
}

export default AccountSettingsPersonal
