import { toastr } from 'react-redux-toastr'
import {
  CampaignType,
  InAppSize
} from '~/dataStore/Campaign/Campaign.interface'
import { IFeatureFlags } from '~/dataStore/App.interface'

export enum NotificationType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export function clearNotifications(type: string) {
  toastr.removeByType(type)
}

export function showNotification(
  message: string | React.ReactElement,
  type: NotificationType,
  options?: { persist?: boolean }
): void {
  let stringMessage = ''
  const notificationOptions: any = {
    showCloseButton: true,
    progressBar: true,
    position: 'top-right',
    title: ''
  }

  let toastrInstance
  switch (type) {
    case NotificationType.INFO:
      toastrInstance = toastr.info
      break
    case NotificationType.WARNING:
      toastrInstance = toastr.warning
      break
    case NotificationType.ERROR:
      notificationOptions.timeOut = 0
      notificationOptions.extendedTimeOut = 0
      notificationOptions.disableTimeOut = true
      toastrInstance = toastr.error
      break
    default:
      toastrInstance = toastr.success
  }

  if (typeof message !== 'string') {
    notificationOptions.component = message
  } else {
    stringMessage = message
  }

  if (options?.persist) {
    notificationOptions.timeOut = 0
    notificationOptions.extendedTimeOut = 0
    notificationOptions.disableTimeOut = true
  }

  toastrInstance(stringMessage, notificationOptions)
}

export function isNotificationBuilderNotAllowed(
  campaignType: CampaignType,
  notificationType: CampaignType,
  inAppSize: InAppSize,
  featureFlags: IFeatureFlags
) {
  const isSms =
    campaignType === CampaignType.SMS || notificationType === CampaignType.SMS
  const isEmail =
    campaignType === CampaignType.EMAIL ||
    notificationType === CampaignType.EMAIL

  return (
    (isEmail && !featureFlags.email) ||
    (isSms && !featureFlags.sms) ||
    ((campaignType === CampaignType.PUSH ||
      notificationType === CampaignType.PUSH) &&
      !featureFlags.push) ||
    (inAppSize === InAppSize.SMALL && !featureFlags.smallInApp)
  )
}
