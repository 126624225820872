import React from 'react'
import { withStore } from '~/dataStore'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import PushNotificationBuilder from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import Goals from '~/pages/Campaign/Notification/NotificationGoals/UI'

interface IProps {
  pushNotification: PushNotificationBuilder
  isCardCampaign: boolean
  isSended: boolean
  withGoals: boolean
}

const LimitedPushVariantStep = ({
  pushNotification,
  isCardCampaign = false,
  isSended,
  withGoals
}: IProps) => {
  const { currentVariant, currentVariantId, shared } = pushNotification

  if (!currentVariant) {
    return null
  }

  return (
    <CollapseWithToggle
      name={isCardCampaign ? 'Text' : 'Text & Notification Action'}
      opened
      isValid={currentVariant.isFieldValid('notification')}
      checked={currentVariant.isFieldActive('notification')}
      onlyCollapse>
      <Textarea
        key={`${currentVariantId}-notification`}
        onChange={currentVariant.notification.setValue}
        value={currentVariant.notification.value}
        invalid={!currentVariant.notification.isValid}
        required
        withoutLineBreaks
        disabled={isSended}
        onBlur={() => currentVariant.validateField('notification')}
        placeholder="This is your main notification..."
        mergeTags={shared.mergeTags}
      />
      {isCardCampaign ? (
        <p className="text-blue-gray text-13">
          Tapping this notification will show you the Feed Post.
        </p>
      ) : (
        <>
          <p className="text-blue-gray text-13">
            Tapping this notification will open an app
          </p>
          {withGoals && currentVariant.goals && (
            <div className="mb-3">
              <Goals
                disabled={isSended}
                setGoal={currentVariant.goals.setGoal}
                sourceType="notification"
                selected={currentVariant.goals.selectedGoalTypes}
                checkedGoalType={currentVariant.goals.isChecked({
                  sourceType: 'notification'
                })}
              />
            </div>
          )}
        </>
      )}
    </CollapseWithToggle>
  )
}

export default withStore(LimitedPushVariantStep)
