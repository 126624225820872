import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Modal from '~/components/modals/Modal/Modal'
import Input from '~/components/forms/Input'
import {
  useAddWebSdkDomain,
  useEditWebSDkDomain
} from '~/pages/App/Settings/sections/WebSDKSection/useWebSDKCredentials'
import { IWebSDKDomain } from '~/pages/App/Settings/sections/WebSDKSection/WebSDKSection.interface'

const EditAddDomain = ({
  domain,
  close
}: {
  domain: IWebSDKDomain
  close: () => void
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ url: string }>({
    mode: 'onBlur',
    defaultValues: { url: domain?.url }
  })

  const { mutate: edit, isLoading: isEditLoading } = useEditWebSDkDomain(close)

  const { mutate: add, isLoading: isAddLoading } = useAddWebSdkDomain(close)

  const onSubmitForm = async (data: { url: string }) => {
    if (domain) {
      edit({ id: domain.id, data })
    } else {
      add(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <ModalBody>
        <Input
          register={register}
          placeholder="Add Domain URL"
          label="Domain URL"
          validation={{
            required: {
              value: true,
              message: 'Domain url is missing.'
            }
          }}
          className={cn('mb-0', {
            'form-group--error': errors.url
          })}
          errorTooltip={errors?.url?.message}
          tooltip=""
          name="url"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={isAddLoading || isEditLoading}>
          Save
          {(isAddLoading || isEditLoading) && (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-2" />
          )}
        </Button>
      </ModalFooter>
    </form>
  )
}

const EditAddDomainModal = () => {
  return (
    <Modal
      id="editAddDomainModal"
      size="md"
      renderHeader={(onClose, params) =>
        `${params?.domain ? 'Edit' : 'New'} Domain`
      }
      render={(close: () => void, params) => (
        <EditAddDomain domain={params?.domain} close={close} />
      )}
    />
  )
}

export default EditAddDomainModal
