import { useEffect, useState } from 'react'

export default function useMatchMedia(query: string): boolean | null {
  const [match, setMatch] = useState<boolean | null>(null)

  useEffect(() => {
    const matchMedia = window.matchMedia(query)
    function matchHandler() {
      setMatch(matchMedia.matches)
    }
    matchHandler()
    window.addEventListener('resize', matchHandler, false)

    return () => {
      window.removeEventListener('resize', matchHandler)
    }
  }, [])

  return match
}
