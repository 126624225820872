import React, { useRef } from 'react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { uniqueId } from 'lodash'
import cn from 'classnames'

import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'

interface IProps extends Pick<FontAwesomeIconProps, 'size'> {
  tooltip: string
  inline?: boolean
  withIcon?: boolean
}

export default function Tooltip({
  tooltip,
  size = 'lg',
  inline = false,
  withIcon = true
}: IProps): React.ReactElement {
  const id = useRef(uniqueId('tooltip-'))

  return (
    <>
      {withIcon && (
        <FontAwesomeIcon
          icon={faInfoCircle}
          className={cn('o-50', { 'd-block ms-1': !inline })}
          id={id.current}
          size={size}
        />
      )}
      <UncontrolledTooltip placement="top" target={id.current}>
        {tooltip}
      </UncontrolledTooltip>
    </>
  )
}
