import React from 'react'
import { UncontrolledAlert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const ExpiryNeverWarning = () => {
  return (
    <UncontrolledAlert closeClassName="d-none" color="warning" className="mb-0">
      <div className="alert-icon">
        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />
      </div>
      <div className="alert-message">
        The campaign will never expire from the user's device, unless an
        optional scheduled end date is selected
      </div>
    </UncontrolledAlert>
  )
}

export default ExpiryNeverWarning
