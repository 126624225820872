import React, { useState } from 'react'
import { InputAction, Options } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

const components = {
  DropdownIndicator: null
}

const CreatableMultiselect = ({
  placeholder,
  onChange,
  value,
  options
}: {
  placeholder?: string
  onChange: (value: any) => void
  value: IOption[]
  options?: IOption[]
}): React.ReactElement => {
  const [inputValue, setInputValue] = useState('')

  const handleChange = (newValue: Options<IOption>) => {
    setInputValue('')
    onChange(newValue)
  }

  const handleInputChange = (
    textInput: string,
    { action }: { action: InputAction }
  ) => {
    if (action === 'input-blur') {
      if (inputValue)
        handleChange([...value, { value: inputValue, label: inputValue }])
    }
    if (action === 'input-change') {
      setInputValue(textInput)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValue) return

    if (['Enter', 'Tab'].includes(event.key)) {
      event.preventDefault()
      handleChange([
        ...value,
        { value: inputValue ?? '', label: inputValue ?? '' }
      ])
    }
  }

  return (
    <CreatableSelect
      classNamePrefix="custom-select"
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
    />
  )
}

export default CreatableMultiselect
