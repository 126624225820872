import { withStore } from '~/dataStore'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchValueOptions: {
  name: string
  value: 'true' | 'false' | 'not_set'
}[] = [
  {
    name: 'true',
    value: 'true'
  },
  {
    name: 'false',
    value: 'false'
  },
  {
    name: 'not set',
    value: 'not_set'
  }
]

function RuleDevicePushPermission({ rule }: IProps): React.ReactElement {
  return (
    <>
      <span aria-label="is">is</span>
      <RuleDropdown
        selected={
          matchValueOptions.find(
            (option) => option.value === rule.value.matchValue
          )?.name
        }
        options={matchValueOptions}
        action={(value) => rule.edit({ matchValue: value })}
        aria-label="Match Value"
      />
    </>
  )
}

export default withStore(RuleDevicePushPermission)
