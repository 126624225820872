import { get, destroy, patch, upload } from '~/api/v2'
import {
  IUser,
  IUsersListMetadata,
  IUsersListQueryParams,
  IUserDTO,
  IUserSubscription,
  IUserGeofenceEvent,
  IUserBeaconEvent,
  IUploadUsersDTO
} from '~/pages/Users/Users.interface'
import { ID, IMetaData } from '~/common.interface'
import { IPaginationQuery } from '~/hooks/usePaginationQuery'

export function fetchUsers(
  appId: string,
  params?: IUsersListQueryParams,
  abortSignal?: AbortSignal
): Promise<{ data: IUser[]; metadata: IUsersListMetadata }> {
  return get(`${appId}/users`, params, abortSignal)
}

export function fetchUserDetails(
  appId: string,
  id: ID,
  abortSignal?: AbortSignal
): Promise<IUserDTO> {
  return get(`${appId}/users/${id}`, abortSignal)
}

export function fetchUserSegments(
  appId: string,
  id: ID,
  abortSignal?: AbortSignal
): Promise<{
  data: { name: string; id: ID }[]
  metadata: Omit<IMetaData, 'groups'>
}> {
  return get(`${appId}/users/${id}/segments`, abortSignal)
}

export function fetchUserSubscriptions(
  appId: string,
  id: ID,
  params: Pick<IPaginationQuery, 'page' | 'perPage'>,
  abortSignal?: AbortSignal
): Promise<{ data: IUserSubscription[]; metadata: Omit<IMetaData, 'groups'> }> {
  return get(`${appId}/users/${id}/subscriptions`, params, abortSignal)
}

export function fetchUserBeaconEvents(
  appId: string,
  id: ID,
  params: Pick<IPaginationQuery, 'page' | 'perPage'>,
  abortSignal?: AbortSignal
): Promise<{ data: IUserBeaconEvent[]; metadata: Omit<IMetaData, 'groups'> }> {
  return get(`${appId}/users/${id}/beacon_events`, params, abortSignal)
}

export function fetchUserGeofenceEvents(
  appId: string,
  id: ID,
  params: Pick<IPaginationQuery, 'page' | 'perPage'>,
  abortSignal?: AbortSignal
): Promise<{
  data: IUserGeofenceEvent[]
  metadata: Omit<IMetaData, 'groups'>
}> {
  return get(`${appId}/users/${id}/geofence_events`, params, abortSignal)
}

export function deleteUser(
  appId: string,
  id: ID,
  abortSignal?: AbortSignal
): Promise<{ success: string }> {
  return destroy(`${appId}/users/${id}`, abortSignal)
}

export function unsubscribeUser(
  appId: string,
  id: ID,
  abortSignal?: AbortSignal
): Promise<{ success: string }> {
  return patch(`${appId}/users/${id}/unsubscribe`, abortSignal)
}

export function subscribeUser(
  appId: string,
  id: ID,
  abortSignal?: AbortSignal
): Promise<{ success: string }> {
  return patch(`${appId}/users/${id}/subscribe`, abortSignal)
}

export function addUserNote(
  appId: string,
  id: ID,
  content: string,
  abortSignal?: AbortSignal
): Promise<{ note: string; success: string }> {
  return patch(`${appId}/users/${id}/note`, { content }, abortSignal)
}

export function uploadUsers(
  appId: ID,
  data: FormData
): Promise<IUploadUsersDTO> {
  return upload(`${appId}/users/upload`, data)
}
