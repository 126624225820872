import React, { ReactElement, useEffect, useState } from 'react'
import PushNotificationBuilder from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import { useStore, withStore } from '~/dataStore'
import { ActionTypes } from '~/dataStore/Campaign/Campaign.interface'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import CampaignCTAButton from '~/pages/CampaignBuilder/CTA/CampaignCTAButton'
import SeparatorLine from '~/components/SeparatorLine'
import CampaignCTAGroupButton from '~/pages/CampaignBuilder/CTA/CampaignCTAGroupButton'
import RichMedia from '~/components/RichMedia'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import useCollapseContext from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import { PushFields } from '~/pages/Campaign/Notification/PushNotification/Model/PushVariant.model'
import Goals from '../../NotificationGoals/UI'

interface IProps {
  pushNotification: PushNotificationBuilder
  isCardCampaign: boolean
  isSended: boolean
  withGoals: boolean
}

function PushVariantStep({
  pushNotification,
  isCardCampaign = false,
  isSended: isSent,
  withGoals
}: IProps): ReactElement | null {
  const {
    app: {
      appDetails: {
        featureFlags: { pushRichMedia }
      }
    }
  } = useStore()

  const { currentVariant, currentVariantId, shared } = pushNotification

  const [groupButtonsActionTypes, setGroupButtonsActionTypes] = useState<
    ActionTypes[]
  >([
    ActionTypes.DEEPLINK,
    ActionTypes.URL,
    ActionTypes.OPEN_APP,
    ActionTypes.DISMISS
  ])

  useEffect(() => {
    let actionTypes = [
      ActionTypes.DEEPLINK,
      ActionTypes.URL,
      ActionTypes.OPEN_APP,
      ActionTypes.DISMISS
    ]
    if (isCardCampaign) {
      actionTypes = [
        ActionTypes.THIS_CARD,
        ActionTypes.DEEPLINK,
        ActionTypes.URL,
        ActionTypes.OPEN_APP
      ]
    }

    setGroupButtonsActionTypes(actionTypes)
  }, [isCardCampaign])

  if (!currentVariant) {
    return null
  }

  const { opened: openPicker, setOpened: setOpenPicker } =
    useCollapseContext() || {}

  function handleCheck(type: keyof PushFields): (check: boolean) => void {
    return (check: boolean) => {
      if (!currentVariant) {
        return
      }
      if (!isSent) {
        if (!check) {
          currentVariant.reset(type)
        } else {
          currentVariant.setActive(type, true)
        }
        currentVariant.validateField(type)
      }
    }
  }

  const pickerProps = (
    type: keyof PushFields,
    name: string | React.ReactNode | undefined
  ) => ({
    name,
    disabled: isSent,
    opened: openPicker === type,
    isValid: currentVariant.isFieldValid(type),
    setOpened: (open: boolean) => {
      setOpenPicker?.(open ? type : null)
    },
    checked: currentVariant.isFieldActive(type),
    setChecked: handleCheck(type)
  })

  return (
    <>
      <CollapseWithToggle {...pickerProps('title', 'Title')}>
        <Textarea
          key={`${currentVariantId}-title`}
          onChange={currentVariant.title.setValue}
          value={currentVariant.title.value}
          disabled={isSent}
          placeholder="Create a title..."
          mergeTags={shared.mergeTags}
          withoutLineBreaks
          onBlur={() => currentVariant.validateField('title')}
        />
      </CollapseWithToggle>
      <SeparatorLine spaceBottom="0" spaceTop="0" />
      <CollapseWithToggle
        {...pickerProps(
          'subTitle',
          <div className="d-flex align-item-center">
            Sub-Title <span className="ms-1 icon icon--ios-blue" />
          </div>
        )}>
        <Textarea
          key={`${currentVariantId}-subtitle`}
          onChange={currentVariant.subTitle.setValue}
          value={currentVariant.subTitle.value}
          disabled={isSent}
          placeholder="Add extra information..."
          mergeTags={shared.mergeTags}
          withoutLineBreaks
          onBlur={() => currentVariant.validateField('subTitle')}
        />
      </CollapseWithToggle>
      <SeparatorLine spaceBottom="0" spaceTop="0" />
      <CollapseWithToggle
        {...pickerProps(
          'notification',
          isCardCampaign ? 'Text' : 'Text & Notification Action'
        )}
        onlyCollapse>
        <Textarea
          key={`${currentVariantId}-notification`}
          onChange={currentVariant.notification.setValue}
          value={currentVariant.notification.value}
          invalid={!currentVariant.notification.isValid}
          required
          withoutLineBreaks
          disabled={isSent}
          onBlur={() => currentVariant.validateField('notification')}
          placeholder="This is your main notification..."
          mergeTags={shared.mergeTags}
        />
        {isCardCampaign ? (
          <p className="text-blue-gray text-13">
            Tapping this notification will show you the Feed Post.
          </p>
        ) : (
          currentVariant.notificationAction && (
            <>
              <CampaignCTAButton
                disabled={isSent}
                key={`${currentVariantId}-cta`}
                field={currentVariant.notificationAction}
                onlyNotificationAction
                deeplinks={shared.deeplinksOptions}
                types={[
                  ActionTypes.DEEPLINK,
                  ActionTypes.URL,
                  ActionTypes.OPEN_APP
                ]}
              />
              {withGoals && currentVariant.goals && (
                <div className="mb-3">
                  <Goals
                    disabled={isSent}
                    setGoal={currentVariant.goals.setGoal}
                    sourceType="notification"
                    selected={currentVariant.goals.selectedGoalTypes}
                    checkedGoalType={currentVariant.goals.isChecked({
                      sourceType: 'notification'
                    })}
                  />
                </div>
              )}
            </>
          )
        )}
      </CollapseWithToggle>
      <SeparatorLine spaceBottom="0" spaceTop="0" />
      {pushRichMedia && (
        <>
          <CollapseWithToggle {...pickerProps('richMedia', 'Rich Media')}>
            {!isSent && (
              <RichMedia
                key={`${currentVariantId}-richMedia`}
                onInternalFileChange={currentVariant.richMedia.addInternalFile}
                onExternalURLChange={currentVariant.richMedia.addExternalFile}
                onGiphyChange={currentVariant.richMedia.addGiphy}
                media={currentVariant.richMedia.value}
                fileName={currentVariant.richMedia.fileName}
                provider={currentVariant.richMedia.provider}
                changeProvider={currentVariant.richMedia.setProvider}
                fileType={currentVariant.richMedia.fileMimeType}
                withCrop={currentVariant.richMedia.previewFileType === 'image'}
              />
            )}
          </CollapseWithToggle>
          <SeparatorLine spaceBottom="0" spaceTop="0" />
        </>
      )}
      <CollapseWithToggle {...pickerProps('actionButtons', 'Action Buttons')}>
        <CampaignCTAGroupButton
          disabled={isSent}
          key={`${currentVariantId}-actionButtons`}
          deeplinks={shared.deeplinksOptions}
          categoryOptions={shared.buttonCategoriesOptions}
          eventsOptions={shared.eventsOptions}
          onSelect={(categoryId: string) => {
            const buttonCategory = shared.getButtonCategoryById(categoryId)
            currentVariant.actionButtons.selectGroup(buttonCategory)
            currentVariant.actionButtons.validateCategory()
            currentVariant.goals?.removeGoals('button')
          }}
          selected={currentVariant.actionButtons.buttonCategory.value}
          buttons={currentVariant.actionButtons.buttons}
          notificationActionTypes={groupButtonsActionTypes}
          withGoals={withGoals}
          notificationGoals={currentVariant.goals}
        />
      </CollapseWithToggle>
    </>
  )
}

export default withStore(PushVariantStep)
