import Block from './Block.model'
import {
  IBlock,
  IBlockExit,
  JourneyBlockType
} from '../Store/JourneyBuilder.interface'

export default class DumbBlock extends Block<void> {
  constructor(public parent: IBlock, blockType: JourneyBlockType) {
    super(blockType, parent)

    this.setReady()
  }

  public validateBlock(): boolean {
    return this.isValid
  }

  // eslint-disable-next-line class-methods-use-this
  public get filled(): boolean {
    return true
  }

  // eslint-disable-next-line class-methods-use-this
  protected getBlockPayload(): void {
    //
  }

  public getPayload(): IBlockExit {
    return {
      id: this.blockID,
      type: this.blockType,
      path: this.path
    }
  }

  // eslint-disable-next-line class-methods-use-this
  protected fillBlockPayload(): void {
    //
  }

  // eslint-disable-next-line class-methods-use-this
  protected getNodeData(): { label: string } & Record<string, any> {
    return { label: '' }
  }
}
