import React from 'react'
import { Range, getTrackBackground } from 'react-range'

import color from '~/utils/color'

import './RangeSlider.scss'

interface IProps {
  step?: number
  min?: number
  max?: number
  values: Array<number>
  onChange: (values: Array<number>) => void
  onFinalChange: (values: Array<number>) => void
}

const ThumbLine = ({ isDragged }: { isDragged: boolean }) => (
  <div
    className="range-slider__thumb-line"
    style={{
      backgroundColor: isDragged ? '#fff' : 'rgba(255,255,255, 0.4)'
    }}
  />
)

const RangeSlider = ({
  step,
  min,
  max,
  values,
  onChange,
  onFinalChange
}: IProps) => {
  return (
    <div className="range-slider">
      <Range
        step={step || 1}
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        onFinalChange={onFinalChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="range-slider__track-wrapper"
            style={{
              ...props.style
            }}>
            <div
              ref={props.ref}
              className="range-slider__track"
              style={{
                background: getTrackBackground({
                  values: values,
                  colors: [color.$fuchsiaPink, color.blueGray],
                  min: min,
                  max: max
                })
              }}>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            className="range-slider__thumb"
            style={{
              ...props.style
            }}>
            <ThumbLine isDragged={isDragged} />
            <ThumbLine isDragged={isDragged} />
            <ThumbLine isDragged={isDragged} />
            <ThumbLine isDragged={isDragged} />
          </div>
        )}
      />
    </div>
  )
}

export default RangeSlider
