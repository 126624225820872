import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import { Collapse } from 'reactstrap'

import ToggleSwitch from '~/components/forms/ToggleSwitch'

import { toggle, toggleDnd } from './style.scss'

interface IProps {
  name: string | React.ReactNode
  opened: boolean
  setOpened: (value: boolean) => void
  checked: boolean
  setChecked: (value: boolean) => void
  isValid: boolean
  disabled?: boolean
  className?: string
  onlyCollapse?: boolean
  dnd?: boolean
}

export default function CollapseWithToggle({
  name,
  opened,
  setOpened,
  checked,
  setChecked,
  children,
  isValid,
  disabled,
  className,
  onlyCollapse,
  dnd
}: PropsWithChildren<IProps>): React.ReactElement {
  function handleClickTitle(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation()

    if (!checked && !opened && !disabled) setChecked(true)
    setOpened(!opened)
  }

  function handleToggle() {
    setChecked(!checked)
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        data-testid="collapseTitle"
        onClick={handleClickTitle}
        className={cn(
          { 'error-label': !isValid && !disabled },
          'd-flex justify-content-between align-items-center text-capitalize',
          toggle,
          className
        )}>
        {dnd && <div className={toggleDnd} />}
        <div
          data-testid="collapseDrag"
          draggable
          className="w-100"
          onDragStart={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}>
          {name}
        </div>
        {!onlyCollapse && (
          <ToggleSwitch
            disabled={disabled}
            checked={checked}
            onChange={handleToggle}
          />
        )}
      </div>
      <Collapse isOpen={opened} data-testid="collapse">
        {children}
      </Collapse>
    </>
  )
}
