import moment from 'moment-timezone'
import {
  DAYS_OPTIONS,
  WEEK_DAYS_LIST,
  DAYS_LIST
} from '~/pages/CampaignBuilder/Email/consts'
import { ITimeWindow } from '~/dataStore/emailBuilder/EmailBuilder.interface'

export function getUTC(): moment.Moment {
  return moment().utc()
}

export function formatToUTC(
  date: Date | string | undefined | null,
  timeZoneName: string | undefined
): moment.Moment {
  if (!timeZoneName) return moment(date).utc()
  return moment(date).tz(timeZoneName, true).utc()
}

function formatTimeWindowToUTC(): (timeWindow: ITimeWindow) => ITimeWindow {
  return (timeWindow: ITimeWindow): ITimeWindow => {
    return {
      weekDay: timeWindow.weekDay,
      startAt: moment(timeWindow.startAt).format('HH:mm'),
      endAt: moment(timeWindow.endAt).format('HH:mm')
    }
  }
}

export function formatToTimeZone(
  date: Date | string | undefined,
  timeZoneName: string | undefined
): Date {
  if (!timeZoneName) return moment(date).toDate()
  return moment(
    moment.utc(date).tz(timeZoneName).format('YYYY-MM-DD HH:mm')
  ).toDate()
}

export function prepareDeliverTimeWindows(
  timeWindows: Array<ITimeWindow>
): ITimeWindow[] {
  if (timeWindows.some((elem) => elem.weekDay === DAYS_OPTIONS.ALL_DAYS)) {
    return DAYS_LIST.map((day) => ({
      weekDay: day,
      startAt: moment(timeWindows[0].startAt).format('HH:mm'),
      endAt: moment(timeWindows[0].endAt).format('HH:mm')
    }))
  }

  const weekDays = timeWindows.find(
    (elem) => elem.weekDay === DAYS_OPTIONS.MONDAY_FRIDAY
  )
  if (weekDays) {
    return [
      ...WEEK_DAYS_LIST.map((day) => ({
        weekDay: day,
        startAt: moment(weekDays.startAt).format('HH:mm'),
        endAt: moment(weekDays.endAt).format('HH:mm')
      })),
      ...timeWindows
        .filter((elem) => DAYS_LIST.includes(elem.weekDay))
        .map(formatTimeWindowToUTC())
    ]
  }

  return timeWindows.filter((elem) => elem.weekDay).map(formatTimeWindowToUTC())
}

export function hasSameValue(
  data: ITimeWindow[],
  key: 'startAt' | 'endAt'
): boolean {
  return (
    data?.length > 0 &&
    data.every((elem) => elem[key].getTime() === data[0][key].getTime())
  )
}

function hasSameStartTime(data: ITimeWindow[]): boolean {
  return hasSameValue(data, 'startAt')
}

function hasSameEndTime(data: ITimeWindow[]): boolean {
  return hasSameValue(data, 'endAt')
}

function hasAll(collection: ITimeWindow[], daysList: string[]): boolean {
  return (
    collection?.length > 0 &&
    daysList.every((day) =>
      collection.find((element) => element.weekDay === day)
    )
  )
}

export function hasAllDays(collection: ITimeWindow[]): boolean | undefined {
  return hasAll(collection, DAYS_LIST)
}

export function hasWeekDays(collection: ITimeWindow[]): boolean | undefined {
  return hasAll(collection, WEEK_DAYS_LIST)
}

export const unwrapDeliverTimeWindows = (
  timeWindows: Array<ITimeWindow>
): ITimeWindow[] => {
  // BE formats time to timezone in runtime
  const parsed = timeWindows.map((elem) => {
    return {
      weekDay: elem.weekDay,
      startAt: moment(elem.startAt, 'HH:mm').toDate(),
      endAt: moment(elem.endAt, 'HH:mm').toDate()
    }
  })

  if (hasAllDays(parsed)) {
    if (hasSameStartTime(parsed) && hasSameEndTime(parsed)) {
      return [{ ...parsed[0], weekDay: DAYS_OPTIONS.ALL_DAYS }]
    }
  }

  if (hasWeekDays(parsed)) {
    const weekDays = parsed.filter((elem) =>
      WEEK_DAYS_LIST.includes(elem.weekDay)
    )

    if (hasSameStartTime(weekDays) && hasSameEndTime(weekDays)) {
      return [
        { ...weekDays[0], weekDay: DAYS_OPTIONS.MONDAY_FRIDAY },
        ...parsed.filter((elem) => !WEEK_DAYS_LIST.includes(elem.weekDay))
      ]
    }
  }

  return parsed
}
