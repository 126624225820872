import { Row } from 'reactstrap'
import cn from 'classnames'
import NumberStat from '~/components/reports/NumberStat'
import { ISummary } from '~/dataStore/reports/Report.interface'

interface IProps {
  summaryNumbers: ISummary[]
  delivered: number | undefined
  sent: number | undefined
}

const CampaignSummaryNumbers = ({
  summaryNumbers,
  delivered,
  sent
}: IProps): React.ReactElement => {
  return (
    <Row>
      <NumberStat
        icon={<div className="icon icon--big icon--envelope" />}
        tooltip="Delivered versus Sent is the number of emails that were delivered to the recipient’s mail server along with the number of emails that were triggered from Pulsate."
        value={
          sent !== undefined && delivered !== undefined ? (
            <>
              {delivered?.toLocaleString() ?? '- -'}{' '}
              <span className="text-muted">
                / {sent?.toLocaleString() ?? '- -'}
              </span>
            </>
          ) : null
        }
        title="Delivered / Sent"
        col={{ xs: 12, xxxl: 6, widths: ['xs', 'xxxl'] }}
      />
      {summaryNumbers.map((summary) => (
        <NumberStat
          key={summary.label}
          icon={<div className={cn('icon icon--big', summary.icon)} />}
          tooltip={summary.tooltip}
          value={summary.rate?.toLocaleString()}
          title={summary.label}
          col={{ xs: 6, xxxl: 3, widths: ['xs', 'xxxl'] }}
        />
      ))}
    </Row>
  )
}

export default CampaignSummaryNumbers
