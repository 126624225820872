import { uniqueId } from 'lodash'
import React, { memo } from 'react'
import CustomDropdown from '~/components/CustomDropdown'
import MergeTagDefault from './MergeTagDefault'
import MergeTagLabel from './MergeTagLabel'
import { splitByMergeTag, splitMergeTag } from '~/utils/utilities'

interface IProps {
  text: string
  editable?: boolean
  disabled?: boolean
  onChange?: (value: string, index: number) => void
}

export default memo(function PreviewMergeTagText({
  text,
  editable,
  disabled,
  onChange
}: IProps): React.ReactElement | null {
  if (!text) {
    return null
  }

  const splittedByMergeTag = splitByMergeTag(text.trimEnd())

  return (
    <div key={uniqueId()}>
      {splittedByMergeTag.map((part, index) => {
        if (part.includes('{{')) {
          const mergeTag = splitMergeTag(part)
          const key = `${mergeTag.tag}-${index}`
          if (editable) {
            return (
              <span
                contentEditable={false}
                key={key}
                className="preview-tag-wrapper"
                onKeyDown={(e) => e.preventDefault()}>
                <CustomDropdown
                  opacityAnimation
                  // persist
                  id="dropdown-toggle"
                  disabled={disabled}
                  container="body"
                  dropdownToggle={
                    <MergeTagLabel
                      key={uniqueId()}
                      editable={editable}
                      mergeTag={mergeTag.tag}
                      defaultTag={mergeTag.default}
                    />
                  }
                  options={[
                    {
                      isCustom: true,
                      name() {
                        return (
                          <MergeTagDefault
                            mergeTag={mergeTag.tag}
                            defaultTag={mergeTag.default}
                            mergeTagIndex={index}
                            onChange={onChange}
                          />
                        )
                      }
                    }
                  ]}
                />
              </span>
            )
          }

          return (
            <MergeTagLabel
              key={uniqueId('merge')}
              editable={editable}
              mergeTag={mergeTag.tag}
              defaultTag={mergeTag.default}
            />
          )
        }

        return part.split(/(&nbsp;)/g).map((subpart) => {
          if (subpart === '&nbsp;' || !subpart.trim()) {
            if (!index)
              return (
                <React.Fragment key={uniqueId('space')}>&zwnj;</React.Fragment>
              )

            return (
              <React.Fragment key={uniqueId('space')}>&nbsp;</React.Fragment>
            )
          }

          return (
            <React.Fragment key={uniqueId('word')}>{subpart}</React.Fragment>
          )
        })
      })}
    </div>
  )
})
