import cn from 'classnames'
import Input from '~/components/forms/Input'
import { withStore } from '~/dataStore'
import { MatchTypes } from '../../SegmentBuilder.interface'
import styles from '../../SegmentBuilder.scss'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchTypeOptions: { name: string; value: MatchTypes }[] = [
  {
    name: 'equal to',
    value: 'equal'
  },
  {
    name: 'not equal to',
    value: 'not_equal'
  },
  {
    name: 'starts with',
    value: 'starts_with'
  },
  {
    name: 'ends with',
    value: 'ends_with'
  },
  {
    name: 'contains',
    value: 'contains'
  }
]

function RuleString({ rule }: IProps): React.ReactElement {
  return (
    <>
      <span aria-label="is">is</span>
      <RuleDropdown
        selected={
          matchTypeOptions.find(
            (option) => option.value === rule.value.matchType
          )?.name
        }
        options={matchTypeOptions}
        action={(value) => rule.edit({ matchType: value })}
        aria-label="Match Type"
      />
      <Input
        className={cn('mb-0', styles.ruleInput)}
        value={rule.value.matchValue}
        onChange={(e) => rule.edit({ matchValue: e.target.value })}
        name={`rule-numeric-matchValue-${rule.id}`}
        errorTooltip={rule.errors.matchValue}
        invalid={!!rule.errors.matchValue}
        aria-label="Match Value"
      />
    </>
  )
}

export default withStore(RuleString)
