import { action, makeObservable, observable, override, runInAction } from 'mobx'
import { getAncestorByType } from '~/pages/Journeys/Journey.service'
import { GoalType } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import { ID, TimeUnit } from '~/common.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import {
  IBlock,
  ISplitBlock,
  JourneyBlock,
  JourneyBlockType,
  SplitActionType,
  SplitSubType
} from '../Store/JourneyBuilder.interface'
import Block from './Block.model'
import Message from './Message'
import { capitalizeString } from '~/utils/string'
import {
  updateBlock,
  createBlock,
  removeBlock
} from '../../Connector/Journeys.connector'
import { IAppDetails } from '~/dataStore/App.interface'
import DumbBlock from './DumbBlock'
import { isEmpty } from '~/utils/utilities'

type SplitDTO = Partial<Pick<ISplitBlock, 'options'>> &
  Pick<ISplitBlock, 'conditions' | 'subType' | 'timeFrame' | 'timeValue'>
export default class Split extends Block<SplitDTO> {
  public options: ISplitBlock['options'] = []

  public conditions: NewRegisteredField<
    ISplitBlock['conditions']
  > = new NewRegisteredField([])

  public isDismissSelected = false

  public timeFrame: NewRegisteredField = new NewRegisteredField<TimeUnit>(
    'days'
  )

  public timeValue: NewRegisteredField = new NewRegisteredField<
    string | number
  >('')

  public subType: NewRegisteredField<
    ISplitBlock['subType']
  > = new NewRegisteredField(undefined)

  constructor(
    public parent: IBlock,
    public currentApp: Pick<IAppDetails, 'id'>,
    public getJourneyId: () => ID
  ) {
    super(JourneyBlockType.SPLIT, parent)

    makeObservable(this, {
      subType: observable,
      conditions: observable,
      options: observable,
      setSubType: action.bound,
      setCondition: action.bound,
      setTimeValue: action.bound,
      setTimeFrame: action.bound,
      isDismissSelected: observable,
      setDismiss: action.bound,
      childrenNodes: override,
      replaceOptionChildId: action.bound
    })
  }

  get filled(): boolean {
    if (
      !this.subType.value ||
      (this.subType.value === SplitSubType.GOALABLE &&
        !this.conditions.value.length)
    ) {
      return false
    }

    return true
  }

  public setDismiss(isChecked: boolean): void {
    this.isDismissSelected = isChecked
  }

  public get childrenNodes(): IBlock[] {
    const dismissChild = this.findDismissOption()

    if (!dismissChild?.childBlockId) {
      return this.children
    }

    return this.children.filter(
      (child) => child.blockID !== dismissChild.childBlockId
    )
  }

  public getBlockPayload(): SplitDTO {
    return {
      options: this.options,
      conditions: this.conditions.value,
      subType: this.subType.value,
      timeFrame:
        this.subType.value === SplitSubType.REACHABLE
          ? undefined
          : this.timeFrame.value,
      timeValue:
        this.subType.value === SplitSubType.REACHABLE
          ? undefined
          : this.timeValue.value
    }
  }

  protected fillBlockPayload(data: SplitDTO): void {
    if (!data.conditions) {
      this.conditions.setValue([])
    } else {
      //* Backward compatibility
      this.conditions.setValue(
        data.conditions.map((condition) => {
          if ('messageId' in condition && !isEmpty(condition.messageId)) {
            return {
              action: condition.action,
              resourceId: condition.messageId,
              resourceType:
                data.subType === SplitSubType.GOALABLE ? 'message' : 'journey'
            }
          }

          return condition
        })
      )
    }

    this.subType.setValue(data.subType)
    this.timeFrame.setValue(data.timeFrame || 'days')
    this.timeValue.setValue(data.timeValue || '')

    if (
      data.conditions?.some((condition) => condition.action === 'dismiss') &&
      data.options?.some((option) => option.type === 'dismiss')
    ) {
      this.setDismiss(true)
    }

    if (data.options) {
      this.options = data.options

      this.children.sort((child) => {
        const option = this.options.find(
          (o) => o.childBlockId === child.blockID
        )

        return option?.type === 'yes' ? -1 : 1
      })
    }

    this.setReady()
  }

  public validateBlock(): boolean {
    this.validateSubType()
    this.validateCondition()
    this.validateTimeValue()

    if (!this.isValid) {
      this.launchErrors = ['You must set split condition']
    }

    return this.isValid
  }

  private validateSubType(): void {
    if (!this.subType.validate()) {
      this.isValid = false
      this.errors.push('You must choose condition')
    }
  }

  private validateCondition(): void {
    if (this.subType.value === SplitSubType.GOALABLE) {
      if (!this.conditions.value.length) {
        this.conditions.isValid = false
        this.isValid = false
        this.errors.push('You must choose a goal')
      }
    }
  }

  private validateTimeValue(): void {
    if (this.subType.value && this.subType.value !== SplitSubType.REACHABLE) {
      const value = Number(this.timeValue.value)
      if (!this.timeValue.validate()) {
        this.isValid = false
        this.errors.push('Enter delay time')
      } else if (value < 1 || !Number.isInteger(value)) {
        this.timeValue.isValid = false
        this.isValid = false
        this.errors.push('Enter a whole number greater than 0')
      } else if (value > 99) {
        this.timeValue.isValid = false
        this.isValid = false
        this.errors.push('Enter a whole number below 100')
      }
    }
  }

  public resetError(): void {
    super.resetError()
    this.subType.resetError()
    this.conditions.resetError()
    this.timeValue.resetError()
  }

  protected getNodeData(): { label: string; goalType?: GoalType } {
    switch (this.subType.value) {
      case SplitSubType.REACHABLE:
        return { label: 'Message Sent' }
      case SplitSubType.GOALABLE:
        return {
          label: `Goal Completed by clicking in ${
            this.getSelectedCondition()
              ? capitalizeString(this.getSelectedCondition())
              : ''
          } Goal`,
          goalType: this.getSelectedCondition()
        }
      case SplitSubType.INTEGRATIONABLE:
        return {
          label: `Integration Goal Completed`
        }
      default:
        return { label: 'Choose split conditions' }
    }
  }

  public setSubType(type: SplitSubType): void {
    this.subType.setValue(type)

    this.resetConditions()
    this.setTimeValue('')

    if (type === SplitSubType.INTEGRATIONABLE) {
      this.setCondition(undefined, this.getJourneyId())
    }

    if (type) {
      this.resetError()
      this.validateSubType()
    }
  }

  public setTimeValue(value: string): void {
    this.timeValue.setValue(value)

    const newValue = Number(value)
    if (newValue > 0 && newValue < 100 && Number.isInteger(newValue)) {
      this.resetError()
      this.validateTimeValue()
    }
  }

  public setTimeFrame(value: TimeUnit): void {
    this.timeFrame.setValue(value)
  }

  public setCondition(
    condition?: SplitActionType,
    integrationKey?: string
  ): void {
    switch (this.subType.value) {
      case SplitSubType.GOALABLE:
        if (!condition) {
          return
        }
        this.conditions.setValue([
          {
            action: condition,
            resourceId: this.getParentMessageBlockId(),
            resourceType: 'message'
          }
        ])
        break
      case SplitSubType.INTEGRATIONABLE:
        if (!integrationKey) {
          return
        }
        this.conditions.setValue([
          {
            action: integrationKey,
            resourceId: this.getJourneyId(),
            resourceType: 'journey'
          }
        ])
        break
      default:
        this.resetConditions()
    }

    if (condition || integrationKey) {
      this.resetError()
      this.validateCondition()
    }
  }

  public notify(
    type: 'currentGoals',
    notificationGoals: SplitActionType[]
  ): void {
    if (
      this.subType.value === SplitSubType.GOALABLE &&
      !this.conditions.value.some((condition) =>
        notificationGoals.includes(condition.action)
      )
    ) {
      this.resetConditions()
    }
  }

  public getSelectedCondition(): GoalType | undefined {
    //* TS 4.9 check satisfy operator
    return this.conditions.value.find(
      (condition): condition is { messageId: ID; action: GoalType } =>
        condition.action !== 'dismiss'
    )?.action
  }

  private resetConditions(): void {
    this.conditions.setValue([])
  }

  public getGoalOptions(): GoalType[] {
    const messageBlock = this.getParentMessageBlock()

    if (!messageBlock) {
      return []
    }

    return messageBlock.getGoalTypes()
  }

  public replaceOptionChildId(oldId: ID, newId: ID): void {
    const option = this.options.find((o) => o.childBlockId === oldId)
    if (option) {
      option.childBlockId = newId
    }
  }

  public getParentMessageBlockId(): ID {
    return this.getParentMessageBlock().id
  }

  private getParentMessageBlock(): Message {
    const messageBlock = getAncestorByType<Message>(
      JourneyBlockType.MESSAGE,
      this.parent
    )

    if (!messageBlock) {
      throw new Error('There must be a message before split block')
    }

    return messageBlock
  }

  private async addDismissToPayload(): Promise<ISplitBlock> {
    let payload = this.getPayload() as ISplitBlock

    if (!this.blockID) {
      return payload
    }

    const dismissOption = this.findDismissOption()
    if (
      !dismissOption ||
      !this.children.some(
        (child) => child.blockID === dismissOption.childBlockId
      )
    ) {
      const response = await createBlock(
        this.currentApp.id,
        this.getJourneyId(),
        {
          parentId: this.blockID,
          childBlockId: undefined,
          type: JourneyBlockType.EXIT
        }
      )

      const exitBlock = new DumbBlock(this, JourneyBlockType.EXIT)
      exitBlock.fillBlock(response)
      this.setChildren([...this.childrenNodes, exitBlock])

      payload = {
        ...payload,
        childBlocks: [
          ...payload.childBlocks,
          { id: response.id, type: JourneyBlockType.EXIT }
        ],
        options: [
          ...this.options.filter((option) => option.type !== 'dismiss'),
          { type: 'dismiss', childBlockId: response.id }
        ]
      }
    }

    if (
      this.conditions.value.every((condition) => condition.action !== 'dismiss')
    ) {
      const messageBlockId = this.getParentMessageBlockId()
      payload = {
        ...payload,
        conditions: [
          ...this.conditions.value,
          {
            action: 'dismiss',
            resourceId: messageBlockId,
            resourceType: 'message'
          }
        ]
      }
    }

    return payload
  }

  private async removeDismissChild(): Promise<ISplitBlock> {
    const payload = this.getPayload() as ISplitBlock
    if (!this.blockID) {
      return payload
    }

    const dismissChild = this.findDismissOption()

    if (
      dismissChild &&
      this.children.some((child) => child.blockID === dismissChild.childBlockId)
    ) {
      await removeBlock(
        this.currentApp.id,
        this.getJourneyId(),
        dismissChild.childBlockId
      )
    }

    this.setChildren(this.childrenNodes)

    return {
      ...payload,
      childBlocks: payload.childBlocks.filter(
        (child) => child.id !== dismissChild?.childBlockId
      ),
      conditions: this.conditions.value.filter(
        (condition) => condition.action !== 'dismiss'
      ),
      options: this.options.filter((option) => option.type !== 'dismiss')
    }
  }

  protected async updateBlock(
    appId: ID,
    journeyId: ID,
    blockId: ID
  ): Promise<JourneyBlock> {
    let payload: JourneyBlock
    if (
      this.isDismissSelected &&
      this.subType.value === SplitSubType.GOALABLE
    ) {
      payload = await this.addDismissToPayload()
    } else {
      payload = await this.removeDismissChild()
    }

    try {
      const response = await updateBlock(appId, journeyId, blockId, payload)
      runInAction(() => {
        this.fillBlock(response)
      })
      return response
    } catch (error) {
      const dismissChild = this.findDismissOption(payload.options)
      if (dismissChild) {
        await removeBlock(appId, journeyId, dismissChild.childBlockId)
        this.setChildren(
          this.children.filter(
            (child) => child.id !== dismissChild.childBlockId
          )
        )
      }
      throw error
    }
  }

  private findDismissOption(options = this.options) {
    return options.find(
      (option): option is { type: 'dismiss'; childBlockId: ID } =>
        option.type === 'dismiss'
    )
  }
}
