import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { isString } from '~/utils/utilities'

export default function ColWithDiff<
  T extends { [x in keyof T]: string | number | boolean | undefined }
>(
  fieldName: keyof T,
  diffFieldName: keyof T,
  colorDiff = true
): React.FunctionComponent<{ row: T }> {
  return function ConversionRateCol({ row }: { row: T }): React.ReactElement {
    const classes = ['ms-2 percentage-rate']
    let diff = ''
    const diffField = row[diffFieldName]
    if (isString(diffField)) {
      diff = diffField
    }
    if (colorDiff && diffField && !['--', '- -'].includes(diff)) {
      classes.push(
        diff[0] === '-' ? 'percentage-rate--red' : 'percentage-rate--green'
      )
    }

    return (
      <div>
        {typeof row[fieldName] === 'undefined' ? (
          <Skeleton />
        ) : (
          <>
            {row[fieldName]}
            {diff && (
              <span className={classes.join(' ')}>
                (<span className="percentage-rate__arrow" />
                {diff})
              </span>
            )}
          </>
        )}
      </div>
    )
  }
}
