import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'
import cn from 'classnames'
import ReduxToastr from 'react-redux-toastr'
import { Provider } from 'react-redux'
import { runInAction } from 'mobx'
import {
  BrowserRouter,
  Route,
  useParams,
  matchPath,
  useLocation,
  useHistory
} from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import '~/assets/css/index.scss'

import AppManager from '~/pages/App/Manager'
import AccountSettings from '~/pages/AccountSettings'
import NewAccountRegistration from '~/pages/Auth/NewAccountRegistration'
import NewAdminRegistration from '~/pages/Auth/NewAdminRegistration'
import SignIn from '~/pages/Auth/SignIn'
import AuthPageLayout from '~/pages/Auth/components/AuthPageLayout'
import ForgotPassword from '~/pages/Auth/ForgotPassword'
import ChangePassword from '~/pages/Auth/ChangePassword'

import routes from '~/routes'

import Navbar from '~/components/Navbar'
import Sidebar from '~/components/Sidebar'
import Modal from '~/components/modals/Modal'
import { withStore, store } from '~/dataStore'
import { hideSplashScreen, showSplashScreen } from '~/utils/splashScreen'
import { store as reduxStore } from '~/redux/store/index'

import { background, backgroundGray } from './style.scss'
import Router, { ScrollToTop } from '~/Router'
import PEMAlert from '~/PEMAlert'
import HintWindow from '~/HintWindow/HintWindow'
import injestWebSDK from './injectWebSdk'
import { showGeneralError } from '~/utils/validations'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000
    }
  }
})

function Layout({ children }) {
  const {
    app: {
      loaded,
      getAppDetails,
      getSessionData,
      openCurtain,
      appDetails,
      currentAdmin,
      currentApp
    }
  } = store
  const [isLogged, setIsLogged] = useState(false)
  const { appId } = useParams()

  const location = useLocation()

  const isEmailCampaignBuilderRoute = () =>
    !!matchPath(location?.pathname, {
      path: [routes.campaignBuilder.path, routes.journeysBuilder.path],
      exact: true
    })

  const isJourneyBuilderRoute = () =>
    !!matchPath(location?.pathname, {
      path: [routes.journeysBuilder.path],
      exact: true
    })

  const isOpportunitiesDashboardRoute = () =>
    !!matchPath(location?.pathname, {
      path: [routes.opportunitiesDashboard.path],
      exact: true
    })

  useEffect(() => {
    if (!window.PulsateSDK) {
      injestWebSDK()
      window.PulsateSDK.setErrorHandler(() => showGeneralError())
    }

    return () => {
      if (appDetails.featureFlags.webSdk) {
        try {
          window.PulsateSDK?.endSession()
        } catch (error) {
          console.error(error)
        }
      }
    }
  }, [appDetails.featureFlags.webSdk])

  useEffect(() => {
    if (loaded === 0) {
      hideSplashScreen()
    }

    if (loaded > 0) {
      showSplashScreen()
    }
  }, [loaded])

  useEffect(() => {
    getSessionData().then((data) => {
      openCurtain()
      setIsLogged(true)
      getAppDetails(data.recentMobileAppId)
      sessionStorage.setItem('active-session', 'true')
    })
  }, [appId, getAppDetails, getSessionData, openCurtain])

  useEffect(() => {
    if (currentAdmin.id && currentApp.id && appDetails.name && window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: currentAdmin.id,
          email: currentAdmin.email,
          firstName: currentAdmin.name
        },

        account: {
          id: currentApp.id,
          accountName: appDetails.name
        }
      })
    }
  }, [currentAdmin, currentApp, appDetails])

  if (!isLogged) return null
  return (
    <>
      <ScrollToTop />
      <Provider store={reduxStore}>
        <QueryClientProvider client={queryClient}>
          <div
            className={cn('spark-root', 'position-relative', {
              'navbar-3-columns':
                isEmailCampaignBuilderRoute() || isJourneyBuilderRoute()
            })}>
            <div className={cn('wrapper')} style={{ overflow: 'initial' }}>
              <Sidebar newLayout />
              <div
                className={cn('main', 'position-relative', {
                  [backgroundGray]: isOpportunitiesDashboardRoute(),
                  [background]: !isOpportunitiesDashboardRoute()
                })}>
                <Navbar />
                <div
                  className={cn('content', {
                    'no-space': isJourneyBuilderRoute(),
                    'desktop-wider-space': isOpportunitiesDashboardRoute()
                  })}>
                  {children}
                </div>
              </div>
              <ReactQueryDevtools initialIsOpen={false} />
              <ReduxToastr
                timeOut={5000}
                preventDuplicates
                newestOnTop
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
            </div>
          </div>
          <Modal container="root" />
        </QueryClientProvider>
      </Provider>
      <PEMAlert />
      <HintWindow />
    </>
  )
}

function AuthorizationPage({ children }) {
  useEffect(() => {
    hideSplashScreen()
  }, [])

  return (
    <Provider store={reduxStore}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NODE_GCKEY}
        useEnterprise>
        <AuthPageLayout>{children}</AuthPageLayout>
        <ReduxToastr
          timeOut={5000}
          preventDuplicates
          newestOnTop
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </GoogleReCaptchaProvider>
    </Provider>
  )
}

const AuthorizedAppLayout = withStore(Layout)
const AuthorizationPageLayout = withStore(AuthorizationPage)

const RootApp = () => {
  const history = useHistory()

  useEffect(() => {
    runInAction(() => {
      store.history = history
    })

    return () => {
      runInAction(() => {
        store.history = undefined
      })
    }
  }, [history])

  if (!store.history) return null

  return (
    <>
      <Route path="/mobile/apps/:appId">
        <AuthorizedAppLayout>
          <Router />
        </AuthorizedAppLayout>
      </Route>
      <Route exact path={['/mobile/apps', '/']}>
        <AuthorizedAppLayout>
          <AppManager />
        </AuthorizedAppLayout>
      </Route>
      <Route exact path="/admins/edit">
        <AuthorizedAppLayout>
          <AccountSettings />
        </AuthorizedAppLayout>
      </Route>
      <Route exact path="/admins/registration/:token">
        <AuthorizationPageLayout>
          <NewAdminRegistration />
        </AuthorizationPageLayout>
      </Route>
      <Route exact path="/admins/forgot_password">
        <AuthorizationPageLayout>
          <ForgotPassword />
        </AuthorizationPageLayout>
      </Route>
      <Route exact path="/admins/edit_password/:token">
        <AuthorizationPageLayout>
          <ChangePassword />
        </AuthorizationPageLayout>
      </Route>

      <Route exact path="/admins/company_registration">
        <AuthorizationPageLayout>
          <NewAccountRegistration />
        </AuthorizationPageLayout>
      </Route>
      <Route exact path="/admins/sign_in">
        <AuthorizationPageLayout>
          <SignIn />
        </AuthorizationPageLayout>
      </Route>
    </>
  )
}

const RootAppWithStore = withStore(RootApp)

const RootAppWithHistory = () => {
  return (
    <BrowserRouter>
      <RootAppWithStore />
    </BrowserRouter>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<RootAppWithHistory />)
