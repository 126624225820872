import React, { useRef } from 'react'
import moment from 'moment-timezone'
import cn from 'classnames'
import { uniqueId } from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'
import expiringIcon from '~/components/icons/clock-expiring.svg'
import warningTriangleIcon from '~/components/icons/warning_triangle.svg'

const PemExpiryColumn = ({
  pemData
}: {
  pemData: {
    pemExpired: boolean
    pemAboutToExpire: boolean
    pemExpiredAt: string | null
    pemErrors: unknown
  }
}): React.ReactElement => {
  const { pemExpired, pemExpiredAt, pemAboutToExpire } = pemData
  const id = useRef(uniqueId('expiry'))

  const dateFormatted = useRef(
    moment(pemData?.pemExpiredAt).utc().format('DD MMM YYYY')
  )

  if (!pemExpiredAt) return <>--</>

  return (
    <div
      className={cn('d-flex align-items-center', {
        'text-danger': pemExpired,
        'text-warning': pemAboutToExpire
      })}
      id={id.current}>
      {dateFormatted.current}
      {pemAboutToExpire && (
        <>
          <img
            src={expiringIcon}
            alt={`PEM Certificate will expire on ${dateFormatted.current}`}
            className="ms-2"
          />{' '}
          <UncontrolledTooltip target={id.current} placement="left">
            PEM Certificate will expire on {dateFormatted.current}
          </UncontrolledTooltip>
        </>
      )}
      {pemExpired && (
        <>
          <img
            src={warningTriangleIcon}
            alt={`PEM Certificate expired on ${dateFormatted.current}`}
            className="ms-2"
          />{' '}
          <UncontrolledTooltip target={id.current} placement="left">
            PEM Certificate expired on {dateFormatted.current}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  )
}

export default PemExpiryColumn
