import {
  IChartData,
  IGoalConversion,
  IGraphData,
  ISummary,
  ISummaryRate
} from '~/dataStore/reports/Report.interface'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'
import { IMetaData } from '~/common.interface'

export type Actions = {
  details: string
  pause: string
  resume: string
  edit: string
  stop: string
  delete: string
  exportData: string
  duplicate: string
}

type IdString = string
export interface ICampaignsDashboardSummaryStatsPayload {
  unsubscribed: { total: number }
  subscribed: { total: number }
  sent: { total: number }
  open: IReportPayload
  click: IReportPayload
  bounce: Pick<IReportPayload, 'uniqRate' | 'uniqTotalGraph' | 'uniqGraph'>
  delivery: Pick<
    IReportPayload,
    | 'uniqWeekDayGraph'
    | 'uniqHourGraph'
    | 'uniqDayGraph'
    | 'uniqGraph'
    | 'uniqRate'
    | 'total'
  >
  primaryGoal: Pick<
    IReportPayload,
    'uniqRate' | 'uniqRateGraph' | 'uniqRateDayGraph' | 'uniqCampaignsGraph'
  >
  secondaryGoal: Pick<
    IReportPayload,
    'uniqRate' | 'uniqRateGraph' | 'uniqRateDayGraph' | 'uniqCampaignsGraph'
  >
}

interface IReportPayload {
  uniqRate: number
  total: number
  uniqGraph: IGraphData[]
  uniqRateGraph: IGraphData[]
  uniqTotalGraph: IGraphData[]
  uniqRateDayGraph: IGraphData[]
  uniqRateHourGraph: IGraphData[]
  uniqDayGraph: IGraphData[]
  uniqWeekDayGraph: IGraphData[]
  uniqHourGraph: IGraphData[]
  uniqRateWeekDayGraph: IGraphData[]
  uniqCampaignsGraph: IGraphData[]
}

export interface ICampaignsDashboardListPayload {
  data: ISingleCampaignStatsPayload[]
  metadata: IMetaData[]
}

interface ISingleCampaignStatsPayload {
  id: IdString
  name: string
  status: string
  sentCount: number
  uniqOpensCount: number
  uniqOpenRate: number
  primaryConversionRate: number
  secondaryConversionRate: number
}

export interface IStoreModel {
  report: ICampaignsDashboardSummaryStatsPayload | undefined
  campaigns: ICampaign[] | undefined
  campaignsMetadata: IMetaData | undefined
  summaryRates: ISummaryRate[]
  summaryTotalNumbers: ISummary[]
  dailyEventsRateStats: IChartData[]
  statsByWeekDay: IChartData[]
  statsByHour: IChartData[]
  dailyGoalsPerformanceStats: IGoalConversion[]
  summaryGoalsRates: IChartData[]
  eventsRateStatsByCampaign: IChartData[]
  filterResources: IFilterResources | undefined
}

export interface ICampaign {
  id: string
  name: string
  status: CampaignStatus
  sentCount: string
  uniqOpensCount: string
  uniqOpenRate: string
  uniqClickRate: string
  primaryConversionRate: string
  secondaryConversionRate: string
  actions: Actions
}

export interface IFilterResources {
  campaigns: Array<{ id: string; name: string }>
  groups: Array<{ id: string; name: string }>
}

export enum RateType {
  UNIQ = 'uniqRate',
  UNIQ_OPEN = 'uniqOpenRate',
  UNIQ_CLICK = 'uniqClickRate',
  PRIMARY_CONVERSION = 'primaryConversionRate',
  SECONDARY_CONVERSION = 'secondaryConversionRate'
}

export enum FilterAction {
  CAMPAIGN_CHARTS = 'campaignsCharts',
  CAMPAIGN_LIST = 'campaignsList'
}

export interface ICampaignsDTO {
  data: ICampaign[]
  metadata: IMetaData
}

export interface ICampaignsFetchQueryParams {
  groupIds?: string
  campaignIds?: string
  ongoing: string
  page?: number
  order?: 'asc' | 'desc'
  sort?: string
}
