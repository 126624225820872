import { makeAutoObservable, runInAction } from 'mobx'
import {
  FilterAction,
  ICampaign,
  ICampaignsDashboardSummaryStatsPayload,
  ICampaignsDTO,
  ICampaignsFetchQueryParams,
  IFilterResources,
  IStoreModel,
  RateType
} from './CampaignsDashboard.interface'
import { IMetaData } from '~/common.interface'
import {
  fetchCampaigns,
  fetchDashboardEmailStatistics,
  fetchFilterResources
} from './CampaignsDashboard.connector'
import {
  IChartData,
  ChartDataType,
  DataChartType,
  IGoalConversion,
  IGraphData,
  ISummary,
  ISummaryRate
} from '../../CampaignReports/Model/Report.interface'

export default class CampaignDashboardStore implements IStoreModel {
  report: ICampaignsDashboardSummaryStatsPayload | undefined = undefined

  campaigns: ICampaign[] | undefined = undefined

  campaignsRates: ICampaign[] | undefined = undefined

  campaignsMetadata: IMetaData | undefined = undefined

  filterResources: IFilterResources | undefined = undefined

  campaignsFetching = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  get dailyGoalsPerformanceStats(): IGoalConversion[] {
    return [
      {
        title: 'Primary Goal Conversion Rate',
        label: 'Conversion Rate',
        value: this.report?.primaryGoal.uniqRate,
        dataType: ChartDataType.NUMERIC,
        tooltip:
          'The average of the primary goal conversion rate of all the campaigns that were delivered or active (Active should be checked in the status filter) during the selected period'
      },
      {
        title: 'Secondary Goal Conversion Rate',
        label: 'Conversion Rate',
        value: this.report?.secondaryGoal.uniqRate,
        tooltip:
          'The average of the secondary goal conversion rate of all the campaigns that have a secondary goal and were delivered or active (Active should be checked in the status filter) during the selected period'
      }
    ]
  }

  get summaryGoalsRates(): IChartData[] {
    return [
      {
        name: 'Primary Conversion Rate',
        data: this.report?.primaryGoal.uniqRateDayGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Secondary Conversion Rate',
        data: this.report?.secondaryGoal.uniqRateDayGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      }
    ]
  }

  get summaryTotalNumbers(): ISummary[] {
    return [
      {
        label: 'Subscribers',
        rate: this.report?.subscribed?.total,
        tooltip:
          'Subscribers are the total number of customers/members who have opted for Email Marketing.',
        icon: 'icon--sent'
      },
      {
        label: 'Unsubscribed',
        rate: this.report?.unsubscribed?.total,
        tooltip:
          'Unsubscribed indicates the total number of customers/members who have opted out of Email Marketing.',
        icon: 'icon--unsubscribe'
      }
    ]
  }

  get statsByWeekDay(): IChartData[] {
    return [
      {
        name: 'Total Delivery',
        data: this.report?.delivery.uniqWeekDayGraph || [],
        dataType: ChartDataType.NUMERIC,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Unique Open Rate',
        data: this.report?.open.uniqRateWeekDayGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Unique Click Rate',
        data: this.report?.click.uniqRateWeekDayGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      }
    ]
  }

  get statsByHour(): IChartData[] {
    return [
      {
        name: 'Total Delivery',
        data: this.report?.delivery.uniqHourGraph || [],
        dataType: ChartDataType.NUMERIC,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Unique Open Rate',
        data: this.report?.open.uniqRateHourGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        valueCurrent: ''
      },
      {
        name: 'Unique Click Rate',
        data: this.report?.click.uniqRateHourGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        valueCurrent: ''
      }
    ]
  }

  get dailyEventsRateStats(): IChartData[] {
    return [
      {
        name: 'Total Delivery',
        data: this.report?.delivery.uniqDayGraph || [],
        dataType: ChartDataType.NUMERIC,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Unique Open Rate',
        data: this.report?.open.uniqRateDayGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        valueCurrent: ''
      },
      {
        name: 'Unique Click Rate',
        data: this.report?.click.uniqRateDayGraph || [],
        dataType: ChartDataType.PERCENTAGE,
        valueCurrent: ''
      }
    ]
  }

  get summaryRates(): ISummaryRate[] {
    if (!this.report) return [{}, {}, {}, {}]
    const { open, click, bounce, delivery } = this.report

    return [
      {
        label: 'Unique Open Rate',
        chartData: open.uniqGraph,
        rate: open.uniqRate !== null ? `${open.uniqRate}%` : '- -',
        tooltip:
          'The Unique Open Rate is defined as the ratio of number of unique email opens to the total number of emails delivered. Expressed in percentage. Formula: (Total unique emails opens / Total delivered emails)*100'
      },
      {
        label: 'Unique Click Rate',
        chartData: click.uniqGraph,
        rate: click.uniqRate !== null ? `${click.uniqRate}%` : '- -',
        tooltip:
          'The Unique Click Rate is defined as the ratio of number of unique clicks to the total number of emails delivered expressed in percentage. Formula: (Total unique emails clicks / Total delivered emails)*100'
      },
      {
        label: 'Bounce Rate',
        chartData: bounce.uniqGraph,
        rate: bounce.uniqRate !== null ? `${bounce.uniqRate}%` : '- -',
        tooltip:
          'The Bounce Rate is defined as the ratio of number of bounced emails to the total number of emails sent expressed in percentage. Formula: (Total bounced emails / Total sent emails)*100'
      },
      {
        label: 'Delivery Rate',
        chartData: delivery.uniqGraph,
        rate: delivery.uniqRate !== null ? `${delivery.uniqRate}%` : '- -',
        tooltip:
          'The Delivery Rate is defined as the ratio of number of delivered emails to the total number of emails sent expressed in percentage. Formula: (Total delivered emails / Total sent emails)*100'
      }
    ]
  }

  get eventsRateStatsByCampaign(): Array<{
    name: string
    data: IGraphData[]
    dataType: ChartDataType
    dataChartType: DataChartType
    valueCurrent: string | number
  }> {
    const campaignsToChartSerieByRate = (
      rateName:
        | RateType.UNIQ_OPEN
        | RateType.UNIQ_CLICK
        | RateType.PRIMARY_CONVERSION
        | RateType.SECONDARY_CONVERSION
    ) =>
      this.campaignsRates?.map((campaign: ICampaign) => ({
        x: campaign.name,
        y: campaign[rateName]
      }))

    return [
      {
        name: 'Unique Open Rate',
        data: campaignsToChartSerieByRate(RateType.UNIQ_OPEN) || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Unique Click Rate',
        data: campaignsToChartSerieByRate(RateType.UNIQ_CLICK) || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Primary Conversion Rate',
        data: campaignsToChartSerieByRate(RateType.PRIMARY_CONVERSION) || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      },
      {
        name: 'Secondary Conversion Rate',
        data: campaignsToChartSerieByRate(RateType.SECONDARY_CONVERSION) || [],
        dataType: ChartDataType.PERCENTAGE,
        dataChartType: DataChartType.COLUMN,
        valueCurrent: ''
      }
    ]
  }

  public async fetchReport(
    appId: string,
    params: ICampaignsFetchQueryParams,
    abortSignal: AbortSignal
  ): Promise<void> {
    this.setReport(undefined)

    try {
      const response = await fetchDashboardEmailStatistics(
        appId,
        params,
        abortSignal
      )
      this.setReport(response)
    } catch (error) {
      console.error(error)
    }
  }

  private setReport(
    report: ICampaignsDashboardSummaryStatsPayload | undefined
  ): void {
    this.report = report
  }

  public populateCampaigns({
    response,
    params
  }: {
    response: ICampaignsDTO
    params: ICampaignsFetchQueryParams
  }): void {
    if (params?.page === 1) {
      this.campaigns = response.data
    } else {
      this.campaigns = this.campaigns?.concat(response.data)
    }
    this.campaignsMetadata = response.metadata
  }

  public async fetchCampaigns(
    appId: string,
    params: ICampaignsFetchQueryParams,
    filterAction: FilterAction | undefined,
    abortSignal?: AbortSignal
  ): Promise<void> {
    try {
      this.campaignsFetching = true
      const response = await fetchCampaigns(appId, params, abortSignal)

      if (filterAction !== FilterAction.CAMPAIGN_LIST) {
        runInAction(() => {
          this.campaignsRates = response.data.slice(0, 8)
        })
      }

      this.populateCampaigns({ response, params })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      runInAction(() => {
        this.campaignsFetching = false
      })
    }
  }

  public async fetchFilterResources(
    appId: string,
    params?: { ongoing: string },
    abortSignal?: AbortSignal
  ): Promise<void> {
    try {
      const response = await fetchFilterResources(appId, params, abortSignal)
      this.setFilers(response)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  private setFilers(filters: IFilterResources): void {
    this.filterResources = filters
  }
}
