import React from 'react'
import { useStore, withStore } from '~/dataStore'
import TimeFrameForm from '~/components/forms/TimeFrameForm'
import Section from '~/pages/CampaignBuilder/DeliveryStep/Panels/components/Section'
import Panel from '~/components/forms/Panel/Panel'

const CampaignDeliveryLimits = () => {
  const {
    campaign: {
      isSended,
      deliver: {
        limits,
        setLimits,
        validateCampaignLimits,
        setTimeFrame,
        setTimeValue,
        timeValue,
        timeFrame,
        deliverUi: { isStartPanelCollapsed, isLimitsPanelBodyCollapsed }
      }
    }
  } = useStore()

  const onToggleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimits(e.target.value)
    validateCampaignLimits()
  }

  return (
    <Panel
      className="bg-light"
      disabled={isSended}
      isCollapsed={isLimitsPanelBodyCollapsed}
      onChange={onToggleSwitch}
      separator={isStartPanelCollapsed}
      title="How often should users receive it?"
      minHeight={87}
      selected={`${limits.value}`}
      switchLabels={[
        {
          label: 'One Time',
          value: 'false',
          width: 175,
          name: 'campaignLimits',
          tooltipText:
            'The user will receive the notification one time if they meet the criteria and enter the segment'
        },
        {
          value: 'true',
          name: 'campaignLimits',
          label: 'Multiple Times',
          width: 175,
          tooltipText:
            'The user must enter the segment, exit the segment, and re-enter the segment to receive the notification more than one time'
        }
      ]}>
      <Section>
        <TimeFrameForm
          disabled={isSended}
          onTimeValueChange={setTimeValue}
          onTimeFrameChange={setTimeFrame}
          initialTimeFrame={timeFrame.value || 'Week'}
          initialTimeValue={timeValue.value}
          timeValueInvalid={!timeValue.isValid}
          timeLabel="Send this campaign to a user no more than once per"
          inputType="number"
          units={
            process.env.NODE_CUSTOM_ENV === 'production'
              ? ['days', 'weeks', 'months', 'years']
              : ['days', 'weeks', 'months', 'years', 'minutes']
          }
        />
      </Section>
    </Panel>
  )
}

export default withStore(CampaignDeliveryLimits)
