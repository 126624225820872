import { PropsWithChildren, ReactElement } from 'react'

interface IProps {
  icon?: React.ReactNode
  header?: string
  subHeader?: string
}

const NoticeBarHeader = ({
  children,
  icon,
  header,
  subHeader
}: PropsWithChildren<IProps>): ReactElement => {
  return (
    <div className="d-flex align-items-center">
      {icon && <div className="me-4 pe-2">{icon}</div>}
      <div>
        {children || (
          <>
            <h2 className="text-white">{header}</h2>
            <p className="mb-0 text-base">{subHeader}</p>
          </>
        )}
      </div>
    </div>
  )
}

export default NoticeBarHeader
