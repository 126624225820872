import {
  faBullhorn,
  faBullseye,
  faChartArea,
  faChartLine,
  faComments,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import React from 'react'

import { generatePath } from 'react-router-dom'
import defaultUserAvatar from '~/assets/img/default-user-avatar.svg'
import pulsateLogo from '~/assets/img/pulsate_logo_blue.svg'
import Img from '~/components/Img'
import Link from '~/components/Link'
import journeysIcon from '~/components/icons/journeys.svg'
import { storeContext, withStore } from '~/dataStore'
import routes from '~/routes'

import Footer from './Footer'
import SidebarCategory from './SidebarCategory'
import SidebarItem from './SidebarItem'
import SimpleCategory from './SimpleCategory'
import style from './style.scss'

type PathParam = {
  appId: string
  campaignType: string
}

const menuLinks = [
  {
    name: 'Opportunities',
    icon: faChartLine,
    badgeText: 'NEW',
    badgeColor: 'info',
    path: ({ appId }: PathParam) =>
      appId && { route: routes.opportunitiesDashboard, params: { appId } }
  },
  {
    name: 'Analyze',
    icon: faChartArea,
    children: [
      {
        name: 'Dashboard',
        path: ({ appId }: PathParam) =>
          appId && {
            route: routes.dashboard,
            params: { appId }
          }
      },
      {
        name: 'App Usage',
        path: ({ appId }: PathParam) =>
          appId && {
            route: routes.appDashboard,
            params: { appId }
          }
      },
      {
        name: 'Device Stats',
        path: ({ appId }: PathParam) =>
          appId && {
            route: routes.devicesDashboard,
            params: { appId }
          }
      },
      {
        name: 'Email Stats',
        path: ({ appId, campaignType = 'email' }: PathParam) =>
          appId && {
            route: routes.campaignDashboard,
            params: {
              appId,
              campaignType
            }
          }
      },
      {
        name: 'Campaign Stats',
        path: ({ appId }: PathParam) =>
          appId && {
            route: routes.campaignReports,
            params: { appId }
          }
      }
    ]
  },
  {
    name: 'Targeting',
    icon: faBullseye,
    children: [
      {
        name: 'Segments',
        path: ({ appId }: PathParam) =>
          appId && {
            route: routes.segmentsList,
            params: { appId }
          }
      },
      {
        name: 'Geofences',
        path: ({ appId }: PathParam) =>
          appId && {
            route: routes.geofences,
            params: { appId }
          }
      },
      {
        name: 'Beacons',
        path: ({ appId }: PathParam) =>
          appId && {
            route: routes.beaconsList,
            params: { appId }
          }
      }
    ]
  },
  {
    name: 'Campaigns',
    icon: faBullhorn,
    path: ({ appId }: PathParam) =>
      appId && {
        route: routes.campaignList,
        params: { appId }
      }
  },
  {
    name: 'Journeys',
    customIcon: journeysIcon,
    badgeText: 'NEW',
    badgeColor: 'info',
    path: ({ appId }: PathParam) =>
      appId && {
        route: routes.journeys,
        params: { appId }
      }
  },
  {
    name: 'Users',
    icon: faUsers,
    path: ({ appId }: PathParam) =>
      appId && {
        route: routes.userList,
        params: { appId }
      }
  },
  {
    name: 'Messages',
    icon: faComments,
    // path: ({ appId }: PathParam) => appId && `/mobile/apps/${appId}/talks`
    path: ({ appId }: PathParam) =>
      appId && { route: routes.messages, params: { appId } }
  }
]

class Sidebar extends React.Component {
  static contextType = storeContext

  state: {
    openedCategory: null | string | number
  } = {
    openedCategory: null
  }

  toggle = (index) => () => {
    this.setState((prevState) => ({
      openedCategory: prevState.openedCategory !== index ? index : null
    }))
  }

  async componentDidMount() {
    // Open collapse category that contains the link for the current url
    const pathName = window.location.pathname
    if (!this.props.newLayout) {
      await this.context.app.getSessionData()
      this.context.app.getAppDetails(this.context.app.currentApp.id)
    }

    const appId = this.context.app.currentApp.id

    menuLinks.some((route, index) => {
      if (route.children) {
        route.children.some((option) => {
          const item = option.path({ appId })

          const path =
            typeof item === 'object'
              ? generatePath(item.route.path, item.params)
              : item

          if (pathName === path) {
            this.setState({ openedCategory: index })
            return true
          }
        })
      } else {
        const item = route.path({ appId })

        const path =
          typeof item === 'object'
            ? generatePath(item.route.path, { appId })
            : item

        if (pathName === path) {
          this.setState({ openedCategory: index })
          return true
        }
      }
    })
  }

  allowedMenuLinks = () => {
    const {
      permissions,
      appDetails: { featureFlags }
    } = this.context.app

    const featureFlagMap = {
      Messages: featureFlags.messages,
      Opportunities: featureFlags.opportunitiesDashboard
    }

    return menuLinks.filter(
      ({ name }) =>
        featureFlagMap[name] ??
        (permissions.includes('all') ||
          permissions.includes(name.toLowerCase()))
    )
  }

  handleSubCategoryFeatureFlag = (name) => {
    const { email, geofences, beacons, dashboardBeta, oldAppAndDeviceStats } =
      this.context.app.appDetails.featureFlags

    if (
      (name === 'App Usage' || name === 'Device Stats') &&
      !oldAppAndDeviceStats
    )
      return false
    if (name === 'Dashboard' && !dashboardBeta) return false
    if (name === 'Email Stats' && !email) return false
    if (name === 'Geofences' && !geofences) return false
    if (name === 'Beacons' && !beacons) return false

    return true
  }

  render() {
    const {
      app: { currentApp },
      ui: { showSidebar }
    } = this.context

    const appId = currentApp.id
    const userInfo = this.context.app.currentAdmin

    return (
      <>
        <div
          className={cn(style.sidebar, 'sidebar', showSidebar || 'toggled')}
          aria-label="Sidebar"
        />
        <nav
          className={cn(
            'position-fixed',
            'sidebar',
            style.navbarHeight,
            showSidebar || 'toggled'
          )}
          aria-label="Sidebar navigation">
          <div className="sidebar-content" aria-label="Sidebar content">
            <Link
              route={appId ? routes.appDashboard : '/'}
              params={{ appId }}
              className={cn(
                'sidebar-brand',
                'bg-white',
                'text-center',
                'd-flex',
                'flex-column',
                'justify-content-center',
                style.logo
              )}
              aria-label="Pulsate logo link">
              <img
                className={cn('align-middle', 'logo')}
                src={pulsateLogo}
                alt="Pulsate Logo"
                style={{ width: 'auto', height: '34px' }}
                aria-label="Pulsate logo"
              />
            </Link>
            <div className={style.logoBottomShadow} />
            <div
              className={cn('sidebar-user', style.sidebarUser)}
              aria-label="Sidebar user">
              <div className={cn(style.borderWrapper)}>
                <Img
                  src={
                    userInfo?.avatarUrl ? userInfo.avatarUrl : defaultUserAvatar
                  }
                  fallbackSrc={defaultUserAvatar}
                  className={cn('img-fluid', 'rounded-circle', 'mb-2')}
                  alt={currentApp?.name}
                  aria-label="User avatar"
                />
              </div>
              <div aria-label="User name">{userInfo?.name}</div>
            </div>
            <ul
              className={cn('sidebar-nav', 'mt-4')}
              aria-label="Sidebar navigation list">
              {this.allowedMenuLinks().map((category, index) => {
                if (category.children) {
                  const renderCategory = category.children.map((route) =>
                    route.path({ appId })
                  )

                  if (renderCategory) {
                    return (
                      <SidebarCategory
                        key={index}
                        name={category.name}
                        to={category.path}
                        dataTestId={`${category.name}SideBarCategory`}
                        icon={category.icon}
                        customIcon={category?.customIcon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        isOpen={this.state.openedCategory === index}
                        onClick={this.toggle(index)}
                        aria-label={`Sidebar category: ${category.name}`}>
                        {category.children.map((route, index) => {
                          const path = route.path({ appId })

                          if (
                            path &&
                            this.handleSubCategoryFeatureFlag(route.name)
                          ) {
                            return (
                              <SidebarItem
                                key={index}
                                name={route.name}
                                dataTestId={`${category.name}Items`}
                                to={path}
                                badgeColor={route.badgeColor}
                                badgeText={route.badgeText}
                                newTab={route.newTab}
                                aria-label={`Sidebar item: ${route.name}`}
                              />
                            )
                          }
                        })}
                      </SidebarCategory>
                    )
                  }
                } else {
                  return (
                    <SimpleCategory
                      key={index}
                      name={category.name}
                      to={category.path({ appId })}
                      icon={category.icon}
                      customIcon={category.customIcon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      isOpen={this.state.openedCategory === index}
                      onClick={this.toggle(index)}
                      aria-label={`Simple category: ${category.name}`}
                    />
                  )
                }
              })}
            </ul>
            <Footer />
          </div>
        </nav>
      </>
    )
  }
}

export default withStore(Sidebar)
