import React from 'react'
import { Button, Label } from 'reactstrap'

import './UploadButton.scss'

interface IProps {
  id?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  wrapperClassName?: string
  buttonClassName?: string
  label?: string | React.ReactNode
  multiple?: boolean
  accept?: string
  disabled?: boolean
}

const UploadButton = ({
  id = 'upload',
  onChange,
  wrapperClassName,
  buttonClassName,
  label,
  multiple = false,
  accept,
  disabled
}: IProps): React.ReactElement => {
  return (
    <div className={`upload-button ${wrapperClassName || ' '}`}>
      <input
        type="file"
        multiple={multiple}
        id={id}
        className="d-none"
        onChange={onChange}
        accept={accept}
        disabled={disabled}
      />
      <Button
        className={`${buttonClassName || ' '} p-0`}
        disabled={disabled}
        color="primary">
        <Label htmlFor={id || 'upload'} className="cursor-pointer mb-0">
          {label}
        </Label>
      </Button>
    </div>
  )
}

export default UploadButton
