import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import reject from 'lodash/reject'
import cn from 'classnames'
import SlideInSidebar from '~/components/SlideInSidebar/SlideInSidebar'
import useSuggestedCampaigns from '~/pages/Campaign/CampaignsList/components/SuggestedCampaignsSidebar/useSuggestedCampaigns'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import TableNew from '~/components/Table/TableNew'
import { humanDateTime } from '~/utils/time'
import {
  generateListsDisplayType,
  getAdditionalCampaignType,
  getMainCampaignType
} from '~/pages/Campaign/Notification/Notification.service'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { useStore } from '~/dataStore'
import HeaderTitle from '~/components/HeaderTitle'
import Header from '~/components/Header'
import lightingBolt from '~/components/icons/lightingBolt.svg'
import SearchInput from '~/components/forms/SearchInput'
import MultiSelect from '~/components/forms/MultiSelect'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import PreviewBtn from '~/pages/Campaign/CampaignsList/components/SuggestedCampaignsSidebar/PreviewBtn'
import useAbortController from '~/hooks/useAbortController'
import { ID } from '~/common.interface'

import { breakLine, col, colName, colWide } from '~/components/Table/Table.scss'
import styles from './SuggestedCampaignsSidebar.scss'
import { ICampaignTemplate } from '~/pages/Campaign/CampaignsList/components/SuggestedCampaignsSidebar/SuggestedCampaigns.interface'

export const SuggestedCampaignsSidebar = () => {
  const [suggestedCampaignsOpen, setSuggestedCampaignsOpen] = useState(false)
  const [queryParams, setQueryParams] = useState({
    sort: 'created_at',
    order: 'desc',
    filterTypes: '',
    filterTargets: '',
    filterStatuses: '',
    groupIds: '',
    searchQuery: ''
  })
  const paginationQuery = usePaginationQuery({ perPage: 13 })
  const abortController = useAbortController()
  const {
    data: campaigns,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useSuggestedCampaigns({
    ...queryParams,
    ...paginationQuery.getPaginationQuery()
  })
  const {
    app: {
      currentApp: { id: appId }
    },
    campaign: { initExistingCampaign, inApp },
    goTo
  } = useStore()

  async function createCampaignFromTemplate(id: ID, typeList: CampaignType[]) {
    try {
      await initExistingCampaign(
        appId,
        id,
        false,
        true,
        abortController.getAbortController('create-from-template').signal
      )
      goTo(
        'campaignBuilder',
        {
          appId,
          campaignType: getMainCampaignType(typeList),
          notificationType: getAdditionalCampaignType(typeList),
          size: inApp?.size
        },
        {}
      )
    } catch (error) {
      console.error(error)
    }
  }

  const campaignsTableColumns = [
    {
      fieldName: 'name',
      displayName: 'Name',
      sortable: true,
      render: ({
        row: { name }
      }: {
        row: ICampaignTemplate
      }): React.ReactElement => (
        <div
          className={cn(
            'd-flex align-items-center',
            styles.campaignName,
            col,
            colWide,
            colName,
            breakLine
          )}>
          {name}
        </div>
      )
    },
    {
      fieldName: 'createdAt',
      displayName: 'Date Created',
      classes: 'no-wrap',
      sortable: true,
      render: ({ row }: { row: ICampaignTemplate }): string =>
        humanDateTime(row.createdAt)
    },
    {
      fieldName: 'type',
      displayName: 'Type',
      render: ({ row }: { row: ICampaignTemplate }): string =>
        generateListsDisplayType(row.typeList),
      sortable: true
    },
    {
      displayName: '',
      render: ({ row }: { row: ICampaignTemplate }) => (
        <div className="d-flex gap-2">
          <PreviewBtn campaignId={row.id} />
          <Button
            color="body"
            outline
            className={styles.useBtn}
            onClick={() => createCampaignFromTemplate(row.id, row.typeList)}>
            Use
          </Button>
        </div>
      )
    }
  ]

  const handleFilterTypes = (values: string[]) => {
    setQueryParams({ ...queryParams, filterTypes: values.join(',') })
  }

  const resetFilters = () => {
    setQueryParams({
      ...queryParams,
      filterTypes: '',
      filterTargets: '',
      groupIds: '',
      searchQuery: ''
    })
    paginationQuery.setOrder('desc')
    paginationQuery.setSortField('created_at')
  }

  useEffect(() => {
    refetch()
  }, [
    paginationQuery.searchQuery,
    paginationQuery.order,
    paginationQuery.sortField,
    queryParams
  ])

  return (
    <>
      {campaigns?.pages.length > 0 && (
        <Button
          color=""
          className={cn(styles.sidebarTriggerBtn, 'btn-lg ms-3')}
          onClick={() => {
            refetch()
            setSuggestedCampaignsOpen(true)
          }}>
          Suggested Campaign ({campaigns?.metadata.dataCount})
        </Button>
      )}
      <SlideInSidebar
        size="md"
        isOpen={suggestedCampaignsOpen}
        close={() => {
          setSuggestedCampaignsOpen(false)
          resetFilters()
        }}>
        <div className="mx-4">
          <Header className="mb-5 pt-4 mt-1">
            <HeaderTitle tag="h2" className={styles.header}>
              <img src={lightingBolt} alt="" className="me-2" height={28} />
              Suggested Campaigns{' '}
              <span className="text-muted">
                ({campaigns?.metadata.dataCount})
              </span>
            </HeaderTitle>
            <p className="color-subheader">
              Check out the trending topic Suggested Campaign drafts below
            </p>
          </Header>
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div className="d-flex">
              <SearchInput
                placeholder="Search for a suggested campaign "
                initialValue={paginationQuery.searchQuery}
                onChange={paginationQuery.handleSearch}
                className={cn('me-3 flex-0-1', styles.searchInput)}
              />

              <MultiSelect
                title="Type"
                selectedOptions={reject(
                  queryParams.filterTypes?.split(','),
                  (s) => s === ''
                )}
                onSelect={handleFilterTypes}
                resetOptionName="Show All"
                options={[
                  {
                    name: MCampaignTypeToName.get(CampaignType.PUSH),
                    value: 'push_notification'
                  },
                  {
                    name: MCampaignTypeToName.get(CampaignType.IN_APP),
                    value: 'in_app_notification'
                  },
                  {
                    name: MCampaignTypeToName.get(CampaignType.CARD),
                    value: 'card'
                  },
                  {
                    name: MCampaignTypeToName.get(CampaignType.SMS),
                    value: 'sms_notification'
                  },
                  {
                    name: MCampaignTypeToName.get(CampaignType.EMAIL),
                    value: 'email_notification'
                  }
                ]}
                className="me-3 bg-white"
              />
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <TableNew
              striped
              isLoading={isLoading}
              isLoadingMore={isFetchingNextPage}
              columns={campaignsTableColumns}
              hasMore={hasNextPage}
              onLoadMore={() => fetchNextPage()}
              rows={campaigns?.pages || []}
              sortOrder={paginationQuery.order}
              sortColumn={paginationQuery.sortField}
              onSort={paginationQuery.handleSort}
            />
          </div>
        </div>
      </SlideInSidebar>
    </>
  )
}

export default SuggestedCampaignsSidebar
