import { makeAutoObservable } from 'mobx'
import { getGoalsRowsForNotification } from '~/pages/Journeys/Journey.service'
import { IGoalPerformance } from '~/pages/Journeys/JourneyReport/Store/JourneyReport.interface'
import {
  ChartDataType,
  IChartData,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { IInAppReport } from '../../Notification.interface'
import { InappParts, IPreview } from '~/dataStore/Campaign/Campaign.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

export default class InAppReport {
  constructor(
    public report: IInAppReport,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report

    return [
      {
        label: 'Sent',
        content: send.totalUniq ?? '- -',
        className: 'summary-field--green',
        tooltip: 'The total number of unique campaign sends.',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []

    const { delivery, impression, bounce, clicks } = this.report
    return [
      {
        label: 'Delivered',
        content: delivery.totalUniq,
        tooltip:
          'Total number of in-app notifications that were successfully delivered to a device.',
        rateLabel: 'Delivery Rate',
        rate: delivery.totalUniqRate
      },
      {
        label: 'Impressions',
        content: impression.totalUniq ?? '- -',
        tooltip: 'The total number of times the in-app message was shown',
        rate: impression.totalUniqRate,
        rateLabel: 'Impression Rate'
      },
      {
        label: 'Clicks',
        content: clicks.totalUniq ?? '- -',
        tooltip: 'Total number of clicks on buttons',
        rate: clicks.totalUniqRate,
        rateLabel: 'Click Rate'
      },
      {
        label: 'Bounced',
        content: bounce?.totalUniq ?? '- -',
        tooltip:
          'The in-app notification will bounce if the permissions are disabled.',
        rate: bounce?.totalUniqRate,
        rateLabel: 'Bounce Rate'
      }
    ]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    const {
      send,
      delivery,
      bounce,
      dismiss,
      clicks,
      buttonClickOne,
      buttonClickTwo,
      impression,
      timeOut
    } = this.report

    return [
      {
        name: 'Sent',
        valueCurrent: send.totalUniq,
        data: send.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Delivered',
        valueCurrent: delivery.totalUniq,
        data: delivery.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      bounce && {
        name: 'Bounced',
        valueCurrent: bounce.totalUniq,
        data: bounce.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Dismissed',
        valueCurrent: dismiss.totalUniq,
        data: dismiss.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      buttonClickOne && {
        name: 'Button Click One',
        valueCurrent: buttonClickOne.totalUniq,
        data: buttonClickOne.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },

      buttonClickTwo && {
        name: 'Button Click Two',
        valueCurrent: buttonClickTwo.totalUniq,
        data: buttonClickTwo.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      clicks && {
        name: 'Clicks',
        valueCurrent: clicks.totalUniq,
        data: clicks.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      {
        name: 'Impressions',
        valueCurrent: impression.totalUniq,
        data: impression.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      timeOut && {
        name: 'Time out',
        valueCurrent: timeOut.totalUniq,
        data: timeOut.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      },
      impression.uniqRateGraph && {
        name: 'Total Impressions Rate',
        valueCurrent: `${impression.totalUniqRate}%`,
        data: impression.uniqRateGraph.slice(),
        dataType: ChartDataType.PERCENTAGE
      }
    ].filter(Boolean) as IChartData[]
  }

  public get goals(): IGoalPerformance[] {
    if (!this.report) {
      return []
    }

    return getGoalsRowsForNotification({
      name: MCampaignTypeToName.get(this.report.type) || '- -',
      goals: this.report.goals
    })
  }

  public get preview(): InappParts | undefined {
    if (!this.notificationPreview) return undefined

    return this.notificationPreview.inApp
  }
}
