import cn from 'classnames'
import styles from './JourneyTemplateIcon.scss'

interface IProps {
  src: string
  size?: 'sm' | 'md' | 'xl'
  center?: boolean
}

export default function JourneyTemplateIcon({
  src,
  size = 'md',
  center = true
}: IProps): React.ReactElement {
  return (
    <div
      className={cn(styles.templateIcon, {
        [styles.templateIconSmall]: size === 'sm',
        [styles.templateIconCenter]: center
      })}>
      <img
        className={cn(styles.templateIconImg)}
        src={src}
        alt="Template icon"
      />
    </div>
  )
}
