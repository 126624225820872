import React from 'react'
import cn from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuestionCircle,
  faCaretUp,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons'

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap'

import { fixedHeight, questionCircle, carrotIndicator } from './style.scss'

interface IProps {
  title: string
  value: string
  diff: string
  diffDescription: string
  icon: any
  tooltip: string
}

function changeIndicator(value) {
  let colorClass
  let icon

  if (value >= 0) {
    colorClass = cn('text-success')
    icon = faCaretUp
  } else {
    icon = faCaretDown
    colorClass = cn('text-danger')
  }

  return (
    <>
      <span className={colorClass}>{value}%</span>
      <FontAwesomeIcon icon={icon} className={carrotIndicator} color="grey" />
    </>
  )
}

export default function StatCard({
  title,
  value,
  diff,
  diffDescription,
  icon,
  tooltip
}: IProps): React.ReactElement {
  const percentageValue = (Math.round((Number(diff) || 0) * 100) / 100).toFixed(
    2
  )

  return (
    <Col sm="3">
      <Card>
        <CardBody
          className={cn(
            fixedHeight,
            'd-flex',
            'justify-content-between',
            'flex-column'
          )}>
          <Row>
            <Col className={cn('mt-0', 'd-flex', 'flex-row')}>
              <CardTitle tag="h5">{title}</CardTitle>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={cn(questionCircle)}
                id={title?.replace(/ /g, '')}
              />
              <UncontrolledTooltip
                placement="top"
                target={title?.replace(/ /g, '')}>
                {tooltip}
              </UncontrolledTooltip>
            </Col>

            <Col className="col-auto">
              <div className="avatar">
                <div
                  className={cn(
                    'avatar-title',
                    'rounded-circle',
                    'bg-primary-dark'
                  )}>
                  {icon}
                </div>
              </div>
            </Col>
          </Row>
          <h1 className={cn('mb-3', 'mt-1', 'display-5')}>{value}</h1>
          <div className={cn('mb-0', 'text-muted')}>
            {changeIndicator(percentageValue)}
            vs. {diffDescription} ago
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}
