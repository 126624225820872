import { ReactElement, useState } from 'react'
import InAppSmallModel from '~/pages/Campaign/Notification/InAppNotification/Model/InAppSmall.model'
import CardFront from '~/pages/Campaign/Notification/InAppNotification/Model/InAppLarge.model'
import RadioCollapse from '~/components/forms/RadioCollapse'
import SeparatorLine from '~/components/SeparatorLine'
import { withStore } from '~/dataStore'
import InAppSmallCorporate from './InAppSmallCorporate'
import InAppSmallPersonal from './InAppSmallPersonal'
import Shared from '~/dataStore/Campaign/Shared'

interface IProps {
  notification: InAppSmallModel
  withPersonal?: boolean
  cardFront?: CardFront
  shared: Shared
  disabled?: boolean
}

function InAppSmall({
  withPersonal,
  notification,
  shared,
  disabled = false
}: IProps): ReactElement | null {
  if (!(notification instanceof InAppSmallModel)) {
    return null
  }
  if (withPersonal) {
    const [openPicker, setOpenPicker] = useState<string | undefined>(
      notification.type.value
    )
    const pickerProps = (id: 'corporate' | 'personal', name: string) => ({
      id,
      name,
      radioName: 'inappSmallStyle',
      isValid: true,
      opened: openPicker === id,
      onTitleClick: (open: boolean) => {
        setOpenPicker(open ? id : undefined)
        notification.type.setValue(id)
      },
      checked: notification.type.value === id
    })

    return (
      <>
        <RadioCollapse {...pickerProps('corporate', 'Standard Style')}>
          <InAppSmallCorporate
            notification={notification}
            isWithCardCampaign
            shared={shared}
            disabled={disabled}
          />
        </RadioCollapse>
        <SeparatorLine spaceBottom="0" spaceTop="0" />
        <RadioCollapse {...pickerProps('personal', 'Personal Style')}>
          <InAppSmallPersonal
            shared={shared}
            disabled={disabled}
            notification={notification}
          />
        </RadioCollapse>
      </>
    )
  }

  return (
    <InAppSmallCorporate
      notification={notification}
      shared={shared}
      disabled={disabled}
    />
  )
}

export default withStore(InAppSmall)
