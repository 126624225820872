import { ReactElement } from 'react'
import cn from 'classnames'
import CustomDropdown from '~/components/CustomDropdown'
import DynamicContainer from '~/components/DynamicContainer'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'

interface IProps {
  admins: IAdmin[]
  disabled: boolean
  onSelect: (admin: IAdmin) => void
  selected?: IAdmin
}

function AdminOption({
  admin,
  onSelect,
  className
}: {
  admin: IAdmin
  onSelect?: (admin: IAdmin) => void
  className?: string
}): ReactElement {
  function handleClick() {
    if (onSelect) {
      onSelect(admin)
    }
  }

  return (
    <DynamicContainer
      tagName={onSelect ? 'button' : 'div'}
      className={cn('admin-option d-flex align-items-center', className)}
      onClick={handleClick}>
      <div className="admin-option__avatar">
        <img
          src={admin.avatarUrl}
          alt={admin.name}
          className="admin-option__img"
        />
      </div>
      {admin.name}
    </DynamicContainer>
  )
}

export default function AdminsSelect({
  admins,
  onSelect,
  disabled,
  selected
}: IProps): ReactElement | null {
  if (!admins?.length || !selected) {
    return null
  }

  return (
    <CustomDropdown
      dropdownToggle={
        <AdminOption admin={selected} className="dropdown-toggle" />
      }
      disabled={disabled}
      options={admins.map((admin) => ({
        isCustom: true,
        name(toggleDropdown) {
          return (
            <AdminOption
              key={admin.id}
              admin={admin}
              className="py-2 px-3 shadow-none btn admin-option--hover w-100"
              onSelect={(a) => {
                onSelect(a)
                toggleDropdown()
              }}
            />
          )
        }
      }))}
    />
  )
}
