import React from 'react'
import StatCard from '~/components/reports/StatCard'
import TableNew from '~/components/Table/TableNew'
import ColWithDiff from './ConversionRateCol'
import { ILinkReport } from './Model/Report.interface'

interface IProps {
  rows: ILinkReport[]
}

export default function ClickPerformance({ rows }: IProps): React.ReactElement {
  const columns = [
    {
      fieldName: 'target',
      displayName: 'Url'
    },
    {
      fieldName: 'totalClicks',
      displayName: 'Total Clicks',
      render: ColWithDiff<ILinkReport>('totalClicks', 'totalClicksRate', false),
      classes: 'text-end'
    },
    {
      fieldName: 'uniqueClicks',
      displayName: 'Unique Clicks',
      render: ColWithDiff<ILinkReport>(
        'uniqueClicks',
        'uniqueClicksRate',
        false
      ),
      classes: 'text-end'
    }
  ]

  return (
    <StatCard title="Click Performance">
      <TableNew
        columns={columns}
        rows={rows}
        rowKey="target"
        className="table--big"
      />
    </StatCard>
  )
}
