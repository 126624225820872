import React from 'react'

const IconSplit = ({
  className,
  fill
}: {
  className?: string
  fill: string
}): React.ReactElement => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 22.75V21C24.5 20.65 24.5 13.825 19.6 11.55C15.75 9.8 15.75 5.425 15.75 5.25V0H12.25V5.25C12.25 5.425 12.25 9.8 8.4 11.55C3.5 13.825 3.5 20.65 3.5 21V22.75H0L5.25 28L10.5 22.75H7V21C7 21 7 16.1 9.975 14.7C11.9 13.825 13.125 12.425 14 11.2C14.875 12.6 16.1 13.825 18.025 14.7C21 16.1 21 21 21 21V22.75H17.5L22.75 28L28 22.75H24.5Z"
        fill={fill}
        className="custom-fill"
      />
    </svg>
  )
}

export default IconSplit
