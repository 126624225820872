import { get, put, post, upload, destroy } from '~/api/v2'
import { IGeofence, IMetaData, IBulkActions } from '../Geofences.interface'
import { isEmpty } from '~/utils/utilities'
import { formatLocationDecimalPlaceLength } from '~/pages/Geofences/utils'

export function fetchGeofences(
  appId: string,
  params: unknown
): Promise<{
  data: Array<IGeofence>
  metadata: IMetaData
  bulkActions: IBulkActions
}> {
  return get(`${appId}/geofences`, params)
}

export function editGeofence(
  appId: string,
  geofence: IGeofence
): Promise<IGeofence> {
  const payload = {
    ...geofence,
    ...(!isEmpty(geofence.radius) && { radius: geofence.radius.toString() }),
    location: formatLocationDecimalPlaceLength(geofence.location)
  }

  return put(`${appId}/geofences/${geofence.id}`, payload)
}

export function removeGeofence(
  appId: string,
  geofenceId: string
): Promise<void> {
  return destroy(`${appId}/geofences/${geofenceId}`)
}

export function addGeofence(appId: string, geofence: IGeofence): Promise<any> {
  const payload = {
    ...geofence,
    ...(!isEmpty(geofence.radius) && { radius: geofence.radius.toString() }),
    location: formatLocationDecimalPlaceLength(geofence.location)
  }
  return post(`${appId}/geofences`, payload)
}

export function exportGeofences(
  appId: string,
  from: string,
  to: string
): Promise<void> {
  return get(`${appId}/geofences/export`, { from, to })
}

export function batchCreateGeofencesByCsv(
  appId: string,
  formData: FormData
): Promise<void> {
  formData.append('submission_type', 'file')
  return upload(`${appId}/geofences/batch_create`, formData)
}

export function batchCreateGeofencesManually(
  appId: string,
  geofences: IGeofence[]
): Promise<void> {
  return post(`${appId}/geofences/batch_create`, {
    submissionType: 'manual',
    geofences
  })
}
