import React from 'react'
import AdminsList from './AdminsList'
import { ID } from '~/common.interface'

const AccountSettingsAdminsSection = ({
  appId
}: {
  appId: ID
}): React.ReactElement => {
  return (
    <>
      <div className="ms-auto">
        <div className="bg-body-main rounded d-flex justify-content-between p-4 align-items-center">
          <h3 className="text-white mb-0">Admins</h3>
          <a
            href="https://pulsate.readme.io/v4.1/docs/pulsate-admin-privileges"
            className="text-nowrap d-inline-block text-white"
            target="_blank"
            rel="noreferrer">
            Learn more
          </a>
        </div>
      </div>
      <div className="mt-4">
        <AdminsList appId={appId} />
      </div>
    </>
  )
}

export default AccountSettingsAdminsSection
