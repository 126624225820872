import { ICampaignsListQueryParams } from '~/pages/Campaign/CampaignsList/CampaignsList.interface'
import { ICampaign, IPreview } from '~/dataStore/Campaign/Campaign.interface'
import { postRequest, putRequest } from '~/api/requests'
import { get, put, post, destroy } from './v2'

export function createDraftCampaign(
  appId: string,
  data: ICampaign,
  abortSignal?: AbortSignal
): Promise<ICampaign> {
  return postRequest(
    `/api/v2/apps/${appId}/campaigns`,
    data,
    'POST',
    abortSignal
  )
}
export function updateCampaign(
  appId: string,
  campaignId: string,
  data: ICampaign,
  abortSignal?: AbortSignal
): Promise<ICampaign> {
  return putRequest(
    `/api/v2/apps/${appId}/campaigns/${campaignId}`,
    data,
    abortSignal
  )
}

export function fetchCampaign<T = ICampaign>(
  appId: string,
  campaignId: string,
  params?: Record<string, unknown>,
  abortSignal?: AbortSignal
): Promise<T> {
  return get(`${appId}/campaigns/${campaignId}`, params, abortSignal)
}

export function fetchCampaigns<T = ICampaign>(
  appId: string,
  params?: ICampaignsListQueryParams,
  abortSignal?: AbortSignal
): Promise<T> {
  return get(`${appId}/campaigns`, params, abortSignal)
}

export function fetchCampaignTemplates<T = ICampaign>(
  appId: string,
  params?: ICampaignsListQueryParams,
  abortSignal?: AbortSignal
): Promise<T> {
  return get(`${appId}/campaign_templates`, params, abortSignal)
}

export function createCampaignFromTemplate(
  appId: string,
  campaignId: string,
  abortSignal?: AbortSignal
): Promise<ICampaign> {
  return post(
    `${appId}/campaign_templates/${campaignId}/create_campaign`,
    undefined,
    abortSignal
  )
}

export function fetchCampaignTemplatePreview(
  appId: string,
  campaignId: string
): Promise<IPreview> {
  return get(`${appId}/campaign_templates/${campaignId}/preview`)
}

export function duplicateCampaign(
  appId: string,
  campaignId: string,
  abortSignal?: AbortSignal
): Promise<ICampaign> {
  return get(
    `${appId}/campaigns/${campaignId}/duplicate`,
    undefined,
    abortSignal
  )
}

export function removeCampaign(
  appId: string,
  campaignId: string,
  abortSignal?: AbortSignal
): Promise<void> {
  return destroy(`${appId}/campaigns/${campaignId}`, undefined, abortSignal)
}

export function sendTestEmail(
  appId: string,
  data: {
    html: string
    mergeTags: { [key: string]: string }
    subject: string
    fromName: string
  }
): Promise<string> {
  return post(`${appId}/campaigns/send_test_email`, data)
}

export function fetchCampaignPreview(
  appId: string,
  campaignId: string
): Promise<IPreview> {
  return get(`${appId}/campaigns/${campaignId}/preview`)
}

export function pauseCampaign(
  appId: string,
  campaignId: string
): Promise<void> {
  return put(`${appId}/campaigns/${campaignId}/pause`)
}

export function resumeCampaign(
  appId: string,
  campaignId: string
): Promise<void> {
  return put(`${appId}/campaigns/${campaignId}/resume`)
}

export function requestCampaignSendApproval(
  appId: string,
  params: { campaignId: string }
): Promise<void> {
  return get(`${appId}/campaigns/demo_register_in_send`, params)
}
