import { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'reactstrap'
import { useParams } from 'react-router-dom'
import TableHeader from '~/components/Table/TableHeader'
import { useStore, withStore } from '~/dataStore'
import SegmentBuilderStore from './SegmentBuilder.store'
import { useSegmentRules } from './useSegmentBuilder'
import RuleTypeDropdown from './RuleTypeDropdown/RuleTypeDropdown'
import SegmentRuleGroup from './SegmentRuleGroup'
import { showGeneralError } from '~/utils/validations'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Input from '~/components/forms/Input'
import Modal from '~/components/modals/Modal'
import {
  useFetchSegmentDetails,
  useInvalidateSegmentDetails
} from '~/pages/Users/UsersList/useUsersList'
import { useInvalidateSegments } from '../SegmentsList/useSegmentsList'
import { CustomErrors } from '~/common.interface'
import { fetchTotalAudience } from '~/api/segments'

function SegmentBuilder(): React.ReactElement {
  const {
    app: { currentApp, openCurtain },
    goTo,
    ui: { showModal }
  } = useStore()
  const store = useRef(new SegmentBuilderStore())

  const [usersCount, setUsersCount] = useState<{
    totalUsersCount: number
    usersCount: number | null
  }>({ totalUsersCount: 0, usersCount: null })

  const { segmentId } = useParams<{ segmentId: string }>()
  const segmentDetails = useFetchSegmentDetails(segmentId)

  const { rulesWithCategories } = useSegmentRules(currentApp.id)

  const invalidateSegmentList = useInvalidateSegments()
  const invalidateSegmentDetails = useInvalidateSegmentDetails()

  async function estimateSegment() {
    try {
      const response = await store.current.estimateSegment(currentApp.id)

      setUsersCount((prev) => ({
        totalUsersCount: prev.totalUsersCount,
        usersCount: response.usersCount
      }))
    } catch (error) {
      console.error(error)
      if ((error as Error).message !== CustomErrors.INVALID) {
        showGeneralError()
      }
    }
  }

  async function saveSegment() {
    try {
      await store.current.saveSegment(currentApp.id, segmentDetails?.data?.id)
      invalidateSegmentList()
      goTo('segmentsList', { appId: currentApp.id })
    } catch (error) {
      if ((error as Error).message !== CustomErrors.INVALID) {
        console.error(error)

        showGeneralError()
      }
    }
  }

  function onSaveClick() {
    showModal('segmentBuilder')
  }

  useEffect(() => {
    if (segmentDetails?.data && Object.keys(rulesWithCategories).length > 0) {
      store.current.fillStore(segmentDetails.data, rulesWithCategories)
      estimateSegment()
    }
  }, [segmentDetails?.data, rulesWithCategories])

  useEffect(() => {
    fetchTotalAudience(currentApp.id).then((response) => {
      setUsersCount({
        totalUsersCount: response.totalAudience,
        usersCount: null
      })
    })
    openCurtain()
    return () => {
      invalidateSegmentDetails()
      invalidateSegmentList()
    }
  }, [])

  const isZeroRules = store.current.isZeroRules()
  useEffect(() => {
    if (isZeroRules && usersCount.totalUsersCount) {
      setUsersCount((prev) => ({
        totalUsersCount: prev.totalUsersCount,
        usersCount: null
      }))
    }
  }, [isZeroRules, usersCount.totalUsersCount])

  return (
    <>
      <Container fluid>
        <TableHeader>
          {segmentDetails?.data?.name
            ? segmentDetails.data.name
            : 'Segment Builder'}
        </TableHeader>
        <Card className="p-4" aria-label="Segment Builder Card">
          <Row className="align-items-center justify-content-between flex-nowrap">
            {usersCount.totalUsersCount > 0 &&
              usersCount.usersCount === null && (
                <Col xs="auto" aria-label="Total Audience">
                  <strong>
                    Total Audience {usersCount.totalUsersCount.toLocaleString()}
                  </strong>
                </Col>
              )}
            {usersCount.totalUsersCount > 0 &&
              usersCount.usersCount !== null && (
                <Col xs="auto" aria-label="Matching Users Count">
                  <strong>
                    {usersCount.usersCount.toLocaleString()} of{' '}
                    {usersCount.totalUsersCount.toLocaleString()} users match
                    this criteria
                  </strong>
                </Col>
              )}

            <Col xs="auto">
              <Button
                color=""
                disabled={store.current.isZeroRules()}
                className="bg-white px-4 btn--hover btn-outline-body me-3"
                onClick={() => estimateSegment()}
                aria-label="Estimate Segment Button">
                Estimate segment
              </Button>
              <Button
                id="save-segment-btn"
                color="body"
                className="btn--hover px-4"
                disabled={store.current.isZeroRules()}
                onClick={() => onSaveClick()}
                aria-label="Save Segment Button">
                Save Segment
              </Button>
            </Col>
          </Row>
        </Card>
        <Card className="p-4" aria-label="Rules Card">
          {store.current.ruleGroups.map((rules) => {
            return (
              <SegmentRuleGroup
                key={rules[0].id}
                store={store.current}
                rulesWithCategories={rulesWithCategories}
                rules={rules}
                aria-label="Segment Rule Group"
              />
            )
          })}
          {store.current.isZeroRules() && (
            <RuleTypeDropdown
              onRuleSelection={store.current.addRule}
              rules={rulesWithCategories}
              aria-label="Add Condition Dropdown">
              Add condition
            </RuleTypeDropdown>
          )}
        </Card>
      </Container>

      <Modal
        id="segmentBuilder"
        className=""
        renderHeader={() => 'Save Segment?'}
        render={(close: () => void) => (
          <ConfirmationModal
            onSubmit={() => {
              return saveSegment().finally(() => {
                if (store.current.segmentName.isValid) close()
              })
            }}
            onClose={close}
            okText="Save"
            cancelText="Cancel"
            aria-label="Save Segment Confirmation Modal">
            <Input
              placeholder="Segment name"
              value={store.current.segmentName.value}
              onChange={(e) => store.current.setName(e.target.value)}
              name="segment-name"
              errorTooltip={store.current.segmentName.errors
                .map((error) => error.message)
                .join(', ')}
              invalid={!store.current.segmentName.isValid}
              aria-label="Segment Name Input"
            />
          </ConfirmationModal>
        )}
      />
    </>
  )
}

export default withStore(SegmentBuilder)
