import Deliver from '../emailBuilder/Deliver/Deliver'
import EmailNotification from '~/pages/Campaign/Notification/EmailNotification/Model/EmailNotification'
import Review from '../emailBuilder/Review'
import SetUp from '../emailBuilder/SetUp'
import Targeting from '../emailBuilder/Targeting/Targeting'
import EmailTemplates from '~/pages/Campaign/Notification/EmailNotification/Model/EmailTemplates'
import { ICampaignModel, StepStoreFactory } from './Campaign.interface'

export default class EmailCampaign {
  static createStepStores(campaign: ICampaignModel): StepStoreFactory[] {
    const targeting = new Targeting(campaign)

    return [
      {
        name: 'setUp',
        displayName: 'Set Up',
        store: new SetUp(campaign),
        position: 1
      },
      {
        name: 'templates',
        displayName: 'Template',
        store: new EmailTemplates(campaign),
        position: 2
      },
      {
        name: 'email',
        displayName: 'Email',
        store: new EmailNotification(),
        position: 3
      },
      {
        name: 'targeting',
        displayName: 'Target',
        store: targeting,
        position: 4
      },
      {
        name: 'deliver',
        displayName: 'Deliver',
        store: new Deliver(campaign, targeting),
        position: 5
      },
      {
        name: 'review',
        displayName: 'Review',
        store: new Review(),
        position: 6
      }
    ]
  }
}
