import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { HtmlExport } from 'react-email-editor'
import { useStore, withStore } from '~/dataStore'

import './EmailStep4.scss'
import EmailNotificationBuilder from '~/pages/Campaign/Notification/EmailNotification/UI/EmailNotificationBuilder'

function EmailBuilderStep4({
  setBeforeExitStep
}: {
  setBeforeExitStep: (beforeExitAction: () => void) => void
}) {
  const {
    campaign: emailBuilder,
    campaign: {
      setHasChanged,
      shared: { mergeTags, fetchMergeTags },
      templates: {
        subject,
        fromName,
        currentTemplate,
        setCurrentTemplate,
        isCurrentTemplateBlank
      },
      email: { editorData, setEditorData, setEditorReady, getEditorReady }
    },
    app: { openCurtain },
    ui: { unregisterAllModals }
  } = useStore()

  const { appId }: { appId: string } = useParams()

  useEffect(() => {
    fetchMergeTags(appId)
  }, [])

  useEffect(() => {
    openCurtain()
    return () => {
      unregisterAllModals()
    }
  }, [openCurtain])

  const onDesignChange = (data: HtmlExport) => {
    emailBuilder.setHasChanged(true)
    setEditorData(data)
  }

  return (
    <div className="campaign-email-template-builder">
      <EmailNotificationBuilder
        currentTemplate={currentTemplate.value}
        mergeTags={mergeTags}
        designChangedCallback={onDesignChange}
        editorData={editorData}
        isCurrentTemplateBlank={isCurrentTemplateBlank}
        setCurrentTemplate={setCurrentTemplate}
        setEditorData={setEditorData}
        saveTemplateCallback={() => setHasChanged(false)}
        setEditorReady={setEditorReady}
        getEditorReady={getEditorReady}
        testEmailData={{
          subject: subject.value,
          fromName: fromName.value.label,
          designData: editorData?.design
        }}
        setBeforeExitStep={setBeforeExitStep}
      />
    </div>
  )
}

export default withStore(EmailBuilderStep4)
