import React from 'react'
import ConfirmationModal from '~/components/modals/ConfirmationModal'

interface IProps {
  onSubmit?: () => void
  onClose: () => void
}

export default function ExitCampaign({
  onClose,
  onSubmit
}: IProps): React.ReactElement {
  return (
    <ConfirmationModal
      onSubmit={onSubmit}
      onClose={onClose}
      cancelText="Cancel"
      okText="Exit">
      To save the changes on an Active, Scheduled or Paused campaigns, you must
      go to the last step (Review) and click Send Campaign
    </ConfirmationModal>
  )
}
