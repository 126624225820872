import { action, computed, observable, makeObservable } from 'mobx'
import {
  IPushPayload,
  FileExtension
} from '~/dataStore/Campaign/Campaign.interface'
import Shared from '~/dataStore/Campaign/Shared'
import { convertFileToURL } from '~/utils/file.service'
import { isType } from '~/utils/utilities'
import { hasInvalidBuilderCharacters } from '~/utils/validations'
import {
  IRegisteredField,
  ValidationTypes
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import ToggleRegisteredField, {
  IToggleField
} from '../../../../../dataStore/emailBuilder/Fields/Toggle.field'
import CTAField from '../../../../../dataStore/emailBuilder/Fields/CTA.field'
import CTAGroup from '../../../../../dataStore/emailBuilder/Fields/CTAGroup.field'
import RichMedia, {
  IToggleRichMedia,
  ToggleRichMedia
} from '../../../../../dataStore/emailBuilder/Fields/RichMedia.field'
import NotificationGoals from '../../NotificationGoals'
import { ID } from '~/common.interface'
import { IPushDTO, NotificationType } from '../../Notification.interface'

export type PushFields = {
  title: IToggleField
  subTitle: IToggleField
  notification: IRegisteredField
  richMedia: IToggleRichMedia
  actionButtons: CTAGroup
}

export default class PushVariant {
  public notificationId: ID | undefined = undefined

  public toggleField = new NewRegisteredField<boolean>(true)

  public id: string

  public title: IToggleField = new ToggleRegisteredField<string>('')

  public subTitle: IToggleField = new ToggleRegisteredField<string>('')

  public notification = new NewRegisteredField<string>('')

  public notificationAction: CTAField | undefined

  public actionButtons: CTAGroup

  public richMedia: IToggleRichMedia

  public goals: NotificationGoals | undefined

  constructor(
    variant: string,
    public appId: string,
    public isWithCard: boolean = false,
    public isSingleCampaign = true
  ) {
    makeObservable<PushVariant, 'validateInvalidCharacters'>(this, {
      id: observable,
      title: observable,
      subTitle: observable,
      notification: observable,
      notificationAction: observable,
      actionButtons: observable,
      richMedia: observable,
      setId: action.bound,
      validateInvalidCharacters: action.bound,
      validate: action.bound,
      registeredFields: computed
    })

    this.id = variant
    //* temp solution until variants are added to journeys and goals to campaigns
    let prefix = ''

    if (isSingleCampaign) {
      prefix = `Variant ${this.id}: `
    } else {
      this.goals = new NotificationGoals()
    }

    this.title.setErrorMessage(
      `${prefix}You must add title or turn off section`
    )

    this.subTitle.setErrorMessage(
      `${prefix}You must add subtitle or turn off section`
    )
    this.subTitle.setActive(false)

    this.notification.setErrorMessage(
      `${prefix}You must add your notification text`
    )

    this.richMedia = new ToggleRichMedia('')
    this.richMedia.setErrorMessage(
      `${prefix}You must add a Rich Media or turn off section`
    )

    if (!this.isWithCard) {
      this.notificationAction = new CTAField(
        `${isSingleCampaign ? `Variant ${this.id} ` : ''}notification action: `
      )
      this.notificationAction.withLabel = false
    }

    this.actionButtons = new CTAGroup(
      `${isSingleCampaign ? `Variant ${this.id}` : ''}`
    )

    this.actionButtons.setActive(false)

    this.reset = this.reset.bind(this)
    this.setActive = this.setActive.bind(this)
    this.fillVariant = this.fillVariant.bind(this)
  }

  public setId(id: string): void {
    this.id = id
  }

  public validateField(type: keyof PushFields): void {
    const field = this[type]
    field.validate()

    if (
      field instanceof NewRegisteredField &&
      isType<'title' | 'subTitle' | 'notification'>(type, (val) =>
        ['title', 'subTitle', 'notification'].includes(val as string)
      )
    ) {
      this.validateInvalidCharacters(field, type)
    }
  }

  private validateInvalidCharacters(
    field: IToggleField | NewRegisteredField,
    fieldName: 'title' | 'subTitle' | 'notification'
  ): boolean {
    const parsedName = {
      title: 'Title',
      subTitle: 'Sub-Title',
      notification: 'Notification Text'
    }

    const isActive = 'isActive' in field ? field.isActive : true

    if (!isActive) return true

    if (field.isValid && hasInvalidBuilderCharacters(field.value)) {
      const prefix = this.isSingleCampaign ? `Variant ${this.id} ` : ''
      field.setError(
        ValidationTypes.FORMAT,
        `${prefix}${parsedName[fieldName]}: The following characters are not allowed: '<', '>', '[', ']'.`
      )
    }

    return field.isValid
  }

  public validate(): void {
    this.title.validate()
    this.validateInvalidCharacters(this.title, 'title')
    this.subTitle.validate()
    this.validateInvalidCharacters(this.subTitle, 'subTitle')
    this.notification.validate()
    this.validateInvalidCharacters(this.notification, 'notification')
    this.notificationAction?.validate()
    this.richMedia.validate()
    this.actionButtons.validate()
    this.goals?.validate([
      { sourceType: 'button', active: this.actionButtons.isActive }
    ])
  }

  public get registeredFields(): IRegisteredField[] {
    return [
      this.toggleField,
      this.title,
      this.subTitle,
      this.notification,
      this.richMedia,
      this.notificationAction?.destinationType,
      this.notificationAction?.isUrl
        ? this.notificationAction.destinationUrl
        : undefined,
      this.notificationAction?.isDeeplink
        ? this.notificationAction.destination
        : undefined,
      ...this.actionButtons.registeredFields,
      this.goals
    ].filter(Boolean) as IRegisteredField[]
  }

  // eslint-disable-next-line class-methods-use-this
  private isToggleField(
    field:
      | IToggleField
      | NewRegisteredField<string>
      | CTAField
      | CTAGroup
      | RichMedia
  ): field is IToggleField {
    return 'isActive' in field
  }

  public isFieldValid(fieldName: keyof PushFields): boolean {
    const field = this[fieldName]

    let goalsValid = true
    if (this.goals) {
      goalsValid = this.goals.isValid
    }

    if (fieldName === 'notification' && this.notificationAction) {
      return field.isValid && this.notificationAction.isValid && goalsValid
    }

    if (fieldName === 'actionButtons' && this.isFieldActive(fieldName)) {
      return field.isValid && goalsValid
    }

    return field.isValid
  }

  public isFieldActive(fieldName: keyof PushFields): boolean {
    const field = this[fieldName]

    if (this.isToggleField(field)) {
      return field.isActive
    }

    return true
  }

  public reset(fieldName: keyof PushFields): void {
    this.setActive(fieldName, false)

    if (fieldName === 'actionButtons') {
      this.goals?.removeGoals('button', 'front')
    }
  }

  public setActive(fieldName: keyof PushFields, value: boolean): void {
    const field = this[fieldName]
    if (this.isToggleField(field)) {
      this.toggleField.setValue(!this.toggleField.value)
      field.setActive(value)
    }
  }

  public async getDuplicatedPayload(): Promise<
    (IPushPayload | IPushDTO) & {
      file: File | undefined
      fileName: string
      fileExtension: FileExtension | undefined
    }
  > {
    const payload = await this.getPayload(true)

    return {
      ...payload,
      file: this.richMedia.file || undefined,
      fileName: this.richMedia.fileName,
      fileExtension: this.richMedia.extenstion
    }
  }

  private async convertRichMediaFileToURL(): Promise<void> {
    if (this.richMedia.file) {
      const response = await convertFileToURL(this.richMedia.file, this.appId)
      if (response) {
        URL.revokeObjectURL(this.richMedia.value)
        this.richMedia.setValue(response)
        this.richMedia.setFile(null)
      }
    }
  }

  public async getPayload(duplicate = false): Promise<IPushDTO | IPushPayload> {
    if (!duplicate) {
      await this.convertRichMediaFileToURL()
    }

    if (this.isSingleCampaign) {
      return {
        id: duplicate ? undefined : this.notificationId,
        bodyTemplate: this.notification.value,
        ...this.notificationAction?.payload,
        ...(this.subTitle.isActive && {
          subtitleTemplate: this.subTitle.value
        }),
        ...(this.title.isActive && { titleTemplate: this.title.value }),
        buttons: this.actionButtons.payload,
        buttonCategoryId: this.actionButtons.buttonCategory.value?.id,
        activeFields: {
          buttons: this.actionButtons.isActive,
          titleTemplate: this.title.isActive,
          subtitleTemplate: this.subTitle.isActive
        },
        attachment: {
          active: this.richMedia.isActive,
          url: this.richMedia.value,
          provider: this.richMedia.provider,
          name: this.richMedia.fileName
        }
      }
    }
    return {
      id: duplicate ? undefined : this.notificationId,
      type: NotificationType.PUSH,
      goals: this.goals?.getPayload() || [],
      frontParts: {
        title: {
          text: this.title.value,
          position: 0,
          active: this.title.isActive,
          side: 'front'
        },
        subTitle: {
          text: this.subTitle.value,
          position: 1,
          active: this.subTitle.isActive,
          side: 'front'
        },
        pushBody: {
          text: this.notification.value,
          position: 2,
          active: true,
          side: 'front',
          ...this.notificationAction?.payload
        },
        attachment: {
          active: this.richMedia.isActive,
          url: this.richMedia.value,
          provider: this.richMedia.provider,
          name: this.richMedia.fileName,
          position: 3,
          side: 'front'
        },
        callToAction: {
          position: 4,
          active: this.actionButtons.isActive,
          side: 'front',
          buttonCategoryId: this.actionButtons.buttonCategory.value?.id,
          buttons: this.actionButtons.payload
        }
      }
    }
  }

  public fillVariant(
    variant: (IPushDTO | IPushPayload) & {
      file?: File
      fileName?: string
      fileExtension?: FileExtension
    },
    shared: Shared
  ): void {
    if (!variant.template) {
      this.notificationId = variant.id
    }
    if ('frontParts' in variant) {
      this.fillNotificationPayload(variant, shared)
    } else {
      this.fillCampaignPayload(variant, shared)
    }
  }

  private fillCampaignPayload(
    payload: IPushPayload & {
      file?: File
      fileName?: string
      fileExtension?: FileExtension
    },
    shared: Shared
  ): void {
    this.notification.fillField({ value: payload.bodyTemplate })
    this.title.fillField({
      value: payload.titleTemplate,
      isActive: !!payload.activeFields?.titleTemplate
    })
    this.subTitle.fillField({
      value: payload.subtitleTemplate,
      isActive: !!payload.activeFields?.subtitleTemplate
    })
    this.notificationAction?.fillField(payload)

    this.richMedia.fillField({
      value: payload.attachment.url || null,
      fileName: payload.attachment.data?.name || '',
      provider: payload.attachment.provider,
      extension: payload.attachment.data?.type,
      isActive: !!payload.attachment.active
    })

    if (payload.file) {
      this.richMedia.setValue(URL.createObjectURL(payload.file))
      this.richMedia.setFile(payload.file)
    }

    if (payload.fileName) {
      this.richMedia.setFileName(payload.fileName)
    }

    if (payload.fileExtension) {
      this.richMedia.setExtension(payload.fileExtension)
    }

    if (payload.buttonCategoryId) {
      this.actionButtons.selectGroup(
        shared.getButtonCategoryById(payload.buttonCategoryId)
      )
    }

    this.actionButtons.fillField({
      isActive: !!payload.activeFields?.buttons,
      buttons: payload.buttons
    })
  }

  private fillNotificationPayload(
    payload: IPushDTO & {
      file?: File
      fileName?: string
      fileExtension?: FileExtension
    },
    shared: Shared
  ): void {
    const { frontParts } = payload
    this.notification.fillField({ value: frontParts.pushBody.text })
    this.title.fillField({
      value: frontParts.title.text,
      isActive: !!frontParts.title.active
    })
    this.subTitle.fillField({
      value: frontParts.subTitle.text,
      isActive: !!frontParts.subTitle.active
    })
    this.notificationAction?.fillField(frontParts.pushBody)

    this.richMedia.fillField({
      value: frontParts.attachment.url || null,
      fileName: frontParts.attachment.customData?.name || '',
      provider: frontParts.attachment.provider,
      extension: frontParts.attachment.customData?.type,
      isActive: !!frontParts.attachment.active
    })

    if (payload.file) {
      this.richMedia.setValue(URL.createObjectURL(payload.file))
      this.richMedia.setFile(payload.file)
    }

    if (payload.fileName) {
      this.richMedia.setFileName(payload.fileName)
    }

    if (payload.fileExtension) {
      this.richMedia.setExtension(payload.fileExtension)
    }

    if (frontParts.callToAction.buttonCategoryId) {
      this.actionButtons.selectGroup(
        shared.getButtonCategoryById(frontParts.callToAction.buttonCategoryId)
      )
    }

    this.actionButtons.fillField({
      isActive: !!frontParts.callToAction.active,
      buttons: frontParts.callToAction.buttons
    })

    if (this.goals && payload.goals) {
      this.goals.fillGoals(payload.goals, this.actionButtons.buttons)
    }
  }
}
