import { Date, ID } from '~/common.interface'

const ruleTypes = [
  'alias',
  'email',
  'firs_name',
  'las_name',
  'age',
  'signup',
  'number_of_sessions',
  'last_active',
  'last_contacted',
  'last_contacted_admin',
  'last_campaign_open',
  'opt_out',
  'email_unsubscribed',
  'device_guid',
  'device_language',
  'device_timezone',
  'device_token',
  'device_type',
  'device_app_version',
  'ip',
  'device_push_permission',
  'device_location_permission',
  'last_been_at',
  'number_of_visits',
  'last_in_app_event',
  'number_of_event_clicks',
  'event_recorded',
  'location',
  'age',
  'gender',
  'is_pro_user',
  'reservation_date',
  'all_users'
] as const

export const customRuleTypes: Extract<
  RuleType,
  | 'last_campaign_open'
  | 'device_type'
  | 'ip'
  | 'device_push_permission'
  | 'device_location_permission'
  | 'last_been_at'
  | 'number_of_visits'
  | 'last_in_app_event'
  | 'number_of_event_clicks'
  | 'event_recorded'
  | 'location'
  | 'signup'
  | 'last_active'
  | 'all_users'
>[] = [
  'last_campaign_open',
  'device_type',
  'ip',
  'device_push_permission',
  'device_location_permission',
  'last_been_at',
  'number_of_visits',
  'last_in_app_event',
  'number_of_event_clicks',
  'event_recorded',
  'location',
  'signup',
  'last_active',
  'all_users'
]

const ruleCategories = [
  'allUsers',
  'personal',
  'activity',
  'deviceSettings',
  'locationEvents',
  'events',
  'location',
  'customData',
  'cunexus'
] as const

export const timeFrame = [
  'minutes',
  'hours',
  'days',
  'weeks',
  'months',
  'years'
] as const

type DataType =
  | 'string'
  | 'numeric'
  | 'integer'
  | 'date_ago'
  | 'boolean'
  | 'empty'

export type MatchTypes =
  | 'less'
  | 'more'
  | 'between'
  | 'equal'
  | 'not_equal'
  | 'starts_with'
  | 'ends_with'
  | 'contains'
  | 'anniversary_today'

type RuleType = (typeof ruleTypes)[number]

type RuleCategory = (typeof ruleCategories)[number]

type TimeFrame = (typeof timeFrame)[number]

type TimeDirection = 'ago' | 'away'

export const BE_RESOURCE_TYPE_MAP = {
  beacon: 'Company::Beacon',
  beaconGroup: 'Mobile::App::Group::Beacon',
  campaign: 'Mobile::App::Campaign',
  campaignGroup: 'Mobile::App::Group::Campaign',
  geofence: 'Mobile::App::Geofence',
  geofenceGroup: 'Mobile::App::Group::Geofence'
} as const

export type EventResourceType =
  (typeof BE_RESOURCE_TYPE_MAP)[keyof typeof BE_RESOURCE_TYPE_MAP]

export type JointType = 'And' | 'Or'

export interface RuleDTO {
  key: RuleType
  type: DataType
}

export type RuleWithCategoriesDTO = {
  [x in RuleCategory]: RuleDTO[]
}

export interface SegmentDTO {
  name: string
  id: ID
  usersCount: number
  groups: {
    joinType: '+'
    rules: SegmentRuleDTO[]
  }[]
  usersDiff?: number
  cachedUsersCount?: number
}

export interface SegmentRuleDTO {
  createdAt?: Date
  eventResource?:
    | { id: ID }
    | { guid: ID }
    | { uuid: ID; major: number; minor: number }
  eventResourceType?: EventResourceType
  eventResourceId?: ID | null
  inAppEventName?: string
  locations?: null | LocationDTO[]
  matchEndValue?: string
  matchType: MatchTypes
  matchValue?: string
  timeFrame?: TimeFrame
  timeDirection?: TimeDirection
  type: RuleType
  updatedAt?: Date
}

export interface LocationDTO {
  type: 'point'
  values: {
    center: [number, number]
    radius: number
  }
}

export interface ISegmentRule extends SegmentRuleDTO {
  id: ID
  rule: RuleDTO
  errors: Record<keyof ISegmentRule, string> | Record<never, string>
}

// {
//     "segment": {
//         "name": "new_segment_name2",
//         "groups": [
//             {
//                 "join_type": "&",
//                 "rules": [
//                     {
//                       "time_frame": "minutes",
//                       "match_type": "less",
//                       "match_value": "123",
//                       "match_end_value": "6",
//                       "event_resource_type": "beacon",
//                       "event_resource": {
//                         "uuid": "{{BEACON_UUID}}",
//                         "major": {{BEACON_MAJOR}},
//                         "minor": {{BEACON_MINOR}}
//                       },
//                       "type": "number_of_visits"
//                     }
//                 ]
//             }
//         ]
//     }
// }

// {
//     "id": "643fe7bcb58b2c9e923de33a",
//     "url": "/api/v2/apps/5ae07be64753364695040000/segments/643fe7bcb58b2c9e923de33a",
//     "name_sorting": "Tb all rules 3",
//     "created_at": "2023-04-19T13:08:12Z",
//     "cached_users_count": 0,
//     "users_diff": 0,
//     "groups_ids": [],
//     "groups": [
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "minutes",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "less",
//                     "match_value": "12",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "Mobile::App::Campaign",
//                     "in_app_event_name": "sample_click",
//                     "type": "last_campaign_open",
//                     "locations": [
//                         {
//                             "values": {
//                                 "radius": "1000",
//                                 "center": [
//                                     19.9167,
//                                     50.0833
//                                 ]
//                             },
//                             "type": "point"
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "equal",
//                     "match_value": "ios",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "device_type",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "equal",
//                     "match_value": "123.123.12.12",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "ip",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "equal",
//                     "match_value": "not_set",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "device_push_permission",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "equal",
//                     "match_value": "true",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "device_location_permission",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "minutes",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "less",
//                     "match_value": "12",
//                     "match_end_value": "",
//                     "event_resource_id": "5ae0861b4753364a20170000",
//                     "event_resource_type": "Company::Beacon",
//                     "in_app_event_name": "sample_click",
//                     "type": "last_been_at",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "less",
//                     "match_value": "12",
//                     "match_end_value": "",
//                     "event_resource_id": "5ae0861b4753364a20170000",
//                     "event_resource_type": "Company::Beacon",
//                     "in_app_event_name": "sample_click",
//                     "type": "number_of_visits",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "minutes",
//                     "time_direction": "ago",
//                     "match_value_type": "",
//                     "match_type": "less",
//                     "match_value": "12",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "last_in_app_event",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "less",
//                     "match_value": "12",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "number_of_event_clicks",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "equal",
//                     "match_value": "true",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "event_recorded",
//                     "locations": null
//                 }
//             ]
//         },
//         {
//             "join_type": "+",
//             "rules": [
//                 {
//                     "time_frame": "",
//                     "time_direction": "",
//                     "match_value_type": "",
//                     "match_type": "between",
//                     "match_value": "",
//                     "match_end_value": "",
//                     "event_resource_id": "643faac3b58b2ca0f348ebdd",
//                     "event_resource_type": "",
//                     "in_app_event_name": "sample_click",
//                     "type": "location",
//                     "locations": [
//                         {
//                             "values": {
//                                 "radius": "1000",
//                                 "center": [
//                                     19.9167,
//                                     50.0833
//                                 ]
//                             },
//                             "type": "point"
//                         }
//                     ]
//                 }
//             ]
//         }
//     ]
// }
