import { ReactElement, ReactNode } from 'react'

import styles from './VitalsTile.scss'

interface IProps {
  title: string | ReactNode
  subTitle: string
}

const VitalsTile = ({ title, subTitle }: IProps): ReactElement => {
  return (
    <div className={styles.wrapper} aria-label="Vitals Tile">
      <h4 className="fw-normal" aria-label={`Title: ${title}`}>
        {title}
      </h4>
      <p className="fw-medium text-13" aria-label={`Subtitle: ${subTitle}`}>
        {subTitle}
      </p>
    </div>
  )
}

export default VitalsTile
