import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropsWithChildren, useEffect } from 'react'
import cn from 'classnames'
import { useStore, withStore } from '~/dataStore'
import styles from './hintWindow.scss'

interface IProps {
  fixed?: boolean
  className?: string
}

function HintBtn({
  children,
  fixed,
  className
}: PropsWithChildren<IProps>): React.ReactElement {
  const { ui } = useStore()

  useEffect(() => {
    return () => {
      ui.setHintWindowActive(false)
    }
  }, [])

  return (
    <>
      <button
        type="button"
        className={cn(
          className,
          styles.hintWindowBtn,
          { [styles.hintWindowBtnFixed]: fixed },
          'd-flex align-items-center'
        )}
        onClick={() => ui.setHintWindowActive(true)}>
        <div className={cn('d-flex align-items-baseline')}>
          <FontAwesomeIcon
            icon={faInfo}
            className={cn(styles.hintWindowBtnIcon)}
          />
          <div className={cn(styles.hintWindowBtnText)}>{children} </div>
        </div>
      </button>
    </>
  )
}

export default withStore(HintBtn)
