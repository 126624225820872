// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#u_body {
  min-height: 100vh !important;
}

.gallery > div .item-context-menu-button {
  display: none !important;
}

.actions-container {
  background-color: white;
  border: 1px solid #28518c;
  border-radius: 3px;

  div > * {
    opacity: 1 !important;
  }

  a {
    color: #28518c !important;
    opacity: 1 !important;

    .disabled {
      opacity: 0.5 !important;
    }
  }

  .eivhxa {
    border-left: 1px solid #28518c !important;
    height: 20px !important;
  }
}

.bootstrap a:not([href]):not([tabindex]) {
  color: #28518c !important;
}

.nav {
  background-color: white;
}

.nav-link.active {
  background-color: #e9ecef !important;
}

.icon,
.nav-icon {
  color: #28518c !important;
  opacity: 1 !important;
}

.card-header {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.react-toggle-track {
  background-color: #3e95d1 !important;
}

.group-toggle,
.card-header,
.tab-content,
.blockbuilder-label-primary,
.blockbuilder-widget-label label,
.blockbuilder-options-header,
.blockbuilder-options-title,
.blockbuilder-options-panel,
.blockbuilder-options-panel .nav-tabs,
.blockbuilder-tools-panel .tab-pane {
  color: #28518c !important;
  background-color: #f4f7fc !important;
}

.blockbuilder-options-header .icon.icon-delete {
  color: #28518c !important;
}
.blockbuilder-options-header .icon.icon-duplicate {
  color: #28518c !important;
}
.blockbuilder-options-header .icon.icon-close {
  color: #28518c !important;
}

.blockbuilder-options-content .nav-tabs .nav-item .nav-link {
  color: #28518c !important;
}
.blockbuilder-options-content .nav-tabs .nav-item .nav-link.active {
  color: #28518c !important;
  background-color: white !important;
}

.blockbuilder-widget-label label {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;
}
.blockbuilder-widget-label label.blockbuilder-label-primary {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.blockbuilder-preview {
  padding: 0;
  overflow: auto;
}

.blockbuilder-preferences {
  border-left: 0 !important;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
}

.blockbuilder-row-tool {
  border: 0 !important;

  .blockbuilder-column {
    background-color: white !important;
    border: 1px solid #7f95b3;
  }

  .blockbuilder-column:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .blockbuilder-column:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .blockbuilder-column::after {
    outline: none !important;
  }

  .blockbuilder-column + .blockbuilder-column {
    border-left: none;
  }

  .blockbuilder-column-content {
    color: #153d77 !important;
    font-size: 14px;
    line-height: 21px;
  }
}

.tab-pane > .blockbuilder-content-tools {
  background-color: #f4f7fc;
  padding-top: 0 !important;
}

.blockbuilder-content-tool {
  border: 0 !important;
  border-radius: 3px !important;
  box-shadow: none !important;
}

.blockbuilder-content-tool:hover {
  box-shadow: inset 0 1px 0 rgba(white, 0.15), 0 1px 1px rgba(black, 0.075) !important;
}

.blockbuilder-content-tool-icon {
  color: #8d9fc0 !important;
}

.blockbuilder-content-tool-name {
  color: #28518c !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: none;
}

.blockbuilder-widget-more-options {
  .form-control {
    border: 1px solid #28518c !important;
    border-radius: 3px !important;
    color: #28518c !important;
  }
}

.blockbuilder-counter-control {
  border: 1px solid #28518c !important;
  border-radius: 3px !important;
  color: #28518c !important;
}

.blockbuilder-counter-control-value {
  border-left: 1px solid #28518c !important;
  border-right: 1px solid #28518c !important;
  background-color: white !important;
  color: #28518c !important;
}

.blockbuilder-dropzone {
  color: #28518c !important;
  border: 2px dashed #28518c !important;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___.toString();
