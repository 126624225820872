import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from 'reactstrap'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import sectionLogo from './section-logo.svg'
import CurrencySelect from '../CurrencySelect'
import { editMasterCurrency } from '~/api/appSettings'
import { showGeneralError } from '~/utils/validations'
import { useStore, withStore } from '~/dataStore'
import { NotificationType, showNotification } from '~/utils/Notification'

const MasterCurrency = () => {
  const {
    app: {
      appDetails,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const [currency, setCurrency] = useState<string | undefined>('USD')
  const [isLoading, setIsLoading] = useState(false)
  const { appId } = useParams<{ appId: string }>()

  const onSubmit = async () => {
    if (!currency) return
    try {
      setIsLoading(true)
      await editMasterCurrency(appId, { masterCurrency: currency })
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
    } catch (error) {
      showGeneralError()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setCurrency(appDetails?.masterCurrency)
  }, [appDetails])

  return (
    <>
      <div className="mt-4">
        <Card>
          <CardHeader>
            <CardTitle tag="h3" className="mb-0 mt-2">
              Master Currency
            </CardTitle>
          </CardHeader>
          <CardBody className="pt-0 pb-4 mt-4">
            <Row>
              <Col xs={6}>
                <CurrencySelect value={currency} onChange={setCurrency} />
              </Col>
              <Col
                xs={6}
                className="ps-0 d-flex align-items-center justify-content-center">
                <img
                  src={sectionLogo}
                  width={126}
                  alt="Master Currency"
                  className="ms-3 position-absolute"
                />
              </Col>
            </Row>
            <Button
              color="primary"
              type="submit"
              disabled={isLoading || isDemoAdmin}
              className="d-block mt-4"
              onClick={onSubmit}>
              Save Changes
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="xs"
                  className="ms-2"
                />
              )}
            </Button>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default withStore(MasterCurrency)
