import { CardTitle } from 'reactstrap'
import React from 'react'
import Tooltip from '~/components/reports/Tooltip'

export const StatCardTitle = ({
  title,
  tooltip
}: {
  title: string
  tooltip: string | undefined
}) => (
  <div className="d-flex justify-content-between align-items-center">
    <CardTitle tag="h3" className="mb-0">
      {title}
    </CardTitle>
    {tooltip && <Tooltip tooltip={tooltip} />}
  </div>
)

export default StatCardTitle
