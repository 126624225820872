import React from 'react'
import moment from 'moment-timezone'

import pluralize from 'pluralize'
import {
  DATE_FORMATS,
  ExpiryType
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { humanTimeDiff, isTimeDiffLessThan24 } from '~/utils/time'
import { withStore } from '~/dataStore'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'

interface IProps {
  isDetailsInPast: boolean
  deliver: {
    isCampaignStartingEndingSameDay: boolean
    startAt?: Date | string | null
    startNow?: boolean | string
    endAt?: Date | string | null
    expiryType?: ExpiryType
    expiryDate?: Date | string | null
    expiryTimeFrame?: string
    expiryTimeValue?: string | number | null
    campaignExpiry?: boolean
    isDeliveryFuture: boolean
  }
  type: 'Campaign' | 'Journey'
  status?: JourneyStatus
}

const Start = ({
  isDetailsInPast,
  type,
  deliver: {
    isCampaignStartingEndingSameDay,
    startAt,
    endAt,
    startNow,
    expiryType,
    expiryDate,
    expiryTimeFrame,
    expiryTimeValue,
    campaignExpiry,
    isDeliveryFuture
  },
  status
}: IProps) => {
  let startAtDate = '- -'
  let startAtTime = ''

  if (startAt) {
    startAtDate = moment(startAt).format(DATE_FORMATS.MONTH_DAY)
    startAtTime = moment(startAt).format(DATE_FORMATS.HOUR_MINUTE_MERIDIAN)
  }

  const sendFullDate = () => {
    if (!isCampaignStartingEndingSameDay || startNow) return null
    return (
      <span>
        {startAtTime}, <span className="text-secondary">{startAtDate}</span>
      </span>
    )
  }

  const sendNow = () => {
    if (!startNow) return null

    return (
      <span>
        Sending <span className="text-secondary">Now</span>
      </span>
    )
  }

  const sendingInOrOn = () => {
    if (startNow || isCampaignStartingEndingSameDay) return null

    const inOrOn = isTimeDiffLessThan24(startAt) ? 'in' : 'on'

    return (
      <>
        Sending {inOrOn}{' '}
        <span className="text-secondary">
          {isTimeDiffLessThan24(startAt)
            ? humanTimeDiff(moment(), startAt)
            : startAtDate}
        </span>
        {endAt && isDeliveryFuture && (
          <>
            <span className="text-muted px-1"> |</span> Ending on{' '}
            <span className="text-secondary">
              {moment(endAt).format(DATE_FORMATS.MONTH_DAY)}
            </span>
          </>
        )}
      </>
    )
  }

  const getTitle = () => {
    if (isDetailsInPast) {
      return type === 'Journey' ? status : 'Sent'
    }
    return sendFullDate() || sendNow() || sendingInOrOn()
  }

  const getSubTitle = (): React.ReactElement => {
    let text = `This ${type} will be sent at ${startAtTime}, ${startAtDate}`
    if (isDetailsInPast) {
      if (type === 'Journey') {
        text = `This Journey was launched on ${startAtTime}, ${startAtDate}`
      } else {
        text = `${type} sent on ${startAtTime}, ${startAtDate}`
      }
    } else if (startNow) {
      text = `This ${type} will be sent now`
    }

    let expiryValue = ''
    let expiryText = ''
    if (expiryType !== ExpiryType.NEVER && campaignExpiry) {
      if (expiryType === ExpiryType.DATE && expiryDate) {
        expiryValue = moment(expiryDate).format('hh:mma, Do MMMM YYYY')
      } else if (
        expiryType === ExpiryType.AMOUNT &&
        expiryTimeFrame &&
        expiryTimeValue
      ) {
        expiryValue = `${expiryTimeValue} ${pluralize(
          expiryTimeFrame,
          expiryTimeValue
        )}`
      }

      expiryText = `and will expire after ${expiryValue}`
    }

    return (
      <div className="muted">
        {text} {expiryText}
      </div>
    )
  }

  return (
    <div className="text-end">
      <div>{getTitle()}</div>
      {getSubTitle()}
    </div>
  )
}

export default withStore(Start)
