import React, { useEffect } from 'react'

import CampaignReview from '~/pages/Campaign/CampaignReview'
import { useStore, withStore } from '~/dataStore'

function ReviewStep(): React.ReactElement {
  const {
    campaign: { reviewData, review, steps }
  } = useStore()

  useEffect(() => {
    review.setStepValid(true)

    return () => {
      review.setStepValid(false)
    }
  }, [review])

  return <CampaignReview withEdit steps={steps} {...reviewData} />
}

export default withStore(ReviewStep)
