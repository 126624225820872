import { action, computed, observable, makeObservable } from 'mobx'
import {
  IInAppPayload,
  PreviewFieldType,
  SmallPayload
} from '~/dataStore/Campaign/Campaign.interface'
import imagePlaceholder from '~/components/icons/image_placeholder.svg'
import CTAField from '../../../../../dataStore/emailBuilder/Fields/CTA.field'
import ImageField from '../../../../../dataStore/emailBuilder/Fields/Image.field'
import NewRegisteredField from '../../../../../dataStore/emailBuilder/RegisteredField.model'
import { convertFileToURL } from '~/utils/file.service'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'
import {
  IRegisteredField,
  ValidationTypes
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { hasInvalidBuilderCharacters } from '~/utils/validations'

export default class InAppSmall {
  public position = new NewRegisteredField<'top' | 'bottom'>('top')

  public type = new NewRegisteredField<'corporate' | 'personal' | undefined>(
    'corporate'
  )

  admin: IAdmin | undefined

  public adminHeader = new NewRegisteredField<string>('')

  public image: ImageField = new ImageField({
    errorMessage: 'Add an image',
    active: true,
    position: 0,
    value: '',
    fileName: ''
  })

  public text: NewRegisteredField<string> = new NewRegisteredField('')

  public cta: CTAField = new CTAField()

  constructor(public appId: string) {
    makeObservable<
      InAppSmall,
      'personalRegisteredFields' | 'corporateRegisteredFields'
    >(this, {
      position: observable,
      type: observable,
      admin: observable,
      adminHeader: observable,
      image: observable,
      text: observable,
      cta: observable,
      setAdmin: action.bound,
      registeredFields: computed,
      personalRegisteredFields: computed,
      corporateRegisteredFields: computed,
      isPersonal: computed
    })

    this.cta.withLabel = false
    this.adminHeader.setErrorMessage('Add an In-App Notification Message')
    this.setPosition = this.setPosition.bind(this)
    this.validateText = this.validateText.bind(this)
  }

  public setPosition(position: 'top' | 'bottom'): void {
    this.position.setValue(position)
  }

  public setAdmin(admin?: IAdmin): void {
    this.admin = admin ? { ...admin, id: admin.id || admin.sId } : undefined
  }

  public validateStep(): void {
    if (this.type.value === 'personal') {
      this.adminHeader.validate()
      this.validateAdminHeaderInvalidChars()
    } else {
      this.image.validate()
      this.validateText()
      this.validateTextInvalidChars()
    }
    this.cta.validate()
  }

  public get isPersonal(): boolean {
    return this.type.value === 'personal'
  }

  public validateText(): void {
    this.text.validate('Add an In-App Notification Message')
  }

  public validateTextInvalidChars(): boolean {
    return this.validateInvalidChars(this.text)
  }

  public validateAdminHeaderInvalidChars(): boolean {
    return this.validateInvalidChars(this.adminHeader)
  }

  // eslint-disable-next-line class-methods-use-this
  private validateInvalidChars(field: NewRegisteredField): boolean {
    if (field.isValid && hasInvalidBuilderCharacters(field.value)) {
      field.setError(
        ValidationTypes.FORMAT,
        `The following characters are not allowed: '<', '>', '[', ']'.`
      )
    }

    return field.isValid
  }

  public get registeredFields(): IRegisteredField[] {
    if (this.type.value === 'personal') {
      return this.personalRegisteredFields
    }

    return this.corporateRegisteredFields
  }

  private get personalRegisteredFields(): IRegisteredField[] {
    return [this.type, this.position, this.adminHeader]
  }

  private get corporateRegisteredFields(): IRegisteredField[] {
    return [
      this.type,
      this.position,
      this.text,
      this.image.field,
      this.cta.destinationType,
      this.cta.isUrl ? this.cta.destinationUrl : undefined,
      this.cta.isDeeplink ? this.cta.destination : undefined
    ].filter(Boolean) as IRegisteredField[]
  }

  public fillAdmin(message: string, admin: IAdmin | undefined): void {
    this.adminHeader.fillField({
      value: message
    })

    this.setAdmin(admin)
  }

  public fillStore(small: SmallPayload): void {
    this.setPosition(small.position)
    this.type.setValue(small.type)
    if (small.type === 'personal') {
      this.fillPersonal(small)
    } else {
      this.fillCorporate(small)
    }

    this.cta.fillField(small.callToAction.buttons?.[0])
  }

  private fillPersonal(small: SmallPayload): void {
    this.fillAdmin(
      small.adminHeaderWithMessage.message,
      small.adminHeaderWithMessage.admin as IAdmin
    )
  }

  private fillCorporate(small: SmallPayload): void {
    this.text.fillField({ value: small.imageHeaderWithMessage.message })

    this.image.fillField({
      value: small.imageHeaderWithMessage.url,
      fileName: small.imageHeaderWithMessage.name
    })
  }

  private async convertRichMediaFileToURL(): Promise<void> {
    if (this.image.file) {
      const response = await convertFileToURL(this.image.file, this.appId)
      if (response) {
        if (this.image.value) {
          URL.revokeObjectURL(this.image.value)
        }
        this.image.setValue(response)
        this.image.setFile(null)
      }
    }
  }

  public async getPayload(): Promise<IInAppPayload> {
    if (this.isPersonal) {
      return {
        small: this.getPersonalPayload()
      }
    }

    return {
      small: await this.getCorporatePayload()
    }
  }

  private getPersonalPayload(): SmallPayload {
    return {
      type: 'personal',
      position: this.position.value,
      callToAction: {
        buttons: [this.cta.payload]
      },
      adminHeaderWithMessage: {
        message: this.adminHeader.value,
        admin: {
          jobTitle: this.admin?.jobTitle || null,
          name: this.admin?.name || null,
          avatarUrl: this.admin?.avatarUrl || null,
          sId: this.admin?.id || null
        }
      }
    }
  }

  private async getCorporatePayload(): Promise<SmallPayload> {
    await this.convertRichMediaFileToURL()

    return {
      type: 'corporate',
      position: this.position.value,
      callToAction: {
        buttons: [this.cta.payload]
      },
      imageHeaderWithMessage: {
        name: this.image.name,
        url: this.image.value,
        message: this.text.value
      }
    }
  }

  public generatePreview(): any {
    let parts = []

    if (this.isPersonal) {
      parts = this.generatePersonalPreview()
    } else {
      parts = this.generateCorporatePreview()
    }

    return {
      position: this.position.value,
      parts
    }
  }

  private generatePersonalPreview() {
    const { admin } = this
    return [
      {
        type: PreviewFieldType.ADMIN_HEADER,
        admin: {
          jobTitle: admin?.jobTitle,
          name: admin?.name,
          avatarUrl: admin?.avatarUrl
        },
        value:
          this.adminHeader.value ||
          'Hello, advertise your campaign with a personal message!'
      }
    ]
  }

  private generateCorporatePreview() {
    return [
      {
        type: PreviewFieldType.IMAGE_HEADER_WITH_MESSAGE,
        image: this.image.value || imagePlaceholder,
        value:
          this.text.value ||
          'Add 1 or 2 extra lines to make your notification tempting and tap! '
      }
    ]
  }
}
