import React, { useEffect } from 'react'

import { withStore, useStore } from '~/dataStore'
import Targeting from '~/dataStore/emailBuilder/Targeting/Targeting'
import { ISegmentsTargetListItem } from './Segments.interface'
import TargetToggle from '../TargetToggle/TargetToggle'
import SegmentNameCol from './SegmentNameCol'

const columns = [
  {
    fieldName: 'name',
    displayName: 'Segment name',
    // eslint-disable-next-line react/display-name
    render: ({
      row,
      onSelectRow
    }: {
      row: ISegmentsTargetListItem
      onSelectRow: (row: ISegmentsTargetListItem, selected: boolean) => void
    }) => {
      return <SegmentNameCol row={row} selectSegment={onSelectRow} />
    }
  },
  {
    fieldName: 'totalUsers',
    displayName: ' Approximate users',
    classes: 'text-end'
  }
]

interface IProps {
  isSended: boolean
  targeting: Targeting
  contentScrollableBlue?: boolean
  onSelectCallback?: (selected: boolean) => void
}

const Segments = ({
  isSended,
  targeting,
  contentScrollableBlue,
  onSelectCallback
}: IProps) => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()

  const {
    validateTargetingChoosenOptions,
    segments: {
      collection,
      fetchSegments,
      hasMore,
      selected,
      selectSegment,
      groups,
      fetchGroups,
      selectedGroups,
      setSelectedGroups,
      searchQuery,
      setSearchQuery,
      totalCount,
      isValid
    }
  } = targeting

  useEffect(() => {
    fetchGroups(appId)
  }, [fetchGroups, appId])

  return (
    <TargetToggle
      fetchResources={() => fetchSegments(appId, true)}
      disabled={isSended}
      searchPlaceholder="Search for a segment"
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      groups={groups}
      selectedGroups={selectedGroups}
      setGroups={setSelectedGroups}
      columns={columns}
      rows={collection.map((segment) => ({
        ...segment,
        isSelected: selected.has(segment.id)
      }))}
      selectedRows={selected}
      selectRow={(row: any, selected: boolean) => {
        selectSegment(row, selected)
        onSelectCallback?.(selected)
        if (selected) validateTargetingChoosenOptions()
      }}
      hasMore={hasMore}
      onLoadMore={fetchSegments}
      totalCount={totalCount}
      invalid={!isValid}
      contentScrollableBlue={contentScrollableBlue}
    />
  )
}

export default withStore(Segments)
