import { Tooltip } from 'reactstrap'
import cn from 'classnames'

interface IProps {
  messages: string[]
  close: () => void
  isOpen: boolean
  targetId: string
  inModal?: boolean
}

export default function BuilderErrors({
  messages,
  close,
  isOpen,
  targetId,
  inModal = false
}: IProps): React.ReactElement {
  return (
    <Tooltip
      className={cn('tooltip-error', { 'tooltip-error--modal': inModal })}
      placement="top"
      isOpen={isOpen}
      target={targetId}>
      <span className="tooltip-error__close icon icon--close" onClick={close} />
      <div className="tooltip-error__header">Checklist</div>
      <div className="tooltip-error__content">
        {messages.map((message, index) => (
          <div key={`${message}-${index}`}>- {message}</div>
        ))}
      </div>
    </Tooltip>
  )
}
