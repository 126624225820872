import { runInAction, makeAutoObservable } from 'mobx'
import {
  fetchButtons,
  fetchDeeplinks,
  fetchEvents,
  fetchMergeTags
} from '~/api/apps'
import { fetchAdmins } from '~/api/admins'
import { ButtonCategory } from '../App.interface'
import { IOption } from '../emailBuilder/EmailBuilder.interface'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'

export default class Shared {
  public admins: IAdmin[] = []

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public async getAdmins(appId: string): Promise<IAdmin[]> {
    if (!this.admins.length) {
      const response = await fetchAdmins(appId)
      runInAction(() => {
        this.admins = response.data
      })
    }
    return this.admins
  }

  deeplinks: { id: string; nickname: string; target: string }[] = []

  public async fetchDeeplinks(
    appId: string
  ): Promise<{ id: string; nickname: string; target: string }[]> {
    if (!this.deeplinks.length) {
      const response = await fetchDeeplinks(appId)
      runInAction(() => {
        this.deeplinks = response.data
      })
    }

    return this.deeplinks
  }

  public get deeplinksOptions(): (IOption & { name: string })[] {
    return this.deeplinks.map((deeplink) => ({
      value: deeplink.target,
      name: deeplink.nickname,
      label: deeplink.nickname
    }))
  }

  mergeTags: string[] = []

  async fetchMergeTags(appId: string): Promise<void> {
    const { mergeTags } = await fetchMergeTags(appId)
    runInAction(() => {
      this.mergeTags = mergeTags
    })
  }

  public buttonCategories: ButtonCategory[] = []

  public async fetchButtonCategories(): Promise<void> {
    if (this.buttonCategories.length) return
    const response = await fetchButtons()
    runInAction(() => {
      this.buttonCategories = response
    })
  }

  public get buttonCategoriesOptions(): (IOption & { name: string })[] {
    return this.buttonCategories.map((category) => ({
      value: category.id,
      name: category.buttonLabels.join(' & '),
      label: category.buttonLabels.join(' & ')
    }))
  }

  public getButtonCategoryById(id: string): ButtonCategory | undefined {
    const buttonCategory = this.buttonCategories.find(
      (category) => category.id === id
    )
    return buttonCategory
  }

  public events: string[] = []

  public searchQuery = ''

  public async fetchEvents(appId: string): Promise<string[]> {
    if (!this.events.length) {
      const response = await fetchEvents(appId, this.searchQuery)
      runInAction(() => {
        this.events = response.data
      })
    }

    return this.events
  }

  public setSearchQuery(value: string): void {
    this.searchQuery = value
  }

  public get eventsOptions(): IOption[] {
    return this.events.map((e: string | { id: string; name: string }) => {
      if (typeof e === 'string') {
        return {
          value: `${e ?? ''}`,
          label: `${e ?? ''}`
        }
      }
      if ('id' in e) {
        return { value: e.id, label: e.name }
      }

      return { value: '', label: '' }
    })
  }
}
