import React, { Fragment, useState, useMemo } from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import cn from 'classnames'
import uniqBy from 'lodash/uniqBy'

import Modal from '~/components/modals/Modal/Modal'

import { subTitle } from './RemoveElementsFromGroup.scss'

interface IProps {
  isSaving: boolean
  elementsName: string
  onClose: () => void
  onSubmit: (selectedGroups: string[]) => void
  nameKey?: string
  elements: Array<unknown>
}

function RemoveElementsFromGroup({
  onSubmit,
  elementsName,
  nameKey = 'name',
  onClose,
  elements,
  isSaving
}: IProps) {
  const [selectedGroups, setSelectedGroups] = useState([])
  const groups = useMemo(
    () =>
      uniqBy(
        elements.flatMap((e) => e.groups),
        'id'
      ),
    [elements]
  )

  const toggleGroup = (groupId: string) =>
    setSelectedGroups((prevGroups) => {
      const idx = prevGroups.findIndex((prevGrpId) => prevGrpId === groupId)

      if (idx >= 0) {
        return [...prevGroups.slice(0, idx), ...prevGroups.slice(idx + 1)]
      }
      return [...prevGroups, groupId]
    })

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(selectedGroups)
        }}>
        <ModalBody className="mx-3">
          <header className={subTitle}>Selected {elementsName}</header>
          {elements.map((e) => (
            <Fragment key={e.id}>
              - {e[nameKey]}
              <br />
            </Fragment>
          ))}
          <br />
          <header className={subTitle}>Remove from</header>
          {groups.map((g) => (
            <Button
              color="body"
              key={g.id}
              onClick={() => toggleGroup(g.id)}
              className={cn('my-2 me-3', {
                'btn-outline-body bg-white text-body': !selectedGroups.find(
                  (grp) => grp === g.id
                )
              })}>
              {g.name}
            </Button>
          ))}
        </ModalBody>
        <ModalFooter className="border-top-0">
          <Button color="" onClick={onClose} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => onSubmit(selectedGroups)}
            disabled={isSaving}>
            Remove
          </Button>
        </ModalFooter>
      </form>
    </>
  )
}

const RemoveElementsFromGroupModal = (props: Omit<IProps, 'onClose'>) => (
  <Modal
    id="removeElementsFromGroup"
    size="sm"
    className="remove-from-group-modal"
    renderHeader={() => 'Choose Group'}
    render={(close: () => void) => (
      <RemoveElementsFromGroup {...props} onClose={close} />
    )}
  />
)

export default RemoveElementsFromGroupModal
