import { uniqueId } from 'lodash'
import React, {
  useState,
  ReactElement,
  useRef,
  useLayoutEffect,
  useCallback
} from 'react'

import cn from 'classnames'
import PreviewMergeTagText from '~/pages/Campaign/Notification/Preview/Parts/PreviewMergeTagText'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import {
  restoreSelection,
  saveSelection,
  splitByMergeTag
} from '~/utils/utilities'
import SelectWithSearch from '../SelectWithSearch'
import EmojiPicker from '~/components/forms/EmojiPicker/EmojiPicker'
import { NotificationType, showNotification } from '~/utils/Notification'
import './Textarea.scss'

interface IProps {
  className?: string
  disabled?: boolean
  invalid?: boolean
  label?: string | React.ReactElement
  onBlur?: (e?: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (value: string) => void
  placeholder?: string
  required?: boolean
  value?: string
  max?: number
  mergeTags?: string[]
  withoutLineBreaks?: boolean
}

export default function Textarea({
  onChange,
  onBlur,
  value,
  className,
  label,
  placeholder,
  invalid,
  required,
  max,
  disabled,
  mergeTags,
  withoutLineBreaks
}: IProps): ReactElement {
  const id = useRef(uniqueId('textarea'))
  const [showPlaceholder, setShowPlaceholder] = useState(!value)
  const [length, setLength] = useState(value?.length || 0)
  const [localValue, setLocalValue] = useState(value || '')
  const [lastPosition, setLastPosition] = useState({ start: 0, end: 0 })

  const keyDown = useRef<string | null>(null)
  const textbox = useRef<HTMLDivElement>(null)
  const restorePosition = useRef<boolean>(false)
  const textboxKey = useRef<string>(uniqueId())

  function handleBlur(e: React.FocusEvent<HTMLDivElement>) {
    //! dropdownToggle (button) bubbles events so we need to prevent this

    if (
      e.target.id !== id.current ||
      (e.relatedTarget as HTMLElement)?.id.includes('dropdown-toggle') ||
      (/AppleWebKit\/([\d.]+)/.exec(navigator.userAgent) &&
        (e.relatedTarget as HTMLElement)?.dataset.id === 'default-merge-tag')
    ) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    textboxKey.current = uniqueId()

    const newVal = e.target.textContent || ''
    onBlur?.()
    setLocalValue(newVal)
    setShowPlaceholder(!newVal)
    setLastPosition(saveSelection(textbox.current))
  }

  function handleInput(e: React.ChangeEvent<HTMLDivElement>) {
    //! dropdownToggle (button) bubbles events so we need to prevent this
    if (e.target.id !== id.current) {
      return
    }
    //! innerText is aware of styling and won't return the text of hidden elements
    const newVal = e.target.textContent || ''

    onChange(newVal)
    setLength(newVal.length)
    setShowPlaceholder(!newVal)
  }

  const change = useCallback(
    (modifiedValue: string, index: number): void => {
      const currentVal = textbox.current?.textContent || localValue
      const splitted = splitByMergeTag(currentVal)

      splitted[index] = modifiedValue
      const newVal = splitted.join('')

      setLastPosition({
        start: newVal.length + 1,
        end: newVal.length
      })

      setLocalValue(newVal)
      onChange(newVal)

      restorePosition.current = true
    },
    [localValue, onChange]
  )

  function handleFocus() {
    setShowPlaceholder(false)
  }

  function clearSelection() {
    if (window.getSelection) {
      if (window.getSelection()?.empty) {
        // Chrome
        window.getSelection()?.empty()
      } else if (window.getSelection()?.removeAllRanges) {
        // Firefox
        window.getSelection()?.removeAllRanges()
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty()
    }
  }

  function handlePaste(e: React.ClipboardEvent<HTMLDivElement>): void {
    let text = e.clipboardData.getData('text/plain')
    // ? images and other stuff
    if (!text) {
      e.preventDefault()
    }

    text = text.trim()

    if (withoutLineBreaks) {
      text = text.replaceAll(/\r?\n|\r/g, ' ')
    }

    if (max && text.length + length > max) {
      showNotification(
        `Notification text can not be longer than ${max} characters`,
        NotificationType.INFO
      )
      e.preventDefault()
      return
    }

    const selection = window.getSelection()
    if (!selection?.rangeCount) return
    selection.deleteFromDocument()
    selection.getRangeAt(0).insertNode(document.createTextNode(text))

    e.preventDefault()
    clearSelection()
    handleInput(e)
  }

  function handleKeyUp(): void {
    keyDown.current = null
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (
      ['Backspace', 'Delete', 'Meta', 'Shift'].includes(e.key) ||
      e.key.includes('Arrow') ||
      (keyDown.current === 'Meta' && e.key === 'a')
    ) {
      keyDown.current = e.key
      return
    }

    if (
      navigator.userAgent.indexOf('Firefox') !== -1 &&
      e.key === 'Enter' &&
      !withoutLineBreaks
    ) {
      e.preventDefault()
      document.execCommand('insertHTML', false, '\n')
    }

    if ((max && length >= max) || (withoutLineBreaks && e.key === 'Enter')) {
      e.preventDefault()
    }
  }

  function handleExternalValue(val: string): void {
    if (max && length + val.length >= max) {
      return
    }

    const formattedValue = `${localValue.slice(
      0,
      lastPosition.end || 0
    )}${val}${localValue.slice(lastPosition.end || 0)}`

    setLastPosition({
      start: lastPosition.start + val.length + 1,
      end: lastPosition.end + val.length
    })

    setLength(formattedValue.length)
    setLocalValue(formattedValue)
    onChange(formattedValue)

    setShowPlaceholder(false)
    onBlur?.()

    restorePosition.current = true
  }

  function moveCursorToEnd() {
    textbox.current?.focus()
    if (document.createRange && window.getSelection && textbox.current) {
      // Firefox, Chrome, Opera, Safari, IE 9+
      const range = document.createRange() // Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(textbox.current) // Select the entire contents of the element with the range
      range.collapse(false) // collapse the range to the end point. false means collapse to end rather than the start
      const selection = window.getSelection() // get the selection object (allows you to change selection)
      selection.removeAllRanges() // remove any selections already made
      selection.addRange(range) // make the range you have just created the visible selection
    }
  }

  function onTagSelected(tag: IOption): void {
    handleExternalValue(`{{${tag.value}}}`)
  }

  function handleEmojiClick(emoji: string): void {
    handleExternalValue(emoji)
  }

  useLayoutEffect(() => {
    if (restorePosition.current) {
      //* workaround to put the carret in a place it was before
      if (localValue.length === lastPosition.end) {
        moveCursorToEnd()
      } else {
        restoreSelection(textbox.current, lastPosition)
      }
      restorePosition.current = false
    }
  }, [localValue, lastPosition])

  return (
    <>
      {label && (
        <label className={cn({ 'error-label': invalid })} htmlFor={id.current}>
          {label} {required ? '*' : ''}
        </label>
      )}
      <div
        onDragEnter={(e) => e.stopPropagation()}
        draggable
        onDragStart={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className={cn(
          'position-relative',
          'form-control',
          'border-0',
          'mt-2',
          'p-0',
          'h-auto',
          {
            'error-input': invalid
          },
          {
            'disabled-field': disabled
          }
        )}>
        <div
          onClick={(e) => {
            if (!e.target.className.includes('preview__tag')) {
              moveCursorToEnd()
            }
          }}
          className={cn('position-relative', 'textarea', className, {
            'textarea--without-line-breaks': withoutLineBreaks,
            'textarea--disabled': disabled
          })}>
          <div
            data-testid="textarea"
            key={textboxKey.current}
            ref={textbox}
            id={id.current}
            tabIndex={0}
            role="textbox"
            className="textarea__input d-inline-block"
            contentEditable={!disabled}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onInput={handleInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPaste={handlePaste}
            suppressContentEditableWarning>
            <PreviewMergeTagText
              text={localValue}
              editable
              onChange={change}
              disabled={disabled}
            />
          </div>
          {placeholder && showPlaceholder && !disabled && (
            <span
              className={cn('textarea__placeholder', {
                'error-label': invalid
              })}>
              {placeholder}
            </span>
          )}
        </div>
        {mergeTags && !disabled && (
          <div className="textarea-extras">
            {max && (
              <div className="text-blue-gray mb-2 text-13 text-end">
                <span className="textarea__characters">{length}</span>/{max}
              </div>
            )}
            <div className="d-flex justify-content-end align-items-center">
              <EmojiPicker onChange={handleEmojiClick} disabled={disabled} />
              <SelectWithSearch
                right
                disabled={disabled}
                onChange={onTagSelected}
                controlShouldRenderValue={false}
                placeholder="Insert"
                className="w-auto ms-2 btn--hover"
                selectPlaceholder="Search..."
                dropdownWidth={200}
                options={mergeTags.map((mergeTag) => ({
                  label: mergeTag,
                  value: mergeTag
                }))}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
