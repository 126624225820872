import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row
} from 'reactstrap'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sectionLogo from './sectionLogo.svg'
import Input from '~/components/forms/Input'
import { useStore, withStore } from '~/dataStore'
import { updateFCMV1Settings } from '~/api/appSettings'
import { NotificationType, showNotification } from '~/utils/Notification'
import { showGeneralError } from '~/utils/validations'
import HintBtn from '~/HintWindow/HintBtn'
import HintModal from '~/HintWindow/HintModal'
import JsonUpload from './json_upload.png'

const FirebaseSection = (): React.ReactElement => {
  const {
    app: {
      appDetails,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const { appId } = useParams<{ appId: string }>()
  const hintRef = useRef()

  const {
    register,
    handleSubmit,

    formState: { errors, isSubmitting }
  } = useForm<{ file: FormData }>()

  function handleUploadSuccess() {
    showNotification(
      <p className="fw-bold text-white">Secret key uploaded successfully</p>,
      NotificationType.SUCCESS
    )
  }

  function handleUploadErrors(error: Error & { body: any }) {
    if (error.body?.errors) {
      if ('fcmJsonSecret' in error.body?.errors) {
        showNotification(
          error.body.errors.fcmJsonSecret[0],
          NotificationType.ERROR
        )
      }
    } else {
      showGeneralError()
    }
  }

  async function onSubmit(data: any): Promise<void> {
    const formData = new FormData()
    if (data.file instanceof FileList && data.file.length > 0) {
      formData.append('fcm_json_secret', data.file[0])
    }

    try {
      await updateFCMV1Settings(appId, formData)
      handleUploadSuccess()
    } catch (error: Error & { body: any }) {
      handleUploadErrors(error)
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h3" className="mb-0 mt-2">
            Firebase Cloud Messaging (FCM)
          </CardTitle>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)} className="py-3">
            <Row>
              <Col xs={8}>
                <div>
                  <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
                    <Label className="fw-medium form-label ">
                      Upload JSON Secret Key
                    </Label>
                    <div ref={hintRef}>
                      <HintBtn>How to get Json Secret Key</HintBtn>
                    </div>
                  </div>
                  <Input
                    register={register}
                    name="file"
                    className={cn('mb-3', {
                      'form-group--error': errors.file
                    })}
                    disabled={isSubmitting}
                    type="file"
                    accept=".json"
                    validation={{
                      required: {
                        value: true,
                        message: '.JSON file is missing.'
                      }
                    }}
                    errorTooltip={errors?.file?.message}
                    tooltip=""
                  />

                  <p>
                    <span className="text-muted">Current uploaded file:</span>{' '}
                    {appDetails.fcm.fcmFileIdentifier}
                  </p>
                </div>
                <Button
                  color="primary"
                  disabled={isSubmitting || isDemoAdmin}
                  type="submit"
                  className="mt-6 d-block">
                  Save Changes
                  {isSubmitting && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="xs"
                      className="ms-2"
                    />
                  )}
                </Button>
              </Col>
              <Col xs={4}>
                <img
                  className="mx-auto d-block pt-1 mt-4"
                  src={sectionLogo}
                  width={93}
                  alt="Firebase Cloud Messaging (FCM)"
                />
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <HintModal elem={hintRef} mediaFile={JsonUpload}>
        <h2>To generate a private key file for your service account:</h2>
        <ol className="ps-3">
          <li className="my-2">
            In the Firebase console, open Settings &gt;{' '}
            <a
              className="text-white text-decoration-underline"
              target="_blank"
              href="https://console.firebase.google.com/project/_/settings/serviceaccounts/adminsdk"
              rel="noreferrer">
              Service Accounts.
            </a>
          </li>
          <li className="my-2">
            Click Generate New Private Key, then confirm by clicking Generate
            Key.
          </li>
          <li className="my-2">Upload JSON file containing the key here.</li>
        </ol>
      </HintModal>
    </>
  )
}

export default withStore(FirebaseSection)
