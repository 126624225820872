import moment from 'moment'
import { Alert } from 'reactstrap'
import cn from 'classnames'
import { matchPath, useLocation } from 'react-router-dom'
import { useStore, withStore } from './dataStore'
import routes from '~/routes'

function PEMAlert(): React.ReactElement | null {
  const {
    app: {
      appDetails: { apns }
    }
  } = useStore()
  const location = useLocation()
  const isJourneysBuilderRoute = !!matchPath(location?.pathname, {
    path: [routes.journeysBuilder.path],
    exact: true
  })

  if (
    !apns.certificate.expired &&
    !apns.certificate.aboutToExpire &&
    !apns.certificate.errors?.length
  ) {
    return null
  }

  let message = null

  if (apns.certificate.expired) {
    message = `Your PEM Certificate expired on
        ${moment(apns.certificate.expiredAt).format(
          'D MMM YYYY'
        )}. Your campaigns will not be delivered to Apple or Android devices until your certificate is updated`
  } else if (apns.certificate.aboutToExpire) {
    message = `Your PEM Certificate will expire on
        ${moment(apns.certificate.expiredAt).format(
          'D MMM YYYY'
        )}. Please update your PEM
        certificate`
  }

  return (
    <Alert
      color="danger"
      className={cn('pem-alert', {
        'pem-alert--journeys': isJourneysBuilderRoute
      })}>
      {message}
      {apns.certificate.errors?.map((error: string) => error)}
    </Alert>
  )
}

export default withStore(PEMAlert)
