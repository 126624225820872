import CampaignSummaryRates from './CampaignSummaryRates'
import CampaignSummaryNumbers from './CampaignSummaryNumbers'
import { ISummary } from '~/dataStore/reports/Report.interface'

interface IProps {
  summaryRates: ISummary[]
  summaryNumbers: ISummary[]
  delivered: number | undefined
  sent: number | undefined
}

const CampaignSummary = ({
  summaryRates,
  summaryNumbers,
  delivered,
  sent
}: IProps): React.ReactElement => {
  return (
    <>
      <CampaignSummaryRates summaryRates={summaryRates} />
      <CampaignSummaryNumbers
        summaryNumbers={summaryNumbers}
        delivered={delivered}
        sent={sent}
      />
    </>
  )
}

export default CampaignSummary
