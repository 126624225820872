import React, { ReactNode } from 'react'
import cn from 'classnames'

interface IProps {
  children: ReactNode
  className?: string
  tag?: keyof JSX.IntrinsicElements
}

const Header = ({
  children,
  className,
  tag: Tag = 'div',
  ...rest
}: IProps): React.ReactElement => (
  <Tag className={cn('header', className)} {...rest}>
    {children}
  </Tag>
)

export default Header
