import React from 'react'
import './PushIcon.scss'

const PushIcon = ({ className }: { className: string }): React.ReactElement => (
  <svg
    id="pushAnimatedIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 136"
    width={72}
    height={136}
    className={className}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <rect
      width="70"
      height="134"
      rx="7"
      ry="7"
      transform="translate(1 1)"
      fill="#fff"
      stroke="#93a8c5"
      strokeWidth="2"
    />
    <rect
      width="58"
      height="102"
      rx="0"
      ry="0"
      transform="translate(7 17)"
      fill="#f4f7fc"
      stroke="#93a8c5"
      strokeWidth="2"
    />
    <g
      id="animatedObject_to"
      style={{
        offsetPath: "path('M36,75.5C35.356812,67.799469,35.5,60.5,36,52.5')",
        offsetRotate: '0deg'
      }}>
      <g id="animatedObject_ts" transform="scale(0.4,0.4)">
        <g id="animatedObject" transform="translate(-40,-51.5)" opacity="0">
          <rect
            width="45"
            height="48"
            rx="0"
            ry="0"
            transform="translate(17.5 27.5)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <rect
            width="45"
            height="20"
            rx="0"
            ry="0"
            transform="translate(17.5 34.5)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="21"
            y1="59.5"
            x2="49"
            y2="59.5"
            transform="translate(4 0)"
            fill="none"
            stroke="#93a8c5"
          />
          <line
            x1="24"
            y1="69.5"
            x2="46"
            y2="69.5"
            transform="translate(4 0)"
            fill="none"
            stroke="#93a8c5"
          />
          <line
            x1="18"
            y1="64.5"
            x2="53"
            y2="64.5"
            transform="translate(4 0)"
            fill="none"
            stroke="#93a8c5"
          />
        </g>
      </g>
    </g>
    <g id="animatedObject2_tr" transform="translate(36,85) rotate(0)">
      <rect
        id="animatedObject2"
        width="45"
        height="9"
        rx="2.5"
        ry="2.5"
        transform="translate(-22.5,-4.5)"
        opacity="0"
        fill="#93a8c5"
        stroke="#93a8c5"
      />
    </g>
  </svg>
)

export default PushIcon
