import React from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { withStore, useStore } from '~/dataStore'
import { hideSplashScreen } from '~/utils/splashScreen'
import { fetchEmailAddresses, removeEmailAddress } from '~/api/appSettings'
import { ID } from '~/common.interface'
import {
  IEmailAddress,
  EMAIL_VERIFICATION_LIST_QUERY
} from './EmailSection.interface'
import TableNew from '~/components/Table/TableNew'
import ActionsColumn from './components/ActionsColumn'
import VerificationStatusColumn from './components/VerificationStatusColumn'
import DeleteEmailAddressModal from './components/DeleteEmailAddressModal'
import AddEmailAddressModal from './components/AddEmailAddressModal'
import Tooltip from '~/components/reports/Tooltip'
import { showGeneralError } from '~/utils/validations'

import styles from './EmailSection.scss'
import { NotificationType, showNotification } from '~/utils/Notification'

const columns = [
  {
    fieldName: 'email',
    displayName: 'Email Address',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IEmailAddress }) => (
      <p className="mb-0">{row.email}</p>
    ),
    classes: styles.column
  },
  {
    fieldName: 'verified',
    displayName: 'Verification Status',
    // eslint-disable-next-line react/display-name
    sufix: () => (
      <Tooltip tooltip="To verify. Please check your mailbox for an email from AWS with Address Verification Request." />
    ),
    classes: styles.column,
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IEmailAddress }) => (
      <VerificationStatusColumn {...row} />
    )
  },
  {
    displayName: 'Actions',
    classes: styles.actionsColumn,
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IEmailAddress }) => (
      <ActionsColumn emailAddress={row} />
    )
  }
]

const EmailSection = () => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    },
    ui: { showModal }
  } = useStore()
  const queryClient = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  const { data: emails, isLoading } = useQuery(
    [EMAIL_VERIFICATION_LIST_QUERY],
    () =>
      fetchEmailAddresses(appId).finally(() => {
        hideSplashScreen()
      })
  )

  const onRemoveEmailAddress = async (id: ID, closeModal: () => void) => {
    try {
      await removeEmailAddress(appId, id)
      queryClient.invalidateQueries(EMAIL_VERIFICATION_LIST_QUERY)
      showNotification(
        'Successfully removed email address',
        NotificationType.SUCCESS
      )
      closeModal()
    } catch (error) {
      showGeneralError()
    }
  }

  return (
    <>
      <div className="ms-auto mt-5">
        <div className="bg-body-main rounded p-4 align-items-center">
          <h3 className="text-white mb-0">Email</h3>
        </div>
      </div>
      <div className="mt-4">
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <CardTitle tag="h3" className="mb-0 mt-2">
              Email Verification
            </CardTitle>
            <Button
              color="primary"
              className="btn--wide btn--hover mt-2"
              size="lg"
              disabled={isDemoAdmin}
              onClick={() => showModal('addEmailAddressModal')}>
              <FontAwesomeIcon icon={faPlus} size="xs" className="me-2" /> Add
              Address
            </Button>
          </CardHeader>
          <CardBody className="pt-0 pb-4">
            <TableNew
              isLoading={isLoading}
              columns={columns}
              rows={emails?.data || []}
            />
          </CardBody>
        </Card>
      </div>
      <AddEmailAddressModal />
      <DeleteEmailAddressModal removeEmailAddress={onRemoveEmailAddress} />
    </>
  )
}

export default withStore(EmailSection)
