import { ReactElement, ReactNode } from 'react'
import { Card } from 'reactstrap'
import CampaignIcon from '~/pages/Campaign/UI/CampaignIcon'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import './mobileCampaignPanel.scss'

interface IProps {
  name?: string
  campaignType?: CampaignType
  children: ReactNode
}

export default function MobileCampaignPanel({
  campaignType,
  name,
  children
}: IProps): ReactElement {
  return (
    <Card className="px-4 py-3 mobile-panel">
      {(name || campaignType) && (
        <div className="d-flex align-items-center mb-4">
          <CampaignIcon type={campaignType} className="mobile-panel__icon" />{' '}
          <h5 className="ms-2 mb-0">{name}</h5>
        </div>
      )}
      {children}
    </Card>
  )
}
