import cn from 'classnames'
import { PropsWithChildren, useRef } from 'react'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import CustomDropdown from '~/components/CustomDropdown'
import DynamicContainer from '~/components/DynamicContainer'
import {
  BlockTypeToBlockTitle,
  IBlock
} from '../Store/JourneyBuilder.interface'
import { Redirect } from '../Blocks'
import { withStore } from '~/dataStore'
import BlockIcon from '../components/BlockIcons'
import HintBtn from '~/HintWindow/HintBtn'
import HintModal from '~/HintWindow/HintModal'
import HowRedirectionFile from '~/HintWindow/mediaFiles/redirection.png'

interface IProps {
  block: Redirect
  disabled: boolean
  redirectTargets: IBlock[]
}

function BlockOption({
  className,
  onSelect,
  children
}: PropsWithChildren<{
  className?: string
  selected?: boolean
  onSelect?: () => void
}>): React.ReactElement {
  return (
    <DynamicContainer
      tagName={onSelect ? 'button' : 'div'}
      onClick={onSelect}
      className={cn(className)}>
      {children}
    </DynamicContainer>
  )
}
function RedirectPanel({
  block,
  disabled,
  redirectTargets
}: IProps): React.ReactElement {
  const hintRef = useRef()

  const selected = redirectTargets.find(
    (rt) => rt.blockID === block.redirectTo?.value
  )
  let placeholderText = 'Choose block'
  if (selected) {
    placeholderText = (
      <>
        <BlockIcon blockType={selected.blockType} color="blue" />{' '}
        {selected.name?.value || BlockTypeToBlockTitle.get(selected.blockType)}
      </>
    )
  }

  return (
    <div>
      <Header className="mb-6">
        <div className="d-flex align-items-center mb-2 px-4">
          <HeaderTitle tag="h2" className="mb-0">
            Redirection
          </HeaderTitle>
          <HintBtn className="ms-3">How redirection works</HintBtn>
        </div>
        <p className="color-subheader px-4" ref={hintRef}>
          Select where users should be redirected after reaching this point
        </p>
      </Header>
      <div className="d-flex align-items-center px-4">
        Users will be redirected to
        <CustomDropdown
          toggleAsButton
          disabled={disabled}
          wrapperClassname={cn('ms-3')}
          dropdownToggle={
            <BlockOption selected className="dropdown-toggle">
              <span>{placeholderText}</span>
            </BlockOption>
          }
          options={redirectTargets.map((rt) => ({
            isCustom: true,
            name(toggleDropdown) {
              return (
                <BlockOption
                  className={cn(
                    'py-2 px-3 shadow-none btn admin-option--hover w-100 text-left d-flex align-items-center'
                  )}
                  onSelect={() => {
                    block.setRedirectTo(rt)
                    toggleDropdown()
                  }}>
                  <>
                    <BlockIcon blockType={rt.blockType} color="blue" />
                    <span className="no-wrap ms-2">
                      {rt.name?.value ||
                        BlockTypeToBlockTitle.get(rt.blockType)}
                    </span>
                  </>
                </BlockOption>
              )
            }
          }))}
        />
      </div>
      <HintModal elem={hintRef} mediaFile={HowRedirectionFile}>
        <h2>Redirection</h2>
        <p>
          This powerful feature allows you to guide your users along a specific
          path based on their actions. When you add a redirect, you can define a
          desired action or condition that triggers the redirection. If a user
          meets the specified criteria, they will be automatically directed to
          another path within the journey. This enables you to tailor the user
          experience and ensure that each individual receives the most relevant
          content based on their engagement.
        </p>
        <p>
          Redirects help you optimize user engagement and drive desired outcomes
          by dynamically adjusting the journey flow.
        </p>
      </HintModal>
    </div>
  )
}

export default withStore(RedirectPanel)
