import { Users, Send } from 'react-feather'
import { faCalendarDay, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Row } from 'reactstrap'
import StatCard from '~/pages/App/Dashboard/UI/Statcard'

interface IProps {
  totalUsers: { valueCurrent: string; diffCurrent: string }
  dauMau: {
    dau: { valueCurrent: string; diffCurrent: string }
    mau: { valueCurrent: string; diffCurrent: string }
  }
  totalCampaignSends: { valueSum: string; diffSum: string }
  days: number
}

const AppStats = ({
  totalUsers,
  dauMau,
  totalCampaignSends,
  days
}: IProps): React.ReactElement => {
  const daysAgo = `${days} days`

  return (
    <div className="w-100">
      <Row>
        <StatCard
          title="Total Users"
          value={totalUsers.valueCurrent}
          diff={totalUsers.diffCurrent}
          diffDescription={daysAgo}
          icon={<Users className={cn('feather', 'align-middle')} />}
          tooltip="Total number of users with an active app downloaded to their device."
        />

        <StatCard
          title="Daily Active Users"
          value={dauMau.dau.valueCurrent}
          diff={dauMau.dau.diffCurrent}
          diffDescription={daysAgo}
          icon={
            <FontAwesomeIcon
              icon={faCalendarDay}
              className={cn('feather', 'align-middle')}
            />
          }
          tooltip="Total number of users who logged in yesterday."
        />

        <StatCard
          title="Monthly Active Users"
          value={dauMau.mau.valueCurrent}
          diff={dauMau.mau.diffCurrent}
          diffDescription={daysAgo}
          icon={
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className={cn('feather', 'align-middle')}
            />
          }
          tooltip="Number of users active in the last 30 days"
        />

        <StatCard
          title="Total Campaign Sends"
          value={totalCampaignSends.valueSum}
          diff={totalCampaignSends.diffSum}
          diffDescription={daysAgo}
          icon={<Send className={cn('feather', 'align-middle')} />}
          tooltip="Total unique messages sent from all campaigns"
        />
      </Row>
    </div>
  )
}

export default AppStats
