import { action, computed, observable, makeObservable } from 'mobx'

export default abstract class Target<T> {
  isActive = false

  isValid = true

  collection: T[] = []

  selected: Map<string, T> = new Map()

  page = 0

  hasMore = true

  searchQuery = ''

  selectedGroups = []

  groups = []

  totalCount = 0

  constructor() {
    makeObservable(this, {
      isActive: observable,
      isValid: observable,
      collection: observable,
      selected: observable,
      page: observable,
      hasMore: observable,
      searchQuery: observable,
      selectedGroups: observable,
      groups: observable,
      totalCount: observable,
      selectedList: computed,
      selectedCount: computed,
      setActive: action.bound,
      setSelectedGroups: action.bound,
      setSearchQuery: action.bound,
      validate: action.bound
    })
  }

  get selectedList(): T[] {
    return this.isActive ? Array.from(this.selected.values()) : []
  }

  get selectedCount(): number {
    return this.isActive ? this.selected.size : 0
  }

  setActive(value: boolean): void {
    this.isActive = value
  }

  setSelectedGroups(groupIds: never[]): void {
    this.selectedGroups = groupIds
  }

  setSearchQuery(txt: string): void {
    this.searchQuery = txt
    this.page = 1
  }

  public validate(): boolean {
    this.isValid = true
    if (this.isActive && !this.selected.size) {
      this.isValid = false
    }

    return this.isValid
  }

  public getSelectedIds(): string[] {
    return this.isActive ? Array.from(this.selected.keys()) : []
  }

  public clearSelected(): void {
    this.selected = new Map()
  }
}
