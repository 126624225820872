import React from 'react'
import { Button } from 'reactstrap'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '~/components/modals/Modal/Modal'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import { hideSplashScreen } from '~/utils/splashScreen'
import EditAddAdminModal from '../EditAddAdminModal'
import { fetchAdmins, removeAdmin } from '~/api/admins'
import {
  AdminsDTO,
  ADMINS_LIST_QUERY
} from '~/pages/AccountSettings/AccountSettings.interface'
import { ADMIN_ROLES, ID } from '~/common.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import AdminListItem from './AdminListItem'
import { withStore, useStore } from '~/dataStore'

import styles from './AdminsList.scss'

const AdminsList = ({ appId }: { appId: ID }): React.ReactElement => {
  const {
    ui: { showModal },
    app: { currentAdmin }
  } = useStore()
  const queryClient = useQueryClient()
  const { data } = useQuery([ADMINS_LIST_QUERY], () =>
    fetchAdmins(appId).finally(() => {
      hideSplashScreen()
    })
  )

  const handleRemoveAdmin = async (id: ID) => {
    try {
      await removeAdmin(appId, id)
      queryClient.setQueryData<AdminsDTO>([ADMINS_LIST_QUERY], (data) => ({
        metadata: data.metadata,
        data: data.data?.filter((a) => !id?.includes(a.id))
      }))
      showNotification(`Admin successfully removed`, NotificationType.SUCCESS)
    } catch (error) {
      showNotification('Failed to remove Admin', NotificationType.ERROR)
    }
  }

  return (
    <>
      <div className={styles.list}>
        {data?.data.slice(0, 4).map((admin) => (
          <AdminListItem key={admin.id} admin={admin} />
        ))}
        {currentAdmin.role === ADMIN_ROLES.MASTER_ADMIN && (
          <Button
            color=""
            data-testid="addAdminBtn"
            onClick={() => showModal('editAddAdminModal', { isAdding: true })}
            className="card btn--hover btn--wide btn bg-concrete btn-lg border border-blue-gray d-flex align-items-center justify-content-center mb-0">
            <span className="text-muted">
              <FontAwesomeIcon icon={faPlus} size="xs" className="me-1" />
              <p>Add new Admin</p>
            </span>
          </Button>
        )}
        {data?.data.slice(4, data?.data.length).map((admin) => (
          <AdminListItem key={admin.id} admin={admin} />
        ))}
      </div>
      <Modal
        id="confirmAdminDeletion"
        className=""
        renderHeader={() => 'Delete Admin'}
        render={(close: () => void, params) => (
          <ConfirmationModal
            onSubmit={() => {
              handleRemoveAdmin(params?.id)
              close()
            }}
            onClose={close}
            okText="Delete"
            cancelText="Cancel">
            Are you sure you want to delete this admin?
          </ConfirmationModal>
        )}
      />
      <EditAddAdminModal />
    </>
  )
}

export default withStore(AdminsList)
