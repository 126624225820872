import { action, computed, observable, makeObservable } from 'mobx'
import { IBeaconTarget, Trigger } from '../EmailBuilder.interface'
import { MTrigger } from '../EmailBuilder.map'

export default class Beacon implements IBeaconTarget {
  battery: string

  beaconEventsCount: number

  beaconId: string

  createdAt: string

  groupIds: any[]

  id: string

  major: number

  minor: number

  nickname: string

  uuid: string

  trigger: Trigger | undefined | null = undefined

  selectBeacon: (beacon: Beacon, isSelected: boolean, trigger?: Trigger) => void

  constructor(
    beacon: IBeaconTarget,
    select: (x: Beacon, isSelected: boolean, trigger?: Trigger) => void
  ) {
    makeObservable(this, {
      trigger: observable,
      isEnterTrigger: computed,
      name: computed,
      isExitTrigger: computed,
      triggerLabel: computed,
      setTrigger: action.bound,
      toggleEntering: action.bound,
      toggleLeaving: action.bound
    })

    this.battery = beacon.battery
    this.beaconEventsCount = beacon.beaconEventsCount
    this.beaconId = beacon.beaconId
    this.createdAt = beacon.createdAt
    this.groupIds = beacon.groupIds
    this.id = beacon.id
    this.major = beacon.major
    this.minor = beacon.minor
    this.nickname = beacon.nickname
    this.uuid = beacon.uuid
    this.selectBeacon = select
  }

  public get isEnterTrigger(): boolean {
    return this.trigger === Trigger.ENTER
  }

  public get name(): string {
    return this.nickname
  }

  public get isExitTrigger(): boolean {
    return this.trigger === Trigger.EXIT
  }

  get triggerLabel(): string | undefined {
    if (this.trigger) {
      return MTrigger.get(this.trigger)
    }

    return undefined
  }

  public setTrigger(trigger: Trigger | null): void {
    this.trigger = trigger
  }

  public toggleEntering(): void {
    if (this.trigger === Trigger.ENTER) {
      this.selectBeacon(this, false)
    } else {
      this.selectBeacon(this, true, Trigger.ENTER)
    }
  }

  public toggleLeaving(): void {
    if (this.trigger === Trigger.EXIT) {
      this.selectBeacon(this, false)
    } else {
      this.selectBeacon(this, true, Trigger.EXIT)
    }
  }
}
