import React, { useState } from 'react'
import { faPause, faPlay, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import StatusPill from '~/components/Filters/StatusPill'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'

import styles from './StatusColumn.scss'
import { ID } from '~/common.interface'

interface IListItem {
  status: CampaignStatus | JourneyStatus
  id: ID
  actions: { pause: boolean; resume: boolean }
  pause: ({ id, name }: { id: ID; name: string }) => void
  resume: ({ id, name }: { id: ID; name: string }) => void
  name: string
}

const StatusColumn = ({
  row,
  pauseStatues,
  pauseTooltip = 'Pause',
  ariaLabel
}: {
  row: IListItem
  pauseStatues: (CampaignStatus | JourneyStatus)[]
  pauseTooltip?: string
  ariaLabel?: string
}): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const { status, id, actions, pause, resume, name } = row

  const pauseResume = async () => {
    try {
      setIsLoading(true)
      if (actions.resume) {
        await resume({ id, name })
      } else if (actions.pause) {
        await pause({ id, name })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.statusColumn}>
      <StatusPill
        status={row.status}
        //   row.status === JourneyStatus.PREPARING
        //     ? `Preparing ${row.progress}%...`
        //     : row.status
        // }
        // progress={
        //   row.status === JourneyStatus.PREPARING ? row.progress : undefined
        // }
        width={115}>
        <>
          {status}
          {(row.actions?.pause || row.actions?.resume) &&
            pauseStatues.includes(status) && (
              <Button
                id={`table-item-status-${id}`}
                onClick={pauseResume}
                color=""
                className={styles.pauseResumeWrapper}
                aria-label={ariaLabel}>
                {!isLoading && (
                  <FontAwesomeIcon
                    size="xs"
                    icon={status !== CampaignStatus.PAUSED ? faPause : faPlay}
                  />
                )}
                {isLoading && (
                  <FontAwesomeIcon size="xs" spin icon={faSpinner} />
                )}
                <UncontrolledTooltip
                  target={`table-item-status-${id}`}
                  placement="right">
                  {status !== CampaignStatus.PAUSED ? pauseTooltip : 'Resume'}
                </UncontrolledTooltip>
              </Button>
            )}
        </>
      </StatusPill>
    </div>
  )
}

export default StatusColumn
