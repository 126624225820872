import { Col, Row } from 'reactstrap'
import TopCampaigns from './TopCampaigns'
import TopNotifications from '~/pages/OpportunitiesDashboard/PerformanceRow/TopNotifications'

const PerformanceRow = () => {
  return (
    <Row className="mb-5">
      <Col xs={7} xxl={7} xxxl={8} widths={['xxl', 'xxxl']}>
        <TopCampaigns />
      </Col>
      <Col xs={5} xxl={5} xxxl={4} widths={['xxl', 'xxxl']}>
        <TopNotifications />
      </Col>
    </Row>
  )
}

export default PerformanceRow
