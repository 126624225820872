import { ReactElement } from 'react'
import { Col, Row } from 'reactstrap'
import AllMobileDevicesPreviews from '~/pages/Campaign/Notification/Preview/AllMobileDevicesPreview'
import { withStore } from '~/dataStore'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import MobileCampaignPanel from '../../../../CampaignBuilder/MobileCampaignPanel/MobileCampaignPanel'
import CardBack from './CardBack'
import { CollapseContextProvider } from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import CardNotification from '../Model/CardNotification'

interface IProps {
  card: CardNotification
  isSended: boolean
  onlyCarousel?: boolean
  withEvent?: boolean
  withGoals?: boolean
  colsWidth?: {
    left: { xxl: { size: number } }
    right: { xxl: { size: number } }
  }
}

function CardBackStep({
  card,
  isSended,
  colsWidth,
  onlyCarousel,
  withGoals,
  withEvent
}: IProps): ReactElement {
  const fieldsDictionary = {
    admin_header_with_message: 'adminHeader',
    image: 'image',
    headline: 'headline',
    text: 'text',
    table: 'table',
    headline_table: 'table',
    button: 'actionButtons'
  }

  return (
    <CollapseContextProvider fieldMapper={fieldsDictionary}>
      <Row className="align-items-start">
        <Col
          widths={['md', 'xxl']}
          md={{ size: 7 }}
          {...colsWidth?.left}
          className="mb-4">
          <AllMobileDevicesPreviews
            onlyCarousel={onlyCarousel}
            parts={card.generatePreview().card.back}
            campaignType={CampaignType.CARD_BACK}
          />
        </Col>
        <Col widths={['md', 'xxl']} md={{ size: 5 }} {...colsWidth?.right}>
          <MobileCampaignPanel>
            <CardBack
              notification={card.cardBack}
              shared={card.shared}
              isSended={isSended}
              withGoals={withGoals}
              notificationGoals={card.goals}
              withEvent={withEvent}
            />
          </MobileCampaignPanel>
        </Col>
      </Row>
    </CollapseContextProvider>
  )
}

export default withStore(CardBackStep)
