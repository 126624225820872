import { computed, makeObservable } from 'mobx'
import { ICampaignModel } from '~/dataStore/Campaign/Campaign.interface'
import { DELIVERY } from '~/pages/CampaignBuilder/Email/consts'
import Targeting from '../Targeting/Targeting'

export default class DeliverUI {
  target: Targeting

  deliver: any

  campaignBuilder: ICampaignModel

  constructor(campaignBuilder: ICampaignModel, targeting: Targeting, deliver) {
    makeObservable(this, {
      timeWindowsActiveAndHasValues: computed,
      isDeliveryFuture: computed,
      isDeliveryCurrent: computed,
      isDeliveryCurrentOrFuture: computed,
      startNowToggleHasValue: computed,
      isPanelsContainerActive: computed,
      isLimitsPanelBodyCollapsed: computed,
      isStartPanelCollapsed: computed,
      isStartPanelBodyCollapsed: computed,
      isStartEndSectionCollapsed: computed,
      isExpiryDateSectionCollapsed: computed,
      isDeliveryFutureOrDefault: computed,
      isEndDate: computed,
      isTimeWindows: computed
    })

    this.target = targeting
    this.deliver = deliver
    this.campaignBuilder = campaignBuilder
  }

  get timeWindowsActiveAndHasValues(): boolean {
    return !!(this.deliver.timeWindowsActive && this.deliver.timeWindowsTotal)
  }

  get isDeliveryFuture(): boolean {
    return this.deliver.delivery.value === DELIVERY.CURRENT_FUTURE
  }

  get isDeliveryCurrent(): boolean {
    return this.deliver.delivery.value === DELIVERY.CURRENT
  }

  get isDeliveryCurrentOrFuture(): boolean {
    return [DELIVERY.CURRENT, DELIVERY.CURRENT_FUTURE].includes(
      this.deliver.delivery.value
    )
  }

  // UI
  // Check if any value of campaign start toggle is selected
  get startNowToggleHasValue(): boolean {
    return this.deliver.startNow.value !== ''
  }

  // Panels
  get isPanelsContainerActive(): boolean {
    return (
      !this.target.isSegmentsOnlyActiveTarget || this.isDeliveryCurrentOrFuture
    )
  }

  get isLimitsPanelBodyCollapsed(): boolean {
    return this.isDeliveryFutureOrDefault && this.deliver.limits.value === true
  }

  get isStartPanelCollapsed(): boolean {
    return (
      this.isDeliveryCurrent ||
      (this.isDeliveryFutureOrDefault && this.deliver.limits.value !== '')
    )
  }

  get isStartPanelBodyCollapsed(): boolean {
    return (
      this.startNowToggleHasValue &&
      (this.campaignBuilder.isCardOrInAppCampaign ||
        !(
          this.isDeliveryCurrent &&
          this.deliver.startNow.value === true &&
          !this.campaignBuilder.isCardOrInAppCampaign
        ) ||
        !this.target.isSegmentsOnlyActiveTarget)
    )
  }

  // UI
  // Sections inside Panels
  get isStartEndSectionCollapsed(): boolean {
    return this.deliver.startNow.value === false
  }

  get isExpiryDateSectionCollapsed(): boolean {
    return this.campaignBuilder.isCardOrInAppCampaign
  }

  get isDeliveryFutureOrDefault(): boolean {
    return this.isDeliveryFuture || !this.target.isSegmentsOnlyActiveTarget
  }

  get isEndDate(): boolean {
    return (
      this.isDeliveryFutureOrDefault &&
      this.isStartEndSectionCollapsed &&
      this.deliver.endAtActive
    )
  }

  get isTimeWindows(): boolean {
    return this.isDeliveryFutureOrDefault && this.timeWindowsActiveAndHasValues
  }
}
