import cn from 'classnames'
import DynamicContainer from '~/components/DynamicContainer'
import CustomDropdown from '~/components/CustomDropdown'
import { withStore } from '~/dataStore'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { getCampaignTypeOptions } from '~/dataStore/CampaignTypeFacade'
import CampaignTypeSwitch from '~/pages/Campaign/CampaignReports/CampaignTypeSwitch'
import { NotificationType } from '~/pages/Campaign/Notification/Notification.interface'
import { MessageBlock } from '../../Store/JourneyReport.interface'
import styles from './MessageDropdown.scss'

interface IProps {
  messages: MessageBlock[]
  onMessageSelect: (messageBlock: MessageBlock) => void
  onNotificationSelect: (type: NotificationType) => void
  selectedMessage?: MessageBlock
  selectedNotificationType: NotificationType
}

function MessageOption({
  message,
  className,
  selected,
  onSelect
}: {
  message: MessageBlock
  className?: string
  selected?: boolean
  onSelect?: () => void
}): React.ReactElement {
  return (
    <DynamicContainer
      tagName={onSelect ? 'button' : 'div'}
      onClick={onSelect}
      className={cn(className, styles.messageDropdown)}>
      {selected && <span className="text-blue-gray me-2">Message</span>}{' '}
      <span>{message.name}</span>
    </DynamicContainer>
  )
}

function MessageDropdown({
  messages,
  onMessageSelect,
  onNotificationSelect,
  selectedMessage,
  selectedNotificationType
}: IProps): React.ReactElement | null {
  if (!selectedMessage) {
    return null
  }

  function extractTypesFromSelectedMessages(
    message: MessageBlock
  ): NotificationType[] {
    return message.options.map((notification) => notification.type)
  }

  function fillCardSides(message: MessageBlock) {
    return message.options.reduce((a, notification) => {
      if (notification.type === CampaignType.CARD) {
        a.card = {}
        notification.notifications.forEach((n) => {
          n.notificationSides.forEach((side) => (a.card[side] = {}))
        })
      }

      return a
    }, {})
  }

  return (
    <div className="d-flex justify-content-between align-items-center my-4 ">
      <CustomDropdown
        dropdownToggle={
          <MessageOption
            selected
            message={selectedMessage}
            className="dropdown-toggle text-24"
          />
        }
        options={messages.map((m) => ({
          isCustom: true,
          name(toggleDropdown) {
            return (
              <MessageOption
                message={m}
                className={cn(
                  'py-2 px-3 shadow-none btn admin-option--hover w-100 text-start'
                )}
                onSelect={() => {
                  onMessageSelect(m)
                  toggleDropdown()
                }}
              />
            )
          }
        }))}
      />

      <CampaignTypeSwitch
        toggle={onNotificationSelect}
        classes="tabs--big"
        activeTab={selectedNotificationType}
        tabs={getCampaignTypeOptions(
          extractTypesFromSelectedMessages(selectedMessage),
          fillCardSides(selectedMessage)
        )}
      />
    </div>
  )
}

export default withStore(MessageDropdown)
