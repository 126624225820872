import { Button } from 'reactstrap'
import cn from 'classnames'
import Journey from './journeys_teaser.svg'
import styles from './JourneyTeaser.scss'
import TeaserCTA from './UI/TeaserCTA'
import About from './UI/About'

export default function JourneyTeaser(): React.ReactElement {
  return (
    <div className={cn(styles.container)}>
      <div className={cn('d-flex align-items-center', styles.teaser)}>
        <div className={cn(styles.teaserCol, styles.teaserDescriptionCol)}>
          <p className={cn(styles.teaserDescription)}>
            Deliver uniquely personalized member experiences to drive
            engagement, conversion and revenue with less effort for better
            results.
          </p>
          <Button
            tag="a"
            color="body"
            target="_blank"
            className={cn('bold', styles.teaserBtn)}
            href="https://info.pulsatehq.com/pulsate-overview">
            Register Interest
          </Button>
        </div>
        <div className={cn(styles.teaserCol)}>
          <img
            alt="example journey"
            src={Journey}
            className={cn(styles.teaserDiagram)}
          />
        </div>
      </div>

      <About />

      <TeaserCTA />
    </div>
  )
}
