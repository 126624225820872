import { action, makeObservable, observable } from 'mobx'
import { ID } from '~/common.interface'
import { ValidationTypes } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import CTAField from '~/dataStore/emailBuilder/Fields/CTA.field'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import {
  GoalSide,
  GoalSourceType,
  GoalType,
  INotificationGoal,
  INotificationGoalDTO
} from './NotificationGoals.interface'

export default class NotificaitonGoals extends NewRegisteredField {
  public goals: INotificationGoal[] = []

  constructor() {
    super('')
    makeObservable<NotificaitonGoals, 'toggleGoal'>(this, {
      goals: observable,
      toggleGoal: action.bound,
      fillGoals: action.bound,
      updateBtnLabelGoal: action.bound
    })

    this.setGoal = this.setGoal.bind(this)
  }

  public setGoal({
    type,
    checked,
    source,
    side = 'front',
    buttonLabel,
    reference,
    buttonID
  }: {
    type: GoalType
    checked: boolean
    source: GoalSourceType
    side?: GoalSide
    buttonLabel?: string
    reference?: string
    buttonID?: ID
  }): void {
    if (!checked) {
      this.toggleGoal(undefined, type)
    } else if (!this.getByGoalType(type)) {
      this.resetError()
      const actionId = buttonID || buttonLabel || reference || source
      this.unselectSameAction(type, actionId)
      this.toggleGoal(
        { source, type, buttonLabel, reference, side, buttonID },
        type
      )
    }
  }

  public updateBtnLabelGoal(
    actionId: string,
    buttonLabel: string | undefined
  ): void {
    const selected = this.actionIsGoal(actionId)
    if (selected) {
      selected.buttonLabel = buttonLabel
    }
  }

  public isChecked({
    sourceType,
    buttonLabel,
    reference
  }: {
    sourceType: GoalSourceType
    buttonLabel?: string
    reference?: string
  }): GoalType | undefined {
    const actionId = buttonLabel || reference || sourceType
    const selected = this.actionIsGoal(actionId)

    return selected?.type
  }

  public removeGoals(
    sourceType: GoalSourceType,
    side: GoalSide = 'front'
  ): void {
    this.goals = this.goals.filter(
      (g) => !(g.source === sourceType && g.side === side)
    )
  }

  public removeGoalByReference(reference: string): void {
    this.goals = this.goals.filter((g) => g.reference !== reference)
  }

  public get selectedGoalTypes(): GoalType[] {
    return this.goals.map((g) => g.type)
  }

  public fillGoals(goals: INotificationGoalDTO[], buttons?: CTAField[]): void {
    const goalsWithButtonID = goals.map((goal) => {
      if (goal.source !== 'button') {
        return goal
      }

      const btn = buttons?.find(
        (b) => b.value === goal.buttonLabel && b.side === goal.side
      )

      if (btn) {
        return {
          ...goal,
          buttonID: btn.buttonID
        }
      }

      return goal
    })

    this.goals = goalsWithButtonID
  }

  public getPayload(): INotificationGoalDTO[] {
    return this.goals.map((g) => ({
      source: g.source,
      type: g.type,
      buttonLabel: g.buttonLabel,
      reference: g.reference,
      side: g.side
    }))
  }

  private getByGoalType(goalType: GoalType): INotificationGoal | undefined {
    return this.goals.find((goal) => goal.type === goalType)
  }

  private toggleGoal(
    goal: INotificationGoal | undefined,
    type: GoalType
  ): void {
    if (goal) {
      this.goals.push(goal)
    } else {
      this.goals = this.goals.filter((g) => g.type !== type)
    }
  }

  private unselectSameAction(goalType: GoalType, actionId: string): void {
    const oppositeGoalType = goalType === 'primary' ? 'secondary' : 'primary'
    if (this.actionIsGoal(actionId)) {
      this.toggleGoal(undefined, oppositeGoalType)
    }
  }

  private actionIsGoal(actionId: string): INotificationGoal | undefined {
    return this.goals.find((g) =>
      [g.buttonLabel, g.source, g.reference, g.buttonID].includes(actionId)
    )
  }

  public validate(
    notificationActions?:
      | { sourceType: GoalSourceType; active: boolean }[]
      | string
      | undefined
  ): boolean {
    this.resetError()

    let withoutInactive = this.goals

    if (Array.isArray(notificationActions)) {
      const inactve = notificationActions
        .filter((a) => !a.active)
        .map((a) => a.sourceType)

      withoutInactive = this.goals.filter((g) => !inactve.includes(g.source))
    }

    if (withoutInactive.length === 0) {
      this.isValid = false
      this.errors = [
        {
          type: ValidationTypes.REQUIRED,
          message: 'Please choose at least one goal'
        }
      ]
    }
    return this.isValid
  }
}
