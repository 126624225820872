import React from 'react'
import { faStop, faTrash } from '@fortawesome/free-solid-svg-icons'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import { IJourney } from '~/pages/Journeys/JourneysList/JourneysList.interface'
import { useStore, withStore } from '~/dataStore'
import CustomDropdown from '~/components/CustomDropdown'
import SearchInput from '~/components/forms/SearchInput'
import StatusOption from '~/components/Filters/StatusOption'
import MultiSelect from '~/components/forms/MultiSelect'
import { ID } from '~/common.interface'

const JourneysListToolbar = ({
  selectedJourneys,
  handleSearch,
  searchQuery,
  status,
  onFilterByStatus,
  actions: { onDeleteRows, onStopRows }
}: {
  actions: {
    onDeleteRows: () => void
    onStopRows: () => void
  }
  selectedJourneys: Map<ID, IJourney>
  searchQuery: string
  handleSearch: (value: string) => void
  status: JourneyStatus[]
  onFilterByStatus: (values: string[]) => void
}) => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()

  const batchActions = [
    {
      name: 'Stop Journeys',
      icon: faStop,
      action: onStopRows
    },
    {
      name: 'Delete Journeys',
      icon: faTrash,
      action: onDeleteRows
    }
  ]

  return (
    <div className="journeys-list__toolbar d-flex">
      {!isDemoAdmin && (
        <CustomDropdown
          options={batchActions}
          selectedItems={selectedJourneys}
          visible={selectedJourneys.size > 0}
          className="me-3"
        />
      )}
      <SearchInput
        placeholder="Search for a journey"
        initialValue={searchQuery}
        onChange={handleSearch}
        className="me-3 flex-0-1"
      />
      <MultiSelect
        title="Status"
        selectedOptions={status}
        onSelect={onFilterByStatus}
        resetOptionName="Show All"
        options={[
          {
            name: <StatusOption name="Draft" status={JourneyStatus.DRAFT} />,
            value: 'draft'
          },
          {
            name: (
              <StatusOption name="Scheduled" status={JourneyStatus.SCHEDULED} />
            ),
            value: 'scheduled'
          },
          {
            name: <StatusOption name="Active" status={JourneyStatus.ACTIVE} />,
            value: 'active'
          },
          {
            name: <StatusOption name="Paused" status={JourneyStatus.PAUSED} />,
            value: 'paused'
          },
          {
            name: (
              <StatusOption name="Stopped" status={JourneyStatus.STOPPED} />
            ),
            value: 'stopped'
          },
          {
            name: (
              <StatusOption name="Delivered" status={JourneyStatus.DELIVERED} />
            ),
            value: 'delivered'
          }
        ]}
      />
    </div>
  )
}

export default withStore(JourneysListToolbar)
