import React from 'react'
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'

interface IProps {
  name: string
  learnMoreLink?: string
  children: React.ReactElement
}

const SectionBody = ({ name, learnMoreLink, children }: IProps) => {
  return (
    <Card className="flex-fill w-100" key={name}>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h3" className="mb-0 mt-2">
          {name}
        </CardTitle>
        {learnMoreLink && (
          <a
            href={learnMoreLink}
            className="text-nowrap d-inline-block"
            target="_blank"
            rel="noreferrer">
            Learn more
          </a>
        )}
      </CardHeader>
      <CardBody className="mt-2 pb-4">{children}</CardBody>
    </Card>
  )
}

export default SectionBody
