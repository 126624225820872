import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import { ID } from '~/common.interface'
import { fetchReports } from '../Model/Report.connector'
import {
  ICampaignReportDTO,
  IReportListDTO
} from './CampaignReportList.interface'

export enum ServerStateKeysEnum {
  CampaignReports = 'campaignReports'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCampaignReports(
  appId: ID,
  queryParams: Omit<IReportListDTO, 'page' | 'perPage'>
) {
  return useInfiniteQuery(
    [ServerStateKeysEnum.CampaignReports, { ...queryParams }],
    ({ pageParam = 1 }) =>
      fetchReports(appId, { ...queryParams, page: pageParam }),
    {
      select: (
        data
      ): InfiniteData<ICampaignReportDTO> & { bulkActions: any } => {
        const metadata = data.pages.at(-1)?.metadata || { totalPages: 0 }
        return {
          ...data,
          pages: data.pages.flatMap((page) => page.data),
          metadata
        }
      },
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.totalPages > lastPage.metadata.page
          ? lastPage.metadata.page + 1
          : undefined
    }
  )
}
