import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { useJsApiLoader } from '@react-google-maps/api'
import { useHistory } from 'react-router-dom'
import HeaderTitle from '~/components/HeaderTitle'
import Header from '~/components/Header'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import useQueryParams from '~/hooks/useQueryParams'
import AddGeofencesModal from './components/AddGeofencesModal'
import GeofencesList from './components/GeofencesList'
import GeofencesListToolbar from './components/GeofencesListToolbar'
import GeofencesMap from './components/GeofencesMap'
import geofencesUploadHelp from './components/geofences-upload-help.svg'
import geofencesUploadHelpFeet from './components/geofences-upload-help-feet.svg'
import Modal from '~/components/modals/Modal'
import DeleteElementsModal from '~/components/modals/DeleteElementsModal'
import AddElementsToGroupModal from '~/components/Table/components/AddElementsToGroup'
import RemoveElementsFromGroupModal from '~/components/Table/components/RemoveElementsFromGroup'
import EditGroupModal from '~/components/Table/components/EditGroupModal'
import DeleteGroup from '~/components/Table/components/DeleteGroup'
import CreateGroupModal from '~/components/Table/components/CreateGroupModal'
import GeofencesActions from './components/GeofencesActions'
import { useStore, withStore } from '~/dataStore'
import { getGeofenceLatLng } from '~/pages/Geofences/utils'
import BatchUpload from '~/components/BatchUpload'
import { IGeofence } from './Geofences.interface'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import {
  GEOFENCES_QUERY_PARAMS,
  getErrorsDictionary,
  MAP_LIBRARIES
} from '~/pages/Geofences/consts'
import useGeofencesStore from './Geofences.context'
import BestPracticesBar from '~/components/common/BestPracticesBar'
import { NotificationType, showNotification } from '~/utils/Notification'
import { getGroups } from '~/api/groups'

import './Geofences.scss'

const Geofences = () => {
  const {
    batchCreateGeofencesManually,
    disableAllShapesEdition,
    selectedGeofencesList,
    removeTempGeofence,
    setInfoWindowOpen,
    selectedGeofence,
    setShapeEditable,
    selectedGeofences,
    isGeofenceSaving,
    isInfoWindowOpen,
    polygonGeofences,
    circleGeofences,
    addTempGeofence,
    removeGeofences,
    removeGeofence,
    polygonsActive,
    selectGeofence,
    fetchGeofences,
    checkGeofence,
    geofencesList,
    saveGeofence,
    editGeofence,
    setMapCenter,
    bulkActions,
    mapCenter,
    geofences,
    metadata
  } = useGeofencesStore()

  const {
    app: {
      googleMapsApiKey,
      appDetails: {
        featureFlags,
        geofenceSetting: { geofenceFeetUnit, minRadius, maxRadius }
      },
      currentApp: { id: appId }
    },
    groups: {
      groups,
      addElementsToGroup,
      removeElementsFromGroup,
      editGroup,
      removeGroup,
      createGroup,
      isPerforming,
      setGroups
    },
    ui: { showModal }
  } = useStore()

  const [map, setMap] = useState<google.maps.Map | undefined>()
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>()
  const [fullScreen, setFullScreen] = useState(false)
  const history = useHistory()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: MAP_LIBRARIES,
    googleMapsApiKey
  })
  const {
    getPaginationQuery,
    searchQuery,
    handleSearch,
    setPage,
    handleSort,
    sortField,
    order
  } = usePaginationQuery<IGeofence>({
    perPage: 1000,
    sortField: ''
  })
  const queryParams = useQueryParams(GEOFENCES_QUERY_PARAMS)
  const fetchList = () => {
    fetchGeofences({
      ...getPaginationQuery(),
      groupIds: queryParams.groupIds,
      shape: queryParams.shape,
      units: queryParams.units
    })
  }

  const goToUserLocation = () => {
    setMapCenter({
      lat: metadata?.location[0],
      lng: metadata?.location[1]
    })
  }

  const scrollToGeofence = () => {
    const selectedGeofenceOnTheList = document.querySelector(
      `[data-geofence-id="${selectedGeofence}"]`
    )
    if (selectedGeofenceOnTheList) {
      selectedGeofenceOnTheList.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      })
    }
  }

  const showRemoveGeofencesModal = () => {
    showModal('deleteElementsModal', {
      elements: selectedGeofencesList,
      url: bulkActions?.delete,
      onSuccess: removeGeofences
    })
  }

  const showRemoveGeofenceModal = (row) => {
    showModal('deleteElementsModal', {
      elements: [row],
      url: bulkActions?.delete,
      onSuccess: removeGeofences
    })
  }

  useEffect(() => {
    if (selectedGeofence) {
      scrollToGeofence()
      setShapeEditable(selectedGeofence)
      map.panTo(getGeofenceLatLng(geofences.get(selectedGeofence)))
      setInfoWindowOpen(true)
    }
  }, [selectedGeofence])

  useEffect(() => {
    if (featureFlags.geofences) {
      fetchList()
    } else {
      showNotification(
        'Sorry, but Geofences are not enabled on your app. Please contact support@pulsatehq.com for more information.',
        NotificationType.INFO
      )
      history.push('/')
    }
  }, [
    queryParams.groupIds,
    queryParams.shape,
    queryParams.units,
    searchQuery,
    featureFlags.geofences
  ])

  useEffect(() => {
    getGroups(appId, 'geofences').then((response) => setGroups(response.data))

    return () => {
      setGroups([])
    }
  }, [appId])

  return (
    <>
      <BestPracticesBar moduleName="geofences" />
      <Container fluid>
        <div>
          <div className="d-flex align-items-center ms-auto">
            <Header className="mb-0">
              <HeaderTitle className="mb-0">
                Geofences <span className="text-muted">({geofences.size})</span>
              </HeaderTitle>
            </Header>
            <div className="d-flex ms-auto">
              <GeofencesActions />
            </div>
          </div>

          <div
            className={`geofences ${
              fullScreen ? 'geofences--full-screen' : ''
            }`}>
            <GeofencesListToolbar
              setSelectedGroup={setSelectedGroup}
              goToUserLocation={goToUserLocation}
              selectedGeofences={selectedGeofences}
              groups={groups}
              setPage={setPage}
              queryParams={queryParams}
              searchQuery={searchQuery}
              handleSearch={handleSearch}
              setMapCenter={setMapCenter}
              isGoogleMapsLoaded={isLoaded}
              fullScreenMode={fullScreen}
              polygonsActive={polygonsActive}
              onAddToGroup={() => showModal('addElementsToGroup')}
              onRemoveFromGroup={() => showModal('removeElementsFromGroup')}
              onDelete={showRemoveGeofencesModal}
              onEditGroup={() => showModal('editGroupModal')}
              onRemoveGroup={() => showModal('removeGroupModal')}
              onCreateGroup={() => showModal('createGroupModal')}
              map={map}
            />
            <div className="geofences__inner d-flex shadow">
              <GeofencesList
                checked={selectedGeofences}
                onCheckGeofence={checkGeofence}
                geofencesList={geofencesList}
                editGeofence={editGeofence}
                handleSort={handleSort}
                order={order}
                sortField={sortField}
                onDeleteRow={showRemoveGeofenceModal}
                selectGeofence={selectGeofence}
                selectedGeofence={selectedGeofence}
              />
              <GeofencesMap
                unitSystem={geofenceFeetUnit ? 'imperial' : 'metric'}
                disableAllShapesEdition={disableAllShapesEdition}
                setShapeEditable={setShapeEditable}
                saveGeofence={saveGeofence}
                addTempGeofence={addTempGeofence}
                isGeofenceSaving={isGeofenceSaving}
                isGoogleMapsLoaded={isLoaded}
                isInfoWindowOpen={isInfoWindowOpen}
                mapCenter={mapCenter}
                minRadius={minRadius}
                maxRadius={maxRadius}
                polygonalGeofences={polygonGeofences}
                polygonsActive={polygonsActive}
                radialGeofences={circleGeofences}
                removeGeofence={removeGeofence}
                removeTempGeofence={removeTempGeofence}
                selectGeofence={selectGeofence}
                selectedGeofence={selectedGeofence}
                setInfoWindowOpen={setInfoWindowOpen}
                setMap={setMap}
                map={map}
                toggleFullScreen={() => setFullScreen((prev) => !prev)}
              />
            </div>
          </div>
        </div>

        <Modal
          id="addGeofencesModal"
          className="add-geofences-modal"
          size="lg"
          renderHeader={() => 'Add Radial Geofences manually'}
          render={(close: () => void) => (
            <AddGeofencesModal
              maxRadius={maxRadius}
              minRadius={minRadius}
              unit={geofenceFeetUnit ? 'ft' : 'm'}
              addGeofences={batchCreateGeofencesManually}
              onClose={close}
            />
          )}
        />

        <AddElementsToGroupModal
          groups={groups}
          onSubmit={(groupId: string) => {
            addElementsToGroup({
              url: bulkActions?.addToGroup,
              groupId,
              resourceIds: [...selectedGeofences.keys()],
              elements: Array.from(geofences.values()),
              successCallback: () => selectedGeofences.clear()
            })
          }}
        />

        <RemoveElementsFromGroupModal
          onSubmit={(selectedGroups) => {
            removeElementsFromGroup({
              selectedGroups,
              url: bulkActions?.removeFromGroup,
              resourceIds: [...selectedGeofences.keys()],
              elements: geofencesList,
              successCallback: () => selectedGeofences.clear()
            })
          }}
          elements={selectedGeofencesList}
          elementsName="Geofences"
          isSaving={isPerforming}
        />

        <EditGroupModal
          group={selectedGroup}
          isSaving={isPerforming}
          onSubmit={editGroup}
        />
        <DeleteGroup
          onSubmit={async (groupId) => {
            await removeGroup(groupId)
            selectedGeofences.clear()
            fetchList()
          }}
          queryParams={queryParams}
          isSaving={isPerforming}
          group={selectedGroup}
        />
        <CreateGroupModal
          isSaving={isPerforming}
          onSubmit={createGroup}
          groupType="geofences"
        />
        <DeleteElementsModal elementType="Geofence" />

        <BatchUpload
          resourceType="geofences"
          helpMedia={
            geofenceFeetUnit ? geofencesUploadHelpFeet : geofencesUploadHelp
          }
          successCallback={fetchList}
          onManualUpload={() => showModal('addGeofencesModal')}
          errorsDictionary={getErrorsDictionary(
            minRadius,
            maxRadius,
            geofenceFeetUnit ? 'ft' : 'm'
          )}
          labels={{
            header: 'Adding New Radial Geofences',
            csv: `Upload a .CSV file containing four columns: Name, Latitude, Longitude, Radius (${
              geofenceFeetUnit ? 'ft' : 'm'
            })`,
            manual: 'Input Name, Latitude, Longitude and Radius values manually'
          }}
        />
      </Container>
    </>
  )
}

export default withStore(Geofences)
