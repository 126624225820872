import React, { useRef, useReducer } from 'react'
import { Button, Fade } from 'reactstrap'
import { UploadCloud, CheckCircle, AlertTriangle } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { uniqueId } from 'lodash'

import filesReducer, { ACTIONS } from './filesReducer'
import {
  FileItem,
  IImage
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { NotificationType, showNotification } from '~/utils/Notification'

import './FileUpload.scss'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000
const DEFAULT_MAX_FILE_SIZE_IN_MEGABYTES = 5
const KILO_BYTES_PER_BYTE = 1024
const ALLOWED_EXTENSION = ['png', 'jpg', 'jpeg']

const convertBytesToKB = (bytes: number) =>
  Math.round(bytes / KILO_BYTES_PER_BYTE)

const FileUpload = ({
  uploadFile,
  uploadSuccessfulCallback
}: {
  uploadFile: (file: File) => void
  uploadSuccessfulCallback: (newImage: IImage) => void
}): React.ReactNode => {
  const fileInputField = useRef(null)
  const [files, dispatch] = useReducer(filesReducer, [])

  const setFileUploading = (id: string, value: boolean) => {
    dispatch({
      type: ACTIONS.UPDATE_FILE,
      payload: { id, isLoading: value }
    })
  }

  const handleFileUpload = async (fileItem: FileItem) => {
    const { id, file } = fileItem
    try {
      setFileUploading(id, true)
      const uploadedFile = await uploadFile(file)
      uploadSuccessfulCallback(uploadedFile)
      dispatch({
        type: ACTIONS.UPDATE_FILE,
        payload: { id, isUploaded: true, isLoading: false }
      })
    } catch (error) {
      dispatch({
        type: ACTIONS.UPLOAD_ERROR,
        payload: { id }
      })
    } finally {
      setFileUploading(id, false)
    }
  }

  const addNewFiles = (newFiles: Array<File>): any => {
    const filesToAdd = []

    Array.from(newFiles).forEach((file) => {
      const [fileType, fileExtension] = file.type.split('/')

      if (file.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        showNotification(
          `Max image size is ${DEFAULT_MAX_FILE_SIZE_IN_MEGABYTES} MB.`,
          NotificationType.ERROR
        )
        return
      }
      if (fileType !== 'image') {
        showNotification(
          `File ${file.name} is not a image, please choose valid image file.`,
          NotificationType.ERROR
        )
        return
      }
      if (!ALLOWED_EXTENSION.includes(fileExtension)) {
        showNotification(
          `File ${file.name} has unsupported extension, allowed extensions are jpg, jpeg, png.`,
          NotificationType.ERROR
        )
        return
      }

      filesToAdd.push({
        id: uniqueId(),
        name: file.name,
        file,
        isLoading: false,
        isUploaded: false,
        hasError: false
      })
    })

    return filesToAdd
  }

  const handleNewFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: newFiles } = e.target
    if (newFiles?.length) {
      const updatedFiles = addNewFiles(newFiles)
      dispatch({ type: ACTIONS.ADD_FILES, payload: { files: updatedFiles } })
      updatedFiles.forEach((fileItem) => {
        handleFileUpload(fileItem)
      })
    }
  }

  return (
    <div className="file-upload">
      <div className="file-upload__dropzone mx-auto">
        <UploadCloud size={50} />
        <p className="my-3 fw-bold">Drag and drop your files here or</p>
        <Button color="primary" className="py-2">
          Browse files
        </Button>
        <span className="muted mt-2">
          Max image size is {DEFAULT_MAX_FILE_SIZE_IN_MEGABYTES} MB.
        </span>
        <input
          className="file-upload__input"
          type="file"
          multiple
          name="file"
          accept=".jpg,.png,.jpeg"
          onDragOver={(e) => {
            e.preventDefault()
          }}
          onChange={handleNewFiles}
          ref={fileInputField}
        />
      </div>

      <div className={`file-upload__preview ${files.length ? 'mt-4' : ''}`}>
        {files.map((file) => (
          <div className="file-upload__preview-item mt-3" key={file.id}>
            <div className="d-flex align-items-center">
              <div>
                <div
                  className="file-upload__preview-image card mb-0"
                  style={{
                    backgroundImage: `url(${URL.createObjectURL(file.file)}`
                  }}
                />
              </div>
              <div className="py-3 ms-4">
                <p className="file-upload__file-name mb-1 fw-bold">
                  {file.name}
                </p>
                <p className="text-muted">
                  {convertBytesToKB(file.file.size)} kb
                </p>
              </div>
              <div className="float-end ms-auto">
                {file.isLoading && (
                  <FontAwesomeIcon
                    className="me-1"
                    icon={faSpinner}
                    spin
                    size="lg"
                  />
                )}
                <Fade in={file.isUploaded} unmountOnExit>
                  <CheckCircle color="green" />
                </Fade>
                {file.hasError && !file.isLoading && (
                  <span>
                    <AlertTriangle color="red" />{' '}
                    <Button
                      color=""
                      className="file-upload__retry-btn"
                      onClick={() => handleFileUpload(file)}>
                      Retry
                    </Button>
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FileUpload
