import cn from 'classnames'
import { Fragment } from 'react'
import RuleTypeDropdown from './RuleTypeDropdown/RuleTypeDropdown'
import {
  JointType,
  RuleDTO,
  RuleWithCategoriesDTO
} from './SegmentBuilder.interface'
import SegmentBuilderStore from './SegmentBuilder.store'
import SegmentRule from './SegmentRule'
import styles from './SegmentBuilder.scss'
import SegmentRuleModel from './Rules/SegmentRule.model'

interface IProps {
  rules: SegmentRuleModel[]
  rulesWithCategories: RuleWithCategoriesDTO
  store: SegmentBuilderStore
}

export default function SegmentRuleGroup({
  rules,
  rulesWithCategories,
  store
}: IProps): React.ReactElement {
  return (
    <>
      <div className={cn({ [styles.ruleGroup]: rules.length > 1 })}>
        {rules.map((rule, index) => {
          return (
            <Fragment key={rule.id}>
              {index > 0 && (
                <div className={cn('my-2', styles.ruleGroupItem)}>OR</div>
              )}
              <SegmentRule
                key={rule.id}
                rule={rule}
                rules={rulesWithCategories}
                store={store}
              />
            </Fragment>
          )
        })}
      </div>
      <RuleTypeDropdown
        onRuleSelection={(newRule: RuleDTO, joinType: JointType | null) =>
          store.addRule(newRule, joinType, rules[0].id)
        }
        rules={rulesWithCategories}
        withJoinType>
        And
      </RuleTypeDropdown>
    </>
  )
}
