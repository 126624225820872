import cn from 'classnames'
import { Navbar as RSNavbar } from 'reactstrap'
import NavbarDropdowns from './NavbarDropdowns'
import NavbarToggle from './NavbarToggle'
import { navbar } from './NavBar.scss'

const Navbar = () => {
  return (
    <RSNavbar
      expand
      container={false}
      className={cn('navbar-container', 'navbar-theme', navbar)}
      aria-label="Main navigation">
      <NavbarToggle aria-label="Toggle navigation" />
      <div id="navbar-builder-status-portal" aria-hidden="true" />
      <NavbarDropdowns aria-label="User settings dropdown" />
    </RSNavbar>
  )
}

export default Navbar
