import { ReactElement, useState } from 'react'
import { Carousel, CarouselItem } from 'reactstrap'
import {
  faChevronRight,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CampaignType,
  DeviceType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'

import DeviceLogo from './DeviceLogo'
import PreviewMobile from '../PreviewMobile'

interface IProps {
  campaignType: CampaignType
  devices: {
    name: string
    logo: string
    deviceType: DeviceType
    className?: string
  }[]
  parts: IPreviewParts
}

export default function MobileDevicesCarousel({
  campaignType,
  devices,
  parts
}: IProps): ReactElement {
  const [activeIndex, setActiveIndex] = useState(1)
  const [animating, setAnimating] = useState(false)
  const nextSlide = () => {
    if (animating) return
    const nextIndex = activeIndex === devices.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previousSlide = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? devices.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  return (
    <div className="d-flex justify-content-center position-relative">
      <div className="d-flex justify-content-center ">
        <Carousel
          className="carousel-preview"
          activeIndex={activeIndex}
          keyboard={false}
          next={nextSlide}
          previous={previousSlide}
          interval={false}>
          {devices.map((device) => (
            <CarouselItem
              key={device.deviceType}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}>
              <DeviceLogo name={device.name} />
              <PreviewMobile
                device={device.deviceType}
                deviceSize="lg"
                preview={parts}
                campaignType={campaignType}
                withTooltip={campaignType === CampaignType.CARD_FRONT}
              />
            </CarouselItem>
          ))}
        </Carousel>
        <button
          aria-label="previous device"
          className="carousel-arrow carousel-arrow--prev"
          type="button"
          onClick={previousSlide}>
          <FontAwesomeIcon
            className="carousel-arrow__chevron"
            icon={faChevronLeft}
          />
        </button>
        <button
          aria-label="next device"
          className="carousel-arrow carousel-arrow--next"
          type="button"
          onClick={nextSlide}>
          <FontAwesomeIcon
            className="carousel-arrow__chevron"
            icon={faChevronRight}
          />
        </button>
      </div>
    </div>
  )
}
