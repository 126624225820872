import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Fade } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { HtmlExport } from 'react-email-editor'
import { useStore, withStore } from '~/dataStore'
import { NotificationType, showNotification } from '~/utils/Notification'
import TemplatesList from './TemplatesList'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal'

import {
  templateBtn,
  templateBtnActive
} from './TemplatesList/TemplatesList.scss'
import {
  IEmailTemplate,
  IRegisteredField
} from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  layouts: IEmailTemplate[]
  isChanged: boolean | null
  templates: IEmailTemplate[]
  fetchLayouts: () => void
  currentTemplate: IRegisteredField<IEmailTemplate | null>
  setEditorData: (data: HtmlExport) => void
  validateTemplate: () => void
  setCurrentTemplate: (template: IEmailTemplate) => void
  deleteEmailTemplate: (appId: string, templateId: string) => void
  fetchEmailTemplates: (appId: string) => void
  getCurrentTemplateType: () => 'all' | 'recent'
  templatesSelectionDisabled: boolean
}

const Templates = ({
  layouts,
  templates,
  isChanged,
  fetchLayouts,
  currentTemplate,
  setEditorData,
  validateTemplate,
  setCurrentTemplate,
  deleteEmailTemplate,
  fetchEmailTemplates,
  getCurrentTemplateType,
  templatesSelectionDisabled
}: IProps) => {
  const {
    ui: { showModal, unregisterAllModals }
  } = useStore()

  const { appId }: { appId: string } = useParams()

  const [active, setActive] = useState('recent')
  const [templatesLoading, setTemplatesLoading] = useState({
    loading: false,
    firstRun: true
  })

  const fetchTemplates = async () => {
    try {
      setTemplatesLoading((prevState) => ({
        ...prevState,
        loading: true
      }))
      await fetchEmailTemplates(appId)
    } catch {
      showNotification(
        'Failed to fetch templates, Please try again later.',
        NotificationType.ERROR
      )
    } finally {
      setTemplatesLoading({
        loading: false,
        firstRun: false
      })
    }
  }

  const deleteTemplate = async (templateId: string) => {
    try {
      setTemplatesLoading((prevState) => ({
        ...prevState,
        loading: true
      }))
      await deleteEmailTemplate(appId, templateId)
      unregisterAllModals()
    } catch {
      showNotification(
        'Failed to delete template, Please try again later.',
        NotificationType.ERROR
      )
    } finally {
      setTemplatesLoading((prevState) => ({
        ...prevState,
        loading: false
      }))
    }
  }

  const setTemplateData = (template: IEmailTemplate) => {
    setCurrentTemplate(template)
    validateTemplate()
    setEditorData(template)
  }

  useEffect(() => {
    Promise.all([fetchLayouts(), fetchTemplates()]).then(() => {
      setActive(getCurrentTemplateType())
    })
  }, [])

  const showDeleteTemplatePrompt = (templateId: string) =>
    showModal('deleteTemplateModal', { templateId })

  const showOverwriteTemplatePrompt = (template: IEmailTemplate) =>
    showModal('switchTemplateModal', { template })

  return (
    <>
      <div className="d-flex">
        <button
          type="button"
          data-testid="savedTemplatesBtn"
          className={cn(
            templateBtn,
            { 'error-label': !currentTemplate.isValid },
            active === 'recent' ? templateBtnActive : 'text-gray',
            'btn btn--transparent'
          )}
          onClick={() => setActive('recent')}
          style={{ cursor: 'pointer' }}>
          Saved Templates
        </button>
        <span className="ms-2 me-2">|</span>
        <button
          type="button"
          data-testid="layoutTemplatesBtn"
          className={cn(
            templateBtn,
            active === 'all' ? templateBtnActive : 'text-gray',
            'btn btn--transparent'
          )}
          onClick={() => setActive('all')}
          style={{ cursor: 'pointer' }}>
          Layouts
        </button>
      </div>
      <div>
        <Fade in={active === 'recent'} mountOnEnter unmountOnExit>
          <TemplatesList
            currentTemplate={currentTemplate}
            deleteTemplate={showDeleteTemplatePrompt}
            hasBlankTemplate
            isChanged={isChanged}
            loading={templatesLoading}
            overwriteTemplate={showOverwriteTemplatePrompt}
            setTemplateData={setTemplateData}
            templates={templates}
            templatesSelectionDisabled={templatesSelectionDisabled}
          />
        </Fade>
        <Fade in={active === 'all'} mountOnEnter unmountOnExit>
          <TemplatesList
            isChanged={isChanged}
            currentTemplate={currentTemplate}
            isLayout
            overwriteTemplate={showOverwriteTemplatePrompt}
            setTemplateData={setTemplateData}
            templates={layouts}
            templatesSelectionDisabled={templatesSelectionDisabled}
          />
        </Fade>
      </div>

      <Modal
        id="switchTemplateModal"
        className="send-test-email-modal"
        renderHeader={() => 'Switching templates'}
        render={(close: () => void, params) => (
          <ConfirmationModal
            onSubmit={() => {
              setTemplateData(params?.template)
              close()
            }}
            onClose={close}
            okText="Change template"
            cancelText="Cancel">
            Switching template will remove your current changes in template
            editor. Are you sure?"
          </ConfirmationModal>
        )}
      />

      <Modal
        id="deleteTemplateModal"
        renderHeader={() => 'Deleting template'}
        render={(close: () => void, params) => (
          <ConfirmationModal
            onSubmit={() => {
              deleteTemplate(params?.templateId)
            }}
            onClose={close}
            okText="Delete"
            cancelText="Cancel">
            Are you sure you want to delete this template?
          </ConfirmationModal>
        )}
      />
    </>
  )
}

export default withStore(Templates)
