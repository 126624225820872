import React, { useState } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { InputGroup } from 'reactstrap'
import DebouncedInput from '../DebouncedInput'

import { searchInput, focusBorder } from './SearchInput.scss'

const SearchInput = ({
  className,
  ...otherProps
}: {
  className?: string
  [x: string]: unknown
}): React.ReactElement => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <InputGroup
      className={cn(
        'search-input border border-input rounded',
        className,
        searchInput,
        {
          [focusBorder]: isFocused
        }
      )}>
      <span className="input-group-text border-0 bg-white px-0">
        <FontAwesomeIcon
          icon={faSearch}
          aria-label="Search icon"
          data-testid="search-icon"
        />
      </span>
      <DebouncedInput
        {...otherProps}
        data-testid="search-input"
        aria-label="Search input"
        className="border-0 fit-content shadow-none px-0"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </InputGroup>
  )
}

export default SearchInput
