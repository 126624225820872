import React from 'react'
import './EmailIcon.scss'

const EmailIconAnimated = ({
  className
}: {
  className: string
}): React.ReactElement => (
  <svg
    id="animated-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 136"
    width="72"
    height="136"
    className={className}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <g>
      <rect
        width="70"
        height="134"
        rx="7"
        ry="7"
        transform="translate(1 1)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <rect
        width="58"
        height="102"
        rx="0"
        ry="0"
        transform="translate(7 17)"
        fill="#f4f7fc"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <g
        id="animatedObject_to"
        style={{
          offsetPath:
            "path('M-48.50005,68.632C-11.488652,29.611672,6.943494,62.172823,36,68.632')",
          offsetRotate: '0deg'
        }}>
        <g id="animatedObject_tr" transform="rotate(0)">
          <g
            id="animatedObject"
            transform="translate(-35.500049,-68.632)"
            opacity="0">
            <rect
              width="32"
              height="20"
              rx="0"
              ry="0"
              transform="translate(19 59)"
              fill="#fff"
            />
            <path
              d="M50.3077,57.9653h-29.6154c-.9308,0-1.6923.7385-1.6923,1.641v18.0513c0,.9025.7615,1.6411,1.6923,1.6411h29.6154c.9307,0,1.6924-.7384,1.6924-1.6411v-18.0513c-.0001-.9025-.7616-1.641-1.6924-1.641Zm-.6351,1.2307L36.4735,68.7961c-.2373.1752-.6016.2847-.9736.2831-.3719.0016-.7361-.1079-.9735-.2831L21.3273,59.196h28.3453ZM42.6221,69.4296l7.1923,8.6154c.0072.0086.016.0152.0237.023h-28.6762c.0076-.0082.0165-.0144.0237-.023l7.1923-8.6154c.2204-.2642.1786-.6515-.0943-.8657-.2725-.2137-.6719-.1732-.8923.0911l-7.1221,8.5313v-17.221l13.4961,9.8153c.5073.3664,1.1245.5277,1.7346.5293.6092-.0013,1.2269-.1625,1.7345-.5293l13.4961-9.8153v17.2208L43.6087,68.655c-.2205-.2642-.6203-.3049-.8923-.0911-.2729.2137-.3148.6015-.0943.8657Z"
              fill="#93a8c5"
              stroke="#fff"
              strokeWidth="0.3"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default EmailIconAnimated
