import { compact } from 'lodash'
import { action, observable, computed, makeObservable } from 'mobx'
import { fetchEvents } from '~/api/apps'
import { TimeUnit } from '~/common.interface'

import { isEmpty } from '~/utils/utilities'
import {
  CampaignType,
  ICampaign,
  ICampaignModel,
  ISetupPayload
} from '../Campaign/Campaign.interface'
import { ValidationTypes, IOption } from './EmailBuilder.interface'
import NewRegisteredField from './RegisteredField.model'
import Goal from './SetUp/Goal.model'
import StepStore from './StepStore'
import { IStore } from '~/dataStore/Store.interface'

export default class SetUp extends StepStore {
  name = new NewRegisteredField<string>('')

  primary: Goal = new Goal()

  secondary: Goal | undefined = undefined

  controlGroup: {
    active: boolean
    quantity: NewRegisteredField<number | undefined>
  } = {
    active: false,
    quantity: new NewRegisteredField(10)
  }

  events: IOption[] = []

  searchQuery = ''

  rootStore: IStore

  constructor(campaign: ICampaignModel) {
    super()

    this.rootStore = campaign.rootStore as IStore

    makeObservable<SetUp, 'prepareEmailEvents' | 'prepareMobileEvents'>(this, {
      name: observable,
      primary: observable,
      secondary: observable,
      controlGroup: observable,
      events: observable,
      prepareMobileEvents: action.bound,
      prepareEmailEvents: action.bound,
      searchQuery: observable,
      setControlGroupActive: action.bound,
      setPrimaryEventName: action.bound,
      setPrimaryExpiryTimeValue: action.bound,
      setPrimaryExpiryTimeUnit: action.bound,
      addSecondaryGoal: action.bound,
      removeSecondaryGoal: action.bound,
      setSecondaryEventName: action.bound,
      setSecondaryExpiryTimeValue: action.bound,
      setSecondaryExpiryTimeUnit: action.bound,
      fetchEvents: action.bound,
      setSearchQuery: action.bound,
      registeredFields: computed,
      validateStep: action,
      validateName: action.bound,
      validatePrimaryGoal: action.bound,
      validatePrimaryGoalTime: action.bound,
      validateSecondaryGoal: action.bound,
      validateSecondaryGoalTime: action.bound,
      validateControlGroup: action.bound
    })

    if (!this.rootStore.app.appDetails.featureFlags.campaignsGoals) {
      this.primary.eventName.value.value = 'open_app'
      this.primary.expiryTimeUnit.value = 'hours'
      this.primary.expiryTimeValue.value = 1
    }
  }

  public setControlGroupActive(value: boolean): void {
    this.controlGroup.active = value
  }

  public setPrimaryEventName(value: IOption<string>): void {
    this.primary.eventName.setValue(value)
  }

  public setPrimaryExpiryTimeValue(
    e: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { value } = e.target
    this.primary.expiryTimeValue.setValue(
      value ? parseInt(value, 10) : undefined
    )
  }

  public setPrimaryExpiryTimeUnit(value: TimeUnit): void {
    this.primary.expiryTimeUnit.setValue(value)
  }

  public addSecondaryGoal(): void {
    if (!this.secondary) {
      this.secondary = new Goal()
    }
  }

  public removeSecondaryGoal(): void {
    this.secondary = undefined
  }

  public setSecondaryEventName(value: IOption): void {
    if (this.secondary) {
      this.secondary.eventName.setValue(value)
    }
  }

  public setSecondaryExpiryTimeValue(
    e: React.ChangeEvent<HTMLInputElement>
  ): void {
    if (this.secondary) {
      const { value } = e.target
      this.secondary.expiryTimeValue.setValue(
        value ? parseInt(value, 10) : undefined
      )
    }
  }

  public setSecondaryExpiryTimeUnit(value: string): void {
    if (this.secondary) {
      this.secondary.expiryTimeUnit.setValue(value)
    }
  }

  async fetchEvents(appId: string, campaignType?: CampaignType): Promise<void> {
    if (campaignType === CampaignType.EMAIL) {
      this.prepareEmailEvents()
    } else {
      const { data } = await fetchEvents(appId, this.searchQuery)
      this.prepareMobileEvents(data)
    }
  }

  private prepareEmailEvents(): void {
    this.events = [
      {
        value: 'placeholder',
        label: 'Users who perform the following event',
        isDisabled: true,
        asLabel: true
      },
      { value: 'open_app', label: 'User Opens App' },
      { value: 'open_email', label: 'User Opens Email' }
    ]
  }

  private prepareMobileEvents(events: string[]): void {
    this.events = events.map((e: string | { id: string; name: string }) => {
      if (typeof e === 'string') {
        return {
          value: `${e ?? ''}`,
          label: `${e ?? ''}`
        }
      }
      if ('id' in e) {
        return { value: e.id, label: e.name }
      }

      return { value: '', label: '' }
    })

    this.events.unshift(
      {
        value: 'placeholder',
        label: 'Users who perform the following event',
        isDisabled: true,
        asLabel: true
      },
      { value: 'open_app', label: 'User Opens App' }
    )
  }

  public setSearchQuery(txt: string): void {
    this.searchQuery = txt
  }

  /*
  Validations
  */
  get registeredFields(): NewRegisteredField[] {
    return [
      this.name,
      this.primary.eventName,
      this.primary.expiryTimeValue,
      this.secondary?.eventName,
      this.secondary?.expiryTimeValue,
      this.controlGroup.active ? this.controlGroup.quantity : undefined
    ].filter(Boolean) as NewRegisteredField[]
  }

  public validateStep(): void {
    this.validateName()
    if (this.rootStore?.app.appDetails.featureFlags.campaignsGoals) {
      this.validatePrimaryGoal()
      this.validatePrimaryGoalTime()
      this.validateSecondaryGoal()
      this.validateSecondaryGoalTime()
    }
    this.validateControlGroup()

    this.beenValid = true
  }

  public validateName(): void {
    this.name.validate('Enter Campaign name')
  }

  public validatePrimaryGoal(): void {
    this.primary.validateGoal('Select Primary goal')
  }

  public validatePrimaryGoalTime(): void {
    this.primary.validateGoalTime('Primary Goal')
  }

  public validateSecondaryGoal(): void {
    if (!this.secondary) {
      return
    }

    this.secondary.validateGoal('Select Secondary Goal')
  }

  public validateSecondaryGoalTime(): void {
    if (!this.secondary) {
      return
    }

    this.secondary.validateGoalTime('Secondary Goal')
  }

  public validateControlGroup(): void {
    this.controlGroup.quantity.resetError()

    if (!this.controlGroup.active) {
      return
    }

    if (
      !this.controlGroup.quantity.validate(
        'Enter a whole number greater than 0'
      )
    ) {
      return
    }

    const value = Number(this.controlGroup.quantity.value)
    if (value < 1 || !Number.isInteger(value)) {
      this.controlGroup.quantity.isValid = false
      this.controlGroup.quantity.errors.push({
        type: ValidationTypes.FORMAT,
        message: 'Enter a whole number greater than 0'
      })
    } else if (value > 80) {
      this.controlGroup.quantity.isValid = false
      this.controlGroup.quantity.errors.push({
        type: ValidationTypes.MIN_MAX,
        message: 'Enter a whole number below 80'
      })
    }
  }

  public fillStore<T extends ICampaign>(data: T): void {
    this.name.fillField({ value: data.name })

    const primary = data.goalsAttributes?.find((elem) => elem.primary)

    this.primary.eventName.fillField({
      value: {
        value: `${(primary?.eventIdentifier || primary?.eventKind) ?? ''}`,
        label: `${(primary?.eventIdentifier || primary?.eventKind) ?? ''}`
      }
    })
    this.primary.expiryTimeUnit.fillField({ value: primary?.expiryTimeUnit })
    this.primary.expiryTimeValue.fillField({ value: primary?.expiryTimeValue })

    const secondary = data.goalsAttributes?.find((elem) => !elem.primary)
    if (secondary) {
      this.secondary = new Goal()
      this.secondary.eventName.fillField({
        value: {
          value: `${(secondary.eventIdentifier || secondary.eventKind) ?? ''}`,
          label: `${(secondary.eventIdentifier || secondary.eventKind) ?? ''}`
        }
      })
      this.secondary.expiryTimeUnit.fillField({
        value: secondary.expiryTimeUnit
      })
      this.secondary.expiryTimeValue.fillField({
        value: secondary.expiryTimeValue
      })
    }

    if (data.controlGroup) {
      this.controlGroup.active = true
      this.controlGroup.quantity.fillField({ value: data.controlGroup })
    }

    this.beenValid = true
  }

  // eslint-disable-next-line class-methods-use-this
  private isNonAppEvent(value?: string): boolean {
    if (!value) {
      return false
    }

    return ['open_app', 'open_email'].includes(value)
  }

  public getPayload(): ISetupPayload {
    const { name, primary, secondary, controlGroup } = this

    const isPrimaryOpenApp = this.isNonAppEvent(primary.eventName.value?.value)
    const isSecondaryOpenApp = this.isNonAppEvent(
      secondary?.eventName.value?.value
    )

    return {
      name: name.value,
      controlGroup:
        controlGroup.active && !isEmpty(controlGroup.quantity.value)
          ? {
              unit: 'percent',
              quantity: controlGroup.quantity.value
            }
          : null,
      goals: compact([
        {
          primary: true,
          eventKind: isPrimaryOpenApp
            ? primary.eventName.value.value
            : 'in_app_event',
          eventIdentifier: isPrimaryOpenApp
            ? null
            : primary.eventName.value?.value,
          expiryTimeUnit: primary.expiryTimeUnit.value,
          expiryTimeValue: primary.expiryTimeValue.value
        },
        secondary && {
          primary: false,
          eventKind: isSecondaryOpenApp
            ? secondary.eventName.value.value
            : 'in_app_event',
          expiryTimeUnit: secondary?.expiryTimeUnit.value,
          expiryTimeValue: secondary?.expiryTimeValue.value,
          eventIdentifier: isSecondaryOpenApp
            ? null
            : secondary?.eventName.value?.value
        }
      ])
    }
  }
}
