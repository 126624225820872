import { camelize, decamelize } from 'humps'
import { paramsToStr } from './requests.service'

// Deeply decamelize object keys
function decamelizeDeep(obj: any): any {
  if (!obj || typeof obj !== 'object') return obj
  const objType = Object.prototype.toString.bind(obj)()
  if (objType === '[object Date]' || objType === '[object RegExp]') return obj
  if (objType === '[object Array]') return obj.map(decamelizeDeep)
  return Object.keys(obj).reduce((acc: any, key) => {
    acc[decamelize(key)] = decamelizeDeep(obj[key])
    return acc
  }, {})
}
// Callback to camelize object keys on fetch() responses
function camelizeReviver(key: string | undefined, val: any): any {
  if (key && key !== '_override' && key !== '_meta') {
    const camelizedKey = camelize(key)
    if (key !== camelizedKey) {
      this[camelizedKey] = val
      return
    }
  }
  return val
}

function RequestError(
  url: string,
  status: string,
  statusText: string,
  body: any
): Error {
  const error = new Error(`Error ${status}: ${statusText} (${url})`)
  error.status = status
  error.body = body
  return error
}

async function parseResponse(response) {
  const { url, status, statusText } = response
  const json = await response.text()
  let payload

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      if (window.location.pathname !== '/admins/sign_in') {
        // ? beacuse of last old page managed by ruby (talks) we need to refresh whole page to redirect to sign_in page. Instead sign in react page opens in message window in talsk page
        window.location.href = '/admins/sign_in'
        // store.history?.push('/admins/sign_in', {
        //   from: window.location.pathname
        // })
      }
    }
  }

  try {
    payload = JSON.parse(json.length > 0 ? json : null, camelizeReviver)
  } catch (error) {
    throw new RequestError(url, status, statusText, json)
  }

  if (!response.ok) {
    throw new RequestError(url, status, statusText, payload)
  }
  return payload
}

export function getRequest(
  url: string,
  params: any = {},
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return fetch(`${url}${paramsToStr(params, url.includes('mobile/apps/'))}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    signal
  }).then(parseResponse)
}

export function downloadFileRequest(
  url: string,
  params: any = {},
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return fetch(`${url}${paramsToStr(params, url.includes('mobile/apps/'))}`, {
    signal
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'report.zip'
      document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click()
      a.remove() // afterwards we remove the element again
    })
}

export function postRequest(
  url: string,
  data: any,
  method = 'POST',
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document
        .querySelector('meta[name="csrf-token"]')
        ?.getAttribute('content')
    },
    body: JSON.stringify(decamelizeDeep(data)),
    signal
  }).then(parseResponse)
}

export function uploadRequest(
  url: string,
  file: FormData,
  method = 'POST',
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return fetch(url, {
    method,
    headers: {
      'X-CSRF-Token': document
        .querySelector('meta[name="csrf-token"]')
        ?.getAttribute('content')
    },
    body: file,
    signal
  }).then(parseResponse)
}

export function putRequest(
  url: string,
  data: any,
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return postRequest(url, data, 'PUT', signal)
}

export function patchRequest(
  url: string,
  data: any,
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return postRequest(url, data, 'PATCH', signal)
}

export function deleteRequest(
  url: string,
  data: any = {},
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return postRequest(url, data, 'DELETE', signal)
}
