// MAP
export const MAP_LIBRARIES = ['drawing', 'places']

export const DEFAULT_ZOOM = 14
export const DEFAULT_CENTER = {
  lat: 53.349322,
  lng: -6.260254
}

// Regexp for latitude, only values between -90 and 90 are valid
const LATITUDE_VALIDATION_PATTERN =
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/

// Regexp for longitude, only values between -180 and 180 are valid
const LONGITUDE_VALIDATION_PATTERN =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/

export const DRAWING_MANGER_OPTIONS = {
  drawingControl: true,
  circleOptions: {
    clickable: true,
    draggable: true,
    editable: false,
    fillColor: '#000',
    fillOpacity: 0.7,
    strokeColor: '#000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    zIndex: 1
  },

  polygonOptions: {
    clickable: true,
    draggable: true,
    editable: true,
    fillColor: '#000',
    fillOpacity: 0.7,
    strokeColor: '#000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    zIndex: 1
  }
}

export const CIRCLE_OPTIONS = {
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0.7,
  clickable: true,
  visible: true,
  zIndex: 1
}

export const POLYGON_OPTIONS = {
  fillOpacity: 0.7,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  clickable: true,
  geodesic: false,
  zIndex: 1
}
export const GEOFENCES_QUERY_PARAMS = {
  groupIds: '',
  shape: '',
  sort: ''
}

const minMaxRadiusValidationMsg = (
  minRadius: number,
  maxRadius: number,
  unit: 'm' | 'ft'
) => `Radius needs to be between ${minRadius} ${unit} and ${maxRadius} ${unit}`

const radiusMissingValidationMsg = (
  minRadius: number,
  maxRadius: number,
  unit: 'm' | 'ft'
) =>
  `Radius is missing (values between ${minRadius} ${unit} and ${maxRadius} ${unit})`

export const radiusFieldValidation = (
  minRadius: number,
  maxRadius: number,
  unit: 'm' | 'ft'
): {
  required: { value: boolean; message: string }
  min: { value: number; message: string }
  max: { value: number; message: string }
} => ({
  required: {
    value: true,
    message: 'Radius is missing'
  },
  min: {
    value: minRadius,
    message: minMaxRadiusValidationMsg(minRadius, maxRadius, unit)
  },
  max: {
    value: maxRadius,
    message: minMaxRadiusValidationMsg(minRadius, maxRadius, unit)
  }
})

export const getErrorsDictionary = (
  minRadius: number,
  maxRadius: number,
  unit: 'm' | 'ft'
): {
  name: Record<string, string>
  radius: Record<string, string>
  shape: Record<string, string>
} => ({
  name: {
    'Missing parameter name': 'Geofence name is missing',
    'is already used': 'Provided Geofence name is already used in the list',
    'is already used in CSV file':
      'Provided Geofence name is already used in CSV file'
  },
  radius: {
    [minMaxRadiusValidationMsg(minRadius, maxRadius, unit)]:
      minMaxRadiusValidationMsg(minRadius, maxRadius, unit),
    [radiusMissingValidationMsg(minRadius, maxRadius, unit)]:
      radiusMissingValidationMsg(minRadius, maxRadius, unit),
    "can't be blank": 'Radius is missing',
    'is not a number': 'Radius must be a number'
  },
  shape: {
    'Missing parameter shape': '',
    'Must be one of: <code>circle</code>, <code>polygon</code>.': ''
  }
})

export const NAME_FIELD_VALIDATION = {
  required: {
    value: true,
    message: 'Geofence name is missing'
  },
  maxLength: {
    value: 90,
    message: 'The length of geofence name must be 90 characters or fewer'
  }
}

export const LAT_FIELD_VALIDATION = {
  pattern: {
    value: LATITUDE_VALIDATION_PATTERN,
    message: 'Latitude is incorrect (values between -90 and 90)'
  },
  required: {
    value: true,
    message: 'Latitude is missing (values between -90 and 90)'
  }
}

export const LNG_FIELD_VALIDATION = {
  pattern: {
    value: LONGITUDE_VALIDATION_PATTERN,
    message: 'Longitude is incorrect (values between -180 and 180)'
  },
  required: {
    value: true,
    message: 'Longitude is missing (values between -180 and 180)'
  }
}
