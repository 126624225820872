import React from 'react'

const Markers = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <marker
      id="markerEnd"
      markerHeight={13}
      markerWidth={13}
      refX={10}
      refY={6}>
      <circle cx="6.5" cy="6.5" r="6.5" fill="#C050BA" />
    </marker>
  </svg>
)

export default Markers
