import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { getParams, RoutesKey } from '~/routes'

export function generateDisplayType(
  notificationTypes: CampaignType[],
  hasCardBack: boolean
): string {
  let displayType = notificationTypes
    .map((type) => MCampaignTypeToName.get(type))
    .join(' + ')
  if (notificationTypes.includes(CampaignType.CARD))
    displayType += ` (Front${hasCardBack ? ', Back' : ''})`
  return displayType
}

export function generateListsDisplayType(types: Array<CampaignType>): string {
  return types
    .map((type) => MCampaignTypeToName.get(type)?.replace(' ', ''))
    .join('-')
}

export function getMainCampaignType(
  types?: Array<CampaignType>,
  routePath?: RoutesKey
): CampaignType | undefined {
  if (types) {
    if (types.includes(CampaignType.CARD)) {
      return CampaignType.CARD
    }
    return types[0]
  }
  if (routePath) {
    const params = getParams(routePath)
    return params?.campaignType
  }

  return undefined
}

export function getAdditionalCampaignType(
  types?: Array<CampaignType>,
  routePath?: RoutesKey
): CampaignType | undefined {
  if (types) {
    const additionalCampaignType = types.filter(
      (type) => type !== getMainCampaignType(types)
    )
    return additionalCampaignType.length > 0
      ? additionalCampaignType[0]
      : undefined
  }
  if (routePath) {
    const params = getParams(routePath)
    return params?.notificationType
  }

  return undefined
}
