import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IImage } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  image: IImage
  done: (imageUrl: string) => void
  removeImage: (id: string) => void
}

const ImageItem = ({ image, done, removeImage }: IProps) => {
  if (!image) return null
  return (
    <div className="email-image-upload__media-card card">
      <div className="email-image-upload__media-container">
        <img
          className="email-image-upload__media"
          onClick={(e) => done(image.imageUrl)}
          src={image.width > 175 ? image.thumbnailUrl : image.imageUrl}
          alt={image.name}
        />
      </div>
      <Button
        color=""
        onClick={() => {
          removeImage(image.id)
        }}
        className="email-image-upload__delete p-0 m-0">
        <FontAwesomeIcon color="red" icon={faTimes} size="1x" />
      </Button>
      <p className="email-image-upload__name p-2 mb-0">{image.name}</p>
    </div>
  )
}

export default ImageItem
