import React from 'react'

import './CardAndNotification.scss'

const CardAndNotification = ({
  className
}: {
  className: string
}): React.ReactElement => (
  <svg
    id="eUqPylMA6Je1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 87 149"
    width="87"
    height="149"
    className={className}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <path
      d="M28.1732,35.66v1.116h-3.54v2.46h2.76v1.116h-2.76v3.648h-1.368v-8.34h4.908Zm2.6358,1.104v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm6.293,0v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm7.649-1.104c.888,0,1.664.172,2.328.516.672.336,1.188.824,1.548,1.464.368.632.552,1.372.552,2.22s-.184,1.584-.552,2.208c-.36.624-.876,1.104-1.548,1.44-.664.328-1.44.492-2.328.492h-2.724v-8.34h2.724Zm0,7.224c.976,0,1.724-.264,2.244-.792s.78-1.272.78-2.232c0-.968-.26-1.724-.78-2.268s-1.268-.816-2.244-.816h-1.356v6.108h1.356Z"
      fill="#93a8c5"
    />
    <g transform="translate(.045622 12.735343)" opacity="0.3">
      <rect
        width="70"
        height="134"
        rx="7"
        ry="7"
        transform="translate(1 1)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <rect
        width="58"
        height="102"
        rx="0"
        ry="0"
        transform="translate(7 20)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <rect
        width="45"
        height="35"
        rx="0"
        ry="0"
        transform="translate(13.5 34.5)"
        fill="#fff"
        stroke="#93a8c5"
      />
      <line x1="18" y1="63.5" x2="53" y2="63.5" fill="none" stroke="#93a8c5" />
      <rect
        width="45"
        height="16"
        rx="0"
        ry="0"
        transform="translate(13.5 34.5)"
        fill="#fff"
        stroke="#93a8c5"
      />
      <line x1="21" y1="58.5" x2="49" y2="58.5" fill="none" stroke="#93a8c5" />
      <rect
        width="45"
        height="35"
        rx="0"
        ry="0"
        transform="translate(13.5 76.5)"
        fill="#fff"
        stroke="#93a8c5"
      />
      <line
        x1="18"
        y1="105.5"
        x2="53"
        y2="105.5"
        fill="none"
        stroke="#93a8c5"
      />
      <rect
        width="45"
        height="16"
        rx="0"
        ry="0"
        transform="translate(13.5 76.5)"
        fill="#fff"
        stroke="#93a8c5"
      />
      <line
        x1="21"
        y1="100.5"
        x2="49"
        y2="100.5"
        fill="none"
        stroke="#93a8c5"
      />
      <path
        d="M28.1732,23.66v1.116h-3.54v2.46h2.76v1.116h-2.76v3.648h-1.368v-8.34h4.908Zm2.6358,1.104v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm6.293,0v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm7.649-1.104c.888,0,1.664.172,2.328.516.672.336,1.188.824,1.548,1.464.368.632.552,1.372.552,2.22s-.184,1.584-.552,2.208c-.36.624-.876,1.104-1.548,1.44-.664.328-1.44.492-2.328.492h-2.724v-8.34h2.724Zm0,7.224c.976,0,1.724-.264,2.244-.792s.78-1.272.78-2.232c0-.968-.26-1.724-.78-2.268s-1.268-.816-2.244-.816h-1.356v6.108h1.356Z"
        fill="#93a8c5"
      />
    </g>
    <g id="eUqPylMA6Je15">
      <rect
        width="70"
        height="134"
        rx="7"
        ry="7"
        transform="translate(16 1)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <rect
        width="58"
        height="102"
        rx="0"
        ry="0"
        transform="translate(22 17)"
        fill="#f4f7fc"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <g id="eUqPylMA6Je18_to" transform="translate(50.75,78.5)">
        <g id="eUqPylMA6Je18" transform="translate(-50.75,-58.5)" opacity="0">
          <g transform="translate(2 0)">
            <rect
              width="45"
              height="48"
              rx="0"
              ry="0"
              transform="translate(26 27.5)"
              fill="#fff"
              stroke="#93a8c5"
            />
            <rect
              width="45"
              height="20"
              rx="0"
              ry="0"
              transform="translate(26 34.5)"
              fill="#fff"
              stroke="#93a8c5"
            />
            <line
              x1="36"
              y1="59.5"
              x2="64"
              y2="59.5"
              transform="translate(-2.5 0)"
              fill="none"
              stroke="#93a8c5"
            />
            <line
              x1="39"
              y1="69.5"
              x2="61"
              y2="69.5"
              transform="translate(-2.5 0)"
              fill="none"
              stroke="#93a8c5"
            />
            <line
              x1="33"
              y1="64.5"
              x2="68"
              y2="64.5"
              transform="translate(-2.5 0)"
              fill="none"
              stroke="#93a8c5"
            />
          </g>
          <g id="eUqPylMA6Je25_tr" transform="translate(51,85) rotate(0)">
            <rect
              width="45"
              height="9"
              rx="2.5"
              ry="2.5"
              transform="translate(-22.5,-4.5)"
              fill="#93a8c5"
              stroke="#93a8c5"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="eUqPylMA6Je26" transform="translate(.087943 12.500237)" opacity="0">
      <rect
        width="70"
        height="134"
        rx="7"
        ry="7"
        transform="translate(1 1)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <rect
        width="58"
        height="102"
        rx="0"
        ry="0"
        transform="translate(7 20)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <g id="eUqPylMA6Je29_to" transform="translate(36,53.764773)">
        <g transform="translate(-36,-55.706026)">
          <rect
            width="45"
            height="35"
            rx="0"
            ry="0"
            transform="translate(13.5 38.206028)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="18"
            y1="63.5"
            x2="53"
            y2="63.5"
            transform="translate(0 3.706028)"
            fill="none"
            stroke="#93a8c5"
          />
          <rect
            width="45"
            height="16"
            rx="0"
            ry="0"
            transform="translate(13.5 38.206028)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="21"
            y1="58.5"
            x2="49"
            y2="58.5"
            transform="translate(0 3.706028)"
            fill="none"
            stroke="#93a8c5"
          />
        </g>
      </g>
      <g id="eUqPylMA6Je34_to" transform="translate(36,4.764773)">
        <g id="eUqPylMA6Je34" transform="translate(-36,-55.706026)" opacity="0">
          <rect
            width="45"
            height="35"
            rx="0"
            ry="0"
            transform="translate(13.5 38.206028)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="18"
            y1="63.5"
            x2="53"
            y2="63.5"
            transform="translate(0 3.706028)"
            fill="none"
            stroke="#93a8c5"
          />
          <rect
            width="45"
            height="16"
            rx="0"
            ry="0"
            transform="translate(13.5 38.206028)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="21"
            y1="58.5"
            x2="49"
            y2="58.5"
            transform="translate(0 3.706028)"
            fill="none"
            stroke="#93a8c5"
          />
        </g>
      </g>
      <g id="eUqPylMA6Je39_to" transform="translate(36,97.176598)">
        <g id="eUqPylMA6Je39" transform="translate(-36,-97.176598)">
          <rect
            width="45"
            height="35"
            rx="0"
            ry="0"
            transform="translate(13.5 79.676596)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="18"
            y1="105.5"
            x2="53"
            y2="105.5"
            transform="translate(0 3.176596)"
            fill="none"
            stroke="#93a8c5"
          />
          <rect
            width="45"
            height="16"
            rx="0"
            ry="0"
            transform="translate(13.5 79.676596)"
            fill="#fff"
            stroke="#93a8c5"
          />
          <line
            x1="21"
            y1="100.5"
            x2="49"
            y2="100.5"
            transform="translate(0 3.176596)"
            fill="none"
            stroke="#93a8c5"
          />
        </g>
      </g>
      <path
        d="M28.1732,23.66v1.116h-3.54v2.46h2.76v1.116h-2.76v3.648h-1.368v-8.34h4.908Zm2.6358,1.104v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm6.293,0v2.448h2.88v1.116h-2.88v2.556h3.24v1.116h-4.608v-8.352h4.608v1.116h-3.24Zm7.649-1.104c.888,0,1.664.172,2.328.516.672.336,1.188.824,1.548,1.464.368.632.552,1.372.552,2.22s-.184,1.584-.552,2.208c-.36.624-.876,1.104-1.548,1.44-.664.328-1.44.492-2.328.492h-2.724v-8.34h2.724Zm0,7.224c.976,0,1.724-.264,2.244-.792s.78-1.272.78-2.232c0-.968-.26-1.724-.78-2.268s-1.268-.816-2.244-.816h-1.356v6.108h1.356Z"
        fill="#93a8c5"
      />
    </g>
  </svg>
)

export default CardAndNotification
