import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement } from 'react'
import { Button } from 'reactstrap'
import Link from '~/components/Link'
import CampaignSubtile, { NotificationTypeTile } from './CampaignSubtile'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'

function CardNotificationType({
  types,
  goBack
}: {
  types: NotificationTypeTile[]
  goBack: () => void
}): ReactElement {
  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <>
      <Button
        data-testid="go-back"
        onClick={goBack}
        color=""
        className="text-decoration-none ps-0 pt-0 mb-xxl-3 align-self-start">
        <FontAwesomeIcon className="me-2" icon={faChevronLeft} />
        Notification + {MCampaignTypeToName.get(CampaignType.CARD)} Campaign
      </Button>

      {types.map((type) => {
        if (type.isDisabled) {
          return null
        }
        return (
          <div key={type.name}>
            {type.onClick ? (
              <CampaignSubtile type={type} />
            ) : (
              <Link
                tabIndex={-1}
                route={type.route}
                params={type.params}
                className="text-decoration-none ">
                <CampaignSubtile type={type} />
              </Link>
            )}
          </div>
        )
      })}
    </>
  )
}

export default CardNotificationType
