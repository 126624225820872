import React, { Component } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip, Nav } from 'reactstrap'
import { headwayBadge, unreadCount, outlineNone } from './NavBar.scss'
import AppsDropdown from './AppsDropdown'
import AccountDropdown from './AccountDropdown'
import { store, storeContext, withStore } from '~/dataStore'
import { ADMIN_ROLES } from '~/common.interface'
import Link from '../Link'
import routes from '~/routes'
import style from '~/components/Navbar/NavBar.scss'
import Img from '~/components/Img'
import defaultAppLogo from '~/assets/img/default-app-logo.svg'

class NavbarDropdowns extends Component {
  static contextType = storeContext

  feedPostCalled = false

  state: {
    unreadCount: undefined | number
  } = {
    unreadCount: undefined
  }

  componentDidUpdate(): void {
    if (
      this.context.app.appDetails.featureFlags.webSdk &&
      window.PulsateSDK &&
      !this.feedPostCalled
    ) {
      this.feedPostCalled = true
      window.PulsateSDK?.getUnreadCount(
        ({ unreadCount }: { unreadCount: number }) =>
          this.setState({ unreadCount })
      )
    }
  }

  render() {
    return (
      <Nav className={cn('align-items-center')} navbar>
        {store.app.currentAdmin.role &&
        ![ADMIN_ROLES.APP_ADMIN, ADMIN_ROLES.DEMO_ADMIN].includes(
          store.app.currentAdmin.role
        ) ? (
          <AppsDropdown />
        ) : (
          <>
            <Img
              src={store.app.appDetails.image}
              className={cn(
                'img-fluid',
                'rounded-circle',
                style.currentAppImage
              )}
              fallbackSrc={defaultAppLogo}
              alt={store.app.appDetails.name}
              aria-label={`Current app ${store.app.appDetails.name}`}
            />
            <span
              className={cn(style.currentAppName, 'me-3')}
              aria-label={`Current app name ${store.app.appDetails.name}`}>
              {store.app.appDetails.name}
            </span>
          </>
        )}

        {this.context.app.appDetails.featureFlags.webSdk && (
          <Link
            route={routes.feed}
            params={{ appId: this.context.app.currentApp.id }}
            className={cn(
              'headwayapp',
              headwayBadge,
              'me-2',
              'position-relative'
            )}
            ariaLabel="Notifications">
            <FontAwesomeIcon
              icon={faBell}
              fixedWidth
              size="lg"
              id="onThePulse"
              className={cn('align-middle', 'text-white', outlineNone)}
            />
            {this.state.unreadCount > 0 && (
              <span
                className={cn(unreadCount)}
                aria-label={`Unread notifications ${this.state.unreadCount}`}>
                {this.state.unreadCount}
              </span>
            )}
            <UncontrolledTooltip placement="left" target="onThePulse">
              On The Pulse
            </UncontrolledTooltip>
          </Link>
        )}

        <AccountDropdown />
      </Nav>
    )
  }
}

export default withStore(NavbarDropdowns)
