import React from 'react'
import TableNew from '~/components/Table/TableNew'

import styles from '../Dashboard.scss'

const SDKStats = ({
  tableData
}: {
  tableData: Array<{ value: number; version: string }>
}) => {
  return (
    <div className={styles.tableWrapper}>
      <TableNew
        stickyHeader
        columns={[
          {
            fieldName: 'version',
            displayName: 'Name',
            sortable: false
          },
          {
            fieldName: 'value',
            displayName: '',
            sortable: false
          }
        ]}
        rows={tableData}
      />
    </div>
  )
}

export default SDKStats
