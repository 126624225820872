import React from 'react'
import { Card, Col, Row } from 'reactstrap'
import cn from 'classnames'
import styles from './TopNotifications.scss'
import { useStore } from '~/dataStore'
import { useGetTopNotifications } from '~/pages/OpportunitiesDashboard/useOpportunitiesDashboard'

const TopNotifications = () => {
  const { app } = useStore()
  const { data } = useGetTopNotifications(app.appDetails.id)

  const progressBarBgColor = (index: number, value: number) => {
    if (value === 0) {
      return '#fff'
    }

    if (index === 0) {
      return '#28518c'
    }

    return '#eef2f7'
  }

  const progressBarColor = (index: number, value: number) => {
    if (value === 0) {
      return '#28518c'
    }

    if (index === 0) {
      return '#fff'
    }

    return '#28518c'
  }

  return (
    <>
      <p className="text-muted text-20 mb-xxl-5 mb-xxxl-3">
        Your most successful notification type
      </p>
      <Card className="p-4 py-5 mb-0">
        {data?.map((item, index) => (
          <Row
            key={item.key}
            className={cn('align-items-center', {
              'mb-4': index !== data.length - 1
            })}>
            <Col xs="5" className="text-end">
              <span className="fw-bold text-18">{item.key}</span>
            </Col>
            <Col xs="7">
              <div className="w-100">
                <div
                  style={{
                    width: item.value > 30 ? `${item.value}%` : '30%',
                    backgroundColor: progressBarBgColor(index, item.value),
                    color: progressBarColor(index, item.value)
                  }}
                  className={cn(styles.progressBar)}>
                  {item.value}%{' '}
                  {index === 0 && item.value !== 0 && (
                    <div>
                      <span className="d-block d-xxl-none d-xxxl-block ms-1">
                        Opportunity
                      </span>
                      <span className="d-none d-xxl-block d-xxxl-none ms-1">
                        Opp
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </Card>
    </>
  )
}

export default TopNotifications
