import { ReactElement } from 'react'
import Select from '~/components/forms/Select'

interface IProps {
  placeholder: string
  options: { value: string; name: string | ReactElement }[]
  selected?: string
  onSelect: (value: string) => void
  disabled?: boolean
}

export default function Deeplink({
  placeholder,
  selected,
  onSelect,
  options,
  disabled
}: IProps): ReactElement {
  return (
    <Select
      options={options}
      title={placeholder}
      disabled={!!disabled}
      wrapperClassName="w-100"
      className="w-100 dropdown-toggle--right"
      popupClassName="w-100 opacity-animation"
      selectedOption={selected}
      onSelect={onSelect}
    />
  )
}
