import React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import {
  EMAIL_VERIFICATION_LIST_QUERY,
  ERRORS_DICT
} from '../EmailSection.interface'
import Modal from '~/components/modals/Modal/Modal'
import Input from '~/components/forms/Input'
import { addEmailAddressToVerify } from '~/api/appSettings'
import {
  getFormServerSideErrors,
  hookFormValidation,
  showGeneralError
} from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'

interface IProps {
  close: () => void
}

const AddEmailAddress = ({ close }: IProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isDirty }
  } = useForm<{ email: string }>()
  const queryClient = useQueryClient()
  const { appId } = useParams<{ appId: string }>()

  const onSubmit = async (data: { email: string }) => {
    try {
      await addEmailAddressToVerify(appId, data.email)
      queryClient.invalidateQueries(EMAIL_VERIFICATION_LIST_QUERY)
      showNotification(
        'Email address added for verification. Please check your inbox for Email from AWS with Address Verification Request.',
        NotificationType.SUCCESS
      )
      close()
    } catch (error: Error & { body: { errors: { email: Array } } }) {
      if (error.body?.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ModalBody>
        <Input
          register={register}
          type="email"
          placeholder="Enter Email Address"
          label="Email address"
          className={cn({
            'form-group--error': errors.email
          })}
          validation={hookFormValidation.email}
          errorTooltip={errors?.email?.message}
          tooltip=""
          novalidate
          name="email"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={isSubmitting || !isDirty}>
          Verify{' '}
          {isSubmitting && (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-2" />
          )}
        </Button>
      </ModalFooter>
    </form>
  )
}

const AddEmailAddressModal = () => {
  return (
    <Modal
      id="addEmailAddressModal"
      size="md"
      renderHeader={() => 'New Email Address'}
      render={(close: () => void) => <AddEmailAddress close={close} />}
    />
  )
}

export default AddEmailAddressModal
