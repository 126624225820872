import {
  CampaignType,
  DeviceType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import { isNoGap, isWithOverlay } from './Preview.service'
import PreviewBox from './UI/PreviewBox'
import PreviewDevice from './UI/PreviewDevice'

interface IProps {
  device: DeviceType
  deviceSize?: 'xs' | 'sm' | 'lg' | 'xl'
  campaignType?: CampaignType
  preview?: IPreviewParts
  id?: string
  withTooltip?: boolean
}

export default function PreviewMobile({
  device,
  campaignType,
  preview,
  deviceSize,
  id,
  withTooltip
}: IProps): React.ReactElement {
  const isSmallInApp = isNoGap(preview)
  const withOverlay = isWithOverlay(preview)

  return (
    <PreviewDevice
      deviceSize={deviceSize}
      deviceType={device}
      campaignType={campaignType}
      id={id}
      withOverlay={withOverlay}
      isSmallInApp={isSmallInApp}>
      {campaignType && (
        <PreviewBox
          noGap={isSmallInApp || campaignType === CampaignType.CARD_BACK}
          deviceType={device}
          preview={preview}
          campaignType={campaignType}
          withTooltip={withTooltip}
        />
      )}
    </PreviewDevice>
  )
}
