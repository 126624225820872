import { uniqueId } from 'lodash'
import { useRef } from 'react'
import InAppSmall from '~/pages/Campaign/Notification/InAppNotification/Model/InAppSmall.model'
import ToggleRadioSwitch from '~/components/forms/ToggleRadioSwitch'
import { withStore } from '~/dataStore'

interface IProps {
  notification: InAppSmall
  radioName: string
}
function InAppPositionSwitch({
  notification,
  radioName
}: IProps): React.ReactElement {
  const options = useRef([
    {
      value: 'top',
      label: 'Top',
      name: radioName,
      id: uniqueId()
    },
    {
      value: 'bottom',
      label: 'Bottom',
      name: radioName,
      id: uniqueId()
    }
  ])
  return (
    <>
      <p className="text-blue-gray mt-2 mb-2">
        Select the In-App Notification Position
      </p>
      <ToggleRadioSwitch
        selected={notification.position.value}
        options={options.current}
        onChange={(e) => notification.setPosition(e.target.value)}
      />
    </>
  )
}

export default withStore(InAppPositionSwitch)
