import Select from '~/components/forms/Select'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  isValid: boolean
  onSelect: (value: CampaignType) => void
  options: { name: string; value: CampaignType }[]
}

export default function NotificationSelect({
  isValid,
  onSelect,
  options
}: IProps): React.ReactElement {
  return (
    <Select
      invalid={!isValid}
      wrapperClassName="w-100"
      className="w-100 dropdown-toggle--right"
      popupClassName="w-100"
      options={options}
      selectedOption="Choose Notification Type"
      onSelect={onSelect}
    />
  )
}
