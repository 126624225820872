import React from 'react'
import { Activity, UserMinus, UserPlus, UserX } from 'react-feather'
import cn from 'classnames'

import { Row } from 'reactstrap'
import StatCard from '~/pages/App/Dashboard/UI/Statcard'

interface IProps {
  days: number
  users: {
    total: { valueCurrent: string; diffCurrent: string }
    active: { valueCurrent: string; diffCurrent: string }
    lost: { valueCurrent: string; diffCurrent: string }
    new: { valueCurrent: string; diffCurrent: string }
    inactivated: { valueCurrent: string; diffCurrent: string }
  }
}

export default function UserStats({
  users: { active, lost, inactivated, ...other },
  days
}: IProps): React.ReactElement {
  const daysAgo = `${days} days`

  return (
    <div className="w-100">
      <Row>
        <StatCard
          title="Total Active Users"
          value={active.valueCurrent}
          diff={active.diffCurrent}
          diffDescription={daysAgo}
          icon={<Activity className={cn('feather', 'align-middle')} />}
          tooltip="Total number of users with an active app downloaded to their device whom have logged in within the last 30 days."
        />

        <StatCard
          title="Total Inactive Users"
          value={inactivated.valueCurrent}
          diff={inactivated.diffCurrent}
          diffDescription={daysAgo}
          icon={<UserMinus className={cn('feather', 'align-middle')} />}
          tooltip="Total number of users who haven't logged on in the last 6 months."
        />

        <StatCard
          title="New Users"
          value={other.new.valueCurrent}
          diff={other.new.diffCurrent}
          diffDescription={daysAgo}
          icon={<UserPlus className={cn('feather', 'align-middle')} />}
          tooltip="Total number of users who previously never used the app, who started to use the app yesterday."
        />

        <StatCard
          title="Unreachable Users"
          value={lost.valueCurrent}
          diff={lost.diffCurrent}
          diffDescription={daysAgo}
          icon={<UserX className={cn('feather', 'align-middle')} />}
          tooltip="Uninstalled users (based on apple/google telling us) or Unsubscribed users (users who do not want to be contacted and are manually unsubscribed in the portal)."
        />
      </Row>
    </div>
  )
}
