import cn from 'classnames'
import styles from '../JourneyTeaser.scss'
import AboutCardItem from './AboutCardItem'

export default function About(): React.ReactElement {
  return (
    <section className={cn(styles.about)}>
      <header className={cn(styles.textContainer)}>
        <h2 className={cn(styles.aboutHeader)}>What is Journeys</h2>
        <p className="text-18">
          Journeys gets the right message, through the right channel, to the
          right member, at the right time – simply, easily and quickly
        </p>
      </header>
      <div className={cn(styles.aboutCards)}>
        <AboutCardItem
          iconSet="channel"
          header="Multi-channel Journeys"
          subheader="Get the right message to the right member through the right channel"
          description="Automate the delivery of communications across multi-channels –
          mobile, email, SMS, triggered by real time member engagement and
          behavior"
        />
        <AboutCardItem
          iconSet="message"
          header="Multi-message Journeys"
          subheader="Deliver the right message to the right member, at the right
          stage in their path to purchase"
          description="Map your messages to each stage in of a member’s decision making
          journey – trigger and automate delivery based on real-time actions
          and engagement"
        />
        <AboutCardItem
          iconSet="stats"
          header="Insights and Analytics"
          subheader="Get a complete picture of campaign effectiveness in a single
                view"
          description="See key metrics such as open, engagement conversions rates by
              channel, by campaigns, by message, at a click of a button"
        />
      </div>
    </section>
  )
}
