import React from 'react'
import cn from 'classnames'
import PreviewMergeTagText from './PreviewMergeTagText'

interface IProps {
  text: string
  onClick?: () => void
}

const Headline = ({ text, onClick }: IProps): React.ReactElement => {
  return (
    // <div className="preview__headline text-truncate">
    <div
      data-testid="preview-part"
      className={cn('preview__headline', { 'cursor-pointer': !!onClick })}
      onClick={onClick}>
      <PreviewMergeTagText text={text} />
    </div>
  )
}

export default Headline
