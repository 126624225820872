import { action, computed, makeObservable, observable, override } from 'mobx'
import { HtmlExport } from 'react-email-editor'
import { IEmailPayload } from '~/dataStore/Campaign/Campaign.interface'
import StepStore from '~/dataStore/emailBuilder/StepStore'
import {
  IDesign,
  IRegisteredField
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { IEmailDTO } from '../../Notification.interface'

export default class EmailNotification extends StepStore {
  isValid = true

  editorReady = false

  constructor() {
    super()

    makeObservable(this, {
      isValid: observable,
      editorReady: observable,
      setEditorReady: action.bound,
      editorData: observable,
      setEditorData: action.bound,
      validateStep: action.bound,
      isStepValid: override,
      registeredFields: computed
    })

    this.getEditorReady = this.getEditorReady.bind(this)
  }

  public get html(): string | undefined {
    return this.editorData?.html
  }

  setEditorReady(state: boolean): void {
    this.beenValid = true
    this.editorReady = state
  }

  getEditorReady(): boolean {
    return this.editorReady
  }

  editorData: HtmlExport | null = null

  setEditorData(data: HtmlExport): void {
    this.editorData = data

    if (!data.html) {
      this.isValid = false
    } else {
      this.setEditorReady(this.beenValid)
      this.validateStep()
    }
  }

  validateStep(): void {
    this.isValid = this.editorReady
    this.beenValid = true
  }

  get isStepValid(): boolean {
    return this.editorReady && this.isValid
  }

  public get registeredFields(): IRegisteredField[] {
    return []
  }

  public fillStore({
    emailNotification
  }: {
    emailNotification?: IEmailPayload | IEmailDTO
  }): void {
    if (!emailNotification) {
      return
    }

    this.setEditorReady(true)

    if ('design' in emailNotification) {
      this.setEditorData({
        design: emailNotification.design as IDesign,
        html: emailNotification.html
      })
    } else if ('frontParts' in emailNotification) {
      this.setEditorData({
        design: emailNotification.frontParts.emailBody.design as IDesign,
        html: emailNotification.frontParts.emailBody.html
      })
    }
  }

  public getPayload(): Pick<IEmailPayload, 'design' | 'html'> & {
    active: true
  } {
    return {
      active: true,
      design: this.editorData?.design
        ? JSON.stringify(this.editorData.design)
        : undefined,
      html: this.editorData?.html || ''
    }
  }
}
