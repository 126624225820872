import React from 'react'
import { Fade, Collapse } from 'reactstrap'

import Checkbox from '~/components/forms/Checkbox'
import DateAndTimeInput from '~/components/forms/DateAndTimeInput'
import { withStore, useStore } from '~/dataStore'
import { getTimeNowPlusHour } from '~/utils/utilities'

const CampaignStartsEndsAt = () => {
  const {
    campaign: {
      deliver: {
        startAt,
        endAt,
        endAtActive,
        setEndAt,
        setEndAtActive,
        validateEndAt,
        validateStartAt,
        setStartAt,
        deliverUi: { isDeliveryFutureOrDefault }
      }
    }
  } = useStore()

  const toggleAdditionalEndDate = (state: boolean) => {
    if (state) {
      setEndAtActive(true)
      setEndAt(getTimeNowPlusHour())
    } else {
      setEndAt(null)
      setEndAtActive(false)
      validateEndAt()
    }
  }

  const onEndDateChange = (value: Date) => {
    setEndAt(value)
    validateEndAt()
  }

  const onStartDateChange = (value: Date) => {
    setStartAt(value)
    validateStartAt()
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <DateAndTimeInput
          value={startAt.value}
          onChange={onStartDateChange}
          label="Start"
          className="d-flex"
          labelClassName="me-3"
          invalid={!startAt.isValid}
        />
        <Fade in={isDeliveryFutureOrDefault}>
          <div className="ms-3">
            <Checkbox
              onChange={(state) => toggleAdditionalEndDate(state)}
              checked={endAtActive}
              label="Add optional end date"
            />
          </div>
        </Fade>
      </div>
      <Collapse isOpen={endAtActive && isDeliveryFutureOrDefault}>
        <div className="d-flex align-items-center mt-3">
          <DateAndTimeInput
            value={endAt.value}
            onChange={onEndDateChange}
            label="End"
            minDate={startAt.value}
            className="d-flex"
            labelClassName="me-4"
            invalid={!endAt.isValid}
          />
        </div>
      </Collapse>
    </div>
  )
}

export default withStore(CampaignStartsEndsAt)
