import cn from 'classnames'
import CustomDropdown from '~/components/CustomDropdown'
import styles from '../SegmentBuilder.scss'

interface IProps<T> {
  selected: string | undefined
  options: { name: string; value: T }[]
  action: (value: T) => void
}

export default function RuleDropdown<T>({
  selected,
  options,
  action
}: IProps<T>): React.ReactElement {
  return (
    <CustomDropdown
      toggleAsButton
      wrapperClassname={cn(styles.ruleDropdown)}
      dropdownToggle={
        <div
          className={cn(
            'pe-0 dropdown-toggle dropdown-toggle--right text-left'
          )}>
          {selected}
        </div>
      }
      options={options.map((option) => ({
        name: option.name,
        action: () => action(option.value)
      }))}
    />
  )
}
