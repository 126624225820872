import React from 'react'
import './SMSIcon.scss'

const SMSIcon = ({ className }: { className: string }): React.ReactElement => (
  <svg
    id="eUevQJY3zDg1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 136"
    width="72"
    height="136"
    className={className}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <g transform="translate(0 0.000001)">
      <rect
        width="70"
        height="134"
        rx="7"
        ry="7"
        transform="translate(1 1)"
        fill="#fff"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <rect
        width="58"
        height="102"
        rx="0"
        ry="0"
        transform="translate(7 17)"
        fill="#bfcbdd"
        stroke="#93a8c5"
        strokeWidth="2"
      />
      <g id="eUevQJY3zDg5_to" transform="translate(36,52.85355)">
        <g id="eUevQJY3zDg5" transform="translate(-36,-59.85355)" opacity="0">
          <path
            d="M16,25c-1.6569,0-3,1.3431-3,3v17v9l6-6h37c1.6569,0,3-1.3431,3-3v-17c0-1.6569-1.3431-3-3-3h-40Z"
            transform="translate(.16108 20)"
            clipRule="evenodd"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="M13,54h-.5v1.2071l.8536-.8535L13,54Zm6-6v-.5h-.2071l-.1465.1464L19,48ZM13.5,28c0-1.3807,1.1193-2.5,2.5-2.5v-1c-1.933,0-3.5,1.567-3.5,3.5h1Zm0,17v-17h-1v17h1Zm0,9v-9h-1v9h1Zm5.1464-6.3536l-6,6l.7072.7072l6-6-.7072-.7072ZM56,47.5h-37v1h37v-1ZM58.5,45c0,1.3807-1.1193,2.5-2.5,2.5v1c1.933,0,3.5-1.567,3.5-3.5h-1Zm0-17v17h1v-17h-1ZM56,25.5c1.3807,0,2.5,1.1193,2.5,2.5h1c0-1.933-1.567-3.5-3.5-3.5v1Zm-40,0h40v-1h-40v1Z"
            transform="translate(0 20)"
            fill="#93a8c5"
          />
          <line
            x1="19"
            y1="40.5"
            x2="54"
            y2="40.5"
            transform="translate(0 20)"
            fill="none"
            stroke="#93a8c5"
          />
          <line
            x1="31"
            y1="33.5"
            x2="54"
            y2="33.5"
            transform="translate(0 20)"
            fill="none"
            stroke="#93a8c5"
          />
          <circle
            r="3.5"
            transform="translate(23 53)"
            fill="#fff"
            stroke="#93a8c5"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SMSIcon
