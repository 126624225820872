import cn from 'classnames'

/**
 *
 * @param spaceTop only value without units: 4 | "4"
 * @param spaceBottom only value without units:  4 | "4"
 */
export default function SeparatorLine({
  spaceTop = 4,
  spaceBottom = 4,
  dashed
}: {
  spaceTop?: string | number
  spaceBottom?: string | number
  dashed?: boolean
}): React.ReactElement {
  return (
    <div
      className={cn(`mt-${spaceTop} mb-${spaceBottom} separator-line`, {
        'separator-line--dashed': dashed
      })}
    />
  )
}
