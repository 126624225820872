import { ID } from '~/common.interface'

export interface IFromName {
  id: ID
  name: string
}

export const EMAIL_FROM_NAMES_LIST_QUERY = 'emailFromNamesList'

export const ERRORS_DICT = {
  name: {
    'is already taken': 'This email from name is already taken'
  }
}
