import { Button } from 'reactstrap'
import { humanDateTime } from '~/utils/time'
import Link from '~/components/Link'
import { useStore } from '~/dataStore'
import { IUser } from '~/pages/Users/Users.interface'
import routes from '~/routes'

const handleDate = (lastSessionAt: string) => {
  if (lastSessionAt) {
    return humanDateTime(lastSessionAt)
  }
  return '- -'
}

const usersListColumns = [
  {
    fieldName: 'lastName',
    displayName: 'Name',
    sortable: true,
    render: ({ row: { fullName } }: { row: IUser }) => (
      <span aria-label={`User Full Name: ${fullName}`}>{fullName}</span>
    )
  },
  {
    fieldName: 'alias',
    displayName: 'Alias',
    render: ({ row: { alias } }: { row: IUser }) => (
      <span aria-label={`User Alias: ${alias}`}>{alias}</span>
    )
  },
  {
    fieldName: 'createdAt',
    displayName: 'Signed Up',
    sortable: true,
    render: ({ row: { createdAt } }: { row: IUser }) => (
      <span aria-label={`User Signed Up Date: ${humanDateTime(createdAt)}`}>
        {humanDateTime(createdAt)}
      </span>
    )
  },
  {
    fieldName: 'lastSessionAt',
    displayName: 'Last Active',
    sortable: true,
    render: ({ row: { lastSessionAt } }: { row: IUser }) => (
      <span aria-label={`User Last Active Date: ${handleDate(lastSessionAt)}`}>
        {handleDate(lastSessionAt)}
      </span>
    )
  },
  {
    fieldName: 'currentCity',
    displayName: 'Last Location',
    render: ({ row: { currentCity } }: { row: IUser }) => (
      <span aria-label={`User Last Location: ${currentCity || '- -'}`}>
        {currentCity || '- -'}
      </span>
    ),
    sortable: true
  },
  {
    displayName: 'Profile',
    render: ({ row: { id } }: { row: IUser }) => {
      const {
        app: { currentApp }
      } = useStore()
      return (
        <Button
          data-testid="viewUserProfileBtn"
          tag={Link}
          route={routes.userProfile}
          params={{ appId: currentApp?.id, userId: id }}
          color="body"
          className="bg-white btn--wide btn--hover"
          outline
          aria-label={`View Profile Button for User ID: ${id}`}>
          View
        </Button>
      )
    }
  }
]

export default usersListColumns
