import { Button } from 'reactstrap'
import GoalIcon from '~/pages/Campaign/Notification/NotificationGoals/UI/GoalIcon'
import Select from '~/components/forms/Select'
import { GoalType } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import { capitalizeString } from '~/utils/string'

interface IProps {
  goals: GoalType[]
  invalid?: boolean
  selectedOption: GoalType | undefined
  onSelect: (value: GoalType) => void
  openPanel: () => void
  disabled?: boolean
}

export default function GoalDropdown(props: IProps): React.ReactElement {
  const {
    goals,
    selectedOption,
    onSelect,
    openPanel,
    invalid,
    disabled = false
  } = props

  let options = [
    {
      name: (
        <div className="text-wrap text-center text-blue-gray">
          <strong>There are no Goals to display</strong>
          <p>
            Please return to the message and set a Primary / Secondary Goal in
            the notifications
          </p>
          <Button tag="div" color="primary" onClick={openPanel}>
            Open Message
          </Button>
        </div>
      ),
      value: ''
    }
  ]
  if (goals.length) {
    options = goals.map((g) => ({
      name: (
        <div className="d-flex align-items-center">
          <GoalIcon type={g} />
          <span className="ps-2">{capitalizeString(g)} Goal</span>
        </div>
      ),
      value: g
    }))
  }

  return (
    <Select
      disabled={disabled}
      invalid={invalid}
      wrapperClassName="w-100"
      className="w-100 dropdown-toggle--right"
      popupClassName="w-100"
      title="Choose Goal"
      options={options}
      selectedOption={selectedOption}
      onSelect={(value) => {
        if (value) {
          onSelect(value)
        }
      }}
    />
  )
}
