import React, { ReactElement } from 'react'
import {
  faMessage,
  faTrash,
  faBellSlash
} from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'
import IconButton from '~/components/IconButton'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal'
import { withStore, useStore } from '~/dataStore'
import useUserDetails, {
  useDeleteUser,
  useUnsubscribeUser
} from '~/pages/Users/UserDetails/useUserDetails'

const UserProfileActions = (): ReactElement => {
  const {
    goTo,
    app: {
      appDetails: {
        featureFlags: { messages }
      },
      currentAdmin: { isDemoAdmin },
      currentApp: { id: appId }
    },
    ui: { showModal }
  } = useStore()
  const { data: user } = useUserDetails()
  const { mutateAsync: deleteUser } = useDeleteUser()
  const { mutateAsync: unsubscribeUser } = useUnsubscribeUser()

  return (
    <>
      <div className="d-flex justify-content-end">
        {messages && (
          <>
            <IconButton
              icon={faMessage}
              id={`message-${user?.id}`}
              ariaLabel="Send Message"
              onClick={() => {
                goTo(
                  'newMessage',
                  { appId },
                  {},
                  { userId: user?.id, userName: user?.fullName }
                )
              }}
            />
            <UncontrolledTooltip
              placement="bottom"
              target={`message-${user?.id}`}>
              Send Message
            </UncontrolledTooltip>
          </>
        )}
        <IconButton
          disabled={isDemoAdmin}
          icon={faTrash}
          id={`delete-${user?.id}`}
          ariaLabel="Delete User"
          onClick={() =>
            showModal('confirmDeleteUser', { displayName: user?.fullName })
          }
        />
        <UncontrolledTooltip placement="bottom" target={`delete-${user?.id}`}>
          Delete
        </UncontrolledTooltip>
        {!user?.status.unsubscribed && (
          <>
            <IconButton
              disabled={isDemoAdmin}
              icon={faBellSlash}
              id={`unsubscribe-${user?.id}`}
              ariaLabel="Unsubscribe User"
              onClick={() =>
                showModal('confirmUnsubscribeUser', {
                  displayName: user?.fullName
                })
              }
            />
            <UncontrolledTooltip
              placement="bottom"
              target={`unsubscribe-${user?.id}`}>
              Unsubscribe
            </UncontrolledTooltip>
          </>
        )}
      </div>

      <Modal
        id="confirmUnsubscribeUser"
        renderHeader={() => 'Unsubscribe User'}
        render={(close: () => void, params) => (
          <ConfirmationModal
            onSubmit={unsubscribeUser}
            onClose={close}
            okText="Unsubscribe"
            cancelText="Cancel">
            Are you sure you want to unsubscribe from user{' '}
            <strong>{params?.displayName}</strong>?
          </ConfirmationModal>
        )}
      />

      <Modal
        id="confirmDeleteUser"
        renderHeader={() => 'Delete User'}
        render={(close: () => void, params) => (
          <ConfirmationModal
            onSubmit={deleteUser}
            onClose={close}
            okText="Delete"
            cancelText="Cancel">
            <p>
              Deleting user <strong>{params?.displayName}</strong> is permanent
              and cannot be undone.
            </p>
          </ConfirmationModal>
        )}
      />
    </>
  )
}

export default withStore(UserProfileActions)
