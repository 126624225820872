import { ReactElement, useState } from 'react'
import InAppLargeModel, {
  DNDFields,
  InAppFields,
  ToggleFields
} from '~/pages/Campaign/Notification/InAppNotification/Model/InAppLarge.model'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import SeparatorLine from '~/components/SeparatorLine'
import AdminHeaderWithMessage from '../../../../CampaignBuilder/MobileCampaignPanel/AdminHeaderWIthMessage.field'
import UploadImage from '~/components/uploadImage/UploadImage'
import withDND from '~/components/DND'
import { withStore } from '~/dataStore'
import { isEmpty } from '~/utils/utilities'
import { ActionTypes } from '~/dataStore/Campaign/Campaign.interface'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import useCollapseContext from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import Shared from '~/dataStore/Campaign/Shared'
import NotificationGoals from '~/pages/Campaign/Notification/NotificationGoals'
import AdminHeader from '~/pages/Campaign/Notification/Preview/Parts/AdminHeader'
import CampaignCTAGroupButton from '~/pages/CampaignBuilder/CTA/CampaignCTAGroupButton'

interface IProps {
  notification: InAppLargeModel
  shared: Shared
  ctaActions: ActionTypes[]
  withEvent?: boolean
  isWithCardCampaign?: boolean
  isSended?: boolean
  withGoals?: boolean
  notificationGoals?: NotificationGoals
  onDestinationTypeChange?: (value: ActionTypes) => void
  adminHeaderEditable?: boolean
}

const categoryOptions = [
  { value: '1', name: '1 Button' },
  { value: '2', name: '2 Buttons' }
]

const ToggleWithDND = withDND(CollapseWithToggle)

function InAppLarge({
  notification,
  shared,
  ctaActions,
  withEvent,
  isWithCardCampaign,
  isSended,
  withGoals,
  notificationGoals,
  onDestinationTypeChange,
  adminHeaderEditable = true
}: IProps): ReactElement {
  const { mergeTags, admins, deeplinksOptions, eventsOptions } = shared
  const { opened: openPicker, setOpened: setOpenPicker } =
    useCollapseContext() || {}
  const [dragged, setDragged] = useState<{ type: DNDFields } | undefined>()

  function setPosition(destination: DNDFields): void {
    if (dragged) {
      notification.replaceFieldPosition(destination, dragged.type)
    }
  }

  function handleCheck(type: ToggleFields): (check: boolean) => void {
    return (check: boolean) => {
      if (isSended) {
        return
      }

      if (!check) {
        notification.reset(type)
      } else {
        notification.setActive(type, true)
      }
      notification[type].validate()
    }
  }

  const pickerProps = (type: InAppFields, name: string) => ({
    type,
    name,
    disabled: isSended,
    opened: openPicker === type,
    setPosition,
    setDragged: (dragged) => {
      setDragged(dragged)
      setOpenPicker?.(null)
    },
    isValid:
      (type === 'actionButtons' || notification.fieldOption.isValid) &&
      notification.isValidField(type),
    setOpened: (open: boolean) => {
      setOpenPicker?.(open ? type : null)
    },
    checked: !isEmpty(notification[type as ToggleFields].isActive)
      ? notification[type as ToggleFields].isActive
      : true,
    setChecked: handleCheck(type as ToggleFields)
  })

  return (
    <>
      <CollapseWithToggle {...pickerProps('adminHeader', 'Personal Message')}>
        {adminHeaderEditable ? (
          <AdminHeaderWithMessage
            admins={admins}
            onSelect={notification.setAdmin}
            selected={notification.admin}
            onChange={notification.adminHeader.setValue}
            value={notification.adminHeader.value}
            invalid={!notification.adminHeader.isValid}
            disabled={isSended}
            onBlur={() => {
              notification.adminHeader.validate()
              notification.validateAdminHeaderInvalidChars()
            }}
            mergeTags={mergeTags}
          />
        ) : (
          <>
            <AdminHeader
              image={notification.admin?.avatarUrl}
              name={notification.admin?.name || ''}
              jobTitle={notification.admin?.jobTitle || ''}
              message={notification.adminHeader.value || ''}
            />
          </>
        )}
      </CollapseWithToggle>
      <SeparatorLine spaceBottom="0" spaceTop="0" />
      <div className="d-flex flex-column">
        <ToggleWithDND
          {...pickerProps('image', 'Image')}
          withSeparator
          position={notification.image.position}>
          <UploadImage
            allowedTypes={['jpg', 'png', 'jpeg', 'gif']}
            onChange={notification.image.addInternalFile}
            image={notification.image.value}
            fileName={notification.image.name}
            maxFileSizes={[{ type: 'image', maxSize: 1_000_000, error: '1MB' }]}
            className="mb-3"
          />
        </ToggleWithDND>
        <ToggleWithDND
          {...pickerProps('headline', 'Headline')}
          withSeparator
          position={notification.headline.position}>
          <Textarea
            onChange={notification.headline.setValue}
            value={notification.headline.value}
            invalid={!notification.headline.isValid}
            required
            withoutLineBreaks
            disabled={isSended}
            onBlur={() => notification.headline.validate()}
            placeholder="Add a Headline that grabs people’s attention... "
            mergeTags={mergeTags}
          />
        </ToggleWithDND>
        <ToggleWithDND
          {...pickerProps('text', 'Text')}
          withSeparator
          position={notification.text.position}>
          <Textarea
            onChange={notification.text.setValue}
            value={notification.text.value}
            invalid={!notification.text.isValid}
            disabled={isSended}
            onBlur={() => notification.text.validate()}
            placeholder="Tell people more about your campaign..."
            mergeTags={mergeTags}
          />
        </ToggleWithDND>
      </div>
      <CollapseWithToggle
        onlyCollapse
        {...pickerProps('actionButtons', 'Call to Action')}>
        <CampaignCTAGroupButton
          key="actionButtons"
          deeplinks={deeplinksOptions}
          categoryOptions={categoryOptions}
          notificationActionTypes={ctaActions}
          withGoals={withGoals}
          notificationGoals={notificationGoals}
          side="front"
          onlyNotificationAction={false}
          eventsOptions={eventsOptions}
          onBlur={notification.actionButtons.validateLabels}
          onSelect={(value: string) => {
            notification.setButtonsCategory(value)
            if (isWithCardCampaign) {
              notification.actionButtons.buttons[0]?.setType(ActionTypes.CARD)
            }
            notificationGoals?.removeGoals('button', 'front')
          }}
          selected={notification.actionButtons.buttonCategory.value}
          buttons={notification.actionButtons.buttons}
          onDestinationTypeChange={onDestinationTypeChange}
          firstOnlyLabel={isWithCardCampaign}
          withEvent={withEvent}
        />
      </CollapseWithToggle>
    </>
  )
}

export default withStore(InAppLarge)
