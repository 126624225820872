import React, { useState, useEffect } from 'react'
import { Card, Container } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import BeaconsStatus from './components/BeaconsStatus'
import BeaconsActions from './components/BeaconsActions'
import TableNew from '~/components/Table/TableNew'
import EditAddBeaconModal from '~/pages/Beacons/components/EditAddBeaconModal'
import Toolbar from './components/BeaconsTable/BeaconsTableToolbar'
import useQueryParams from '~/hooks/useQueryParams'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import beaconsTableColumns from '~/pages/Beacons/components/BeaconsTable/beaconsTableColumns'
import AddElementsToGroupModal from '~/components/Table/components/AddElementsToGroup'
import RemoveElementsFromGroupModal from '~/components/Table/components/RemoveElementsFromGroup'
import DeleteElementsModal from '~/components/modals/DeleteElementsModal'
import Modal from '~/components/modals/Modal/Modal'
import { IBeacon, BEACONS_QUERY_PARAMS, ERRORS_DICT } from './Beacons.interface'
import CreateGroupModal from '~/components/Table/components/CreateGroupModal'
import DeleteGroup from '~/components/Table/components/DeleteGroup'
import BatchUpload from '~/components/BatchUpload'
import EditGroupModal from '~/components/Table/components/EditGroupModal'
import { useStore, withStore } from '~/dataStore'
import beaconsUploadHelp from './components/beacons-upload-help.svg'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import useBeacons, { useInvalidateBeacons } from './useBeacons'
import useSelectListItems from '~/hooks/useSelectListItems'
import { getGroups } from '~/api/groups'
import { exportBeacons } from '~/api/beacons'

import './Beacons.scss'

const Beacons = () => {
  const {
    app: {
      appDetails: { featureFlags },
      currentApp: { id: appId }
    },
    ui: { showModal },
    groups: {
      groups,
      addElementsToGroup,
      removeElementsFromGroup,
      editGroup,
      removeGroup,
      createGroup,
      setGroups,
      isPerforming
    }
  } = useStore()

  const history = useHistory()
  const paginationQuery = usePaginationQuery({ perPage: 50 })
  const queryParams = useQueryParams(BEACONS_QUERY_PARAMS)
  const {
    data: beacons,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    isRefetching,
    isLoading
  } = useBeacons(
    appId,
    {
      ...queryParams,
      ...paginationQuery.getPaginationQuery()
    },
    featureFlags.beacons
  )

  const invalidateBeacons = useInvalidateBeacons()

  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>()

  const bulkActions = beacons?.bulkActions

  const {
    selectListItems,
    selectedListItems,
    clearSelectedItems
  } = useSelectListItems()

  const showRemoveBeaconsModal = () => {
    showModal('deleteElementsModal', {
      elements: Array.from(selectedListItems, ([, value]) => value),
      url: bulkActions?.delete,
      onSuccess: () => {
        invalidateBeacons()
        clearSelectedItems()
      }
    })
  }

  const showRemoveBeaconModal = (beacon: IBeacon) => {
    showModal('deleteElementsModal', {
      elements: [beacon],
      url: beacon?.actions.delete,
      onSuccess: () => {
        invalidateBeacons()
        clearSelectedItems()
      }
    })
  }

  useEffect(() => {
    if (!featureFlags.beacons) {
      showNotification(
        'Sorry, but Beacons are not enabled on your app. Please contact support@pulsatehq.com for more information.',
        NotificationType.INFO
      )
      history.push('/')
    }
  }, [featureFlags.beacons])

  useEffect(() => {
    getGroups(appId, 'beacons').then((response) => setGroups(response.data))

    return () => {
      setGroups([])
    }
  }, [appId])

  return (
    <Container fluid>
      <div className="beacons">
        <Header className="mb-3">
          <HeaderTitle>
            Beacons{' '}
            <span className="text-muted">({beacons?.metadata?.dataCount})</span>
          </HeaderTitle>
        </Header>
        <div className="d-flex justify-content-between">
          <BeaconsStatus
            isLoading={isLoading}
            statuses={beacons?.metadata?.beaconsStatus}
          />
          <BeaconsActions
            exportBeacons={({ from, to }) => exportBeacons(appId, from, to)}
            addBeaconModal={() =>
              showModal('batchUploadModal', { beacon: null })
            }
          />
        </div>
        <Card className="beacons__table-wrapper mt-4 d-flex flex-column">
          <Toolbar
            handleSearch={paginationQuery.handleSearch}
            searchQuery={paginationQuery.searchQuery}
            queryParams={queryParams}
            isRefetching={isRefetching}
            selectedBeacons={selectedListItems}
            onDeleteRows={showRemoveBeaconsModal}
            setSelectedGroup={setSelectedGroup}
          />
          <div className="p-3">
            <TableNew
              columns={beaconsTableColumns({
                onEditRow: (beacon: IBeacon) =>
                  showModal('editAddBeaconModal', { beacon })
              })}
              rows={beacons?.pages || []}
              sortOrder={paginationQuery.order}
              sortColumn={paginationQuery.sortField}
              onSort={paginationQuery.handleSort}
              onDeleteRow={showRemoveBeaconModal}
              selectedRows={selectedListItems}
              hasMore={hasNextPage}
              onLoadMore={() => fetchNextPage()}
              onUpdateRow={invalidateBeacons}
              onSelect={selectListItems}
              isLoading={isLoading}
              isLoadingMore={isFetchingNextPage}
            />
          </div>
        </Card>
      </div>

      <AddElementsToGroupModal
        groups={groups}
        onSubmit={(groupId: string) =>
          addElementsToGroup({
            url: bulkActions.addToGroup,
            groupId,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateBeacons()
              clearSelectedItems()
            }
          })
        }
      />

      <RemoveElementsFromGroupModal
        onSubmit={(selectedGroups) => {
          if (!bulkActions) return
          removeElementsFromGroup({
            selectedGroups,
            url: bulkActions?.removeFromGroup,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateBeacons()
              clearSelectedItems()
            }
          })
        }}
        nameKey="nickname"
        elements={Array.from(selectedListItems, ([, value]) => value)}
        elementsName="Beacons"
        isSaving={isPerforming}
      />

      <Modal
        id="editAddBeaconModal"
        size="lg"
        className="beacon-modal"
        renderHeader={(closeModal, params?: { beacon: IBeacon }) =>
          params?.beacon ? 'Edit beacon' : 'Add your 3rd Party Beacons'
        }
        render={(close: () => void, params?: { beacon: IBeacon }) => (
          <EditAddBeaconModal beacon={params?.beacon} closeModal={close} />
        )}
      />

      <EditGroupModal
        group={selectedGroup}
        isSaving={isPerforming}
        onSubmit={editGroup}
      />
      <DeleteGroup
        onSubmit={(groupId) => {
          removeGroup(groupId)
          invalidateBeacons()
          clearSelectedItems()
        }}
        queryParams={queryParams}
        group={selectedGroup}
        isSaving={isPerforming}
      />
      <CreateGroupModal
        groupType="beacons"
        onSubmit={createGroup}
        isSaving={isPerforming}
      />
      <DeleteElementsModal elementType="Beacon" nameKey="nickname" />

      <BatchUpload
        helpMedia={beaconsUploadHelp}
        onManualUpload={() => showModal('editAddBeaconModal')}
        resourceType="beacons"
        successCallback={refetch}
        labels={{
          header: 'Adding New Beacons',
          csv:
            'Upload a .CSV file in the format of: UUID, Major, Minor, Beacon ID, Nickname',
          manual: 'Input Name, Latitude, Longitude and Radius values manually'
        }}
        errorsDictionary={ERRORS_DICT}
      />
    </Container>
  )
}

export default withStore(Beacons)
