import {
  CampaignType,
  DeviceImageType,
  DeviceType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'

export function getDeviceImageType(
  device?: DeviceType,
  campaignType?: CampaignType,
  isSmallInApp?: boolean
): DeviceImageType | undefined {
  if (device === 'desktop' || campaignType === CampaignType.SMS) {
    return device
  }

  if (campaignType === CampaignType.PUSH) {
    switch (device) {
      case 'ios':
        return 'iosPush'
      case 'android':
        return 'androidPush'
      case 'watch':
        return 'watch'
      default:
        return 'iosPush'
    }
  }

  if (campaignType === CampaignType.CARD_FRONT) {
    switch (device) {
      case 'ios':
        return 'iosCardFront'
      case 'android':
        return 'androidCardFront'
      default:
        return 'iosCardFront'
    }
  }

  if (campaignType === CampaignType.CARD_BACK) {
    switch (device) {
      case 'ios':
        return 'iosCardBack'
      case 'android':
        return 'androidCardBack'
      default:
        return 'iosCardBack'
    }
  }

  if (isSmallInApp) {
    switch (device) {
      case 'ios':
        return 'iosAppBg'
      case 'android':
        return 'androidAppBg'
      default:
        return 'iosAppBg'
    }
  }

  if (!campaignType) {
    return 'ios'
  }

  return 'iosAppBg'
}

export function isDeviceSpecificCampaign(campaignType?: CampaignType): boolean {
  if (!campaignType) {
    return true
  }
  return [
    CampaignType.SMS,
    CampaignType.PUSH,
    CampaignType.CARD_FRONT,
    CampaignType.CARD_BACK,
    CampaignType.CARD
  ].includes(campaignType)
}

export function isNoGap(preview?: IPreviewParts): boolean {
  if (preview && 'size' in preview) {
    return preview.size === 'small'
  }

  return false
}

export function isWithOverlay(preview?: IPreviewParts): boolean {
  if (!preview || 'imageUrl' in preview || !preview.parts.length) {
    return false
  }

  if ('size' in preview) {
    return preview.size !== 'small'
  }

  return false
}
