import React, { useEffect, useState } from 'react'
import { Card, Container, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { withStore, useStore } from '~/dataStore'
import useQueryParams from '~/hooks/useQueryParams'
import TableNew from '~/components/Table/TableNew'
import { getGroups } from '~/api/groups'
import useSegmentsList, { useInvalidateSegments } from './useSegmentsList'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import useSelectListItems from '~/hooks/useSelectListItems'
import segmentsTableColumns from './components/segmentsTableColumns'
import SegmentsListToolbar from './components/SegmentsToolbar'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import CreateSegmentFromCSVModal from '../CreateSegmentFromCSVModal'
import Link from '~/components/Link'
import SegmentCSVErrorsModal from '../CreateSegmentFromCSVModal/components/CSVErrorsModal'
import {
  ISegmentListItem,
  QUERY_PARAMS_DEFAULTS
} from './SegmentsList.interface'
// modals
import AddElementsToGroupModal from '~/components/Table/components/AddElementsToGroup'
import RemoveElementsFromGroupModal from '~/components/Table/components/RemoveElementsFromGroup'
import DeleteElementsModal from '~/components/modals/DeleteElementsModal'
import EditGroupModal from '~/components/Table/components/EditGroupModal'
import CreateGroupModal from '~/components/Table/components/CreateGroupModal'
import DeleteGroup from '~/components/Table/components/DeleteGroup'
import routes from '~/routes'
import SalesForceIntegrationBar from './components/SalesForceIntegrationBar'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'

const SegmentsList = () => {
  const { selectListItems, selectedListItems, clearSelectedItems } =
    useSelectListItems<ISegmentListItem>()
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>()
  const {
    ui: { showModal },
    groups: {
      setGroups,
      groups,
      createGroup,
      isPerforming,
      editGroup,
      removeGroup,
      removeElementsFromGroup,
      addElementsToGroup
    },
    app: { currentApp }
  } = useStore()
  const appId = currentApp.id
  const queryParams = useQueryParams(QUERY_PARAMS_DEFAULTS)
  const paginationQuery = usePaginationQuery({ perPage: 50 })
  const {
    data: segments,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    isLoading
  } = useSegmentsList(appId, {
    ...queryParams,
    ...paginationQuery.getPaginationQuery()
  })

  const invalidateSegments = useInvalidateSegments()
  const bulkActions = segments?.bulkActions

  useEffect(() => {
    getGroups(appId, 'segments').then((response) => setGroups(response.data))

    return () => {
      setGroups([])
    }
  }, [appId])

  return (
    <>
      <SalesForceIntegrationBar />
      <Container fluid>
        <Header
          className="d-flex justify-content-between align-items-end mt-0 pt-3 mb-4"
          aria-label="Segments List Header">
          <HeaderTitle className="mb-0" ariaLabel="Segments List Title">
            <>
              Segments{' '}
              <span className="text-muted">
                ({segments?.metadata.dataCount})
              </span>
            </>
          </HeaderTitle>
          <div>
            <Button
              size="lg"
              color=""
              onClick={() => showModal('createSegmentFromCSVModal')}
              className="bg-white px-4 btn--hover btn-outline-body me-3"
              aria-label="Segment from CSV Button">
              Segment from .CSV
            </Button>
            <Button
              tag={Link}
              route={routes.segmentsBuilder}
              size="lg"
              color="primary"
              className="btn--wide btn--hover"
              aria-label="New Segment Button">
              <FontAwesomeIcon size="xs" icon={faPlus} className="me-2" /> New
              Segment
            </Button>
          </div>
        </Header>
        <Card className="px-2 pt-2 pb-5" aria-label="Segments List Card">
          <SegmentsListToolbar
            queryParams={queryParams}
            handleSearch={paginationQuery.handleSearch}
            searchQuery={paginationQuery.searchQuery}
            clearSelectedSegments={clearSelectedItems}
            selectedSegments={selectedListItems}
            batchActionUrls={bulkActions}
            setSelectedGroup={setSelectedGroup}
            isLoading={isRefetching}
            aria-label="Segments List Toolbar"
          />
          <TableNew
            onUpdateRow={invalidateSegments}
            isLoadingMore={isFetchingNextPage}
            isLoading={isLoading}
            columns={segmentsTableColumns()}
            selectedRows={selectedListItems}
            onSelect={selectListItems}
            hasMore={hasNextPage}
            onLoadMore={() => fetchNextPage()}
            rows={segments?.pages || []}
            sortOrder={paginationQuery.order}
            sortColumn={paginationQuery.sortField}
            onSort={paginationQuery.handleSort}
          />
        </Card>
      </Container>
      <DeleteElementsModal
        nameKey="name"
        elementType="Segment"
        aria-label="Delete Elements Modal"
      />
      <EditGroupModal
        group={selectedGroup}
        isSaving={isPerforming}
        onSubmit={editGroup}
        aria-label="Edit Group Modal"
      />
      <CreateGroupModal
        groupType="segments"
        onSubmit={createGroup}
        isSaving={isPerforming}
        aria-label="Create Group Modal"
      />
      <DeleteGroup
        onSubmit={(groupId) => {
          removeGroup(groupId)
          invalidateSegments()
          clearSelectedItems()
        }}
        queryParams={queryParams}
        group={selectedGroup}
        isSaving={isPerforming}
        aria-label="Delete Group Modal"
      />

      <AddElementsToGroupModal
        groups={groups}
        onSubmit={(groupId: string) =>
          addElementsToGroup({
            url: bulkActions.addToGroup,
            groupId,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateSegments()
              clearSelectedItems()
            }
          })
        }
        aria-label="Add Elements to Group Modal"
      />

      <RemoveElementsFromGroupModal
        onSubmit={(selectedGroups) => {
          if (!bulkActions) return
          removeElementsFromGroup({
            selectedGroups,
            url: bulkActions?.removeFromGroup,
            resourceIds: [...selectedListItems.keys()],
            elements: [],
            successCallback: () => {
              invalidateSegments()
              clearSelectedItems()
            }
          })
        }}
        nameKey="name"
        elements={Array.from(selectedListItems, ([, value]) => value)}
        elementsName="Segments"
        isSaving={isPerforming}
        aria-label="Remove Elements from Group Modal"
      />

      <SegmentCSVErrorsModal aria-label="Segment CSV Errors Modal" />
      <CreateSegmentFromCSVModal aria-label="Create Segment from CSV Modal" />
    </>
  )
}

export default withStore(SegmentsList)
