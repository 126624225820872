import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import pluralize from 'pluralize'
import {
  ICampaignListItemDTO,
  ICampaignsListItem,
  ICampaignsListQueryParams
} from '~/pages/Campaign/CampaignsList/CampaignsList.interface'
import { ID } from '~/common.interface'
import { fetchCampaigns, pauseCampaign, resumeCampaign } from '~/api/campaigns'
import { NotificationType, showNotification } from '~/utils/Notification'
import { useStore } from '~/dataStore'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { putRequest } from '~/api/requests'

export enum ServerStateKeysEnum {
  Campaigns = 'campaigns'
}

export const useInvalidateCampaigns = () => {
  const cache = useQueryClient()
  return () => cache.invalidateQueries([ServerStateKeysEnum.Campaigns])
}

export const useHideCampaignsFromFeed = () => {
  const cache = useQueryClient()
  const url = cache.getQueryState({ queryKey: ServerStateKeysEnum.Campaigns })
    ?.data?.pages?.[0].bulkActions?.hideFromFeed
  return useMutation(
    (data: { ids: ID[]; onSuccess: () => void }) =>
      putRequest(url, {
        resourceIds: data.ids
      }),
    {
      onSuccess: (_, data: { ids: ID[]; onSuccess: () => void }) => {
        data.onSuccess()
        showNotification(
          `${pluralize('Campaign', data.ids.length)} hid`,
          NotificationType.SUCCESS
        )
        cache.invalidateQueries([ServerStateKeysEnum.Campaigns])
      },
      onError: (_, data: { ids: ID[]; onSuccess: () => void }) => {
        showNotification(
          `Couldn't hide ${pluralize(
            'campaign',
            data.ids.length
          )}, please try again later.`,
          NotificationType.ERROR
        )
      }
    }
  )
}

export const useResumeCampaign = (appId: ID) => {
  const cache = useQueryClient()
  return useMutation((data: { id: ID }) => resumeCampaign(appId, data.id), {
    onSuccess: () => {
      cache.invalidateQueries([ServerStateKeysEnum.Campaigns])
    },
    onError: () => {
      showNotification(
        "Couldn't change campaign status, please try again later.",
        NotificationType.ERROR
      )
    }
  })
}

export const usePauseCampaign = (appId: ID) => {
  const cache = useQueryClient()
  return useMutation((data: { id: ID }) => pauseCampaign(appId, data.id), {
    onSuccess: () => {
      cache.invalidateQueries([ServerStateKeysEnum.Campaigns])
    },
    onError: () => {
      showNotification(
        "Couldn't change campaign status, please try again later.",
        NotificationType.ERROR
      )
    }
  })
}

const useCampaigns = (
  queryParams: Partial<Omit<ICampaignsListQueryParams, 'page' | 'per_page'>>
) => {
  const {
    app: {
      appDetails,
      currentApp: { id: appId }
    }
  } = useStore()
  const { mutateAsync: pause } = usePauseCampaign(appId)
  const { mutateAsync: resume } = useResumeCampaign(appId)
  return useInfiniteQuery<
    { data: ICampaignListItemDTO[]; bulkActions: any; metadata: any },
    unknown,
    ICampaignsListItem
  >(
    [
      ServerStateKeysEnum.Campaigns,
      {
        ...queryParams
      }
    ],
    ({ pageParam = 1 }) =>
      fetchCampaigns(appId, {
        ...queryParams,
        page: pageParam
      }),
    {
      select: (data) => ({
        pages: data.pages
          .flatMap((page) => page.data)
          .map((campaign) => ({
            ...campaign,
            batchActionUrls: data.pages[0].bulkActions,
            pause,
            resume,
            disabled:
              (campaign.typeList.includes(CampaignType.EMAIL) &&
                appDetails.featureFlags?.email === false) ||
              (campaign.typeList.includes(CampaignType.SMS) &&
                appDetails.featureFlags?.sms === false)
          })),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata,
        bulkActions: data.pages[0].bulkActions
      }),
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage, pages) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export default useCampaigns
