import { ApexOptions } from 'apexcharts'
import {
  ChartDataType,
  ChartType,
  ISeriesData
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import numberToStringRepresentation from '~/utils/number'

export function getSeriesNameByType(
  data: ISeriesData[],
  type: ChartDataType
): string[] {
  return data
    .filter((series) => series.dataType === type)
    .map((elem) => elem.name)
}
export function getFirstActiveSeries(
  data: { name: string; active: boolean }[],
  filtered: string[]
): { name: string; active: boolean } | undefined {
  return data
    .filter((elem) => filtered.includes(elem.name))
    .find((elem) => elem.active)
}

export function getYAxisNumericConfig(series: ISeriesData[]): ApexYAxis[] {
  const numeric = getSeriesNameByType(
    series.filter((s) => s.active),
    ChartDataType.NUMERIC
  )
  const firstNumericActive = getFirstActiveSeries(series, numeric)

  return numeric.map((s) => ({
    seriesName: numeric[0],
    show: firstNumericActive?.name === s,
    opposite: false,
    labels: { formatter: numberToStringRepresentation }
  }))
}

export function getYAxisPercentageConfig(series: ISeriesData[]): ApexYAxis[] {
  const percentage = getSeriesNameByType(
    series.filter((s) => s.active),
    ChartDataType.PERCENTAGE
  )
  const firstPercentageActive = getFirstActiveSeries(series, percentage)

  return percentage.map((s) => ({
    seriesName: percentage[0],
    show: firstPercentageActive?.name === s,
    opposite: true,
    labels: {
      formatter: (value) => {
        return `${value?.toFixed(2) || 0}%`
      }
    }
  }))
}

export function getXAxisConfig({
  xAxisType,
  tickAmount,
  trim = true,
  hideOverlappingLabels = true
}: Partial<{
  xAxisType: 'datetime' | 'category'
  tickAmount: number
  trim: boolean
  hideOverlappingLabels: boolean
}>): Pick<ApexOptions, 'xaxis'> {
  return {
    xaxis: {
      type: xAxisType || 'datetime',
      tickAmount,
      labels: {
        trim,
        rotate: 0,
        hideOverlappingLabels,
        rotateAlways: false
      }
    }
  }
}

export function getFillConfig(
  series: ISeriesData[],
  includeOverviewColumn?: boolean,
  chartType?: ChartType
): Pick<ApexOptions, 'fill'> {
  return {
    fill: {
      opacity: 1,
      ...(chartType === 'bar' && {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: series.map((serie: ISeriesData) => [
            {
              offset: 0,
              color: serie.color?.start
            },
            {
              offset: 100,
              color: serie.color?.end
            }
          ])
        }
      })
    }
  }
}
