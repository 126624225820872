import { ReactElement } from 'react'
import cn from 'classnames'
import ColorPicker from '~/components/ColorPicker'
import { BACKGROUND_COLOR_SWATCH, TEXT_COLOR_SWATCH } from '~/constants/common'
import styles from '~/pages/CampaignBuilder/CTA/CampaignCTAButton/CampaignCTAButton.scss'
import textColorPicker from '~/components/icons/textColorPicker.svg'
import backgroundColorPicker from '~/components/icons/backgroundColorPicker.svg'
import CTAField from '~/dataStore/emailBuilder/Fields/CTA.field'

interface IProps {
  button: CTAField
  defaultTxtColor: string
  defaultBtnColor: string
}

const CTAButtonColorPicker = ({
  button,
  defaultTxtColor,
  defaultBtnColor
}: IProps): ReactElement => (
  <div className="position-relative d-flex align-items-center m-n2">
    <ColorPicker
      title="Text color"
      colors={TEXT_COLOR_SWATCH}
      currentColor={button.txtColor.value || defaultTxtColor}
      toggleClassName="px-0"
      onChange={(color) => button.setTextColor(color.hex)}>
      <span color="" className={cn('me-n1', styles.colorPicker)}>
        <img src={textColorPicker} alt={button.txtColor.value} />
      </span>
    </ColorPicker>
    <ColorPicker
      title="Background Color"
      currentColor={button.btnColor.value || defaultBtnColor}
      colors={BACKGROUND_COLOR_SWATCH}
      toggleClassName="px-0"
      onChange={(color) => button.setBtnColor(color.hex)}>
      <span color="" className={cn('ms-n1', styles.colorPicker)}>
        <img src={backgroundColorPicker} alt={button.txtColor.value} />
      </span>
    </ColorPicker>
  </div>
)

export default CTAButtonColorPicker
