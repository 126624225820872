import React from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'
import { IApplication } from '../AppManager.interface'
import IconButton from '~/components/IconButton'
import { useStore } from '~/dataStore'

const DeleteAppModal = ({ application }: { application: IApplication }) => {
  const {
    ui: { showModal }
  } = useStore()

  return (
    <>
      <IconButton
        onClick={() =>
          showModal('confirmDeleteAppModal', {
            application
          })
        }
        icon={faTrash}
        id={`delete-${application.id}`}
        ariaLabel={`Delete application ${application.name}`}
      />
      <UncontrolledTooltip
        placement="bottom"
        target={`delete-${application.id}`}>
        Delete
      </UncontrolledTooltip>
    </>
  )
}

export default DeleteAppModal
