import React from 'react'
import pluralize from 'pluralize'
import { putRequest } from '~/api/requests'
import { NotificationType, showNotification } from '~/utils/Notification'
import Modal from '~/components/modals/Modal/Modal'
import ConfirmationListModal from '~/components/modals/ConfirmationListModal'
import { ID } from '~/common.interface'

type ElementType = 'Journey' | 'Campaign'

interface IElementsModalProps {
  elements: Array<{
    id: string
    name: string
  }>
  url: string
  action: (resourceId: ID) => Promise<unknown>
  onSuccess: () => void
  nameKey?: string
  elementType: ElementType
  onClose: () => void
}

const ResumeElements = ({
  url,
  action,
  elements,
  onSuccess,
  onClose,
  elementType,
  nameKey = 'name'
}: IElementsModalProps) => {
  const handleSave = async () => {
    const ids = elements.map((c) => c.id)
    try {
      if (ids.length === 1) {
        await action(ids[0])
      } else {
        await putRequest(url, { resourceIds: ids })
      }
      onSuccess()
      onClose()
      showNotification(
        `${pluralize(elementType, elements.length)} resumed`,
        NotificationType.SUCCESS
      )
    } catch (error) {
      showNotification(
        `Failed to resume ${pluralize(elementType, elements.length)}`,
        NotificationType.ERROR
      )
    }
  }

  if (!elements) return null

  return (
    <ConfirmationListModal
      actionType="resume"
      elementType="Journey"
      elements={elements}
      onClose={onClose}
      onSubmit={handleSave}
      nameKey={nameKey}
    />
  )
}

export default function ResumeElementsModal({
  elementType
}: {
  elementType: ElementType
}): React.ReactElement {
  return (
    <Modal
      id="resumeElementsModal"
      size="md"
      renderHeader={(_, params) =>
        `Resume ${pluralize(elementType, params?.elements?.length || 1)}`
      }
      render={(close: () => void, params) => {
        return (
          <ResumeElements
            onClose={close}
            elementType={elementType}
            {...params}
          />
        )
      }}
    />
  )
}
