import React, { PropsWithChildren, ReactElement } from 'react'
import cn from 'classnames'
import Link from '~/components/Link'
import styles from '../NoticeBar.scss'

const NoticeBarButton = ({
  children,
  url
}: PropsWithChildren<{ url: string }>): ReactElement => {
  return (
    <Link
      target="_blank"
      className={cn('no-decoration text-nowrap px-5 py-3 btn', styles.btn)}
      route={url}>
      {children}
    </Link>
  )
}

export default NoticeBarButton
