import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import Checkbox from '~/components/forms/Checkbox'
import SearchInput from '~/components/forms/SearchInput'
import useInfiniteScroll from '~/hooks/useInfiniteScroll'
import {
  IHandleFilterProps,
  PER_PAGE_COUNT
} from '../CampaignsAndGroupsMultiSelect.interface'
import { handleLongName } from '~/utils/string'

interface IProps {
  campaigns?: Array<{ id: string; name: string }>
  selectedCampaignIds?: Set<string>
  handleFilter: ({ checked, value, reset }: IHandleFilterProps) => void
}

const CampaignsTab = ({
  campaigns,
  selectedCampaignIds,
  handleFilter
}: IProps): React.ReactElement => {
  const [searchQuery, setSearchQuery] = useState('')

  const [campaignsPaged, setCampaignsPaged] = useState(
    campaigns?.slice(0, PER_PAGE_COUNT)
  )

  const [perPageCount, setPerPageCount] = useState(PER_PAGE_COUNT)

  const bottomRef = useInfiniteScroll({
    hasMore: campaigns ? perPageCount <= campaigns?.length : false,
    onLoadMore: () => {
      setPerPageCount((prev) => prev + PER_PAGE_COUNT)
    }
  })

  useEffect(() => {
    setCampaignsPaged(
      searchQuery
        ? campaigns?.filter((campaign: { id: string; name: string }) =>
            campaign.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : campaigns?.slice(0, perPageCount)
    )
  }, [perPageCount, searchQuery, campaigns])

  return (
    <div className="campaigns-groups-multi-select__list">
      <SearchInput
        placeholder="Search"
        initialValue={searchQuery}
        onChange={setSearchQuery}
        className="me-3 flex-0-1 bg-white"
      />
      <Button
        color=""
        className="mt-3 mb-2 p-0"
        onClick={() => handleFilter({ reset: true })}>
        Show all
      </Button>
      {campaigns
        ?.filter((campaign) => selectedCampaignIds?.has(campaign.id))
        .map((campaign: { id: string; name: string }) => (
          <Checkbox
            key={campaign.id}
            checked
            data-testid={`resource-selected-campaign-${campaign.id}`}
            wrapperClass="mt-2"
            onChange={(checked) => {
              handleFilter({ checked, value: campaign.id })
            }}
            label={campaign.name}
          />
        ))}
      {selectedCampaignIds && selectedCampaignIds.size > 0 && (
        <div className="campaigns-groups-multi-select__selected-separator" />
      )}
      {campaignsPaged
        ?.filter((campaign) => !selectedCampaignIds?.has(campaign.id))
        .map((campaign: { id: string; name: string }) => (
          <Checkbox
            key={campaign.id}
            wrapperClass="mt-2"
            data-testid={`resource-campaign-${campaign.id}`}
            onChange={(checked) => {
              handleFilter({ checked, value: campaign.id })
            }}
            label={handleLongName(campaign.name, 30)}
          />
        ))}
      <div ref={bottomRef} />
    </div>
  )
}

export default CampaignsTab
