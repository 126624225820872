import React from 'react'
import { showNotification, NotificationType } from '~/utils/Notification'

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const URL_REGEX = new RegExp(
  '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*', // port and path
  'i'
) // fragment locator

// 1 uppercase, 1 lowercase, 1 number, 1 special character, 8 characters
export const PASSWORD_REGEX = new RegExp(
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
)

export const MEDIA_URL_REGEX = /\.(png|jpg|jpeg|mp3|mp4|gif)$/i

export function isValidEmail(email: string): boolean {
  return EMAIL_REGEX.test(email)
}

export function isValidURL(url: string): boolean {
  return URL_REGEX.test(url)
}

export function isValidMediaURL(url: string): boolean {
  return MEDIA_URL_REGEX.test(url)
}

export function validateCharacters(
  invalidChars: string[]
): (value: string) => boolean {
  return (value: string) => {
    return invalidChars.some((char) => value.includes(char))
  }
}

export function getFormServerSideErrors(
  errors: { [x: string]: string[] },
  errorsDictionary: Record<string, Record<string, string>> = {}
): Array<{ name: string; message: React.ReactElement[] }> {
  return Object.keys(errors).map((error: string) => ({
    name: error,
    message: errors[error].map((errorMessage: string, index: number) => (
      <p
        key={errorMessage}
        className={errors[error].length > 1 ? 'mb-1' : 'mb-0'}
        aria-label={`Error: ${errorMessage}`}
        data-testid={`error-${error}-${index}`}>
        {errors[error].length > 1 && '-'}{' '}
        {errorsDictionary[error] && errorsDictionary[error][errorMessage]
          ? errorsDictionary[error][errorMessage]
          : errorMessage}
      </p>
    ))
  }))
}

export const hasInvalidBuilderCharacters = validateCharacters([
  '<',
  '>',
  '[',
  ']'
])

export const hookFormValidation = {
  password: {
    required: {
      value: true,
      message: 'Password is missing'
    },
    pattern: {
      value: PASSWORD_REGEX,
      message:
        'Password must be at least 8 characters long with 1 uppercase, 1 lowercase, 1 digit and 1 special character'
    }
  },
  passwordConfirmation: {
    required: {
      value: true,
      message: 'Password Confirmation is missing'
    }
  },
  email: {
    required: {
      value: true,
      message: 'Email is missing'
    },
    pattern: {
      value: EMAIL_REGEX,
      message: 'Email format is incorrect (ex: john@xyz.com)'
    }
  },
  minZero: {
    min: {
      value: 0,
      message: 'Value must be greater than or equal to 0'
    }
  },
  minOne: {
    min: {
      value: 1,
      message: 'Must be greater than 0'
    }
  }
}

export const showGeneralError = (message?: string) =>
  showNotification(
    message || 'Something went wrong, please try again later.',
    NotificationType.ERROR
  )
