import { makeObservable, override } from 'mobx'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import {
  IBlock,
  IBlockAddToSegment,
  JourneyBlockType
} from '../Store/JourneyBuilder.interface'
import Block from './Block.model'

type AddToSegmentDTO = Pick<IBlockAddToSegment, 'name' | 'path'>

export default class AddToSegment extends Block<AddToSegmentDTO> {
  public name = new NewRegisteredField<string>('')

  constructor(public parent: IBlock) {
    super(JourneyBlockType.ADDTOSEGMENT, parent)
    makeObservable<AddToSegment, 'resetError'>(this, {
      resetError: override
    })

    this.setName = this.setName.bind(this)
  }

  get filled(): boolean {
    return !!this.name.value
  }

  public getBlockPayload(): AddToSegmentDTO {
    return {
      name: this.name.value
    }
  }

  protected fillBlockPayload(data: AddToSegmentDTO): void {
    this.name.setValue(data.name || '')

    this.setReady()
  }

  public validateBlock(): boolean {
    this.validateName()

    if (!this.isValid) {
      this.launchErrors = ['You must set segment name']
    }
    return this.isValid
  }

  public validateName(): void {
    if (!this.name.validate()) {
      this.isValid = false
      this.errors = ['Enter Segment Name']
    }
  }

  public resetError(): void {
    super.resetError()
    this.name.resetError()
  }

  public setName(value: string): void {
    this.name.setValue(value)

    if (value) {
      this.resetError()
    }
  }

  protected getNodeData(): { label: string } {
    return { label: this.name.value || 'Segment Name' }
  }
}
