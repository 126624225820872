import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { X } from 'react-feather'
import Input from '~/components/forms/Input/Input'
import { isValidMediaURL, isValidURL } from '~/utils/validations'
import './RichMedia.style.scss'

interface IProps {
  hide: () => void
  onURLChange: (url: string) => void
}

export default function ExternalMedia({
  hide,
  onURLChange
}: IProps): React.ReactElement {
  const [error, setError] = useState<string | null>(null)

  function onMediaURLChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target
    setError(null)
    if (!isValidURL(value) || !isValidMediaURL(value)) {
      setError(
        'URL must start with http:// or https:// and end with valid extension: png, gif, jpg, jpeg, mp3, mp4'
      )
      return
    }

    onURLChange(value)
  }

  return (
    <div className="my-4">
      <div className="d-flex mb-1">
        <Input
          className="flex-fill mb-0 me-2"
          type="text"
          placeholder="Enter an URL with the file"
          onChange={onMediaURLChange}
          invalid={!!error}
        />
        <Button
          color=""
          className="p-0"
          onClick={() => {
            setError(null)
            hide()
          }}>
          <X className="cursor-pointer p-0 remove-icon" />
        </Button>
      </div>
      {error && <div className="text-13 error-label">{error}</div>}
    </div>
  )
}
