import React from 'react'
import { Container } from 'reactstrap'

import Skeleton from 'react-loading-skeleton'
import ReviewTopBar from './ReviewTopBar'
import Section from './Section'
import SetUp from './SetUp'
import Template from './Template'
import Target from './Target'
import Deliver from './Deliver'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'

import './campaignReview.scss'
import { ICampaignReview } from './CampaignReview.interface'
import { useStore, withStore } from '~/dataStore'
import AllMobilePreviews from '../Notification/Preview/AllMobilePreviews'

function CampaignReview({
  campaignType,
  campaignSubType,
  displayType,
  mode,
  targeting,
  setUp,
  isDetailsInPast,
  template,
  preview,
  deliver,
  withEdit,
  steps,
  loading
}: ICampaignReview): React.ReactElement {
  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      }
    }
  } = useStore()

  function getStepNumberByName(name: string): number {
    return steps?.find((step) => step.title === name)?.number || 1
  }

  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center my-6 review-container">
      <Header className="mb-3">
        <HeaderTitle className="review-container__header">
          {mode === 'builder' && 'You are about to send this'}
          {!loading ? (
            <strong> {displayType} </strong>
          ) : (
            <Skeleton width={70} className="d-inline-block mx-1" />
          )}
          Campaign
        </HeaderTitle>
      </Header>

      <ReviewTopBar
        mode={mode}
        name={setUp.name.value}
        targeting={targeting}
        displayType={displayType}
        isDetailsInPast={isDetailsInPast}
        deliver={deliver}
        campaignType={campaignType}
        campaignSubType={campaignSubType}
      />

      {campaignsGoals && (
        <Section
          title="Set up"
          stepNumber={getStepNumberByName('Set up')}
          withEdit={withEdit}>
          <SetUp setUp={setUp} />
        </Section>
      )}

      {campaignType && (
        <>
          {template && (
            <Section
              title="Template"
              subTitle={`This is how user ${
                isDetailsInPast ? 'was' : 'will be'
              } notified`}
              stepNumber={getStepNumberByName('Template')}
              withEdit={withEdit}>
              <Template {...template} loading={loading} />
            </Section>
          )}

          {preview && (
            <Section
              title="Notification"
              subTitle={`This is how user ${
                isDetailsInPast ? 'was' : 'will be'
              } notified`}
              // TODO convert step name to number
              stepNumber={2}
              withEdit={withEdit}>
              <AllMobilePreviews preview={preview} />
            </Section>
          )}
        </>
      )}

      <Section
        withEdit={withEdit}
        title="Target"
        subTitle={`This Campaign ${
          isDetailsInPast ? 'was' : 'will be'
        } sent to the following targeting criteria`}
        stepNumber={getStepNumberByName('Target')}>
        <Target targeting={targeting} mode={mode} />
      </Section>
      <Section
        withEdit={withEdit}
        title="Deliver"
        subTitle={`This Campaign ${
          isDetailsInPast ? 'was' : 'will be'
        } sent under the following conditions`}
        stepNumber={getStepNumberByName('Deliver')}>
        <Deliver
          type="Campaign"
          deliver={{
            timeZoneName: deliver.timeZoneName.value,
            startAt: deliver.startAt.value,
            endAt: deliver.endAt.value,
            startNow: !!deliver.startNow.value,
            timeWindows: deliver.timeWindows.value,
            deliverUi: {
              isDeliveryFutureOrDefault:
                deliver.deliverUi.isDeliveryFutureOrDefault,
              isTimeWindows: deliver.deliverUi.isTimeWindows,
              isEndDate: deliver.deliverUi.isEndDate,
              isStartEndSectionCollapsed:
                deliver.deliverUi.isStartEndSectionCollapsed
            },
            expiryType: deliver.expiryType,
            expiryDate: deliver.expiryDate.value,
            expiryTimeValue: deliver.expiryTimeValue.value,
            expiryTimeFrame: deliver.expiryTimeFrame.value,
            limits: deliver.limits.value,
            timeFrame: deliver.timeFrame.value,
            timeValue: deliver.timeValue.value
          }}
          isDetailsInPast={isDetailsInPast}
        />
      </Section>
    </Container>
  )
}

export default withStore(CampaignReview)
