import { memo, ReactElement, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'reactstrap'
import cn from 'classnames'
import {
  DeviceType,
  IPreviewItem
} from '~/dataStore/Campaign/Campaign.interface'
import PreviewParts from '../Parts/PreviewParts'
import PreviewCard from '../UI/PreviewCard'

interface IProps {
  deviceType: DeviceType
  parts: IPreviewItem[]
  withTooltip?: boolean
}

function CardFrontPreview({
  deviceType,
  parts,
  withTooltip = false
}: IProps): ReactElement {
  const previewCard = useRef<HTMLDivElement | null>(null)
  const [overflown, setOverflown] = useState<boolean>(false)
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(true)

  useEffect(() => {
    if (previewCard.current && withTooltip) {
      const timeout = setTimeout(() => {
        const { clientHeight, scrollHeight } = previewCard.current!
        const isOverflown = scrollHeight > clientHeight
        setOverflown(isOverflown)
      }, 450)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [parts, withTooltip])

  return (
    <>
      {deviceType === 'ios' && (
        <svg
          className="card-front-arrow"
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.290114 0.675348C1.64311 -0.40681 4.8125 5.17523 10.7908 5.17469L3.88039 11.1976C3.88039 11.1976 -0.062512 5.7568 0.290114 0.675348Z"
            fill="white"
          />
        </svg>
      )}
      <PreviewCard
        side="front"
        ref={previewCard}
        emptyPreview={!parts.length}
        deviceType={deviceType}>
        <PreviewParts parts={parts} />
      </PreviewCard>
      {withTooltip && deviceType === 'android' && (
        <div
          className={cn('text-end', {
            'card-front-tooltip-overflown': overflown
          })}>
          <span id="card-front-tooltip-target" />
        </div>
      )}
      {withTooltip && deviceType === 'android' && (
        <Tooltip
          boundariesElement="window"
          className="card-front-tooltip"
          placement="right-end"
          isOpen={overflown && tooltipOpen}
          target="card-front-tooltip-target">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            type="button"
            className="btn tooltip-error__close icon icon--close"
            onClick={() => {
              setTooltipOpen(false)
            }}
          />
          <p className="text-start mt-3">
            <strong>
              This is the maximum recommended height of a card in a user's feed.
            </strong>
          </p>
          <p className="text-start">
            Exceeding this recommended card height may result in full card not
            being displayed in a user's feed.
          </p>
        </Tooltip>
      )}
    </>
  )
}

export default memo(CardFrontPreview)
