// TODO: Probably to remove, need to confirm. Was used for true reach
const targetingTooltips = {
  email: {
    true: [
      'This is the current number of people in your selected segments who have an email address and who didn’t unsubscribe.',
      'This number can increase over time as new users are added to the selected segments.'
    ],
    potential: [
      'This is the current number of people in your selected segments.',
      'This number can increase over time as new users are added to the selected segments.'
    ]
  },
  push: {
    true: [
      'This is the current number of people in your selected segments who didn’t unsubscribe.',
      'This number can increase over time as new users are added to the selected segments.'
    ],
    potential: [
      'This is the current number of people in your selected segments.',
      'This number can increase over time as new users are added to the selected segments.'
    ]
  },
  sms: {
    true: [
      'This is the current number of people in your selected segments who have a phone number and who didn’t unsubscribe.',
      'This number can increase over time as new users are added to the selected segments.'
    ],
    potential: [
      'This is the current number of people in your selected segments.',
      'This number can increase over time as new users are added to the selected segments.'
    ]
  },
  in_app: {
    potential: [
      'This is the current number of people in your selected segments.',
      'This number can increase over time as new users are added to the selected segments.'
    ]
  },
  card: {
    potential: [
      'This is the current number of people in your selected segments.',
      'This number can increase over time as new users are added to the selected segments.'
    ]
  }
}

export default targetingTooltips
