import { Card } from 'reactstrap'
import cn from 'classnames'
import { PropsWithChildren } from 'react'
import styles from './noticeBarSecondary.scss'

export default function NoticeBarSecondary({
  children
}: PropsWithChildren): React.ReactElement | null {
  return (
    <Card
      className={cn(
        styles.barSecondary,
        'px-4 px-xxxl-5 py-4 d-flex flex-row justify-content-between'
      )}>
      {children}
    </Card>
  )
}
