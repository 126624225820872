import { Collapse, FormGroup } from 'reactstrap'
import cn from 'classnames'
import Checkbox from '~/components/forms/Checkbox'
import CreatableMultiselect from '~/components/forms/CreatableMultiselect'
import { withStore } from '~/dataStore'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import CTAField from '~/dataStore/emailBuilder/Fields/CTA.field'

interface IProps {
  field: CTAField
  eventsOptions: IOption[]
}

function RecordEvent({ eventsOptions, field }: IProps): React.ReactElement {
  return (
    <div className="pt-2">
      <Collapse isOpen={!!field.destinationType.value}>
        <Checkbox
          label="Record an Event"
          onChange={(value: boolean) => {
            field.setInAppEventsActive(value)
            if (!value) {
              field.validateInappEvents()
            }
          }}
          checked={!!field.isInAppEventsActive}
        />
      </Collapse>
      <Collapse isOpen={field.isInAppEventsActive} className="mt-2">
        <FormGroup
          className={cn({ 'form-group--error': !field.inAppEvents.isValid })}>
          <CreatableMultiselect
            placeholder="Eg: clicked_btn_view_more "
            onChange={(value: any) => {
              field.setEvents(value)
              field.validateInappEvents()
            }}
            value={field.inAppEvents.value || []}
            options={eventsOptions}
          />
        </FormGroup>
      </Collapse>
    </div>
  )
}

export default withStore(RecordEvent)
