import React, { useState } from 'react'
import { Button, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import uniq from 'lodash/uniq'
import { startCase, camelCase } from 'lodash'
import Modal from '~/components/modals/Modal/Modal'
import SearchInput from '~/components/forms/SearchInput'
import styles from './MergeTagsModal.scss'
import Input from '~/components/forms/Input'

interface IMergeTag {
  id?: number
  name: string
  value: string
  default?: string
}

interface IProps {
  onClose: () => void
  onSelect: ({ name, value }: { name: string; value: string }) => void
  mergeTags: string[]
}

const MergeTags = ({ onClose, onSelect, mergeTags }: IProps) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selected, setSelected] = useState<number>()
  const [mergeTagsList, setMergeTagsList] = useState<IMergeTag[]>(
    uniq(
      mergeTags?.map((m: string, index) => ({
        id: index + 1,
        value: m,
        name: m.replace(/_/g, ' '),
        default: ''
      }))
    )
  )

  const setMergeTagDefault = (mergeTag: IMergeTag, defaultText: string) => {
    setMergeTagsList((prev) =>
      prev.map((m: IMergeTag) =>
        m.id === mergeTag.id ? { ...m, default: defaultText } : m
      )
    )
  }

  const pasteMergeTag = () => {
    const mergeTag = mergeTagsList.find((m) => m.id === selected)
    if (!mergeTag) return
    let formattedMergeTag = mergeTag.value
    if (mergeTag.default) {
      formattedMergeTag += ` | default: '${mergeTag.default}'`
    }
    onSelect({
      name: mergeTag.name,
      value: `{{${formattedMergeTag}}}`
    })
    onClose()
  }

  return (
    <>
      <ModalBody className="px-0 py-1">
        <SearchInput
          placeholder="Search for a Merge Tag"
          onChange={setSearchQuery}
        />
        <div
          className={cn('mt-3 px-2 mx-n2 pb-2', styles.mergeTagsListWrapper)}>
          {mergeTagsList
            .filter((mergeTag) =>
              mergeTag.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((mergeTag) => (
              <div
                key={mergeTag.id}
                className={cn(
                  'd-flex cursor-pointer justify-content-between mt-3 rounded w-100 btn-no-outline align-items-center',
                  {
                    [styles.selectedMergeTag]: selected === mergeTag.id,
                    [styles.mergeTagTile]: selected !== mergeTag.id
                  }
                )}
                onKeyDown={() => {
                  setSelected(mergeTag.id)
                }}
                onClick={() => {
                  setSelected(mergeTag.id)
                }}>
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <span
                    id={`merge-tag-name-${mergeTag.id}`}
                    className={cn(
                      'font-weight-semibold text-truncate',
                      styles.mergeTagNameWrapper
                    )}>
                    {mergeTag.name}
                  </span>
                  <UncontrolledTooltip
                    placement="top"
                    target={`merge-tag-name-${mergeTag.id}`}>
                    {mergeTag.name}
                  </UncontrolledTooltip>
                  <div className="d-flex">
                    {selected === mergeTag.id && !mergeTag.default && (
                      <div
                        className={cn(styles.alternativeTextControl, 'me-3')}>
                        <Input
                          id="alternative-text-control"
                          onBlur={(e) => {
                            setMergeTagDefault(mergeTag, e.target.value.trim())
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setMergeTagDefault(
                                mergeTag,
                                e.target.value.trim()
                              )
                            }
                          }}
                          type="text"
                          className={cn('mb-0')}
                          placeholder="Enter alternative word"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="alternative-text-control">
                          If {mergeTag.name} is unavailable, substitute with
                          word below
                        </UncontrolledTooltip>
                      </div>
                    )}
                    {mergeTag.default && (
                      <div className={cn('ps-3 me-2', styles.mergeTagDefault)}>
                        {mergeTag.default}
                        <Button
                          color=""
                          onClick={() => {
                            setMergeTagDefault(mergeTag, '')
                          }}>
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </div>
                    )}
                    <p
                      className={cn(
                        styles.mergeTagValue,
                        'py-2 px-3 bg-water-gray font-weight-medium rounded mb-0 text-truncate'
                      )}>
                      {`{{${mergeTag.value}}}`}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="" className="btn--hover" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!selected}
          color="primary"
          className="btn--hover"
          onClick={pasteMergeTag}>
          <span>Paste Tag</span>{' '}
        </Button>
      </ModalFooter>
    </>
  )
}

const ModalHeader = () => (
  <>
    Insert Personalized Merge Tags
    <FontAwesomeIcon
      icon={faInfoCircle}
      className="o-50 ms-2"
      id="info"
      size="xs"
    />
    <UncontrolledTooltip placement="top" target="info">
      Merge tags allows you to programmatically insert personal details about a
      user such as their first name, last name or any other piece of custom data
    </UncontrolledTooltip>
  </>
)

const MergeTagsModal = (): React.ReactElement => (
  <Modal
    id="mergeTagsModal"
    className="merge-tags-modal"
    renderHeader={ModalHeader}
    render={(close: () => void, params) => (
      <MergeTags
        onClose={close}
        onSelect={params?.onSelect}
        mergeTags={params?.mergeTags}
      />
    )}
  />
)

export default MergeTagsModal
