import { ReactElement } from 'react'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import AdminsSelect from './AdminsSelect'

interface IProps {
  onChange: (value: string) => void
  value: string | undefined
  invalid: boolean
  disabled?: boolean
  onBlur: () => void
  mergeTags: string[]
  admins: IAdmin[]
  onSelect: (admin: IAdmin) => void
  selected?: IAdmin
  max?: number
  placeholder?: string
}

export default function AdminHeaderWithMessage({
  onChange,
  value,
  invalid,
  disabled = false,
  onBlur,
  mergeTags,
  admins,
  onSelect,
  selected,
  max,
  placeholder = 'Add a personal message...'
}: IProps): ReactElement {
  return (
    <>
      <AdminsSelect
        admins={admins}
        disabled={disabled}
        onSelect={onSelect}
        selected={selected}
      />
      <Textarea
        onChange={onChange}
        value={value}
        invalid={invalid}
        required
        disabled={disabled}
        onBlur={onBlur}
        max={max}
        placeholder={placeholder}
        mergeTags={mergeTags}
      />
    </>
  )
}
