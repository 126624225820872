import cn from 'classnames'
import styles from '../JourneyTeaser.scss'
import AboutIcons from './AboutIcons'

interface IProps {
  header: string
  subheader: string
  description: string
  iconSet: 'channel' | 'message' | 'stats'
}

export default function AboutCardItem(props: IProps): React.ReactElement {
  const { header, subheader, description, iconSet } = props
  return (
    <section className={cn(styles.aboutCardsItem, 'h-100')}>
      <AboutIcons iconSet={iconSet} />
      <div className={cn(styles.aboutCardsItemContent)}>
        <header>
          <h3 className={cn(styles.aboutCardHeader)}>{header}</h3>
          <h5 className={cn(styles.aboutCardSubheader)}>{subheader}</h5>
        </header>
        <p className={cn(styles.aboutCardText)}>{description}</p>
      </div>
    </section>
  )
}
