import DatePicker from 'react-datepicker'

interface Props {
  className?: string
  width?: string | number
  onChange?: (value: Date) => void
  value: Date | null
  disabled?: boolean
  minTime?: Date
  maxTime?: Date
}

const TimePicker = ({
  className,
  width = 85,
  onChange,
  value,
  disabled,
  minTime,
  maxTime
}: Props): React.ReactElement => {
  const onTimeChange = (date: Date) => {
    if (onChange) {
      onChange(date)
    }
  }

  return (
    <div className={`time-picker ${className}`} style={{ width }}>
      <DatePicker
        disabled={disabled}
        className="form-control text-center"
        selected={typeof value === 'undefined' ? null : value}
        onChange={onTimeChange}
        showTimeSelect
        showTimeSelectOnly
        placeholderText="-- -- --"
        timeIntervals={5}
        timeCaption="Time"
        minTime={minTime}
        maxTime={maxTime}
        dateFormat="h:mm aa"
      />
    </div>
  )
}

export default TimePicker
