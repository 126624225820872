/* eslint-disable max-classes-per-file */
import { action, observable, makeObservable, override } from 'mobx'

export type Constructor<T = {}> = new (...args: any[]) => T
export type MixinClass<Mixer, Base> = Constructor<Mixer> & Base

type BaseDND = {
  fillField(arg: { [x: string]: any }): void
}

type BaseToggle = {
  valid: boolean
  fillField(arg: { [x: string]: any }): void
  resetError(): void
  validate(errorMessage?: string): boolean
}

export type DNDAble = {
  position: number
  setPosition(value: number): void
  fillField({ position }: { position: number }): void
}

export interface ToggleAble {
  isActive: boolean
  setActive(value: boolean): void
  fillField({ isActive }: { isActive: boolean; [x: string]: any }): void
  isValid: boolean
  validate(message?: string): boolean
}

export function DNDMixin<T extends Constructor<BaseDND>>(
  Base: T
): MixinClass<DNDAble, T> {
  class DNDField extends Base {
    public position: number

    constructor(...args: any[]) {
      super(...args)

      makeObservable(this, {
        position: observable,
        setPosition: action.bound,
        fillField: override
      })
    }

    public setPosition(value: number): void {
      this.position = value
    }

    public fillField({ position, ...rest }: { position: number }) {
      super.fillField(rest)

      this.setPosition(position ?? this.position)
    }
  }

  return DNDField
}

export function ToggleMixin<T extends Constructor<BaseToggle>>(
  Base: T
): MixinClass<ToggleAble, T> {
  class ToggleField extends Base {
    public isActive = true

    constructor(...args: any[]) {
      super(...args)

      makeObservable(this, {
        isActive: observable,
        setActive: action.bound,
        isValid: override,
        fillField: override
      })
    }

    public setActive(value: boolean): void {
      this.isActive = value
    }

    public fillField({ isActive, ...rest }: { isActive: boolean }) {
      super.fillField(rest)

      this.setActive(
        typeof isActive !== 'undefined' ? !!isActive : this.isActive
      )

      this.resetError()
    }

    public get isValid(): boolean {
      if (!this.isActive) return true

      return this.valid
    }

    public set isValid(value: boolean) {
      this.valid = value
    }

    public validate(message?: string): boolean {
      this.resetError()
      if (!this.isActive) return true

      return super.validate(message)
    }
  }

  return ToggleField
}
