import React, { ReactElement } from 'react'
import { useStore, withStore } from '~/dataStore'
import VariantTabs from '../MobileCampaignPanel/VariantsTabs'
import PushNotificationBuilder from '~/pages/Campaign/Notification/PushNotification/UI/PushNotificationBuilder'

function PushStep(): ReactElement | null {
  const {
    goTo,
    campaign: {
      isCardCampaign,
      isSended,
      push,
      setUp: { controlGroup, setControlGroupActive }
    }
  } = useStore()

  return (
    <PushNotificationBuilder
      pushNotification={push}
      isCardCampaign={isCardCampaign}
      isSended={isSended}
      colsWidth={{ left: { xxl: { size: 8 } }, right: { xxl: { size: 4 } } }}>
      <VariantTabs
        addVariant={push.addVariant}
        removeVariant={push.removeVariant}
        removeCG={() => setControlGroupActive(false)}
        goToControlGroup={() => {
          sessionStorage.setItem('cg', 'true')
          goTo(undefined, {}, { step: 1 })
        }}
        selected={push.currentVariantId}
        onSelect={push.setVariant}
        variants={push.variants.map((v) => ({ id: v.id }))}
        controlGroup={
          controlGroup.active ? controlGroup.quantity.value : undefined
        }
      />
    </PushNotificationBuilder>
  )
}

export default withStore(PushStep)
