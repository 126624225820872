import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useStore, withStore } from '~/dataStore'
import CampaignPerformance from './CampaignPerformance'
import CampaignSummary from './CampaignSummary'
import ClickPerformance from './ClickPerformance'
import GoalsPerformance from './GoalsPerformance'
import RangeDatePickerDropdown from '~/components/forms/DatePicker/RangeDatePickerDropdown'
import DownloadBtn from '~/components/DownloadBtn'
import CampaignTypeSwitch from './CampaignTypeSwitch'
import CampaignNumbers from './UI/CampaignNumbers'
import { IReport } from './Model/Report.interface'

function CampaignReport(): React.ReactElement | null {
  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      }
    },
    resetReport,
    campaignReport: {
      report,
      campaignTypeOptions,
      currentType,
      setType,
      fetchReport,
      fetchStatsAndGoals,
      startDateFilter,
      endDateFilter,
      setFilterDates,
      summary,
      summaryNumbers,
      numbers,
      charts,
      goalsPerformance,
      clickPerformance,
      preview
    }
  } = useStore()

  const { appId, campaignId } = useParams<{
    appId: string
    campaignId: string
  }>()

  function setFilter(dates: { startDate: Date; endDate: Date }) {
    setFilterDates(dates)
    fetchStatsAndGoals(appId, campaignId)
  }

  function exportReportButton(rep?: IReport): React.ReactElement | undefined {
    const exportUrl = rep?.stats?.exportUrl || rep?.old?.exportUrl
    if (exportUrl) {
      return (
        <DownloadBtn classes="ms-3 btn--wide" url={exportUrl}>
          Export Report
        </DownloadBtn>
      )
    }

    return undefined
  }

  useEffect(() => {
    fetchReport(appId, campaignId)

    return () => {
      resetReport()
    }
  }, [])

  return (
    <Container fluid>
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <div className="ms-auto me-0">
          {startDateFilter && endDateFilter && (
            <RangeDatePickerDropdown
              startDateFilter={startDateFilter}
              endDateFilter={endDateFilter}
              setFilter={setFilter}
            />
          )}
          {exportReportButton(report)}
        </div>
      </div>
      <CampaignSummary
        summary={summary}
        summaryNumbers={summaryNumbers}
        preview={preview}
        types={campaignTypeOptions}
        currentType={currentType}
        setType={setType}
        report={report}
      />

      {campaignTypeOptions.length > 1 && (
        <CampaignTypeSwitch
          tabs={campaignTypeOptions}
          activeTab={currentType}
          toggle={setType}
          classes="mb-4 mt-5 tabs--big"
        />
      )}
      <CampaignNumbers numbers={numbers} />
      <CampaignPerformance charts={charts} />
      {campaignsGoals && (
        <GoalsPerformance goalsPerformance={goalsPerformance} />
      )}
      {clickPerformance && <ClickPerformance rows={clickPerformance} />}
    </Container>
  )
}

export default withStore(CampaignReport)
