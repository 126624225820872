import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'reactstrap'
import cn from 'classnames'
import { downloadFileRequest } from '~/api/requests'

interface IProps {
  classes?: string
  iconClasses?: string
  url: string
  [x: string]: unknown
}

export default function DownloadBtn({
  classes,
  iconClasses,
  url,
  children,
  ...rest
}: IProps): React.ReactElement {
  async function downloadReport(): Promise<void> {
    try {
      await downloadFileRequest(url)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Button
      color="body"
      outline
      className={`${classes} download-btn btn--hover bg-white`}
      onClick={downloadReport}
      {...rest}
      aria-label="Download Report"
      download>
      <FontAwesomeIcon
        fixedWidth
        icon={faFileDownload}
        className={cn('me-1', iconClasses)}
      />
      {children}
    </Button>
  )
}
