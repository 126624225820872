import React from 'react'
import StatCard from '~/components/reports/StatCard'
import LineChart from '~/components/ApexCharts/ApexLineChart/ApexLineChart'
import { IChartData } from '~/dataStore/reports/Report.interface'
import { useStore, withStore } from '~/dataStore'

const CampaignsSpecificMetrics = ({
  chartData
}: {
  chartData: IChartData[]
}): React.ReactElement => {
  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      }
    }
  } = useStore()

  const goalsChartDataLabels = [
    'Primary Conversion Rate',
    'Secondary Conversion Rate'
  ]

  const chart = campaignsGoals
    ? chartData
    : chartData.filter((item) => !goalsChartDataLabels.includes(item.name))

  return (
    <StatCard
      cardClasses="mb-0 h-100"
      tooltip="This graph shows the unique click, unique open, primary conversion, and secondary conversion  rates for the last 8 individual campaigns"
      title={`Unique Open Rate  vs  Unique Click Rate  ${
        campaignsGoals ? 'vs  Conversion Rate by Campaigns' : ''
      }`}>
      <LineChart
        chartData={chart}
        minimumActiveSeries={4}
        chartType="bar"
        xAxisConfig={{ xAxisType: 'category', hideOverlappingLabels: false }}
      />
    </StatCard>
  )
}

export default withStore(CampaignsSpecificMetrics)
