import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton'
import ChatMessage from './components/ChatMessage'
import {
  useChat,
  useInvalidateChat,
  useMarkRead,
  useSendMessage
} from '../useMessages'
import defaultUserAvatar from '~/assets/img/default-user-avatar.svg'
import AssignMessage from './components/AssignMessage'
import { IChatMessage } from '~/pages/Messages/Chat/Chat.interface'

import styles from '../Messages.scss'

export const Chat = ({ goBack, id }: { goBack: () => void; id: string }) => {
  const [newMessage, setNewMessage] = useState('')
  const messageBox = useRef(null)
  const invalidateChat = useInvalidateChat()
  const { data: chat } = useChat(id, { perPage: 99999 })
  const { mutateAsync: sendMessage, isLoading } = useSendMessage(id)
  const { mutateAsync: markRead } = useMarkRead(id)

  const scrollToBottom = () => {
    messageBox.current.scrollBottom = messageBox.current.scrollHeight
    const scroll =
      messageBox.current.scrollHeight - messageBox.current.clientHeight
    messageBox.current.scrollTo(0, scroll)
  }

  const onSendMessage = async () => {
    try {
      await sendMessage({ message: newMessage })
      scrollToBottom()
      setNewMessage('')
    } catch (error) {
      console.log(error)
    }
  }

  const handleKeyPress = (event: {
    key: string
    shiftKey: any
    preventDefault: () => void
  }) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      onSendMessage()
    }
  }

  useEffect(() => {
    if (messageBox.current) {
      scrollToBottom()
    }
  }, [chat?.data])

  useEffect(() => {
    markRead()

    return () => {
      invalidateChat()
    }
  }, [id])

  return (
    <div className={styles.chatWrapper}>
      <div className="py-2 pe-4 ps-2 border-bottom d-none d-lg-block">
        <div className="d-flex align-items-center py-1">
          <div className="position-relative">
            <Button color="" onClick={goBack}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </Button>
            <img
              src={defaultUserAvatar}
              className="rounded-circle ms-2"
              alt={chat?.userName}
              width="40"
              height="40"
            />
          </div>
          <div className="flex-grow-1 ps-3">
            <strong>{chat?.userName || <Skeleton width={100} />}</strong>
          </div>
          <div>
            <AssignMessage chatId={id} />
          </div>
        </div>
      </div>
      <div
        className="position-relative overflow-auto flex-grow-1"
        ref={messageBox}>
        <div className="p-4">
          {chat?.data?.map((message: IChatMessage) => (
            <ChatMessage key={message.id} message={message} />
          ))}
        </div>
      </div>
      <div className="p-3 border-top">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSendMessage()
          }}
          onKeyDown={handleKeyPress}>
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message here"
            className="w-100 p-2 border-body color-body rounded"
          />
          <Button
            disabled={isLoading || !newMessage}
            color="primary"
            type="submit"
            className="float-end btn--wide mt-2">
            Send
            {isLoading && (
              <FontAwesomeIcon
                className="ms-2"
                icon={faSpinner}
                spin
                size="xs"
              />
            )}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Chat
