import React from 'react'
import BatteryStatusDot from '../BatteryStatusDot'
import { BatteryStatus } from '../../Beacons.interface'

import './BatteryStatusBlock.scss'

const BatteryStatusBlock = ({
  status
}: {
  status: BatteryStatus
}): React.ReactElement => (
  <div className="battery-status-block">
    <span className="battery-status-block__label">Battery Status</span>
    <div className="battery-status-block__status">
      <BatteryStatusDot batteryStatus={status} slim />
      <span className="ms-2">{status}</span>
    </div>
  </div>
)

export default BatteryStatusBlock
