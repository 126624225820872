import { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import { uniqueId } from 'lodash'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useUserDetails from '~/pages/Users/UserDetails/useUserDetails'

const UserCustomDataSection = (): ReactElement => {
  const { data: user, isLoading } = useUserDetails()
  return (
    <Card aria-label="User Custom Data Section">
      <CardHeader
        className="d-flex justify-content-between pt-3"
        aria-label="Custom Data Header">
        <CardTitle tag="h3" className="mb-0" aria-label="Custom Data Title">
          Custom Data
        </CardTitle>
      </CardHeader>
      <CardBody className="pb-2" aria-label="Custom Data Body">
        <div className="d-flex flex-wrap" aria-label="Custom Data List">
          {isLoading && (
            <>
              <Skeleton className="me-3" width={100} />
              <Skeleton className="me-3" width={100} />
              <Skeleton className="me-3" width={100} />
              <Skeleton width={100} />
            </>
          )}
          {!isLoading &&
            user?.customData.map((customData) => (
              <p
                className="pe-4 d-flex align-items-center"
                key={uniqueId()}
                aria-label={`Custom Data: ${customData}`}>
                <FontAwesomeIcon icon={faTag} className="me-1" /> {customData}
              </p>
            ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default UserCustomDataSection
