import { getRequest } from '~/api/requests'
import { get, post, put, destroy } from '~/api/v2'
import { ID } from '~/common.interface'
import {
  JourneyBlock,
  JourneyBlockType,
  JourneyDTO,
  JourneyTemplate,
  JourneyTemplatesDTO
} from '../JourneyBuilder/Store/JourneyBuilder.interface'
import {
  JourneyReportDTO,
  JourneyStatsDTO
} from '../JourneyReport/Store/JourneyReport.interface'
import {
  IJourneysQueryParams,
  IJourneysListDTO,
  IAutoSegment
} from '../JourneysList/JourneysList.interface'

export function fetchJourneys(
  appId: string,
  params?: IJourneysQueryParams | undefined,
  abortSignal?: AbortSignal
): Promise<IJourneysListDTO> {
  return get(`${appId}/journeys`, params, abortSignal)
}

export function fetchJourney(
  appId: ID,
  journeyId: ID
): Promise<Required<JourneyDTO>> {
  return get(`${appId}/journeys/${journeyId}`)
}

export function fetchJourneyTemplates(
  appId: ID
): Promise<Required<JourneyTemplatesDTO>> {
  return get(`${appId}/journey_templates`)
}

export function fetchDefaultJourneyTemplates(): Promise<
  Required<JourneyTemplatesDTO>
> {
  return getRequest(`/api/v2/default_journey_templates`)
}

export function fetchJourneyTemplate(
  appID: ID,
  templateID: ID
): Promise<Required<JourneyTemplate>> {
  return get(`${appID}/journey_templates/${templateID}`, {
    showHidden: true
  })
}

export function fetchDefaultJourneyTemplate(
  templateID: ID
): Promise<Required<JourneyTemplate>> {
  return getRequest(`/api/v2/default_journey_templates/${templateID}`, {
    showHidden: true
  })
}

export function saveJourney(
  appId: string,
  data: JourneyDTO
): Promise<Required<JourneyDTO>> {
  return post(`${appId}/journeys`, data)
}

export function updateJourney(
  appId: ID,
  journeyId: ID,
  data: JourneyDTO
): Promise<Required<JourneyDTO>> {
  return put(`${appId}/journeys/${journeyId}`, data)
}

export function sendJourney(appId: ID, journeyId: ID): Promise<void> {
  return put(`${appId}/journeys/${journeyId}/launch`)
}

export function updateBlock(
  appId: ID,
  journeyId: ID,
  blockId: ID,
  data: Exclude<JourneyBlock, JourneyDTO>
): Promise<JourneyBlock> {
  return put(`${appId}/journeys/${journeyId}/blocks/${blockId}`, data)
}

export function createBlock(
  appId: ID,
  journeyId: ID,
  data:
    | {
        parentId: ID
        childBlockId: ID
        type: JourneyBlockType
        showChildBlocks?: boolean
        childBlocks?: JourneyBlock[]
        options?: [{ childBlockId: ID; type: 'yes' }]
      }
    | { parentId: ID; childBlockId: undefined; type: JourneyBlockType.EXIT }
): Promise<Required<JourneyBlock>> {
  return post(`${appId}/journeys/${journeyId}/blocks`, data)
}

export function removeBlock(
  appId: ID,
  journeyId: ID,
  blockId: ID
): Promise<void> {
  return destroy(
    `${appId}/journeys/${journeyId}/blocks/${blockId}?destroy_children=false`
  )
}

export function fetchAutoSegment(
  appId: ID,
  journeyId: ID
): Promise<IAutoSegment[]> {
  return get(`${appId}/journeys/${journeyId}/add_to_segment_blocks/segments`)
}

/*
*******
  Report page
*******
*/
export function fetchJourneyOverview(
  appId: ID,
  journeyId: ID
): Promise<JourneyReportDTO> {
  return get(`${appId}/journeys/${journeyId}/overview`)
}

export function fetchJourneyStats(
  appId: ID,
  journeyId: ID
): Promise<JourneyStatsDTO> {
  return get(`${appId}/journeys/${journeyId}/stats`)
}

/** *
 ******
 * Journeys list actions
 ******
 */

export function stopJourney(appId: ID, journeyId: ID): Promise<void> {
  return put(`${appId}/journeys/${journeyId}/stop`)
}

export function deleteJourney(appId: ID, journeyId: ID): Promise<void> {
  return destroy(`${appId}/journeys/${journeyId}`)
}

export function pauseJourney(appId: ID, journeyId: ID): Promise<void> {
  return put(`${appId}/journeys/${journeyId}/pause`)
}

export function resumeJourney(appId: ID, journeyId: ID): Promise<void> {
  return put(`${appId}/journeys/${journeyId}/resume`)
}

export function duplicateJourney(
  appId: ID,
  journeyId: ID
): Promise<{ id: ID }> {
  return post(`${appId}/journeys/${journeyId}/duplicate`)
}

export function requestJourneySendApproval(
  appId: string,
  params: { journeyId: string }
): Promise<void> {
  return get(`${appId}/journeys/demo_register_in_send`, params)
}
