import { uploadFile, uploadImage } from '~/api/apps'
import { postRequest } from '~/api/requests'

export const convertImageToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export function convertURLToBase64(url: string): Promise<{ base64: string }> {
  return postRequest(`/api/v2/images/base64fromurl`, { url })
}

export async function convertToBlob(base64OrUrl: string): Promise<Blob> {
  return fetch(base64OrUrl).then((r) => r.blob())
}

/**
 *
 * @param blob object to transform into File type
 * @param fileName optional value to become a filename
 * @returns File object
 */

export function blobToFile(blob: Blob, fileName?: string): File {
  return new File([blob], fileName || 'cropped.jpeg', {
    type: 'image/jpeg'
  })
}

export async function convertFileToURL(
  file: File,
  appId: string
): Promise<string | undefined> {
  const response = await uploadFile(appId, file)
  return response.url
}

// Limitations 1 file per request, 1 mb max, allowed extensions: jpg, jpeg, png, gif, svg
export async function convertImageToUrl(
  appId: string,
  file: File
): Promise<string> {
  const formData = new FormData()
  formData.append('file', file)
  const response = await uploadImage(appId, formData)
  return response.url
}
