import { UncontrolledTooltip } from 'reactstrap'
import JourneyNotificationPreview from '~/pages/Journeys/UI/JourneyNotificationPreview/JourneyNotificationPreview'
import { Message } from '../../Blocks'

interface IProps {
  message: Message
}

export default function JourneyNotificationReview({
  message
}: IProps): React.ReactElement {
  const id = `message-${message.id}`
  return (
    <div className="flex-0-1-3 p-3 d-flex flex-column">
      <div className="mb-4">
        <h2 id={id} className="fw-normal text-body truncate w-fit-content ">
          {message.name.value}
        </h2>
      </div>
      <UncontrolledTooltip placement="top" target={id}>
        {message.name.value}
      </UncontrolledTooltip>
      <div className="bg-water-gray p-3 flex-1-1">
        <JourneyNotificationPreview
          bottom
          preview={message.getNotificationPreviews()}
        />
      </div>
    </div>
  )
}
