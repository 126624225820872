import { getRequest, postRequest, putRequest } from '~/api/requests'
import { FORM_FIELDS } from '~/pages/Auth/ResetPassword/ResetPassword'

export function isAdminAuthorized(): Promise<{ success: boolean }> {
  return getRequest(`/sessions/api/v2/whoami`)
}

export function resetPassword(data: {
  email: string
}): Promise<{ success: boolean }> {
  return postRequest(`/admins/api/v2/password`, data)
}

export function changePassword(
  data: FORM_FIELDS & { resetPasswordToken: string }
): Promise<{ message: string; path: string }> {
  return putRequest(`/admins/api/v2/update_password`, data)
}
