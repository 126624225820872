import React from 'react'
import { Collapse } from 'reactstrap'
import cn from 'classnames'
import color from '~/utils/color'
import ToggleRadioSwitch from '~/components/forms/ToggleRadioSwitch'

interface IProps {
  children: React.ReactElement
  isCollapsed: boolean
  switchLabels: any[]
  minHeight: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  separator?: boolean
  title: string | React.ReactElement
  disabled: boolean
  selected: string
  className?: string
  invalid?: boolean
}

const Panel = ({
  selected,
  children,
  isCollapsed,
  onChange,
  title,
  switchLabels,
  minHeight,
  separator,
  className,
  disabled = false,
  invalid
}: IProps): React.ReactElement => {
  return (
    <>
      <div className="d-flex">
        <div className="d-flex align-items-center w-100">
          <h4 className="mb-0 fw-normal">{title}</h4>
          <ToggleRadioSwitch
            disabled={disabled}
            options={switchLabels}
            className={cn('ms-auto', { 'error-input': invalid })}
            onChange={onChange}
            selected={selected}
          />
        </div>
      </div>
      <Collapse isOpen={isCollapsed}>
        <div
          style={{ minHeight }}
          className={cn({ 'pt-4 ps-4 pe-4 pb-1 mt-3': children }, className)}>
          {children}
        </div>
      </Collapse>
      {separator && (
        <div
          style={{
            margin: '20px 0',
            width: '100%',
            height: 1,
            backgroundColor: color.mysticGray
          }}
        />
      )}
    </>
  )
}

export default Panel
