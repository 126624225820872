import { makeAutoObservable } from 'mobx'
import { ID } from '~/common.interface'
import {
  ActionTypes,
  CampaignType,
  ICampaignModel,
  ICardPayload
} from '~/dataStore/Campaign/Campaign.interface'
import { IStep } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import CardBack from '~/pages/Campaign/Notification/CardNotification/Model/CardBack.model'
import CardNotification from '~/pages/Campaign/Notification/CardNotification/Model/CardNotification'

type Step = {
  number: number
  title: string
  store: CardNotification | CardBack
  isValid: boolean
  type: CampaignType.CARD_FRONT | CampaignType.CARD_BACK
}

export default class JourneyCard {
  public card: CardNotification

  public stepStores: Step[]

  public currentStep: Step

  constructor({ appId, campaign }: { appId: ID; campaign?: ICampaignModel }) {
    makeAutoObservable(this, undefined, { autoBind: true })

    this.card = new CardNotification({ appId, campaign })

    this.stepStores = [
      {
        number: 1,
        type: CampaignType.CARD_FRONT,
        title: MCampaignTypeToName.get(CampaignType.CARD_FRONT),
        store: this.card,
        isValid: this.card.isStepValid
      }
    ]

    const [currentStep] = this.stepStores
    this.currentStep = currentStep
  }

  public toggleCardBackStore(actionType: ActionTypes | undefined): void {
    if (actionType === ActionTypes.CARD_BACK) {
      this.addCardBackStep()
    } else {
      this.stepStores = this.stepStores.filter(
        (s) => s.type !== CampaignType.CARD_BACK
      )

      this.card.goals?.removeGoals('button', 'back')
    }
  }

  private addCardBackStep(): void {
    this.stepStores[1] = {
      number: 2,
      title: MCampaignTypeToName.get(CampaignType.CARD_BACK),
      type: CampaignType.CARD_BACK,
      store: this.card.cardBack,
      isValid: this.card.cardBack.isStepValid
    }
  }

  public fillStore(data: ICardPayload): void {
    this.card.fillStore({ cardNotification: data })

    if (this.card.isCardBack) {
      this.addCardBackStep()
    }
  }

  public getStoreByNumber(
    number: number
  ): CardNotification | CardBack | undefined {
    return this.stepStores.find((step: Step) => step.number === number)?.store
  }

  public getStepByNumber(number: number): Step | undefined {
    return this.stepStores.find((step: Step) => step.number === number)
  }

  public setStep(stepNumber: number): void {
    const step = this.getStepByNumber(stepNumber)
    this.currentStep = step || this.stepStores[0]
  }

  public async getPayload(): Promise<unknown> {
    return this.card.getPayload()
  }

  public validateStep(): void {
    this.card.validateStep()
    if (this.card.isCardBack) {
      this.card.cardBack.validateStep()
    }
  }

  public getErrorMessages(): string[] {
    return this.currentStep.store.getErrorMessages()
  }

  public get isStepValid(): boolean {
    let isValid = this.card.isStepValid

    if (this.card.isCardBack) {
      isValid = this.card.cardBack.isStepValid && isValid
    }

    return isValid
  }

  public resetError(): void {
    this.card.resetError()
    this.card.cardBack.resetError()
  }

  public generatePreview(): PartialPreview {
    return this.card.generatePreview()
  }

  get steps(): IStep[] {
    return this.stepStores.map((step) => ({
      number: step.number,
      title: step.title,
      isValid: step.store.isStepValid
    }))
  }
}
