import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap'
import cn from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ToggleSwitch from '~/components/forms/ToggleSwitch'
import Input from '~/components/forms/Input'
import Tooltip from '~/components/reports/Tooltip'
import {
  URL_REGEX,
  getFormServerSideErrors,
  showGeneralError
} from '~/utils/validations'
import { editWebhookSettings } from '~/api/appSettings'
import styles from './WebhookSection.scss'
import { NotificationType, showNotification } from '~/utils/Notification'
import { useStore, withStore } from '~/dataStore'
import { FormValues, ERRORS_DICT } from './WebhookSection.interface'

const WebhookSection = (): React.ReactElement => {
  const { appId } = useParams<{ appId: string }>()
  const {
    app: {
      appDetails,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()

  const {
    register,
    handleSubmit,
    setError,
    control,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    values: {
      beaconEventWebhookEnabled:
        appDetails?.beacon.eventWebhookEnabled || false,
      beaconEventUrl: appDetails?.beacon.eventUrl
    }
  })

  const onSubmit = async (data: FormValues) => {
    try {
      await editWebhookSettings(appId, {
        settingAttributes: {
          beaconEventWebhookEnabled: `${data.beaconEventWebhookEnabled}`,
          beaconEventUrl: data.beaconEventUrl || undefined
        }
      })
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
    } catch (err) {
      if (err.body?.errors) {
        getFormServerSideErrors(err.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader>
          <CardTitle tag="h3" className="mb-0 mt-2 d-flex align-items-center">
            <Controller
              control={control}
              name="beaconEventWebhookEnabled"
              register={register}
              render={({ field: { onChange, value } }) => (
                <ToggleSwitch
                  checked={value}
                  onChange={(value) => onChange(value)}
                  controlId="push-location-switch"
                />
              )}
            />
            <label htmlFor="push-location-switch" className="mb-0 ms-3 h3">
              Webhooks
            </label>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={12}>
              <div className="d-flex align-items-center">
                <p className="mb-0">Send a POST request to</p>
                <Input
                  disabled={!watch('beaconEventWebhookEnabled')}
                  className={cn('my-0 mx-3', styles.beaconEventInput, {
                    'form-group--error': errors.beaconEventUrl
                  })}
                  name="beaconEventUrl"
                  register={register}
                  validation={{
                    pattern: {
                      value: URL_REGEX,
                      message: 'Invalid url'
                    }
                  }}
                  placeholder="e.g. www.your-domain.com"
                  errorTooltip={errors.beaconEventUrl?.message}
                  tooltip="If you set a password for your cert, enter it here"
                />
                <p className="mb-0">
                  every time user enters or leaves a beacon
                </p>
                <Tooltip tooltip="Every time a user with the bank app enters or leaves any beacon, pulsate sends a request to the provided address" />
              </div>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || isDemoAdmin}
                className="mt-4 d-block">
                Save Changes
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-2"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </form>
    </Card>
  )
}

export default withStore(WebhookSection)
