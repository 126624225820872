export const ERRORS_DICT = {
  email: {
    'is already taken': 'Email is already taken'
  },
  activationCode: {
    "can't be blank": 'Activation Code is missing (length 36 or 40)',
    'is not valid': 'Activation Code is not valid (length 36 or 40)'
  },
  appName: {
    "can't be blank": 'App Name is missing'
  },
  username: {
    'is already taken': 'Username is already taken'
  },
  companyName: {
    "can't be blank": 'Company Name is missing',
    'is already taken': 'Company Name is already taken'
  }
}

export const STEP1_FORM_FIELDS = [
  'name',
  'username',
  'email',
  'password',
  'passwordConfirmation'
] as const

export type TStep1FormFields = Record<
  (typeof STEP1_FORM_FIELDS)[number],
  string
>

export const STEP2_FORM_FIELDS = [
  'companyName',
  'appName',
  'activationCode'
] as const

export type TStep2FormFields = Record<
  (typeof STEP2_FORM_FIELDS)[number],
  string
>

export type FormFields = TStep1FormFields & TStep2FormFields
