import React from 'react'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal/Modal'
import { useRemoveDeepLink } from '../useDeeplinks'

const DeleteDeeplinkModal = (): React.ReactElement => {
  const { mutate: removeDeepLink } = useRemoveDeepLink()
  return (
    <Modal
      id="confirmDeeplinkDeletion"
      className=""
      renderHeader={() => 'Delete Deeplink'}
      render={(close: () => void, params) => (
        <ConfirmationModal
          onSubmit={() => {
            removeDeepLink({ id: params?.id, closeModal: close })
          }}
          onClose={close}
          okText="Delete"
          cancelText="Cancel"
          body="Are you sure you want to delete this deeplink?"
        />
      )}
    />
  )
}

export default DeleteDeeplinkModal
