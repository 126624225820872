import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import cn from 'classnames'
import plugIcon from '~/components/icons/plug.svg'
import downloadIcon from '~/components/icons/download.svg'
import CustomDropdown from '~/components/CustomDropdown'
import { useExportAppOops } from '~/pages/OpportunitiesDashboard/useOpportunitiesDashboard'
import { useStore, withStore } from '~/dataStore'

interface IProps {
  rangeDays: number[] | null
  setRange: (range: number) => void
  range: number | undefined
  channel: string
  setChannel: (channel: string) => void
}

const EngagementSummaryToolbar = ({
  rangeDays,
  setRange,
  range,
  channel,
  setChannel
}: IProps) => {
  const { mutate: exportAppOops } = useExportAppOops()
  const {
    app: {
      appDetails: { downloadOpportunityFiles }
    }
  } = useStore()

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        {rangeDays && (
          <CustomDropdown
            right
            modern
            dropdownToggleClassname="border-0"
            dropdownToggle={
              <div
                className={cn(
                  'dropdown-toggle text-base bg-white text-body outline-none'
                )}>
                Time Period:{' '}
                <span className="ps-1">
                  <strong>{range} days</strong>
                </span>
              </div>
            }
            options={rangeDays?.map((option) => ({
              name: `${option} days`,
              action: () => setRange(option)
            }))}
          />
        )}

        <CustomDropdown
          right
          modern
          disabled
          wrapperClassname="ms-3"
          dropdownToggleClassname="border-0"
          dropdownToggle={
            <div
              className={cn(
                'dropdown-toggle text-base bg-white text-body outline-none'
              )}>
              Channel:{' '}
              <span className="ps-1">
                <strong>{channel}</strong>
              </span>
            </div>
          }
          options={[{ name: 'All Channels' }]}
        />
      </div>
      <div>
        <Button
          disabled
          color="body"
          className="rounded rounded-pill px-4 text-base  fw-medium h-100">
          <img src={plugIcon} alt="Integrations" className="me-3" />
          Integrations
        </Button>

        <Button
          disabled={!downloadOpportunityFiles?.appFile}
          color="transparent"
          id="engagement-download-opps-btn"
          onClick={() => exportAppOops({ range })}
          className="rounded rounded-pill px-4 text-base ms-3  fw-medium h-100 border text-black bg-white">
          <img
            src={downloadIcon}
            alt="Download My Opportunities"
            className="me-3"
          />
          Download My
          <span className="d-none d-xxl-inline-block ms-1">Opportunities</span>
          <span className="d-xxxl-none ms-1">Opps</span>
        </Button>

        {!downloadOpportunityFiles?.appFile && (
          <UncontrolledTooltip
            popperClassName="tooltip-modern"
            placement="top"
            target="engagement-download-opps-btn">
            Opportunities file is being generated.
          </UncontrolledTooltip>
        )}
      </div>
    </div>
  )
}

export default withStore(EngagementSummaryToolbar)
