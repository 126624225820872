import cn from 'classnames'
import GraduationCapIcon from '~/components/icons/graduationCap'
import styles from '../noticeBarSecondary.scss'

const LearnMoreButton = ({
  purple,
  href
}: {
  purple?: boolean
  href: string
}): React.ReactElement => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={cn(styles.learnMoreButton, 'btn', {
        [styles.learnMoreButtonPurple]: purple
      })}
      href={href}>
      <GraduationCapIcon
        className={cn(styles.learnMoreButtonIcon, 'fill-current')}
      />
      Learn more
    </a>
  )
}

export default LearnMoreButton
