import React from 'react'
import StatCard from '~/components/reports/StatCard'
import LineChart from '~/components/ApexCharts/ApexLineChart'
import { IChartData } from '~/dataStore/reports/Report.interface'

const EngagementByTheHour = ({
  chartData
}: {
  chartData: IChartData[]
}): React.ReactElement => {
  return (
    <StatCard
      cardClasses="mb-0 h-100"
      tooltip="This graph shows the click and open rate trends prevailing during different hours of the day"
      title="Email Engagement by the Hour of the Day">
      <LineChart
        includeOverviewColumn
        chartData={chartData}
        minimumActiveSeries={3}
        xAxisConfig={{ trim: false, tickAmount: 5, xAxisType: 'category' }}
      />
    </StatCard>
  )
}

export default EngagementByTheHour
