import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { DeeplinkFormValues, IDeeplink } from '../Deeplink.interface'
import Modal from '~/components/modals/Modal/Modal'
import Input from '~/components/forms/Input'
import { useAddDeepLink, useEditDeepLink } from '../useDeeplinks'

const EditAddDeeplink = ({
  deeplink,
  close
}: {
  deeplink: IDeeplink
  close: () => void
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<DeeplinkFormValues>({
    mode: 'onBlur',
    defaultValues: { nickname: deeplink?.nickname, target: deeplink?.target }
  })
  const { mutate: edit, isLoading: isEditLoading } = useEditDeepLink(
    close,
    setError
  )
  const { mutate: add, isLoading: isAddLoading } = useAddDeepLink(
    close,
    setError
  )

  const onSubmitForm = async (data: DeeplinkFormValues) => {
    if (deeplink) {
      edit({ id: deeplink.id, data })
    } else {
      add(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <ModalBody>
        <Input
          register={register}
          placeholder="Give your Deeplink a Name"
          label="Deeplink Name"
          className={cn('mb-3', {
            'form-group--error': errors.nickname
          })}
          validation={{
            required: {
              value: true,
              message: 'Deeplink name is missing.'
            }
          }}
          errorTooltip={errors?.nickname?.message}
          tooltip=""
          name="nickname"
        />
        <Input
          register={register}
          placeholder="Add Deeplink URL"
          label="Deeplink URL"
          validation={{
            required: {
              value: true,
              message: 'Deeplink url is missing.'
            }
          }}
          className={cn('mb-0', {
            'form-group--error': errors.target
          })}
          errorTooltip={errors?.target?.message}
          tooltip=""
          name="target"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={isAddLoading || isEditLoading}>
          Save
          {(isAddLoading || isEditLoading) && (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-2" />
          )}
        </Button>
      </ModalFooter>
    </form>
  )
}

const EditAddDeeplinkModal = () => {
  return (
    <Modal
      id="editAddDeeplink"
      size="md"
      renderHeader={(onClose, params) =>
        `${params?.deeplink ? 'Edit' : 'New'} Deeplink`
      }
      render={(close: () => void, params) => (
        <EditAddDeeplink deeplink={params?.deeplink} close={close} />
      )}
    />
  )
}

export default EditAddDeeplinkModal
