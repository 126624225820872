import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useStore } from '~/dataStore'
import EditAddDomainModal from './EditAddDomainModal'
import TableNew from '~/components/Table/TableNew'
import styles from '~/pages/App/ChannelSettings/sections/Deeplink/DeeplinkSection.scss'
import ActionsColumn from './ActionsColumn'
import DeleteDomainModal from '~/pages/App/Settings/sections/WebSDKSection/components/DeleteDomainModal'
import { IWebSDKCredentials } from '~/pages/App/Settings/sections/WebSDKSection/WebSDKSection.interface'

const DomainsTable = ({
  domains
}: {
  domains: IWebSDKCredentials['webSdkDomains']
}) => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    },
    ui: { showModal }
  } = useStore()

  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <p className="fw-medium mb-0">Web SDK Domains</p>
        <Button
          color="primary"
          className="btn--wide btn--hover"
          size="lg"
          disabled={isDemoAdmin}
          onClick={() => showModal('editAddDomainModal')}>
          <FontAwesomeIcon icon={faPlus} size="xs" className="me-2" /> Add Web
          SDK Domain
        </Button>
      </div>
      <TableNew
        className="mt-4"
        columns={[
          {
            fieldName: 'url',
            displayName: 'Domain Url'
          },
          {
            displayName: 'Actions',
            classes: styles.actionsColumn,
            // eslint-disable-next-line react/display-name
            render: ({ row }) => <ActionsColumn domain={row} />,
            sortable: false
          }
        ]}
        rows={domains}
      />
      <DeleteDomainModal />
      <EditAddDomainModal />
    </>
  )
}

export default DomainsTable
