import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement, useRef, useEffect } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import Input from '~/components/forms/Input'

interface IProps {
  mergeTag: string
  defaultTag?: string
  mergeTagIndex: number
  onChange: (value: string, index: number) => void
}

export default function MergeTagDefault({
  mergeTag,
  mergeTagIndex,
  onChange,
  defaultTag
}: IProps): ReactElement {
  function stopDefault(e: React.MouseEvent): void {
    e.preventDefault()
    e.stopPropagation()
  }

  function handleChange(value?: string): void {
    const trimmed = value?.trim()

    if (!trimmed) {
      return
    }

    const newVal = `{{${mergeTag} | default: '${trimmed}'}}`

    onChange(newVal, mergeTagIndex)
  }

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target
    handleChange(value)
  }

  function removeDefaultValue(): void {
    onChange(`{{${mergeTag}}}`, mergeTagIndex)
  }

  function handlePaste(e: React.ClipboardEvent<HTMLDivElement>): void {
    const text = e.clipboardData.getData('text/plain')
    // ? images and other stuff
    if (!text) {
      e.preventDefault()
    }

    handleChange(text)
  }

  function handleEnter(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === 'Enter') {
      const { value } = e.target as HTMLInputElement
      handleChange(value)
    }
  }

  //* workaround on safari, blur is not fired properly on textarea
  const modalRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    modalRef.current?.focus()
  }, [])

  return (
    // eslint-disable-next-line
    <div
      className="p-2 merge-tag-modal"
      onClick={stopDefault}
      onKeyDown={(e) => e.stopPropagation()}>
      If
      <span className="mx-1 preview__tag">{mergeTag}</span>
      is unavailable, substitute with
      {!defaultTag && (
        <Input
          data-id="default-merge-tag"
          inputRef={modalRef}
          placeholder="Enter alternative word"
          className="m-0 mt-2"
          onBlur={handleBlur}
          onKeyUp={handleEnter}
          onPaste={handlePaste}
        />
      )}
      {defaultTag && (
        <div className="merge-tag-modal__default mt-2 ps-2">
          {defaultTag.replace(/'(.*?)'/, '$1')}
          <Button color="" onMouseDown={removeDefaultValue}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      )}
    </div>
  )
}
