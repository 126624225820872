import React from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { IRevenueEvent, IRevenueEventDTO } from '../RevenueSection.interface'
import ActionsColumn from './components/ActionsColumn'
import DeleteRevenueEventModal from './components/DeleteRevenueEventModal'
import EditAddRevenueEventModal from './components/EditAddRevenueEventModal'
import TableNew from '~/components/Table/TableNew'
import { useStore, withStore } from '~/dataStore'
import { useGetRevenueEvents } from '../useRevenueEvents'

import styles from './RevenueEvent.scss'

const columns = [
  {
    fieldName: 'name',
    displayName: 'Event',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IRevenueEvent }) => (
      <p className="mb-0">{row.eventName}</p>
    ),
    classes: styles.column
  },
  {
    fieldName: 'currency',
    displayName: 'Currency',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IRevenueEvent }) => (
      <p className="mb-0">{row.currency}</p>
    )
  },
  {
    fieldName: 'amount',
    displayName: 'Amount',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IRevenueEvent }) => (
      <p className="mb-0">{row.amount?.toFixed(2)}</p>
    )
  },
  {
    displayName: 'Actions',
    classes: styles.actionsColumn,
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IRevenueEvent }) => (
      <ActionsColumn revenueEvent={row} />
    )
  }
]

const RevenueEvent = () => {
  const {
    ui: { showModal },
    app: {
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const { appId } = useParams<{ appId: string }>()
  const { data: revenueEvents, isLoading } = useGetRevenueEvents(appId)

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <CardTitle tag="h3" className="mb-0 mt-2">
            Revenue Event
          </CardTitle>
          <Button
            color="primary"
            className="btn--wide btn--hover mt-2"
            size="lg"
            disabled={isDemoAdmin}
            onClick={() => showModal('editAddRevenueEventModal')}>
            <FontAwesomeIcon icon={faPlus} size="xs" className="me-2" /> Add
            Revenue Event
          </Button>
        </CardHeader>
        <CardBody className="pt-0 pb-4">
          <TableNew
            isLoading={isLoading}
            columns={columns}
            rows={
              revenueEvents?.data.map((revenueEvent: IRevenueEventDTO) => ({
                id: revenueEvent.id,
                amount: revenueEvent.attributes?.amount,
                currency: revenueEvent.attributes?.currency,
                eventName: revenueEvent.attributes?.eventName
              })) || []
            }
          />
        </CardBody>
      </Card>
      <DeleteRevenueEventModal />
      <EditAddRevenueEventModal />
    </>
  )
}

export default withStore(RevenueEvent)
