import React from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import RemoveFromNameButton from './components/RemoveFromNameButton'
import { hideSplashScreen } from '~/utils/splashScreen'
import {
  EMAIL_FROM_NAMES_LIST_QUERY,
  IFromName
} from './EmailFromNames.interface'
import { getFromNames, removeFromName } from '~/api/appSettings'
import TableNew from '~/components/Table/TableNew'
import { useStore, withStore } from '~/dataStore'
import RemoveFromNameModal from './components/RemoveFromNameModal'
import AddFromNameModal from './components/AddFromNameModal'
import { ID } from '~/common.interface'

import styles from './EmailFromNamesSection.scss'
import { NotificationType, showNotification } from '~/utils/Notification'

const columns = [
  {
    fieldName: 'name',
    displayName: 'From Name',
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IFromName }) => <p className="mb-0">{row.name}</p>,
    classes: styles.column
  },
  {
    displayName: 'Actions',
    classes: styles.actionsColumn,
    // eslint-disable-next-line react/display-name
    render: ({ row }: { row: IFromName }) => (
      <RemoveFromNameButton id={row.id} />
    )
  }
]

const EmailFromNamesSection = () => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    },
    ui: { showModal }
  } = useStore()
  const { appId } = useParams<{ appId: string }>()
  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery([EMAIL_FROM_NAMES_LIST_QUERY], () =>
    getFromNames(appId).finally(() => {
      hideSplashScreen()
    })
  )

  const onRemoveFromName = async (id: ID, closeModal: () => void) => {
    try {
      await removeFromName(appId, id)
      queryClient.invalidateQueries(EMAIL_FROM_NAMES_LIST_QUERY)
      showNotification(
        'Successfully removed email from name',
        NotificationType.SUCCESS
      )
      closeModal()
    } catch (error) {
      showNotification(
        'Failed to remove from name, please try again later.',
        NotificationType.ERROR
      )
    }
  }

  return (
    <>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <CardTitle tag="h3" className="mb-0 mt-2">
            From Name List
          </CardTitle>
          <Button
            color="primary"
            className="btn--wide btn--hover mt-2"
            size="lg"
            disabled={isDemoAdmin}
            onClick={() => showModal('addFromNameModal')}>
            <FontAwesomeIcon icon={faPlus} size="xs" className="me-2" /> Add
            Name
          </Button>
        </CardHeader>
        <CardBody className="pt-0 pb-4">
          <TableNew isLoading={isLoading} columns={columns} rows={data || []} />
        </CardBody>
      </Card>
      <RemoveFromNameModal removeFromName={onRemoveFromName} />
      <AddFromNameModal />
    </>
  )
}

export default withStore(EmailFromNamesSection)
