import cn from 'classnames'
import { Button } from 'reactstrap'
import styles from '../SegmentBuilder.scss'

interface IProps {
  title: string
  onClick: () => void
  special?: boolean
}

export default function RuleTypeOption({
  title,
  onClick,
  special
}: IProps): React.ReactElement {
  return (
    <Button
      color={special ? 'body' : ''}
      className={cn(
        styles.ruleDropdown,
        'text-left',
        'dropdown-item',
        { 'rounded-0': special },
        { [styles.ruleDropdownSpecial]: special },
        { 'btn-no-outline text-dark': !special }
      )}
      onClick={onClick}>
      {title}
    </Button>
  )
}
