import React from 'react'
import { withStore } from '~/dataStore'

import TargetingOptionPreview from './UI/TargetingOptionPreview'

import './targetingPreview.scss'
import initialIllustration from './UI/initialIllustration.svg'
import segmentIcon from '~/components/icons/segment.svg'
import beaconIcon from '~/components/icons/beacon.svg'
import geofenceIcon from '~/components/icons/geofence.svg'
import eventIcon from '~/components/icons/event.svg'
import Segments from '~/dataStore/emailBuilder/Targeting/Segments'
import Beacons from '~/dataStore/emailBuilder/Targeting/Beacons'
import Geofences from '~/dataStore/emailBuilder/Targeting/Geofences'
import Events from '~/dataStore/emailBuilder/Targeting/Events'

interface IProps {
  segments: Segments
  beacons: Beacons
  geofences: Geofences
  events?: Events
  mode: 'builder' | 'details'
  type?: 'campaign' | 'journey'
}

function TargetingPreview({
  segments,
  beacons,
  geofences,
  events,
  mode,
  type = 'campaign'
}: IProps): React.ReactElement {
  if (
    segments.selectedCount ||
    beacons.selectedCount ||
    geofences.selectedCount ||
    events?.selectedCount
  ) {
    return (
      <div className="mx-auto bubble-wrapper">
        <div className="bubble mt-0">The following customer group</div>
        <TargetingOptionPreview
          collection={segments.selectedList}
          collectionCount={segments.selectedCount}
          icon={segmentIcon}
          withCount={mode === 'builder'}
          reachRange={segments.reachRange}
          type="Segments"
        />
        <div className="bubble">will receive this {type}</div>

        {beacons.selectedCount || geofences.selectedCount ? (
          <div className="bubble bubble--mini bubble--outline">When</div>
        ) : null}

        <TargetingOptionPreview
          collectionCount={beacons.selectedCount}
          collection={beacons.selectedList}
          icon={beaconIcon}
          outlineBubble
          type="Beacons"
        />

        <TargetingOptionPreview
          collectionCount={geofences.selectedCount}
          collection={geofences.selectedList}
          icon={geofenceIcon}
          outlineBubble
          type="Geofences"
        />
        {events && (
          <TargetingOptionPreview
            collectionCount={events.selectedCount}
            collection={events.selectedList}
            icon={eventIcon}
            outlineBubble
            type="Events"
          />
        )}
      </div>
    )
  }

  return (
    <img alt="" src={initialIllustration} className="mx-auto illustration" />
  )
}

export default withStore(TargetingPreview)
