import React from 'react'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IDeeplink } from '../Deeplink.interface'
import { withStore, useStore } from '~/dataStore'

const ActionsColumn = ({ deeplink }: { deeplink: IDeeplink }) => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    },
    ui: { showModal }
  } = useStore()

  return (
    <>
      <Button
        onClick={() =>
          showModal('confirmDeeplinkDeletion', { id: deeplink.id })
        }
        disabled={isDemoAdmin}
        className="float-end text-body px-0 py-0 ms-3"
        color="white">
        <FontAwesomeIcon size="lg" icon={faTrash} />
      </Button>
      <Button
        className="float-end text-body px-0 py-0"
        color="white"
        disabled={isDemoAdmin}
        onClick={() => showModal('editAddDeeplink', { deeplink })}>
        <FontAwesomeIcon size="lg" icon={faPen} />
      </Button>
    </>
  )
}

export default withStore(ActionsColumn)
