import React from 'react'

const IconCalendar = ({ className }: { className?: string }) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0117188 17.9137C0.0117188 18.9219 0.827567 19.7399 1.83315 19.7399H15.1903C16.1959 19.7399 17.0117 18.9219 17.0117 17.9137V7.56543H0.0117188V17.9137ZM12.1546 10.4569C12.1546 10.2058 12.3595 10.0003 12.6099 10.0003H14.1278C14.3782 10.0003 14.5831 10.2058 14.5831 10.4569V11.9787C14.5831 12.2298 14.3782 12.4352 14.1278 12.4352H12.6099C12.3595 12.4352 12.1546 12.2298 12.1546 11.9787V10.4569ZM12.1546 15.3267C12.1546 15.0756 12.3595 14.8701 12.6099 14.8701H14.1278C14.3782 14.8701 14.5831 15.0756 14.5831 15.3267V16.8485C14.5831 17.0996 14.3782 17.305 14.1278 17.305H12.6099C12.3595 17.305 12.1546 17.0996 12.1546 16.8485V15.3267ZM7.29743 10.4569C7.29743 10.2058 7.50234 10.0003 7.75279 10.0003H9.27065C9.52109 10.0003 9.726 10.2058 9.726 10.4569V11.9787C9.726 12.2298 9.52109 12.4352 9.27065 12.4352H7.75279C7.50234 12.4352 7.29743 12.2298 7.29743 11.9787V10.4569ZM7.29743 15.3267C7.29743 15.0756 7.50234 14.8701 7.75279 14.8701H9.27065C9.52109 14.8701 9.726 15.0756 9.726 15.3267V16.8485C9.726 17.0996 9.52109 17.305 9.27065 17.305H7.75279C7.50234 17.305 7.29743 17.0996 7.29743 16.8485V15.3267ZM2.44029 10.4569C2.44029 10.2058 2.6452 10.0003 2.89565 10.0003H4.4135C4.66395 10.0003 4.86886 10.2058 4.86886 10.4569V11.9787C4.86886 12.2298 4.66395 12.4352 4.4135 12.4352H2.89565C2.6452 12.4352 2.44029 12.2298 2.44029 11.9787V10.4569ZM2.44029 15.3267C2.44029 15.0756 2.6452 14.8701 2.89565 14.8701H4.4135C4.66395 14.8701 4.86886 15.0756 4.86886 15.3267V16.8485C4.86886 17.0996 4.66395 17.305 4.4135 17.305H2.89565C2.6452 17.305 2.44029 17.0996 2.44029 16.8485V15.3267ZM15.1903 2.69564H13.3689V0.869466C13.3689 0.534668 13.0956 0.260742 12.7617 0.260742H11.5474C11.2135 0.260742 10.9403 0.534668 10.9403 0.869466V2.69564H6.08315V0.869466C6.08315 0.534668 5.80993 0.260742 5.476 0.260742H4.26172C3.92779 0.260742 3.65458 0.534668 3.65458 0.869466V2.69564H1.83315C0.827567 2.69564 0.0117188 3.51361 0.0117188 4.52181V6.34798H17.0117V4.52181C17.0117 3.51361 16.1959 2.69564 15.1903 2.69564Z"
      fill="#28518C"
    />
  </svg>
)

export default IconCalendar
