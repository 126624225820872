import React from 'react'
import cn from 'classnames'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import styles from '../JourneyBuilderSidebar/Sidebar.scss'
import { withStore } from '~/dataStore'
import { INotification } from '../Store/JourneyBuilder.interface'
import { ID } from '~/common.interface'
import JourneyNotificationSidebarWrapper from './JourneyNotificationSidebarWrapper'
import SlideInSidebar from '~/components/SlideInSidebar/SlideInSidebar'

interface IProps {
  isOpen: boolean
  selectedNotification: INotification | null
  close: () => void
  save: (id: ID) => Promise<void>
  cancelCallback: () => void
  disabled: boolean
}

const JourneyNotificationSidebar = ({
  isOpen,
  close,
  selectedNotification,
  save,
  cancelCallback,
  disabled
}: IProps): React.ReactElement | null => {
  function onCancelClick(): void {
    if (!disabled) {
      cancelCallback()
    }
    close()
  }

  return (
    <SlideInSidebar
      isOpen={isOpen}
      close={onCancelClick}
      size="wide"
      className={cn({
        [styles.sidebarEmailActive]:
          selectedNotification?.type === CampaignType.EMAIL
      })}>
      {isOpen && (
        <div className="h-100 position-relative">
          <JourneyNotificationSidebarWrapper
            disabled={disabled}
            cancelCallback={cancelCallback}
            selectedNotification={selectedNotification}
            close={close}
            save={save}
          />
        </div>
      )}
    </SlideInSidebar>
  )
}

export default withStore(JourneyNotificationSidebar)
