import React from 'react'
import geofencesIcon from './geofencesIcon.svg'
import GraduationCapIcon from '~/components/icons/graduationCap'
import { NoticeBar, NoticeBarButton, NoticeBarHeader } from '../NoticeBar'

interface IProps {
  moduleName: 'geofences'
}

const BestPracticesBar = ({ moduleName }: IProps): React.ReactElement => {
  function getModuleDetails(): { header: string; icon: string; url: string } {
    switch (moduleName) {
      case 'geofences':
        return {
          header: 'Geofences',
          icon: geofencesIcon,
          url:
            'https://docs.pulsatehq.com/reference/geofence-testing-and-limitations-copy'
        }
      // case 'journeys':
      // return journeysIcon
      default:
        return { header: '', icon: '', url: '' }
    }
  }

  const { header, icon, url } = getModuleDetails()

  return (
    <NoticeBar>
      <NoticeBarHeader
        header={header}
        subHeader="Click button for best practices"
        icon={<img src={icon} alt={header} />}
      />
      <NoticeBarButton url={url}>
        <GraduationCapIcon className="me-2 fill-current" /> Learn more
      </NoticeBarButton>
    </NoticeBar>
  )
}

export default BestPracticesBar
