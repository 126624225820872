import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { Button } from 'reactstrap'
import { useStore } from '~/dataStore'
import lightingBoltIcon from '~/components/icons/lightingBolt.svg'
import HintModal from '~/HintWindow/HintModal'
import helpMedia from './helpMedia.svg'

import styles from './SuggestedCampaignsBtn.scss'

const SuggestedCampaignsBtn = () => {
  const { ui } = useStore()
  const hintRef = useRef()
  useEffect(() => {
    return () => {
      ui.setHintWindowActive(false)
    }
  }, [])

  return (
    <>
      <Button
        innerRef={hintRef}
        color=""
        className={cn(
          styles.btn,
          'd-flex align-items-center btn-outline-fuschia'
        )}
        onClick={() => ui.setHintWindowActive(true)}>
        <div className={cn('d-flex')}>
          <img src={lightingBoltIcon} className={styles.btnIcon} alt="" />
          <div className={cn(styles.btnText)}>
            How to use Suggested Campaigns{' '}
          </div>
        </div>
      </Button>
      <HintModal
        elem={hintRef}
        mediaFile={helpMedia}
        mediaFileHeight={260}
        link="">
        <h2>Suggested Campaigns</h2>
        <p>
          Take advantage of Pulsate’s expertise. We develop campaigns we know
          your Customers will notice, and tee them up for you when the timing is
          just right.
        </p>
        <p>
          For tips on how to create the right segment and other engagement
          inspiration check out On The Pulse.
        </p>
      </HintModal>
    </>
  )
}

export default SuggestedCampaignsBtn
