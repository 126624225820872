import React, { useMemo, useCallback, ChangeEvent, useRef } from 'react'
import cn from 'classnames'
import pluralize from 'pluralize'
import { InputType } from 'reactstrap/es/Input'
import { uniqueId } from 'lodash'
import Input from '~/components/forms/Input'
import Select from '~/components/forms/Select'
import blockNegativeVal from '~/utils/blockNegativeVal'
import { TimeUnit } from '~/common.interface'

interface IProps {
  initialTimeValue: number | string | undefined
  initialTimeFrame: TimeUnit
  units?: string[]
  onTimeValueChange: (e: ChangeEvent<HTMLInputElement>) => void
  onTimeValueBlur?: () => void
  onTimeFrameChange: (value: TimeUnit) => void
  timeValueInvalid?: boolean
  timeValueError?: string
  timeFrameInvalid?: boolean
  timeFrameError?: string
  timeLabel?: string | React.ReactElement
  unitLabel?: string | React.ReactElement
  inputType: InputType
  className?: string
  disabled?: boolean
  max?: number
}

const TimeFrameForm = ({
  initialTimeValue,
  initialTimeFrame,
  units = ['minutes', 'hours', 'days', 'weeks', 'months', 'years'],
  onTimeValueChange,
  onTimeValueBlur,
  onTimeFrameChange,
  timeValueInvalid,
  timeValueError,
  timeFrameInvalid,
  timeFrameError,
  timeLabel,
  unitLabel,
  inputType,
  className,
  disabled,
  max
}: IProps): React.ReactElement => {
  const timeValueInputId = useRef(uniqueId('time-value-input-'))
  const timeFrameSelectId = useRef(uniqueId('time-frame-select-'))
  const options = useMemo(() => {
    return units.map((unit) => ({
      name: pluralize(unit, parseInt(initialTimeValue, 10)),
      value: unit
    }))
  }, [units, initialTimeValue])

  const internalTimeValueOnChange = useCallback(
    (timeValue) => {
      onTimeValueChange(timeValue)
    },
    [onTimeValueChange]
  )

  const internalTimeFrameOnChange = useCallback(
    (timeFrame) => {
      onTimeFrameChange(timeFrame)
      if (onTimeValueBlur) onTimeValueBlur()
    },
    [onTimeFrameChange]
  )

  return (
    <div className={cn(className, 'd-flex align-items-center')}>
      <label className="mb-0" htmlFor={timeValueInputId.current}>
        {timeLabel}
      </label>
      <Input
        disabled={disabled}
        value={initialTimeValue}
        type={inputType}
        id={timeValueInputId.current}
        onChange={internalTimeValueOnChange}
        onKeyDown={blockNegativeVal}
        className={cn(
          { 'ms-3': timeLabel },
          { 'form-group--error': timeValueInvalid },
          'mb-0 form-control-number center text-center'
        )}
        errorTooltip={timeValueError}
        step="1"
        onBlur={onTimeValueBlur}
        min={1}
        max={max}
      />
      <Select
        errorTooltip={timeFrameError}
        invalid={timeFrameInvalid}
        id={timeFrameSelectId.current}
        disabled={disabled}
        selectedOption={pluralize(
          initialTimeFrame,
          parseInt(initialTimeValue, 10)
        )}
        wrapperClassName="mx-3"
        options={options}
        onSelect={(value) => internalTimeFrameOnChange(value)}
      />
      {unitLabel}
    </div>
  )
}

export default TimeFrameForm
