import React from 'react'
import { Col, Row } from 'reactstrap'
import StatCard from '~/components/reports/StatCard'
import GoalTable from './GoalTable'
import { IGoalsPerformanceRow } from './Model/Report.interface'

interface IProps {
  goalsPerformance: {
    primary: IGoalsPerformanceRow[]
    secondary: IGoalsPerformanceRow[]
  }
}

export default React.memo(function GoalsPerformance({
  goalsPerformance
}: IProps): React.ReactElement {
  return (
    <StatCard title="Goals Performance">
      <Row className="counter">
        <Col lg="6">
          <GoalTable title="Primary" rows={goalsPerformance.primary} />
        </Col>
        <Col lg="6">
          <GoalTable title="Secondary" rows={goalsPerformance.secondary} />
        </Col>
      </Row>
    </StatCard>
  )
})
