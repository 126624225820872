import { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import cn from 'classnames'
import SeparatorLine from '~/components/SeparatorLine'
import { withStore } from '~/dataStore'
import NotificaitonGoals from '../NotificaitonGoals'
import Goals from './Goals'
import styles from './Goals.scss'

interface IProps {
  actions: string[]
  goals: NotificaitonGoals
  emptyListText: string
}

function GoalList(props: IProps): React.ReactElement {
  const { actions, goals, emptyListText } = props

  return (
    <div>
      {actions.length > 0 && (
        <div className="d-flex align-items-center text-blue-gray ">
          <div className="flex-1-1">Link</div>
          <Row className="flex-1-1">
            <Col>Primary Goal</Col>
            <Col>Secondary Goal</Col>
          </Row>
        </div>
      )}
      <div className={cn(styles.goalList)}>
        {actions.length === 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <span className={cn(styles.goalReturnIcon, 'me-3')} />
            <p className="m-0 text-blue-gray text-16">{emptyListText}</p>
          </div>
        )}

        {actions.map((action, index) => (
          <Fragment key={action}>
            {index > 0 && <SeparatorLine spaceBottom={2} spaceTop={2} />}
            <div className="d-flex align-items-center">
              <div className="flex-1-1 truncate pe-2" title={action}>
                {action}
              </div>
              <Goals
                listView
                checkedGoalType={goals.isChecked({
                  sourceType: 'url',
                  reference: action
                })}
                reference={action}
                sourceType="url"
                setGoal={goals.setGoal}
                selected={goals.selectedGoalTypes}
              />
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default withStore(GoalList)
