import { Collapse } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import SelectWithSearch from '~/components/forms/SelectWithSearch'
import TimeFrameForm from '~/components/forms/TimeFrameForm'

import { GOALS_TIME_UNITS } from '~/pages/CampaignBuilder/Email/consts'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  timeFramePanelActive: boolean
  events: IOption<string>[]
}

const SecondaryGoalForm = ({
  events,
  timeFramePanelActive
}: IProps): React.ReactElement | null => {
  const {
    campaign: {
      isSended,
      setUp: {
        secondary,
        setSecondaryEventName,
        setSecondaryExpiryTimeUnit,
        setSecondaryExpiryTimeValue,
        setSearchQuery,
        validateSecondaryGoal,
        validateSecondaryGoalTime
      }
    }
  } = useStore()

  if (!secondary) {
    return null
  }

  return (
    <>
      <SelectWithSearch
        placeholder="Select a secondary conversion goal"
        selectPlaceholder="Search for an event"
        value={secondary.eventName.value.value}
        options={events}
        onChange={setSecondaryEventName}
        onBlur={validateSecondaryGoal}
        onInputChange={setSearchQuery}
        invalid={!secondary.eventName.isValid}
        disabled={isSended}
      />
      <Collapse
        isOpen={!!secondary.eventName.value.value && timeFramePanelActive}
        className="mt-3">
        <TimeFrameForm
          initialTimeFrame={secondary.expiryTimeUnit.value}
          initialTimeValue={secondary.expiryTimeValue.value || ''}
          onTimeValueChange={setSecondaryExpiryTimeValue}
          onTimeValueBlur={validateSecondaryGoalTime}
          onTimeFrameChange={setSecondaryExpiryTimeUnit}
          timeValueInvalid={!secondary.expiryTimeValue.isValid}
          inputType="number"
          timeLabel={<span className="o-50 text-dark">Within</span>}
          unitLabel={
            <span className="o-50 text-dark">of receiving campaign</span>
          }
          units={GOALS_TIME_UNITS}
          max={secondary.expiryTimeUnit.value === 'hours' ? 72 : undefined}
        />
      </Collapse>
    </>
  )
}

export default withStore(SecondaryGoalForm)
