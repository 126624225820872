import pluralize from 'pluralize'
import numberToStringRepresentation from '~/utils/number'

export default function SegmentReach({
  min,
  max
}: {
  min: number
  max: number
}): React.ReactElement {
  if (min === max) {
    return (
      <>
        <strong>{numberToStringRepresentation(min)}</strong>
        <br />
        <span className="o-50">{pluralize('user', min)}</span>
      </>
    )
  }

  return (
    <>
      <strong>
        {numberToStringRepresentation(min)} -{' '}
        {numberToStringRepresentation(max)}
      </strong>
      <br />
      <span className="o-50">users</span>
    </>
  )
}
