import { Handle, Position } from 'reactflow'
import cn from 'classnames'
import NodeIcon from './NodeIcon'
import {
  NodeType,
  NodeWithData
} from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'

export default function Redirect({
  id,
  data
}: NodeWithData): React.ReactElement {
  return (
    <div
      id={id}
      className={cn('flow-node flow-node--redirect', {
        'flow-node--filled': data.filled,
        'flow-node--error': !data.block.isValid
      })}>
      <Handle type="target" position={Position.Top} className="custom-handle" />
      <Handle
        type="source"
        position={Position.Bottom}
        className="custom-handle"
        id="bottom"
      />
      <div className="flow-node__hover">
        <div className="flow-node__icon-wrapper">
          <NodeIcon type={NodeType.REDIRECT} />
        </div>
      </div>
    </div>
  )
}
