import React from 'react'

interface IProps {
  name: string
  last?: boolean
  icon: any
  triggerLabel?: string
  outlineBubble?: boolean
  withCount?: boolean
  collapsed?: boolean
  grouped?: boolean
}

const SingleTargetingOptionPreview = ({
  name,
  last,
  icon,
  triggerLabel,
  outlineBubble,
  withCount,
  collapsed,
  grouped
}: IProps): React.ReactElement => (
  <>
    <div
      className={`targeting-box ${grouped ? 'targeting-box--grouped' : ''}
      ${
        collapsed ? 'targeting-box--collapsed' : ''
      } d-flex justify-content-center align-items-center`}>
      <img alt="" src={icon} className="me-3 targeting-box__icon" />
      <div>
        {triggerLabel && (
          <span className="targeting-box__trigger">{triggerLabel}</span>
        )}
        <p className="m-0 targeting-box__name">{name}</p>
      </div>
      {withCount && (
        <div className="user-count-marker">
          <div className="dot">&#9679;</div>
        </div>
      )}
    </div>

    {last || (
      <div
        className={`bubble bubble--mini ${
          outlineBubble ? 'bubble--outline' : ''
        }`}>
        OR
      </div>
    )}
  </>
)

export default SingleTargetingOptionPreview
