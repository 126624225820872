import { Dictionary, mapValues } from 'lodash'
import { action, makeObservable, runInAction } from 'mobx'

import { getGeofences } from '~/api/geofences'
import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'
import { getGroups } from '~/api/groups'
import { Trigger } from '../EmailBuilder.interface'
import Geofence from './Geofence.model'
import Target from './Target'

export default class Geofences extends Target<Geofence> {
  targetName = TARGETS.GEOFENCES

  constructor() {
    super()

    makeObservable(this, {
      fillGeofences: action.bound,
      selectGeofence: action.bound,
      fetchGeofences: action.bound,
      fetchGroups: action.bound,
      setBatchTriger: action.bound,
      validateDwellTime: action.bound
    })
  }

  fillGeofences(
    geofenceEvents: Dictionary<Trigger | null | undefined>,
    geofenceDwellTimes: Dictionary<string | number | undefined>
  ): boolean {
    if (!geofenceEvents) {
      return false
    }
    Object.keys(geofenceEvents).forEach((selectedGeofence) => {
      const id = selectedGeofence
      const geofence = this.collection.find(
        (geofenceItem) => id === geofenceItem.id
      )
      if (geofence) {
        geofence.trigger = geofenceEvents[id]
        if (geofence.trigger === Trigger.DWELL) {
          geofence.dwellTime.setValue(geofenceDwellTimes[id])
        }
        this.selected.set(geofence.id, geofence)
        this.isActive = true
      }
    })

    return this.isActive
  }

  selectGeofence(
    geofence: Geofence,
    isSelected: boolean,
    trigger: Trigger = Trigger.ENTER
  ): void {
    if (!isSelected) {
      geofence.setTrigger(null)
      this.selected.delete(geofence.id)
    } else {
      geofence.setTrigger(trigger)
      this.selected.set(geofence.id, geofence)
    }
  }

  async fetchGeofences(appId: string, reset = false): Promise<void> {
    const currentGeofences = reset ? [] : this.collection
    this.page = reset ? 1 : this.page + 1

    const {
      data: geofences,
      metadata: { totalPages, dataCount }
    } = await getGeofences(
      appId,
      this.page,
      this.searchQuery,
      this.selectedGroups
    )

    runInAction(() => {
      this.hasMore = this.page < totalPages

      this.collection = currentGeofences.concat(
        geofences.map(
          (geofence) =>
            this.selected.get(geofence.id) ||
            new Geofence(geofence, this.selectGeofence)
        )
      )
      if (!this.searchQuery && !this.selectedGroups.length) {
        this.totalCount = dataCount
      }
    })
  }

  async fetchGroups(appId: string): Promise<void> {
    const groups = await getGroups(appId, 'geofences')
    runInAction(() => {
      this.groups = groups.data.map((g) => ({ ...g, value: g.id }))
    })
  }

  public setBatchTriger(trigger: Trigger, dwellTime?: number): void {
    this.selectedList.forEach((geofence) => {
      geofence.setTrigger(trigger)
      if (typeof dwellTime !== 'undefined') {
        geofence.setDwellTime(dwellTime)
      }
    })
  }

  public validateDwellTime(): boolean {
    let isValid = true
    this.selectedList.forEach((geofence) => {
      if (geofence.isDwellTrigger) {
        geofence.validateDwellTime()
        if (!geofence.dwellTime.isValid) isValid = false
      }
    })

    return isValid
  }

  public getGeofencesEvents(): Dictionary<Trigger | null | undefined> {
    return this.isActive
      ? mapValues(Object.fromEntries(this.selected), 'trigger')
      : {}
  }

  public getGeofenceDwellingTimes(): Dictionary<string | number | undefined> {
    return this.isActive
      ? mapValues(Object.fromEntries(this.selected), (geofence) =>
          geofence.trigger === Trigger.DWELL
            ? geofence.dwellTime.value
            : undefined
        )
      : {}
  }
}
