import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from 'reactstrap'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import pluralize from 'pluralize'
import pushSectionLogo from './push-section-logo.png'
import Input from '~/components/forms/Input'
import ToggleSwitch from '~/components/forms/ToggleSwitch'
import blockNegativeVal from '~/utils/blockNegativeVal'
import SeparatorLine from '~/components/SeparatorLine'
import { updatePushSettings } from '~/api/appSettings'
import { useStore, withStore } from '~/dataStore'
import {
  getFormServerSideErrors,
  hookFormValidation,
  showGeneralError
} from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'

import styles from './PushSection.scss'

interface IFormValues {
  pushNotificationTtl: number | string
  addLocationParamsToPushUrls: boolean
}

const ERRORS_DICT = {
  pushNotificationTtl: {
    'Must be greater than 0 or empty': hookFormValidation.minOne.min.message,
    'Must be a Integer': 'Value must be a whole number'
  }
}

const PushSection = () => {
  const {
    app: {
      appDetails,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { isSubmitting, errors }
  } = useForm<IFormValues>({
    values: {
      pushNotificationTtl: appDetails?.pushNotificationTtl,
      addLocationParamsToPushUrls: appDetails?.addLocationParamsToPushUrls
    }
  })

  const { appId } = useParams<{ appId: string }>()

  const onSubmit = async (data: IFormValues) => {
    try {
      await updatePushSettings(appId, {
        settingAttributes: {
          addLocationParamsToPushUrls: `${data.addLocationParamsToPushUrls}`,
          pushNotificationTtl: data.pushNotificationTtl
            ? Number(data.pushNotificationTtl)
            : null
        }
      })
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
    } catch (error) {
      if (error.body.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="ms-auto mt-5">
        <div className="bg-body-main rounded p-4">
          <h3 className="text-white mb-0">Push</h3>
        </div>
      </div>
      <div className="mt-4">
        <Card>
          <CardHeader>
            <CardTitle tag="h3" className="mb-0 mt-2">
              Push Life Time
            </CardTitle>
          </CardHeader>
          <CardBody className="pt-0 pb-4 mt-4">
            <Row>
              <Col xs={7}>
                <div className="d-flex align-items-center">
                  <p>Push will retry to be delivered for</p>
                  <Input
                    min="1"
                    name="pushNotificationTtl"
                    register={register}
                    type="number"
                    validation={hookFormValidation.minOne}
                    className={cn('mx-3 mb-3', styles.pushVisibilitySwitch, {
                      'form-group--error': errors.pushNotificationTtl
                    })}
                    onKeyDown={blockNegativeVal}
                    errorTooltip={errors.pushNotificationTtl?.message}
                    step="1"
                  />
                  <p>
                    {pluralize(
                      'day',
                      parseInt(watch('pushNotificationTtl'), 10)
                    )}
                  </p>
                </div>
                <SeparatorLine spaceTop="1" />
                <div className="d-flex align-items-center">
                  <Controller
                    control={control}
                    name="addLocationParamsToPushUrls"
                    register={register}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <ToggleSwitch
                          checked={value || false}
                          onChange={onChange}
                          controlId="push-location-switch"
                        />
                        <label
                          htmlFor="push-location-switch"
                          className="mb-0 ms-3">
                          add location parameters to push notification URLs
                        </label>
                      </>
                    )}
                  />
                </div>
              </Col>
              <Col
                xs={5}
                className="d-flex align-items-center justify-content-center">
                <img src={pushSectionLogo} width={135} alt="Push settings" />
              </Col>
            </Row>
            <div className="mt-4 pt-2">
              <Button
                disabled={isSubmitting || isDemoAdmin}
                color="primary"
                type="submit"
                className="d-block">
                Save Changes
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-1"
                  />
                )}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </form>
  )
}

export default withStore(PushSection)
