import cn from 'classnames'
import { startCase } from 'lodash'
import { PropsWithChildren, useState } from 'react'
import CustomDropdown from '~/components/CustomDropdown'
import {
  JointType,
  RuleDTO,
  RuleWithCategoriesDTO
} from '../SegmentBuilder.interface'
import RuleTypeOption from './RuleTypeOption'

import styles from '../SegmentBuilder.scss'

interface IProps {
  rules: RuleWithCategoriesDTO
  onRuleSelection: (rule: RuleDTO, jointType: JointType | null) => void
  withJoinType?: boolean
}

export default function RuleTypeDropdown({
  rules,
  onRuleSelection,
  children,
  withJoinType = false
}: PropsWithChildren<IProps>): React.ReactElement {
  const [joinType, setJointType] = useState<JointType | null>(null)

  function selectRule(rule: RuleDTO, join: JointType | null): void {
    onRuleSelection(rule, join)
  }

  function getRuleTitle(key: RuleDTO['key']): string {
    if (key === 'opt_out') {
      return `Push ${startCase(key)}`
    }
    if (key === 'number_of_event_clicks') {
      return 'Number Of Event Occurrences'
    }
    return key.includes('->') ? key : startCase(key)
  }

  function getOptions(): { name: (...args: any) => void; isCustom: boolean }[] {
    if (withJoinType && !joinType) {
      return (['And', 'Or'] as const).map((rule) => ({
        // eslint-disable-next-line react/display-name
        name: () => {
          return (
            <RuleTypeOption title={rule} onClick={() => setJointType(rule)} />
          )
        },
        isCustom: true
      }))
    }

    return Object.keys(rules)
      .sort((a) => (a === 'allUsers' ? -1 : 0))
      .map((rule) => {
        if (rule === 'location') {
          return {
            name: 'Location',
            action: () => selectRule({ key: rule, type: 'string' }, joinType)
          }
        }

        if (rule === 'allUsers') {
          return {
            name: 'All Users',
            action: () =>
              selectRule({ key: 'all_users', type: 'empty' }, joinType)
          }
        }

        return {
          name: startCase(rule),
          options: rules[rule].map((r) => ({
            name: getRuleTitle(r.key),
            action: () => selectRule(r, joinType)
          }))
        }
      })
  }

  return (
    // * extra div because of flex container, instead dropdown takes whole width of parent
    <div>
      <CustomDropdown
        toggleAsButton
        dropdownToggleClassname={cn(styles.ruleDropdown, {
          [styles.ruleDropdownJoin]: withJoinType,
          'my-3': withJoinType
        })}
        dropdownToggle={
          <div className={cn('pe-0 dropdown-toggle text-left')}>{children}</div>
        }
        options={getOptions()}
        onClose={() => {
          setJointType(null)
        }}
      />
    </div>
  )
}
