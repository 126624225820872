import { getParams } from '~/routes'
import Deliver from '../emailBuilder/Deliver/Deliver'
import InAppNotification from '../../pages/Campaign/Notification/InAppNotification/Model/InAppNotification'
import InAppSmall from '../../pages/Campaign/Notification/InAppNotification/Model/InAppSmall.model'
import Review from '../emailBuilder/Review'
import SetUp from '../emailBuilder/SetUp'
import StepStore from '../emailBuilder/StepStore'
import Targeting from '../emailBuilder/Targeting/Targeting'
import { ActionTypes, ICampaignModel } from './Campaign.interface'

type Store = {
  store: StepStore
  name: string
  displayName: string
}
export default class InAppCampaign {
  static createStepStores(campaign: ICampaignModel): {
    store: StepStore
    name: string
    displayName: string
    position: number
  }[] {
    const targeting = new Targeting(campaign)

    return [
      {
        name: 'setUp',
        displayName: 'Set Up',
        store: new SetUp(campaign),
        position: 1
      },
      {
        ...InAppCampaign.initInAppStore(campaign),
        position: 2
      },
      {
        name: 'targeting',
        displayName: 'Target',
        store: targeting,
        position: 3
      },
      {
        name: 'deliver',
        displayName: 'Deliver',
        store: new Deliver(campaign, targeting),
        position: 4
      },
      {
        name: 'review',
        displayName: 'Review',
        store: new Review(),
        position: 5
      }
    ]
  }

  static initInAppStore(campaign: ICampaignModel): Store {
    const inApp = new InAppNotification({
      appId: campaign.rootStore?.app.appDetails.id,
      campaign
    })

    const params = getParams('campaignBuilder')
    if (params?.size) {
      inApp.setSize(params.size)
    }

    if (params?.campaignType === 'card') {
      if (inApp.notification instanceof InAppSmall) {
        inApp.notification.type.setValue(undefined)
        inApp.notification.cta.setType(ActionTypes.CARD)
      } else {
        inApp.notification.actionButtons.buttons[0]?.setType(ActionTypes.CARD)
      }
    }

    return {
      name: 'inApp',
      displayName: 'In App',
      store: inApp
    }
  }
}
