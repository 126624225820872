import React, { useEffect, useState } from 'react'
import SearchInput from '~/components/forms/SearchInput'
import MultiSelect from '~/components/forms/MultiSelect'
import Table from '~/components/Table/TableNew'
import { useStore, withStore } from '~/dataStore'
import CustomDropdown from '~/components/CustomDropdown'

import { listHeader } from './style.scss'

interface IProps {
  searchPlaceholder: string
  searchQuery: string
  setSearchQuery: (value: string) => void
  groups?: any[]
  selectedGroups?: string[]
  setGroups?: (options: Array<string>, value?: string) => void
  columns: {
    fieldName: string
    displayName: string
  }[]
  rows: any[]
  selectedRows: Map<string, any>
  selectRow: (row: any, selected: boolean) => void
  onLoadMore?: any
  hasMore?: boolean
  totalCount: number
  batchActions?: {
    name: string | React.ReactElement | ((...args: any) => void)
    action?: () => void
    isCustom?: boolean
  }[]
  invalid: boolean
  disabled?: boolean
  contentScrollableBlue?: boolean
  fetchResources: () => void
}

function TargetToggle({
  searchPlaceholder,
  searchQuery,
  setSearchQuery,
  groups,
  selectedGroups,
  setGroups,
  columns,
  hasMore,
  onLoadMore,
  rows,
  selectedRows,
  selectRow,
  totalCount,
  batchActions,
  invalid,
  disabled,
  contentScrollableBlue,
  fetchResources
}: IProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false)

  const onFetchResources = async () => {
    try {
      setIsLoading(true)
      await fetchResources()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    onFetchResources()
  }, [searchQuery, selectedGroups])

  return (
    <>
      <div className={listHeader}>
        <div className="d-flex flex-grow-1">
          {!disabled && batchActions && selectedRows.size > 0 && (
            <CustomDropdown options={batchActions} className="bg-white" />
          )}
          <SearchInput
            placeholder={searchPlaceholder}
            initialValue={searchQuery}
            onChange={setSearchQuery}
          />
        </div>
        {groups && (
          <MultiSelect
            className="ms-3 bg-white"
            title="Groups"
            options={groups}
            selectedOptions={selectedGroups}
            onSelect={setGroups}
            right
          />
        )}
      </div>
      <Table
        disabled={disabled}
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        selectedRows={selectedRows}
        onSelect={selectRow}
        hasMore={hasMore}
        onLoadMore={onLoadMore}
        contentScrollable
        skeleton={{ count: 5, height: 20, className: 'my-0' }}
        contentScrollableBlue={contentScrollableBlue}
        className="target-table table--small table--overflow"
        invalid={invalid}
      />

      <p className="px-20p m-0">
        {selectedRows.size} selected of {totalCount}
      </p>
    </>
  )
}

export default withStore(TargetToggle)
