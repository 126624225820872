import React, { useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import cn from 'classnames'

import { useParams } from 'react-router-dom'
import Img from '~/components/Img'
import setUpCampaignInfo from '~/assets/img/campaign-builder/Blank_canvas_for_Set_up.svg'
import Header from '~/components/Header'
import Input from '~/components/forms/Input/Input'
import HeaderTitle from '~/components/HeaderTitle'
import { withStore, useStore } from '~/dataStore'

import ControlGroupForm from './ControlGroupForm'
import GoalForms from './GoalForms/GoalForms'
import { getMainCampaignType } from '~/pages/Campaign/Notification/Notification.service'

import { stepMedia } from './style.scss'
import SeparatorLine from '../../../components/SeparatorLine'

function SetupStep() {
  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      }
    },
    campaign: {
      campaignType,
      setUp: {
        name,
        fetchEvents,
        searchQuery,
        validateName,
        setControlGroupActive
      }
    }
  } = useStore()

  const { appId } = useParams<{ appId: string }>()

  useEffect(() => {
    fetchEvents(appId, getMainCampaignType(campaignType, 'campaignBuilder'))
  }, [appId, fetchEvents, searchQuery])

  useEffect(() => {
    const cg = sessionStorage.getItem('cg')
    if (cg) {
      setTimeout(() => {
        setControlGroupActive(true)
      }, 450)
      sessionStorage.removeItem('cg')
    }
  }, [])

  return (
    <Row>
      <Col
        md={{ size: campaignsGoals ? 4 : 3, offset: campaignsGoals ? 2 : 4 }}>
        <Header>
          <HeaderTitle>Set up Campaign</HeaderTitle>
        </Header>
        <Input
          label="Campaign Name"
          name="campaignName"
          type="text"
          placeholder="Enter campaign name"
          value={name.value}
          onChange={(e) => {
            name.setValue(e.target.value)
          }}
          onBlur={validateName}
          invalid={!name.isValid}
        />
        {campaignsGoals && (
          <>
            <GoalForms />
            <SeparatorLine />
            <ControlGroupForm />
          </>
        )}
      </Col>
      <Col
        md={{ size: campaignsGoals ? 5 : 3, offset: campaignsGoals ? 0 : 4 }}>
        <Img
          src={setUpCampaignInfo}
          className={cn(stepMedia, 'img-fluid', {
            'mt-5': !campaignsGoals
          })}
        />
      </Col>
    </Row>
  )
}

export default withStore(SetupStep)
