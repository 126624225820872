import React from 'react'
import { withStore } from '~/dataStore'

interface IProps {
  row: {
    isEnterTrigger: boolean
    isExitTrigger: boolean
    isDwellTrigger?: boolean
    toggleEntering: () => void
    toggleLeaving: () => void
    toggleDwelling?: () => void
  }
  disabled?: boolean
}

function Triggers({ row, disabled }: IProps): React.ReactElement {
  return (
    <div className="d-flex justify-content-end">
      <button
        disabled={disabled}
        type="button"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          row.toggleEntering()
        }} /* eslint-disable-line */
        className="btn shadow-none p-0">
        <span
          className={`icon icon--enter ${
            !row.isEnterTrigger ? 'icon--inactive' : ''
          }`}
          title="Enter"
        />
      </button>
      <button
        disabled={disabled}
        type="button"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          row.toggleLeaving()
        }} /* eslint-disable-line */
        className="btn shadow-none p-0">
        <span
          className={`icon icon--exit ms-3 ${
            !row.isExitTrigger ? 'icon--inactive' : ''
          }`}
          title="Exit"
        />
      </button>
      {row.toggleDwelling && (
        <button
          disabled={disabled}
          type="button"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            row.toggleDwelling()
          }} /* eslint-disable-line */
          className="btn shadow-none p-0">
          <span
            className={`icon icon--dwell ms-3 ${
              !row.isDwellTrigger ? 'icon--inactive' : ''
            }`}
            title="Dwelling"
          />
        </button>
      )}
    </div>
  )
}

export default withStore(Triggers)
