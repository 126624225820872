import React, { useEffect } from 'react'

import { withStore, useStore } from '~/dataStore'

import Triggers from '../Triggers'

import Geofence from './Geofence'

import TargetToggle from '../TargetToggle/TargetToggle'
import { Trigger } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import DwellTime from '../DwellTime'
import Targeting from '~/dataStore/emailBuilder/Targeting/Targeting'

const columns = [
  {
    fieldName: 'name',
    displayName: 'Geofence name',
    classes: 'text-truncate',
    render: Geofence
  },
  {
    fieldName: 'triggers',
    displayName: 'Set trigger',
    render: Triggers,
    classes: 'text-end'
  }
]

interface IProps {
  isSended: boolean
  targeting: Targeting
  contentScrollableBlue?: boolean
  onSelectCallback?: (selected: boolean) => void
}

function Geofences({
  isSended,
  targeting,
  contentScrollableBlue,
  onSelectCallback
}: IProps) {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()

  const {
    validateTargetingChoosenOptions,
    validateTargetingDwellTime,
    targetingDwellTime: { isValid: dwellTimeIsValid },
    geofences: {
      collection,
      fetchGeofences,
      selected,
      selectGeofence,
      hasMore,
      groups,
      fetchGroups,
      selectedGroups,
      setSelectedGroups,
      searchQuery,
      totalCount,
      setSearchQuery,
      setBatchTriger,
      isValid
    }
  } = targeting

  useEffect(() => {
    fetchGroups(appId)
  }, [fetchGroups, appId])

  const batchActions = [
    {
      name: 'Set to "On entry"',
      action: setBatchTriger.bind(null, Trigger.ENTER)
    },
    {
      name: 'Set to "On exit"',
      action: setBatchTriger.bind(null, Trigger.EXIT)
    },
    {
      name(dropdownToggle: () => void): React.ReactElement {
        return (
          <DwellTime
            isValid={dwellTimeIsValid}
            action={(value: number) => {
              setBatchTriger(Trigger.DWELL, value)
              if (validateTargetingDwellTime()) {
                dropdownToggle()
              }
            }}
          />
        )
      },
      isCustom: true
    }
  ]

  return (
    <TargetToggle
      fetchResources={() => fetchGeofences(appId, true)}
      disabled={isSended}
      searchPlaceholder="Search for a geofence"
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      groups={groups}
      selectedGroups={selectedGroups}
      setGroups={setSelectedGroups}
      columns={columns}
      rows={collection}
      selectedRows={selected}
      selectRow={(row: any, selected: boolean) => {
        selectGeofence(row, selected)
        onSelectCallback?.(selected)
        if (selected) validateTargetingChoosenOptions()
      }}
      hasMore={hasMore}
      onLoadMore={fetchGeofences}
      totalCount={totalCount}
      invalid={!isValid}
      batchActions={batchActions}
      contentScrollableBlue={contentScrollableBlue}
    />
  )
}

export default withStore(Geofences)
