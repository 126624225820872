import { IGroup } from '~/dataStore/Groups/Groups.interface'

export type Date = string

export type ID = string

export type TimeUnit = 'minutes' | 'hours' | 'days' | 'weeks'

export type Reveal<T> = { [P in keyof T]: T[P] }

export type PartialFields<T, K extends keyof T> = Reveal<
  Partial<Pick<T, K>> & Omit<T, K>
>

export type BatchUploadErrors =
  | string
  | Array<{
      row: number
      error: Array<Record<string, Array<string>>>
    }>

export enum CustomErrors {
  INVALID = 'Invalid'
}

export type IMetaData = {
  dataCount: number
  page: number
  perPage: number
  totalPages: number
  groups: IGroup[]
}

export type IGeneralBulkActions = {
  addToGroup: string
  delete: string
  stop: string
  hideFromFeed: string
  pause: string
  removeFromGroup: string
}

// ? Do we need this interface?
export interface IRow {
  id: string
  allGroups: IGroup[]
  groups: IGroup[]
  onUpdateRow: (row: IRow) => void
  batchActionUrls?: {
    addToGroup: string
    removeFromGroup: string
  }
}

export enum ADMIN_ROLES {
  MASTER_ADMIN = 'master_admin',
  APP_ADMIN = 'app_admin',
  ACCOUNT_ADMIN = 'account_admin',
  DEMO_ADMIN = 'demo_admin'
}

export type TBadgeVariations =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
