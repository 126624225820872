import React, {
  createContext,
  useState,
  useContext,
  PropsWithChildren
} from 'react'
import { PreviewFieldType } from '~/dataStore/Campaign/Campaign.interface'

interface ICollapseContext {
  opened: string | null | undefined
  setOpened: (section: PreviewFieldType | null) => void
}

export const CollapseContext = createContext<ICollapseContext | null>(null)

function isMappedType(value: any): value is PreviewFieldType {
  return Object.values(PreviewFieldType).includes(value)
}

export function CollapseContextProvider({
  fieldMapper,
  children
}: PropsWithChildren<{
  fieldMapper: Partial<{ [x in PreviewFieldType]: string }>
}>): React.ReactElement {
  const [opened, setOpened] = useState<string | null | undefined>(null)

  function openSection(
    section: PreviewFieldType | string | null | undefined
  ): void {
    let mapped = section
    if (isMappedType(section)) {
      mapped = fieldMapper[section]
    }

    setOpened(mapped)
  }

  return (
    <CollapseContext.Provider
      value={{
        opened,
        setOpened: openSection
      }}>
      {children}
    </CollapseContext.Provider>
  )
}

const useCollapseContext = (): ICollapseContext | null => {
  const context = useContext(CollapseContext)
  if (!context) {
    return null
    // throw new Error('There is no Provider for this context')
  }

  return context
}

export default useCollapseContext
