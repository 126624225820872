import { action, computed, makeObservable, observable } from 'mobx'
import { JourneyErrors } from '~/pages/Journeys/Journeys.interface'
import { parseServerJourneyErrors } from '~/pages/Journeys/Journey.service'
import {
  removeBlock,
  updateBlock
} from '~/pages/Journeys/Connector/Journeys.connector'
import { CustomErrors, ID } from '~/common.interface'
import {
  IBlock,
  JourneyBlock,
  JourneyBlockType,
  NodeWithData,
  Path
} from '../Store/JourneyBuilder.interface'
import Node from './Node.model'

export default abstract class Block<T = unknown> implements IBlock {
  public blockID: ID | undefined = undefined

  public children: Block[] = []

  //* Operational Fields
  public node: Node

  public selected = false

  public currentBlockState: T | undefined = undefined

  public isValid = true

  public errors: string[] = []

  public launchErrors: string[] = []

  public path?: Path = undefined

  private initialized = false

  public abstract get filled(): boolean

  protected abstract getBlockPayload(cache?: boolean): T

  protected abstract fillBlockPayload(data: T): void

  protected abstract getNodeData(): { label: string } & Record<string, unknown>

  public abstract validateBlock(): boolean

  constructor(
    public blockType: JourneyBlockType,
    public parent: IBlock | null
  ) {
    makeObservable<Block<T>, 'setSelected' | 'initialized' | 'setReady'>(this, {
      blockID: observable,
      children: observable,
      childrenNodes: computed,
      parent: observable,
      node: observable,
      selected: observable,
      isValid: observable,
      initialized: observable,
      errors: observable,
      launchErrors: observable,
      path: observable,
      id: computed,
      setParent: action.bound,
      setChildren: action.bound,
      setPath: action.bound,
      setActive: action.bound,
      setSelected: action.bound,
      setServerErrors: action.bound,
      storeCurrentState: action.bound,
      clearCurrentState: action.bound,
      resetError: action.bound,
      fillBlock: action.bound,
      ready: computed,
      setReady: action.bound,
      replaceInParentWith: action.bound
    })

    this.restore = this.restore.bind(this)

    this.node = new Node(blockType)
  }

  public get ready(): boolean {
    return this.initialized
  }

  protected setReady(): void {
    this.initialized = true
  }

  public get id(): ID {
    return this.blockID || this.node.id
  }

  public get childrenNodes(): IBlock[] {
    return this.children
  }

  public setParent(parent: IBlock): void {
    this.parent = parent
  }

  public setChildren(children: IBlock[]): void {
    this.children = children
  }

  public setPath(path: Path): void {
    this.path = path
  }

  public setActive(active: boolean): void {
    this.setSelected(active)

    if (active) {
      this.storeCurrentState()
    } else {
      this.clearCurrentState()
    }
  }

  private setSelected(isSelected: boolean): void {
    this.selected = isSelected
  }

  public storeCurrentState(): void {
    this.currentBlockState = this.getBlockPayload(true)
  }

  public clearCurrentState(): void {
    this.currentBlockState = undefined
  }

  public restore(): void {
    if (this.currentBlockState) {
      this.resetError()
      this.fillBlockPayload(this.currentBlockState)
    }
  }

  public addError(message: string): void {
    this.errors.push(message)
  }

  public resetError(): void {
    this.isValid = true
    this.errors = []
    this.launchErrors = []
  }

  public setServerErrors(errors: JourneyErrors): void {
    let blockErrors
    if (this.blockType === JourneyBlockType.START) {
      blockErrors = errors.errors.journey
    } else {
      blockErrors = errors.errors.blocks?.find(
        (block) => this.blockID === block.id
      )
    }
    if (blockErrors?.errors && Object.keys(blockErrors.errors).length) {
      this.isValid = false
      this.launchErrors = parseServerJourneyErrors(blockErrors.errors)
    }
    this.children.forEach((child) => child.setServerErrors(errors))
  }

  public isActive(): boolean {
    return this.selected
  }

  public getPayload(): JourneyBlock {
    return {
      id: this.blockID,
      type: this.blockType,
      path: this.path,
      childBlocks: this.children.map((child) => child.getPayload()),
      ...this.getBlockPayload()
    }
  }

  public fillBlock(data: JourneyBlock): void {
    this.blockID = data.id
    if ('childBlocks' in data) {
      this.children.forEach((child, index) =>
        child.fillBlock(data.childBlocks[index])
      )
    }

    if ('path' in data && data.path) {
      this.setPath(data.path)
    }

    //* it is important to fill children first, reachable sorts children after they got ID
    this.fillBlockPayload(data)
  }

  public getNode(): NodeWithData {
    this.node.calculatePosition(this.childrenNodes)
    return {
      id: this.id,
      ...this.node.getNode(),
      selected: this.selected,
      data: {
        filled: this.filled,
        block: this,
        ...this.getNodeData()
      }
    }
  }

  public save(appId: ID, journeyId: ID | undefined): Promise<JourneyBlock> {
    this.validateBlock()

    if (!this.isValid) {
      throw new Error(CustomErrors.INVALID)
    }

    if (!journeyId) {
      throw new Error('There is no journey combined to this block')
    }
    if (!this.blockID) {
      throw new Error('Block is not created yet')
    }

    return this.updateBlock(appId, journeyId, this.blockID)
  }

  protected updateBlock(
    appId: ID,
    journeyId: ID,
    blockId: ID
  ): Promise<JourneyBlock> {
    return updateBlock(appId, journeyId, blockId, this.getPayload())
  }

  public replaceInParentWith(block: IBlock): void {
    if (!this.parent) {
      return
    }
    this.parent.setChildren(
      this.parent.children.map((child) => {
        if (child.id === this.id) {
          return block
        }
        return child
      })
    )

    this.replaceOptionInParent(block.blockID)
  }

  private replaceWithFirstChild(): void {
    if (!this.parent) {
      return
    }

    this.parent.setChildren(
      this.parent.children.map((child) => {
        if (child.id === this.id) {
          return this.children[0]
        }
        return child
      })
    )

    this.children.forEach((child) => {
      child.setParent(this.parent)
    })

    this.replaceOptionInParent(this.children[0].blockID)
  }

  private replaceOptionInParent(newId: ID | undefined) {
    if (this.parent?.replaceOptionChildId && this.blockID && newId) {
      this.parent.replaceOptionChildId(this.blockID, newId)
    }
  }

  public removeFromTree(): void {
    if (!this.parent) {
      return
    }

    this.replaceWithFirstChild()
  }

  public async remove(appId: ID, journeyId: ID | undefined): Promise<void> {
    if (!journeyId) {
      throw new Error('There is no journey combined to this block')
    }

    if (!this.blockID) {
      throw new Error('Block is not created yet')
    }

    await removeBlock(appId, journeyId, this.blockID)
  }
}
