import React from 'react'

import { Collapse } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import SingleTargetingOptionPreview from './SingleTargetingOptionPreview'
import { withStore } from '~/dataStore'
import { Trigger } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import SegmentReach from './SegmentReach'

interface IProps {
  collection: {
    id: string
    name: string
    triggerLabel?: string
    trigger?: Trigger | null | undefined
  }[]
  collectionCount: number
  icon: any
  outlineBubble?: boolean
  withCount?: boolean
  reachRange?: { min: number; max: number } | null
  type: 'Segments' | 'Beacons' | 'Geofences' | 'Events'
}

const COLLAPSE_AMOUNT = 5

const TargetingOptionPreview = ({
  collection,
  collectionCount,
  icon,
  outlineBubble,
  withCount,
  reachRange,
  type
}: IProps): React.ReactElement | null => {
  if (!collectionCount) {
    return null
  }

  function getCollapsedLabel(): string | undefined {
    if (type === 'Segments') {
      return undefined
    }
    const set = new Set()

    collection.forEach((element) => element.trigger && set.add(element.trigger))

    if (set.size <= 1) {
      return collection[0].triggerLabel || undefined
    }
    return 'Combination of Triggers'
  }

  return (
    <div className="targeting-box-wrapper">
      <SingleTargetingOptionPreview
        triggerLabel={getCollapsedLabel()}
        name={`(${collectionCount}) ${type} selected`}
        icon={icon}
        withCount={withCount}
        last
        collapsed={collectionCount > COLLAPSE_AMOUNT}
        grouped
      />
      <Collapse
        isOpen={collectionCount <= COLLAPSE_AMOUNT}
        className="targeting-box-collapse">
        {collection.map((element, index) => (
          <SingleTargetingOptionPreview
            key={element.id}
            name={element.name}
            icon={icon}
            triggerLabel={element.triggerLabel}
            outlineBubble={outlineBubble}
            withCount={withCount}
            last={index === COLLAPSE_AMOUNT || index === collectionCount - 1}
          />
        ))}
      </Collapse>
      {withCount && reachRange !== undefined && (
        <div className="user-count">
          <div className="user-count__icon" />
          <div className="txt">
            {reachRange !== null ? (
              <SegmentReach min={reachRange.min} max={reachRange.max} />
            ) : (
              <Skeleton count={6} width={48} />
            )}
          </div>
          <div className="dot">&#9679;</div>
        </div>
      )}
    </div>
  )
}

export default withStore(TargetingOptionPreview)
