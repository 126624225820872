import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { Fade, Button, FormFeedback } from 'reactstrap'
import { pull, filter, isEqual, sortBy } from 'lodash'

import IconDelete from '~/components/icons/IconDelete'
import TimePicker from '~/components/forms/TimePicker'
import SelectDays from '~/components/forms/SelectDays'
import { useStore, withStore } from '~/dataStore'
import {
  DAYS_OPTIONS,
  DAYS_LIST,
  ALL_DAYS_OPTIONS_LIST,
  WEEK_DAYS_LIST
} from '~/pages/CampaignBuilder/Email/consts'
import {
  getTimeNow,
  getTimeNowPlusHour,
  setHours,
  setMinutes
} from '~/utils/utilities'
import { ITimeWindow } from '~/dataStore/emailBuilder/EmailBuilder.interface'

const Schedule = ({
  className,
  disabled
}: {
  className: string
  disabled: boolean
}) => {
  const {
    campaign: {
      deliver: {
        setTimeWindows,
        timeWindows,
        timeWindowsTotal,
        validateTimeWindows
      }
    }
  } = useStore()

  const defaultItem = {
    weekDay: '',
    startAt: getTimeNow(),
    endAt: getTimeNowPlusHour()
  }
  const [scheduleItems, setScheduleItems] = useState<ITimeWindow[]>(
    timeWindowsTotal ? timeWindows.value : [defaultItem]
  )

  useEffect(() => {
    setTimeWindows(scheduleItems)
    validateTimeWindows()
  }, [scheduleItems])

  const updateCurrentSchedule = (
    currentItemValue: string | Date,
    currentItemIndex: number
  ) => {
    if (currentItemValue === DAYS_OPTIONS.ALL_DAYS) {
      setScheduleItems([
        {
          weekDay: DAYS_OPTIONS.ALL_DAYS,
          startAt: scheduleItems[currentItemIndex].startAt,
          endAt: scheduleItems[currentItemIndex].endAt
        }
      ])
    } else if (currentItemValue === DAYS_OPTIONS.MONDAY_FRIDAY) {
      setScheduleItems((items) =>
        filter(items, (item) => !WEEK_DAYS_LIST.includes(item.weekDay))
      )
    }
  }

  const formatToSelectScheme = (array) =>
    array.map((item) => ({ value: item, name: item }))

  const handleInputChange = (
    name: string,
    value: Date | string,
    index: number
  ) => {
    const list = [...scheduleItems]
    const elem = list[index]
    elem[name] = value

    if (
      name === 'startAt' &&
      setHours(elem.startAt, elem.startAt.getHours() + 1) >= elem.endAt
    ) {
      elem.endAt = setHours(elem.startAt, elem.startAt.getHours() + 1)
    }

    setScheduleItems(list)
    updateCurrentSchedule(value, index)
  }

  const handleRemoveClick = (index: number) => {
    const list = [...scheduleItems]
    list.splice(index, 1)
    setScheduleItems(list)
    setTimeWindows(list)
  }

  const handleAddClick = () => {
    setScheduleItems((prevScheduleItems) => [
      ...prevScheduleItems,
      {
        ...defaultItem
      }
    ])
  }

  const filterSchedule = (currentValue: ITimeWindow) => {
    const currentSelectedDay = currentValue.weekDay
    const possibleDays = [...ALL_DAYS_OPTIONS_LIST]

    if (currentSelectedDay === DAYS_OPTIONS.ALL_DAYS) {
      return formatToSelectScheme(possibleDays)
    }

    scheduleItems.forEach((scheduleItem) => {
      const day = scheduleItem.weekDay
      if (DAYS_LIST.includes(day)) {
        pull(possibleDays, day)
      } else if (
        day === DAYS_OPTIONS.MONDAY_FRIDAY &&
        currentSelectedDay !== DAYS_OPTIONS.MONDAY_FRIDAY
      ) {
        pull(
          possibleDays,
          ...Object.values(WEEK_DAYS_LIST),
          DAYS_OPTIONS.MONDAY_FRIDAY
        )
      }
    })

    return formatToSelectScheme(
      filter(
        ALL_DAYS_OPTIONS_LIST,
        (day) => possibleDays.includes(day) || currentSelectedDay === day
      )
    )
  }

  const addButton = () => {
    const scheduleDays = scheduleItems.map((selectedDay) => selectedDay.weekDay)

    const hasEveryDay =
      scheduleDays.includes(DAYS_OPTIONS.MONDAY_FRIDAY) &&
      scheduleDays.includes(DAYS_OPTIONS.SATURDAY) &&
      scheduleDays.includes(DAYS_OPTIONS.SUNDAY)

    if (
      disabled ||
      scheduleDays.includes(DAYS_OPTIONS.ALL_DAYS) ||
      hasEveryDay ||
      isEqual(sortBy(DAYS_LIST), sortBy(scheduleDays))
    ) {
      return null
    }

    return (
      <div className="pt-3">
        <Button
          color="primary"
          className="bg-white border-input text-dark fw-normal px-3 btn-hover-shadow"
          onClick={handleAddClick}>
          +<span className="ms-1">Add</span>
        </Button>
      </div>
    )
  }

  const removeButton = (index: number) => {
    if (scheduleItems.length === 1) return null
    return (
      <button
        type="button"
        className="ms-3 cursor-pointer border-0"
        onClick={() => handleRemoveClick(index)}>
        <IconDelete />
      </button>
    )
  }

  return (
    <div>
      {scheduleItems.map((scheduleItem, i) => {
        return (
          <Fade key={i}>
            <div className={cn(className, 'd-flex align-items-center mt-2')}>
              <SelectDays
                disabled={disabled}
                selected={scheduleItem.weekDay}
                options={filterSchedule(scheduleItem)}
                onChange={(value) => handleInputChange('weekDay', value, i)}
              />
              <div className="d-flex align-items-center">
                <span className="o-50 text-dark me-2">Start:</span>
                <TimePicker
                  disabled={disabled}
                  value={scheduleItem.startAt}
                  minTime={setHours(setMinutes(new Date(), 0), 0)}
                  maxTime={setHours(setMinutes(new Date(), 55), 22)}
                  onChange={(value) => handleInputChange('startAt', value, i)}
                />
              </div>
              <div className="ms-3 d-flex align-items-center">
                <span className="o-50 text-dark me-2">End:</span>
                <TimePicker
                  disabled={disabled}
                  minTime={setHours(
                    scheduleItem.startAt,
                    scheduleItem.startAt.getHours() + 1
                  )}
                  maxTime={setHours(setMinutes(new Date(), 55), 23)}
                  value={scheduleItem.endAt}
                  onChange={(value) => handleInputChange('endAt', value, i)}
                />
              </div>
              {removeButton(i)}
            </div>
          </Fade>
        )
      })}
      {addButton()}
      <FormFeedback tooltip>Sweet! that name is available</FormFeedback>
    </div>
  )
}

export default withStore(Schedule)
