import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import Deliver from '../emailBuilder/Deliver/Deliver'
import Review from '../emailBuilder/Review'
import SetUp from '../emailBuilder/SetUp'
import StepStore from '../emailBuilder/StepStore'
import Targeting from '../emailBuilder/Targeting/Targeting'
import { ICampaignModel } from './Campaign.interface'

export default class PushCampaign {
  static createStepStores(campaign: ICampaignModel): {
    store: StepStore
    name: string
    displayName: string
    position: number
  }[] {
    const targeting = new Targeting(campaign)

    return [
      {
        name: 'setUp',
        displayName: 'Set Up',
        store: new SetUp(campaign),
        position: 1
      },
      {
        name: 'push',
        displayName: 'Push',
        store: new PushNotification({
          app: campaign?.rootStore?.app.appDetails,
          campaign,
          isCardCampaign: campaign.isCardCampaign
        }),
        position: 2
      },
      {
        name: 'targeting',
        displayName: 'Target',
        store: targeting,
        position: 3
      },
      {
        name: 'deliver',
        displayName: 'Deliver',
        store: new Deliver(campaign, targeting),
        position: 4
      },
      {
        name: 'review',
        displayName: 'Review',
        store: new Review(),
        position: 5
      }
    ]
  }
}
