import { action, makeObservable, runInAction } from 'mobx'
import { fetchEvents } from '~/api/apps'

import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'
import { Trigger } from '../EmailBuilder.interface'
import { MTrigger } from '../EmailBuilder.map'
import Target from './Target'

interface IEvent {
  id: string
  name: string
  trigger?: Trigger
  triggerLabel: string | undefined
}

export default class Events extends Target<IEvent> {
  targetName = TARGETS.EVENTS

  constructor() {
    super()

    makeObservable(this, {
      fetchEvents: action.bound,
      fillEvents: action.bound,
      selectEvent: action.bound
    })
  }

  async fetchEvents(appId: string): Promise<void> {
    const { data } = await fetchEvents(appId, this.searchQuery)

    runInAction(() => {
      this.collection = data.map(
        (event) =>
          this.selected.get(event) || {
            id: event,
            name: event,
            triggerLabel: MTrigger.get(Trigger.TRIGGERED)
          }
      )

      if (!this.searchQuery) {
        this.totalCount = data.length
      }
    })
  }

  fillEvents(selectedEvents: string[] | undefined): boolean {
    selectedEvents?.forEach((selectedEvent) => {
      const event = this.collection.find(
        (eventItem) => eventItem.id === selectedEvent
      )
      if (event) {
        this.selected.set(selectedEvent, event)
      }
    })

    if (selectedEvents?.length) {
      this.isActive = true
    }

    return this.isActive
  }

  selectEvent(event: IEvent, isSelected: boolean): void {
    if (!isSelected) {
      this.selected.delete(event.id)
    } else {
      this.selected.set(event.id, event)
    }
  }
}
