import { action, observable, computed, makeObservable, runInAction } from 'mobx'
import {
  addGeofence,
  batchCreateGeofencesManually,
  editGeofence,
  removeGeofence,
  exportGeofences,
  fetchGeofences
} from './Geofences.connector'
import { pickColor } from '~/pages/Geofences/utils'
import { NotificationType, showNotification } from '~/utils/Notification'
import {
  IBulkActions,
  IGeofence,
  ITempGeofence,
  IMetaData
} from '../Geofences.interface'
import { IStore } from '~/dataStore/Store.interface'
import { DEFAULT_CENTER } from '~/pages/Geofences/consts'

export default class Geofences {
  public rootStore

  constructor(rootStore: IStore) {
    makeObservable(this, {
      geofences: observable,
      metadata: observable,
      isBeaconSaving: observable,
      isInfoWindowOpen: observable,
      bulkActions: observable,
      isGeofenceSaving: observable,
      selectedGeofences: observable,
      selectedGeofencesList: computed,
      selectedGeofence: observable,
      mapCenter: observable,
      polygonsActive: computed,
      geofencesList: computed,
      circleGeofences: computed,
      polygonGeofences: computed,
      setInfoWindowOpen: action.bound,
      selectGeofence: action.bound,
      addTempGeofence: action.bound,
      removeTempGeofence: action.bound,
      setMapCenter: action.bound,
      currentQueryParams: observable,
      checkGeofence: action.bound,
      batchCreateGeofencesManually: action.bound,
      saveGeofence: action.bound,
      updateTempGeofence: action.bound,
      addSingleGeofence: action.bound,
      editGeofence: action.bound,
      removeGeofence: action.bound,
      removeGeofences: action.bound,
      setShapeEditable: action.bound,
      disableAllShapesEdition: action.bound,
      exportGeofences: action.bound,
      fetchGeofences: action.bound
    })

    this.rootStore = rootStore
  }

  geofences = new Map()

  metadata: IMetaData | undefined = undefined

  isBeaconSaving: boolean | undefined = false

  // used to force hide Info window
  isInfoWindowOpen = true

  bulkActions: IBulkActions | undefined

  isGeofenceSaving = false

  selectedGeofences: Map<string, IGeofence> = new Map()

  get selectedGeofencesList(): Array<IGeofence> {
    return Array.from(this.selectedGeofences.values())
  }

  selectedGeofence: string | undefined = undefined

  mapCenter = DEFAULT_CENTER

  get polygonsActive(): boolean {
    return this.rootStore?.app?.appDetails.featureFlags.polygon
  }

  get geofencesList(): Array<IGeofence> {
    return Array.from(this.geofences.values())
  }

  get circleGeofences(): Array<IGeofence> {
    return this.geofencesList.filter((geofence) => geofence.shape === 'circle')
  }

  get polygonGeofences(): Array<IGeofence> {
    return this.geofencesList.filter((geofence) => geofence.shape === 'polygon')
  }

  setInfoWindowOpen(value: boolean): void {
    this.isInfoWindowOpen = value
  }

  selectGeofence(id?: string): void {
    this.selectedGeofence = id
  }

  addTempGeofence(geofence: ITempGeofence): void {
    this.removeTempGeofence()
    const tempGeofence = {
      ...geofence,
      id: 'temp',
      error: {},
      groups: [],
      isDraggable: true,
      isEditable: geofence.shape === 'polygon'
    }
    this.geofences.set('temp', tempGeofence)
  }

  removeTempGeofence(): void {
    this.geofences.delete('temp')
  }

  setMapCenter({ lat, lng }: { lat: number; lng: number }): void {
    this.mapCenter = { lat, lng }
  }

  currentQueryParams?: {
    page?: number | undefined
    perPage?: number | undefined
    groupIds?: string[] | undefined
  } = undefined

  checkGeofence(geofence: IGeofence, isSelected?: boolean): void {
    if (!geofence.id) return
    if (!isSelected) {
      this.selectedGeofences.delete(geofence.id)
    } else {
      this.selectedGeofences.set(geofence.id, geofence)
    }
  }

  private synchroniseGeofence(id: string, geofence: IGeofence) {
    if (this.selectedGeofences.has(id)) {
      this.selectedGeofences.set(id, geofence)
    }
    this.geofences.set(id, geofence)
  }

  public async batchCreateGeofencesManually({
    geofences,
    errorCallback
  }: {
    geofences: IGeofence[]
    errorCallback?: (
      errors: Array<{ row: number; error: Array<string> }>
    ) => void
  }): Promise<void> {
    try {
      await batchCreateGeofencesManually(
        this.rootStore?.app?.currentApp.id,
        geofences
      )
      this.fetchGeofences({ ...this.currentQueryParams, page: 1 })
      showNotification('Geofences added successfully', NotificationType.SUCCESS)
      this.rootStore?.ui?.unregisterAllModals()
    } catch (error) {
      if (errorCallback) errorCallback(error?.body?.errors)
    }
  }

  public async saveGeofence(value: IGeofence): Promise<void> {
    if (value.id !== 'temp') {
      await this.editGeofence(value)
    } else {
      await this.addSingleGeofence(value)
    }
  }

  updateTempGeofence(geofence: ITempGeofence): void {
    if (!this.geofences.get('temp')) return
    const tempGeofence = this.geofences.get('temp')
    this.geofences.set('temp', { ...tempGeofence, ...geofence })
  }

  public async addSingleGeofence(geofence: IGeofence): Promise<void> {
    this.updateTempGeofence(geofence)
    try {
      if (!geofence.name) {
        throw new Error('Missing parameter name')
      }
      this.isGeofenceSaving = true
      const response = await addGeofence(
        this.rootStore?.app?.currentApp.id,
        geofence
      )
      const newGeofence = {
        ...response,
        error: null,
        isDraggable: true,
        isEditable: geofence.shape === 'polygon'
      }
      this.removeTempGeofence()
      this.geofences.set(newGeofence.id, newGeofence)
      this.selectGeofence(newGeofence.id)
    } catch (error: Error & { body: { errors: Record<string, string[]> } }) {
      this.handleSaveGeofenceErrors(error)
    } finally {
      this.isGeofenceSaving = false
    }
  }

  public handleSaveGeofenceErrors(
    error: Error & { body: { errors: Record<string, string[]> } }
  ): void {
    const { message, body } = error
    if (message === 'Missing parameter name') {
      this.updateTempGeofence({ error: { name: [error.message] } })
      return
    }
    if (body) {
      const errors:
        | { radius: string[]; name: string[] }
        | Record<string, string[]> = {}
      Object.keys(body.errors).forEach((key: string) => {
        errors[key] = [body.errors[key][0]]
      })
      this.updateTempGeofence({
        error: errors
      })
      return
    }
    showNotification(
      'Failed to save geofence, please try again later.',
      NotificationType.ERROR
    )
  }

  public async editGeofence(geofence: IGeofence): Promise<void> {
    const { id } = geofence
    const originalGeofence = this.geofences.get(geofence.id)
    this.synchroniseGeofence(id, { ...geofence, error: null })

    try {
      const response = await editGeofence(
        this.rootStore?.app?.currentApp.id,
        geofence
      )
      const newGeofence = { ...geofence, ...response, error: null }
      this.synchroniseGeofence(id, newGeofence)
    } catch (error) {
      if (error?.body?.errors) {
        this.synchroniseGeofence(id, {
          ...geofence,
          error: error?.body?.errors
        })
      } else {
        showNotification(
          'Failed to edit geofence, please try again later.',
          NotificationType.ERROR
        )
        this.synchroniseGeofence(id, originalGeofence)
      }
    }
  }

  public async removeGeofence(geofence: IGeofence): Promise<void> {
    if (geofence.id !== 'temp') {
      try {
        await removeGeofence(this.rootStore?.app?.currentApp.id, geofence.id)
        this.geofences.delete(geofence.id)
        this.selectedGeofences.delete(geofence.id)
      } catch {
        showNotification(
          'Failed to remove geofence, please try again later.',
          NotificationType.ERROR
        )
      }
    } else {
      this.geofences.delete('temp')
    }
    this.isInfoWindowOpen = false
  }

  public removeGeofences(ids: Array<string>): void {
    ids.forEach((id) => {
      this.geofences.delete(id)
      this.selectedGeofences.delete(id)
    })
  }

  setShapeEditable(id: string | undefined): void {
    this.geofences.forEach((geofence: IGeofence) => {
      if (geofence.id === id) {
        geofence.isDraggable = true
        geofence.isEditable = geofence.shape === 'polygon'
      } else {
        geofence.isDraggable = false
        geofence.isEditable = false
      }
    })
  }

  disableAllShapesEdition(): void {
    this.geofences.forEach((geofence: IGeofence) => {
      geofence.isDraggable = false
      geofence.isEditable = false
    })
  }

  public async exportGeofences({
    from,
    to
  }: {
    from: string
    to: string
  }): Promise<void> {
    return exportGeofences(this.rootStore?.app?.currentApp.id, from, to)
  }

  public async fetchGeofences(params?: {
    page?: number
    perPage?: number
    groupIds?: string[]
  }): Promise<void> {
    this.currentQueryParams = params
    try {
      const geofences = await fetchGeofences(
        this.rootStore?.app?.currentApp.id,
        this.currentQueryParams
      )
      let geofencesData = null
      if (!this.polygonsActive) {
        geofencesData = geofences.data.filter(
          (geofence: IGeofence) => geofence.shape === 'circle'
        )
      } else {
        geofencesData = geofences.data
      }

      runInAction(() => {
        this.metadata = geofences.metadata
        this.mapCenter = {
          lat: geofences.metadata.location[0],
          lng: geofences.metadata.location[1]
        }
        this.bulkActions = geofences.bulkActions
        this.geofences = new Map(
          geofencesData.map((geofence: IGeofence, index: number) => [
            geofence.id,
            {
              ...geofence,
              color: pickColor(index),
              isDraggable: false,
              isEditable: false,
              error: null
            }
          ])
        )
      })
    } catch (error) {
      console.error(error)
    }
  }
}
