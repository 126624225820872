import { action, computed, observable, makeObservable } from 'mobx'
import { TimeUnit } from '~/common.interface'
import {
  DEFAULT_EXPIRY_TIME_FRAME,
  DEFAULT_EXPIRY_TIME_VALUE
} from '../../../pages/CampaignBuilder/Email/consts'
import { IOption, ValidationTypes } from '../EmailBuilder.interface'
import NewRegisteredField from '../RegisteredField.model'

export default class Goal {
  eventName = new NewRegisteredField<IOption>({ value: '', label: '' }, false)

  expiryTimeValue = new NewRegisteredField<number | undefined>(
    DEFAULT_EXPIRY_TIME_VALUE
  )

  expiryTimeUnit = new NewRegisteredField<TimeUnit>(DEFAULT_EXPIRY_TIME_FRAME)

  constructor() {
    makeObservable(this, {
      eventName: observable,
      expiryTimeValue: observable,
      expiryTimeUnit: observable,
      groupIsValid: computed,
      validateGoal: action.bound,
      validateGoalTime: action.bound
    })
  }

  public get groupIsValid(): boolean {
    return (
      this.eventName.isValid &&
      this.expiryTimeValue.isValid &&
      this.expiryTimeUnit.isValid
    )
  }

  public validateGoal(message: string): void {
    this.eventName.validate(message)
  }

  public validateGoalTime(message: string): void {
    const emptyOeLessThanMinMessage = `Enter a whole number greater than 0 for ${message}`
    const moreThanMaxMessage = `Enter a whole number below 72 for ${message}`
    if (!this.expiryTimeValue.validate(emptyOeLessThanMinMessage)) {
      return
    }

    const value = Number(this.expiryTimeValue.value)
    if (value < 1 || !Number.isInteger(value)) {
      this.expiryTimeValue.isValid = false
      this.expiryTimeValue.errors.push({
        type: ValidationTypes.MIN_MAX,
        message: emptyOeLessThanMinMessage
      })
    } else if (this.expiryTimeUnit.value === 'hour' && value > 72) {
      this.expiryTimeValue.isValid = false
      this.expiryTimeValue.errors.push({
        type: ValidationTypes.MIN_MAX,
        message: moreThanMaxMessage
      })
    }
  }
}
