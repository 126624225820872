import { decamelize } from 'humps'

export function parseToString(key: string, value: string): string {
  return `${decamelize(key)}=${value}`
}

export function parseArrayToString(key: string): (value: string) => string {
  return function (value: string): string {
    return parseToString(key, value)
  }
}

export function pairToString(
  isMobileAppsEndpoint: boolean,
  [key, value]: [string, any]
): string | string[] {
  if (Array.isArray(value)) {
    if (isMobileAppsEndpoint) {
      return value.map(parseArrayToString(`${key}[]`))
    }
    return parseToString(key, value.join(','))
  }
  return parseToString(key, value)
}

export function paramsToStr(
  params: any = {},
  isMobileAppsEndpoint = false
): string {
  const str = Object.entries(params)
    .filter(([key, value]: [string, any]) => {
      if (Array.isArray(value)) {
        return value.filter((v) => !(v === null || v === undefined)).length > 0
      }
      return !!value
    })
    .flatMap(pairToString.bind(null, isMobileAppsEndpoint))
    .join('&')
  if (str.length > 0) return `?${str}`
  return str
}
