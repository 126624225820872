import React from 'react'
import {
  Input as InputField,
  FormFeedback,
  InputGroup,
  InputProps,
  Label,
  UncontrolledTooltip
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import { UseFormRegister } from 'react-hook-form'

export interface Props {
  inputRef?: InputProps['innerRef']
  className?: string
  labelClassName?: string
  disabled?: boolean
  errorMessage?: string
  invalid?: boolean
  tooltip?: string
  id?: string
  errorTooltip?: string
  label?: string | React.ReactElement
  name?: string
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  required?: boolean
  register?: UseFormRegister<unknown>
  type?: InputProps['InputType']
  value?: unknown
  prefix?: unknown
  suffix?: unknown
  [x: string]: unknown
}

const Input: React.FunctionComponent<Props> = ({
  inputRef,
  className,
  controlClassName,
  labelClassName,
  disabled,
  errorMessage,
  invalid,
  label,
  name,
  id,
  validation,
  tooltip,
  errorTooltip,
  onBlur,
  onChange,
  register,
  required,
  onKeyPress,
  placeholder,
  type = 'text',
  value,
  prefix,
  suffix,
  ...rest
}: Props) => {
  const inputId = id || name
  const getInputOptions = () => {
    return {
      id: inputId,
      className: cn(controlClassName, { 'error-input': invalid }),
      disabled,
      label,
      name,
      onBlur,
      onChange,
      onKeyPress,
      placeholder,
      required,
      type,
      value,
      prefix,
      suffix,
      innerRef: inputRef,
      ...rest
    }
  }

  let inputComponent: React.ReactElement | null = null

  if (register) {
    const registered = register(name, { ...validation })

    inputComponent = (
      <InputField
        {...getInputOptions()}
        name={registered.name}
        onBlur={registered.onBlur}
        onChange={registered.onChange}
        innerRef={registered.ref}
      />
    )
  } else {
    inputComponent = <InputField {...getInputOptions()} />
  }

  const renderInput = () => {
    if (prefix || suffix) {
      return (
        <InputGroup>
          {prefix && (
            <div className={cn('input-group-text', { 'error-input': invalid })}>
              {prefix}
            </div>
          )}
          {inputComponent}
          {suffix && (
            <div className={cn('input-group-text', { 'error-input': invalid })}>
              {suffix}
            </div>
          )}
        </InputGroup>
      )
    }
    return inputComponent
  }

  return (
    <div
      className={cn(className, {
        'error-input': invalid
      })}>
      {label && (
        <div className="d-flex justify-content-between">
          <Label
            for={inputId}
            className={cn('fw-medium', labelClassName, {
              'error-label': invalid
            })}>
            {label}
          </Label>
          {rest.maxLength && (
            <span className="text-blue-gray">
              {value.length}/{rest.maxLength}
            </span>
          )}
          {tooltip && (
            <>
              <FontAwesomeIcon
                tabIndex={-1}
                icon={faInfoCircle}
                className="o-50 ms-auto"
                id={`info-tooltip-${inputId}`}
                size="lg"
              />
              <UncontrolledTooltip
                placement="top"
                target={`info-tooltip-${inputId}`}>
                {tooltip}
              </UncontrolledTooltip>
            </>
          )}
        </div>
      )}
      {renderInput()}
      {errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
      {errorTooltip && inputId && (
        <UncontrolledTooltip
          className="tooltip-validation-error"
          placement="top-start"
          target={inputId}>
          {errorTooltip}
        </UncontrolledTooltip>
      )}
    </div>
  )
}

export default Input
