import { ReactElement, useState } from 'react'
import InAppLargeModel, {
  DNDFields,
  InAppFields,
  ToggleFields
} from '~/pages/Campaign/Notification/InAppNotification/Model/InAppLarge.model'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import SeparatorLine from '~/components/SeparatorLine'
import CampaignCTAButton from '../../../../CampaignBuilder/CTA/CampaignCTAButton'
import AdminHeaderWithMessage from '../../../../CampaignBuilder/MobileCampaignPanel/AdminHeaderWIthMessage.field'
import UploadImage from '~/components/uploadImage/UploadImage'
import withDND from '~/components/DND'
import { withStore } from '~/dataStore'
import { isEmpty } from '~/utils/utilities'
import { ActionTypes } from '~/dataStore/Campaign/Campaign.interface'
import RecordEvent from '../../../../CampaignBuilder/RecordEvent'
import Input from '~/components/forms/Input'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import useCollapseContext from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import Shared from '~/dataStore/Campaign/Shared'
import NotificationGoals from '~/pages/Campaign/Notification/NotificationGoals'
import Goals from '~/pages/Campaign/Notification/NotificationGoals/UI'
import AdminHeader from '~/pages/Campaign/Notification/Preview/Parts/AdminHeader'

interface IProps {
  notification: InAppLargeModel
  shared: Shared
  ctaActions: ActionTypes[]
  withEvent?: boolean
  isWithCardCampaign?: boolean
  isSended?: boolean
  withGoals?: boolean
  notificationGoals?: NotificationGoals
  onDestinationTypeChange?: (value: ActionTypes) => void
  adminHeaderEditable?: boolean
}

const ToggleWithDND = withDND(CollapseWithToggle)

function InAppLarge({
  notification,
  shared,
  ctaActions,
  withEvent,
  isWithCardCampaign,
  isSended,
  withGoals,
  notificationGoals,
  onDestinationTypeChange,
  adminHeaderEditable = true
}: IProps): ReactElement {
  const { mergeTags, admins, deeplinksOptions, eventsOptions } = shared
  const { opened: openPicker, setOpened: setOpenPicker } =
    useCollapseContext() || {}
  const [dragged, setDragged] = useState<{ type: DNDFields } | undefined>()

  function setPosition(destination: DNDFields): void {
    if (dragged) {
      notification.replaceFieldPosition(destination, dragged.type)
    }
  }

  function handleCheck(type: ToggleFields): (check: boolean) => void {
    return (check: boolean) => {
      if (isSended) {
        return
      }

      if (!check) {
        notification.reset(type)
      } else {
        notification.setActive(type, true)
      }
      notification[type].validate()
    }
  }

  const pickerProps = (type: InAppFields, name: string) => ({
    type,
    name,
    disabled: isSended,
    opened: openPicker === type,
    setPosition,
    setDragged: (dragged) => {
      setDragged(dragged)
      setOpenPicker?.(null)
    },
    isValid:
      (type === 'actionButtons' || notification.fieldOption.isValid) &&
      notification.isValidField(type),
    setOpened: (open: boolean) => {
      setOpenPicker?.(open ? type : null)
    },
    checked: !isEmpty(notification[type as ToggleFields].isActive)
      ? notification[type as ToggleFields].isActive
      : true,
    setChecked: handleCheck(type as ToggleFields)
  })

  const cta = notification.actionButtons.buttons[0]

  return (
    <>
      <CollapseWithToggle {...pickerProps('adminHeader', 'Personal Message')}>
        {adminHeaderEditable ? (
          <AdminHeaderWithMessage
            admins={admins}
            onSelect={notification.setAdmin}
            selected={notification.admin}
            onChange={notification.adminHeader.setValue}
            value={notification.adminHeader.value}
            invalid={!notification.adminHeader.isValid}
            disabled={isSended}
            onBlur={() => {
              notification.adminHeader.validate()
              notification.validateAdminHeaderInvalidChars()
            }}
            mergeTags={mergeTags}
          />
        ) : (
          <>
            <AdminHeader
              image={notification.admin?.avatarUrl}
              name={notification.admin?.name || ''}
              jobTitle={notification.admin?.jobTitle || ''}
              message={notification.adminHeader.value || ''}
            />
          </>
        )}
      </CollapseWithToggle>
      <SeparatorLine spaceBottom="0" spaceTop="0" />
      <div className="d-flex flex-column">
        <ToggleWithDND
          {...pickerProps('image', 'Image')}
          withSeparator
          position={notification.image.position}>
          <UploadImage
            allowedTypes={['jpg', 'png', 'jpeg', 'gif']}
            onChange={notification.image.addInternalFile}
            image={notification.image.value}
            fileName={notification.image.name}
            maxFileSizes={[{ type: 'image', maxSize: 1_000_000, error: '1MB' }]}
            className="mb-3"
          />
        </ToggleWithDND>
        <ToggleWithDND
          {...pickerProps('headline', 'Headline')}
          withSeparator
          position={notification.headline.position}>
          <Textarea
            onChange={notification.headline.setValue}
            value={notification.headline.value}
            invalid={!notification.headline.isValid}
            required
            withoutLineBreaks
            disabled={isSended}
            onBlur={() => notification.headline.validate()}
            placeholder="Add a Headline that grabs people’s attention... "
            mergeTags={mergeTags}
          />
        </ToggleWithDND>
        <ToggleWithDND
          {...pickerProps('text', 'Text')}
          withSeparator
          position={notification.text.position}>
          <Textarea
            onChange={notification.text.setValue}
            value={notification.text.value}
            invalid={!notification.text.isValid}
            disabled={isSended}
            onBlur={() => notification.text.validate()}
            placeholder="Tell people more about your campaign..."
            mergeTags={mergeTags}
          />
        </ToggleWithDND>
      </div>
      <CollapseWithToggle
        {...pickerProps('actionButtons', 'Call to action')}
        onlyCollapse>
        {isWithCardCampaign ? (
          <>
            <span className="d-block mb-2 text-muted">
              This button opens the card you designed
            </span>
            <Input
              value={cta.value}
              placeholder="Button Text"
              onBlur={() => {
                cta.validateLabel()
                cta.validateLabelInvalidChars()
              }}
              invalid={!cta.label.isValid}
              className="mb-4"
              onChange={(e) => {
                cta.setValue(e.target.value)
              }}
            />
          </>
        ) : (
          <CampaignCTAButton
            field={cta}
            deeplinks={deeplinksOptions}
            types={ctaActions}
            buttonInputLabel="Button Label"
            onDestinationTypeChange={onDestinationTypeChange}
          />
        )}

        {withEvent && <RecordEvent eventsOptions={eventsOptions} field={cta} />}

        {withGoals && notificationGoals && (
          <div className="mb-3">
            <Goals
              disabled={isSended}
              setGoal={notificationGoals.setGoal}
              updateBtnLabelGoal={notificationGoals.updateBtnLabelGoal}
              sourceType="button"
              buttonID={cta.buttonID}
              buttonLabel={cta.label.value}
              selected={notificationGoals.selectedGoalTypes}
              checkedGoalType={notificationGoals.isChecked({
                //* only one button in inapp so we do not need to distinguish by label
                sourceType: 'button',
                buttonLabel: cta.buttonID
              })}
            />
          </div>
        )}
      </CollapseWithToggle>
    </>
  )
}

export default withStore(InAppLarge)
