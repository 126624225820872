import { useQuery } from '@tanstack/react-query'
import { fetchCurrencies } from '~/api/appSettings'
import { ID } from '~/common.interface'
import { ICurrenciesDTO } from './RevenueSection.interface'

export enum ServerStateKeysEnum {
  Currencies = 'currencies'
}

interface CurrenciesQueryResult {
  data: ICurrenciesDTO | undefined
}

export const useGetCurrencies = (appId: ID): CurrenciesQueryResult =>
  useQuery([ServerStateKeysEnum.Currencies], () => fetchCurrencies(appId), {
    staleTime: Infinity
  })
