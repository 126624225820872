import React from 'react'
import { handleLongName } from '~/utils/string'
import { IGroup } from '~/dataStore/Groups/Groups.interface'

const getGroupsColumnOptions = (
  groups: IGroup[],
  unselectedGroups: IGroup[]
) => [
  {
    key: 'groups',
    name: (
      <>
        <span className="ms-3 text-dark o-75 mb-1 d-block">
          Group
          <small>(</small>s<small>)</small>
        </span>
        {groups.length === 0 && (
          <span className="ms-3 mb-2 groups-table-column__no-groups-message">
            No group assigned
          </span>
        )}
      </>
    )
  },
  ...groups.map(({ id, name }: IGroup) => ({
    value: id,
    name: handleLongName(name, 25),
    tooltip: name
  })),
  {
    key: 'addToGroups',
    name: (
      <span className="ms-3 text-dark o-75 mt-4 d-block">
        Add to Group
        <small>(</small>s<small>)</small>
      </span>
    )
  },
  ...unselectedGroups.map(({ id, name }: IGroup) => ({
    value: id,
    name: handleLongName(name, 25),
    tooltip: name
  }))
]

export default getGroupsColumnOptions
