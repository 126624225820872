import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

export const createSelectOption = (label: string | undefined): IOption => {
  if (label) {
    return {
      label,
      value: label.toLowerCase().replace(/\W/g, '')
    }
  }
  return {
    label: '',
    value: ''
  }
}

export const createSelectOptions = (
  options: (string | undefined)[] | undefined | null
): IOption[] => {
  if (!options) return []
  return options.map(createSelectOption)
}
