import { action, computed, observable, makeObservable } from 'mobx'
import { isEmpty, isNotEmpty, isOption } from '~/utils/utilities'
import { ValidationTypes, IRegisteredField } from './EmailBuilder.interface'

export default class NewRegisteredField<T = any>
  implements IRegisteredField<T> {
  value: T

  valid: boolean

  beenValid: boolean

  errors: { type: ValidationTypes; message: string }[]

  keyErrors: { [x: string]: string }

  defaultErrorMessage = ''

  constructor(initialValue: T, beenValid?: boolean) {
    makeObservable<NewRegisteredField, 'validateExistence'>(this, {
      value: observable,
      valid: observable,
      beenValid: observable,
      errors: observable,
      keyErrors: observable,
      isValid: computed,
      resetError: action.bound,
      fillField: action.bound,
      validateExistence: action.bound,
      setError: action.bound,
      setValue: action.bound
    })

    this.value = initialValue
    this.valid = true
    this.errors = []
    this.keyErrors = {}
    this.beenValid = beenValid ?? !!initialValue

    this.validate = this.validate.bind(this)
  }

  public get isValid(): boolean {
    return this.valid
  }

  public set isValid(value: boolean) {
    this.valid = value
  }

  public resetError(): void {
    this.isValid = true
    this.errors = []
    this.keyErrors = {}
    this.beenValid = true
  }

  public fillField({ value }: { value: T | undefined }): void {
    if (isNotEmpty(value)) {
      this.value = value
      this.beenValid = true
    }
  }

  public setErrorMessage(message: string): void {
    this.defaultErrorMessage = message
  }

  private getValidationValue(): T | string {
    if (isOption(this.value)) {
      return this.value.value
    }

    return this.value
  }

  public validate(message?: string): boolean {
    this.resetError()

    const errorMessage = message || this.defaultErrorMessage

    const value = this.getValidationValue()

    this.validateExistence(value, errorMessage)

    return this.isValid
  }

  private validateExistence(
    value: T | undefined | string,
    message: string
  ): void {
    if (isEmpty(value)) {
      this.setError(ValidationTypes.REQUIRED, message)
    }
  }

  public setError(type: ValidationTypes, message: string): void {
    this.isValid = false
    this.errors.push({
      message,
      type
    })
  }

  public setKeyError(key: string, message: string): void {
    this.isValid = false
    this.keyErrors[key] = message
  }

  public setValue(value: T): void {
    this.value = value
  }
}
