import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row } from 'reactstrap'
import Input from '~/components/forms/Input'
import { withStore } from '~/dataStore'
import TableField from '~/dataStore/emailBuilder/Fields/Table.field'
import CampaignBuilderTableRow from './CampaignBuilderTableRow'

interface IProps {
  field: TableField
}

function CampaignBuilderTable({ field }: IProps): React.ReactElement {
  return (
    <div
      className="my-3"
      onDragEnter={(e) => e.stopPropagation()}
      draggable
      onDragStart={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}>
      <label htmlFor="tableHeader" className="text-muted mb-2">
        Table Heading (optional)
      </label>
      <Input
        name="tableHeader"
        placeholder="Eg. Opening Hours"
        value={field.value}
        onChange={(e) => field.setValue(e.target.value)}
        className="mb-4"
      />
      <Row>
        <Col>
          <span className="text-muted">Label</span>
        </Col>
        <Col>
          <span className="text-muted">Value</span>
        </Col>
        {field.rows.length !== 1 && <Col className="ps-3 me-3 flex-grow-0" />}
      </Row>

      {field.rows.map((row, _, collection) => (
        <CampaignBuilderTableRow
          key={row.value.id}
          row={row}
          remove={field.removeRow}
          onBlur={() => field.validate()}
          showRemoveIcon={collection.length > 1}
        />
      ))}
      <Button
        color=""
        className="bg-white btn--hover btn-outline-body"
        onClick={field.addEmptyRow}>
        <FontAwesomeIcon
          icon={faPlus}
          size="xs"
          className="me-1 align-middle"
        />
        Add
      </Button>
    </div>
  )
}

export default withStore(CampaignBuilderTable)
