import React, { useEffect, useState, useMemo } from 'react'
import Input from '~/components/forms/Input'
import debouncedEventHandler from '~/helpers/debouncedEventHandler'
import Masonry from '~/components/Masonry'
import useInfiniteScroll from '~/hooks/useInfiniteScroll'
import { getStockPhotos } from '~/api/unlayer'

type Image = {
  id: string
  thumb: string
  full: string
}

const StockPhotos = ({ done }: { done: (url: string) => void }) => {
  const [images, setImages] = useState<Array<Image>>([])
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('people')

  const prepareImages = (imageProviders: any): Array<Image> => {
    const newImages: Image[] = []
    const [unsplash, pixabay, pexel] = imageProviders
    unsplash.unsplash.forEach((image: any) => {
      newImages.push({
        id: image.id,
        thumb: image.urls.thumb,
        full: image.urls.regular
      })
    })

    pixabay.pixabay.forEach((image: any) => {
      newImages.push({
        id: image.id,
        thumb: image.previewURL,
        full: image.webformatURL
      })
    })

    pexel.pexel.forEach((image: any) => {
      newImages.push({
        id: image.id,
        thumb: image.src.thumb,
        full: image.src.large
      })
    })

    return newImages
  }

  const fetchImages = async (params?: {
    page?: number
    searchQuery?: string
  }) => {
    const providers = await getStockPhotos(
      params?.searchQuery || searchQuery,
      params?.page || page
    )
    setImages((prevImages) => [
      ...prevImages,
      ...prepareImages(providers.images)
    ])
    setPage(params?.page + 1 || page + 1)
  }

  const bottomRef = useInfiniteScroll({
    hasMore: true,
    onLoadMore: fetchImages
  })

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
    setImages([])
    fetchImages({ searchQuery: e.target.value, page: 1 })
  }

  useEffect(() => {
    fetchImages()
  }, [])

  return (
    <div>
      <p className="muted">
        Powered by Unsplash, Pexels, Pixabay. All images licensed under Creative
        Commons Zero.
      </p>
      <Input
        placeholder="Search"
        onChange={debouncedEventHandler(handleSearch, 300)}
        className="email-image-upload__search mb-0"
      />
      <div className="mt-4">
        <Masonry breakPoints={[350, 500, 750]}>
          {useMemo(() => {
            return images.map((image: Image, i) => (
              <img
                key={image.id}
                src={image.thumb}
                onClick={() => {
                  done(image.full)
                }}
                ref={i + 1 === images.length ? bottomRef : null}
              />
            ))
          }, [images])}
        </Masonry>
      </div>
    </div>
  )
}

export default StockPhotos
