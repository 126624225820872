import { ALPHABET } from '~/pages/CampaignBuilder/Email/consts'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { IOption } from './emailBuilder/EmailBuilder.interface'
import {
  CampaignType,
  IPreview,
  IPreviewParts
} from './Campaign/Campaign.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

type CampaignTypeOption = IOption<string> & {
  original: CampaignType
}

// TODO: remove preview dependency, make separate function to get sides and get list of sides as parameter
export function getCampaignTypeOptions(
  types: CampaignType[],
  preview?: IPreview
): CampaignTypeOption[] {
  return (
    types
      .map((type) => {
        const options = []
        if (type === CampaignType.CARD) {
          options.push({
            label: MCampaignTypeToName.get(CampaignType.CARD_FRONT) || '',
            value: CampaignType.CARD_FRONT,
            original: CampaignType.CARD_FRONT
          })
          if (preview?.card.back) {
            options.push({
              label: MCampaignTypeToName.get(CampaignType.CARD_BACK) || '',
              value: CampaignType.CARD_BACK,
              original: CampaignType.CARD_BACK
            })
          }

          return options
        }

        if (type === CampaignType.PUSH && preview?.push) {
          if (preview.push?.variants?.length <= 1) {
            return preview.push.variants.map((v, index) => ({
              label: MCampaignTypeToName.get(CampaignType.PUSH) || '',
              value: `push-${index}`,
              original: CampaignType.PUSH
            }))
          }
          return preview.push?.variants?.map((v, index) => ({
            label: `Push ${ALPHABET[index].toUpperCase()}`,
            value: `push-${index}`,
            original: CampaignType.PUSH
          }))
        }

        return {
          label: MCampaignTypeToName.get(type) || '',
          value: type,
          original: type
        }
      })
      .flat() || []
  )
}

export function getVariantNumber(value: string): number {
  return Number(value.split('-')?.[1]) ?? 0
}

export function getPreviewByOptionType(
  type: CampaignTypeOption,
  preview: PartialPreview
): IPreviewParts {
  if (type.value === CampaignType.CARD_FRONT && preview.card) {
    return preview.card.front
  }
  if (type.value === CampaignType.CARD_BACK && preview.card) {
    return preview.card.back
  }
  if (type.original === CampaignType.PUSH && preview.push) {
    const number = getVariantNumber(type.value)
    return preview.push.variants[number]
  }
  if (type.original === CampaignType.IN_APP && preview.inApp) {
    return preview.inApp
  }
  if (type.original === CampaignType.EMAIL && preview.email) {
    return {
      ...preview.email,
      html: preview.email.notification?.html || preview.email.html,
      imageUrl: preview.email.imageUrl
    }
  }

  if (type.value in preview) {
    return preview[type.value]
  }

  return { parts: [] }
}
