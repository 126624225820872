export interface FormValues {
  segmentName: string
  customTag: string
  file: FileList
}

export const ERRORS_DICT = {
  file: {
    'Missing parameter file': '.CSV file is missing'
  }
}
