import { FunctionComponent, ReactElement } from 'react'
import SeparatorLine from '~/components/SeparatorLine'

interface IProps {
  position: number
  type: string
  setPosition: (type: string) => void
  setDragged: ({ type }: { type: string }) => void
  withSeparator?: boolean
  disabled?: boolean
}

export default function withDND(Component: FunctionComponent<any>): any {
  return function DNDComponent({
    position,
    type,
    setPosition,
    setDragged,
    withSeparator,
    disabled,
    ...props
  }: IProps): ReactElement {
    function handleDrop(e) {
      e.target.classList.remove('dnd__hover')
    }

    function handleDrag(e) {
      setDragged({ type })
    }

    function handleDragOver(e) {
      e.preventDefault()
    }

    function handleDragEnter(e) {
      setPosition(type)
      e.target.classList.add('dnd__hover')
    }

    function handleDragLeave(e) {
      e.target.classList.remove('dnd__hover')
    }

    return (
      <div
        className="dnd"
        draggable={!disabled}
        style={{ order: position }}
        data-id={type}
        data-position={position}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragStart={handleDrag}>
        <Component {...props} dnd disabled={disabled} />

        {withSeparator && <SeparatorLine spaceBottom="0" spaceTop="0" />}
      </div>
    )
  }
}
