import { ReactElement, SyntheticEvent } from 'react'
import cn from 'classnames'
import CampaignIcon from '~/pages/Campaign/UI/CampaignIcon'
import { useStore, withStore } from '~/dataStore'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  name: string
  campaignType: CampaignType | 'notificationCard'
  registerInterest?: boolean
  description?: string
  comingSoon?: boolean
  onClick?: (selected: CampaignType | 'notificationCard') => void
  handleRegisterInterest?: ({
    newType,
    name,
    campaignType
  }: {
    newType: boolean
    name: string
    campaignType: CampaignType | 'notificationCard'
  }) => void
}

function CampaignTile({
  name,
  description,
  campaignType,
  registerInterest,
  comingSoon,
  onClick,
  handleRegisterInterest
}: IProps): ReactElement {
  const { resetCampaign } = useStore()

  function handleClick(e: SyntheticEvent<HTMLDivElement>) {
    if (comingSoon) {
      e.preventDefault()
      e.stopPropagation()
      handleRegisterInterest?.({ newType: false, name, campaignType })
      return
    }

    if (onClick) {
      onClick(campaignType)
    } else {
      resetCampaign(campaignType)
    }
  }

  return (
    <>
      <div
        data-testid={campaignType}
        onClick={handleClick}
        className={cn(
          'campaign-tile d-flex align-items-center cursor-pointer group',
          `campaign-tile--${campaignType}`
        )}>
        {registerInterest && (
          <div className="campaign-tile__label">Register interest</div>
        )}

        {comingSoon && (
          <div className="campaign-tile__label campaign-tile__label--gray">
            Coming Soon
          </div>
        )}

        <CampaignIcon
          type={campaignType}
          className="campaign-tile__icon"
          withBg
        />
        <div className="campaign-tile__details">
          <div className="campaign-tile__name">
            {registerInterest && (
              <span className="campaign-tile__new">New</span>
            )}
            {name}
          </div>
          <div className="campaign-tile__description">{description}</div>
        </div>
      </div>
    </>
  )
}

export default withStore(CampaignTile)
