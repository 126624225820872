import cn from 'classnames'
import ReportBtn from '../../CampaignReports/ReportBtn/ReportBtn'
import { ICampaignsListItem } from '../CampaignsList.interface'
import CampaignNameColumn from '~/components/Table/components/CampaignNameColumn/CampaignNameColumn'
import { humanDateTime } from '~/utils/time'
import GroupsColumn from '~/components/Table/components/GroupsColumn'
import { col, colWide } from '~/components/Table/Table.scss'
import RowActions from './RowActions'
import routes from '~/routes'
import StatusColumn from '~/components/Table/components/StatusColumn'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'
import { generateListsDisplayType } from '~/pages/Campaign/Notification/Notification.service'

const groupsColumn = ({
  row,
  onUpdateRow
}: {
  row: ICampaignsListItem
  onUpdateRow: (row: unknown) => void
}): React.ReactElement => <GroupsColumn {...row} onUpdateRow={onUpdateRow} />

const campaignsTableColumns = [
  {
    fieldName: 'name',
    displayName: 'Name',
    sortable: true,
    render: ({
      row: { name, actions, id, hiddenFromFeed }
    }: {
      row: ICampaignsListItem
    }): React.ReactElement => (
      <CampaignNameColumn
        name={name}
        isRedirectActive={actions?.details}
        campaignId={id}
        hiddenFromFeed={hiddenFromFeed}
      />
    )
  },
  {
    fieldName: 'createdAt',
    displayName: 'Date Created',
    classes: 'no-wrap',
    sortable: true,
    render: ({ row }: { row: ICampaignsListItem }): string =>
      humanDateTime(row.createdAt)
  },
  {
    fieldName: 'type',
    displayName: 'Type',
    render: ({ row }: { row: ICampaignsListItem }): string =>
      generateListsDisplayType(row.typeList),
    sortable: true
  },
  {
    fieldName: 'status',
    displayName: 'Status',
    render({ row }: { row: ICampaignsListItem }): React.ReactElement {
      return (
        <StatusColumn
          row={row}
          pauseStatues={[CampaignStatus.PAUSED, CampaignStatus.ACTIVE]}
          pauseTooltip="Pause (users who meet the targeting criteria after the pause will not receive the campaign)"
        />
      )
    }
  },
  {
    fieldName: 'groups',
    displayName: 'Groups',
    render: groupsColumn,
    className: cn(col, colWide, 'groups-row')
  },
  {
    displayName: 'Report',
    render: ({ row }: { row: ICampaignsListItem }) => (
      <ReportBtn
        compact
        status={row.status}
        id={row.id}
        paramName="campaignId"
        route={routes.campaignReport}
      />
    )
  },
  {
    displayName: 'Actions',
    render: RowActions
  }
]

export default campaignsTableColumns
