import React, { PropsWithChildren } from 'react'
import pluralize from 'pluralize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Alert } from 'reactstrap'
import { deleteRequest } from '~/api/requests'
import { NotificationType, showNotification } from '~/utils/Notification'
import Modal from '~/components/modals/Modal/Modal'
import ConfirmationListModal from './ConfirmationListModal'
import { ID } from '~/common.interface'

type ElementType = 'Journey' | 'Campaign' | 'Segment' | 'Geofence' | 'Beacon'

interface IDeleteElementsModalProps {
  elements: Array<{
    id: string
    name: string
  }>
  url: string
  action?: (resourceId: ID) => Promise<unknown>
  onSuccess: (ids: Array<string>) => void
  elementType: ElementType
  nameKey?: string
  onClose: () => void
}

const DeleteElements = ({
  url,
  action,
  elements,
  onSuccess,
  onClose,
  elementType,
  nameKey = 'name',
  children
}: PropsWithChildren<IDeleteElementsModalProps>) => {
  const handleSave = async () => {
    const ids = elements.map((c) => c.id)
    try {
      if (ids.length === 1 && action) {
        await action(ids[0])
      } else {
        await deleteRequest(url, { resourceIds: ids })
      }
      onSuccess(ids)
      onClose()
      showNotification(
        `${pluralize(elementType, elements.length)} deleted`,
        NotificationType.SUCCESS
      )
    } catch (error) {
      const errorMesage = error.body?.error || ''

      showNotification(
        `Failed to delete ${pluralize(
          elementType,
          elements.length
        )}, ${errorMesage}`,
        NotificationType.ERROR
      )
    }
  }

  if (!elements) return null

  return (
    <ConfirmationListModal
      actionType="delete"
      elementType={elementType}
      elements={elements}
      onClose={onClose}
      onSubmit={handleSave}
      nameKey={nameKey}>
      <Alert
        fade={false}
        closeClassName="d-none"
        color="warning"
        className="mb-0 mt-4">
        <div className="d-flex align-items-center p-2">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size="2x"
            className="me-3"
          />
          <ul className="mb-0">
            <li>
              The delete action is irreversible and it will{' '}
              {['Journey', 'Campaign'].includes(elementType) ? 'end and' : ''}{' '}
              erase the {pluralize(elementType.toLowerCase(), elements.length)}{' '}
              listed above
            </li>
            {children && <li>{children}</li>}
          </ul>
        </div>
      </Alert>
    </ConfirmationListModal>
  )
}

export default function DeleteElementsModal({
  elementType,
  nameKey,
  children
}: PropsWithChildren<{
  elementType: ElementType
  nameKey?: string
}>): React.ReactElement {
  return (
    <Modal
      id="deleteElementsModal"
      size="md"
      container="body"
      renderHeader={(_, params) =>
        `Delete ${pluralize(elementType, params?.elements?.length || 1)}`
      }
      render={(close: () => void, params) => {
        return (
          <DeleteElements
            onClose={close}
            nameKey={nameKey}
            elementType={elementType}
            {...params}>
            {children}
          </DeleteElements>
        )
      }}
    />
  )
}
