import { Collapse } from 'reactstrap'

import Radio from '~/components/forms/Radio'
import ExpiryNeverWarning from './ExpiryNeverWarning'
import DateForm from './DateForm'
import TimeAndFrameForm from './TimeAndFrameForm'

import { withStore } from '~/dataStore'
import { ExpiryType } from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  label: string
  name: string
  value: ExpiryType
  disabled?: boolean
  onChange: (value: ExpiryType) => void
  expiryDateInvalid?: boolean
}

function Expiry({
  label,
  name,
  value,
  onChange,
  disabled,
  expiryDateInvalid
}: IProps): React.ReactElement {
  return (
    <div>
      <p className="mb-0 pb-3">{label}</p>
      <Radio
        withDivider
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={[
          {
            value: ExpiryType.DATE,
            label: 'On a specific date',
            className: expiryDateInvalid ? 'error-label' : undefined
          },
          {
            value: ExpiryType.AMOUNT,
            label: 'After a specific amount of time'
          },
          { value: ExpiryType.NEVER, label: 'Never' }
        ]}
      />
      <Collapse isOpen={value === ExpiryType.DATE}>
        <div className="pt-3">
          <DateForm />
        </div>
      </Collapse>
      <Collapse isOpen={value === ExpiryType.AMOUNT}>
        <div className="pt-3">
          <TimeAndFrameForm />
        </div>
      </Collapse>
      <Collapse isOpen={value === ExpiryType.NEVER}>
        <div className="pt-3">
          <ExpiryNeverWarning />
        </div>
      </Collapse>
    </div>
  )
}

export default withStore(Expiry)
