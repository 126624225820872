import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { useStore, withStore } from '~/dataStore'
import onThePulse from './assets/on_pulse.svg'

const Feed = () => {
  const { app } = useStore()

  useEffect(() => {
    if (app.appDetails.featureFlags.webSdk) {
      window.PulsateSDK?.showFeed('pulsate-feed')
    }
  }, [app.appDetails.featureFlags.webSdk])

  if (!app.appDetails.featureFlags.webSdk) {
    return null
  }
  return (
    <Container>
      <div className="text-center mb-5">
        <img
          src={onThePulse}
          alt="On the Pulse"
          className="mw-100"
          aria-label="On the Pulse Image"
          data-testid="on-the-pulse-image"
        />
      </div>
      <div id="pulsate-feed" className="mb-5">
        <div className="pulse-feed">
          <div
            className="pulse-feedpost"
            aria-label="Feed Post 1"
            data-testid="feed-post-1">
            <div className="pulse-img-box-feed">
              <Skeleton count={1} height={260} />
            </div>
            <div className="pulse-content-box">
              <Skeleton count={1} height={25} />
              <Skeleton count={3} />
              <Skeleton count={1} height={50} width={200} />
            </div>
          </div>
          <div
            className="pulse-feedpost"
            aria-label="Feed Post 2"
            data-testid="feed-post-2">
            <div className="pulse-img-box-feed">
              <Skeleton count={1} height={260} />
            </div>
            <div className="pulse-content-box">
              <Skeleton count={1} height={25} />
              <Skeleton count={3} />
              <Skeleton count={1} height={50} width={200} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default withStore(Feed)
