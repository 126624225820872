import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Row, Col } from 'reactstrap'

import GoalIcon from '~/pages/Campaign/Notification/NotificationGoals/UI/GoalIcon'
import PreviewDesktop from '~/pages/Campaign/Notification/Preview/PreviewDesktop'
import { withStore } from '~/dataStore'
import Columns from '~/components/Columns'
import EmailDetail from './EmailDetail'
import bulpIcon from './bulp-icon.svg'
import mailIcon from './mail-icon.svg'
import userIcon from './user-icon.svg'
import { INotificationGoal } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'

interface IProps {
  subject: string
  fromEmailAddress: string
  fromName: string
  unlayerThumbnailUrl?: string
  loading?: boolean
  html?: string
  goals?: INotificationGoal[]
}

function Template({
  subject,
  fromEmailAddress,
  fromName,
  unlayerThumbnailUrl,
  loading,
  html,
  goals
}: IProps) {
  return (
    <div className="m-auto">
      <PreviewDesktop srcDoc={html} size="xxl">
        {!loading ? (
          <img
            src={unlayerThumbnailUrl}
            alt="Email Campaign preview"
            className="preview-img"
          />
        ) : (
          <Row className="p-4 w-100 d-flex align-items-center">
            <Col xs={12}>
              <SkeletonTheme baseColor="#DFDFDF">
                <Skeleton height={30} width="70%" />
              </SkeletonTheme>
            </Col>
            <Col xs={12}>
              <SkeletonTheme baseColor="#E1E4E8">
                <Skeleton height={100} width="100%" />
              </SkeletonTheme>
            </Col>
            <Col xs={6}>
              <SkeletonTheme baseColor="#E1E4E8">
                <Skeleton height={125} width="100%" />
              </SkeletonTheme>
            </Col>
            <Col xs={6}>
              <SkeletonTheme baseColor="#DFDFDF">
                <Skeleton width="80%" height={20} />
                <div className="mt-2">
                  <Skeleton width="100%" height={20} />
                </div>
              </SkeletonTheme>
              <div className="mt-3">
                <SkeletonTheme baseColor="#F0A0B1">
                  <Skeleton width="20%" height={30} />
                </SkeletonTheme>
              </div>
            </Col>
          </Row>
        )}
      </PreviewDesktop>
      {goals && (
        <div className="mt-5 d-flex justify-content-center">
          {goals.map((goal) => (
            <div
              key={goal.reference}
              className="d-flex align-items-center mx-3">
              <GoalIcon type={goal.type} size="xl" />
              <span className="mx-3">{goal.reference}</span>
            </div>
          ))}
        </div>
      )}
      <div className="mt-5">
        <Columns columnCount={3}>
          <EmailDetail icon={bulpIcon} value={subject} label="Email subject" />
          <EmailDetail
            icon={mailIcon}
            value={fromEmailAddress}
            label="From Email Address"
          />
          <EmailDetail icon={userIcon} value={fromName} label="From name" />
        </Columns>
      </div>
    </div>
  )
}

export default withStore(Template)
