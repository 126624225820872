import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Image, UploadCloud } from 'react-feather'
import {
  ModalBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button
} from 'reactstrap'
import ImageItem from './ImageItem'
import FileUpload from './FileUpload'
import StockPhotos from './StockPhotos'
import Input from '~/components/forms/Input'
import useInfiniteScroll from '~/hooks/useInfiniteScroll'
import debouncedEventHandler from '~/helpers/debouncedEventHandler'
import { isEmpty } from '~/utils/utilities'
import { removeImage, getImages, uploadEmailImage } from '~/api/emailImages'
import { convertImageToBase64 } from '~/utils/file.service'
import { NotificationType, showNotification } from '~/utils/Notification'
import { IImage } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { IMetaData } from '~/common.interface'

import './ImageUploadModal.scss'

const PER_PAGE = 16

enum TABS {
  RECENT_UPLOADS = 'recent',
  UPLOAD_NEW = 'new',
  STOCK_PHOTOS = 'stock'
}

const ImageUploadModal = ({
  appId,
  done
}: {
  appId: string
  done: (url: string) => void
}): React.ReactElement => {
  const [isLoadingMoreImages, setIsLoadingMoreImages] = useState(false)
  const [isBusy, setIsBusy] = useState(true)
  const [images, setImages] = useState<IImage[]>([])
  const [meta, setMeta] = useState<IMetaData>()
  const [searchQuery, setSearchQuery] = useState('')
  const hasMore = meta ? meta.dataCount > meta.page * meta.perPage : false
  const [activeTab, setActiveTab] = useState(TABS.RECENT_UPLOADS)

  const fetchImages = async () => {
    setIsBusy(true)
    try {
      const response = await getImages(appId, {
        page: searchQuery || !meta ? 1 : meta?.page,
        searchQuery,
        per_page: PER_PAGE
      })
      setMeta(response.metadata)
      setImages(response.data)
    } catch (error) {
      showNotification(
        'Failed to load images, please try again later.',
        NotificationType.ERROR
      )
    } finally {
      setIsBusy(false)
    }
  }

  const loadMoreImages = async () => {
    if (isLoadingMoreImages) return
    setIsLoadingMoreImages(true)
    try {
      const response = await getImages(appId, {
        page: meta?.page + 1,
        searchQuery,
        per_page: PER_PAGE
      })
      setImages((prevImages) => {
        return [...prevImages, ...response.data]
      })
      setMeta(response.metadata)
    } finally {
      setIsLoadingMoreImages(false)
    }
  }

  useEffect(() => {
    fetchImages()
  }, [appId, searchQuery])

  const handleFileUpload = async (file: File) => {
    const base64 = await convertImageToBase64(file)
    return uploadEmailImage(appId, {
      name: file.name,
      image: base64
    })
  }

  const handleImageSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const handleImageRemoval = async (id: string) => {
    try {
      setIsBusy(true)
      await removeImage(appId, id)
      setImages((prevImages) => prevImages.filter((image) => image.id !== id))
    } catch (error) {
      showNotification(
        'Removing image failed, please try again later.',
        NotificationType.ERROR
      )
    } finally {
      setIsBusy(false)
    }
  }

  const bottomRef = useInfiniteScroll({ hasMore, onLoadMore: loadMoreImages })

  return (
    <ModalBody className="email-image-upload__body">
      <div className="tab mb-0">
        <Nav tabs className="mt-3">
          <NavItem>
            <NavLink
              active={activeTab === TABS.RECENT_UPLOADS}
              onClick={() => {
                setActiveTab(TABS.RECENT_UPLOADS)
              }}>
              Recent images
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === TABS.STOCK_PHOTOS}
              onClick={() => {
                setActiveTab(TABS.STOCK_PHOTOS)
              }}>
              Stock photos <Image className="ms-1" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === TABS.UPLOAD_NEW}
              onClick={() => {
                setActiveTab(TABS.UPLOAD_NEW)
              }}>
              Upload new <UploadCloud className="ms-1" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.RECENT_UPLOADS}>
            <Input
              placeholder="Search"
              onChange={debouncedEventHandler(handleImageSearch, 300)}
              className="email-image-upload__search mb-0"
            />
            <div
              className={`email-image-upload__images-container mt-3 ${
                isBusy ? 'email-image-upload__images-container--busy' : ''
              }`}>
              {!isEmpty(images) && (
                <Row className="pt-3">
                  {images.map((image: IImage) => (
                    <Col xs={3} key={image?.id}>
                      <ImageItem
                        removeImage={handleImageRemoval}
                        image={image}
                        done={done}
                        isLoading={isBusy}
                      />
                    </Col>
                  ))}
                  <div className="w-100" ref={bottomRef}>
                    {' '}
                  </div>
                </Row>
              )}
              {images.length === 0 && !isBusy && (
                <div className="email-image-upload__no-images-info">
                  <p className="text-muted">No Images</p>
                  <Button
                    color="primary"
                    className="py-2"
                    onClick={() => setActiveTab(TABS.UPLOAD_NEW)}>
                    Upload new
                  </Button>
                </div>
              )}
              {isBusy && (
                <div className="email-image-upload__busy-indicator">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="2x"
                    className="email-image-upload__loading"
                  />
                </div>
              )}
              {isLoadingMoreImages && (
                <div className="text-center">
                  <FontAwesomeIcon
                    className="me-1"
                    icon={faSpinner}
                    spin
                    size="lg"
                  />
                </div>
              )}
            </div>
          </TabPane>
          <TabPane tabId={TABS.UPLOAD_NEW}>
            <Row>
              <Col className="d-flex justify-content-between align-items-center">
                <FileUpload
                  uploadFile={handleFileUpload}
                  uploadSuccessfulCallback={(newImage) => {
                    setImages((currentImages) => [newImage, ...currentImages])
                    setActiveTab(TABS.RECENT_UPLOADS)
                  }}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={TABS.STOCK_PHOTOS}>
            {activeTab === TABS.STOCK_PHOTOS && <StockPhotos done={done} />}
          </TabPane>
        </TabContent>
      </div>
    </ModalBody>
  )
}

export default ImageUploadModal
