import { uniqueId } from 'lodash'
import { Edge, Position } from 'reactflow'
import { GoalNotificationDTO } from '~/pages/Campaign/Notification/Notification.interface'
import InAppNotification from '~/pages/Campaign/Notification/InAppNotification/Model/InAppNotification'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { GoalType } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import { timeMultipierToMinutes } from '~/utils/time'
import JourneyEmailNotificationStore from './JourneyBuilder/JourneyNotificationSidebar/EmailNotificationSidebar/Store/JourneyEmailNotificationStore'
import {
  IBlock,
  JourneyBlockType,
  NodeType,
  NotificationModel
} from './JourneyBuilder/Store/JourneyBuilder.interface'
import {
  JourneyGoalsStatsDTO,
  IGoalPerformance
} from './JourneyReport/Store/JourneyReport.interface'
import { journeyFields, JourneyFields } from './Journeys.interface'
import { MCampaignTypeToName } from '../Campaign/CampaignReports/Model/Report.map'
import JourneyCard from './JourneyBuilder/JourneyNotificationSidebar/CardNotificationSidebar/JourneyCard'
import { isString } from '~/utils/utilities'

export function getRedirectEdge(
  sourceBlock: IBlock,
  targetBlock: IBlock
): Edge {
  return {
    id: uniqueId(),
    target: sourceBlock.redirectTo.value,
    source: sourceBlock.id,
    targetHandle: sourceBlock.node.cx > targetBlock?.node.cx ? 'right' : 'left',
    label: 'redirect',
    sourceHandle: Position.Bottom,
    className: 'react-flow__edge--redirect',
    markerEnd: 'markerEnd',
    type: 'redirect'
  }
}

export function getEdge(
  block: IBlock,
  readOnlyMode: boolean
):
  | Edge<{
      last: boolean
      label: string | undefined
      isLeft: boolean
      isBeforeExit: boolean
    }>
  | undefined {
  if (!block.parent) {
    return undefined
  }

  const isSplit = block.parent.node.nodeType === NodeType.SPLIT
  const isLeft = block.parent.children[0].id === block.id
  const isStart = block.parent?.blockType === JourneyBlockType.START
  const label = isLeft ? 'Yes' : 'No'
  const isBeforeExit = block.blockType === JourneyBlockType.EXIT

  return {
    id: uniqueId(),
    source: block.parent.id,
    target: block.id,
    sourcePosition: 'bottom',
    data: {
      last: block.node.last,
      label: isSplit ? label : undefined,
      isLeft,
      isBeforeExit
    },
    type: !isStart && !readOnlyMode ? 'addNewBlockEdge' : 'customEdge'
  }
}

export function getLeafs(root: IBlock, arr: IBlock[]): void {
  // DST - pre order
  if (!root.childrenNodes.length) {
    arr.push(root)
  }

  root.childrenNodes.forEach((child) => {
    getLeafs(child, arr)
  })
}

export function getSplitNodesBeforeNextMessage(block: IBlock): IBlock[] {
  let splitBlocks: IBlock[] = []

  if (block.blockType === JourneyBlockType.SPLIT) {
    splitBlocks.push(block)
  }

  if (block.blockType === JourneyBlockType.MESSAGE) {
    return splitBlocks
  }

  if (block.children) {
    block.children.forEach((child) => {
      splitBlocks = splitBlocks.concat(getSplitNodesBeforeNextMessage(child))
    })
  }

  return splitBlocks
}

export function isBlockWithDelay(
  block: unknown
): block is {
  timeValue: { value: string | number }
  timeFrame: { value: string }
} {
  return 'timeValue' in block
}

export function sumDelays(block: IBlock, accumulator: number): number {
  if (!block.parent) {
    return accumulator
  }

  let delay = 0
  if (isBlockWithDelay(block) && block.timeValue.value) {
    delay =
      +block.timeValue.value *
      (timeMultipierToMinutes[block.timeFrame.value] || 1)
  }

  return sumDelays(block.parent, accumulator + delay)
}

/**
 * Traverse whole tree and prepare matrix where each level represents siblings and cousins (BFS)
 * @param root root block of a tree
 * @returns Matrix of a blocks
 */
export function getBlocksByLevel(root: IBlock): IBlock[][] {
  // BFS
  const levels = [[root], [...root.childrenNodes]]
  const queue: IBlock[] = root.childrenNodes.map((child) => {
    child.node.setLevel(1)
    return child
  })

  while (queue.length) {
    const block = queue.shift()
    if (block?.childrenNodes?.slice().length) {
      let nodeLevel = block.node.level + 1

      if (block.blockType === JourneyBlockType.MESSAGE) {
        //* Message node is higher than others so its children has to be one extra level lower
        nodeLevel += 1
      }

      if (!levels[nodeLevel]) {
        levels[nodeLevel] = []
      }
      levels[nodeLevel].push(...block.childrenNodes.slice())

      queue.push(
        ...block.childrenNodes.map((target) => {
          target.node.setLevel(nodeLevel)
          return target
        })
      )
    }
  }

  return levels.filter(Boolean)
}
/**
 * Recursively look for a block by passed id
 * @param id ID of a desired block
 * @param block Block
 * @returns Block or undefined
 */
export function getBlock(id: string, block?: IBlock): IBlock | undefined {
  // PRE order search
  if (!block) {
    return undefined
  }

  if (block.id === id) {
    return block
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const target of block.children) {
    const node = getBlock(id, target)
    if (node) {
      return node
    }
  }

  return undefined
}

export function getAncestorByType<S extends IBlock = IBlock>(
  blockType: JourneyBlockType,
  block: IBlock | null
): S | undefined {
  if (!block) {
    return undefined
  }

  if (block.blockType === blockType) {
    return block as S
  }

  return getAncestorByType(blockType, block.parent)
}

export function getChildByType(
  blockType: JourneyBlockType,
  block: IBlock | null
): IBlock | undefined {
  if (!block) {
    return undefined
  }

  if (block.blockType === blockType) {
    return block
  }

  return block.children.find((child) => getChildByType(blockType, child))
}

export function getChildrenByType(
  blockTypes: JourneyBlockType[],
  block: IBlock | null
): IBlock[] | undefined {
  if (block) {
    const blocks = getBlocksByLevel(block).flat()
    return blocks.filter((b) => blockTypes.includes(b.blockType))
  }

  return []
}

export const journeyFieldsNamesToUserNames = new Map<JourneyFields, string>([
  ['name', 'Name'],
  ['startAt', 'Start date'],
  ['endAt', 'End date'],
  ['segments', 'Segments'],
  ['beacons', 'Beacons'],
  ['beaconEvents', 'Beacons'],
  ['geofences', 'Geofences'],
  ['geofenceEvents', 'Geofences'],
  ['geofenceDwellingTimes', 'Geofences'],
  ['timeZoneName', 'Time zone'],
  ['timeZoneOffset', 'Time zone offset'],
  ['childBlocks', 'Child blocks'],
  ['options', 'Nofications'],
  ['notifications', 'Notifications'],
  ['goals', 'Goals'],
  ['goalsErrors', 'Goals'],
  ['timeFrame', 'Delay type'],
  ['timeValue', 'Delay'],
  ['conditions', 'Split condition'],
  ['condition', 'Split condition'],
  ['subType', 'Split condition']
])

function isJourneyFieldKey(key: string): key is JourneyFields {
  return journeyFields.some((field) => field === key)
}

function parseError([fieldName, fieldErrors]: [string, string[]]):
  | string
  | undefined {
  if (
    isJourneyFieldKey(fieldName) &&
    fieldErrors.every((error) => isString(error))
  ) {
    return `${journeyFieldsNamesToUserNames.get(
      fieldName
    )} - ${fieldErrors.join(', ')}`
  }

  return undefined
}

export function parseServerJourneyErrors(
  errors: Record<JourneyFields, string[]>
): string[] {
  const messages: string[] = Object.entries(errors)
    .map(parseError)
    .filter((m): m is string => !!m)

  return messages
}

export function getGoalTypesFromNotification(
  notification: NotificationModel
): GoalType[] {
  // TODO: add method to get selectedGoals in each notification model?
  if (notification instanceof PushNotification) {
    return notification.currentVariant?.goals?.selectedGoalTypes || []
  }

  if (
    notification instanceof InAppNotification ||
    notification instanceof JourneyEmailNotificationStore
  ) {
    return notification.goals?.selectedGoalTypes || []
  }

  if (notification instanceof JourneyCard) {
    return notification.card.goals?.selectedGoalTypes || []
  }

  return []
}

export function getPreviewFromNotification(
  notification: NotificationModel
): PartialPreview | undefined {
  if (notification instanceof PushNotification) {
    return notification.generateCurrentVariantPreview()
  }

  return notification.generatePreview()
}

export function getGoalsRowsForMessage(
  goals: JourneyGoalsStatsDTO[]
): IGoalPerformance[] {
  return goals.map((goal) => ({
    id: goal.messageId,
    name: goal.messageName,
    clicks: goal.clicks,
    conversions: goal.conversions,
    delivery: goal.delivery,
    impression: goal.impression,
    subRows: goal.notifications.map((n) => ({
      id: n.notificationId,
      name: MCampaignTypeToName.get(n.notificationType) || '',
      clicks: n.clicks,
      conversions: n.conversions,
      delivery: n.delivery,
      impression: n.impression
    }))
  }))
}

export function getGoalsRowsForNotification(notification: {
  goals: GoalNotificationDTO
  name: string
}): IGoalPerformance[] {
  if (!notification.goals.actions) {
    return []
  }

  return [
    {
      id: uniqueId(),
      name: notification.name,
      clicks: notification.goals.clicks,
      conversions: notification.goals.conversions,
      delivery: notification.goals.delivery,
      impression: notification.goals.impression,
      actions: notification.goals.actions.map((item) => ({
        id: uniqueId(),
        name: item.action,
        conversions: item.conversions
      }))
    }
  ]
}
