import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'
import { ReactElement } from 'react'
import {
  NoticeBar,
  NoticeBarHeader,
  NoticeBarButton
} from '~/components/common/NoticeBar'

const SalesForceIntegrationBar = (): ReactElement => (
  <NoticeBar>
    <NoticeBarHeader
      icon={<FontAwesomeIcon icon={faBullseye} size="3x" />}
      header="Automation is coming to Pulsate!"
      subHeader="Join the early adopters program"
    />
    <NoticeBarButton url="https://info.pulsatehq.com/automation-waitlist">
      How to Join
    </NoticeBarButton>
  </NoticeBar>
)

export default SalesForceIntegrationBar
