import { ReactElement } from 'react'
import InAppSmallModel from '~/pages/Campaign/Notification/InAppNotification/Model/InAppSmall.model'
import { withStore } from '~/dataStore'
import SeparatorLine from '~/components/SeparatorLine'
import AdminHeaderWithMessage from '../../../../CampaignBuilder/MobileCampaignPanel/AdminHeaderWIthMessage.field'
import RecordEvent from '../../../../CampaignBuilder/RecordEvent'
import InAppPositionSwitch from './InAppPositionSwitch'
import Shared from '~/dataStore/Campaign/Shared'

function InAppSmallPersonal({
  notification,
  shared,
  disabled = false
}: {
  notification: InAppSmallModel
  shared: Shared
  disabled?: boolean
}): ReactElement | null {
  const { mergeTags, admins, eventsOptions } = shared

  return (
    <>
      <AdminHeaderWithMessage
        admins={admins}
        onSelect={notification.setAdmin}
        selected={notification.admin}
        onChange={notification.adminHeader.setValue}
        value={notification.adminHeader.value}
        invalid={!notification.adminHeader.isValid}
        disabled={disabled}
        onBlur={() => {
          notification.adminHeader.validate()
          notification.validateAdminHeaderInvalidChars()
        }}
        mergeTags={mergeTags}
        placeholder="Add an In-App notification..."
        max={84}
      />
      <p className="info-box">
        The personal message text included here will be appear in the personal
        message at the front of the feed post in the following step.
      </p>

      <SeparatorLine />

      <InAppPositionSwitch
        notification={notification}
        radioName="position-personal"
      />

      <div className="py-3">
        <RecordEvent eventsOptions={eventsOptions} field={notification.cta} />
      </div>
    </>
  )
}

export default withStore(InAppSmallPersonal)
