import React, { useState } from 'react'
import { getSmoothStepPath, EdgeProps, EdgeLabelRenderer } from 'reactflow'
import cn from 'classnames'
import styles from './AddNewBlockEdge.scss'
import { JourneyBlockType } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import AddNewBlockButton from './AddNewBlockButton'

const foreignObjectSize = 33

const CREATABLE_BLOCKS = [
  JourneyBlockType.MESSAGE,
  JourneyBlockType.SPLIT,
  JourneyBlockType.DELAY,
  JourneyBlockType.ADDTOSEGMENT,
  JourneyBlockType.REDIRECT
]

const tooltips: Record<JourneyBlockType | string, string> = {
  [JourneyBlockType.MESSAGE]: 'Add a New Message',
  [JourneyBlockType.SPLIT]: 'Add a Split Condition',
  [JourneyBlockType.DELAY]: 'Add a Time Delay',
  [JourneyBlockType.ADDTOSEGMENT]: 'Add an Auto Segment',
  [JourneyBlockType.REDIRECT]: 'Add a Redirect',
  [`${JourneyBlockType.REDIRECT}Disabled`]:
    'Redirect is only available at the end of paths'
}

function getTooltip(
  blockType: JourneyBlockType,
  isBeforeExit: boolean
): string {
  if (blockType === JourneyBlockType.REDIRECT && !isBeforeExit) {
    return tooltips[`${JourneyBlockType.REDIRECT}Disabled`]
  }
  return tooltips[blockType]
}

export default function AddNewBlockEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data
}: EdgeProps<{
  last: boolean
  label: string | undefined
  createBlock?: (blockType: JourneyBlockType) => unknown
  isBeforeExit: boolean
}>): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [path, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  const onCreateBlock = async (blockType: JourneyBlockType) => {
    try {
      setIsLoading(true)
      await data?.createBlock?.(blockType)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <path
        id={id}
        style={style}
        className="custom-edge react-flow__edge-path"
        d={path}
        markerEnd={markerEnd}
      />
      {data?.label && (
        <g
          className="react-flow__edge-textwrapper"
          transform={`translate(${labelX - (data.isLeft ? -80 : 110)}, ${
            labelY - 5
          })`}>
          <text className="react-flow__edge-text" dy="-0.3em">
            {data.label}
          </text>
        </g>
      )}
      <EdgeLabelRenderer>
        <div
          className={cn(styles.edgeForeignobject, 'add-new-block-edge')}
          style={{
            width: foreignObjectSize,
            height: foreignObjectSize,
            transform: `translate(-46%, 110%) translate(${labelX}px,${labelY}px)`
          }}>
          <div className={styles.addNew}>
            <div id={id} className={styles.menu}>
              <input
                type="checkbox"
                className={styles.menuOpen}
                name="menu-open"
                id={`${id}-menu`}
              />
              <label htmlFor={`${id}-menu`} className="mb-0">
                <span className={styles.menuOpenBtn} />
              </label>

              {CREATABLE_BLOCKS.map((blockType) => (
                <AddNewBlockButton
                  key={blockType}
                  blockType={blockType}
                  onClick={() => onCreateBlock(blockType)}
                  isLoading={isLoading}
                  disabled={
                    isLoading ||
                    (blockType === JourneyBlockType.REDIRECT &&
                      !data?.isBeforeExit)
                  }
                  tooltip={getTooltip(blockType, !!data?.isBeforeExit)}
                />
              ))}
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            className={styles.shadow}>
            <defs>
              {/* We can choose effect with or without shadow */}
              <filter id="shadowed-goo" colorInterpolationFilters="sRGB">
                <feGaussianBlur
                  in="SourceGraphic"
                  result="blur"
                  stdDeviation="10"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  result="goo"
                />
                <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
                <feColorMatrix
                  in="shadow"
                  mode="matrix"
                  values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2"
                  result="shadow"
                />
                <feOffset in="shadow" dx="1" dy="1" result="shadow" />
                <feComposite in2="shadow" in="goo" result="goo" />
                <feComposite in2="goo" in="SourceGraphic" result="mix" />
              </filter>
              {/* <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              result="blur"
              stdDeviation="10"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="goo"
            />
            <feComposite in2="goo" in="SourceGraphic" result="mix" />
          </filter> */}
            </defs>
          </svg>
        </div>
      </EdgeLabelRenderer>
    </>
  )
}
