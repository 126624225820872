import { action, computed, makeObservable, observable, reaction } from 'mobx'
import moment from 'moment'
import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import EmailCampaign from './EmailCampaign'
import SMSCampaign from './SMSCampaign'
import {
  createCampaignFromTemplate,
  createDraftCampaign,
  duplicateCampaign,
  fetchCampaign,
  fetchCampaignPreview,
  updateCampaign
} from '~/api/campaigns'
import SetUp from '../emailBuilder/SetUp'
import EmailTemplates from '~/pages/Campaign/Notification/EmailNotification/Model/EmailTemplates'
import EmailNotification from '../../pages/Campaign/Notification/EmailNotification/Model/EmailNotification'
import Review from '../emailBuilder/Review'
import { IStep } from '../emailBuilder/EmailBuilder.interface'
import Targeting from '../emailBuilder/Targeting/Targeting'
import Deliver from '../emailBuilder/Deliver/Deliver'
import {
  generateDisplayType,
  getMainCampaignType,
  getAdditionalCampaignType
} from '~/pages/Campaign/Notification/Notification.service'
import {
  ActionTypes,
  CampaignStatus,
  CampaignType,
  ICampaign,
  ICampaignModel,
  ICurrentStep,
  IPreview,
  IStepStore,
  StepStoreFactory
} from './Campaign.interface'
import {
  ICampaignReview,
  Preview
} from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { getCampaignTypeOptions } from '../CampaignTypeFacade'

import { IStore } from '../Store.interface'
import {
  MCampaignToNotification,
  MCardCampaignToNotification
} from '../emailBuilder/EmailBuilder.map'
import SMS from '../emailBuilder/SMS/SMS'
import InAppCampaign from './InAppCampaign'
import InAppNotification from '../../pages/Campaign/Notification/InAppNotification/Model/InAppNotification'
import { getParams } from '~/routes'
import PushCampaign from './PushCampaign'
import Shared from './Shared'
import CardCampaign from './CardCampaign'
import CardNotification from '../../pages/Campaign/Notification/CardNotification/Model/CardNotification'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

export default class Campaign implements ICampaignModel {
  shared = new Shared()

  rootStore: IStore

  campaignId: string | undefined = undefined

  duplicationSourceId: string | null = null

  status: CampaignStatus | undefined = undefined

  campaignType: CampaignType[] | undefined = undefined

  preview: IPreview | undefined = undefined

  stepStores: StepStoreFactory[] = []

  currentStep: ICurrentStep

  disposers: (() => void)[] = []

  forceShowTooltip = false

  hasChanged = true

  isCampaignSaving: boolean | null = null

  mode: 'details' | 'builder' = 'builder'

  constructor(
    rootStore: IStore,
    campaignType?: CampaignType,
    notificationType?: CampaignType
  ) {
    makeObservable<
      Campaign,
      'initStores' | 'setStep' | 'fillCampaign' | 'populateStoresWithData'
    >(this, {
      campaignId: observable,
      status: observable,
      campaignType: observable,
      preview: observable,
      stepStores: observable,
      currentStep: observable,
      forceShowTooltip: observable,
      hasChanged: observable,
      isCampaignSaving: observable,
      mode: observable,
      init: action,
      initStores: action,
      isFinalStep: computed,
      setForceShowTooltip: action.bound,
      setHasChanged: action.bound,
      setStep: action,
      setCampaignSaving: action.bound,
      toggleCardBackStore: action.bound,
      setStatus: action.bound,
      setStepByNumber: action.bound,
      nextStep: action.bound,
      isAutosaveActive: computed,
      fillCampaign: action.bound,
      initExistingCampaign: action.bound,
      populateStoresWithData: action.bound,
      isEmailCampaign: computed,
      isCardCampaign: computed,
      isCardInAppCampaign: computed,
      isInAppCampaign: computed,
      isCardOrInAppCampaign: computed,
      isSended: computed,
      isSendedOrScheduled: computed,
      reviewData: computed,
      setUp: computed,
      sms: computed,
      inApp: computed,
      push: computed,
      card: computed,
      templates: computed,
      email: computed,
      targeting: computed,
      deliver: computed,
      review: computed,
      steps: computed
    })

    this.rootStore = rootStore

    this.saveCampaign = this.saveCampaign.bind(this)
    this.getAppId = this.getAppId.bind(this)
    this.addDisposer = this.addDisposer.bind(this)
    this.getStepNameByNumber = this.getStepNameByNumber.bind(this)
    this.disposeReactions = this.disposeReactions.bind(this)
    this.autoSaveCampaign = this.autoSaveCampaign.bind(this)

    this.init(campaignType, notificationType)
  }

  public init(...rest: (CampaignType | undefined)[]): void {
    const types: CampaignType[] = rest.filter(Boolean) as CampaignType[]
    this.campaignType = types.length ? types : undefined

    this.initStores()

    this.currentStep = {
      number: 1,
      store: this.setUp
    }
  }

  private initStores(): void {
    switch (getMainCampaignType(this.campaignType, 'campaignBuilder')) {
      case CampaignType.EMAIL:
        this.stepStores = EmailCampaign.createStepStores(this)
        break
      case CampaignType.SMS:
        this.stepStores = SMSCampaign.createStepStores(this)
        break
      case CampaignType.IN_APP:
        this.stepStores = InAppCampaign.createStepStores(this)
        break
      case CampaignType.PUSH:
        this.stepStores = PushCampaign.createStepStores(this)
        break
      case CampaignType.CARD:
        this.stepStores = CardCampaign.createStepStores(
          this,
          getAdditionalCampaignType(this.campaignType, 'campaignBuilder')
        )
        break
      default:
        this.stepStores = EmailCampaign.createStepStores(this)
    }
  }

  private getStoreByName<T extends IStepStore>(name: string): T {
    return this.stepStores.find((store) => store.name === name)?.store as T
  }

  private getStoreByNumber<T extends IStepStore>(number: number): T {
    return this.stepStores.find((store) => store.position === number)
      ?.store as T
  }

  public getStepNameByNumber(number: number): string | undefined {
    return this.stepStores.find((store) => store.position === number)?.name
  }

  public get isFinalStep(): boolean {
    return this.getStepNameByNumber(this.currentStep.number) === 'review'
  }

  public getAppId(): string | undefined {
    return this.rootStore?.app?.currentApp.id
  }

  public setForceShowTooltip(value: boolean): void {
    this.forceShowTooltip = value
  }

  public setHasChanged(value: boolean): void {
    this.hasChanged = value
  }

  private setStep(step: ICurrentStep): void {
    this.currentStep = step
  }

  public setCampaignSaving(value: boolean): void {
    this.isCampaignSaving = value
  }

  public toggleCardBackStore(destinationType: ActionTypes | undefined): void {
    if (destinationType === ActionTypes.CARD_BACK) {
      if (this.stepStores.some((store) => store.name === 'cardBack')) {
        return
      }
      const cardBackTargetPosition =
        this.stepStores.findIndex((store) => store.name === 'cardFront') + 1

      this.stepStores = [
        ...this.stepStores.slice(0, cardBackTargetPosition),
        {
          name: 'cardBack',
          displayName: MCampaignTypeToName.get(CampaignType.CARD_BACK),
          store: this.card.cardBack
        },
        ...this.stepStores.slice(cardBackTargetPosition)
      ].map((step, index) => ({ ...step, position: index + 1 }))
    } else {
      this.stepStores = this.stepStores
        .filter((store) => store.name !== 'cardBack')
        .map((step, index) => ({ ...step, position: index + 1 }))
    }
  }

  setStatus(status: CampaignStatus): void {
    this.status = status
  }

  public setStepByNumber(number: number): void {
    if (this.arePrevStepsValid(number)) {
      this.setStep({
        number,
        store: this.getStoreByNumber(number)
      })
    } else {
      this.rootStore.goTo(undefined, {}, { step: this.currentStep.number })
    }
  }

  private arePrevStepsValid(number: number): boolean {
    const prevNumber = number - 1
    if (prevNumber < 1) {
      return true
    }

    const steps = this.stepStores.filter((store) => store.position < number)

    steps.forEach((step) => {
      const { store } = step
      store?.validateStep()
    })

    return steps.every((step) => step.store.isStepValid)
  }

  nextStep(): void {
    const nextStepNumber = this.currentStep.number + 1
    this.rootStore.goTo(undefined, {}, { step: nextStepNumber })
  }

  public get isAutosaveActive(): boolean {
    return this.status === CampaignStatus.DRAFT
  }

  public async autoSaveCampaign(appId: string): Promise<void> {
    if (this.hasChanged || !this.status) {
      try {
        this.setCampaignSaving(true)
        const response = await this.saveCampaign(appId, true)
        if (!this.campaignId) {
          this.fillCampaign(response)

          this.rootStore.replaceTo('campaignBuilder', {
            appId,
            campaignType: getMainCampaignType(
              this.campaignType,
              'campaignBuilder'
            ),
            campaignId: this.campaignId,
            size: this.inApp?.size,
            notificationType: getAdditionalCampaignType(
              this.campaignType,
              'campaignBuilder'
            )
          })
          this.trackChanges()
        }
        this.setHasChanged(false)
      } finally {
        this.setCampaignSaving(false)
      }
    }
  }

  private fillCampaign({
    id,
    typeList,
    status,
    duplicationSourceId
  }: Pick<ICampaign, 'id' | 'typeList' | 'status' | 'duplicationSourceId'>) {
    this.campaignId = id
    this.campaignType = typeList
    this.status = status
    this.duplicationSourceId = duplicationSourceId
  }

  public async initExistingCampaign(
    appId: string,
    campaignId: string,
    duplicate = false,
    fromTemplate = false,
    abortSignal?: AbortSignal
  ): Promise<void> {
    try {
      let response: ICampaign
      if (fromTemplate) {
        response = await createCampaignFromTemplate(
          appId,
          campaignId,
          abortSignal
        )
      } else if (duplicate) {
        response = await duplicateCampaign(appId, campaignId, abortSignal)
      } else {
        response = await fetchCampaign(
          appId,
          campaignId,
          undefined,
          abortSignal
        )
      }

      this.init(...(response.typeList || []))

      await this.fetchTargetingLists(appId, response)
      this.fillCampaign({
        id: duplicate || fromTemplate ? undefined : response.id,
        typeList: response.typeList,
        status: duplicate || fromTemplate ? undefined : response.status,
        duplicationSourceId: response.duplicationSourceId
      })

      if (
        this.isCardCampaign &&
        response.cardNotification?.frontParts.callToAction.buttons.some(
          (btn) => btn.destinationType === ActionTypes.CARD_BACK
        )
      ) {
        this.toggleCardBackStore(ActionTypes.CARD_BACK)
      }

      this.populateStoresWithData(response)
      this.trackChanges()

      if (this.mode === 'builder' && !duplicate && !fromTemplate) {
        this.replaceLocation(response.lastBuilderPage)
      }
      this.setHasChanged(false)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private replaceLocation(lastBuilderPage: number | string = 1): void {
    const params = getParams('campaignBuilder') || {}

    if (this.inApp?.size) {
      if (params.size && this.inApp.size !== params.size) {
        this.rootStore.replaceTo('newCampaign', params)
        return
      }

      params.size = this.inApp.size
    }

    this.rootStore.replaceTo('campaignBuilder', params, {
      step: Number(lastBuilderPage)
    })
  }

  private fetchTargetingLists(
    appId: string,
    response: ICampaign
  ): Promise<void[]> {
    const promises = [
      this.setUp?.fetchEvents(
        appId,
        getMainCampaignType(this.campaignType, 'campaignBuilder')
      )
    ]

    const { targeting, push } = this

    if (response.segmentIds?.length) {
      promises.push(targeting?.segments.fetchSegments(appId))
    }
    if (response.beaconIds?.length) {
      promises.push(targeting?.beacons.fetchBeacons(appId))
    }
    if (response.geofenceIds?.length) {
      promises.push(targeting?.geofences.fetchGeofences(appId))
    }
    if (response.inAppEventNames?.length) {
      promises.push(targeting?.events.fetchEvents(appId))
    }

    if (response.pushVariants?.[0]?.buttonCategoryId) {
      promises.push(push?.shared.fetchButtonCategories())
    }

    return Promise.all(promises)
  }

  public async fetchCampaignPreview(
    appId: string,
    campaignId: string
  ): Promise<void> {
    try {
      this.preview = await fetchCampaignPreview(appId, campaignId)
    } catch (error) {
      console.error(error)
    }
  }

  private populateStoresWithData(response: ICampaign): void {
    this.stepStores.forEach((stepStore) => stepStore.store.fillStore(response))
  }

  public get isEmailCampaign(): boolean {
    return (
      getMainCampaignType(this.campaignType, 'campaignBuilder') ===
      CampaignType.EMAIL
    )
  }

  get isCardCampaign(): boolean {
    return (
      getMainCampaignType(this.campaignType, 'campaignBuilder') ===
      CampaignType.CARD
    )
  }

  get isCardInAppCampaign(): boolean {
    return (
      getMainCampaignType(this.campaignType, 'campaignBuilder') ===
        CampaignType.CARD &&
      getAdditionalCampaignType(this.campaignType, 'campaignBuilder') ===
        CampaignType.IN_APP
    )
  }

  get isInAppCampaign(): boolean {
    return (
      CampaignType.IN_APP ===
      getMainCampaignType(this.campaignType, 'campaignBuilder')
    )
  }

  // Check if campaign is card type or has InApp component
  get isCardOrInAppCampaign(): boolean {
    return this.isCardCampaign || this.isInAppCampaign
  }

  public get isSended(): boolean {
    if (!this.status) return false

    return [
      CampaignStatus.ACTIVE,
      CampaignStatus.DELIVERED,
      CampaignStatus.PAUSED
    ].includes(this.status)
  }

  public get isSendedOrScheduled(): boolean {
    return this.isSended || CampaignStatus.SCHEDULED === this.status
  }

  public saveCampaign(
    appId: string,
    asDraft?: boolean,
    abortSignal?: AbortSignal
  ): Promise<ICampaign> {
    if (asDraft) {
      this.setUp.validateName()
      if (!this.setUp.name.isValid) {
        throw new Error('validation')
      }
    } else if (!this.validate()) {
      // TODO: display all error messages in bottomActions (currently are took from currentstep only)
      throw new Error('validation')
    }

    return this.sendCampaign(appId, asDraft, abortSignal)
  }

  public validate(): boolean {
    return this.stepStores
      .map((stepStore) => {
        stepStore.store.validateStep()
        return stepStore.store
      })
      .every((step) => step.isStepValid)
  }

  private async sendCampaign(
    appId: string,
    asDraft?: boolean,
    abortSignal?: AbortSignal
  ): Promise<ICampaign> {
    const campaign: ICampaign = await this.getPayload(asDraft)

    if (this.campaignId) {
      return updateCampaign(appId, this.campaignId, campaign, abortSignal)
    }

    return createDraftCampaign(appId, campaign, abortSignal)
  }

  private async getPayload(asDraft?: boolean): Promise<ICampaign> {
    const mainCampaignType = getMainCampaignType(
      this.campaignType,
      'campaignBuilder'
    )
    let type = MCampaignToNotification.get(mainCampaignType)

    if (this.isCardCampaign) {
      const notificationType = getAdditionalCampaignType(
        this.campaignType,
        'campaignBuilder'
      )
      if (notificationType) {
        type = MCardCampaignToNotification.get(notificationType)
      }
    }

    if (!type) {
      throw new Error('Missing campaign type')
    }

    const notificationPayload = await this.getNotificationPayload(
      mainCampaignType
    )

    return {
      stateMachineNotificationsState: asDraft ? 'draft' : 'initial',
      duplicationSourceId: this.duplicationSourceId,
      type,
      lastBuilderPage: this.isFinalStep ? '1' : this.currentStep.number,

      ...this.setUp.getPayload(),

      ...notificationPayload,

      ...this.targeting.getPayload(),

      ...this.deliver.getPayload(!!asDraft)
    }
  }

  private async getNotificationPayload(
    type: CampaignType | undefined
  ): Promise<
    | Pick<
        ICampaign,
        | 'emailNotification'
        | 'smsNotification'
        | 'inAppNotification'
        | 'pushVariants'
        | 'cardNotification'
      >
    | { [x: string]: never }
  > {
    switch (type) {
      case CampaignType.EMAIL:
        return {
          emailNotification: {
            ...this.templates.getPayload(),
            ...this.email.getPayload()
          }
        }
      case CampaignType.SMS:
        return { smsNotification: this.sms.getPayload() }
      case CampaignType.IN_APP:
        return {
          inAppNotification: await this.inApp.getPayload()
        }
      case CampaignType.PUSH:
        return {
          pushVariants: await this.push.getPayload()
        }
      case CampaignType.CARD:
        return {
          cardNotification: await this.card.getPayload(),
          ...(await this.getNotificationPayload(
            getAdditionalCampaignType(this.campaignType, 'campaignBuilder')
          ))
        }
      default:
        return {}
    }
  }

  private getDesignPreview(
    type: CampaignType | undefined
  ): Preview | undefined {
    switch (type) {
      case CampaignType.SMS:
        return this.sms.generatePreview()
      case CampaignType.IN_APP:
        return this.inApp.generatePreview()
      case CampaignType.PUSH:
        return this.push.generateAllVariantsPreview()
      case CampaignType.CARD:
        return this.getCardPreview()
      default:
        return undefined
    }
  }

  private getCardPreview(): Preview {
    const cardPreview = this.card.generatePreview()
    const notificationPreview = this.getDesignPreview(
      getAdditionalCampaignType(this.campaignType, 'campaignBuilder')
    )
    return {
      ...cardPreview,
      ...notificationPreview,
      type: [...(notificationPreview?.type || []), ...cardPreview.type]
    }
  }

  public addDisposer(disposer: () => void): void {
    this.disposers.push(disposer)
  }

  public disposeReactions(): void {
    this.disposers.forEach((disposer) => disposer())
    this.disposers = []
  }

  private trackChanges(): void {
    if (this.mode !== 'builder') {
      return
    }

    this.addDisposer(
      reaction(
        () => ({
          step: this.currentStep.number,
          values: this.currentStep.store.registeredFields.map((field) => {
            return field.value?.value ?? field.value
          })
        }),
        () => {
          this.setHasChanged(true)
        },
        {
          equals: (prev, current) => {
            if (prev.step !== current.step) {
              return true
            }
            return false
          }
        }
      )
    )
  }

  public get reviewData(): ICampaignReview {
    const payload: ICampaignReview = {
      campaignType: getMainCampaignType(this.campaignType, 'campaignBuilder'),
      campaignSubType: getAdditionalCampaignType(
        this.campaignType,
        'campaignBuilder'
      ),
      displayType: generateDisplayType(
        this.campaignType || [],
        this.card?.isCardBack
      ),
      mode: this.mode,
      setUp: this.setUp,
      targeting: this.targeting,
      deliver: this.deliver,
      isDetailsInPast:
        this.mode === 'details' &&
        moment(this.deliver?.startAt.value).isBefore()
    }

    if (!this.campaignId) {
      return payload
    }

    if (this.isEmailCampaign) {
      const { templates, email } = this
      payload.template = {
        subject: templates?.subject.value,
        fromEmailAddress: templates?.fromEmailAddress.value.label,
        fromName: templates?.fromName.value.label,
        html: email.getPayload().html
      }
    } else if (this.preview) {
      const types = getCampaignTypeOptions(this.preview.type, this.preview)
      payload.preview = {
        ...this.preview,
        type: types
      }
    } else if (this.mode === 'builder') {
      payload.preview = this.getDesignPreview(
        getMainCampaignType(this.campaignType, 'campaignBuilder')
      )
    }

    return payload
  }

  get setUp(): SetUp {
    return this.getStoreByName<SetUp>('setUp')
  }

  get sms(): SMS {
    return this.getStoreByName<SMS>('sms')
  }

  get inApp(): InAppNotification {
    return this.getStoreByName<InAppNotification>('inApp')
  }

  get push(): PushNotification {
    return this.getStoreByName<PushNotification>('push')
  }

  get card(): CardNotification {
    return this.getStoreByName<CardNotification>('cardFront')
  }

  get templates(): EmailTemplates {
    return this.getStoreByName<EmailTemplates>('templates')
  }

  get email(): EmailNotification {
    return this.getStoreByName<EmailNotification>('email')
  }

  get targeting(): Targeting {
    return this.getStoreByName<Targeting>('targeting')
  }

  get deliver(): Deliver {
    return this.getStoreByName<Deliver>('deliver')
  }

  get review(): Review {
    return this.getStoreByName<Review>('review')
  }

  get steps(): IStep[] {
    return this.stepStores.map((step) => ({
      number: step.position,
      title: step.displayName,
      isValid: step.store.isStepValid,
      beenValid: step.store.beenValid
    }))
  }
}
