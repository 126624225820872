import React from 'react'
import cn from 'classnames'
import { Line } from 'react-chartjs-2'
import { ChartOptions } from 'chart.js'
import 'chart.js/auto'
import moment from 'moment'

import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import colors from '~/utils/color'

interface IProps {
  title: string
  chartData: {
    label: string
    color?: string
    data: { x: string; y: string }[]
  }[]
}

const options: ChartOptions<'line'> = {
  maintainAspectRatio: false,
  hover: {
    intersect: true
  },
  plugins: {
    filler: {
      propagate: false
    },
    legend: {
      display: false
    },
    tooltip: {
      intersect: false
    }
  },
  scales: {
    x: {
      grid: {
        color: 'rgba(0,0,0,0.05)'
      }
    },
    y: {
      ticks: {
        stepSize: 500
      },
      display: true,
      border: {
        dash: [5, 5]
      },
      grid: {
        color: 'rgba(0,0,0,0)'
        // fontColor: '#fff'
      }
    }
  }
}

function prepareDataForChart(chartData: IProps['chartData']) {
  // Get the labels for the X axis from the first dataset
  const labels = chartData[0].data.map((ds) => moment(ds.x).format('MMM D'))

  // Get line label and
  const lines = chartData.map(({ label, color, data }) => ({
    label,
    data: data.map((d) => d.y),
    backgroundColor: 'transparent' || colors.primary,
    borderColor: color || colors.primary,
    borderWidth: 2,
    tension: 0.4,
    fill: true
  }))

  return { labels, datasets: lines }
}

const LineChart = ({ title, chartData }: IProps): React.ReactElement => (
  <Card className={cn('flex-fill', 'w-100')}>
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
        {title}
      </CardTitle>
    </CardHeader>
    <CardBody className="py-3">
      <div className={cn('chart', 'chart-sm')}>
        <Line data={prepareDataForChart(chartData)} options={options} />
      </div>
    </CardBody>
  </Card>
)

export default LineChart
