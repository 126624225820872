import React from 'react'
import { Button } from 'reactstrap'
import cn from 'classnames'
import Link from '~/components/Link'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'
import { useStore, withStore } from '~/dataStore'
import { Route } from '~/routes'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import { ID } from '~/common.interface'

import { reportBtn, reportBtnCompact, reportBtnDisabled } from './style.scss'

interface IProps {
  status: CampaignStatus | JourneyStatus
  id: ID
  paramName: 'campaignId' | 'journeyId'
  route: Route
  compact?: boolean
}

function ReportBtn({
  status,
  id,
  paramName,
  route,
  compact
}: IProps): React.ReactElement {
  const { app } = useStore()

  // TODO: make one general type for statuses
  const isDisabled = [
    CampaignStatus.DRAFT,
    CampaignStatus.SCHEDULED,
    JourneyStatus.DRAFT,
    JourneyStatus.SCHEDULED,
    JourneyStatus.PREPARING
  ].includes(status)

  return (
    <Button
      tag={Link}
      params={{ appId: app.currentApp.id, [paramName]: id }}
      route={!isDisabled ? route : ''}
      color={!isDisabled ? 'body' : 'gray'}
      className={cn(reportBtn, {
        [reportBtnCompact]: compact,
        [reportBtnDisabled]: isDisabled
      })}
      outline
      disabled={isDisabled}>
      View
    </Button>
  )
}

export default withStore(ReportBtn)
