import { Button } from 'reactstrap'
import cn from 'classnames'
import GoalIconWithPlaceholder from '~/pages/Campaign/Notification/NotificationGoals/UI/GoalIconWithPlaceholder'
import { GoalType } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import { getGoalTypesFromNotification } from '~/pages/Journeys/Journey.service'
import { INotification } from '../../Store/JourneyBuilder.interface'
import { withStore } from '~/dataStore'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

interface IProps {
  notification: INotification
  setNotification: (notification: INotification) => void
  readonly?: boolean
}

function NotificationRow({
  notification,
  setNotification,
  readonly
}: IProps): React.ReactElement {
  let goals: GoalType[] = []

  if (notification.notification) {
    goals = getGoalTypesFromNotification(notification.notification)
  }

  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div className="flex-1-1">
        {MCampaignTypeToName.get(notification.type)}
      </div>
      <div className="d-flex flex-1-1">
        <GoalIconWithPlaceholder goals={goals} type="primary" />
        <GoalIconWithPlaceholder goals={goals} type="secondary" />
      </div>
      <Button
        color=""
        className={cn('bg-white fw-medium px-4 btn--hover btn-outline-body', {
          'error-input': !notification.isValid
        })}
        onClick={() => {
          setNotification(notification)
        }}>
        {readonly ? 'View Notification' : 'Set up'}
      </Button>
    </div>
  )
}

export default withStore(NotificationRow)
