import cn from 'classnames'
import { forwardRef, PropsWithChildren } from 'react'
import { DeviceType } from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  side?: 'front' | 'back' | undefined
  position?: 'top' | 'bottom'
  size?: 'small' | 'large'
  deviceType?: DeviceType
  emptyPreview?: boolean
  className?: string
}

function PreviewCenterBox({
  children,
  withWrapper,
  emptyPreview
}: {
  children: React.ReactElement
  withWrapper: boolean
  emptyPreview?: boolean
}): JSX.Element {
  if (withWrapper) {
    return (
      <div className="min-h-100 d-flex flex-column justify-content-center">
        {!emptyPreview && <div className="preview__exit" />}
        {children}
      </div>
    )
  }

  return children
}

export default forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  function PreviewCard(
    { children, side, position, size, deviceType, emptyPreview, className },
    ref
  ): JSX.Element {
    return (
      <PreviewCenterBox
        withWrapper={size === 'large'}
        emptyPreview={emptyPreview}>
        <div
          ref={ref}
          className={cn(
            'preview__card',
            { [`preview__card--${side}`]: side },
            { [`preview__card--${size}`]: size },
            { [`preview__card--${position}`]: position },
            { [`preview__card--${deviceType}`]: deviceType },
            className
          )}>
          {children}
        </div>
      </PreviewCenterBox>
    )
  }
)
