import { uniqueId } from 'lodash'
import { action, observable, makeObservable, override } from 'mobx'
import { DNDAble, DNDMixin, ToggleAble, ToggleMixin } from '~/utils/mixins'
import { isEmpty } from '~/utils/utilities'
import { hasInvalidBuilderCharacters } from '~/utils/validations'
import { IOption, ValidationTypes } from '../EmailBuilder.interface'
import NewRegisteredField from '../RegisteredField.model'

export default class TableField extends NewRegisteredField<string | undefined> {
  rows: NewRegisteredField<IOption & { id: string }>[] = [
    new NewRegisteredField({ value: '', label: '', id: uniqueId() })
  ]

  constructor(initialValue: string | undefined) {
    super(initialValue)

    makeObservable(this, {
      rows: observable,
      addEmptyRow: action.bound,
      removeRow: action.bound,
      fillField: override,
      validate: action
    })
  }

  public addEmptyRow(): void {
    this.rows.push(
      new NewRegisteredField({ value: '', label: '', id: uniqueId() })
    )
  }

  public removeRow(id: string): void {
    this.rows = this.rows.filter((row) => row.value.id !== id)
    this.validate()
  }

  public getRowsPayload(): IOption[] {
    return this.rows.map((field) => ({
      value: field.value.value,
      label: field.value.label
    }))
  }

  public fillField({
    rows,
    value
  }: {
    value: string | undefined
    rows: IOption[] | null
  }): void {
    super.fillField({ value })

    const rowsOrEmpty = rows?.length ? rows : [{ value: '', label: '' }]

    this.rows = rowsOrEmpty.map(
      (row) => new NewRegisteredField({ ...row, id: uniqueId() })
    )

    this.beenValid = true
  }

  //* don't bound this method as it overrides validate from ToggleMixin
  public validate(): boolean {
    this.resetError()

    if (
      this.rows.some(
        (row) => isEmpty(row.value.value) || isEmpty(row.value.label)
      )
    ) {
      this.isValid = false
      this.errors.push({
        message: 'Add your Labels and Values or turn off section',
        type: ValidationTypes.REQUIRED
      })
    } else if (
      this.rows.some(
        (row) =>
          hasInvalidBuilderCharacters(row.value.value) ||
          hasInvalidBuilderCharacters(row.value.label)
      )
    ) {
      this.isValid = false
      this.errors.push({
        message: `Table: The following characters are not allowed: '<', '>', '[', ']'.`,
        type: ValidationTypes.FORMAT
      })
    }
    return this.isValid
  }
}

export type IToggleDNDTableField = ToggleAble & DNDAble & TableField
export const ToggleDNDTableField = ToggleMixin(DNDMixin(TableField))
