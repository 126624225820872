import React, { ChangeEvent, useRef } from 'react'
import SelectWithSearch from '~/components/forms/SelectWithSearch'
import Input from '~/components/forms/Input'
import useInputCursorPosition from '~/hooks/useInputCursorPosition'
import { withStore } from '~/dataStore'

import './EmailSubjectInput.scss'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'

interface IProps {
  subject: NewRegisteredField<string>
  mergeTags: string[]
  setEmailSubject: (value: string) => void
  validateEmailSubject: (e: ChangeEvent<HTMLInputElement>) => void
}

const EmailSubjectInput = ({
  subject,
  mergeTags,
  setEmailSubject,
  validateEmailSubject
}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const inputCursorPosition = useInputCursorPosition(inputRef)

  const onTagSelected = (tagSelected: IOption) => {
    if (inputRef.current) {
      const formattedValue = `${subject.value.slice(
        0,
        inputCursorPosition || 0
      )}{{${tagSelected.value}}}${subject.value.slice(
        inputCursorPosition || 0
      )}`

      setEmailSubject(formattedValue)
      inputRef.current.focus()
    }
  }

  return (
    <Input
      className="subject-input"
      name="emailSubject"
      inputRef={inputRef}
      label="Email Subject"
      suffix={
        <SelectWithSearch
          right
          toggleClassName="p-0"
          onChange={onTagSelected}
          controlShouldRenderValue={false}
          placeholder="Insert"
          selectPlaceholder="Search..."
          dropdownWidth={300}
          options={mergeTags.map((tag) => ({
            label: tag,
            value: tag
          }))}
        />
      }
      type="text"
      placeholder="Enter email subject"
      value={subject.value}
      invalid={!subject.isValid}
      onBlur={validateEmailSubject}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setEmailSubject(e.target.value)
      }
    />
  )
}

export default withStore(EmailSubjectInput)
