import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { DeeplinkFormValues } from '~/pages/App/ChannelSettings/sections/Deeplink/Deeplink.interface'
import { hideSplashScreen } from '~/utils/splashScreen'
import { NotificationType, showNotification } from '~/utils/Notification'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { ID } from '~/common.interface'
import {
  fetchDeeplinks,
  removeDeeplink,
  addDeeplink,
  editDeeplink
} from '~/api/apps'

const ERRORS_DICT = {
  target: {
    'is already taken': 'This deeplink url is already taken'
  },
  nickname: {
    "can't be blank": 'Deeplink name is missing',
    'is already taken': 'This deeplink url is already taken'
  }
}

export enum ServerStateKeysEnum {
  DeepLinks = 'deepLinks'
}

export const useGetDeepLinks = (appId: ID) =>
  useQuery([ServerStateKeysEnum.DeepLinks], () =>
    fetchDeeplinks(appId).finally(() => {
      hideSplashScreen()
    })
  )

export const useEditDeepLink = (close: () => void, setError: unknown) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (data: { data: DeeplinkFormValues; id: ID }) =>
      editDeeplink(appId, data.data, data.id),
    {
      onSuccess: () => {
        cache.invalidateQueries(ServerStateKeysEnum.DeepLinks)
        showNotification(
          'Successfully edited deep link',
          NotificationType.SUCCESS
        )
        close()
      },
      onError: (errors) => {
        if (errors.body.errors) {
          getFormServerSideErrors(errors.body.errors, ERRORS_DICT).forEach(
            (er) => setError(er.name, { type: 'server', message: er.message })
          )
        } else {
          showGeneralError()
        }
      }
    }
  )
}

export const useAddDeepLink = (close: () => void, setError: unknown) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (deepLink: DeeplinkFormValues) => addDeeplink(appId, deepLink),
    {
      onSuccess: () => {
        cache.invalidateQueries(ServerStateKeysEnum.DeepLinks)
        showNotification(
          'Successfully added deep link',
          NotificationType.SUCCESS
        )
        close()
      },
      onError: (errors) => {
        if (errors.body.errors) {
          getFormServerSideErrors(errors.body.errors, ERRORS_DICT).forEach(
            (er) => setError(er.name, { type: 'server', message: er.message })
          )
        } else {
          showGeneralError()
        }
      }
    }
  )
}

export const useRemoveDeepLink = () => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (data: { id: ID; closeModal: () => void }) =>
      removeDeeplink(appId, data.id),
    {
      onSuccess: (data, variables) => {
        cache.invalidateQueries(ServerStateKeysEnum.DeepLinks)
        variables.closeModal()
        showNotification(
          'Successfully removed deep link',
          NotificationType.SUCCESS
        )
      },
      onError: () => {
        showGeneralError()
      }
    }
  )
}
