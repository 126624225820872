import color from '~/utils/color'
import { isValidEmail } from '~/utils/validations'

const validationStyles = {
  multiValue: (provided, state) => {
    return {
      ...provided,
      background: isValidEmail(state.children)
        ? provided.backgroundColor
        : `${color.dangerLight} !important`
    }
  },
  multiValueLabel: (provided, state) => {
    return {
      ...provided,
      color: isValidEmail(state.children) ? provided.color : 'white !important'
    }
  },
  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      color: isValidEmail(state.children) ? provided.color : 'white !important',
      '&:hover': {
        backgroundColor: isValidEmail(state.children)
          ? provided.backgroundColor
          : `${color.dangerLight} !important`
      }
    }
  }
}

export default validationStyles
