import React from 'react'
import Modal from '~/components/modals/Modal/Modal'
import { withStore } from '~/dataStore'
import EditAddAdmin from './EditAddAdmin'

import styles from './EditAddAdmin.scss'

const EditAddAdminModal = () => (
  <Modal
    id="editAddAdminModal"
    className="edit-add-admin-modal"
    renderHeader={(onClose, params) => (
      <div className="mb-3">
        {params?.admin && (
          <div className="d-flex align-items-center text-base">
            <span className={styles.avatarWrapper}>
              <span
                style={{ backgroundImage: `url(${params.admin.avatarUrl})` }}
                className={styles.avatar}
              />
            </span>
            <div>
              <p className="ps-3 mb-0 text-base text-truncate">
                {params.admin.name}
              </p>
              {params.admin.jobTitle && (
                <span className="ps-3 text-truncate text-muted text-13">
                  {params.admin.jobTitle}
                </span>
              )}
            </div>
          </div>
        )}
        {!params?.admin && 'Invite New Admin'}
      </div>
    )}
    render={(close: () => void, params) => (
      <EditAddAdmin admin={params?.admin} />
    )}
  />
)

export default withStore(EditAddAdminModal)
