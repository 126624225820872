import React from 'react'
import cn from 'classnames'

import Skeleton from 'react-loading-skeleton'
import Start from './Start'
import { withStore } from '~/dataStore'

import './ReviewTopBar.scss'
import Targeting from '~/dataStore/emailBuilder/Targeting/Targeting'
import Deliver from '~/dataStore/emailBuilder/Deliver/Deliver'
import CampaignIcon from '~/pages/Campaign/UI/CampaignIcon'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import TargetingSummary from '../TargetingSummary'

interface IProps {
  name: string
  targeting: Targeting
  displayType?: string
  isDetailsInPast: boolean
  campaignType?: CampaignType
  campaignSubType?: CampaignType
  deliver: Deliver
  mode: 'builder' | 'details'
}

function ReviewTopBar({
  name,
  targeting,
  displayType,
  campaignType,
  campaignSubType,
  isDetailsInPast,
  deliver,
  mode
}: IProps) {
  return (
    <header
      className={cn(
        'campaign-header d-flex justify-content-between align-items-center w-100'
      )}>
      <div className="campaign-header__col d-flex align-items-center">
        {name ? (
          <>
            <CampaignIcon
              type={
                [
                  CampaignType.IN_APP,
                  CampaignType.SMS,
                  CampaignType.PUSH
                ].includes(campaignSubType)
                  ? 'notificationCard'
                  : campaignType
              }
            />
            <div className="d-flex flex-column ms-4">
              <span>{name}</span>
              <span className="muted">{displayType}</span>
            </div>
          </>
        ) : (
          <Skeleton width={200} count={2} />
        )}
      </div>

      {mode === 'builder' && (
        <div className="campaign-header__col d-flex flex-column align-items-center">
          {!name ? (
            <Skeleton width={80} count={2} className="d-block my-2" />
          ) : (
            <TargetingSummary targeting={targeting} />
          )}
        </div>
      )}
      <div className="campaign-header__col d-flex flex-column align-items-end">
        {name ? (
          <Start
            isDetailsInPast={isDetailsInPast}
            deliver={{
              isCampaignStartingEndingSameDay:
                deliver.isCampaignStartingEndingSameDay,
              startAt: deliver.startAt.value,
              endAt: deliver.endAt.value,
              startNow: deliver.startNow.value,
              expiryType: deliver.expiryType,
              expiryDate: deliver.expiryDate.value,
              expiryTimeFrame: deliver.expiryTimeFrame.value,
              expiryTimeValue: deliver.expiryTimeValue.value,
              campaignExpiry: deliver.campaignExpiry,
              isDeliveryFuture: deliver.deliverUi.isDeliveryFuture
            }}
            type="Campaign"
          />
        ) : (
          <Skeleton width={200} count={2} className="d-block my-2" />
        )}
      </div>
    </header>
  )
}

export default withStore(ReviewTopBar)
