import { makeAutoObservable } from 'mobx'
import { estimateSegment, saveSegment, updateSegment } from '~/api/segments'
import { CustomErrors, ID } from '~/common.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import { isEmpty } from '~/utils/utilities'
import SegmentRule from './Rules/SegmentRule.model'
import {
  JointType,
  RuleDTO,
  RuleWithCategoriesDTO,
  SegmentDTO
} from './SegmentBuilder.interface'

/** *
 * To simplify logc inside store I use 2D array to store selected rules.
 * It will simplify manage AND/OR behaviour between rules
 * This causes edit method to mutate rule directly instead of searching it first from 2d array
 */

export default class SegmentBuilderStore {
  public ruleGroups: SegmentRule[][] = []

  public segmentName = new NewRegisteredField('')

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public setName(value: string): void {
    this.segmentName.setValue(value)
  }

  public addRule(
    rule: RuleDTO,
    joinType?: JointType | null,
    id?: ID
  ): SegmentRule {
    const newRule = SegmentRule.getRule(rule)
    if (!joinType) {
      this.ruleGroups.push([newRule])
    } else if (joinType === 'And' && id) {
      const index = this.ruleGroups.findIndex((group) =>
        group.some((r) => r.id === id)
      )

      if (index > -1) {
        this.ruleGroups.splice(index + 1, 0, [newRule])
      } else {
        this.ruleGroups.push([newRule])
      }
    } else if (joinType === 'Or' && id) {
      const ruleGroup = this.ruleGroups.find((group) =>
        group.some((r) => r.id === id)
      )

      if (ruleGroup) {
        ruleGroup.push(newRule)
      }
    }

    return newRule
  }

  public replaceRule(newRule: RuleDTO, id: ID): void {
    this.ruleGroups = this.ruleGroups.map((rule) => {
      return rule.map((r) => {
        if (r.id === id) {
          return SegmentRule.getRule(newRule)
        }

        return r
      })
    })
  }

  public removeRule(id: ID): void {
    this.ruleGroups = this.ruleGroups
      .map((rule) => {
        return rule.filter((r) => r.id !== id)
      })
      .filter((group) => group.length > 0)
  }

  public isZeroRules(): boolean {
    return this.ruleGroups.length === 0
  }

  private validateRules(
    options: { segmentNameRequired: boolean } = { segmentNameRequired: true }
  ): boolean {
    let isValid = true
    this.segmentName.resetError()

    if (this.isZeroRules()) {
      isValid = false
    }

    this.ruleGroups.forEach((group) => {
      group.forEach((rule) => {
        rule.validateRule()
        isValid &&= rule.isValid
      })
    })

    if (options.segmentNameRequired) {
      isValid &&= this.segmentName.validate("can't be blank")
    }

    return isValid
  }

  private getPayload(): Pick<SegmentDTO, 'name' | 'groups'> {
    return {
      name: this.segmentName.value,
      groups: this.ruleGroups.map((group) => {
        return {
          joinType: '+',
          rules: group.map((rule) => ({
            ...rule.getPayload()
          }))
        }
      })
    }
  }

  public saveSegment(appId: ID, segmentId?: ID): Promise<SegmentDTO> {
    if (!this.validateRules()) {
      throw new Error(CustomErrors.INVALID)
    }

    const segment = this.getPayload()

    if (segmentId) {
      return updateSegment(appId, segmentId, segment)
    }

    return saveSegment(appId, segment)
  }

  public estimateSegment(appId: ID): Promise<{
    totalUsersCount: number
    usersCount: number
  }> {
    if (!this.validateRules({ segmentNameRequired: false })) {
      throw new Error(CustomErrors.INVALID)
    }

    const { groups } = this.getPayload()
    return estimateSegment(appId, { segment: { groups } })
  }

  public fillStore(
    data: SegmentDTO,
    rulesWithCategories: RuleWithCategoriesDTO
  ): void {
    this.setName(data.name || data.name)

    const rules = Object.entries(rulesWithCategories).flatMap(([key, rule]) => {
      if (key === 'location') {
        return { key, type: 'string' }
      }
      return rule
    })

    this.ruleGroups = []

    data.groups.forEach((group, groupIndex) => {
      group.rules.forEach((segmentRuleDTO, index) => {
        const rule = rules.find(
          (ruleDTO) => ruleDTO.key === segmentRuleDTO.type
        )
        if (rule) {
          const segmentRule = this.addRule(
            rule,
            index > 0 ? 'Or' : null,
            this.ruleGroups.at(groupIndex)?.at(0)?.id
          )
          const segmentRuleWithoutEmptyValues = Object.fromEntries(
            Object.entries(segmentRuleDTO).filter(([_, v]) => !isEmpty(v))
          )

          segmentRule.fill(segmentRuleWithoutEmptyValues)
        }
      })
    })
  }
}
