import React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import reject from 'lodash/reject'
import Modal from '~/components/modals/Modal'
import { IGroup } from '~/dataStore/Groups/Groups.interface'

interface IProps {
  group?: IGroup
  onSubmit: (groupId: string) => void
  isSaving: boolean
  queryParams?: { groupIds?: string }
}

export default function DeleteGroup({
  group,
  onSubmit,
  isSaving,
  queryParams
}: IProps) {
  // TODO: Refactor
  const removeGroupFromQueryParams = (id: string) => {
    if (!queryParams?.groupIds) return
    queryParams.groupIds = reject(
      queryParams.groupIds.split(','),
      (groupId) => groupId === id
    ).join(',')
  }

  const removeGroup = () => {
    if (!group?.id) return
    onSubmit(group.id)
    removeGroupFromQueryParams(group.id)
  }

  return (
    <Modal
      id="removeGroupModal"
      size="sm"
      className="delete-group-modal"
      renderHeader={() => 'Delete Group'}
      render={(close: () => void) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            removeGroup()
          }}>
          <ModalBody className="m-3">
            Are you sure you want to delete?
            <br />
            <br />
            {group?.name}
          </ModalBody>
          <ModalFooter>
            <Button color="" onClick={close} disabled={isSaving}>
              Cancel
            </Button>
            <Button color="primary" onClick={removeGroup} disabled={isSaving}>
              Delete
            </Button>
          </ModalFooter>
        </form>
      )}
    />
  )
}
