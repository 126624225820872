import React from 'react'
import { Collapse } from 'reactstrap'

import journeysInfoGraphic from './journeys-infographic.svg'
import NoticeBarSecondary from '~/components/common/NoticeBarSecondary/NoticeBarSecondary'
import NoticeBarSecondaryHeader from '~/components/common/NoticeBarSecondary/components/NoticeBarSecondaryHeader'
import LearnMoreButton from '~/components/common/NoticeBarSecondary/components/LearnMoreButton'

const LearnMoreBottomBanner = ({
  isOpen
}: {
  isOpen: boolean
}): React.ReactElement => {
  return (
    <Collapse isOpen={isOpen}>
      <NoticeBarSecondary>
        <NoticeBarSecondaryHeader
          icon={journeysInfoGraphic}
          header="Deliver great experiences with Customer Journeys"
          subHeader="Automated marketing helps you reach more customers with less
              effort and more results."
        />
        <LearnMoreButton href="https://docs.pulsatehq.com/reference/journeys" />
      </NoticeBarSecondary>
    </Collapse>
  )
}

export default LearnMoreBottomBanner
