import moment from 'moment-timezone'
import { ID } from '~/common.interface'
import { ISegmentListItem } from '~/pages/Segment/SegmentsList/SegmentsList.interface'

export const DEFAULT_TIMEZONE = moment.tz.guess()

export enum ValidationTypes {
  REQUIRED = 'REQUIRED',
  FORMAT = 'FORMAT',
  MIN_MAX = 'MIN_MAX'
}

export enum Trigger {
  ENTER = 'enter',
  EXIT = 'exit',
  DWELL = 'dwell',
  TRIGGERED = 'triggered'
}

export enum ExpiryType {
  DATE = 'date',
  AMOUNT = 'amount',
  NEVER = 'never'
}

export interface IOption<T = string> {
  value?: T
  label: string
  isDisabled?: boolean
  asLabel?: boolean
  options?: Pick<IOption, 'value' | 'label'>[]
  dataTestId?: string
}

export interface IError {
  type: ValidationTypes
  message: string
}
export interface IRegisteredField<T = any> {
  value: T
  valid: boolean
  isValid: boolean
  beenValid: boolean
  errors: IError[]
  resetError: () => void
  setValue: (value: T) => void
  fillField({ value }: { value: T }): void
  validate(message: string): boolean
}

export interface IStep {
  number: number
  title: string
  isValid: boolean
  beenValid: boolean
}

export interface IBeaconTarget {
  battery: string
  beaconEventsCount: number
  beaconId: string
  createdAt: string
  groupIds: any[]
  id: string
  major: number
  minor: number
  nickname: string
  uuid: string
}

export interface ISegmentTarget extends ISegmentListItem {
  name: string
  totalUsers: number
}

export interface IGeofenceTarget {
  createdAt: string
  groupIds: any[]
  guid: string
  id: string
  location: [number, number]
  name: string
  path: string | null
  radius: number
  shape: string
}
export type IEventTarget = string[]

export interface IEmailTemplate {
  name?: string
  id: ID
  content?: any
  createdAt?: string
  html?: string
  updatedAt?: string
  imageUrl?: string
  thumbnailUrl?: string
  isDefault?: boolean
}

export interface IEmailEditorData {
  design: IDesign
  html: string
  chunks?: any
  options?: any
}

export interface IDesign {
  body: any
  counters: any
  schemaVersion: number
}

export interface ITimeWindow {
  weekDay: string
  startAt: Date | null | string
  endAt: Date | null | string
}

export enum DATE_FORMATS {
  HOUR_MINUTE = 'hh:mm',
  HOUR_MINUTE_MERIDIAN = 'hh:mma',
  MONTH_DAY = 'MMM D',
  FULL_DATE = 'hh:mma, MMM D YYYY'
}

export interface IImage {
  contentType: string
  createdAt: Date
  height: number
  id: string
  imageUrl: string
  name: string
  thumbnailUrl: string
  updatedAt: string
  width: number
}

export type FileItem = {
  id: string
  name: string
  file: File
  isLoading: boolean
  isUploaded: boolean
  hasError: boolean
}
