import moment from 'moment'

export const getTooltipLabelAsDateRange = (label: string | number) => {
  if (typeof label === 'number') return label

  const dateRange = label.split(',')
  if (dateRange.length === 2) {
    return `${moment(dateRange[0]).format('D')} - ${moment(dateRange[1]).format(
      'D'
    )} ${moment(dateRange[0]).format('MMM YYYY')}`
  }

  return moment(label).format('D MMM YYYY')
}
