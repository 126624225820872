import cn from 'classnames'
import styles from '../JourneyTeaser.scss'
import channel1 from './icons/channel_1.svg'
import channel2 from './icons/channel_2.svg'
import channel3 from './icons/channel_3.svg'
import message1 from './icons/message_1.svg'
import message2 from './icons/message_2.svg'
import message3 from './icons/message_3.svg'
import stats1 from './icons/stats_1.svg'
import stats2 from './icons/stats_2.svg'
import stats3 from './icons/stats_3.svg'

interface IProps {
  iconSet: 'channel' | 'message' | 'stats'
}

export default function AboutIcons(props: IProps): React.ReactElement | null {
  const { iconSet } = props

  const icons = {
    channel1,
    channel2,
    channel3,
    message1,
    message2,
    message3,
    stats1,
    stats2,
    stats3
  }

  return (
    <div className={cn(styles.aboutIconBox)}>
      <img src={icons[`${iconSet}1`]} className={cn(styles.aboutIcon)} alt="" />
      <img src={icons[`${iconSet}2`]} className={cn(styles.aboutIcon)} alt="" />
      <img src={icons[`${iconSet}3`]} className={cn(styles.aboutIcon)} alt="" />
    </div>
  )
}
