import React from 'react'
import cn from 'classnames'

interface IProps {
  className?: string
  children: React.ReactNode
  tag?: keyof JSX.IntrinsicElements
  ariaLabel?: string
}

const HeaderTitle = ({
  children,
  className,
  tag: Tag = 'h1',
  ariaLabel,
  ...rest
}: IProps): React.ReactElement => (
  <Tag
    className={cn('header-title', className)}
    aria-label={ariaLabel}
    {...rest}>
    {children}
  </Tag>
)

export default HeaderTitle
