import { makeAutoObservable } from 'mobx'
import {
  ChartDataType,
  IChartData,
  ISummary
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'
import { ISMSReport } from '../../Notification.interface'
import { IPreview, PreviewParts } from '~/dataStore/Campaign/Campaign.interface'

export default class SMSReport {
  constructor(
    public report: ISMSReport,
    public controlGroupParsed?: string,
    public notificationPreview?: IPreview
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  public get summaryNumbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report

    return [
      {
        label: 'Sent',
        content: send.totalUniq ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Total number of unique campaign sends, minus those who do not have in-app notifications enabled.',
        icon: 'icon--sent'
      },
      {
        label: 'Control Group',
        content: this.controlGroupParsed ?? '- -',
        className: 'summary-field--green',
        tooltip:
          'Percentage of the chosen ‘Target’ criteria who will not receive the campaign.',
        icon: 'icon--control-group'
      }
    ]
  }

  public get numbers(): ISummary[] {
    if (!this.report) return []

    const { send } = this.report
    return [
      {
        label: 'Sent',
        content: send.totalUniq,
        rate: send.totalUniqRate
      }
    ]
  }

  public get charts(): IChartData[] {
    if (!this.report) return []

    const { send } = this.report

    return [
      {
        name: 'Sent',
        valueCurrent: send.totalUniq,
        data: send.uniqGraph?.slice() || [],
        dataType: ChartDataType.NUMERIC
      }
    ]
  }

  public get preview(): PreviewParts | undefined {
    if (!this.notificationPreview) return undefined

    return this.notificationPreview.sms
  }
}
