import React from 'react'
import cn from 'classnames'
import Chart from 'react-apexcharts'
import moment from 'moment'

import { ApexOptions } from 'apexcharts'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

interface IProps {
  title: string
  chartData: {
    name: string
    color: string
    data: { x: string; y: string }[]
  }[]
}

function prepareOptionsApexChart(chartData: IProps['chartData']) {
  const optionsApex: ApexOptions = {
    colors: [],
    chart: {
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [5, 7, 5],
      curve: 'straight',
      dashArray: [0, 8, 5]
    },
    markers: {
      size: 0
      // style: 'hollow'
    },
    xaxis: {
      categories: []
    },
    grid: {
      borderColor: '#f1f1f1'
    },
    legend: {
      markers: {
        fillColors: []
      }
    }
  }

  chartData.forEach((dataset) => {
    optionsApex.colors.push(dataset.color)
    optionsApex.legend.markers.fillColors.push(dataset.color)
    dataset.data.forEach((item) => {
      optionsApex.xaxis.categories.push(moment(item.x).format('MMM D'))
    })
  })

  optionsApex.xaxis.categories.filter(
    (item, index) => optionsApex.xaxis.categories.indexOf(item) === index
  )

  return optionsApex
}

function prepareDataForChart(chartData: IProps['chartData']) {
  chartData.forEach((dataset) => {
    dataset.data.forEach((item) => {
      item.x = moment(item.x).format('MMM D')
    })
  })

  return chartData
}

const ApexLineChart = ({ title, chartData }: IProps): React.ReactElement => (
  <Card className={cn('flex-fill', 'w-100')}>
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
        {title}
      </CardTitle>
    </CardHeader>
    <CardBody className="py-3">
      <div className={cn('chart', 'chart-sm', 'overflow-hidden')}>
        <Chart
          type="line"
          series={prepareDataForChart(chartData)}
          options={prepareOptionsApexChart(chartData)}
        />
      </div>
    </CardBody>
  </Card>
)

export default ApexLineChart
