import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { ActionMeta, Options, InputAction } from 'react-select'
import { createSelectOption } from '~/utils/createSelectOptions'
import validationStyles from './validationStyles'

const components = {
  DropdownIndicator: null
}

const CreatableTextInput = ({
  placeholder,
  onChange,
  value
}: {
  placeholder?: string
  onChange: (value: any) => void
  value: any
}): React.ReactElement => {
  const [inputValue, setInputValue] = useState('')

  const handleChange = (
    inputValue: Options<any> | string,
    action?: ActionMeta<any>
  ) => {
    if (
      action?.action === 'remove-value' ||
      action?.action === 'pop-value' ||
      action?.action === 'clear'
    ) {
      onChange(inputValue)
    } else {
      onChange([...value, createSelectOption(inputValue)])
      setInputValue('')
    }
  }

  const handleInputChange = (
    textInput: string,
    { action }: { action: InputAction }
  ) => {
    if (action === 'input-blur') {
      if (inputValue) handleChange(inputValue)
    }
    if (action === 'input-change') {
      setInputValue(textInput)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handleChange(inputValue)
        event.preventDefault()
    }
  }

  return (
    <CreatableSelect
      classNamePrefix="custom-select"
      styles={validationStyles}
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      value={value}
    />
  )
}

export default CreatableTextInput
