import { Handle, Position } from 'reactflow'
import cn from 'classnames'
import JourneyNotificationPreview from '~/pages/Journeys/UI/JourneyNotificationPreview/JourneyNotificationPreview'
import { NodeWithData } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import { Message as MessageBlock } from '../Blocks'
import { withStore } from '~/dataStore'

function Message({ id, data }: NodeWithData<MessageBlock>): React.ReactElement {
  return (
    <div
      id={id}
      className={cn('flow-node flow-node--message', {
        'flow-node--filled': data.filled,
        'flow-node--error': !data.block.isValid
      })}>
      <div className="flow-node__wrapper text-start">
        <Handle
          type="target"
          position={Position.Top}
          className="custom-handle"
        />
        <Handle
          type="target"
          position={Position.Left}
          className="custom-handle flow-node__left-target"
          id="left"
        />
        <Handle
          type="target"
          position={Position.Right}
          className="custom-handle flow-node__right-target"
          id="right"
        />
        <div className="flow-node__hover">
          <JourneyNotificationPreview
            preview={data.block.getNotificationPreviews()}
          />
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          className="custom-handle"
          id="bottom"
        />
      </div>
    </div>
  )
}

export default withStore(Message)
