import React, { ReactElement } from 'react'
import cn from 'classnames'

const AppHeader = ({
  logo,
  name,
  onClick
}: {
  logo?: string
  name: string
  onClick?: () => void
}): ReactElement => {
  return (
    <div
      className={cn('app-header', { 'cursor-pointer': !!onClick })}
      onClick={onClick}>
      {logo && (
        <div className="app-header__logo-wrapper">
          <img src={logo} alt={name} className="app-header__logo" />
        </div>
      )}
      <span className="app-header__name">{name}</span>
      <span className="app-header__close" />
    </div>
  )
}

export default AppHeader
