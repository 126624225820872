import React from 'react'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import DeeplinkSection from './sections/Deeplink'
import EmailFromNamesSection from './sections/EmailFromNames'
import PushSection from './sections/Push'
import EmailSection from './sections/Email'
import RevenueSection from './sections/Revenue'
import IntegrationGoalFilesSection from './sections/IntegrationGoalFiles'
import GeofenceSection from '~/pages/App/ChannelSettings/sections/Geofence'
import { useStore, withStore } from '~/dataStore'

const AppChannelSettings = (): React.ReactElement => {
  const { app } = useStore()
  return (
    <div className="page-wrapper pb-5">
      <Header className="mb-4">
        <HeaderTitle ariaLabel="Channel Settings Title">
          Channel Settings
        </HeaderTitle>
      </Header>
      <DeeplinkSection />
      <PushSection />
      <EmailSection />
      <EmailFromNamesSection />
      <GeofenceSection />
      <RevenueSection />
      {app.appDetails.featureFlags.externalGoalFileUploadSection && (
        <IntegrationGoalFilesSection />
      )}
    </div>
  )
}

export default withStore(AppChannelSettings)
