import { ReactElement } from 'react'
import cn from 'classnames'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iOS from '~/components/icons/sms_ios.svg'
import android from '~/components/icons/sms_android.svg'
import watch from '~/components/icons/sms_watch.svg'
import { DeviceType } from '~/dataStore/Campaign/Campaign.interface'

interface IProps {
  deviceType: DeviceType
}

export default function SMSHeader({ deviceType }: IProps): ReactElement {
  function getIcon(): string | undefined {
    switch (deviceType) {
      case 'ios':
        return iOS
      case 'android':
        return android
      case 'watch':
        return watch
      default:
        return undefined
    }
  }

  return (
    <div
      className={cn(
        'd-flex align-items-center',
        'sms-header',
        `sms-header--${deviceType}`
      )}>
      <img src={getIcon()} alt={deviceType} className="sms-header__icon" />
      <span className="sms-header__text">Messages</span>
      {deviceType !== 'watch' && (
        <span className="sms-header__time">
          {deviceType === 'ios' ? 'now' : '• 4 min ago'}
        </span>
      )}
      {deviceType === 'android' && (
        <FontAwesomeIcon className="ms-auto" icon={faChevronUp} />
      )}
    </div>
  )
}
