/* eslint-disable import/prefer-default-export */
import { IGeofenceTarget } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { IMetaData } from '~/common.interface'

import { get } from './v2'

export function getGeofences(
  appId: string,
  page: number,
  searchQuery = '',
  groupIds: string[]
): Promise<{ data: IGeofenceTarget[]; metadata: IMetaData }> {
  return get(`${appId}/geofences`, { page, searchQuery, groupIds })
}
