import { ReactElement } from 'react'
import { Col, Container, Row } from 'reactstrap'
import {
  UserBeaconEventsSection,
  UserCustomDataSection,
  UserDevicesSection,
  UserPersonalSection,
  UserGeofenceEventsSection,
  UserNotes,
  UserSegmentsSection,
  UserSubscriptionsSection
} from './sections'

const UserDetails = (): ReactElement => {
  return (
    <Container className="pb-5">
      <UserPersonalSection />
      <Row>
        <Col>
          <UserSegmentsSection />
        </Col>
        <Col>
          <UserCustomDataSection />
        </Col>
      </Row>
      <UserDevicesSection />
      <UserSubscriptionsSection />
      <Row>
        <Col>
          <UserGeofenceEventsSection />
        </Col>
        <Col>
          <UserBeaconEventsSection />
        </Col>
      </Row>
      <Row>
        <Col>
          <UserNotes />
        </Col>
      </Row>
    </Container>
  )
}

export default UserDetails
