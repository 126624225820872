import React from 'react'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'
import { IBeaconRow } from '../../Beacons.interface'
import IconButton from '~/components/IconButton'

const BeaconsTableRowActions = ({
  onDeleteRow,
  onEditRow,
  row
}: {
  onDeleteRow: () => void
  row: IBeaconRow
  onEditRow: (row: IBeaconRow) => void
}): React.ReactElement => (
  <div className="row-actions">
    <IconButton
      onClick={onEditRow}
      icon={faPen}
      dataTestId="editBeaconBtn"
      className="me-1"
      id={`edit-${row.id}`}
    />
    <UncontrolledTooltip placement="bottom" target={`edit-${row.id}`}>
      Edit
    </UncontrolledTooltip>

    <IconButton
      dataTestId="deleteBeaconBtn"
      onClick={onDeleteRow}
      icon={faTrash}
      className="me-n3"
      id={`delete-${row.id}`}
    />
    <UncontrolledTooltip placement="bottom" target={`delete-${row.id}`}>
      Delete
    </UncontrolledTooltip>
  </div>
)

export default BeaconsTableRowActions
