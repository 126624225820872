import React, { useCallback } from 'react'
import AsyncSelect from 'react-select/async'
import debounce from 'lodash/debounce'
import { fetchAdmins } from '~/api/admins'
import { useAssignMessage } from '~/pages/Messages/useMessages'
import { useStore } from '~/dataStore'

const AssignMessage = ({ chatId }: { chatId: string }) => {
  const [adminId, setAdminId] = React.useState<string | null>(null)
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  const { mutateAsync: assignMessage } = useAssignMessage(chatId)

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      fetchAdmins(appId, { searchQuery: inputText }).then((response) =>
        callback(
          response.data.map((user) => ({
            label: user.name,
            value: user.id
          }))
        )
      )
    }, 3000),
    []
  )

  const onChange = async (val: string) => {
    setAdminId(val)
    await assignMessage(val)
    setAdminId(null)
  }

  return (
    <AsyncSelect
      placeholder="Assign message to"
      classNamePrefix="custom-select"
      styles={{
        input: (provided) => ({
          ...provided,
          width: '200px'
        })
      }}
      noOptionsMessage={() => 'Type to search admin'}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      value={adminId}
      onChange={(val) => onChange(val.value)}
      loadOptions={loadOptions}
    />
  )
}

export default AssignMessage
