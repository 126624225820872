import { useRef } from 'react'
import HintBtn from '~/HintWindow/HintBtn'
import { Delay } from '../Blocks'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import TimeFrameForm from '~/components/forms/TimeFrameForm'
import { withStore } from '~/dataStore'
import HintModal from '~/HintWindow/HintModal'
import HowDelayWorksFile from '~/HintWindow/mediaFiles/delay.png'

interface IProps {
  block: Delay
  disabled: boolean
}

function DelayPanel({ block, disabled }: IProps): React.ReactElement {
  const { timeFrame, setTimeFrame, timeValue, setTimeValue } = block

  const hintRef = useRef()

  return (
    <div>
      <Header className="mb-6">
        <div className="d-flex align-items-center mb-2 px-4">
          <HeaderTitle tag="h2" className="mb-0">
            Time Delay
          </HeaderTitle>
          <HintBtn className="ms-3">How time delay works</HintBtn>
        </div>
        <p className="color-subheader px-4" ref={hintRef}>
          Set the duration of the time delay
        </p>
      </Header>
      <div className="px-4">
        <TimeFrameForm
          disabled={disabled}
          timeLabel="Set a Delay of"
          units={['minutes', 'hours', 'days', 'weeks']}
          inputType="number"
          initialTimeValue={timeValue.value}
          initialTimeFrame={timeFrame.value}
          timeValueInvalid={!timeValue.isValid}
          onTimeValueChange={(e) => setTimeValue(e.target.value)}
          onTimeFrameChange={setTimeFrame}
        />
      </div>
      <HintModal
        elem={hintRef}
        link="https://docs.pulsatehq.com/reference/time-delay-best-practices"
        mediaFile={HowDelayWorksFile}>
        <h2>Add a time delay between messages</h2>
        <p>
          Use this feature to introduce a time delay between messages. The time
          delay commences once the primary goal is met. After the desired action
          is achieved, the tool will automatically hold the subsequent message
          for the specified time period before sending the next message. This
          allows you to create a strategic rhythm in your messaging, ensuring
          your audience receives the right message at the right time, maximizing
          engagement and impact.
        </p>
      </HintModal>
    </div>
  )
}

export default withStore(DelayPanel)
