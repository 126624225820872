import React from 'react'

import Link from '~/components/Link'
import infoMedia from './infoMedia.svg'
import GraduationCapIcon from '~/components/icons/graduationCap'

import './LearnMoreWidget.scss'

const LearnMoreWidget = (): React.ReactElement => {
  return (
    <div className="learn-more-widget card p-4 p-xxl-5 justify-content-center h-100">
      <img
        className="learn-more-widget__media w-100"
        src={infoMedia}
        alt="Email Marketing Strategy and Tips for Successful Campaigns"
      />

      <p className="learn-more-widget__text mt-4 pt-1">
        Email Marketing Strategy <br /> and Tips for Successful Campaigns
      </p>

      <Link
        target="_blank"
        className="learn-more-widget__btn mx-auto no-decoration text-nowrap mt-2 py-4 w-100 btn btn--hover"
        route="https://pulsate.readme.io"
        href="https://pulsate.readme.io">
        <GraduationCapIcon className="me-2 fill-current" />
        Learn more
      </Link>
    </div>
  )
}

export default LearnMoreWidget
