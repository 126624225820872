import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap'
import cn from 'classnames'
import { useParams } from 'react-router-dom'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormValues, ERRORS_DICT } from './FirebaseSection.interface'
import sectionLogo from './sectionLogo.svg'
import Input from '~/components/forms/Input'
import { useStore, withStore } from '~/dataStore'
import { updateFCMSettings } from '~/api/appSettings'
import { NotificationType, showNotification } from '~/utils/Notification'
import { showGeneralError, getFormServerSideErrors } from '~/utils/validations'

const FirebaseSection = (): React.ReactElement => {
  const {
    app: {
      appDetails,
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const { appId } = useParams<{ appId: string }>()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({
    values: {
      fcmSenderId: appDetails?.fcm.senderId,
      fcmServerKey: appDetails?.fcm.serverKey
    }
  })

  const onSubmit = async (data: FormValues) => {
    try {
      await updateFCMSettings(appId, {
        settingAttributes: {
          fcmServerKey: data.fcmServerKey || '',
          fcmSenderId: data.fcmSenderId || ''
        }
      })
      showNotification('Settings successfully saved', NotificationType.SUCCESS)
    } catch (err) {
      if (err.body?.errors) {
        getFormServerSideErrors(err.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className="mb-0 mt-2">
          Firebase Cloud Messaging (FCM)
        </CardTitle>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)} className="py-3">
          <Row>
            <Col xs={8}>
              <Input
                register={register}
                label="Sender ID"
                placeholder="Enter Sender ID provided by FCM portal"
                errorTooltip={errors.fcmSenderId?.message}
                className={cn('mb-3', {
                  'form-group--error': errors.fcmSenderId
                })}
                tooltip="Enter Sender Key provided by FCM portal"
                name="fcmSenderId"
              />
              <Input
                register={register}
                label="Server Key"
                placeholder="Enter Server Key provided by FCM portal"
                errorTooltip={errors.fcmServerKey?.message}
                className={cn('mb-3', {
                  'form-group--error': errors.fcmServerKey
                })}
                tooltip="Enter Server Key provided by FCM portal"
                name="fcmServerKey"
              />
              <Button
                color="primary"
                disabled={isSubmitting || isDemoAdmin}
                type="submit"
                className="mt-4 d-block">
                Save Changes
                {isSubmitting && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    size="xs"
                    className="ms-2"
                  />
                )}
              </Button>
            </Col>
            <Col xs={4}>
              <img
                className="mx-auto d-block pt-1 mt-4"
                src={sectionLogo}
                width={93}
                alt="Firebase Cloud Messaging (FCM)"
              />
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  )
}

export default withStore(FirebaseSection)
