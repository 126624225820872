import { action, IObservableArray, observable, runInAction } from 'mobx'
import React, { useCallback, useEffect, useRef } from 'react'
import { ApexOptions } from 'apexcharts'
import Chart from 'react-apexcharts'
import cloneDeep from 'lodash/cloneDeep'
import Skeleton from 'react-loading-skeleton'
import { useStore, withStore } from '~/dataStore'
import { chartOptions, colors } from './chartOptions'
import {
  getFillConfig,
  getXAxisConfig,
  getYAxisNumericConfig,
  getYAxisPercentageConfig
} from './chart.service'
import {
  ChartType,
  DataChartType,
  IChartData,
  ISeriesData
} from '~/pages/Campaign/CampaignReports/Model/Report.interface'

interface IProps {
  chartData: IChartData[]
  minimumActiveSeries?: number
  chartType?: ChartType
  customColors?: Array<{ start: string; end: string; legend: string }>
  includeOverviewColumn?: boolean
  xAxisConfig?: Partial<{
    xAxisType: 'datetime' | 'category'
    tickAmount: number
    trim: boolean
    hideOverlappingLabels: boolean
  }>
  legend?: boolean
  customFill?: boolean
}

function LineChart({
  chartData,
  minimumActiveSeries = 2,
  chartType = 'line',
  customColors,
  includeOverviewColumn,
  xAxisConfig,
  legend = true,
  customFill = false
}: IProps): React.ReactElement {
  const {
    ui: { showSidebar }
  } = useStore()
  const defaultOptionsRef = useRef<ApexOptions>(cloneDeep(chartOptions))
  const chartRef = useRef<Chart & { chart: { series: any } }>()

  const series = useRef<IObservableArray<ISeriesData>>(
    observable(
      chartData.map((serie, index) => ({
        name: serie.name,
        value: serie.valueCurrent,
        data: serie.data,
        color:
          customColors?.[index] ||
          colors[includeOverviewColumn ? index : index + 1],
        dataType: serie.dataType,
        dataChartType: serie.dataChartType || DataChartType.LINE,
        active: index < minimumActiveSeries
      }))
    )
  )

  function handleMouseOver(e: React.MouseEvent) {
    if (chartRef.current?.chart) {
      const target = e.target.closest('button')

      if (target.getAttribute('rel') > 0) {
        if (e.type === 'mouseenter') {
          chartRef.current.chart.series?.toggleSeriesOnHover(
            { type: 'mousemove' },
            target
          )
        } else if (e.type === 'mouseleave') {
          chartRef.current.chart.series?.toggleSeriesOnHover(
            { type: 'mouseout' },
            target
          )
        }
      }
    }
  }

  const handleClick = action((serieName: string): void => {
    const serie = series.current.find((s) => s.name === serieName)
    if (serie) {
      serie.active = !serie.active
    }
  })

  useEffect(() => {
    const current = series.current.slice()
    runInAction(() => {
      series.current.replace(
        chartData.map((serie, index) => ({
          name: serie.name,
          value: serie.valueCurrent,
          data: serie.data,
          dataType: serie.dataType,
          dataChartType: serie.dataChartType || DataChartType.LINE,
          color:
            customColors?.[index] ||
            colors[includeOverviewColumn ? index : index + 1],
          active: current[index]
            ? current[index]?.active
            : index < minimumActiveSeries
        }))
      )
    })
  }, [chartData])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [showSidebar])

  const getChartOptions = () => ({
    ...defaultOptionsRef.current,
    ...getXAxisConfig(xAxisConfig || {}),
    yaxis: [
      ...getYAxisNumericConfig(series.current.slice()),
      ...getYAxisPercentageConfig(series.current.slice())
    ],
    ...(!customFill && {
      ...getFillConfig(
        series?.current.filter((s) => s.active),
        includeOverviewColumn,
        chartType
      )
    })
  })

  const setRef = useCallback((node) => {
    if (node) {
      window.dispatchEvent(new Event('resize'))
    }
    chartRef.current = node
  }, [])

  return (
    <div className="chart">
      <Chart
        type={chartType}
        series={series.current
          .slice()
          .filter((serie) => serie.active)
          .map((serie) => ({
            name: serie.name,
            data: serie.data,
            color: serie.color?.start,
            type: serie.dataChartType
          }))}
        options={getChartOptions()}
        height="350"
        width="100%"
        ref={setRef}
      />
      {legend && (
        <div className="legend">
          {series.current.slice().map((serie, index) => (
            <button
              //! don't remove, required for hovering state
              rel={
                series.current
                  .filter((s) => s.active)
                  .findIndex((s) => s.name === serie.name) + 1
              }
              key={serie.name}
              type="button"
              className={`legend__item d-flex align-items-center justify-content-center flex-column ${
                serie.active ? 'legend__item--active' : ''
              }`}
              onMouseEnter={handleMouseOver}
              onMouseLeave={handleMouseOver}
              onClick={handleClick.bind(null, serie.name)}>
              <div
                style={{
                  color: `${
                    serie.active
                      ? customColors?.[index].legend ||
                        colors[includeOverviewColumn ? index : index + 1].legend
                      : '#fff'
                  }`
                }}>
                {serie.name}
              </div>
              <div className="legend__value">
                {serie.value ?? <Skeleton width="100px" />}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

const MemoizedChart = React.memo(withStore(LineChart))

export default MemoizedChart
