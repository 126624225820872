import DatePicker from 'react-datepicker'
import cn from 'classnames'
import moment from 'moment'
import { uniqueId } from 'lodash'
import { InputGroup, InputGroupText } from 'reactstrap'

import IconCalendar from '~/components/icons/IconCalendar'

import {
  customDatepickerWrapper,
  datepickerCalendar,
  datepickerInput,
  datepickerInputIcon
} from './style.scss'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_CALENDAR = 'MMMM'
export const DISPLAY_DATE_FORMAT = 'dd MMM yy'

interface IProps {
  value: Date | null
  name?: string
  displayDateFormat?: string
  maxDate?: Date | null | undefined
  minDate?: Date | null | undefined
  showMonthYearDropdown?: boolean
  onChange?: (date: Date) => void
  disabled?: boolean
  [x: string]: string | boolean | unknown
}

const DatepickerInput = ({
  displayDateFormat,
  minDate = moment().toDate(),
  maxDate,
  name,
  onChange,
  value,
  showMonthYearDropdown = true,
  disabled,
  ...rest
}: IProps): React.ReactElement => {
  const id = `datepicker-${uniqueId()}`

  const onDateChange = (selectedDate: Date) => {
    if (onChange) onChange(selectedDate)
  }

  return (
    <div className={customDatepickerWrapper}>
      <InputGroup className="flex-nowrap">
        <DatePicker
          className={cn(datepickerInput, 'form-control')}
          selected={typeof value === 'undefined' ? null : value}
          onChange={onDateChange}
          calendarClassName={datepickerCalendar}
          name={name}
          placeholderText="-- -- --"
          popperPlacement="auto"
          dateFormat={displayDateFormat || DISPLAY_DATE_FORMAT}
          minDate={minDate || moment().toDate()}
          maxDate={maxDate || moment().add(12, 'month').toDate()}
          id={id}
          showMonthYearDropdown={showMonthYearDropdown}
          disabled={disabled}
          {...rest}
        />
        <label htmlFor={id} className="mb-0">
          <div className={cn('h-100', { disabled })}>
            <InputGroupText className={datepickerInputIcon}>
              <IconCalendar />
            </InputGroupText>
          </div>
        </label>
      </InputGroup>
    </div>
  )
}

export default DatepickerInput
