// //////////////////////////////////////////////////////////////// //
// Replacement for <img> tags that can deal with loading errors and //
// display a fallback image                                         //
// //////////////////////////////////////////////////////////////// //

import React, { useState, useCallback, useEffect } from 'react'

interface IProps {
  src: string
  fallbackSrc: string
  [x: string]: any
}

export default function Img({
  src,
  fallbackSrc,
  ...props
}: IProps): React.ReactElement {
  const [error, setError] = useState(false)
  const onError = useCallback(() => setError(true), [])

  useEffect(() => {
    if (src) setError(false)
  }, [src])

  return (
    <img src={error ? fallbackSrc : src} alt="" onError={onError} {...props} />
  )
}
