import React, { useState } from 'react'
import {
  faPaperPlane,
  faSpinner,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import {
  EMAIL_VERIFICATION_LIST_QUERY,
  IEmailAddress
} from '~/pages/App/ChannelSettings/sections/Email/EmailSection.interface'
import { useStore, withStore } from '~/dataStore'
import { resendEmailVerification } from '~/api/appSettings'
import { NotificationType, showNotification } from '~/utils/Notification'
import { showGeneralError } from '~/utils/validations'

const ActionsColumn = ({ emailAddress }: { emailAddress: IEmailAddress }) => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    },
    ui: { showModal }
  } = useStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { appId } = useParams<{ appId: string }>()
  const { id } = emailAddress
  const queryClient = useQueryClient()

  const resendVerification = async () => {
    try {
      setIsLoading(true)
      await resendEmailVerification(appId, id)
      showNotification(
        'Please check your email inbox for Email from AWS with Address Verification Request',
        NotificationType.SUCCESS
      )
      queryClient.invalidateQueries(EMAIL_VERIFICATION_LIST_QUERY)
    } catch (err) {
      if (err.body?.errors) {
        showNotification(err.body.errors[0].email, NotificationType.ERROR)
      } else {
        showGeneralError()
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <UncontrolledTooltip placement="top" target={`remove-${id}`}>
        Delete
      </UncontrolledTooltip>
      <Button
        onClick={() => showModal('confirmEmailAddressDeletion', { id })}
        className="float-end text-body px-0 py-0 ms-3"
        id={`remove-${id}`}
        disabled={isDemoAdmin}
        color="white">
        <FontAwesomeIcon size="lg" icon={faTrash} />
      </Button>
      {emailAddress.expired && (
        <>
          <Button
            className="float-end text-body px-0 py-0"
            color="white"
            disabled={isDemoAdmin}
            id={`resend-${id}`}
            onClick={resendVerification}>
            {isLoading ? (
              <FontAwesomeIcon size="lg" spin icon={faSpinner} />
            ) : (
              <FontAwesomeIcon size="lg" icon={faPaperPlane} />
            )}
          </Button>
          <UncontrolledTooltip placement="top" target={`resend-${id}`}>
            Resend verification email
          </UncontrolledTooltip>
        </>
      )}
    </>
  )
}

export default withStore(ActionsColumn)
