import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal'
import registerInterestSms from './registerInterestSms.svg'
import registerInterestEmail from './registerInterestEmail.svg'

const RegisterInterestModal = (): React.ReactElement => {
  return (
    <>
      <Modal
        id="registerInterestModal"
        size="md"
        className="register-interest-modal"
        render={(close: () => void, args) => (
          <ConfirmationModal
            onClose={close}
            onSubmit={args?.register}
            okText="Yes"
            cancelText="Cancel">
            <>
              <div className="register-interest-modal__media">
                <img
                  alt="Register interest"
                  src={
                    args?.name === 'SMS'
                      ? registerInterestSms
                      : registerInterestEmail
                  }
                />
              </div>
              <div className="mt-4">
                {args?.newType && (
                  <span className="campaign-tile__new">New</span>
                )}
                <h2 className="font-weight-medium">{args?.name}</h2>
              </div>
              Would you like to be contacted to learn more about this service?
            </>
          </ConfirmationModal>
        )}
      />
      <Modal
        id="successfulRegisterInterest"
        size="md"
        renderHeader={(close: () => void, args) => args?.name}
        render={(close: () => void, args) => (
          <ConfirmationModal onClose={close} cancelText="close">
            You have successfully registered your interest in {args?.name}
          </ConfirmationModal>
        )}
      />
    </>
  )
}

export default RegisterInterestModal
