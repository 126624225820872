import { Col, Row } from 'reactstrap'
import { useRef } from 'react'
import { Split } from '../../Blocks'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import { withStore } from '~/dataStore'
import Select from '~/components/forms/Select'
import { SplitSubType } from '../../Store/JourneyBuilder.interface'
import TimeFrameForm from '~/components/forms/TimeFrameForm'
import GoalDropdown from './GoalDropdown'
import { ID } from '~/common.interface'
import SeparatorLine from '~/components/SeparatorLine'
import { NotificationType, showNotification } from '~/utils/Notification'
import HintModal from '~/HintWindow/HintModal'
import HintBtn from '~/HintWindow/HintBtn'
import HowDismissWorksFile from '~/HintWindow/mediaFiles/Dismiss.gif'
import ToggleSwitch from '~/components/forms/ToggleSwitch'

interface IProps {
  block: Split
  openPanel: (nodeId: ID) => void
  disabled: boolean
}

function SplitPanel({
  block,
  openPanel,
  disabled
}: IProps): React.ReactElement {
  const hintRef = useRef()
  function notReachable(): boolean {
    return !!(
      block.subType.value && block.subType.value !== SplitSubType.REACHABLE
    )
  }

  function withDismiss(): boolean {
    return !!(
      block.subType.value && block.subType.value === SplitSubType.GOALABLE
    )
  }

  function openMessagePanel() {
    try {
      const blockId = block.getParentMessageBlockId()
      if (blockId) {
        openPanel(blockId)
      }
    } catch (error) {
      showNotification((error as Error).message, NotificationType.ERROR)
    }
  }

  return (
    <div>
      <Header className="mb-6">
        <HeaderTitle tag="h2" className="px-4">
          Split Conditions
        </HeaderTitle>
        <p className="color-subheader px-4" ref={hintRef}>
          Select the criteria the user need to meet to branch off the Journey.
          Here is where our engine will evaluate the users and decide whether to
          send them to one branch of the Journey or to the other
        </p>
      </Header>

      <HintModal
        elem={hintRef}
        link="https://docs.pulsatehq.com/reference/dismiss-as-a-split-condition"
        mediaFile={HowDismissWorksFile}>
        <h2>Dismiss</h2>
        <p>
          If a notification is dismissed the user will not receive any messages
          that follow
        </p>
        <ul className="my-3">
          <li>
            <strong>Push</strong>: Swipe away (user need to be log in)
          </li>
          <li>
            <strong>In-App</strong>: Closing by clicking X
          </li>
          <li>
            <strong>Feed Post</strong>: Deleting
          </li>
          <li>
            <strong>Email</strong>: Unsubscribe
          </li>
          <li>
            Any button with “dismiss” set as the action (push, in-app, feed
            post)
          </li>
          <li>Auto swipe of all notifications on device</li>
        </ul>
      </HintModal>

      <Row className="align-items-center px-4">
        <Col xs="4" className="flex-1-1">
          <Select
            disabled={disabled}
            invalid={!block.subType.isValid}
            wrapperClassName="w-100"
            className="w-100 dropdown-toggle--right"
            title="Choose Condition"
            options={[
              { name: 'Message Sent', value: SplitSubType.REACHABLE },
              { name: 'Goal Completed', value: SplitSubType.GOALABLE },
              {
                name: 'Integration Goal Completed',
                value: SplitSubType.INTEGRATIONABLE
              }
            ]}
            selectedOption={block.subType.value}
            onSelect={(value) => block.setSubType(value)}
          />
        </Col>
        {block.subType.value === SplitSubType.GOALABLE && (
          <Col xs="5" className="d-flex align-items-center">
            by clicking in
            <div className="flex-1-1 ps-4">
              <GoalDropdown
                disabled={disabled}
                invalid={!block.conditions.isValid}
                goals={block.getGoalOptions()}
                onSelect={block.setCondition}
                openPanel={openMessagePanel}
                selectedOption={block.getSelectedCondition()}
              />
            </div>
          </Col>
        )}
        {notReachable() && (
          <Col xs="5" className="flex-0-1">
            <TimeFrameForm
              disabled={disabled}
              timeLabel="within"
              units={
                process.env.NODE_CUSTOM_ENV === 'production'
                  ? ['hours', 'days', 'weeks']
                  : ['minutes', 'hours', 'days', 'weeks']
              }
              inputType="number"
              initialTimeValue={block.timeValue.value}
              initialTimeFrame={block.timeFrame.value}
              timeValueInvalid={!block.timeValue.isValid}
              onTimeValueChange={(e) => block.setTimeValue(e.target.value)}
              onTimeFrameChange={block.setTimeFrame}
            />
          </Col>
        )}
      </Row>

      {withDismiss() && (
        <div className="px-4">
          <SeparatorLine />

          <div className="d-flex align-items-center">
            <label
              className="d-flex align-items-center me-2 cursor-pointer"
              htmlFor="dismissToggle">
              <ToggleSwitch
                controlId="dismissToggle"
                disabled={disabled}
                onChange={block.setDismiss}
                checked={block.isDismissSelected}
              />
              <span className="ms-2">
                Do not send more notifications in case of dismiss
              </span>
            </label>
            <HintBtn>How dismiss works</HintBtn>
          </div>
        </div>
      )}
    </div>
  )
}

export default withStore(SplitPanel)
