import React from 'react'
import cn from 'classnames'
import {
  NodeType,
  NodeState,
  JourneyBlockType
} from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'

import './NodeIcon.scss'
import BlockIcon from '../../components/BlockIcons'

const NodeIcon = ({
  state,
  type,
  blockType
}: {
  state?: NodeState
  type?: NodeType
  blockType?: JourneyBlockType
}): React.ReactElement => {
  return (
    <div
      className={cn('flow-node-icon', {
        [`flow-node-icon--${state}`]: state,
        [`flow-node-icon--${type}`]: type
      })}>
      <BlockIcon blockType={blockType || type} />
    </div>
  )
}

export default NodeIcon
