import React from 'react'
import { Col } from 'reactstrap'
import ApexRadialBarChart from '~/components/ApexCharts/ApexRadialBarChart'
import StatCard from '~/components/reports/StatCard'
import { IGoalConversion } from '~/dataStore/reports/Report.interface'

const PrimarySecondaryConversion = ({
  chartData
}: {
  chartData: IGoalConversion[]
}): React.ReactElement => {
  return (
    <>
      {chartData.map((chart) => (
        <Col
          xs={6}
          xxxl={3}
          widths={['xs', 'xxxl']}
          className="mb-4 mb-xxxl-0"
          key={chart.title}>
          <StatCard
            title={chart.title}
            cardClasses="h-100"
            tooltip={chart.tooltip}
            classes="d-flex flex-wrap h-100">
            <div className="mx-auto">
              <ApexRadialBarChart chartData={chart} />
            </div>
          </StatCard>
        </Col>
      ))}
    </>
  )
}

export default PrimarySecondaryConversion
