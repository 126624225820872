import { ReactElement } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import cn from 'classnames'
import TableNew from '~/components/Table/TableNew'
import { useUserSubscriptions } from '~/pages/Users/UserDetails/useUserDetails'
import styles from '~/pages/Users/UserDetails/UserDetails.scss'
import { breakLine, col, colWide } from '~/components/Table/Table.scss'
import { IUserSubscription } from '~/pages/Users/Users.interface'

const columns = [
  {
    fieldName: 'name',
    displayName: 'Name',
    render: ({
      row: { name }
    }: {
      row: IUserSubscription
    }): React.ReactElement => (
      <div
        className={cn('d-flex align-items-center', col, colWide, breakLine)}
        aria-label={`Subscription Name: ${name}`}>
        {name}
      </div>
    )
  },
  {
    fieldName: 'type',
    displayName: 'Type',
    sortable: false
  },
  {
    fieldName: 'read',
    displayName: 'Read',
    render: ({ row }: { row: IUserSubscription }) => row.read.toString()
  },
  {
    fieldName: 'readAt',
    displayName: 'Read At',
    sortable: false
  },
  {
    fieldName: 'deliveredAt',
    displayName: 'Delivered At',
    sortable: false
  }
]

const UserSubscriptionsSection = (): ReactElement => {
  const {
    data: subscriptions,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading
  } = useUserSubscriptions()

  return (
    <Card className="mt-4" aria-label="User Subscriptions Section">
      <CardHeader
        className="d-flex justify-content-between pb-0 pt-3"
        aria-label="Subscriptions Header">
        <CardTitle tag="h3" className="mb-0" aria-label="Subscriptions Title">
          Campaigns List
        </CardTitle>
      </CardHeader>
      <CardBody className="pt-3 pb-4" aria-label="Subscriptions Body">
        <TableNew
          className={styles.listWrapper}
          columns={columns}
          hasMore={hasNextPage}
          onLoadMore={() => fetchNextPage()}
          isLoading={isLoading}
          isLoadingMore={isFetchingNextPage}
          rows={subscriptions?.pages || []}
          contentScrollable
        />
      </CardBody>
    </Card>
  )
}

export default UserSubscriptionsSection
