import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import imac from '~/components/icons/device/imac.svg'
import android from '~/components/icons/device/android.svg'
import ios from '~/components/icons/device/ios.svg'
import watch from '~/components/icons/device/ios_watch.svg'
import iosAppBg from '~/components/icons/device/ios_app_bg.svg'
import androidAppBg from '~/components/icons/device/android_app_bg.svg'
import iosAppBgOverlay from '~/components/icons/device/ios_app_bg_overlay.svg'
import iosPush from '~/components/icons/device/ios_push.svg'
import androidPush from '~/components/icons/device/android_push.svg'
import iosCardFront from '~/components/icons/device/ios_card_front.svg'
import androidCardFront from '~/components/icons/device/android_card_front.svg'
import iosCardBack from '~/components/icons/device/ios_card_back.svg'
import androidCardBack from '~/components/icons/device/android_card_back.svg'
import {
  CampaignType,
  DeviceType
} from '~/dataStore/Campaign/Campaign.interface'
import { getDeviceImageType } from '../Preview.service'

interface IProps {
  deviceType?: DeviceType
  campaignType?: CampaignType
  deviceSize?: 'xs' | 'sm' | 'lg' | 'xl' | 'xxl'
  withOverlay?: boolean
  id?: string
  isSmallInApp?: boolean
}

const DeviceBG = React.memo(function DeviceBG({
  deviceType,
  campaignType,
  withOverlay,
  isSmallInApp
}: {
  deviceType?: DeviceType
  campaignType?: CampaignType
  withOverlay?: boolean
  isSmallInApp?: boolean
}): React.ReactElement {
  function getDevice() {
    switch (getDeviceImageType(deviceType, campaignType, isSmallInApp)) {
      case 'desktop':
        return imac
      case 'ios':
        return ios
      case 'android':
        return android
      case 'watch':
        return watch
      case 'iosAppBg':
        if (withOverlay) {
          return iosAppBgOverlay
        }
        return iosAppBg
      case 'androidAppBg':
        return androidAppBg
      case 'iosPush':
        return iosPush
      case 'iosCardFront':
        return iosCardFront
      case 'androidCardFront':
        return androidCardFront
      case 'iosCardBack':
        return iosCardBack
      case 'androidCardBack':
        return androidCardBack
      case 'androidPush':
        return androidPush
      default:
        return ios
    }
  }

  return (
    <img
      data-testid="device-bg"
      src={getDevice()}
      alt=""
      className="preview-device__img"
    />
  )
})

export default function PreviewDevice({
  deviceType,
  campaignType,
  children,
  deviceSize,
  withOverlay,
  id,
  isSmallInApp
}: PropsWithChildren<IProps>): React.ReactElement {
  return (
    <div
      id={id}
      className={cn(
        'preview-device',
        { [`preview-device--${deviceSize}`]: !!deviceSize },
        `preview-device--${deviceType}`,
        {
          [`preview-device--${campaignType}`]:
            campaignType === CampaignType.CARD_BACK ||
            campaignType === CampaignType.IN_APP
        }
      )}>
      <div className="position-relative">
        <DeviceBG
          campaignType={campaignType}
          deviceType={deviceType}
          withOverlay={withOverlay}
          isSmallInApp={isSmallInApp}
        />
        <div className="preview-device__box">
          <div className="preview-device__content">
            <div
              className={cn('h-100', {
                'overflow-hidden': deviceType !== 'desktop'
              })}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
