import React, { useRef } from 'react'

import cn from 'classnames'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { uniqueId } from 'lodash'
import { withStore } from '~/dataStore'

import { dwellingTxt, dwellingInput } from './style.scss'
import GeofenceModel from '~/dataStore/emailBuilder/Targeting/Geofence.model'
import blockNegativeVal from '~/utils/blockNegativeVal'
import { MAX_DWELL_TIME, MIN_DWELL_TIME } from '../../Email/consts'

function Geofence({
  row,
  onSelectRow,
  isSelected,
  disabled
}: {
  row: GeofenceModel
  onSelectRow: (row: GeofenceModel, selected: boolean) => void
  isSelected?: boolean
  disabled?: boolean
}) {
  const id = useRef(uniqueId('geofenceName'))
  return (
    <>
      <Button
        className="p-0 w-100 text-start shadow-none d-flex align-items-center mb-0 "
        onClick={() => onSelectRow(row, !isSelected)}
        color="">
        <span tabIndex={0} role="button" className="truncate" id={id.current}>
          {row.name}
        </span>
        <UncontrolledTooltip placement="top" target={id.current}>
          {row.name}
        </UncontrolledTooltip>
      </Button>

      {row.isDwellTrigger && (
        <div className={dwellingTxt} onClick={(e) => e.stopPropagation()}>
          <span>Dwell time is</span>
          <input
            disabled={disabled}
            className={cn(
              dwellingInput,
              `${!row.dwellTime.isValid ? 'error-input' : ''}`
            )}
            type="number"
            min={MIN_DWELL_TIME}
            max={MAX_DWELL_TIME}
            onKeyDown={blockNegativeVal}
            onChange={(e) => row.setDwellTime(e.target.value)}
            value={row.dwellTime.value}
          />
          <span>minutes</span>
        </div>
      )}
    </>
  )
}

export default withStore(Geofence)
