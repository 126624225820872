import { uniqueId } from 'lodash'
import React, { useRef } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

const EmailDetail = ({
  icon,
  label,
  value
}: {
  icon: string
  label: string
  value: string
}): React.ReactElement => {
  const id = useRef(uniqueId('detail'))
  return (
    <div className="d-flex align-items-center justify-content-center">
      <img src={icon} alt={label} />
      <div className="ms-3">
        <p id={id.current} className="mb-0 truncate">
          {value}
        </p>
        <UncontrolledTooltip direction="up" target={id.current}>
          {value}
        </UncontrolledTooltip>
        <p className="muted mb-0">{label}</p>
      </div>
    </div>
  )
}

export default EmailDetail
