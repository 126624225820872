import React, { useState, useEffect, useRef } from 'react'

import './Masonry.scss'

interface Props {
  breakPoints: Array<number>
}

const Masonry = ({ breakPoints, children }: Props) => {
  const [columns, setColumns] = useState(1)
  const masonryRef = useRef(null)

  const getColumns = (width: number): number =>
    breakPoints.reduceRight((accumulator, currentValue, i) => {
      return currentValue < width ? accumulator : i
    }, breakPoints.length) + 1

  const onResize = () => {
    const columnsSize = getColumns(masonryRef.current?.offsetWidth)
    if (columnsSize !== columns) {
      setColumns(columnsSize)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
  }, [])

  const getColumnsAndItems = (): Array<Array<React.ReactElement>> => {
    const columnsAndItems = []
    const numberOfColumns = columns
    for (let i = 0; i < numberOfColumns; i += 1) {
      columnsAndItems.push([])
    }

    return children.reduce((accumulator, currentValue, i) => {
      accumulator[i % numberOfColumns].push(currentValue)
      return accumulator
    }, columnsAndItems)
  }

  return (
    <div className="masonry" ref={masonryRef}>
      {getColumnsAndItems().map((column: Array<React.ReactElement>, i) => (
        <div className="masonry__column" key={i}>
          {column.map((child) => child)}
        </div>
      ))}
    </div>
  )
}

export default Masonry
