import React, { useEffect, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { Button, FormGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfoWindow } from '@react-google-maps/api'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { locationToLatLng } from '~/pages/Geofences/utils'
import Input from '~/components/forms/Input'
import RangeSlider from '~/components/RangeSlider'
import { IGeofence, IUnitSystem } from '~/pages/Geofences/Geofences.interface'
import { withStore } from '~/dataStore'

interface IProps {
  saveGeofence: (geofence: IGeofence) => void
  removeGeofence: (geofence: IGeofence) => void
  geofence: IGeofence
  minRadius: number | undefined
  maxRadius: number | undefined
  unitSystem: IUnitSystem
  radius: number
  name: string
  setName: (name: string | undefined) => void
  setRadius: (radius: number | undefined) => void
  isGeofenceSaving: boolean
  setIsRadiusSliderDragged: (value: boolean) => void
}

const CircleInfoWindow = ({
  saveGeofence,
  removeGeofence,
  geofence,
  minRadius,
  maxRadius,
  radius,
  name,
  setRadius,
  setName,
  isGeofenceSaving,
  setIsRadiusSliderDragged,
  unitSystem
}: IProps) => {
  const onSubmit = (updatedValue: { radius?: number; name?: string }): void => {
    saveGeofence({ ...geofence, ...updatedValue })
  }

  const delayedOnChange = useCallback(
    debounce((value) => onSubmit({ ...value }), 300),
    [saveGeofence, geofence]
  )

  const internalOnNameChange = useCallback(
    (e) => {
      setName(e.target.value)
      delayedOnChange({ name: e.target.value })
    },
    [delayedOnChange]
  )

  const internalOnRadiusChange = useCallback(
    (e) => {
      const newRadius =
        e.target.value > maxRadius ? maxRadius : Number(e.target.value)

      setRadius(newRadius)
      if (e.target.value !== '') delayedOnChange({ radius: newRadius })
    },
    [delayedOnChange]
  )

  useEffect(() => {
    if (name !== geofence.name) setName(geofence.name)
  }, [geofence.name])

  useEffect(() => {
    if (radius !== geofence.radius) setRadius(geofence.radius)
  }, [geofence.radius])

  return (
    <InfoWindow position={locationToLatLng(geofence)}>
      <div className="info-window">
        <FormGroup
          className={`${
            geofence.error?.name?.length ? 'form-group--error' : ''
          }`}>
          <Input
            name="name"
            placeholder="Enter Geofence name"
            type="text"
            value={name}
            disabled={isGeofenceSaving}
            id="name"
            onChange={internalOnNameChange}
            className="text-base mb-1"
          />
          {geofence.error?.name?.length && (
            <span className="error-message">{geofence.error?.name[0]}</span>
          )}
        </FormGroup>
        <div>
          <div className="d-flex mt-4 align-items-center justify-content-center">
            <span className="color-secondary fw-bold fw-medium text-base">
              Radius
            </span>
            <FormGroup
              className={`${
                geofence.error?.radius?.length ? 'form-group--error' : ''
              } mb-0`}>
              <Input
                placeholder=""
                bsSize="xs"
                type="number"
                value={radius}
                min={minRadius}
                onChange={internalOnRadiusChange}
                className="info-window__radius mb-0 px-2"
              />
            </FormGroup>
            <span className="color-secondary fw-medium text-base">
              {unitSystem === 'metric' ? 'm' : 'ft'}
            </span>
          </div>
          {geofence.error?.radius?.length && (
            <p className="error-label mt-2 text-center">
              {geofence.error?.radius[0]}
            </p>
          )}
          <div className="mt-2 px-4">
            <RangeSlider
              min={minRadius}
              step={1}
              max={maxRadius}
              values={[radius]}
              onChange={(values) => {
                setIsRadiusSliderDragged(true)
                setRadius(values[0])
              }}
              onFinalChange={(values) => {
                setIsRadiusSliderDragged(false)
                onSubmit({ radius: values[0] })
              }}
            />
          </div>
        </div>

        <Button
          className="btn--transparent mx-auto mt-4 d-block"
          color=""
          onClick={() => removeGeofence(geofence)}>
          <span className="color-body text-base font-medium">
            <FontAwesomeIcon icon={faTrash} className="me-2" />
            Delete
          </span>
        </Button>
      </div>
    </InfoWindow>
  )
}

export default withStore(CircleInfoWindow)
