import React, { useState } from 'react'

import { Button } from 'reactstrap'
import Input from '~/components/forms/Input/Input'
import { withStore } from '~/dataStore'
import blockNegativeVal from '~/utils/blockNegativeVal'
import { MAX_DWELL_TIME, MIN_DWELL_TIME } from '../Email/consts'

interface IProps {
  action: (value: number) => void
  isValid: boolean
}

function DwellTime({ action, isValid }: IProps) {
  const [isActive, setActive] = useState(false)
  const [time, setTime] = useState()

  const toggle = () => setActive((prevState) => !prevState)

  function handleOnChange(e) {
    const {
      target: { value }
    } = e

    setTime(value.startsWith('00') ? '0' : value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    action(Number(time))
  }

  return (
    <>
      <button
        type="button"
        className="btn-no-outline text-dark dropdown-item"
        onClick={toggle}>
        Set to "Dwell time"
      </button>
      {isActive && (
        <form
          className="my-2 px-15p d-flex align-items-center justify-content-between"
          onSubmit={handleSubmit}
          noValidate>
          <Input
            name="dwellTime"
            type="number"
            min={MIN_DWELL_TIME}
            max={MAX_DWELL_TIME}
            onKeyPress={blockNegativeVal}
            onChange={handleOnChange}
            className={`m-0 ${!isValid ? 'error-input' : ''}`}
          />

          <Button type="submit" color="body" className="no-wrap ms-2">
            Save
          </Button>
        </form>
      )}
    </>
  )
}

export default withStore(DwellTime)
