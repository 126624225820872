import cn from 'classnames'
import { Col, Row } from 'reactstrap'
import { Fragment } from 'react'
import StatCard from '~/components/reports/StatCard'
import { IGoalPerformance } from '../../Store/JourneyReport.interface'
import styles from './GoalsPerformance.scss'
import SeparatorLine from '~/components/SeparatorLine'
import GoalIcon from '~/pages/Campaign/Notification/NotificationGoals/UI/GoalIcon'
import Tooltip from '~/components/reports/Tooltip'
import { IChartReport } from '~/pages/Campaign/Notification/Notification.interface'
import GoalsPerformanceRow from './GoalsPerformanceRow'
import { isEmpty } from '~/utils/utilities'

interface IProps {
  goals: IGoalPerformance[]
  externalGoal?: {
    conversions: IChartReport
  }[]
}

export default function GoalsPerformance({
  goals = [],
  externalGoal
}: IProps): React.ReactElement {
  return (
    <StatCard title="Goals Performance">
      {externalGoal?.map((goal, idx) => (
        <Fragment key={idx}>
          <SeparatorLine />
          <Row>
            <Col
              widths={['xs', 'xxl']}
              xs="4"
              xxl="3"
              className="d-flex align-items-center">
              <GoalIcon type="external" size="xl" />
              <span className="ms-3 text-18 fw-medium">Integration Goal</span>
              <Tooltip tooltip="Represents the external data provided as a file or API" />
            </Col>
            <Col xs="8" xxl="4" widths={['xs', 'xxl']}>
              <Row className={cn(styles.tableHeader, 'align-items-end')}>
                <Col xs="4">
                  <span className="fw-medium">Conversion</span>
                  <div
                    className={cn(
                      'color-fuchsia-pink',
                      styles.externalConversion
                    )}>
                    {goal.conversions.totalUniq ?? '- -'}
                  </div>
                </Col>
                <Col xs="6">
                  <span className="fw-medium">Conversion Rate</span>
                  <div
                    className={cn(
                      'color-fuchsia-pink',
                      styles.externalConversion
                    )}>
                    {!isEmpty(goal.conversions?.totalUniqRate)
                      ? `${goal.conversions?.totalUniqRate}%`
                      : '- -'}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <SeparatorLine />
        </Fragment>
      ))}
      <Row className={cn(styles.tableHeader, 'mt-4', 'align-items-end')}>
        <Col xs="3">Name</Col>
        <Col xs="4">
          <Row className="align-items-end">
            <Col xs="4" className="d-flex align-items-center" title="Delivered">
              <span className="truncate">Delivered</span>
              <Tooltip tooltip="Total number of notifications that were delivered successfully" />
            </Col>
            <Col
              xs="4"
              className="d-flex align-items-center"
              title="Impressions">
              <span className="truncate">Impressions</span>
              <Tooltip tooltip="Total number of times users have seen the notification" />
            </Col>
            <Col xs="4" className="d-flex align-items-center" title="Clicks">
              <span className="truncate">Clicks</span>
              <Tooltip tooltip="Total number of clicks on the notification and buttons" />
            </Col>
          </Row>
        </Col>
        <Col xs="5">
          <Row className="align-items-end">
            <Col xs="4" className="d-flex align-items-center" title="CTR">
              <span className="truncate">CTR</span>
              <Tooltip tooltip="Unique Clicked Value divided by Unique Delivered Value" />
            </Col>
            <Col
              xs="4"
              title="Primary Goal Conversion"
              className="d-flex align-items-center">
              <div className="me-2">
                <GoalIcon type="primary" size="lg" />
              </div>
              <div>
                <div>Primary</div> <div>Goal</div>
                <div className="no-wrap">
                  Conversion{' '}
                  <Tooltip
                    inline
                    tooltip="Unique number of times primary goal was achieved per user in notification"
                  />
                </div>
              </div>
            </Col>
            <Col
              xs="4"
              title="Secondary Goal Conversion"
              className="d-flex  align-items-center">
              <div className="me-2">
                <GoalIcon type="secondary" size="lg" />
              </div>
              <div>
                <div>Secondary</div>
                <div>Goal</div>
                <div className="no-wrap">
                  Conversion{' '}
                  <Tooltip
                    inline
                    tooltip="Unique number of times secondary goal was achieved per user in notification"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <SeparatorLine spaceBottom={2} spaceTop={2} />
      {goals.map((goal) => (
        <div className={cn(styles.table)} key={goal.id}>
          <GoalsPerformanceRow row={goal} />
        </div>
      ))}
    </StatCard>
  )
}
