import React, { useRef } from 'react'
import cn from 'classnames'
import Chart from 'react-apexcharts'
import { Card, UncontrolledTooltip } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { uniqueId } from 'lodash'
import {
  IEngagementSummary,
  RADIAL_CHART_OPTIONS
} from '../../OpportunitiesDashboard.interface'

import styles from './RadialCharts.scss'

const RadialCharts = ({
  summaryData,
  currentRange,
  direction
}: {
  summaryData: IEngagementSummary | undefined
  currentRange: number | undefined
  direction?: 'vertical' | 'horizontal'
}) => {
  const pushTooltip = useRef(uniqueId('tooltip-'))
  const geoTooltip = useRef(uniqueId('tooltip-'))

  return (
    <Card
      className={cn(styles.stats, {
        [styles.statsVertical]: direction === 'vertical'
      })}>
      <div className={styles.stat}>
        <p className={styles.statTitle} id={pushTooltip.current}>
          <span className="d-xxl-none d-xxxl-block">Push Opportunity Rate</span>
          <span className="d-none d-xxl-block d-xxxl-none">Push Opp Rate</span>
        </p>
        <UncontrolledTooltip
          target={pushTooltip.current}
          popperClassName="tooltip-modern">
          <strong>Push Opportunity Rate</strong> <br />
          <span>
            Percentage of users with double opt-in for push permissions who
            engaged with a push campaign in the last {currentRange} days,
            divided by the total push notifications sent within the same period
          </span>
        </UncontrolledTooltip>
        <div className={cn(styles.chart, 'mx-auto')}>
          {summaryData ? (
            <Chart
              options={RADIAL_CHART_OPTIONS}
              series={[summaryData.engagement.push.rate]}
              type="radialBar"
              height={200}
            />
          ) : (
            <Skeleton circle width={135} height={135} className="mt-3" />
          )}
        </div>
      </div>

      <div className={styles.stat}>
        <p className={styles.statTitle} id={geoTooltip.current}>
          <span className="d-xxl-none d-xxxl-block">
            Geofence Opportunity Rate
          </span>
          <span className="d-none d-xxl-block d-xxxl-none">
            Geofence Opp Rate
          </span>
        </p>
        <UncontrolledTooltip
          target={geoTooltip.current}
          popperClassName="tooltip-modern">
          <strong>Geofence Opportunity Rate</strong> <br />
          <span>
            Percentage of users with double opt-in for location permissions
            (foreground and background) who engaged with a geofence campaign in
            the last {currentRange} days, divided by the total
            geofence-triggered campaigns and journeys sent within the same
            period
          </span>
        </UncontrolledTooltip>
        <div className={cn(styles.chart, 'mx-auto')}>
          {summaryData ? (
            <Chart
              height={200}
              options={RADIAL_CHART_OPTIONS}
              series={[summaryData.engagement.geo.rate]}
              type="radialBar"
            />
          ) : (
            <Skeleton circle width={135} height={135} className="mt-3" />
          )}
        </div>
      </div>
    </Card>
  )
}

export default RadialCharts
