import cn from 'classnames'
import { GoalType } from '../NotificationGoals.interface'
import GoalIcon from './GoalIcon'

import styles from './Goals.scss'

interface IProps {
  goals: GoalType[]
  type: GoalType
  noBorder?: boolean
}

export default function GoalIconWithPlaceholder({
  goals,
  type,
  noBorder
}: IProps): React.ReactElement {
  return (
    <>
      <span
        className={cn(styles.goalPlaceholder, {
          [styles.goalPlaceholderNoBorder]: goals.includes(type) || noBorder
        })}>
        {goals.includes(type) && <GoalIcon type={type} size="lg" />}
      </span>
    </>
  )
}
