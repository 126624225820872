import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  uploadRequest
} from '~/api/requests'

const v1ApiPrefix = '/api/v1/apps/'

export function get(
  path: string,
  params = {},
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return getRequest(`${v1ApiPrefix}${path}`, params, signal)
}

export function post(
  path: string,
  data = {},
  signal: AbortSignal | undefined = undefined
): Promise<any> {
  return postRequest(`${v1ApiPrefix}${path}`, data, 'POST', signal)
}

export function put(path: string, data = {}): Promise<any> {
  return putRequest(`${v1ApiPrefix}${path}`, data)
}

export function destroy(path: string, data = {}): Promise<any> {
  return deleteRequest(`${v1ApiPrefix}${path}`, data)
}

export function upload(
  path: string,
  file: FormData,
  method?: string
): Promise<any> {
  return uploadRequest(`${v1ApiPrefix}${path}`, file, method)
}
