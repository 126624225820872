export const ERRORS_DICT = {
  appleCertificate: {
    'Missing parameter apple_certificate': 'Apple certificate is missing',
    'PEM cert is invalid!': 'PEM certificate is invalid',
    'Setting is invalid': 'PEM certificate is invalid'
  }
}

export interface FormValues {
  appleCertificate: FileList | string
  applePassword?: string
  appleBundleId?: string
}
