import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Col, Row } from 'reactstrap'
import useDeviceTypeSwitch from '~/pages/Campaign/Notification/Preview/useDeviceTypeSwitch'
import DeviceTypeSwitch from '~/pages/Campaign/Notification/Preview/DeviceTypeSwitch'
import PreviewDesktop from '~/pages/Campaign/Notification/Preview/PreviewDesktop'
import PreviewMobile from '~/pages/Campaign/Notification/Preview/PreviewMobile'
import { isDeviceSpecificCampaign } from '~/pages/Campaign/Notification/Preview/Preview.service'
import PreviewPopover from '~/pages/Campaign/Notification/Preview/PreviewPopover'
import StatusPill from '~/components/Filters/StatusPill'
import Columns from '~/components/Columns'
import HeaderTitle from '~/components/HeaderTitle'
import StatCard from '~/components/reports/StatCard'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import CampaignTypeSwitch from './CampaignTypeSwitch'
import SummaryField from './UI/SummaryField'
import {
  CampaignType,
  IPreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import { IReport, ISummary } from './Model/Report.interface'
import { useStore } from '~/dataStore'
import SummaryTarget from '~/components/reports/SummaryTarget'

interface IProps {
  report?: IReport | undefined
  summary: ISummary[]
  summaryNumbers: ISummary[]
  preview?: IPreviewParts
  types: (IOption<CampaignType> & { original: CampaignType })[]
  currentType: CampaignType | undefined
  setType: (tab: CampaignType) => void
}

export default function CampaignSummary({
  report,
  summary,
  summaryNumbers,
  preview,
  types,
  currentType,
  setType
}: IProps): React.ReactElement {
  const {
    app: {
      appDetails: {
        featureFlags: { campaignsGoals }
      }
    }
  } = useStore()
  const { deviceType, setDeviceType } = useDeviceTypeSwitch('ios')
  // TODO: refactor preview by mapped campaign type
  const optionType = types.find((type) => type.value === currentType)

  let currentOriginalType
  if (optionType) {
    currentOriginalType = optionType.original
  }
  const deviceSpecificCampaign = isDeviceSpecificCampaign(currentOriginalType)

  const summaryNumbersData = campaignsGoals
    ? summaryNumbers
    : summaryNumbers.filter((item) => item.label !== 'Control Group')

  return (
    <StatCard>
      <Row>
        <Col xl="9">
          <div className="d-flex align-items-center mb-5">
            {report?.status ? (
              <>
                <StatusPill
                  className="me-3 status-pill"
                  status={report.status}
                  mini
                  width="auto"
                />
                <HeaderTitle className="mb-0">{report.name}</HeaderTitle>
              </>
            ) : (
              <Skeleton width="200px" />
            )}
          </div>
          <Columns columnCount="3" className="mb-5" border>
            {summary.map((field) => (
              <SummaryField
                key={field.label}
                label={field.label}
                content={field.content}
                className={field.className}
                tooltip={field.tooltip}
              />
            ))}
            {report?.target && (
              <SummaryField
                label="Target"
                content={<SummaryTarget target={report.target} />}
              />
            )}
          </Columns>
          <Row>
            {summaryNumbersData.map((field) => (
              <Col md="2" lg="4" key={field.label}>
                <div className="d-flex align-items-end">
                  <div className={`icon icon--big me-3 ${field.icon}`} />
                  <SummaryField
                    label={field.label}
                    content={field.content}
                    className={field.className}
                    tooltip={field.tooltip}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          xl="3"
          className="d-flex justify-content-center align-items-center">
          <div className="d-flex">
            {!currentOriginalType && <PreviewMobile device="ios" />}
            {currentOriginalType === CampaignType.EMAIL && (
              <>
                <div id="preview-wrapper">
                  <PreviewDesktop srcDoc={preview?.html} />
                </div>
                <PreviewPopover
                  target="preview-wrapper"
                  campaignType={CampaignType.EMAIL}
                  size="xl"
                  deviceType="desktop"
                  parts={preview}
                />
              </>
            )}
            {currentOriginalType &&
              currentOriginalType !== CampaignType.EMAIL && (
                <>
                  <div id="preview-wrapper" className="position-relative">
                    <PreviewMobile
                      campaignType={currentOriginalType}
                      preview={preview}
                      device={deviceType}
                    />

                    {deviceSpecificCampaign && (
                      <div className="position-absolute preview-switch-wrapper">
                        <DeviceTypeSwitch
                          setDeviceType={setDeviceType}
                          activeDevice={deviceType}
                        />
                      </div>
                    )}
                  </div>
                  <PreviewPopover
                    target="preview-wrapper"
                    campaignType={currentOriginalType}
                    deviceType={deviceType}
                    parts={preview}
                  />
                </>
              )}
            {types.length > 1 && (
              <CampaignTypeSwitch
                tabs={types}
                activeTab={currentType}
                toggle={setType}
                classes="tabs--button"
              />
            )}
          </div>
        </Col>
      </Row>
    </StatCard>
  )
}
