import React from 'react'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal/Modal'
import { useRemoveRevenueEvent } from '../../useRevenueEvents'

const DeleteRevenueEventModal = () => {
  const { mutate: removeEvent } = useRemoveRevenueEvent()

  return (
    <Modal
      id="confirmRevenueEventDeletion"
      renderHeader={() => 'Delete Revenue Event'}
      render={(close: () => void, params) => (
        <ConfirmationModal
          onSubmit={() => {
            removeEvent({ id: params?.id, closeModal: close })
          }}
          onClose={close}
          okText="Delete"
          cancelText="Cancel"
          body="Are you sure you wish to delete this revenue event?"
        />
      )}
    />
  )
}

export default DeleteRevenueEventModal
