import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import ExportByDate from '~/components/ExportByDate'

const BeaconsActions = ({
  addBeaconModal,
  exportBeacons
}: {
  addBeaconModal: () => void
  exportBeacons: ({ from, to }: { from: string; to: string }) => void
}): React.ReactElement => {
  return (
    <div className="d-flex align-items-center">
      <ExportByDate onSubmit={exportBeacons} label="Export Report" />
      <Button
        color="primary"
        className="btn--wide btn--hover"
        size="lg"
        onClick={addBeaconModal}>
        <FontAwesomeIcon icon={faPlus} size="xs" className="me-2" /> New Beacon
      </Button>
    </div>
  )
}

export default BeaconsActions
