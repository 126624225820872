import { isEmpty, startCase, uniqueId } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { ID } from '~/common.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { IBeacon } from '~/pages/Beacons/Beacons.interface'
import { ICampaignsListItem } from '~/pages/Campaign/CampaignsList/CampaignsList.interface'
import { IGeofence } from '~/pages/Geofences/Geofences.interface'
import { pickColor } from '~/pages/Geofences/utils'
import {
  BE_RESOURCE_TYPE_MAP,
  customRuleTypes,
  RuleDTO,
  SegmentRuleDTO
} from '../SegmentBuilder.interface'

type EventResource = IBeacon | Partial<IGeofence> | IGroup | ICampaignsListItem

export default class SegmentRule {
  static getRule(rule: RuleDTO): SegmentRule {
    let key: (typeof customRuleTypes)[number] | RuleDTO['type'] = rule.type
    if (customRuleTypes.includes(rule.key)) {
      key = rule.key as (typeof customRuleTypes)[number]
    }

    switch (key) {
      case 'boolean':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'equal',
          matchValue: 'false'
        })

      case 'all_users':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'equal',
          matchValue: 'true'
        })
      case 'date_ago':
      case 'signup':
      case 'last_active':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'less',
          matchValue: '',
          timeFrame: 'minutes'
        })
      case 'numeric':
      case 'integer':
        return new SegmentRule(rule, {
          type: rule.key,
          matchValue: '',
          matchType: 'less'
        })
      case 'string':
        return new SegmentRule(rule, {
          type: rule.key,
          matchValue: '',
          matchType: 'equal'
        })
      case 'device_type':
        return new SegmentRule(rule, {
          type: rule.key,
          matchValue: 'any',
          matchType: 'equal'
        })
      case 'ip':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'equal'
        })
      case 'device_push_permission':
      case 'device_location_permission':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'equal',
          matchValue: 'true'
        })
      case 'last_campaign_open':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'less',
          matchValue: '',
          timeFrame: 'minutes',
          eventResourceType: BE_RESOURCE_TYPE_MAP.campaign
        })
      case 'last_been_at':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'less',
          matchValue: '',
          timeFrame: 'minutes',
          eventResourceType: BE_RESOURCE_TYPE_MAP.beacon
        })
      case 'number_of_visits':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'less',
          matchValue: '',
          eventResourceType: BE_RESOURCE_TYPE_MAP.beacon
        })
      case 'last_in_app_event':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'less',
          matchValue: '',
          timeFrame: 'minutes',
          timeDirection: 'ago'
        })
      case 'number_of_event_clicks':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'less',
          matchValue: ''
        })
      case 'event_recorded':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'equal',
          matchValue: 'true'
        })
      case 'location':
        return new SegmentRule(rule, {
          type: rule.key,
          matchType: 'between',
          matchValue: '1000'
        })
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = rule.type
        throw new Error(exhaustiveCheck)
    }
  }

  public id: ID = uniqueId()

  public rule: RuleDTO

  public eventResource: EventResource | undefined = undefined

  private field: NewRegisteredField<SegmentRuleDTO>

  private constructor(rule: RuleDTO, typeSpecificValues: SegmentRuleDTO) {
    makeAutoObservable(this, undefined, { autoBind: true })

    this.rule = rule

    this.field = new NewRegisteredField(typeSpecificValues)

    if (rule.key === 'location') {
      this.setEventResource({
        isEditable: false,
        isDraggable: true,
        location: [53.349322, -6.260254],
        radius: 1000,
        shape: 'circle',
        name: 'rule',
        color: pickColor(1)
      })
    }
  }

  public get value(): SegmentRuleDTO {
    return this.field.value
  }

  public get isValid(): boolean {
    return this.field.isValid
  }

  public get errors(): {
    [x: string]: string
  } {
    return this.field.keyErrors
  }

  public getTitle(): string {
    const name = (this.value.type).includes('->') ? this.value.type : startCase(this.value.type)
    if (this.value.type === 'opt_out') {
      return `Push ${name}`
    }
    return name
  }

  public setEventResource<T extends EventResource>(value: T | undefined): void {
    this.eventResource = value
  }

  public edit<Key extends keyof SegmentRuleDTO>(value: {
    [x in Key]: SegmentRuleDTO[Key]
  }): void {
    this.field.setValue({ ...this.value, ...value })
  }

  public validateRule(): void {
    this.field.resetError()

    if (this.field.value.matchType === 'anniversary_today') return

    if (isEmpty(this.value.matchValue)) {
      this.field.setKeyError('matchValue', "can't be blank")
    }

    if (['numeric', 'date_ago', 'integer'].includes(this.rule.type)) {
      const matchValue = Number(this.value.matchValue)
      if (isEmpty(this.value.matchValue)) {
        this.field.setKeyError('matchValue', "can't be blank")
      } else if (
        !Number.isInteger(matchValue) ||
        this.value.matchValue.includes('.')
      ) {
        this.field.setKeyError('matchValue', 'must be an integer')
      }

      if (this.value.matchType === 'between') {
        const matchEndValue = Number(this.value.matchEndValue)
        if (isEmpty(this.value.matchEndValue)) {
          this.field.setKeyError('matchEndValue', "can't be blank")
        } else if (
          !Number.isInteger(matchEndValue) ||
          this.value.matchEndValue.includes('.')
        ) {
          this.field.setKeyError('matchEndValue', 'must be an integer')
        }
      }
    }
  }

  public getPayload(): SegmentRuleDTO {
    const payload = this.value

    if (this.eventResource) {
      if (payload.type === 'location' && 'radius' in this.eventResource) {
        return {
          ...payload,
          locations: [
            {
              type: 'point',
              values: {
                center: this.eventResource.location,
                radius: Number(this.eventResource.radius)
              }
            }
          ]
        }
      }

      return {
        ...payload,
        eventResourceId: this.eventResource.id
      }
    }

    return payload
  }

  public fill(data: SegmentRuleDTO): void {
    this.edit(data)

    if (data.type === 'location') {
      this.setEventResource({
        isEditable: false,
        isDraggable: true,
        location: data.locations?.at(0)?.values.center,
        radius: Number(data.matchValue ?? 1000),
        shape: 'circle',
        name: 'rule',
        color: pickColor(1)
      })
    }
  }
}
