export const BACKGROUND_COLOR_SWATCH = [
  '#FFFFFF',
  '#990300',
  '#EE2650',
  '#F9861C',
  '#F1C232',
  '#5ABF9D',
  '#0E7AFE',
  '#28518C'
]

export const TEXT_COLOR_SWATCH = ['#FFFFFF', '#28518C', '#333333']
