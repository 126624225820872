import cn from 'classnames'
import { lowerCase } from 'lodash'
import { useEffect } from 'react'
import Input from '~/components/forms/Input'
import { useStore, withStore } from '~/dataStore'
import { MatchTypes, timeFrame } from '../../SegmentBuilder.interface'
import styles from '../../SegmentBuilder.scss'
import { useEvents } from '../../useSegmentBuilder'
import RuleDropdown from '../RuleDropdown'
import SegmentRule from '../SegmentRule.model'

interface IProps {
  rule: SegmentRule
}

const matchTypeOptions: { name: string; value: MatchTypes }[] = [
  {
    name: 'less than',
    value: 'less'
  },
  {
    name: 'more than',
    value: 'more'
  },
  {
    name: 'between',
    value: 'between'
  },
  {
    name: 'equal to',
    value: 'equal'
  },
  {
    name: 'not equal to',
    value: 'not_equal'
  }
]

function RuleLastInappEvent({ rule }: IProps): React.ReactElement {
  const {
    app: { currentApp }
  } = useStore()
  const { data: events } = useEvents(currentApp.id)

  useEffect(() => {
    if (!rule.value.inAppEventName && events?.data) {
      rule.edit({ inAppEventName: events.data[0] })
    }
  }, [events?.data])

  if (rule.value.type === 'event_recorded') {
    return (
      <>
        <RuleDropdown
          selected={rule.value.inAppEventName || 'nothing selected'}
          options={
            events?.data.map((item) => ({
              name: item,
              value: item
            })) || []
          }
          action={(value) => {
            rule.edit({ inAppEventName: value })
          }}
          aria-label="In-App Event Name"
        />

        <span aria-label="is">is</span>
        <RuleDropdown
          selected={rule.value.matchValue}
          options={['true', 'false'].map((elem) => ({
            name: elem,
            value: elem
          }))}
          action={(value) => rule.edit({ matchValue: value })}
          aria-label="Match Value"
        />
      </>
    )
  }

  return (
    <>
      <RuleDropdown
        selected={rule.value.inAppEventName || 'nothing selected'}
        options={
          events?.data.map((item) => ({
            name: item,
            value: item
          })) || []
        }
        action={(value) => {
          rule.edit({ inAppEventName: value })
        }}
        aria-label="In-App Event Name"
      />

      <span aria-label="is">is</span>
      <RuleDropdown
        selected={
          matchTypeOptions.find(
            (option) => option.value === rule.value.matchType
          )?.name
        }
        options={matchTypeOptions}
        action={(value) => {
          rule.edit({
            matchType: value,
            matchEndValue: value === 'between' ? '' : undefined
          })
        }}
        aria-label="Match Type"
      />
      <Input
        className={cn('mb-0', styles.ruleInput)}
        value={rule.value.matchValue}
        onChange={(e) => rule.edit({ matchValue: e.target.value })}
        name={`rule-numeric-matchValue-${rule.id}`}
        errorTooltip={rule.errors.matchValue}
        invalid={!!rule.errors.matchValue}
        aria-label="Match Value"
      />
      {rule.value.matchType === 'between' && (
        <>
          <span aria-label="and">and</span>
          <Input
            className={cn('mb-0', styles.ruleInput)}
            value={rule.value.matchEndValue}
            onChange={(e) => rule.edit({ matchEndValue: e.target.value })}
            name={`rule-numeric-matchEndValue-${rule.id}`}
            errorTooltip={rule.errors.matchEndValue}
            invalid={!!rule.errors.matchEndValue}
            aria-label="Match End Value"
          />
        </>
      )}

      {rule.value.type === 'last_in_app_event' && (
        <>
          <RuleDropdown
            selected={lowerCase(rule.value.timeFrame)}
            options={timeFrame.map((frame) => ({ name: frame, value: frame }))}
            action={(value) => rule.edit({ timeFrame: value })}
            aria-label="Time Frame"
          />

          <RuleDropdown
            selected={lowerCase(rule.value.timeDirection)}
            options={(['ago', 'away'] as const).map((item) => ({
              name: item,
              value: item
            }))}
            action={(value) => rule.edit({ timeDirection: value })}
            aria-label="Time Direction"
          />
        </>
      )}
    </>
  )
}

export default withStore(RuleLastInappEvent)
