import { put, destroy, post } from '~/api/v2'

export function editGroup(
  appId: string,
  groupId: string,
  name: string
): Promise<any> {
  return put(`${appId}/groups/${groupId}`, { name })
}

export function removeGroup(appId: string, groupId: string): Promise<any> {
  return destroy(`${appId}/groups/${groupId}`)
}

export function createGroup(
  appId: string,
  name: string,
  resourceType: string
): Promise<any> {
  return post(`${appId}/groups`, { name, resourceType })
}
