import { ReactElement, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import AllMobileDevicesPreviews from '~/pages/Campaign/Notification/Preview/AllMobileDevicesPreview'
import PreviewMobile from '~/pages/Campaign/Notification/Preview/PreviewMobile'
import { useStore, withStore } from '~/dataStore'
import {
  ActionTypes,
  CampaignType
} from '~/dataStore/Campaign/Campaign.interface'
import { CollapseContextProvider } from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import MobileCampaignPanel from '~/pages/CampaignBuilder/MobileCampaignPanel/MobileCampaignPanel'
import InAppLarge from '~/pages/Campaign/Notification/InAppNotification/UI/InAppLarge'
import InAppSmall from '~/pages/Campaign/Notification/InAppNotification/UI/InAppSmall'
import InAppLargeModel from '../Model/InAppLarge.model'
import InAppNotification from '../Model/InAppNotification'
import InAppSmallModel from '../Model/InAppSmall.model'
import InAppLargeTwoBtns from './InAppLargeTwoBtns'

interface IProps {
  inApp: InAppNotification
  isCardInAppCampaign?: boolean
  withGoals?: boolean
  isSended?: boolean
  colsWidth?: {
    left: { xxl: { size: number } }
    right: { xxl: { size: number } }
  }
}

function InappNotificationBuilder(props: IProps): ReactElement {
  const {
    app: { currentApp, currentAdmin, appDetails }
  } = useStore()

  const {
    inApp,
    withGoals,
    isCardInAppCampaign = false,
    colsWidth,
    isSended = false
  } = props

  const inappPreview = inApp.generatePreview().inApp

  useEffect(() => {
    inApp.shared
      .getAdmins(currentApp.id)
      .then((response) => {
        inApp.setAdmin(
          response.find((admin) => admin.id === currentAdmin.id) || response[0]
        )
      })
      .catch((error) => console.error(error))

    inApp.shared
      .fetchMergeTags(currentApp.id)
      .catch((error) => console.error(error))

    inApp.shared
      .fetchDeeplinks(currentApp.id)
      .catch((error) => console.error(error))

    inApp.shared
      .fetchEvents(currentApp.id)
      .catch((error) => console.error(error))
  }, [currentApp.id, inApp, currentAdmin])

  const fieldsDictionary = {
    admin_header_with_message: 'adminHeader',
    image: 'image',
    headline: 'headline',
    text: 'text',
    button: 'actionButtons'
  }

  return (
    <CollapseContextProvider fieldMapper={fieldsDictionary}>
      <Row className="align-items-start">
        <Col
          widths={['md', 'xxl']}
          md={{ size: 7 }}
          {...colsWidth?.left}
          className="mb-4">
          {inApp.size === 'small' && (
            <AllMobileDevicesPreviews
              parts={inappPreview}
              campaignType={CampaignType.IN_APP}
            />
          )}
          {inApp.size === 'large' && (
            <PreviewMobile
              campaignType={CampaignType.IN_APP}
              preview={inappPreview}
              device="ios"
              deviceSize="lg"
            />
          )}
        </Col>
        <Col widths={['md', 'xxl']} md={{ size: 5 }} {...colsWidth?.right}>
          <MobileCampaignPanel>
            {inApp.size === 'large' && (
              <>
                {appDetails.featureFlags.notificationsExtraButtons ? (
                  <InAppLargeTwoBtns
                    isWithCardCampaign={isCardInAppCampaign}
                    notification={inApp.notification as InAppLargeModel}
                    shared={inApp.shared}
                    withGoals={withGoals}
                    notificationGoals={inApp.goals}
                    isSended={isSended}
                    ctaActions={[
                      ActionTypes.DEEPLINK,
                      ActionTypes.URL,
                      ActionTypes.OPEN_FEED,
                      ActionTypes.DISMISS
                    ]}
                  />
                ) : (
                  <InAppLarge
                    isWithCardCampaign={isCardInAppCampaign}
                    notification={inApp.notification as InAppLargeModel}
                    shared={inApp.shared}
                    withGoals={withGoals}
                    notificationGoals={inApp.goals}
                    isSended={isSended}
                    ctaActions={[
                      ActionTypes.DEEPLINK,
                      ActionTypes.URL,
                      ActionTypes.OPEN_FEED,
                      ActionTypes.DISMISS
                    ]}
                  />
                )}
              </>
            )}
            {inApp.size === 'small' && (
              <InAppSmall
                withPersonal={isCardInAppCampaign}
                shared={inApp.shared}
                disabled={isSended}
                notification={inApp.notification as InAppSmallModel}
              />
            )}
          </MobileCampaignPanel>
        </Col>
      </Row>
    </CollapseContextProvider>
  )
}

export default withStore(InappNotificationBuilder)
