import React from 'react'
import reject from 'lodash/reject'
import {
  faObjectUngroup,
  faObjectGroup,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { Button, Spinner } from 'reactstrap'
import CustomDropdown from '~/components/CustomDropdown'
import SearchInput from '~/components/forms/SearchInput'
import MultiSelect from '~/components/forms/MultiSelect'
import BatteryStatusDot from '../BatteryStatusDot'
import GroupsOption from '~/components/Table/components/GroupsOption'
import { BATTERY_STATUSES, ISelectedBeacons } from '../../Beacons.interface'
import { useStore, withStore } from '~/dataStore'
import { ID } from '~/common.interface'

interface IProps {
  selectedBeacons: ISelectedBeacons
  searchQuery: string
  handleSearch: (value: string) => void
  onDeleteRows: () => void
  isRefetching: boolean
  setSelectedGroup: (id: ID) => void
  queryParams: { batteryStatus: string; groupIds: string }
}

const BeaconsTableToolbar = ({
  selectedBeacons,
  queryParams,
  searchQuery,
  handleSearch,
  onDeleteRows,
  isRefetching,
  setSelectedGroup
}: IProps): React.ReactElement => {
  const {
    ui: { showModal },
    groups: { groups }
  } = useStore()
  const batchActions = [
    {
      name: 'Add to a Group',
      icon: faObjectGroup,
      action: () => showModal('addElementsToGroup')
    },
    {
      name: 'Remove from a Group',
      icon: faObjectUngroup,
      action: () => showModal('removeElementsFromGroup'),
      hidden:
        Array.from(selectedBeacons, ([key, value]) => value).flatMap(
          (c) => c.groups
        ).length === 0
    },
    {
      name: 'Delete Beacons',
      icon: faTrash,
      action: onDeleteRows
    }
  ]

  const batteryStatusOptions = BATTERY_STATUSES.map((status) => ({
    name: (
      <div className="d-flex justify-content-between w-100 fit">
        <span>{status}</span>{' '}
        <span className="ms-3">
          <BatteryStatusDot batteryStatus={status} slim />
        </span>
      </div>
    ),
    value: status.toLocaleLowerCase()
  }))

  const handleFilterGroups = (values: string[]) => {
    queryParams.groupIds = values.join(',')
  }

  const handleFilterBatteryStatus = (values: string[]) => {
    queryParams.batteryStatus = values.join(',')
  }

  const groupFilterOptions = groups.map((g) => ({
    name: (
      <GroupsOption
        {...g}
        setEditGroup={(id: string) => {
          setSelectedGroup(id)
          showModal('editGroupModal')
        }}
        setDeleteGroup={(id: string) => {
          setSelectedGroup(id)
          showModal('removeGroupModal')
        }}
      />
    ),
    value: g.id
  }))

  return (
    <div className="d-flex justify-content-between align-items-center p-3">
      <div className="d-flex">
        <CustomDropdown
          options={batchActions}
          selectedItems={selectedBeacons}
          visible={selectedBeacons.size > 0}
          className="me-3"
        />
        <SearchInput
          placeholder="Search for a beacon"
          initialValue={searchQuery}
          onChange={handleSearch}
          className="me-3 flex-0-1"
        />

        <MultiSelect
          title="Groups"
          selectedOptions={reject(
            queryParams.groupIds?.split(','),
            (s) => s === ''
          )}
          onSelect={handleFilterGroups}
          resetOptionName="Show All"
          options={groupFilterOptions}
          className="me-3"
          bottomChildren={
            <div className="d-flex">
              <Button
                data-testid="createGroupNameBtn"
                onClick={() => showModal('createGroupModal')}
                color="primary"
                className="mx-3 my-2 w-100 text-nowrap">
                + Create Group Name
              </Button>
            </div>
          }
        />

        <MultiSelect
          title="Battery Status"
          selectedOptions={reject(
            queryParams.batteryStatus?.split(','),
            (s) => s === ''
          )}
          onSelect={handleFilterBatteryStatus}
          resetOptionName="Show All"
          options={batteryStatusOptions}
          className="me-3"
        />
      </div>
      <div className="d-flex">
        {isRefetching && (
          <Spinner color="primary" type="grow" className="ms-auto" />
        )}
      </div>
    </div>
  )
}

export default withStore(BeaconsTableToolbar)
