import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import cn from 'classnames'
import React, { useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import DateAndTimeInput from '~/components/forms/DateAndTimeInput'
import TimeZonePicker from '~/components/forms/TimeZonePicker'
import ToggleSwitch from '~/components/forms/ToggleSwitch'
import { useStore, withStore } from '~/dataStore'
import { Start } from '../Blocks'
import BuilderErrors from '~/pages/CampaignBuilder/BottomActions/BuilderErrors'
import { CustomErrors } from '~/common.interface'

interface IProps {
  isLoading: boolean
  disabled: boolean
  launch: () => void
  save: () => Promise<unknown>
  startBlock: Start
}

function JourneyBottomBar({
  isLoading,
  disabled,
  launch,
  save,
  startBlock
}: IProps): React.ReactElement {
  const [errorsOpen, setErrorsOpen] = useState(false)

  const {
    ui: { hideModal },
    goTo,
    app: {
      currentApp: { id: appId }
    }
  } = useStore()

  const { mode } = useParams<{ mode: 'builder' | 'details' }>()

  function saveJourney(): Promise<unknown> {
    startBlock.resetError()
    startBlock.validateDates()
    if (!startBlock.isValid) {
      throw new Error(CustomErrors.INVALID)
    }

    return save()
  }

  async function onFinishLaterClick(): Promise<void> {
    try {
      startBlock.resetError()
      await save()
      goTo('journeys', { appId })
    } catch (error) {
      if ((error as Error).message !== CustomErrors.INVALID) {
        console.error(error)
      }

      setErrorsOpen(true)
    }
  }

  async function onLaunchJourney(): Promise<void> {
    try {
      await saveJourney()
      launch()
    } catch (error) {
      if ((error as Error).message !== CustomErrors.INVALID) {
        console.error(error)
      }

      setErrorsOpen(true)
    }
  }

  if (mode === 'details') {
    return (
      <div className="journey-builder__bar bg-water-gray d-flex justify-content-end">
        <div className="d-flex align-items-center flex-no-wrap">
          <Button
            color=""
            data-testid="journeyBuilderLaunchModalBtn"
            onClick={() => {
              hideModal('journeyBuilderLaunchModal')
            }}
            className="px-4">
            Close
          </Button>
          <Button
            color=""
            data-testid="returnToJourneysBtn"
            onClick={() => {
              goTo('journeys', { appId })
            }}
            className="bg-white px-4 btn--hover btn-outline-body">
            Return to Journeys
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="journey-builder__bar bg-water-gray d-flex justify-content-between">
      <div className="d-flex align-items-center flex-no-wrap">
        <DateAndTimeInput
          value={startBlock.startAt.value}
          onChange={startBlock.setStartAt}
          label="Start"
          className="d-flex"
          labelClassName="me-3"
          invalid={!startBlock.startAt.isValid}
          popperPlacement="top-start"
          minDate={moment().toDate()}
          popperProps={{
            positionFixed: true // use this to make the popper position: fixed
          }}
        />
        <span
          className={cn('ms-4 o-50 bold', {
            'error-label o-100': !startBlock.endAt.isValid
          })}>
          End
        </span>
        {startBlock.endAtActive && (
          <DateAndTimeInput
            value={startBlock.endAt.value}
            onChange={startBlock.setEndAt}
            minDate={moment(startBlock.startAt.value)
              .add(startBlock.getDelayInMinutes() + 180, 'm')
              .toDate()}
            className="d-flex"
            labelClassName="me-3"
            invalid={!startBlock.endAt.isValid}
            popperProps={{
              positionFixed: true // use this to make the popper position: fixed
            }}
          />
        )}
        <ToggleSwitch
          className="mx-3"
          checked={startBlock.endAtActive}
          onChange={startBlock.setEndActive}
        />
        <TimeZonePicker
          disabled={false}
          invalid={!startBlock.timeZoneName.isValid}
          value={startBlock.timeZoneName.value}
          onChange={startBlock.setTimezone}
          direction="up"
        />
      </div>
      <div className="d-flex align-items-center flex-no-wrap">
        <Button
          color=""
          data-testid="journeyLaunchModalBtn"
          onClick={() => {
            startBlock.resetError()
            hideModal('journeyBuilderLaunchModal')
          }}
          className="px-4">
          Cancel
        </Button>
        <Button
          color=""
          onClick={onFinishLaterClick}
          data-testid="journeyFinishLaterBtn"
          className="bg-white px-4 btn--hover btn-outline-body">
          Finish Later
        </Button>
        <Button
          color="body"
          id="launch-journey-btn"
          data-testid="launchJourneyBtn"
          disabled={disabled}
          onClick={onLaunchJourney}
          className="ms-2 btn--hover d-flex align-items-center">
          Launch Journey
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-2" />
          ) : (
            <span className="icon icon--rocket ms-2" />
          )}
        </Button>

        {!startBlock.isValid && (
          <BuilderErrors
            key={`errors-${startBlock.errors.length}-${startBlock.launchErrors.length}`}
            inModal
            targetId="launch-journey-btn"
            messages={
              startBlock.errors.length > 0
                ? startBlock.errors
                : startBlock.launchErrors
            }
            close={() => setErrorsOpen(false)}
            isOpen={errorsOpen}
          />
        )}
      </div>
    </div>
  )
}

export default withStore(JourneyBottomBar)
