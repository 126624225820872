import { useState } from 'react'

const useDropdownNavTabs = (tabName: string | number) => {
  const [activeTab, setActiveTab] = useState(tabName)

  const getTabClassName = (tab: string | number): string => {
    const className = ['dropdown-nav-tabs__tab py-3']

    if (activeTab === tab) {
      className.push('dropdown-nav-tabs__tab--active')
    }

    return className.join(' ')
  }

  const toggle = (tab: string | number): void => {
    setActiveTab(tab)
  }

  return [activeTab, toggle, getTabClassName] as const
}

export default useDropdownNavTabs
