/* eslint-disable max-classes-per-file */
import { action, computed, observable, makeObservable } from 'mobx'
import NewRegisteredField from '../RegisteredField.model'

export type DNDProps = {
  position: number
  active: boolean
  value?: string
  errorMessage: string
}
export default class ImageField {
  field = new NewRegisteredField<string | undefined | null>('')

  fileName = ''

  file: File | null = null

  position: number

  isActive = true

  errorMessage: string

  constructor({
    position,
    active,
    value,
    fileName,
    errorMessage
  }: DNDProps & { fileName: string }) {
    makeObservable(this, {
      field: observable,
      fileName: observable,
      file: observable,
      position: observable,
      isActive: observable,
      fillField: action.bound,
      setActive: action.bound,
      setPosition: action.bound,
      setValue: action.bound,
      value: computed,
      isValid: computed,
      validate: action.bound,
      setFile: action.bound,
      setName: action.bound,
      name: computed
    })

    this.position = position
    this.field.value = value
    this.isActive = active
    this.errorMessage = errorMessage

    this.fileName = fileName

    this.addInternalFile = this.addInternalFile.bind(this)
  }

  public fillField({
    value,
    fileName,
    isActive,
    position
  }: {
    value: string | null
    fileName: string
    isActive?: boolean
    position?: number
  }): void {
    this.field.fillField({ value })
    this.setActive(typeof isActive !== 'undefined' ? !!isActive : this.isActive)
    this.setPosition(position ?? this.position)

    this.fileName = fileName || ''

    this.field.beenValid = true
  }

  public setActive(value: boolean): void {
    this.isActive = value
  }

  public setPosition(value: number): void {
    this.position = value
  }

  public setValue(value: string): void {
    this.field.value = value
  }

  public get value(): string | null | undefined {
    return this.field.value
  }

  public get isValid(): boolean {
    if (!this.isActive) return true

    return this.field.isValid
  }

  public validate(): boolean {
    this.field.resetError()
    if (!this.isActive) return true

    return this.field.validate(this.errorMessage)
  }

  public setFile(file: File | null): void {
    this.file = file
  }

  public setName(value: string): void {
    this.fileName = value
  }

  public get name(): string {
    return this.fileName
  }

  public addInternalFile(
    objectURL: string,
    fileName: string,
    file: File | null
  ): void {
    this.setFile(file)
    this.setValue(objectURL)
    this.setName(fileName)
    this.validate()
  }
}
