import {
  DeeplinkFormValues,
  IDeeplink
} from '~/pages/App/ChannelSettings/sections/Deeplink/Deeplink.interface'
import {
  ButtonCategory,
  Deeplink,
  IAppDetails
} from '~/dataStore/App.interface'
import { getRequest } from '~/api/requests'
import { get, upload, put, post, destroy } from './v2'
import { ID, IMetaData } from '~/common.interface'

export function fetchMergeTags(appId: string): Promise<any> {
  return getRequest(`/mobile/apps/${appId}/merge_tags`)
}

export function fetchAppDetails(appId: string): Promise<IAppDetails> {
  return getRequest(`/mobile/apps/${appId}`)
}

export function fetchDeeplinks(
  appId: string
): Promise<{
  data: Deeplink[]
  metadata: IMetaData
}> {
  return get(`${appId}/deeplinks`)
}

export function removeDeeplink(appId: string, deeplinkID: ID): Promise<void> {
  return destroy(`${appId}/deeplinks/${deeplinkID}`)
}

export function editDeeplink(
  appId: ID,
  data: DeeplinkFormValues,
  deeplinkId: ID
): Promise<DeeplinkFormValues> {
  return put(`${appId}/deeplinks/${deeplinkId}`, data)
}

export function addDeeplink(
  appId: ID,
  data: DeeplinkFormValues
): Promise<IDeeplink> {
  return post(`${appId}/deeplinks`, data)
}

export function fetchButtons(): Promise<ButtonCategory[]> {
  return getRequest('/api/v2/global_settings', { key: 'button_labels' })
}

export function uploadFile(
  appId: string,
  file: File
): Promise<{ url: string }> {
  const data = new FormData()
  data.append('file', file, file.name)
  return upload(`${appId}/attachments/upload`, data)
}

export function uploadImage(
  appId: string,
  formData: FormData
): Promise<{ url: string }> {
  return upload(`${appId}/general_media/upload`, formData)
}

export function fetchEvents(
  appId: string,
  searchQuery = ''
): Promise<{ data: string[] }> {
  return get(`${appId}/events`, { searchQuery })
}
