import { Col, Row } from 'reactstrap'
import OpportunitiesDashboardGraphCard from '~/pages/OpportunitiesDashboard/EngagementSummary/components/OpportunitiesDashboardGraphCard'
import { IEngagementSummary } from '~/pages/OpportunitiesDashboard/OpportunitiesDashboard.interface'
import { isNumber } from '~/utils/utilities'

const AreaCharts = ({
  summaryData,
  currentRange
}: {
  summaryData: IEngagementSummary | undefined
  currentRange: number | undefined
}) => {
  return (
    <Row className="area-charts-row">
      <Col xs={4}>
        <OpportunitiesDashboardGraphCard
          graphTitle="Engagement Score"
          value={`${
            isNumber(summaryData?.performance?.engagementScore?.rate)
              ? `${summaryData?.performance?.engagementScore?.rate}%`
              : summaryData?.performance?.engagementScore?.rate
          }`}
          graphData={summaryData?.performance?.engagementScore}
          tooltipData={{
            header: 'Engagement Score',
            text: `Ratio of users who have had an impression for a notification within the last ${currentRange} days, divided by the total number of active users`
          }}
        />
      </Col>
      <Col xs={4}>
        <OpportunitiesDashboardGraphCard
          value={summaryData?.activeUsers?.total.count.toLocaleString('en-US')}
          graphTitle="Total Active Users"
          graphData={summaryData?.activeUsers?.total}
          tooltipData={{
            header: 'Total Active Users',
            text: `Total number of users who have logged into banking on mobile or web in the last ${currentRange} days`
          }}
        />
      </Col>
      <Col xs={4}>
        <OpportunitiesDashboardGraphCard
          graphTitle="New Active Users"
          value={summaryData?.activeUsers?.new.count.toLocaleString('en-US')}
          graphData={summaryData?.activeUsers?.new}
          tooltipData={{
            header: 'New Active Users',
            text: `Total number of new users who never used banking on mobile or web in ${currentRange} days`
          }}
        />
      </Col>
      <Col xs={4}>
        <OpportunitiesDashboardGraphCard
          graphTitle="Total Impressions"
          value={summaryData?.performance?.impressions?.count.toLocaleString(
            'en-US'
          )}
          graphData={summaryData?.performance.impressions}
          chartThemeGreen
          tooltipData={{
            header: 'Total Impressions',
            text: `Total number of unique impressions on any notification sent in the last ${currentRange} days`
          }}
        />
      </Col>
      <Col xs={4}>
        <OpportunitiesDashboardGraphCard
          graphTitle="Total Clicks"
          value={summaryData?.performance.clicks.count.toLocaleString('en-US')}
          graphData={summaryData?.performance.clicks}
          tooltipData={{
            header: 'Total Clicks',
            text: `Total number of unique clicks on any notification button sent in the last ${currentRange} days`
          }}
          chartThemeGreen
        />
      </Col>
      <Col xs={4}>
        <OpportunitiesDashboardGraphCard
          graphTitle="CTR"
          value={`${
            isNumber(summaryData?.performance.ctr?.rate)
              ? `${summaryData?.performance.ctr?.rate}%`
              : summaryData?.performance.ctr?.rate
          }`}
          graphData={summaryData?.performance.ctr}
          tooltipData={{
            header: 'CTR',
            text: `The average percentage of clicks on any notification in the last ${currentRange} days`
          }}
          chartThemeGreen
        />
      </Col>
    </Row>
  )
}

export default AreaCharts
