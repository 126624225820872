import React from 'react'
import Modal from '~/components/modals/Modal/Modal'
import JourneyReview, { IProps } from './JourneyReview'

const LaunchModal = ({
  closeCallback,
  ...rest
}: IProps & { closeCallback: () => void }): React.ReactElement => {
  return (
    <Modal
      size="xl"
      closeCallback={closeCallback}
      id="journeyBuilderLaunchModal"
      render={() => <JourneyReview {...rest} />}
    />
  )
}

export default LaunchModal
