import React, { useRef } from 'react'
import { Card, UncontrolledTooltip } from 'reactstrap'
import cn from 'classnames'
import { uniqueId } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import ApexAreaChart from '~/components/ApexCharts/ApexAreaChart'
import { isNumber } from '~/utils/utilities'
import styles from './OpportunitiesDashboardGraphCard.scss'

interface IProps {
  graphTitle: string
  graphData:
    | {
        count?: number | string | undefined
        rate?: number | string | undefined
        diff: number
        graph: {
          x: string
          y: number
        }[]
      }
    | undefined
  tooltipData: {
    header: string
    text: string
  }
  chartThemeGreen?: boolean
  value: string | number | undefined
}

const OpportunitiesDashboardGraphCard = ({
  graphTitle,
  graphData,
  tooltipData,
  chartThemeGreen,
  value
}: IProps) => {
  const id = useRef(uniqueId('tooltip'))
  return (
    <Card className={styles.graphCard}>
      <p className={cn(styles.graphTitle)} id={id.current}>
        {graphTitle || <Skeleton />}
      </p>
      <UncontrolledTooltip target={id.current} popperClassName="tooltip-modern">
        <strong>{tooltipData.header}</strong> <br />
        <span>{tooltipData.text}</span>
      </UncontrolledTooltip>
      {graphData ? (
        <p className="mt-4 pt-2 mb-3 h1 d-flex align-items-center">
          {value}
          {isNumber(graphData?.diff) &&
            value !== '0%' &&
            graphData.diff !== 0 && (
              <>
                <div
                  className={cn(styles.graphDiff, {
                    [styles.graphDiffPositive]: graphData?.diff > 0,
                    [styles.graphDiffNegative]: graphData?.diff < 0
                  })}>
                  <span className={styles.graphDiffIcon} />
                  <span className={styles.graphDiffValue}>
                    {graphData?.diff}%
                  </span>
                </div>
              </>
            )}
        </p>
      ) : (
        <Skeleton width={100} height={32} />
      )}

      <div className="mt-1">
        {graphData ? (
          <ApexAreaChart
            height={50}
            graphData={graphData?.graph}
            themeGreen={chartThemeGreen}
          />
        ) : (
          <Skeleton height={102} />
        )}
      </div>
    </Card>
  )
}

export default OpportunitiesDashboardGraphCard
