import React from 'react'
import { Label, FormGroup } from 'reactstrap'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import SelectWithSearch from '~/components/forms/SelectWithSearch'
import styles from './CurrencySelect.scss'
import { useGetCurrencies } from '../useGetCurrencies'

interface IProps {
  value?: string
  onChange: (value?: string) => void
  error?: string
}

const CurrencySelect = ({ value, onChange, error }: IProps) => {
  const { appId } = useParams<{ appId: string }>()
  const { data: currencies } = useGetCurrencies(appId)

  return (
    <div>
      <Label for="currency-select" className="fw-medium">
        Currency
      </Label>
      <SelectWithSearch
        placeholder="Select currency"
        selectPlaceholder="Search for currency"
        options={currencies?.groupedCurrencies || []}
        className={cn(styles.currencySelect)}
        value={value}
        grouped
        invalid={!!error}
        errorTooltip={error}
        id="currency-select"
        onChange={(option) => {
          onChange(option.value)
        }}
      />
    </div>
  )
}

export default CurrencySelect
