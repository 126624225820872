import React from 'react'

const IconDelay = ({
  className,
  fill
}: {
  className?: string
  fill: string
}): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 26 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.36789 10.6308L11.9585 12.6291C12.0584 13.4345 12.7373 14.0606 13.5698 14.0606C14.4714 14.0606 15.2019 13.3297 15.2019 12.4285C15.2019 12.394 15.194 12.3611 15.1915 12.327L18.9878 7.83593C19.2524 7.53886 19.2574 7.11077 18.9986 6.88114C18.7403 6.65105 18.3164 6.70595 18.0517 7.00342L14.322 10.9882C14.0957 10.8696 13.8427 10.7964 13.5698 10.7964C13.2782 10.7964 13.0081 10.8792 12.771 11.0136L10.3322 9.31534C10.0176 9.08362 9.54588 9.19092 9.27918 9.55462C9.01344 9.9173 9.05254 10.3995 9.36789 10.6308Z"
        fill={fill}
      />
      <path
        d="M22.3596 3.6403C20.1151 1.39288 16.9982 -0.00086288 13.5704 4.00803e-07V3.0129C16.1757 3.01376 18.52 4.06468 20.2291 5.77083C21.9348 7.47946 22.9857 9.82423 22.9866 12.4291C22.9857 15.0338 21.9348 17.3786 20.2291 19.0873C18.52 20.7938 16.1757 21.8448 13.5704 21.8452C10.9657 21.8448 8.6209 20.7938 6.91221 19.0873C5.20606 17.3786 4.15515 15.0338 4.15434 12.4291C4.15474 11.5749 4.27123 10.7524 4.48258 9.96901L5.70367 10.7296C5.86551 10.8299 6.06229 10.8511 6.22372 10.7849C6.38637 10.7179 6.49286 10.5736 6.50535 10.4005L7.03124 3.51386C7.04536 3.31662 6.9372 3.11776 6.75337 3.00213C6.56781 2.88818 6.34107 2.87772 6.17091 2.97883L0.221992 6.48638C0.0718319 6.57418 -0.010586 6.73308 0.00109367 6.90782C0.0127226 7.08342 0.117992 7.25023 0.280238 7.35088L1.83912 8.32192C1.38859 9.60958 1.14103 10.9925 1.14103 12.4291C1.14022 15.8563 2.53477 18.9733 4.78138 21.2182C7.02627 23.4648 10.1428 24.8594 13.5705 24.8585C16.9978 24.8594 20.1152 23.4648 22.3597 21.2182C24.6063 18.9733 26.0009 15.8563 26 12.4291C26.0008 9.00137 24.6062 5.88442 22.3596 3.6403Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconDelay
