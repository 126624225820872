import { IImage } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { IMetaData } from '~/common.interface'

import { destroy, get, post } from './v2'

export function uploadEmailImage(
  appId: string,
  data: { name: string; image: string }
): Promise<void> {
  return post(`${appId}/email_images`, data)
}

export function getImages(
  appId: string,
  params: any
): Promise<{ data: IImage[]; metadata: IMetaData }> {
  return get(`${appId}/email_images`, params)
}

export function removeImage(appId: string, id: string): Promise<void> {
  return destroy(`${appId}/email_images/${id}`)
}
