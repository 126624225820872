import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Input from '~/components/forms/Input'
import { tagHotjarRecording } from '~/utils/utilities'
import Select from '~/components/forms/Select'
import ERRORS_DICT, {
  AdminFormFieldValues,
  IAdmin,
  ADMINS_LIST_QUERY
} from '~/pages/AccountSettings/AccountSettings.interface'
import { ADMIN_ROLES } from '~/common.interface'
import { IApplication } from '~/pages/App/Manager/AppManager.interface'
import { get } from '~/api/v2'
import { useStore, withStore } from '~/dataStore'
import { addAdmin, updateAdminPrivileges } from '~/api/admins'
import { NotificationType, showNotification } from '~/utils/Notification'
import {
  getFormServerSideErrors,
  hookFormValidation,
  showGeneralError
} from '~/utils/validations'

import styles from './EditAddAdmin.scss'

const EditAddAdmin = ({ admin }: { admin?: IAdmin }) => {
  const {
    app: {
      currentApp,
      appDetails: { featureFlags }
    },
    ui: { hideModal }
  } = useStore()
  const queryClient = useQueryClient()
  const [applications, setApplications] = useState([])
  const {
    register,
    control,
    setError,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<AdminFormFieldValues>({
    mode: 'onBlur',
    defaultValues: { email: admin?.email, allowedActions: admin?.access }
  })

  const fetchApps = async () => {
    try {
      const response = await get('')
      setApplications(response.data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const handleErrors = (errors: Record<AdminFormFieldValues, string[]>) => {
    getFormServerSideErrors(errors, ERRORS_DICT).forEach((er) =>
      setError(er.name, { type: 'server', message: er.message })
    )
  }

  useEffect(() => {
    fetchApps()
  }, [])

  const updateAdmin = async (data: AdminFormFieldValues) => {
    tagHotjarRecording(['adminPrivilegesUpdated'])
    try {
      await updateAdminPrivileges(currentApp.id, admin?.id, data)
      queryClient.invalidateQueries([ADMINS_LIST_QUERY])
      hideModal('editAddAdminModal')
    } catch (error) {
      if (error.body?.errors) {
        handleErrors(error.body.errors)
      } else {
        showGeneralError()
      }
    }
  }

  const saveAdmin = async (data: AdminFormFieldValues) => {
    try {
      await addAdmin(currentApp.id, data)
      queryClient.invalidateQueries([ADMINS_LIST_QUERY])
      hideModal('editAddAdminModal')
      showNotification(
        'New Admin successfully invited',
        NotificationType.SUCCESS
      )
    } catch (error) {
      if (error.body?.errors) {
        handleErrors(error.body.errors)
      } else {
        showGeneralError()
      }
    }
  }

  const handleFormSubmit = async (data: AdminFormFieldValues) => {
    const payload = {
      ...data,
      allowedActions: 'all'
    }
    if (admin) {
      await updateAdmin(payload)
    } else {
      await saveAdmin(payload)
    }
  }

  return (
    <form
      className="w-100 account-settings-personal"
      onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="d-flex align-items-end">
        <Input
          register={!admin ? register : null}
          value={admin ? admin?.email : undefined}
          type="email"
          disabled={!!admin}
          placeholder="Enter Email Address"
          label="Email address"
          className={cn('mb-0', styles.emailAddressInput, {
            'form-group--error': errors.email
          })}
          validation={hookFormValidation.email}
          errorTooltip={errors?.email?.message}
          tooltip=""
          name="email"
        />
        <div className="ms-4">
          <Controller
            control={control}
            defaultValue={admin?.role}
            name="role"
            render={({ field: { onChange, value } }) => (
              <div>
                <label
                  htmlFor="account-type-select"
                  className="fw-medium d-block form-label">
                  Account Type
                </label>
                <Select
                  id="account-type-select"
                  onSelect={onChange}
                  selectedOption={value}
                  title="Please Select Account Type"
                  invalid={!!errors?.role}
                  errorTooltip={errors?.role?.message}
                  options={[
                    { name: 'App Admin', value: ADMIN_ROLES.APP_ADMIN },
                    { name: 'Account Admin', value: ADMIN_ROLES.ACCOUNT_ADMIN },
                    { name: 'Master Admin', value: ADMIN_ROLES.MASTER_ADMIN },
                    ...(featureFlags.demoMode
                      ? [
                          {
                            name: 'Demo Admin',
                            value: ADMIN_ROLES.DEMO_ADMIN
                          }
                        ]
                      : [])
                  ]}
                />
              </div>
            )}
          />
        </div>
        {[ADMIN_ROLES.APP_ADMIN, ADMIN_ROLES.DEMO_ADMIN].includes(
          watch('role')
        ) && (
          <>
            <div className="ms-4">
              <Controller
                control={control}
                defaultValue={admin?.managedApp?.id}
                name="managedAppId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <label
                        htmlFor="application-select"
                        className="fw-medium d-block form-label">
                        Application
                      </label>
                      <Select
                        id="application-select"
                        invalid={!!errors?.managedAppId}
                        errorTooltip={errors?.managedAppId?.message}
                        onSelect={onChange}
                        selectedOption={value}
                        title="Please Select App"
                        wrapperClassName="w-100"
                        className="justify-content-between"
                        options={applications.map((app: IApplication) => ({
                          name: app.name,
                          value: app.id
                        }))}
                      />
                    </div>
                  )
                }}
              />
            </div>
          </>
        )}
        <div className="mt-4 pt-1">
          <Button
            color="primary"
            disabled={isSubmitting}
            type="submit"
            className="ms-4 d-block">
            {admin ? 'Save changes' : 'Invite New Admin'}
            {isSubmitting && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="xs"
                className="ms-2"
              />
            )}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default withStore(EditAddAdmin)
