import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Label, ModalBody, ModalFooter } from 'reactstrap'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useParams } from 'react-router-dom'
import CreatableTextInput from '~/components/forms/CreatableTextInput'
import Checkbox from '~/components/forms/Checkbox'
import SuccessfulSendModal from './SuccessfulSendModal'
import { fetchDesignHTML } from '~/api/unlayer'
import { sendTestEmail } from '~/api/campaigns'
import { useStore, withStore } from '~/dataStore'
import { NotificationType, showNotification } from '~/utils/Notification'
import { isValidEmail } from '~/utils/validations'
import { TestEmailData } from '../../UnlayerEditor.interface'
import { mergeTags } from '~/constants/email/mergeTags'
import Modal from '~/components/modals/Modal'

import './SendTestEmailModal.scss'

const NO_EMAILS_ERROR =
  'Please enter at least one email address or check "Send to yourself" option'
const WRONG_FORMAT_ERROR =
  'One of email addresses you provided contains wrong format.'

interface IProps {
  closeSendTestEmailModal: () => void
  testEmailData: TestEmailData
}

const SendTestEmailModal = ({
  closeSendTestEmailModal,
  testEmailData
}: IProps) => {
  const {
    app: { currentAdmin, unlayerAccessKey },
    ui: { showModal }
  } = useStore()

  const { appId } = useParams()

  const [emailsList, setEmailsList] = useState([])
  const [exportedHTML, setExportedHTML] = useState()
  const [shouldSendToSelf, setShouldSendToSelf] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [emailsListError, setEmailsListError] = useState('')

  useEffect(() => {
    const getHTMLToSend = async () => {
      const html = await fetchDesignHTML(
        {
          displayMode: 'email',
          mergeTags,
          design: testEmailData.designData
        },
        unlayerAccessKey
      )
      const exportedHtml = await html.json()
      setExportedHTML(exportedHtml.data.html)
    }
    getHTMLToSend()
  }, [])

  useEffect(() => {
    let hasError = false
    emailsList.forEach((email) => {
      if (!isValidEmail(email.label)) hasError = true
    })
    setEmailsListError(hasError ? WRONG_FORMAT_ERROR : '')
  }, [emailsList])

  const showSuccessModal = (emailAddresses: Array<string>) =>
    showModal('successfulSendModal', { emailAddresses })

  const emailAddressesToSend = () => {
    const addresses = emailsList.map(
      (address: { value: string; label: string }) => address.label
    )

    if (shouldSendToSelf) {
      addresses.push(currentAdmin.email)
    }

    return addresses
  }

  const handleErrors = (
    error: Error & {
      body: { errors: { html: [string]; emailAddresses: [string] } }
    }
  ) => {
    if (!error.body?.errors) {
      showNotification(
        'Something went wrong, please try again later.',
        NotificationType.ERROR
      )
      return
    }
    if (error.body.errors) {
      if ('emailAddresses' in error.body.errors) {
        setEmailsListError(NO_EMAILS_ERROR)
      }
      if ('html' in error.body.errors) {
        showNotification(
          'Failed to generate test email, please try again later.',
          NotificationType.ERROR
        )
      }
    }
  }

  const sendEmail = async () => {
    try {
      setIsSending(true)
      const sendedEmails = await sendTestEmail(appId, {
        html: exportedHTML,
        email_addresses: emailAddressesToSend(),
        mergeTags,
        subject: testEmailData.subject,
        fromName: testEmailData.fromName
      })
      setIsSending(false)
      showSuccessModal(sendedEmails['sendTo:'])
    } catch (error) {
      handleErrors(error)
    } finally {
      setIsSending(false)
    }
  }

  const onSubmit = () => {
    if (!emailAddressesToSend().length && !shouldSendToSelf) {
      setEmailsListError(NO_EMAILS_ERROR)
      return
    }

    if (emailsListError && !shouldSendToSelf) return

    sendEmail()
  }

  return (
    <>
      <ModalBody className="send-test-modal__body pt-0">
        <div className="tab mb-0 mt-4">
          <FormGroup className={emailsListError ? 'form-group--error' : ''}>
            <Label>Who are we sending this email to?</Label>
            <CreatableTextInput
              onChange={setEmailsList}
              value={emailsList}
              placeholder="Press enter or tab after each e-mail address"
            />
            <span className="error-message mt-2">{emailsListError}</span>
          </FormGroup>
          <Checkbox
            onChange={setShouldSendToSelf}
            label={`Send to yourself (${currentAdmin?.email})`}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color=""
          className="btn--hover"
          onClick={closeSendTestEmailModal}>
          Cancel
        </Button>
        <Button
          disabled={isSending}
          onClick={onSubmit}
          color="primary"
          className="btn--hover px-4">
          {isSending && (
            <FontAwesomeIcon className="me-1" icon={faSpinner} spin size="xs" />
          )}
          <span>{isSending ? 'Sending...' : 'Send'}</span>
        </Button>
      </ModalFooter>

      <Modal
        id="successfulSendModal"
        size="md"
        render={(closeSuccessSendModal: () => void, params) => (
          <SuccessfulSendModal
            emailAddresses={params?.emailAddresses}
            adminEmailAddress={currentAdmin?.email}
            closeModal={() => {
              closeSendTestEmailModal()
              closeSuccessSendModal()
            }}
          />
        )}
      />
    </>
  )
}

export default withStore(SendTestEmailModal)
