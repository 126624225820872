import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'
import { ID } from '~/common.interface'

const HiddenFromFeedIndicator = ({
  campaignId
}: {
  campaignId: ID
}): React.ReactElement => {
  return (
    <>
      <FontAwesomeIcon
        id={`hidden-from-feed-${campaignId}`}
        icon={faEyeSlash}
        className="me-2 text-muted"
      />
      <UncontrolledTooltip
        placement="top"
        target={`hidden-from-feed-${campaignId}`}>
        Post is hidden from Feed
      </UncontrolledTooltip>
    </>
  )
}

export default HiddenFromFeedIndicator
