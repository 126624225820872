import { ReactElement } from 'react'
import cn from 'classnames'

interface IProps {
  logo?: string
  name: string
  className?: string
}

export default function DeviceLogo({
  logo,
  name,
  className
}: IProps): ReactElement {
  return (
    <div
      className={cn(
        'd-flex justify-content-center align-items-center device-logo',
        className
      )}>
      {logo && <img src={logo} alt={name} className="me-2" />}
      <span className="device-logo__name">{name}</span>
    </div>
  )
}
