import React from 'react'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import Time from './Time'
import CampaignDeliveryLimits from './CampaignDeliveryLimits'
import paperPlane from '~/components/icons/paperPlane.svg'
import finishLine from '~/components/icons/finishLine.svg'
import iconCalendar from '~/components/icons/icon-calendar.svg'
import { withStore } from '~/dataStore'
import {
  ITimeWindow,
  DATE_FORMATS,
  ExpiryType
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import './Deliver.scss'

interface IProps {
  type: 'Campaign' | 'Journey'
  deliver: {
    timeZoneName: string
    startAt?: Date | null | string
    endAt?: Date | null | string
    startNow?: boolean
    timeWindows?: ITimeWindow[]
    deliverUi: {
      isDeliveryFutureOrDefault: boolean
      isTimeWindows: boolean
      isEndDate: boolean
      isStartEndSectionCollapsed: boolean
    }
    expiryType: ExpiryType
    expiryDate?: Date | null
    expiryTimeValue?: string | number
    expiryTimeFrame?: string
    limits?: string | boolean
    timeFrame?: string
    timeValue?: string
  }
  isDetailsInPast: boolean
  status?: JourneyStatus
}

function Deliver({
  type = 'Campaign',
  isDetailsInPast,
  status,
  deliver: {
    timeZoneName,
    startAt,
    endAt,
    startNow,
    timeWindows,
    deliverUi,
    expiryType,
    expiryDate,
    expiryTimeValue,
    expiryTimeFrame,
    limits,
    timeFrame,
    timeValue
  }
}: IProps) {
  const getTimeWindow = (timeWindow: ITimeWindow) =>
    `${timeWindow.weekDay} ${moment(timeWindow.startAt).format(
      DATE_FORMATS.HOUR_MINUTE_MERIDIAN
    )} - ${moment(timeWindow.endAt).format(DATE_FORMATS.HOUR_MINUTE_MERIDIAN)}`

  function getExpiry() {
    if (expiryType === ExpiryType.NEVER) return ''
    let expiryText = ''
    if (expiryType === ExpiryType.DATE && expiryDate) {
      expiryText = moment(expiryDate).format('hh:mma, Do MMMM YYYY')
    } else if (
      expiryType === ExpiryType.AMOUNT &&
      expiryTimeFrame &&
      expiryTimeValue
    ) {
      expiryText = `${expiryTimeValue} ${pluralize(
        expiryTimeFrame,
        expiryTimeValue
      )}`
    }

    return `, and will expire after ${expiryText}`
  }

  function getTimeText() {
    if (isDetailsInPast) {
      return type === 'Journey' ? status : 'Sent'
    }
    if (startNow) {
      return 'Sending now'
    }

    if (!startAt) {
      return '- -'
    }

    return `${moment(startAt).format(
      `${DATE_FORMATS.HOUR_MINUTE_MERIDIAN}, ${DATE_FORMATS.MONTH_DAY}`
    )} ${getExpiry()}`
  }

  function getDescription() {
    if (isDetailsInPast) {
      if (type === 'Journey') {
        return `This Journey was launched on ${moment(startAt).format(
          `${DATE_FORMATS.HOUR_MINUTE_MERIDIAN}, ${DATE_FORMATS.MONTH_DAY}`
        )} ${getExpiry()}`
      }

      return `${type} sent on ${moment(startAt).format(
        `${DATE_FORMATS.HOUR_MINUTE_MERIDIAN}, ${DATE_FORMATS.MONTH_DAY}`
      )} ${getExpiry()}`
    }
    if (startNow) {
      return `This ${type} will be sent now${getExpiry()}`
    }

    return `Scheduled time and date for this ${type} ${getExpiry()}`
  }

  return (
    <div className="deliver">
      {deliverUi.isDeliveryFutureOrDefault && (
        <CampaignDeliveryLimits
          limits={limits}
          timeFrame={timeFrame}
          timeValue={timeValue}
        />
      )}
      <div className="deliver__times section-card section-card--times flex-column align-items-center">
        <Time
          className="py-3"
          icon={paperPlane}
          time={getTimeText()}
          description={getDescription()}
        />
        {deliverUi.isTimeWindows && timeWindows && (
          <Time
            className="py-3"
            icon={iconCalendar}
            time={timeWindows.map(getTimeWindow)}
            description="Deliver on certain days"
          />
        )}
        {deliverUi.isEndDate && (
          <Time
            className="py-3"
            icon={finishLine}
            time={moment(endAt).format(
              `${DATE_FORMATS.HOUR_MINUTE_MERIDIAN}, ${DATE_FORMATS.MONTH_DAY}`
            )}
            description={`${type} end date`}
          />
        )}
      </div>
      {(deliverUi.isDeliveryFutureOrDefault ||
        deliverUi.isStartEndSectionCollapsed) && (
        <div className="deliver__footer">
          <p className="mb-0">
            <span className="muted">Timezone:</span>&nbsp; {timeZoneName}
          </p>
        </div>
      )}
    </div>
  )
}

export default withStore(Deliver)
