import React from 'react'
import TargetingPreview from '~/pages/Campaign/UI/TargetingPreview'
import Beacons from '~/dataStore/emailBuilder/Targeting/Beacons'
import Events from '~/dataStore/emailBuilder/Targeting/Events'
import Geofences from '~/dataStore/emailBuilder/Targeting/Geofences'
import Segments from '~/dataStore/emailBuilder/Targeting/Segments'

interface IProps {
  targeting: {
    segments: Segments
    beacons: Beacons
    geofences: Geofences
    events?: Events
  }
  mode: 'builder' | 'details'
  type?: 'journey' | 'campaign'
}

function Target({ targeting, mode, type }: IProps): React.ReactElement {
  return (
    <div className="mt-4 d-flex justify-content-center">
      <TargetingPreview
        mode={mode}
        segments={targeting.segments}
        beacons={targeting.beacons}
        geofences={targeting.geofences}
        events={targeting.events}
        type={type}
      />
    </div>
  )
}

export default Target
