import React, { useRef } from 'react'
import cn from 'classnames'
import { Input, UncontrolledTooltip } from 'reactstrap'
import { uniqueId } from 'lodash'

import style from './Goals.scss'

interface IProps {
  checked?: boolean
  label: string | React.ReactNode
  onChange: (checked: boolean) => void
  invalid: boolean
  disabled: boolean
  readonly?: boolean
  type: 'primary' | 'secondary'
  listView?: boolean
}

export default function GoalCheckbox({
  checked,
  onChange,
  label,
  invalid,
  disabled,
  type,
  listView = false,
  readonly = false,
  ...otherProps
}: IProps): React.ReactElement {
  const uniqueID = useRef(uniqueId('goal'))
  return (
    <>
      <Input
        id={uniqueID.current}
        type="checkbox"
        checked={checked}
        className={cn(style.goalCheckbox)}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.checked)
          }
        }}
        disabled={disabled}
        {...otherProps}
      />
      <label
        id={`${uniqueID.current}-label`}
        htmlFor={uniqueID.current}
        className={cn(
          style.goalCheckboxLabel,
          { [style.goalCheckboxLabelPrimary]: type === 'primary' },
          { [style.goalCheckboxLabelSecondary]: type === 'secondary' },
          { 'error-input': invalid },
          { 'disabled-opacity': disabled },
          'mb-0'
        )}>
        {!listView && <span className={style.label}>{label}</span>}
      </label>
      {disabled && !readonly && (
        <UncontrolledTooltip
          placement="top"
          target={`${uniqueID.current}-label`}>
          {type === 'primary'
            ? 'You can’t select more then one Primary Goal per notification'
            : 'You can’t select more then one Secondary Goal per notification'}
        </UncontrolledTooltip>
      )}
    </>
  )
}
