import { IGroup } from '~/dataStore/Groups/Groups.interface'

export const BEACONS_QUERY_PARAMS = {
  groupIds: '',
  batteryStatus: ''
}

export enum BATTERY_STATUS {
  ACTIVE = 'Active',
  CHECK = 'Check',
  INACTIVE = 'Inactive'
}

export const BATTERY_STATUSES = [
  BATTERY_STATUS.ACTIVE,
  BATTERY_STATUS.CHECK,
  BATTERY_STATUS.INACTIVE
]

const BEACON_UUID_REGEXP =
  /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/

export const ERRORS_DICT = {
  uuid: {
    "can't be blank": 'UUID is missing',
    'is invalid':
      'UUID format is incorrect (Numbers from 0-9, or letters from A to F. It contains 32 hexadecimal digits, split into 5 groups: ffffffff-ffff-ffff-ffff-ffffffffffff)',
    'is already taken': 'UUID provided is already taken',
    'is already used in CSV file': 'Provided UUID is already used in CSV file',
    'Must be a String': 'UUID must be a string'
  },
  minor: {
    'is not a number':
      'Minor must be a number (Integer values between 0 and 65535)',
    'Must be a Integer':
      'Minor must be a number (Integer values between 0 and 65535)',
    "can't be blank": 'Minor is missing (Integer values between 0 and 65535)',
    'must be less than or equal to 65535':
      'Minor is incorrect (Integer values between 0 and 65535)',
    'must be greater than or equal to 0':
      'Minor is incorrect (Integer values between 0 and 65535)'
  },
  major: {
    'is not a number':
      'Major must be a number (Integer values between 0 and 65535)',
    'Must be a Integer':
      'Major must be a number (Integer values between 0 and 65535)',
    "can't be blank": 'Major is missing (Integer values between 0 and 65535)',
    'must be less than or equal to 65535':
      'Major is incorrect (Integer values between 0 and 65535)',
    'must be greater than or equal to 0':
      'Major is incorrect (Integer values between 0 and 65535)'
  },
  beaconId: {
    "can't be blank": 'Beacon ID is missing'
  }
}

export const NAME_FIELD_VALIDATION = {
  required: {
    value: true,
    message: 'Beacon name is missing.'
  }
}

export const MINOR_FIELD_VALIDATION = {
  required: {
    value: true,
    message: ERRORS_DICT.minor["can't be blank"]
  },
  max: {
    value: 65535,
    message: ERRORS_DICT.minor['must be less than or equal to 65535']
  },
  min: {
    value: 0,
    message: ERRORS_DICT.minor['must be greater than or equal to 0']
  }
}

export const BEACON_ID_VALIDATION = {
  required: {
    value: true,
    message: ERRORS_DICT.beaconId["can't be blank"]
  }
}

export const MAJOR_FIELD_VALIDATION = {
  required: {
    value: true,
    message: ERRORS_DICT.major["can't be blank"]
  },
  max: {
    value: 65535,
    message: ERRORS_DICT.major['must be less than or equal to 65535']
  },
  min: {
    value: 0,
    message: ERRORS_DICT.major['must be greater than or equal to 0']
  }
}

export const UUID_FIELD_VALIDATION = {
  required: {
    value: true,
    message: ERRORS_DICT.uuid["can't be blank"]
  },
  pattern: {
    value: BEACON_UUID_REGEXP,
    message: ERRORS_DICT.uuid['is invalid']
  }
}

export interface BeaconFormValues {
  nickname: string
  major: string
  minor: string
  uuid: string
}

export interface IBeaconPayload {
  nickname: string
  major: number
  minor: number
  uuid: string
}

export type BatteryStatus =
  | BATTERY_STATUS.ACTIVE
  | BATTERY_STATUS.CHECK
  | BATTERY_STATUS.INACTIVE

export interface IBeaconsStatus {
  active: number
  check: number
  inactive: number
}

export interface IBeaconsDTO {
  data: IBeacon[]
  metadata: IMetaData
  bulkActions: IBatchActionUrls
}

export interface IBeacon {
  id: string
  nickname: string
  cachedBeaconEventsCount: number
  createdAt: string
  uuid: string
  major: number
  minor: number
  battery: BatteryStatus
  beaconId: number
  groups: IGroup[]
  actions: {
    edit: string
    delete: string
  }
}

export type ISelectedBeacons = Map<string, IBeacon>

export interface IMetaData {
  dataCount: number
  page: number
  perPage: number
  totalPages: number
  groups: Array<IGroup>
  beaconsStatus: IBeaconsStatus
}

export interface IBatchActionUrls {
  addToGroup: string
  delete: string
  removeFromGroup: string
}

export interface IBeaconRow extends IBeacon {
  allGroups: Array<{ id: string; name: string }>
  batchActionUrls: IBatchActionUrls
}

export interface IBeaconsQueryParams {
  perPage?: number
  page?: number | undefined
  batteryStatus?: string
  searchQuery?: string
  groupIds?: string[]
  sort?: string
  order?: 'asc' | 'desc'
}
