import React, { useEffect, useState } from 'react'
import { Modal as ModalWrapper, ModalHeader } from 'reactstrap'

import { withStore, useStore } from '~/dataStore'

import './Modal.scss'

interface IProps {
  id: string
  render?: (closeModal: () => void, params: any) => React.ReactNode
  renderHeader?: (closeModal: () => void, params: any) => React.ReactNode
  container?: string
  backdrop?: 'static' | true | false
  closeCallback?: () => void
  keyboard?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  contentClassName?: string
}

export const Modal = ({
  backdrop,
  id,
  keyboard,
  closeCallback,
  render,
  renderHeader,
  container = 'root',
  size = 'sm',
  className = '',
  contentClassName
}: IProps): React.ReactElement => {
  const { ui } = useStore()

  const [showContent, setShowContent] = useState(false)

  function closeModal() {
    ui.hideModal(id)
  }

  function closeWithCallback() {
    closeModal()
    closeCallback?.()
  }

  useEffect(() => {
    ui.registerModal(id)

    return () => {
      ui.unregisterModal(id)
    }
  }, [])

  return (
    <ModalWrapper
      backdrop={backdrop}
      keyboard={keyboard}
      centered
      container={container}
      size={size}
      onClosed={() => {
        setShowContent(false)
      }}
      onOpened={() => setShowContent(true)}
      isOpen={ui.isModalOpen(id)}
      toggle={closeWithCallback}
      modalClassName={className}
      contentClassName={contentClassName}>
      <ModalHeader className="py-4 fw-normal" toggle={closeWithCallback}>
        {renderHeader && renderHeader(closeModal, ui.getModalParams(id))}
      </ModalHeader>
      <div className="px-4 pb-4">
        {showContent && render && render(closeModal, ui.getModalParams(id))}
      </div>
    </ModalWrapper>
  )
}

export default withStore(Modal)
