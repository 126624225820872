import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { isEmpty } from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'
import { useStore } from '~/dataStore'
import {
  IAdmin,
  ADMIN_ROLES_NAMES
} from '~/pages/AccountSettings/AccountSettings.interface'
import SeparatorLine from '~/components/SeparatorLine'

import styles from './AdminsList.scss'

const AdminListItem = ({ admin }: { admin: IAdmin }): React.ReactElement => {
  const {
    ui: { showModal },
    app: { currentAdmin }
  } = useStore()

  return (
    <div className={cn(styles.listItem, 'card')}>
      {isEmpty(admin) ? (
        <SkeletonTheme baseColor="#f5f7fc">
          <Skeleton circle width={100} height={100} />
          <Skeleton height={10} className="mt-3" />
          <hr className="my-3" />
          <Skeleton height={10} />
          <Skeleton height={10} />
        </SkeletonTheme>
      ) : (
        <>
          {admin.actions.edit && (
            <button
              type="button"
              color=""
              className={cn(styles.action, styles.actionEdit)}
              onClick={() => showModal('editAddAdminModal', { admin })}>
              <FontAwesomeIcon
                size="sm"
                icon={faPen}
                className="text-primary"
              />
            </button>
          )}
          {admin.actions.delete && (
            <button
              type="button"
              color=""
              className={cn(styles.action, styles.actionDelete)}
              onClick={() =>
                showModal('confirmAdminDeletion', { id: admin?.id })
              }>
              <FontAwesomeIcon
                size="sm"
                icon={faTrash}
                className="text-primary"
              />
            </button>
          )}
          <span className={styles.avatarWrapper}>
            <span
              style={{ backgroundImage: `url(${admin.avatarUrl})` }}
              className={styles.avatar}
            />
          </span>
          {admin.username && (
            <p className={cn('fw-semibold mt-3 mb-0 text-break')}>
              {admin.username}{' '}
              {currentAdmin.id === admin.id && <span>(me)</span>}
            </p>
          )}
          {admin.name && <p className="text-break mt-1 mb-0">{admin?.name}</p>}
          {admin.email && (
            <>
              <p
                id={`admin-${admin.id}-email`}
                className={cn(
                  { 'mt-3': !admin.username && !admin.name },
                  { 'mt-1': admin.username && admin.name },
                  'mb-0 text-truncate'
                )}>
                {admin.email}
              </p>
              <UncontrolledTooltip target={`admin-${admin.id}-email`}>
                {admin.email}
              </UncontrolledTooltip>
            </>
          )}
          {admin.jobTitle && (
            <p className="text-secondary truncate truncate--three-lines mt-1 mb-0">
              {admin.jobTitle}
            </p>
          )}
          <SeparatorLine spaceTop={3} spaceBottom={3} />
          {admin.role && (
            <p className="mb-1">{ADMIN_ROLES_NAMES[admin.role]}</p>
          )}
          {admin.managedApp?.name && (
            <p className="mb-1"> {admin.managedApp?.name || 'All Apps'} </p>
          )}
        </>
      )}
    </div>
  )
}

export default AdminListItem
