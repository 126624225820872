import React from 'react'
import StatCard from '~/components/reports/StatCard'
import LineChart from '~/components/ApexCharts/ApexLineChart/ApexLineChart'
import { IChartData } from '~/dataStore/reports/Report.interface'

const PrimaryVsSecondaryGoal = ({
  chartData
}: {
  chartData: IChartData[]
}): React.ReactElement => {
  return (
    <StatCard
      cardClasses="mb-0 h-100"
      tooltip="This graph shows the change in primary and secondary conversion goal rates over the selected period"
      title="Primary vs Secondary Goals Performance ">
      <LineChart
        chartData={chartData}
        chartType="bar"
        customColors={[
          { start: '#5ABF9D', end: '#347C64', legend: '#469D7F' },
          { start: '#F5C324', end: '#C1960C', legend: '#F9861C' }
        ]}
      />
    </StatCard>
  )
}

export default PrimaryVsSecondaryGoal
