import { ApexOptions } from 'apexcharts'

const DEFAULT_OPTIONS: ApexOptions = {
  labels: [],
  chart: {
    height: 350,
    type: 'radialBar',
    toolbar: {
      show: false
    }
  },
  states: {
    hover: {
      filter: {
        type: '',
        value: 1
      }
    }
  },
  plotOptions: {
    radialBar: {
      startAngle: -85,
      endAngle: 275,
      hollow: {
        margin: 0,
        size: '60%',
        background: '#fff',
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: 'front',
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: '#fff',
        strokeWidth: '100%',
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: 0,
          left: 0,
          blur: 1,
          opacity: 0.35
        }
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: '#28518C',
          fontSize: '14px'
        },
        value: {
          color: '#28518C',
          fontSize: '36px',
          show: true
        }
      }
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'vertical',
      shadeIntensity: 0.5,
      gradientToColors: ['#5ABF9D', '#3E95D1', '#C050BA'],
      colorStops: [
        [
          {
            offset: 0,
            color: '#C050BA',
            opacity: 1
          },
          {
            offset: 40,
            color: '#3E95D1',
            opacity: 1
          },
          {
            offset: 100,
            color: '#5ABF9D',
            opacity: 1
          }
        ]
      ],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1
      // stops: [0, 50, 100]
    }
  },
  stroke: {
    lineCap: 'round'
  }
}

export default DEFAULT_OPTIONS
