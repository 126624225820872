import React, { Component } from 'react'
import cn from 'classnames'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledTooltip
} from 'reactstrap'
import { IApplication } from '~/pages/App/Manager/AppManager.interface'

import { get } from '~/api/v2'

import { withStore, store } from '~/dataStore'
import { storeContext } from '~/dataStore/index'

import defaultAppLogo from '~/assets/img/default-app-logo.svg'
import { transparentPlaceholder } from '~/utils/html'
import Img from '~/components/Img'

import style from './NavBar.scss'

class AppsDropdown extends Component {
  static contextType = storeContext

  state = {
    apps: []
  }

  componentDidMount() {
    get('').then((response) => {
      this.setState({ apps: response.data })
    })
  }

  render() {
    const {
      app: { appDetails }
    } = store
    const { apps } = this.state
    return (
      <UncontrolledButtonDropdown>
        <DropdownToggle
          caret
          color=""
          className={cn(style.appsWrapper, 'me-3', 'btn-outline')}
          aria-label={`App button ${appDetails.name}`}>
          <Img
            src={appDetails.image}
            className={cn('img-fluid', 'rounded-circle', style.currentAppImage)}
            fallbackSrc={defaultAppLogo}
            alt={appDetails.name}
          />
          <span className={cn(style.currentAppName)}>{appDetails.name}</span>
        </DropdownToggle>
        <DropdownMenu className={cn(style.dropdownMinWidth, 'dropdown-nav')}>
          {apps.map((app: IApplication) => {
            return (
              <DropdownItem
                key={app.id}
                className={cn(style.appLink, style.btnNoOutline)}
                href={`/mobile/apps/${app.id}/campaigns`}
                aria-label={`App dropdown ${app.name}`}>
                <Img
                  src={app ? app.image : transparentPlaceholder}
                  fallbackSrc={defaultAppLogo}
                  className={cn(
                    'img-fluid',
                    'rounded-circle',
                    'mb-2',
                    style.currentAppImage
                  )}
                  alt={app.name}
                />
                <span id={`app-${app.id}-name`} className={style.appName}>
                  {app.name}
                </span>
                {app.name.length > 18 && (
                  <UncontrolledTooltip
                    placement="top"
                    target={`app-${app.id}-name`}>
                    {app.name}
                  </UncontrolledTooltip>
                )}
              </DropdownItem>
            )
          })}
          {apps.length === 0 && (
            <p className="mb-0 mx-2" aria-label="No other apps">
              There is no other Apps
            </p>
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )
  }
}

export default withStore(AppsDropdown)
