import { PropsWithChildren, ReactElement } from 'react'
import paperPlaneIcon from '~/components/icons/paperPlane.svg'
import Link from '~/components/Link'

interface IProps {
  buttonText?: string
  route?: string
}

const SuccessMessage = ({
  children,
  buttonText = 'Back to sign In',
  route = '/admins/sign_in'
}: PropsWithChildren<IProps>): ReactElement => {
  return (
    <div>
      <img
        src={paperPlaneIcon}
        width={71}
        alt=""
        className="mx-auto d-block mt-3"
      />
      <p className="fw-medium mt-4 pt-2">{children}</p>
      <Link
        className="mt-4 btn d-block mt-4 w-100 btn-outline-body btn--hover"
        route={{ path: route }}>
        {buttonText}
      </Link>
    </div>
  )
}

export default SuccessMessage
