import React from 'react'

import DateAndTimeInput from '~/components/forms/DateAndTimeInput'
import { useStore, withStore } from '~/dataStore'

const DateForm = () => {
  const {
    campaign: {
      deliver: { expiryDate, setExpiryDate, validateExpiryDate, startAt }
    }
  } = useStore()

  return (
    <div className="d-flex align-items-center">
      <DateAndTimeInput
        invalid={!expiryDate.isValid}
        value={expiryDate.value || null}
        minDate={startAt.value || undefined}
        onChange={(value) => {
          setExpiryDate(value)
          validateExpiryDate()
        }}
        className="d-flex"
      />
    </div>
  )
}

export default withStore(DateForm)
