import React from 'react'

import Skeleton from 'react-loading-skeleton'
import { goal as goalStyle } from './SetUp.scss'

interface IProps {
  type: string
  goal: string
  time: string
}

const Goal = ({ type, goal, time }: IProps): React.ReactElement => (
  <div className={goalStyle}>
    {goal ? (
      <>
        <header className="mb-4">{type} Goal</header>
        <div className="text-dark">{goal}</div>
        <div className="muted mt-2">
          Within
          <span className="text-dark"> {time} </span>
          of receiving campaign
        </div>
      </>
    ) : (
      <Skeleton width={300} count={4} className="mx-auto d-block my-2" inline />
    )}
  </div>
)

export default Goal
