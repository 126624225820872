import { ReactElement } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import UserPersonalData from '../components/UserPersonalData'
import UserVitals from '../components/UserVitals'
import ProfileActions from '../components/UserProfileActions'
import UserStatusOverlay from '../components/UserStatusOverlay'
import useUserDetails from '~/pages/Users/UserDetails/useUserDetails'

const UserPersonalSection = (): ReactElement => {
  const { data: user, isLoading } = useUserDetails()

  return (
    <>
      <Card className="mt-4" aria-label="User Personal Section">
        <CardBody className="pt-3 pb-4" aria-label="Personal Section Body">
          <Row>
            <Col
              xl={4}
              xxxl={4}
              widths={['xl', 'xxxl']}
              aria-label="Personal Data Column">
              <UserPersonalData />
            </Col>
            <Col
              xl={6}
              xxxl={5}
              widths={['xl', 'xxxl']}
              aria-label="Vitals Column">
              <UserVitals />
            </Col>
            {!user?.status.deleted && (
              <Col
                xl={2}
                className="offset-xxxl-1"
                aria-label="Profile Actions Column">
                {!isLoading ? (
                  <ProfileActions />
                ) : (
                  <div
                    className="d-flex justify-content-end"
                    aria-label="Loading Profile Actions">
                    <Skeleton circle width={20} height={20} />
                    <Skeleton className="mx-3" circle width={20} height={20} />
                    <Skeleton circle width={20} height={20} />
                  </div>
                )}
              </Col>
            )}
          </Row>
        </CardBody>
        <UserStatusOverlay />
      </Card>
    </>
  )
}

export default UserPersonalSection
