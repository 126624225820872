import { ReactElement } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import cn from 'classnames'
import TableNew from '~/components/Table/TableNew'
import { useUserBeaconEvents } from '~/pages/Users/UserDetails/useUserDetails'
import styles from '~/pages/Users/UserDetails/UserDetails.scss'
import { breakLine, col, colWide } from '~/components/Table/Table.scss'
import { IUserBeaconEvent } from '~/pages/Users/Users.interface'

const columns = [
  {
    fieldName: 'name',
    displayName: 'Name',
    render: ({
      row: { name }
    }: {
      row: IUserBeaconEvent
    }): React.ReactElement => (
      <div
        className={cn('d-flex align-items-center', col, colWide, breakLine)}
        aria-label={`Event Name: ${name}`}>
        {name}
      </div>
    )
  },
  {
    fieldName: 'type',
    displayName: 'Event Type'
  },
  {
    fieldName: 'occurredAt',
    displayName: 'Occurred at'
  }
]

const UserBeaconEventsSection = (): ReactElement => {
  const {
    data: beaconEvents,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading
  } = useUserBeaconEvents()

  return (
    <Card className="" aria-label="User Beacon Events Section">
      <CardHeader
        className="d-flex justify-content-between pb-0 pt-3"
        aria-label="Beacon Events Header">
        <CardTitle tag="h3" className="mb-0" aria-label="Beacon Events Title">
          Beacon Events
        </CardTitle>
      </CardHeader>
      <CardBody className="py-3" aria-label="Beacon Events Body">
        <TableNew
          className={styles.listWrapper}
          columns={columns}
          hasMore={hasNextPage}
          onLoadMore={() => fetchNextPage()}
          isLoading={isLoading}
          isLoadingMore={isFetchingNextPage}
          rows={beaconEvents?.pages || []}
          contentScrollable
          striped
        />
      </CardBody>
    </Card>
  )
}

export default UserBeaconEventsSection
