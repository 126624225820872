import React from 'react'
import SearchInput from '~/components/forms/SearchInput'
import ToggleRadioSwitch from '~/components/forms/ToggleRadioSwitch'
import Select from '~/components/forms/Select'
import {
  IMessageFilters,
  IMessageType
} from '~/pages/Messages/Messages.interface'

interface IProps {
  handleSearch: (searchQuery: string) => void
  searchQuery: string
  queryParams: {
    page: number
    type: IMessageType
    filter: IMessageFilters
  }
  unreadCount: number
}

const Toolbar = ({
  handleSearch,
  searchQuery,
  queryParams,
  unreadCount
}: IProps) => {
  const handleTypeChange = (val: IMessageType) => {
    queryParams.page = 1
    queryParams.type = val
  }

  return (
    <div className="d-flex justify-content-between align-items-center py-4 px-3 shadow flex">
      <div className="d-flex">
        <SearchInput
          placeholder="Search for a message"
          initialValue={searchQuery}
          onChange={handleSearch}
          className="me-3 flex-0-1"
        />
        <Select
          selectedOption={queryParams.filter}
          title="Filter"
          options={[
            { name: 'Show All', value: 'view_all' },
            { name: 'Read', value: 'read' },
            { name: `Unread (${unreadCount})`, value: 'unread' },
            { name: 'Feedback', value: 'feedback' },
            { name: 'Feature Suggestion', value: 'feature_suggestion' },
            { name: 'Complaint', value: 'complaint' },
            { name: 'Other', value: 'other' }
          ]}
          onSelect={(val) => {
            queryParams.filter = val
            queryParams.page = 1
          }}
        />
      </div>
      <ToggleRadioSwitch
        options={[
          {
            name: 'all',
            value: 'all',
            label: 'All Messages',
            width: 160
          },
          {
            name: 'admin',
            value: 'admin',
            label: 'Assigned to me',
            width: 180
          }
        ]}
        className="ms-auto me-1"
        onChange={(e) => {
          handleTypeChange(e.target.value as IMessageType)
        }}
        selected={queryParams.type}
      />
    </div>
  )
}

export default Toolbar
