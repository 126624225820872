import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { NotificationType, showNotification } from '~/utils/Notification'
import { ID } from '~/common.interface'
import {
  createWebSDKDomain,
  editWebSDKDomain,
  fetchSDKCredentials,
  generateWebSDKKey,
  removeWebSDKDomain
} from '~/api/appSettings'

export enum ServerStateKeysEnum {
  WebSDKCredentials = 'webSDKCredentials'
}

export const useGetWebSDKCredentials = (appId: ID) =>
  useQuery([ServerStateKeysEnum.WebSDKCredentials], () =>
    fetchSDKCredentials(appId)
  )

export const useEditWebSDkDomain = (close: () => void) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (data: { data: { url: string }; id: ID }) =>
      editWebSDKDomain(appId, data.id, data.data),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.WebSDKCredentials])
        showNotification('Successfully edited domain', NotificationType.SUCCESS)
        close()
      },
      onError: () => {
        showNotification(
          "Couldn't edit domain, please try again later.",
          NotificationType.ERROR
        )
      }
    }
  )
}

export const useAddWebSdkDomain = (close: () => void) => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (domain: { url: string }) => createWebSDKDomain(appId, domain),
    {
      onSuccess: () => {
        cache.invalidateQueries([ServerStateKeysEnum.WebSDKCredentials])
        showNotification('Successfully added domain', NotificationType.SUCCESS)
        close()
      },
      onError: () => {
        showNotification(
          "Couldn't add domain, please try again later.",
          NotificationType.ERROR
        )
      }
    }
  )
}

export const useRemoveWebSdkDomain = () => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(
    (data: { id: ID; closeModal: () => void }) =>
      removeWebSDKDomain(appId, data.id),
    {
      onSuccess: (data, variables) => {
        cache.invalidateQueries([ServerStateKeysEnum.WebSDKCredentials])
        variables.closeModal()
        showNotification(
          'Successfully removed domain',
          NotificationType.SUCCESS
        )
      },
      onError: () => {
        showNotification(
          "Couldn't remove domain, please try again later.",
          NotificationType.ERROR
        )
      }
    }
  )
}

export const useGenerateKey = () => {
  const cache = useQueryClient()
  const { appId } = useParams<{ appId: string }>()
  return useMutation(() => generateWebSDKKey(appId), {
    onSuccess: () => {
      cache.invalidateQueries([ServerStateKeysEnum.WebSDKCredentials])
    },
    onError: () => {
      showNotification(
        "Couldn't generate key, please try again later.",
        NotificationType.ERROR
      )
    }
  })
}
