import { Button, Collapse, Row } from 'reactstrap'
import cn from 'classnames'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from './GoalsPerformance.scss'
import { IGoalPerformance } from '../../Store/JourneyReport.interface'
import GoalsPerformanceCols, {
  GoalsPerformanceActionCols
} from './GoalsPerformanceCols'

interface IProps {
  row: IGoalPerformance
}

export default function GoalsPerformanceRow({
  row
}: IProps): React.ReactElement {
  const [isOpen, setOpen] = useState(false)

  if (!row.subRows && !row.actions) {
    return (
      <Row className={cn(styles.tableRow)}>
        <GoalsPerformanceCols row={row}>
          <div className={cn(styles.tableXLine)}>{row.name}</div>
        </GoalsPerformanceCols>
      </Row>
    )
  }

  return (
    <div className={cn(styles.tableRow)}>
      <Row className="bold">
        <GoalsPerformanceCols row={row}>
          <Button
            color=""
            className={cn('pt-0 pb-0 btn--transparent text-start bold')}
            onClick={() => setOpen((prev) => !prev)}>
            {row.name}
            {(row.subRows || row.actions) && (
              <FontAwesomeIcon
                className={cn('ms-2', styles.tableArrow, {
                  [styles.tableArrowActive]: isOpen
                })}
                icon={faChevronDown}
              />
            )}
          </Button>
        </GoalsPerformanceCols>

        {row.subRows && (
          <Collapse isOpen={isOpen} className={cn('col fw-normal')}>
            {row.subRows.map((subRow) => (
              <Row
                key={subRow.id}
                className={cn('my-2', styles.tableStriped, styles.tableXLine)}>
                <GoalsPerformanceCols
                  row={subRow}
                  colClasses={styles.tableSpaceLeft}
                />
              </Row>
            ))}
          </Collapse>
        )}

        {row.actions && (
          <Collapse isOpen={isOpen} className={cn('col fw-normal')}>
            {row.actions.map((actionRow) => (
              <Row
                key={actionRow.id}
                className={cn('my-2', styles.tableStriped, styles.tableXLine)}>
                <GoalsPerformanceActionCols
                  row={actionRow}
                  colClasses={styles.tableSpaceLeft}
                />
              </Row>
            ))}
          </Collapse>
        )}
      </Row>
    </div>
  )
}
