import { ReactElement, useRef } from 'react'
import cn from 'classnames'
import Input from '~/components/forms/Input'
import HintBtn from '~/HintWindow/HintBtn'
import HintModal from '~/HintWindow/HintModal'
import hint from './hint.png'

import styles from './CustomTagInput.scss'

interface IProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error: string | undefined
  hintTargetRef?: React.RefObject<HTMLDivElement>
  defaultValue?: string
}

const CustomTagInput = ({
  error,
  onChange,
  hintTargetRef,
  defaultValue
}: IProps): ReactElement => {
  const inputWrapperRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between position-relative mt-4"
        ref={inputWrapperRef}>
        <Input
          defaultValue={defaultValue}
          placeholder="Enter Custom Data Tag"
          className={cn('w-100 pe-5', {
            'form-group--error': error
          })}
          label="Custom Data Tag"
          name="customTag"
          onChange={onChange}
          errorTooltip={error}
          aria-label="Custom Data Tag"
        />
        <HintBtn
          className={styles.hintBtn}
          aria-label="How Custom Data Tags works">
          How Custom Data Tags works
        </HintBtn>
      </div>

      <HintModal
        link="https://docs.pulsatehq.com/reference/new-segment-by-csv"
        mediaFile={hint}
        offsetLeft={20}
        offsetTop={100}
        elem={hintTargetRef || inputWrapperRef}
        aria-label="Custom Data Tag Help">
        <h2>Custom Data Tag</h2>
        <p>
          Custom Data Tag allows saving Segments effortlessly without the need
          for additional conditions.
        </p>
        <p>
          Access your Custom Data Tag conveniently in the Segment Builder under
          Custom Data, allowing seamless integration with other conditions.
        </p>
      </HintModal>
    </>
  )
}

export default CustomTagInput
