import { ICurrentAdmin } from '~/dataStore/App.interface'
import { ID, IMetaData, ADMIN_ROLES } from '~/common.interface'

export const ERRORS_DICT = {
  currentPassword: {
    'is invalid': 'Current password is invalid',
    "can't be blank": 'Please provide current password'
  },
  password: {
    "can't be blank": 'Please provide new password',
    'is too short (minimum is 8 characters)':
      'Password is too short (minimum is 8 characters)'
  },
  passwordConfirmation: {
    "doesn't match Password": 'Passwords does not match'
  },
  avatarContentType: {
    'is invalid': 'File must contain the valid extension'
  },
  avatarFileSize: {
    'must be less than 1MB': 'File size exceeded 1MB'
  },
  avatar: {
    'must be less than 1MB': 'File size exceeded 1MB',
    'has contents that are not what they are reported to be':
      'Invalid file content'
  },
  name: {
    "can't be blank": 'Full name name is missing'
  },
  email: {
    'has already been taken': 'Email is already taken'
  },
  role: {
    'Missing parameter role': 'Please select Admin Role'
  },
  managedAppId: {
    "can't be blank": 'Please select application'
  }
}

export type Section = {
  name: string
  sectionBody: (
    currentAdmin: ICurrentAdmin,
    appId: ID,
    onSubmit: (data: {
      name: string
      jobTitle?: string
      avatarUrl?: string
    }) => void
  ) => React.ReactElement
  learnMoreLink?: string
}

export interface IAdmin {
  id: ID
  name: string
  username: string
  email: string
  jobTitle: string
  avatarUrl: string
  managedApp: { name: string; id: ID } | null
  access: 'all' | ''
  role: ADMIN_ROLES
  actions: {
    edit: boolean
    delete: true
  }
  createdAt: Date
  updatedAt: Date
}

export interface AdminsDTO {
  data: IAdmin[]
  metadata: IMetaData
}

export const ADMIN_ROLES_NAMES = {
  master_admin: 'Master Admin',
  app_admin: 'App Admin',
  account_admin: 'Account Admin',
  demo_admin: 'Demo Admin'
} as const

export interface SecurityFormFieldValues {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

export interface PersonalFormFieldValues {
  jobTitle: string
  name: string
  adminAvatar: {
    objectURL: string
    fileName: string
    file: File | null
    valid: boolean
  }
}

export interface AdminFormFieldValues {
  managedAppId: ID
  role: ADMIN_ROLES
  email: string
  allowedActions: 'all' | ''
}

export const ADMINS_LIST_QUERY = 'admins'

export default ERRORS_DICT
