import React from 'react'
import cn from 'classnames'
import { Spinner, Table as BSTable } from 'reactstrap'
import useInfiniteScroll from '~/hooks/useInfiniteScroll'
import Checkbox from '~/components/forms/Checkbox'
import ColHeader from './ColHeader'
import { withStore } from '~/dataStore'
import TableSkeletonPlaceholder from './components/TableSkeletonPlaceholder'
import { ITableProps } from './Table.interface'

import { scrollableBody, scrollableBodyBlue } from './Table.scss'

function rowToArray(
  columns: any[],
  row: any,
  onUpdateRow?: (row: any) => any,
  onDeleteRow?: () => any,
  onSelect?: (row: any, selected: boolean) => any,
  isSelected?: boolean,
  disabled = false
) {
  return columns.map(({ fieldName, render: Component }) =>
    Component ? (
      <Component
        disabled={disabled}
        row={row}
        onUpdateRow={onUpdateRow}
        onDeleteRow={onDeleteRow}
        onSelectRow={onSelect}
        isSelected={isSelected}
      />
    ) : (
      row[fieldName]
    )
  )
}

function Table<T>({
  columns,
  rows,
  rowKey = 'id',
  sortColumn,
  sortOrder,
  onSort,
  selectedRows,
  selectedRow,
  hasMore,
  onLoadMore,
  onSelect,
  onUpdateRow,
  onDeleteRow,
  isLoading,
  isLoadingMore,
  contentScrollable,
  maxHeight,
  className,
  invalid = false,
  borderless = false,
  disabled,
  contentScrollableBlue,
  striped = false,
  skeleton,
  stickyHeader,
  noResultsMessage = 'No results',
  ariaLabel
}: ITableProps<T> & { ariaLabel?: string }): React.ReactElement {
  const selectRow = (row: T, selected: boolean) => {
    if (onSelect) {
      onSelect(row, selected)
    }
  }
  const selectAll = (selected: boolean) => {
    if (onSelect) {
      rows.forEach((row) => onSelect(row, selected))
    }
  }

  const bottomRef = useInfiniteScroll({ hasMore, onLoadMore })

  return (
    <div
      className={cn(
        className,
        'd-flex flex-column',
        { [scrollableBody]: contentScrollable },
        { [scrollableBodyBlue]: contentScrollableBlue }
      )}
      style={{ maxHeight }}
      aria-label={ariaLabel}>
      <BSTable
        className={cn('mb-0', { 'table--sticky-header': stickyHeader })}
        borderless={borderless}
        striped={striped}>
        <thead>
          <tr className="table__header-row">
            {onSelect && (
              <th className="border-top-0 px-3 table__col table__col--header table__col--select">
                <Checkbox
                  checked={
                    (selectedRows?.size && selectedRows.size === rows.length) ||
                    false
                  }
                  disabled={disabled}
                  onChange={selectAll}
                  wrapperClass="d-flex"
                  invalid={invalid}
                />
              </th>
            )}

            {columns.map((c) => (
              <ColHeader
                key={c.displayName}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSort={onSort}
                {...c}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.length === 0 && !isLoading && (
            <tr>
              <td
                className="table__col text-center border-0"
                colSpan={columns.length + (onSelect ? 1 : 0)}>
                {noResultsMessage}
              </td>
            </tr>
          )}
          {isLoading && (
            <TableSkeletonPlaceholder
              {...skeleton}
              tableColumns={columns}
              withSelectCheckbox={!!onSelect}
            />
          )}
          {!isLoading &&
            rows.map((row, i) => (
              <tr
                key={row[rowKey]}
                ref={i === rows.length - 1 ? bottomRef : null}
                className={cn('mw-100 table__row', {
                  'table__row--selected': row.id === selectedRow
                })}>
                {onSelect && (
                  <td
                    className="px-3 table__col table__col--select"
                    onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      wrapperClass="d-flex"
                      disabled={disabled}
                      checked={!!selectedRows?.get(row[rowKey]) || false}
                      onChange={(selected: boolean) => selectRow(row, selected)}
                      invalid={invalid}
                    />
                  </td>
                )}

                {rowToArray(
                  columns,
                  row,
                  onUpdateRow,
                  onDeleteRow,
                  onSelect,
                  !!selectedRows?.get(row[rowKey]) || false,
                  disabled
                ).map((col, idx) => (
                  <td
                    key={columns[idx].displayName}
                    className="px-1 table__col">
                    <div
                      className={columns[idx].classes}
                      title={typeof col === 'string' ? col : ''}>
                      {col ?? '- -'}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </BSTable>
      {isLoadingMore && (
        <Spinner
          color="primary"
          type="grow"
          className="align-self-center mb-2 mt-2"
        />
      )}
    </div>
  )
}

export default withStore(Table)
