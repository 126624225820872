import cn from 'classnames'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { withStore, useStore } from '~/dataStore'
import { deleteRequest } from '~/api/requests'

import style from './NavBar.scss'

async function handleSignOut() {
  await deleteRequest('/admins/api/v2/logout')
  sessionStorage.removeItem('active-session')
  try {
    window.PulsateSDK?.endSession()
  } catch (error) {
    console.error(error)
  }
  window.location = '/admins/sign_in'
}

function item(name, url, permissions) {
  if (permissions.includes('all') || permissions.includes(name.toLowerCase())) {
    return (
      <DropdownItem
        className={style.appLink}
        href={typeof url === 'string' ? url : null}
        onClick={typeof url === 'function' ? url : null}>
        {name}
      </DropdownItem>
    )
  }

  return null
}

function AccountDropdown() {
  const {
    app: {
      permissions,
      currentApp: { id }
    }
  } = useStore()

  return (
    <UncontrolledDropdown nav className={cn('ms-1')}>
      <DropdownToggle
        nav
        caret
        className={cn(
          style.appsWrapper,
          style.iconWrapper,
          'me-3',
          'btn-outline',
          'position-relative'
        )}
        aria-label="Settings dropdown">
        <FontAwesomeIcon icon={faCog} className="align-middle" />
      </DropdownToggle>
      <DropdownMenu end data-bs-popper="true" className="dropdown-nav">
        {item('Account Settings', '/admins/edit', permissions)}
        {id &&
          item(
            'Channel Settings',
            `/mobile/apps/${id}/channel_settings`,
            permissions
          )}
        {id &&
          item('App Settings', `/mobile/apps/${id}/app_settings`, permissions)}
        {item('App Manager', '/mobile/apps', permissions)}
        {item('Documentation', 'https://pulsate.readme.io/v4.1', permissions)}
        {item('Sign out', handleSignOut, permissions)}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default withStore(AccountDropdown)
