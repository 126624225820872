import { PropsWithChildren, ReactElement } from 'react'
import cn from 'classnames'
import styles from '../noticeBarSecondary.scss'

interface IProps {
  icon?: string
  header?: string
  subHeader?: string
}

export default function NoticeBarSecondaryHeader({
  icon,
  header,
  subHeader
}: PropsWithChildren<IProps>): ReactElement {
  return (
    <div className="d-flex align-items-center">
      {icon && <img width={63} src={icon} alt="" />}
      <div className="ps-4 ms-xxl-3">
        <p className={cn(styles.barSecondaryTitle)}>{header}</p>
        <p className={cn(styles.barSecondarySubtitle, 'mb-0')}>{subHeader}</p>
      </div>
    </div>
  )
}
