import React from 'react'
import { Button, Col, Fade, ModalBody, ModalFooter, Row } from 'reactstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import Input from '~/components/forms/Input'
import {
  getErrorsDictionary,
  LAT_FIELD_VALIDATION,
  LNG_FIELD_VALIDATION,
  NAME_FIELD_VALIDATION,
  radiusFieldValidation
} from '~/pages/Geofences/consts'
import { IGeofence } from '~/pages/Geofences/Geofences.interface'

import './AddGeofencesModal.scss'

interface IProps {
  addGeofences: ({
    geofences,
    errorCallback,
    successCallback
  }: {
    geofences: IGeofence[]
    errorCallback?: (
      errors: Array<{ row: number; error: Array<string> }>
    ) => void
    successCallback?: () => void
  }) => void
  onClose: () => void
  minRadius: number | undefined
  maxRadius: number | undefined
  unit: 'm' | 'ft'
}

interface IFormData {
  geofences: Array<{
    name: string
    radius: number
    lat: number
    lng: number
  }>
}

const defaultValues = {
  name: '',
  lat: '',
  lng: '',
  radius: '',
  shape: 'circle'
}

const AddGeofencesModal = ({
  addGeofences,
  onClose,
  minRadius,
  maxRadius,
  unit
}: IProps): React.ReactElement => {
  const ERRORS_DICT = getErrorsDictionary(minRadius, maxRadius)
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      geofences: [defaultValues]
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'geofences'
  })

  const handleErrors = (
    validationErrors: Array<{ row: number; error: Array<string> }>
  ) => {
    validationErrors.forEach((errorRow) => {
      Object.keys(errorRow.error[0]).forEach((errorKey) => {
        setError(`geofences.${errorRow.row - 1}.${errorKey}`, {
          type: 'server',
          message: ERRORS_DICT[errorKey][errorRow.error[0][errorKey][0]]
        })
      })
    })
  }

  const onSubmit = (formData: IFormData) => {
    const geofences = formData.geofences.map((geofence) => ({
      name: geofence.name,
      radius: geofence.radius,
      shape: 'circle',
      location: [geofence.lat || '', geofence.lng || '']
    }))
    addGeofences({ geofences, errorCallback: handleErrors })
  }

  const getInputClassNames = (
    rowIndex: number,
    inputName: 'name' | 'lat' | 'lng' | 'radius'
  ): string => {
    const classes = ['mb-0']

    if (errors.geofences?.[rowIndex] && errors.geofences?.[rowIndex][inputName])
      classes.push('form-group--error')

    return classes.join(' ')
  }

  return (
    <form data-testid="submit-form" onSubmit={handleSubmit(onSubmit)}>
      <ModalBody className="px-0 pb-5">
        <Row className="mb-2">
          <Col xs={1} className="add-geofences-modal__small-col" />
          <Col xs={5}>Name</Col>
          <Col xs={2}>Latitude</Col>
          <Col xs={2}>Longitude</Col>
          <Col xs={2}>Radius ({unit})</Col>
          <Col xs={1} className="add-geofences-modal__small-col" />
        </Row>
        {fields.map((item, index) => {
          return (
            <Fade key={item.id}>
              <Row className="align-items-center mt-2">
                <Col xs={1} className="add-geofences-modal__small-col">
                  {index + 1}
                </Col>
                <Col xs={5}>
                  <Input
                    name={`geofences.${index}.name`}
                    id={`geofences-${index}-name`}
                    data-testid={`geofences-${index}-name`}
                    defaultValue={`${item.name}`}
                    register={register}
                    validation={NAME_FIELD_VALIDATION}
                    placeholder="Name"
                    className={getInputClassNames(index, 'name')}
                    errorTooltip={errors.geofences?.[index]?.name?.message}
                  />
                </Col>
                <Col xs={2}>
                  <Input
                    name={`geofences.${index}.lat`}
                    id={`geofences-${index}-lat`}
                    data-testid={`geofences-${index}-lat`}
                    defaultValue={`${item.lng}`}
                    className={getInputClassNames(index, 'lat')}
                    register={register}
                    validation={LAT_FIELD_VALIDATION}
                    placeholder="Latitude"
                    errorTooltip={errors.geofences?.[index]?.lat?.message}
                  />
                </Col>
                <Col xs={2}>
                  <Input
                    name={`geofences.${index}.lng`}
                    id={`geofences-${index}-lng`}
                    data-testid={`geofences-${index}-lng`}
                    defaultValue={`${item.lng}`}
                    validation={LNG_FIELD_VALIDATION}
                    register={register}
                    className={getInputClassNames(index, 'lng')}
                    placeholder="Longitude"
                    errorTooltip={errors.geofences?.[index]?.lng?.message}
                  />
                </Col>
                <Col xs={2}>
                  <Input
                    name={`geofences.${index}.radius`}
                    id={`geofences-${index}-radius`}
                    data-testid={`geofences-${index}-radius`}
                    type="number"
                    defaultValue={`${item.radius}`}
                    register={register}
                    validation={radiusFieldValidation(
                      minRadius,
                      maxRadius,
                      unit
                    )}
                    placeholder="Radius"
                    className={getInputClassNames(index, 'radius')}
                    errorTooltip={errors.geofences?.[index]?.radius?.message}
                  />
                </Col>
                <Col xs={1} className="add-geofences-modal__small-col">
                  {index > 0 && (
                    <FontAwesomeIcon
                      className="cursor-pointer"
                      icon={faTrash}
                      data-testid="remove-geofence-row-modal"
                      onClick={() => remove(index)}
                    />
                  )}
                </Col>
              </Row>
            </Fade>
          )
        })}
        <Row>
          <Col xs={1} className="add-geofences-modal__small-col" />
          <Col xs={4}>
            <Button
              type="button"
              color=""
              data-testid="add-geofence-row-modal"
              className="px-4 btn bg-white btn-hover-shadow btn-outline-body mt-3"
              onClick={() => {
                append(defaultValues)
              }}>
              <FontAwesomeIcon className="mt-2" icon={faPlus} size="xs" /> Add
              more
            </Button>
          </Col>
          <Col xs={2} />
          <Col xs={2} />
          <Col xs={2} />
          <Col xs={1} className="add-geofences-modal__small-col" />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={`px-4 ${
            !isValid && Object.keys(errors).length ? 'btn--error' : ''
          }`}
          color="primary"
          type="submit"
          data-testid="add-geofence-form-submit"
          disabled={!isDirty}>
          Save
        </Button>
      </ModalFooter>
    </form>
  )
}

export default AddGeofencesModal
