import React, { useState } from 'react'
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import { useForm, SubmitHandler } from 'react-hook-form'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { tagHotjarRecording } from '~/utils/utilities'
import { updateCurrentAdminPassword } from '~/api/admins'
import Input from '~/components/forms/Input'
import {
  ERRORS_DICT,
  SecurityFormFieldValues
} from '../AccountSettings.interface'
import { NotificationType, showNotification } from '~/utils/Notification'
import { getFormServerSideErrors } from '~/utils/validations'
import { ID } from '~/common.interface'
import shieldLockIcon from '~/components/icons/lock_shield.svg'
import { useStore } from '~/dataStore'

const AccountSettingsSecurity = ({
  appId
}: {
  appId: ID
}): React.ReactElement => {
  const {
    app: {
      currentAdmin: { isDemoAdmin }
    }
  } = useStore()
  const [passwordShown, setPasswordShown] = useState(false)
  const {
    setError,
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<SecurityFormFieldValues>({
    mode: 'onSubmit'
  })

  const onSubmit: SubmitHandler<SecurityFormFieldValues> = async (
    data: SecurityFormFieldValues
  ) => {
    tagHotjarRecording(['adminSecurityInformationEdited'])
    try {
      await updateCurrentAdminPassword(appId, data)
      showNotification(
        'Successfully updated admin security information',
        NotificationType.SUCCESS
      )
      reset()
    } catch (error) {
      getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
        setError(er.name, { type: 'server', message: er.message })
      )
    }
  }

  return (
    <Row className="w-100">
      <Col xs={8}>
        <form
          className="w-100 account-settings-personal"
          onSubmit={(e) => {
            clearErrors()
            handleSubmit(onSubmit)(e)
          }}>
          <Input
            register={register}
            label={
              <div className="d-flex justify-content-between">
                <span>Old password</span>
                <FontAwesomeIcon
                  onClick={(e) => {
                    e.preventDefault()
                    setPasswordShown((prev) => !prev)
                  }}
                  id="toggle-password-visibility"
                  className="cursor-pointer"
                  icon={passwordShown ? faEyeSlash : faEye}
                  size="lg"
                />
                <UncontrolledTooltip
                  placement="top"
                  target="toggle-password-visibility">
                  {passwordShown ? 'Hide' : 'Show'} password
                </UncontrolledTooltip>
              </div>
            }
            labelClassName="w-100"
            placeholder="Enter Old Password"
            className={cn('mb-3', {
              'form-group--error': errors.currentPassword
            })}
            validation={{
              required: {
                value: true,
                message: ERRORS_DICT.currentPassword["can't be blank"]
              }
            }}
            type={passwordShown ? 'text' : 'password'}
            errorTooltip={errors.currentPassword?.message}
            name="currentPassword"
          />

          <Input
            register={register}
            label="New Password"
            placeholder="Enter New Password"
            className={cn('mb-3', { 'form-group--error': errors.password })}
            required
            validation={{
              required: {
                value: true,
                message: ERRORS_DICT.password["can't be blank"]
              }
            }}
            errorTooltip={errors.password?.message}
            type={passwordShown ? 'text' : 'password'}
            name="password"
          />

          <Input
            register={register}
            label="Confirm New Password"
            placeholder="Confirm New Password"
            className={cn('mb-3', {
              'form-group--error': errors.passwordConfirmation
            })}
            required
            validation={{
              required: {
                value: true,
                message: ERRORS_DICT.password["can't be blank"]
              }
            }}
            type={passwordShown ? 'text' : 'password'}
            errorTooltip={errors.passwordConfirmation?.message}
            name="passwordConfirmation"
          />
          <Button
            color="primary"
            type="submit"
            className="mt-4 d-block"
            disabled={isDemoAdmin}>
            Save New Password{' '}
            {isSubmitting && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="xs"
                className="ms-1"
              />
            )}
          </Button>
        </form>
      </Col>
      <Col xs={4} className="d-flex align-items-center justify-content-center">
        <img
          src={shieldLockIcon}
          alt="Security"
          height={135}
          className="mb-4"
        />
      </Col>
    </Row>
  )
}

export default AccountSettingsSecurity
