import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { uniqueId } from 'lodash'
import PreviewPopover from '~/pages/Campaign/Notification/Preview/PreviewPopover'
import Link from '~/components/Link'
import routes from '~/routes'
import { fetchCampaignPreview } from '~/api/campaigns'
import { useStore, withStore } from '~/dataStore'
import {
  getCampaignTypeOptions,
  getPreviewByOptionType
} from '~/dataStore/CampaignTypeFacade'
import { Preview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import { breakLine, col, colName, colWide } from '~/components/Table/Table.scss'
import HiddenFromFeedIndicator from './HiddenFromFeedIndicator'

const CampaignNameColumn = ({
  name,
  isRedirectActive = true,
  campaignId,
  hiddenFromFeed,
  className,
  ariaLabel
}: {
  name: string
  isRedirectActive: boolean
  campaignId: string
  hiddenFromFeed: boolean
  className?: string
  ariaLabel?: string
}) => {
  const {
    app: {
      currentApp: { id }
    }
  } = useStore()

  const targetId = useRef(uniqueId('campaign-name'))

  const [preview, setPreview] = useState<Preview>()

  async function getPreview(): Promise<void> {
    if (preview) {
      return
    }

    try {
      const response = await fetchCampaignPreview(id, campaignId)
      const type = getCampaignTypeOptions(response.type, response)
      setPreview({
        ...response,
        type
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (isRedirectActive) {
    return (
      <div className={cn('d-flex align-items-center', className)}>
        {hiddenFromFeed && <HiddenFromFeedIndicator campaignId={campaignId} />}
        <Link
          id={targetId.current}
          onMouseEnter={getPreview}
          className={cn(col, colWide, colName, breakLine)}
          route={routes.campaignDetails}
          params={{ campaignId }}
          aria-label={ariaLabel}>
          {name}
        </Link>
        <PreviewPopover
          target={targetId.current}
          campaignType={preview?.type[0].original}
          parts={
            preview?.type[0] && getPreviewByOptionType(preview.type[0], preview)
          }
          size="sm"
          deviceType="ios"
        />
      </div>
    )
  }
  return (
    <div
      className={cn(
        'd-flex align-items-center',
        col,
        colWide,
        colName,
        breakLine,
        className
      )}
      aria-label={ariaLabel}>
      {hiddenFromFeed && <HiddenFromFeedIndicator campaignId={campaignId} />}
      {name}
    </div>
  )
}

export default withStore(CampaignNameColumn)
