import { Button, Col, Row } from 'reactstrap'
import { useState } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { withStore } from '~/dataStore'
import Steps from '~/components/BuilderSteps'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import BuilderErrors from '~/pages/CampaignBuilder/BottomActions/BuilderErrors'
import styles from '../../JourneyBuilderSidebar/Sidebar.scss'
import { isString } from '~/utils/utilities'
import { NotificationType, showNotification } from '~/utils/Notification'
import { CustomErrors } from '~/common.interface'
import CardNotificationBuilder from '~/pages/Campaign/Notification/CardNotification/UI/CardNotificationBuilder'
import JourneyCard from './JourneyCard'

interface IProps {
  notification: JourneyCard
  close: () => void
  save: () => Promise<void>
  cancelCallback: () => void
  disabled?: boolean
}

const CardNotificationSidebar = ({
  notification,
  close,
  save,
  cancelCallback,
  disabled = false
}: IProps): React.ReactElement | null => {
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorsTooltip, setShowErrorsTooltip] = useState(false)

  async function saveNotification(): Promise<void> {
    try {
      setIsLoading(true)
      await save()
      close()
    } catch (error) {
      if ((error as Error).message !== CustomErrors.INVALID) {
        console.error(error)

        if (isString((error as Error).message)) {
          showNotification((error as Error).message, NotificationType.ERROR)
        }
      }
      setShowErrorsTooltip(true)
    } finally {
      setIsLoading(false)
    }
  }

  function onSaveClick(): void {
    if (notification.card.isCardBack) {
      notification.currentStep.store.validateStep()
      if (notification.currentStep.store.isStepValid) {
        if (notification.currentStep.number === 1) {
          notification.setStep(notification.currentStep.number + 1)
        } else {
          saveNotification()
        }
      } else {
        setShowErrorsTooltip(true)
      }
    } else {
      saveNotification()
    }
  }

  const onStepClick = (stepNumber: number) => {
    notification.getStoreByNumber(stepNumber)?.validateStep()
    if (notification.getStoreByNumber(stepNumber)?.isStepValid) {
      notification.setStep(stepNumber)
    }
  }

  const goBack = () => {
    if (notification.currentStep.number === 1) {
      cancelCallback()
      close()
    } else {
      notification.setStep(notification.currentStep.number - 1)
    }
  }

  const backBtnText =
    notification.currentStep.number === 1
      ? 'Back to Message'
      : 'Back to Card Front'

  const nextBtnText =
    notification.currentStep.number === 1 && notification.card.isCardBack
      ? 'Save & Continue'
      : 'Save'

  return (
    <>
      <div className={cn('h-100 position-relative', styles.sidebarBlock)}>
        <div className="px-4">
          <Header className="mb-6">
            <HeaderTitle tag="h2" ariaLabel="Notification Step Title">
              {notification.currentStep.title}
            </HeaderTitle>
            <p className="color-subheader">
              Prepare your message and remember that you can use other types of
              notification to reach a bigger audience
            </p>
          </Header>
          <CardNotificationBuilder
            card={notification.card}
            type={notification.currentStep.type}
            onlyCarousel
            withEvent={false}
            toggleCardBackStore={notification.toggleCardBackStore}
            withGoals
            isSended={disabled}
          />
        </div>
      </div>

      <div
        className={cn(
          'position-fixed w-100 bottom-0 fixed-bottom p-4 bg-water-gray'
        )}>
        <Row className="align-items-center">
          <Col xs={4}>
            {notification.card.isCardBack && (
              <Steps
                steps={notification.steps}
                currentStep={notification.currentStep}
                onStepClick={onStepClick}
              />
            )}
          </Col>
          <Col xs={8}>
            <div
              className={cn('d-flex justify-content-end align-items-center')}>
              {disabled ? (
                <Button color="" onClick={goBack}>
                  {backBtnText}
                </Button>
              ) : (
                <>
                  <Button color="" disabled={isLoading} onClick={goBack}>
                    {backBtnText}
                  </Button>
                  <Button
                    id="save-block-btn"
                    color="body"
                    onClick={onSaveClick}
                    disabled={isLoading}
                    className="btn--hover px-4">
                    {nextBtnText}
                    {isLoading && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="xs"
                        className="ms-1"
                      />
                    )}
                  </Button>
                  {notification.getErrorMessages().length > 0 && (
                    <BuilderErrors
                      key={`${notification.getErrorMessages()?.length}`}
                      targetId="save-block-btn"
                      messages={notification.getErrorMessages()}
                      close={() => {
                        setShowErrorsTooltip(false)
                      }}
                      isOpen={!notification.isStepValid && showErrorsTooltip}
                    />
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withStore(CardNotificationSidebar)
