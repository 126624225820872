import React, { PropsWithChildren } from 'react'

import styles from './styles.scss'

interface IProps {
  divider?: boolean
}

const Section = ({ divider, children }: PropsWithChildren<IProps>) => {
  return (
    <div className={styles.formGroup}>
      <div className={styles.content}>{children}</div>
      {divider && (
        <div className={styles.dividerWrapper}>
          <span className={styles.divider} />
        </div>
      )}
    </div>
  )
}

export default Section
