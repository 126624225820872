import { Button } from 'reactstrap'
import cn from 'classnames'
import { find, findKey, startCase } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { withStore } from '~/dataStore'
import { RuleWithCategoriesDTO } from './SegmentBuilder.interface'
import SegmentBuilderStore from './SegmentBuilder.store'
import IconDelete from '~/components/icons/IconDelete'
import styles from './SegmentBuilder.scss'
import Rules from './Rules/Rules'
import SegmentRuleModel from './Rules/SegmentRule.model'

interface IProps {
  rules: RuleWithCategoriesDTO
  rule: SegmentRuleModel
  store: SegmentBuilderStore
}

function SegmentRule({ rules, rule, store }: IProps): React.ReactElement {
  const category = startCase(
    findKey(rules, (o) => {
      if (rule.rule.key === 'all_users') return
      return find(o, (i) => i.key === rule.rule.key)
    })
  )

  return (
    <div
      className={cn(
        'd-flex align-items-center',
        'flex-wrap',
        styles.ruleGroupItem,
        styles.ruleGroupItemHover
      )}>
      {category && (
        <>
          {category}
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
        </>
      )}
      {rule.getTitle()}
      <Rules rule={rule} />
      <Button
        color=""
        className={cn('p-0 ms-auto', styles.ruleDelete)}
        onClick={() => store.removeRule(rule.id)}>
        <IconDelete />
      </Button>
    </div>
  )
}

export default withStore(SegmentRule)
