import React from 'react'
import cn from 'classnames'
import PreviewMergeTagText from './PreviewMergeTagText'

interface IProps {
  image: string
  name: string
  message: string
  jobTitle: string
  onClick?: () => void
}

const AdminHeader = ({
  image,
  name,
  message,
  jobTitle,
  onClick
}: IProps): React.ReactElement => {
  return (
    <div
      className={cn('personal-message d-flex align-items-center w-100', {
        'cursor-pointer': !!onClick
      })}
      onClick={onClick}>
      {image && (
        <img src={image} className="personal-message__avatar" alt={name} />
      )}
      <div className="flex-fill">
        <p className="m-0">
          <span className="personal-message__name">{name} </span>
          {jobTitle && (
            <span className="personal-message__job">- {jobTitle}</span>
          )}
        </p>
        <div className="personal-message__message mb-0">
          <PreviewMergeTagText text={message} />
        </div>
      </div>
    </div>
  )
}

export default AdminHeader
