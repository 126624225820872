import { createContext, useContext, useRef } from 'react'
import CampaignDashboardStore from './Store/CampaignsDashboardStore'

const CampaignsDashboardContext = createContext<
  CampaignDashboardStore | undefined
>(undefined)

export function CampaignsDashboardProvider({
  children
}: {
  children: React.ReactNode
}): React.ReactElement {
  const store = useRef(new CampaignDashboardStore())

  return (
    <CampaignsDashboardContext.Provider value={store.current}>
      {children}
    </CampaignsDashboardContext.Provider>
  )
}

const useCampaignsDashboardStore = (): CampaignDashboardStore => {
  const context = useContext(CampaignsDashboardContext)
  if (!context) {
    throw new Error('There is no Provider for this context')
  }

  return context
}

export default useCampaignsDashboardStore
