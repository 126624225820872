import React from 'react'
import { Button, ModalBody, ModalFooter } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import {
  EMAIL_FROM_NAMES_LIST_QUERY,
  ERRORS_DICT
} from '../EmailFromNames.interface'
import Modal from '~/components/modals/Modal/Modal'
import Input from '~/components/forms/Input'
import { addFromName } from '~/api/appSettings'
import { getFormServerSideErrors, showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'

interface IProps {
  close: () => void
}

const AddFromName = ({ close }: IProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isDirty }
  } = useForm<{ name: string }>()
  const queryClient = useQueryClient()
  const { appId } = useParams<{ appId: string }>()

  const onSubmit = async (data: { name: string }) => {
    try {
      await addFromName(appId, data.name)
      queryClient.invalidateQueries(EMAIL_FROM_NAMES_LIST_QUERY)
      showNotification('From Name added successfully', NotificationType.SUCCESS)
      close()
    } catch (error: Error & { body: { errors: { email: Array } } }) {
      if (error.body?.errors) {
        getFormServerSideErrors(error.body.errors, ERRORS_DICT).forEach((er) =>
          setError(er.name, { type: 'server', message: er.message })
        )
      } else {
        showGeneralError()
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <Input
          register={register}
          type="text"
          placeholder="Enter Email From Name"
          label="Email From Name"
          className={cn({
            'form-group--error': errors.name
          })}
          validation={{
            required: {
              value: true,
              message: 'From Name is missing'
            }
          }}
          errorTooltip={errors?.name?.message}
          tooltip=""
          name="name"
          aria-label="Email From Name"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="" onClick={close} aria-label="Cancel">
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={isSubmitting || !isDirty}
          aria-label="Save">
          Save
          {isSubmitting && (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-2" />
          )}
        </Button>
      </ModalFooter>
    </form>
  )
}

const AddFromNameModal = (): React.ReactElement => {
  return (
    <Modal
      id="addFromNameModal"
      size="md"
      renderHeader={() => 'New Email From Name'}
      render={(close: () => void) => <AddFromName close={close} />}
    />
  )
}

export default AddFromNameModal
