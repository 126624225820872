import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ID } from '~/common.interface'
import CreatableSelect from '~/components/forms/CreatableSelect'

import { withStore } from '~/dataStore'
import {
  IOption,
  IRegisteredField
} from '~/dataStore/emailBuilder/EmailBuilder.interface'

interface IProps {
  fromNames: Array<{ id: string; name: string }>
  fromName: IRegisteredField
  setFromName: ({ value, label }: { value: string; label: string }) => void
  addFromName: (appId: string, value: string) => void
  validateFromName: () => void
}

const FromNameSelect = ({
  fromNames,
  fromName,
  setFromName,
  addFromName,
  validateFromName
}: IProps) => {
  const { appId } = useParams<{ appId: ID }>()

  const [isLoading, setIsLoading] = useState(false)
  const [names, setNames] = useState<Array<{ value: string; label: string }>>(
    []
  )

  const handleChange = (newValue: IOption, actionMeta: any) => {
    if (actionMeta.action === 'clear') {
      setFromName({ value: '', label: '' })
      return
    }
    setFromName(newValue)
  }

  const handleCreate = async (inputValue: any) => {
    setIsLoading(true)
    await addFromName(appId, inputValue)
    setIsLoading(false)
    validateFromName()
  }

  const onBlur = () => {
    if (!isLoading) {
      validateFromName()
    }
  }

  useEffect(() => {
    setNames(
      fromNames.map((option: { id: string; name: string }) => ({
        value: option.name,
        label: option.name
      }))
    )
  }, [fromNames])

  return (
    <CreatableSelect
      placeholder="Name the email will be sent from.."
      isLoading={isLoading}
      handleCreate={handleCreate}
      onChange={handleChange}
      onBlur={onBlur}
      value={fromName.value.value}
      options={names}
    />
  )
}

export default withStore(FromNameSelect)
