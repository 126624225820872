import cn from 'classnames'
import React from 'react'
import InAppNotification from '~/pages/Campaign/Notification/InAppNotification/Model/InAppNotification'
import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import JourneyEmailNotificationStore from '~/pages/Journeys/JourneyBuilder/JourneyNotificationSidebar/EmailNotificationSidebar/Store/JourneyEmailNotificationStore'
import styles from '../JourneyBuilderSidebar/Sidebar.scss'
import EmailNotificationSidebar from './EmailNotificationSidebar'
import { withStore } from '~/dataStore'
import { INotification } from '../Store/JourneyBuilder.interface'
import PushNotificationSidebar from './PushNotificationSidebar'
import { ID } from '~/common.interface'
import EmailNotificationDetailsPreview from './EmailNotificationSidebar/EmailNotificationDetailsPreview'
import InappNotificationSidebar from './InappNotificationSidebar'
import { NotificationType } from '~/pages/Campaign/Notification/Notification.interface'
import CardNotificationSidebar from './CardNotificationSidebar/CardNotificationSidebar'
import JourneyCard from './CardNotificationSidebar/JourneyCard'

interface IProps {
  isOpen: boolean
  selectedNotification: INotification | null
  close: () => void
  save: (id: ID) => Promise<void>
  cancelCallback: () => void
  disabled: boolean
}

function JourneyNotificationSidebarWrapper({
  selectedNotification,
  disabled,
  close,
  save,
  cancelCallback
}: Omit<IProps, 'isOpen'>): React.ReactElement | null {
  if (!selectedNotification) {
    return null
  }

  if (selectedNotification.type === NotificationType.PUSH) {
    return (
      <PushNotificationSidebar
        disabled={disabled}
        pushNotification={selectedNotification.notification as PushNotification}
        close={close}
        save={() => save(selectedNotification.id)}
        cancelCallback={cancelCallback}
      />
    )
  }

  if (selectedNotification.type === NotificationType.IN_APP) {
    return (
      <InappNotificationSidebar
        disabled={disabled}
        notification={selectedNotification.notification as InAppNotification}
        close={close}
        save={() => save(selectedNotification.id)}
        cancelCallback={cancelCallback}
      />
    )
  }

  if (selectedNotification?.type === NotificationType.EMAIL) {
    return disabled ? (
      <EmailNotificationDetailsPreview
        close={close}
        emailNotification={
          selectedNotification.notification as JourneyEmailNotificationStore
        }
      />
    ) : (
      <div className={cn('h-100 position-relative', styles.sidebarBlock)}>
        <EmailNotificationSidebar
          emailNotification={
            selectedNotification.notification as JourneyEmailNotificationStore
          }
          close={close}
          save={() => save(selectedNotification.id)}
          cancelCallback={cancelCallback}
        />
      </div>
    )
  }

  if (selectedNotification.type === NotificationType.CARD) {
    return (
      <CardNotificationSidebar
        disabled={disabled}
        notification={selectedNotification.notification as JourneyCard}
        close={close}
        save={() => save(selectedNotification.id)}
        cancelCallback={cancelCallback}
      />
    )
  }

  if (selectedNotification.type === NotificationType.SMS) {
    return null
  }

  return null
}

export default withStore(JourneyNotificationSidebarWrapper)
