import React from 'react'

import { withStore, useStore } from '~/dataStore'

import TargetToggle from '../TargetToggle/TargetToggle'

const columns = [
  {
    fieldName: 'name',
    displayName: 'Event name',
    classes: 'text-truncate'
  }
]

function Events() {
  const {
    app: {
      currentApp: { id: appId }
    },
    campaign: {
      isSended,
      targeting: {
        validateTargetingChoosenOptions,
        events: {
          collection,
          fetchEvents,
          selected,
          selectEvent,
          searchQuery,
          setSearchQuery,
          totalCount,
          isValid
        }
      }
    }
  } = useStore()

  return (
    <TargetToggle
      fetchResources={() => fetchEvents(appId)}
      disabled={isSended}
      searchPlaceholder="Search for an event"
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      columns={columns}
      rows={collection}
      selectedRows={selected}
      selectRow={(row: any, selected: boolean) => {
        selectEvent(row, selected)
        if (selected) validateTargetingChoosenOptions()
      }}
      totalCount={totalCount}
      invalid={!isValid}
    />
  )
}

export default withStore(Events)
