import React from 'react'
import './InAppLargeAnimatedIcon.scss'

const InAppLargeAnimatedIcon = ({
  className
}: {
  className: string
}): React.ReactElement => (
  <svg
    id="eJdNwaOsyZc1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 206 206"
    width={206}
    height={206}
    className={className}
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision">
    <g transform="translate(-.5 0)">
      <path d="M2,0h203v206h-203L2,0Z" fill="#fff" />
      <g mask="url(#eJdNwaOsyZc6)">
        <path d="M203,0v206h4v-206h-4ZM4,206L4,0h-4v206h4Z" fill="#93a8c5" />
        <mask
          id="eJdNwaOsyZc6"
          mask-type="luminance"
          x="-150%"
          y="-150%"
          height="400%"
          width="400%">
          <rect width="207" height="206" rx="0" ry="0" fill="#fff" />
          <path d="M2,0h203v206h-203L2,0Z" />
        </mask>
      </g>
      <path
        d="M15,0h177.204v206L15,206L15,0Z"
        transform="translate(0-.243989)"
        fill="#bfcbdd"
      />
      <g mask="url(#eJdNwaOsyZc12)">
        <path
          d="M190.204,0v206h4v-206h-4ZM17,206L17,0h-4v206h4Z"
          fill="#93a8c5"
        />
        <mask
          id="eJdNwaOsyZc12"
          mask-type="luminance"
          x="-150%"
          y="-150%"
          height="400%"
          width="400%">
          <rect
            width="182"
            height="206"
            rx="0"
            ry="0"
            transform="translate(13 0)"
            fill="#fff"
          />
          <path d="M15,0h177.204v206L15,206L15,0Z" />
        </mask>
      </g>
      <g id="eJdNwaOsyZc15_to" transform="translate(174,79)">
        <g id="eJdNwaOsyZc15_tr" transform="rotate(0)">
          <g id="eJdNwaOsyZc15" transform="translate(-174,-55)" opacity="0">
            <circle
              r="9"
              transform="translate(174 55)"
              fill="#fff"
              stroke="#93a8c5"
              strokeWidth="2"
            />
            <g clipPath="url(#eJdNwaOsyZc20)">
              <g>
                <path
                  d="M175.516,54.7142l2.275-2.2337c.279-.2741.279-.7185,0-.9928l-.506-.4965c-.279-.2741-.731-.2741-1.011,0L174,53.2249l-2.274-2.2337c-.279-.2741-.732-.2741-1.011,0l-.506.4965c-.279.2741-.279.7185,0,.9928l2.275,2.2337-2.275,2.2337c-.279.2741-.279.7185,0,.9929l.506.4964c.279.2741.732.2741,1.011,0L174,56.2035l2.274,2.2337c.279.2741.732.2741,1.011,0l.506-.4964c.279-.2741.279-.7185,0-.9929l-2.275-2.2337Z"
                  fill="#93a8c5"
                />
              </g>
              <clipPath id="eJdNwaOsyZc20">
                <rect
                  width="8"
                  height="11.4286"
                  rx="0"
                  ry="0"
                  transform="translate(170 49)"
                  fill="#fff"
                />
              </clipPath>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g id="eJdNwaOsyZc23_to" transform="translate(236.616323,23.616323)">
          <path
            d="M36,15.5h42c4.6944,0,8.5,3.8056,8.5,8.5s-3.8056,8.5-8.5,8.5h-42c-4.6944,0-8.5-3.8056-8.5-8.5s3.8056-8.5,8.5-8.5Z"
            transform="translate(-57,-24)"
            fill="#93a8c5"
            stroke="#93a8c5"
          />
        </g>
        <g id="eJdNwaOsyZc24_to" transform="translate(306,23.616323)">
          <path
            d="M105,15.5h42c4.694,0,8.5,3.8056,8.5,8.5s-3.806,8.5-8.5,8.5h-42c-4.694,0-8.5-3.8056-8.5-8.5s3.806-8.5,8.5-8.5Z"
            transform="translate(-126,-24)"
            fill="#93a8c5"
            stroke="#93a8c5"
          />
        </g>
        <g id="eJdNwaOsyZc25_to" transform="translate(359,24)">
          <path
            d="M174,15.5h18.5v17h-18.5c-4.694,0-8.5-3.8056-8.5-8.5s3.806-8.5,8.5-8.5Z"
            transform="translate(-179,-24)"
            fill="#93a8c5"
            stroke="#93a8c5"
          />
        </g>
      </g>
      <g id="eJdNwaOsyZc26_to" transform="translate(103.602,276.5)">
        <g id="eJdNwaOsyZc26" transform="translate(-104,-136.5)" opacity="0">
          <g mask="url(#eJdNwaOsyZc30)">
            <path
              d="M24,75c0-3.3137,2.6863-6,6-6h148c3.314,0,6,2.6863,6,6v131h-160v-131Z"
              fill="#fff"
            />
            <path
              d="M22,75c0-4.4183,3.5817-8,8-8h148c4.418,0,8,3.5817,8,8h-4c0-2.2091-1.791-4-4-4h-148c-2.2091,0-4,1.7909-4,4h-4ZM184,206h-160h160ZM22,206v-131c0-4.4183,3.5817-8,8-8v4c-2.2091,0-4,1.7909-4,4v131h-4ZM178,67c4.418,0,8,3.5817,8,8v131h-4v-131c0-2.2091-1.791-4-4-4v-4Z"
              fill="#93a8c5"
            />
            <mask
              id="eJdNwaOsyZc30"
              mask-type="luminance"
              x="-150%"
              y="-150%"
              height="400%"
              width="400%">
              <path
                d="M24,75c0-3.3137,2.6863-6,6-6h148c3.314,0,6,2.6863,6,6v131h-160v-131Z"
                fill="#fff"
              />
            </mask>
          </g>
          <path
            d="M32.7188,157.2c0-1.105.8954-2,2-2h139.2402c1.104,0,2,.895,2,2v4.86c0,1.104-.896,2-2,2h-139.2402c-1.1046,0-2-.896-2-2v-4.86Z"
            fill="#93a8c5"
          />
          <path
            d="M33,173c0-1.105.8954-2,2-2h139c1.105,0,2,.895,2,2v5c0,1.105-.895,2-2,2h-139c-1.1046,0-2-.895-2-2v-5Z"
            fill="#93a8c5"
          />
          <path
            d="M33,188c0-1.105.8954-2,2-2h67c1.105,0,2,.895,2,2v5c0,1.105-.895,2-2,2h-67c-1.1046,0-2-.895-2-2v-5Z"
            fill="#93a8c5"
          />
          <line
            x1="26"
            y1="146"
            x2="182"
            y2="146"
            fill="none"
            stroke="#93a8c5"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default InAppLargeAnimatedIcon
