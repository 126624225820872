import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useStore, withStore } from '~/dataStore'
import JourneyStore from './Store/JourneyStore'

const JourneyContext = createContext<undefined | JourneyStore>(undefined)

function JourneyProvider({
  children
}: PropsWithChildren<any>): React.ReactElement | null {
  const { app } = useStore()

  const { mode } = useParams<{ mode: 'builder' | 'details' }>()

  if (!app.currentApp.id) {
    return null
  }

  const firstRun = useRef(true)
  const [store, setStore] = useState(
    new JourneyStore(app.appDetails, mode === 'details')
  )
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    setStore(new JourneyStore(app.appDetails, mode === 'details'))
  }, [app.currentApp.id])

  return (
    <JourneyContext.Provider value={store}>{children}</JourneyContext.Provider>
  )
}

export const JourneyProviderWithStore = withStore(JourneyProvider)

const useJourneyStore = (): JourneyStore => {
  const context = useContext(JourneyContext)
  if (!context) {
    throw new Error('There is no Provider for this context')
  }

  return context
}

export default useJourneyStore
