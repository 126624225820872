import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Collapse } from 'reactstrap'
import style from '~/components/Sidebar/style.scss'

interface IProps {
  name: string
  badgeColor: string
  badgeText: string
  icon: string
  customIcon: string
  isOpen: boolean
  children: React.ReactNode | React.ReactNode[]
  onClick: () => void
  to: string
  dataTestId: string
}

const SidebarCategory = ({
  name,
  badgeColor,
  badgeText,
  icon,
  customIcon,
  isOpen,
  children,
  onClick,
  to,
  dataTestId
}: IProps): React.ReactElement => {
  return (
    <li
      data-testid={dataTestId}
      className={cn('sidebar-item sidebar-item-category')}
      aria-label={`Sidebar category: ${name}`}>
      <span
        data-toggle="collapse"
        className={cn('sidebar-link', { collapsed: !isOpen })}
        onClick={onClick}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-label={`Toggle ${name}`}>
        {customIcon && (
          <img
            className="me-2"
            src={customIcon}
            alt={name}
            aria-label={`Custom icon for ${name}`}
          />
        )}
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className={cn('align-middle', 'me-2')}
            aria-label={`Icon for ${name}`}
          />
        )}{' '}
        <span className="align-middle" aria-label={`Name: ${name}`}>
          {name}
        </span>
        {badgeColor && badgeText ? (
          <Badge
            color={badgeColor}
            size={18}
            pill
            className={cn('ms-3', style.badge)}
            aria-label={`Badge: ${badgeText}`}>
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen} aria-label={`Collapse for ${name}`}>
        <ul
          id="item"
          className={cn('sidebar-dropdown', 'list-unstyled')}
          aria-label={`Children of ${name}`}>
          {children}
        </ul>
      </Collapse>
    </li>
  )
}

export default SidebarCategory
