import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment-timezone'

import {
  DEFAULT_DELIVER_TIME_FRAME,
  DELIVERY
} from '~/pages/CampaignBuilder/Email/consts'
import {
  DEFAULT_TIMEZONE,
  ExpiryType,
  ITimeWindow,
  ValidationTypes
} from '../EmailBuilder.interface'
import {
  formatToTimeZone,
  formatToUTC,
  getUTC,
  prepareDeliverTimeWindows,
  unwrapDeliverTimeWindows
} from '~/helpers/deliverTimeWindows'
import StepStore from '../StepStore'
import NewRegisteredField from '../RegisteredField.model'
import Targeting from '../Targeting/Targeting'
import DeliverUI from './DeliverUI'
import {
  ICampaign,
  ICampaignModel,
  IDeliveryPayload
} from '~/dataStore/Campaign/Campaign.interface'

export default class Deliver extends StepStore {
  target: Targeting

  deliverUi: DeliverUI

  delivery = new NewRegisteredField<string>('')

  limits = new NewRegisteredField<string | boolean>('')

  startNow = new NewRegisteredField<string | boolean>('')

  startAt = new NewRegisteredField(
    moment()
      .set('minutes', Math.ceil(moment().minutes() / 5) * 5)
      .toDate()
  )

  endAt = new NewRegisteredField<Date | null>(null, true)

  public endAtActive = false

  timeFrame = new NewRegisteredField(DEFAULT_DELIVER_TIME_FRAME)

  timeValue = new NewRegisteredField('1')

  timeWindowsActive = false

  timeWindows = new NewRegisteredField<ITimeWindow[]>([])

  public expiryType: ExpiryType = ExpiryType.NEVER

  campaignExpiry = false

  expiryDate = new NewRegisteredField<Date | null>(null)

  expiryTimeValue = new NewRegisteredField<string | number | undefined>(1)

  expiryTimeFrame = new NewRegisteredField<string | undefined>('weeks')

  public setExpiryType(value: ExpiryType): void {
    this.expiryType = value
  }

  timeZoneName = new NewRegisteredField(DEFAULT_TIMEZONE)

  timeZoneOffset = moment.tz(DEFAULT_TIMEZONE).format('Z')

  constructor(campaign: ICampaignModel, targeting: Targeting) {
    super()
    makeObservable(this, {
      target: observable,
      deliverUi: observable,
      delivery: observable,
      limits: observable,
      startNow: observable,
      startAt: observable,
      endAt: observable,
      endAtActive: observable,
      timeFrame: observable,
      timeValue: observable,
      timeWindowsActive: observable,
      timeWindows: observable,
      expiryType: observable,
      campaignExpiry: observable,
      expiryDate: observable,
      expiryTimeValue: observable,
      expiryTimeFrame: observable,
      setExpiryType: action.bound,
      timeZoneName: observable,
      timeZoneOffset: observable,
      setDeliveryCurrent: action.bound,
      setDeliveryCurrentFuture: action.bound,
      setLimits: action.bound,
      setStartNow: action.bound,
      setStartAt: action.bound,
      setEndAt: action.bound,
      setEndAtActive: action.bound,
      setTimeFrame: action.bound,
      setTimeValue: action.bound,
      setTimeWindows: action.bound,
      timeWindowsTotal: computed,
      setTimeWindowsActive: action.bound,
      setExpiryDate: action.bound,
      setExpiryTimeValue: action.bound,
      setExpiryTimeFrame: action.bound,
      setTimeZoneName: action.bound,
      setTimeZoneOffset: action.bound,
      isCampaignStartingEndingSameDay: computed,
      validateDelivery: action.bound,
      validateCampaignActivation: action.bound,
      validateCampaignLimits: action.bound,
      validateTimeValue: action.bound,
      validateTimeWindows: action.bound,
      validateStartAt: action.bound,
      validateEndAt: action.bound,
      validateExpiryDate: action.bound,
      validateTimeZone: action.bound,
      registeredFields: computed,
      validateStep: action
    })

    this.target = targeting
    this.deliverUi = new DeliverUI(campaign, this.target, this)

    if (campaign.isCardOrInAppCampaign) {
      this.campaignExpiry = true
      this.expiryType = ExpiryType.DATE
    }
  }

  setDeliveryCurrent(): void {
    this.delivery.value = DELIVERY.CURRENT
  }

  setDeliveryCurrentFuture(): void {
    this.delivery.value = DELIVERY.CURRENT_FUTURE
  }

  setLimits(value: 'true' | 'false'): void {
    this.limits.value = value === 'true'
  }

  setStartNow(value: 'true' | 'false'): void {
    this.startNow.setValue(value === 'true')
  }

  setStartAt(value: Date): void {
    this.startAt.value = value
  }

  setEndAt(value: Date | null): void {
    this.endAt.value = value
  }

  setEndAtActive(value: boolean): void {
    this.endAtActive = value
  }

  setTimeFrame(value: string): void {
    this.timeFrame.value = value
  }

  setTimeValue(e: React.ChangeEvent<HTMLInputElement>): void {
    this.timeValue.value = e.target.value
  }

  setTimeWindows(value: ITimeWindow[]): void {
    this.timeWindows.value = value
  }

  get timeWindowsTotal(): number | undefined {
    return this.timeWindows.value?.length
  }

  setTimeWindowsActive(value: boolean): void {
    this.timeWindowsActive = value
  }

  setExpiryDate(value: Date | null): void {
    this.expiryDate.value = value
  }

  setExpiryTimeValue(e: React.ChangeEvent<HTMLInputElement>): void {
    this.expiryTimeValue.value = e.target.value
  }

  setExpiryTimeFrame(value: string): void {
    this.expiryTimeFrame.value = value
  }

  setTimeZoneName(value: string | undefined): void {
    this.timeZoneName.setValue(value || '')
    this.setTimeZoneOffset(moment.tz(value || '').format('Z'))
  }

  setTimeZoneOffset(value: string): void {
    this.timeZoneOffset = value
  }

  get isCampaignStartingEndingSameDay(): boolean {
    return !!(
      this.startAt.value &&
      this.endAt.value &&
      moment(this.startAt.value).isSame(moment(this.endAt.value), 'day')
    )
  }

  public validateDelivery(): void {
    if (!this.target.isSegmentsOnlyActiveTarget) return

    this.delivery.validate(
      'Please choose which users can receive this campaign'
    )
  }

  public validateCampaignActivation(): void {
    this.startNow.resetError()
    if (!this.deliverUi.isStartPanelCollapsed) return

    if (this.startNow.value === '') {
      this.startNow.isValid = false
      this.startNow.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'Please choose when the campaign should be activated'
      })
    }
  }

  public validateCampaignLimits(): void {
    this.limits.resetError()
    if (!this.deliverUi.isDeliveryFutureOrDefault) return

    if (this.limits.value === '') {
      this.limits.isValid = false
      this.limits.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'Please choose how often users can receive this campaign'
      })
    }
  }

  public validateTimeValue(): void {
    this.timeValue.resetError()
    if (!this.deliverUi.isDeliveryFutureOrDefault || !this.limits.value) return

    this.timeValue.validate(
      'Please choose how often we can send out campaigns '
    )
  }

  public validateTimeWindows(): void {
    this.timeWindows.resetError()
    if (!this.timeWindowsActive || this.deliverUi.isDeliveryCurrent) return

    if (this.timeWindows.value.some((timeWindow) => !timeWindow.weekDay)) {
      this.timeWindows.isValid = false
      this.timeWindows.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'Please select a day or range for every "Select day" drop-down'
      })
    }
  }

  public validateStartAt(): void {
    this.startAt.resetError()
    if (!this.deliverUi.isStartEndSectionCollapsed) return

    if (
      formatToUTC(this.startAt.value, this.timeZoneName.value).isBefore(
        getUTC()
      )
    ) {
      this.startAt.isValid = false
      this.startAt.errors.push({
        type: ValidationTypes.REQUIRED,
        message: "The start of the campaign can't be in the past"
      })
    }
  }

  public validateEndAt(): void {
    this.endAt.resetError()

    if (
      this.deliverUi.isEndDate &&
      (!this.endAt.value || this.endAt.value < this.startAt.value)
    ) {
      this.endAt.isValid = false
      this.endAt.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'Please choose valid end date'
      })
    }
  }

  public validateExpiryDate(): void {
    this.expiryDate.resetError()
    if (
      this.expiryType !== ExpiryType.DATE ||
      !this.deliverUi.isExpiryDateSectionCollapsed
    )
      return

    if (
      !this.expiryDate.value ||
      formatToUTC(this.expiryDate.value, this.timeZoneName.value).isBefore(
        getUTC()
      ) ||
      (this.deliverUi.isStartEndSectionCollapsed &&
        this.expiryDate.value < this.startAt.value)
    ) {
      this.expiryDate.isValid = false
      this.expiryDate.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'Please choose valid expiry date'
      })
    }
  }

  public validateTimeZone(): void {
    this.timeZoneName.resetError()

    if (!this.timeZoneName.value) {
      this.timeZoneName.isValid = false
      this.timeZoneName.errors.push({
        type: ValidationTypes.REQUIRED,
        message: 'Please select Time Zone'
      })
    }
  }

  get registeredFields(): NewRegisteredField[] {
    return [
      this.timeZoneName,
      this.target?.isSegmentsOnlyActiveTarget ? this.delivery : undefined,
      this.deliverUi?.isStartPanelCollapsed ? this.startNow : undefined,
      this.deliverUi?.isDeliveryFutureOrDefault ? this.limits : undefined,
      this.timeWindowsActive && !this.deliverUi?.isDeliveryCurrent
        ? this.timeWindows
        : undefined,
      this.deliverUi?.isStartEndSectionCollapsed ? this.startAt : undefined,
      this.deliverUi?.isEndDate ? this.endAt : undefined,
      this.deliverUi?.isDeliveryFutureOrDefault && this.limits.value
        ? this.timeFrame
        : undefined,
      this.deliverUi?.isDeliveryFutureOrDefault && this.limits.value
        ? this.timeValue
        : undefined,
      this.expiryType === ExpiryType.DATE ? this.expiryDate : undefined,
      this.expiryType === ExpiryType.AMOUNT ? this.expiryTimeFrame : undefined,
      this.expiryType === ExpiryType.AMOUNT ? this.expiryTimeValue : undefined
    ].filter(Boolean) as NewRegisteredField[]
  }

  public validateStep(): void {
    this.validateTimeZone()
    this.validateDelivery()
    this.validateCampaignActivation()
    this.validateCampaignLimits()
    this.validateTimeWindows()
    this.validateStartAt()
    this.validateEndAt()
    this.validateTimeValue()
    this.validateExpiryDate()

    this.beenValid = true
  }

  public fillStore(data: ICampaign): void {
    this.startNow.fillField({ value: data.startNow })

    if (data.startAt) {
      this.startAt.fillField({
        value: formatToTimeZone(data.startAt, data.timeZoneName)
      })
    }
    if (data.endAt) {
      this.endAt.fillField({
        value: formatToTimeZone(data.endAt, data.timeZoneName)
      })
      this.setEndAtActive(true)
    }
    if (data.timeWindows?.length) {
      this.timeWindows.fillField({
        value: unwrapDeliverTimeWindows(data.timeWindows)
      })
      this.setTimeWindowsActive(true)
    }

    this.campaignExpiry = !!data.campaignExpiry

    this.timeFrame.fillField({ value: data.timeFrame ?? this.timeFrame.value })
    this.timeValue.fillField({ value: data.timeValue ?? this.timeValue.value })
    this.setTimeZoneName(data.timeZoneName || this.timeZoneName.value)
    this.delivery.fillField({ value: data.delivery })
    this.limits.fillField({ value: data.campaignLimits })

    if (data.campaignExpiry) {
      this.expiryDate.fillField({
        value:
          (data.expiryDate &&
            formatToTimeZone(data.expiryDate, this.timeZoneName.value)) ||
          null
      })
      this.expiryTimeFrame.fillField({
        value: data.expiryTimeFrame?.includes('minute')
          ? 'weeks'
          : data.expiryTimeFrame
      })
      this.expiryTimeValue.fillField({ value: data.expiryTimeValue })

      if (data.expiryDate) {
        this.setExpiryType(ExpiryType.DATE)
      } else if (data.expiryTimeValue) {
        this.setExpiryType(ExpiryType.AMOUNT)
      }
    } else {
      this.setExpiryType(ExpiryType.NEVER)
    }
  }

  public getPayload(asDraft?: boolean): IDeliveryPayload {
    // set Delivery as future if target is not segment only
    let delivery = this.target.isSegmentsOnlyActiveTarget
      ? this.delivery.value
      : ''

    let campaignLimits = this.deliverUi.isDeliveryFutureOrDefault
      ? this.limits.value
      : ''

    if (!asDraft) {
      if (!delivery) delivery = DELIVERY.CURRENT_FUTURE

      if (!campaignLimits) campaignLimits = false
    }

    let expiry = {}
    if (this.expiryType === ExpiryType.DATE && this.expiryDate.value) {
      expiry = {
        expiryDate: formatToUTC(
          this.expiryDate.value,
          this.timeZoneName.value
        ).format('YYYY-MM-DD[T]HH:mm:ss')
      }
    } else if (
      this.expiryType === ExpiryType.AMOUNT &&
      this.expiryTimeFrame.value
    ) {
      expiry = {
        expiryTimeFrame: this.expiryTimeFrame.value,
        expiryTimeValue: this.expiryTimeValue.value
      }
    }

    return {
      startNow: this.startNow.value,
      startAt: this.startNow.value
        ? moment.utc().format('YYYY-MM-DD[T]HH:mm:ss')
        : formatToUTC(this.startAt.value, this.timeZoneName.value).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          ),
      endAt: this.deliverUi.isEndDate
        ? formatToUTC(this.endAt.value, this.timeZoneName.value).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        : '',
      ...(this.deliverUi.isTimeWindows && {
        timeWindows: prepareDeliverTimeWindows(this.timeWindows.value)
      }),
      // backend wont accept 'week' value
      timeFrame:
        this.timeFrame.value === DEFAULT_DELIVER_TIME_FRAME
          ? 'weeks'
          : this.timeFrame.value,
      timeValue: this.limits.value ? this.timeValue.value : '',
      timeZoneName: this.timeZoneName.value,
      timeZoneOffset: this.timeZoneOffset,
      delivery,
      campaignLimits,
      campaignExpiry: this.expiryType !== ExpiryType.NEVER,
      ...expiry
    }
  }
}
