import { action, observable, makeObservable } from 'mobx'
import {
  ICardInAppPayload,
  PreviewFieldType,
  PreviewParts
} from '~/dataStore/Campaign/Campaign.interface'
import {
  IRegisteredField,
  ValidationTypes
} from '../../../../../dataStore/emailBuilder/EmailBuilder.interface'
import NewRegisteredField from '../../../../../dataStore/emailBuilder/RegisteredField.model'
import imagePlaceholder from '~/components/icons/image_placeholder.svg'
import ToggleDNDRegisteredField, {
  IToggleDNDField
} from '~/dataStore/emailBuilder/Fields/ToggleDND.field'
import ImageField from '../../../../../dataStore/emailBuilder/Fields/Image.field'
import { convertFileToURL } from '~/utils/file.service'
import { hasInvalidBuilderCharacters } from '~/utils/validations'
import { IAdmin } from '~/pages/AccountSettings/AccountSettings.interface'
import { IInappCardDTO } from '../../Notification.interface'
import CTAGroup from '~/dataStore/emailBuilder/Fields/CTAGroup.field'
import { ButtonCategory } from '~/dataStore/App.interface'

export type DNDFields = 'headline' | 'image' | 'text'
export type ToggleFields = DNDFields | 'adminHeader'
export type InAppFields = ToggleFields | 'actionButtons'

export default class InAppLarge {
  public fieldOption = new NewRegisteredField<boolean>(true)

  admin: IAdmin | undefined

  public adminHeader: IToggleDNDField<string>

  public headline: IToggleDNDField<string>

  public image: ImageField = new ImageField({
    errorMessage: 'You must upload an Image or turn off section',
    active: true,
    position: 0,
    value: '',
    fileName: ''
  })

  public text: IToggleDNDField<string>

  public actionButtons = new CTAGroup()

  constructor(public appId: string, fieldErrorMessage?: string) {
    makeObservable(this, {
      admin: observable,
      adminHeader: observable,
      headline: observable,
      image: observable,
      text: observable,
      actionButtons: observable,
      setAdmin: action.bound,
      validateFieldOption: action.bound,
      validateAdminHeaderInvalidChars: action.bound,
      replaceFieldPosition: action.bound
    })

    this.setButtonsCategory = this.setButtonsCategory.bind(this)

    this.setButtonsCategory('1')

    this.adminHeader = new ToggleDNDRegisteredField<string>('')
    this.adminHeader.setErrorMessage(
      'You must create a Personal Message or turn off section'
    )
    this.adminHeader.setPosition(-1)

    this.headline = new ToggleDNDRegisteredField<string>('')
    this.headline.setErrorMessage(
      'You must create a Headline or turn off section'
    )
    this.headline.setPosition(1)

    this.text = new ToggleDNDRegisteredField<string>('')
    this.text.setErrorMessage('You must add your Text or turn off section')
    this.text.setPosition(2)

    this.fieldOption.setErrorMessage(
      fieldErrorMessage || 'You must select at least one In-App section'
    )
  }

  public get formfields(): any[] {
    return [this.adminHeader, this.headline, this.image, this.text]
  }

  public setAdmin(admin?: IAdmin): void {
    this.admin = admin ? { ...admin, id: admin.id || admin.sId } : undefined
  }

  public validateFieldOption(): void {
    this.fieldOption.resetError()
    if (this.formfields.every((field) => !field.isActive)) {
      this.fieldOption.isValid = false
      this.fieldOption.errors.push({
        type: ValidationTypes.REQUIRED,
        message: this.fieldOption.defaultErrorMessage
      })
    }
  }

  public validateAdminHeaderInvalidChars(): boolean {
    if (!this.adminHeader.isActive) return true

    if (
      this.adminHeader.isValid &&
      hasInvalidBuilderCharacters(this.adminHeader.value)
    ) {
      this.adminHeader.setError(
        ValidationTypes.FORMAT,
        `Personal Message: The following characters are not allowed: '<', '>', '[', ']'.`
      )
    }

    return this.adminHeader.isValid
  }

  public validateStep(): void {
    this.validateFieldOption()
    this.adminHeader.validate()
    this.validateAdminHeaderInvalidChars()
    this.headline.validate()
    this.image.validate()
    this.text.validate()
    this.actionButtons.validate()
    this.actionButtons.validateLabels()
  }

  public isValidField(fieldName: InAppFields): boolean {
    return this[fieldName].isValid
  }

  public get registeredFields(): IRegisteredField[] {
    return [
      this.fieldOption,
      this.adminHeader,
      this.headline,
      this.text,
      this.image.field,
      ...this.actionButtons.registeredFields
    ].filter(Boolean) as IRegisteredField[]
  }

  public fillStore(data: IInappCardDTO | ICardInAppPayload): void {
    this.adminHeader.fillField({
      value: data.adminHeaderWithMessage.message,
      isActive: data.adminHeaderWithMessage.active
    })
    this.setAdmin(data.adminHeaderWithMessage.admin as IAdmin)

    this.headline.fillField({
      value: data.headline.text,
      isActive: data.headline.active,
      position: data.headline.position
    })
    this.text.fillField({
      value: data.text.text,
      isActive: data.text.active,
      position: data.text.position
    })

    this.setButtonsCategory(`${data.callToAction.buttons.length}`)
    this.actionButtons.fillField({
      isActive: true,
      buttons: data.callToAction.buttons
    })

    this.image.fillField({
      value: data.image.url,
      fileName: data.image.name,
      isActive: data.image.active,
      position: data.image.position
    })
  }

  public fillAdmin(message: string, admin: IAdmin | undefined): void {
    this.adminHeader.fillField({
      value: message
    })

    this.setAdmin(admin)
  }

  private notifyChange(): void {
    // Trigers autosave
    this.fieldOption.setValue(!this.fieldOption.value)
  }

  public reset(fieldName: ToggleFields): void {
    this.setActive(fieldName, false)
  }

  public setActive(fieldName: ToggleFields, value: boolean): void {
    if (['adminHeader', 'headline', 'image', 'text'].includes(fieldName)) {
      this[fieldName].setActive(value)
      this.notifyChange()
    }
  }

  public replaceFieldPosition(destination: DNDFields, source: DNDFields): void {
    const destinationPosition = this[destination].position
    const sourcePosition = this[source].position
    this[destination].setPosition(sourcePosition)
    this[source].setPosition(destinationPosition)

    this.notifyChange()
  }

  public setButtonsCategory(value: string): void {
    let buttonCategory: ButtonCategory | undefined
    switch (value) {
      case '1':
        buttonCategory = {
          id: '1',
          buttonLabels: ['']
        }
        break
      case '2':
        buttonCategory = {
          id: '2',
          buttonLabels: ['', '']
        }
        break
      default:
        buttonCategory = undefined
    }

    this.actionButtons.selectGroup(buttonCategory)
    this.actionButtons.validateCategory()
  }

  private async convertRichMediaFileToURL(): Promise<void> {
    if (this.image.file) {
      const response = await convertFileToURL(this.image.file, this.appId)
      if (response) {
        URL.revokeObjectURL(this.image.value)
        this.image.setValue(response)
        this.image.setFile(null)
      }
    }
  }

  public async getPayload(): Promise<IInappCardDTO> {
    await this.convertRichMediaFileToURL()

    const payload: IInappCardDTO = {
      adminHeaderWithMessage: {
        side: 'front',
        position: this.adminHeader.position,
        active: this.adminHeader.isActive,
        message: this.adminHeader.value,
        admin: {
          jobTitle: this.admin?.jobTitle || null,
          name: this.admin?.name || null,
          avatarUrl: this.admin?.avatarUrl || null,
          sId: this.admin?.id || null
        }
      },
      image: {
        side: 'front',
        name: this.image.name,
        active: this.image.isActive,
        position: this.image.position,
        url: this.image.value
      },
      headline: {
        side: 'front',
        active: this.headline.isActive,
        text: this.headline.value,
        position: this.headline.position
      },
      text: {
        side: 'front',
        active: this.text.isActive,
        text: this.text.value,
        position: this.text.position
      },
      callToAction: {
        // active: this.actionButtons.isActive,
        buttons: this.actionButtons.payload,
        side: 'front'
      }
    }

    return payload
  }

  public generatePreview(): PreviewParts {
    const parts = []

    let anyIsActive = false

    if (this.adminHeader.isActive && this.admin) {
      anyIsActive = true
      const { admin } = this
      parts.push({
        position: this.adminHeader.position,
        type: PreviewFieldType.ADMIN_HEADER,
        admin: {
          jobTitle: admin.jobTitle,
          name: admin.name,
          avatarUrl: admin.avatarUrl
        },
        value:
          this.adminHeader.value ||
          'Hello, advertise your campaign with a personal message!'
      })
    }

    if (this.image.isActive) {
      anyIsActive = true
      parts.push({
        position: this.image.position,
        type: PreviewFieldType.IMAGE,
        value: this.image.value || imagePlaceholder
      })
    }

    if (this.headline.isActive) {
      anyIsActive = true
      parts.push({
        position: this.headline.position,
        type: PreviewFieldType.HEADLINE,
        value: this.headline.value || 'Grab your users attention'
      })
    }

    if (this.text.isActive) {
      anyIsActive = true
      parts.push({
        position: this.text.position,
        type: PreviewFieldType.TEXT,
        value:
          this.text.value ||
          'Add 1 or 2 extra lines to make your notification tempting and tap! '
      })
    }

    if (anyIsActive) {
      parts.push({
        position: 3,
        type: PreviewFieldType.BUTTON,
        value: this.actionButtons.buttons.map((button) => ({
          label: button.value || 'Button Text',
          txtColor: button.txtColor.value,
          btnColor: button.btnColor.value
        }))
      })
    }

    return {
      parts: parts.sort((a, b) => a.position - b.position)
    }
  }
}
