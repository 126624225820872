import cn from 'classnames'

import { ALL_DAYS_OPTIONS_LIST } from '~/pages/CampaignBuilder/Email/consts'

import Select from '~/components/forms/Select'

import styles from './style.scss'

interface IProps {
  onChange: (value: string) => void
  options: { name: string; value: any }[]
  selected?: string
  disabled?: boolean
}

const SelectDays = ({
  onChange,
  selected,
  options,
  disabled
}: IProps): React.ReactElement => {
  return (
    <Select
      disabled={disabled}
      selectedOption={selected || 'Select day'}
      className={cn(styles.selectDaysWrapper, 'me-3 px-4')}
      options={options || ALL_DAYS_OPTIONS_LIST}
      onSelect={onChange}
    />
  )
}

export default SelectDays
