import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import cn from 'classnames'
import { useQueryClient } from '@tanstack/react-query'
import Modal from '~/components/modals/Modal/Modal'
import { useStore } from '~/dataStore'
import hint from './hint.svg'
import hintMemberId from './hintMemberId.svg'
import DownloadLink from '~/components/DownloadLink'
import styles from './UsersCsvUpload.scss'
import UploadButton from '~/components/UploadButton'
import { uploadUsers } from '~/api/users'
import CSVErrorsModal from './CSVErrorsModal'
import { showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'
import { IUploadUsersDTO } from '~/pages/Users/Users.interface'
import { ServerStateKeysEnum } from '../../useUsersList'
import ToggleSwitch from '~/components/forms/ToggleSwitch'
import Input from '~/components/forms/Input'
import CustomTagInput from '~/pages/Segment/CreateSegmentFromCSVModal/components/CustomTagInput'
import ConfirmationModal from '~/components/modals/ConfirmationModal'

type FormValues = {
  segmentName: string
  customTag: string
}

const ModalContent = ({
  closeModal,
  segmentName,
  customTag,
  segmentFormActive
}: {
  closeModal: () => void
  segmentName?: string
  customTag?: string
  segmentFormActive?: boolean
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    ui: { showModal },
    app: {
      appDetails: {
        featureFlags: { coreMemberIdUpload }
      },
      currentApp: { id: appId }
    }
  } = useStore()
  const queryClient = useQueryClient()
  const {
    register,
    setError,
    getValues,
    control,
    reset,
    formState: { errors }
  } = useForm<FormValues>()
  const [showSegmentForm, toggleSegmentForm] = useState<boolean>(true)
  const handleUploadErrors = (error: Error & { body: any }) => {
    if (error.body?.errors) {
      if ('file' in error.body?.errors) {
        showNotification(error.body.errors.file[0], NotificationType.ERROR)
        return
      }

      if ('rows' in error.body?.errors) {
        closeModal()
        showModal('CSVErrorsModal', {
          errors: error.body.errors.rows,
          csvDownloadLink: error.body.errors.link,
          segmentFormActive: showSegmentForm,
          ...getValues()
        })
      } else {
        Object.keys(error.body.errors).forEach((key) => {
          setError(key, { type: 'server', message: error.body.errors[key][0] })
        })
      }
    } else {
      showGeneralError()
    }
  }

  const handleUploadSuccess = (response: IUploadUsersDTO) => {
    const { upload } = response
    queryClient.invalidateQueries([ServerStateKeysEnum.Users])
    showModal('uploadSuccessModal', { successText: upload })
  }

  const uploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return
    const { segmentName, customTag } = getValues()
    try {
      setIsLoading(true)
      const data = new FormData()
      data.append('file', e.target.files[0], e.target.files[0].name)
      data.append('create_segment', showSegmentForm.toString())
      if (showSegmentForm) {
        data.append('segment_name', segmentName || '')
        data.append('custom_tag', customTag || '')
      }
      const response = await uploadUsers(appId, data)
      handleUploadSuccess(response)
    } catch (error: Error & { body: any }) {
      handleUploadErrors(error)
    } finally {
      setIsLoading(false)
      e.target.value = ''
    }
  }

  useEffect(() => {
    reset({
      segmentName,
      customTag
    })

    if (segmentFormActive !== undefined) {
      toggleSegmentForm(segmentFormActive)
    }
  }, [segmentName, customTag, segmentFormActive])

  return (
    <>
      <div className="mt-3" aria-label="CSV Upload Steps">
        <div className={styles.columnsWrapper} aria-label="Steps Columns">
          <div
            className={cn(styles.columnSeparator, 'px-4 text-center')}
            aria-label="Download Step">
            <p className="d-flex align-items-center justify-content-center">
              <span
                className={cn(
                  'rounded-circle p-1 me-3 d-flex align-items-center justify-content-center',
                  styles.numberPill
                )}>
                1
              </span>
              <span className="text-18 fw-medium text-muted">Download</span>
            </p>
            <p className="text-center text-muted">
              Download a .CSV template with pre-defined column names
            </p>
            <DownloadLink
              classes="btn--wide"
              download="upload-users-by-csv-template.csv"
              url={`https://pulsate-assets.s3.eu-west-1.amazonaws.com/upload-users-by-csv-template${
                coreMemberIdUpload ? '-core_member_id.csv' : '.csv'
              }`}
              aria-label="Download CSV Template">
              Download .CSV Template
            </DownloadLink>
          </div>

          <div
            className={cn(styles.columnSeparator, 'px-4')}
            aria-label="Fulfil Step">
            <p className="d-flex align-items-center justify-content-center">
              <span
                className={cn(
                  'rounded-circle p-1 me-3 d-flex align-items-center justify-content-center',
                  styles.numberPill
                )}>
                2
              </span>
              <span className="text-18 fw-medium text-muted">Fulfil</span>
            </p>
            <p className="text-center text-muted">
              Open .CSV and fill out required columns
            </p>
          </div>
          <div
            className={cn(styles.columnSeparator, 'px-4')}
            aria-label="Create Segment Step">
            <div className="px-3">
              <div className="d-flex align-items-center justify-content-center mb-3">
                <span
                  className={cn(
                    'rounded-circle p-1 me-3 d-flex align-items-center justify-content-center',
                    styles.numberPill
                  )}>
                  3
                </span>
                <span className="text-18 fw-medium text-muted">
                  Create New Segment
                </span>
                <ToggleSwitch
                  className="ms-2"
                  checked={showSegmentForm}
                  onChange={(value) => toggleSegmentForm(value)}
                  controlId="push-location-switch"
                  aria-label="Toggle Create Segment"></ToggleSwitch>
              </div>
              {!showSegmentForm ? (
                <p className="text-center text-muted">
                  Create a New Segment with users from uploaded .CSV
                </p>
              ) : (
                <form aria-label="Segment Form">
                  <Input
                    name="segmentName"
                    placeholder="Enter Segment Name"
                    register={register}
                    className={cn('mb-3', {
                      'form-group--error': errors.segmentName
                    })}
                    errorTooltip={errors?.segmentName?.message}
                    validation={{
                      required: {
                        value: true,
                        message: 'Segment Name is missing.'
                      }
                    }}
                    label="Segment Name"
                    aria-label="Segment Name Input"
                  />
                  <Controller
                    control={control}
                    name="customTag"
                    render={({
                      field: { onChange },
                      formState: { errors }
                    }) => (
                      <CustomTagInput
                        defaultValue={customTag}
                        error={errors?.customTag?.message}
                        onChange={onChange}
                        aria-label="Custom Tag Input"
                      />
                    )}
                  />
                </form>
              )}
            </div>
          </div>
          <div aria-label="Upload Step">
            <p className="d-flex align-items-center justify-content-center">
              <span
                className={cn(
                  'rounded-circle p-1 me-3 d-flex align-items-center justify-content-center',
                  styles.numberPill
                )}>
                4
              </span>
              <span className="text-18 fw-medium text-muted">Upload</span>
            </p>
            <p className="text-muted text-center">Upload fulfilled .CSV file</p>
            <UploadButton
              onChange={uploadFile}
              wrapperClassName="text-center"
              label="Upload .CSV"
              accept=".csv"
              disabled={isLoading}
              buttonClassName="btn--wide btn--hover mt-2"
              aria-label="Upload CSV Button"
            />
          </div>
        </div>
        <div
          className={cn(styles.hintWrapper, 'bg-blue-gray p-4 text-center')}
          aria-label="Hint Section">
          <img
            src={coreMemberIdUpload ? hintMemberId : hint}
            alt="When using your own tables recreate those columns in your .CSV file"
            aria-label="Hint Image"
          />
        </div>
      </div>
      <Modal
        id="uploadSuccessModal"
        size="md"
        render={(close: () => void, args) => (
          <ConfirmationModal
            onClose={closeModal}
            cancelText="OK"
            aria-label="Upload Success Modal">
            <h4>{args.successText}</h4>
          </ConfirmationModal>
        )}
      />
    </>
  )
}

const UsersCSVUploadModal = (): React.ReactElement | null => {
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()
  if (!featureFlags.csvUsersUpload) return null
  return (
    <>
      <Modal
        id="usersCSVUploadModal"
        className="users-csv-upload-modal"
        renderHeader={() => 'Add/Update Users'}
        render={(close: () => void, params) => (
          <ModalContent closeModal={close} {...params} />
        )}
        aria-label="Users CSV Upload Modal"
      />
      <CSVErrorsModal />
    </>
  )
}

export default UsersCSVUploadModal
