import { ReactElement } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useUserDetails } from '../useUserDetails'
import SeparatorLine from '~/components/SeparatorLine'
import { withStore, useStore } from '~/dataStore'

const UserPersonalData = (): ReactElement => {
  const { data: user, isLoading } = useUserDetails()
  const {
    app: {
      appDetails: { featureFlags }
    }
  } = useStore()

  return (
    <div>
      <h3 className="mb-0" aria-label="Personal Information">
        Personal Information
      </h3>
      <SeparatorLine spaceTop={3} />
      {!isLoading ? (
        <h4 className="mb-4" aria-label={`User Full Name: ${user?.fullName}`}>
          {user?.fullName}
        </h4>
      ) : (
        <Skeleton aria-label="Loading user full name" />
      )}
      <table className="my-2 table w-85">
        {!isLoading && (
          <tbody>
            {user?.email && (
              <tr>
                <th aria-label="Email">Email</th>
                <td aria-label={`User Email: ${user.email}`}>{user.email}</td>
              </tr>
            )}
            {user?.alias && (
              <tr>
                <th aria-label="Alias">Alias</th>
                <td aria-label={`User Alias: ${user?.alias}`}>{user?.alias}</td>
              </tr>
            )}
            {user?.currentLocation && featureFlags.geofences && (
              <tr>
                <th aria-label="Last Known Location">Last Known Location</th>
                <td
                  aria-label={`User Last Known Location: ${user.currentCity}, ${user.currentCountry}`}>
                  {user.currentCity},{user.currentCountry}
                </td>
              </tr>
            )}
            {!!user?.age && (
              <tr>
                <th aria-label="Age">Age</th>
                <td aria-label={`User Age: ${user.age}`}>{user.age}</td>
              </tr>
            )}
            {user?.gender && (
              <tr>
                <th aria-label="Gender">Gender</th>
                <td aria-label={`User Gender: ${user.gender}`}>
                  {user.gender}
                </td>
              </tr>
            )}
            {user?.phone && (
              <tr>
                <th className="border-0" aria-label="Phone">
                  Phone
                </th>
                <td
                  className="border-0"
                  aria-label={`User Phone: ${user.phone}`}>
                  {user.phone}
                </td>
              </tr>
            )}
          </tbody>
        )}
        {isLoading && (
          <tbody>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

export default withStore(UserPersonalData)
