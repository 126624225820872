// Force a hex value to have 2 characters
function pad2(c) {
  return c.length === 1 ? `0${c}` : `${c}`
}

export function rgbToHex(r: number, g: number, b: number) {
  const hex = [
    pad2(Math.round(r).toString(16)),
    pad2(Math.round(g).toString(16)),
    pad2(Math.round(b).toString(16))
  ]

  return `#${hex.join('')}`
}

export function isValidHex(hex: string) {
  if (!hex) return false
  return /^#([A-Fa-f0-9]{6})$/.test(hex)
}

export function hexToRGB(hex: string): { r: number; g: number; b: number } {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return {
    r,
    g,
    b
  }
}
