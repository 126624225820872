import moment from 'moment'

export function isoDate(time = moment()): string {
  return moment(time).format('YYYY-MM-DD')
}

export function humanDateTime(
  time: string | Date | moment.Moment = moment()
): string {
  return moment(time).format('DD MMM YYYY HH:mm')
}

export function humanTimeDiff(ts1: string, ts2: string): string {
  const d1 = new Date(ts1)
  const d2 = new Date(ts2)
  const diffMinutes = Math.floor((d2 - d1) / 1000 / 60)
  const hours = Math.floor(diffMinutes / 60)
  const minutes = diffMinutes % 60

  if (hours > 0) {
    return `${hours}h`
  }
  return `${minutes}m`
}

export const isTimeDiffLessThan24 = (date: Date): boolean => {
  const hours = moment(date).diff(moment(), 'h')
  return hours <= 24 && hours > 0
}

export const timeMultipierToMinutes = {
  minutes: 1,
  hours: 60,
  days: 60 * 24,
  weeks: 60 * 24 * 7,
  months: 60 * 24 * 7 * 4,
  years: 60 * 24 * 365
}
