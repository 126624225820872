import cn from 'classnames'
import style from './style.scss'

interface IProps<T> {
  disabled?: boolean
  name: string
  value: T
  label?: string
  onChange: (value: T) => void
  className?: string
  id: string
  checked: boolean
}

export default function SingleRadio<T extends string | number>({
  disabled,
  name,
  value,
  label,
  onChange,
  className,
  checked,
  id
}: IProps<T>): React.ReactElement {
  return (
    <>
      <input
        disabled={disabled}
        type="radio"
        value={value}
        checked={checked}
        className={style.radio}
        id={id}
        name={name}
        onChange={() => onChange(value)}
      />
      <label
        htmlFor={id}
        id={`${id}-label`}
        className={cn(style.radioContainer, className, 'mb-0')}>
        {label && <span className="ms-2">{label}</span>}
      </label>
    </>
  )
}
