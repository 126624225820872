import { ReactElement } from 'react'
import Picker from 'emoji-picker-react'
import CustomDropdown from '~/components/CustomDropdown'

interface IProps {
  disabled?: boolean
  onChange: (emoji: string) => void
}

export default function EmojiPicker({
  disabled,
  onChange
}: IProps): ReactElement {
  return (
    <CustomDropdown
      right
      disabled={disabled}
      dropdownToggle={
        <div className="d-flex align-items-center">
          <div className="icon icon--med icon--emoji btn--hover rounded-circle" />
        </div>
      }
      withoutModifiers
      options={[
        {
          isCustom: true,
          name(toggleDropdown) {
            return (
              <Picker
                lazyLoadEmojis
                onEmojiClick={(emojiObject) => {
                  onChange(emojiObject.emoji)
                  toggleDropdown()
                }}
              />
            )
          }
        }
      ]}
    />
  )
}
