import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Modal from '~/components/modals/Modal/Modal'
import BulkUploadMethodModal from '~/components/BulkUpload/BulkUploadMethodModal/BulkUploadMethodModal'
import BulkUploadErrorsModals from '~/components/BulkUpload/BulkUploadErrorsModals'
import { batchCreateGeofencesByCsv } from '~/pages/Geofences/Store/Geofences.connector'
import { batchAddBeacons } from '~/api/beacons'
import { NotificationType, showNotification } from '~/utils/Notification'
import { isString } from '~/utils/utilities'
import { useStore } from '~/dataStore'
import { BatchUploadErrors } from '~/common.interface'

interface IProps {
  helpMedia: string
  onManualUpload: () => void
  resourceType: 'geofences' | 'beacons'
  successCallback: () => void
  labels: { header: string; csv: string; manual: string }
  errorsDictionary: { [key: string]: Record<string, string> }
}

const BatchUpload = ({
  errorsDictionary,
  helpMedia,
  labels,
  onManualUpload,
  resourceType,
  successCallback
}: IProps): React.ReactElement => {
  const [batchUploadErrors, setBatchUploadErrors] = useState<
    BatchUploadErrors | undefined
  >()
  const {
    ui: { showModal, hideModal }
  } = useStore()
  const { appId } = useParams<{ appId: string }>()

  const handleCsvUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const formData = new FormData()
    formData.append('csv_file', e.target.files[0], e.target.files[0].name)
    try {
      if (resourceType === 'geofences') {
        await batchCreateGeofencesByCsv(appId, formData)
      }
      if (resourceType === 'beacons') {
        await batchAddBeacons(appId, formData)
      }
      hideModal('batchUploadModal')
      setBatchUploadErrors(undefined)
      successCallback()
    } catch (error) {
      if (error?.body?.errors) {
        setBatchUploadErrors(error?.body?.errors)
        if (!isString(error?.body?.errors)) showModal('batchUploadErrorsModal')
      } else {
        showNotification(
          'Failed to upload the CSV file',
          NotificationType.ERROR
        )
      }
    }

    e.target.value = ''
  }

  return (
    <>
      <Modal
        id="batchUploadModal"
        className="bulk-upload-method-modal"
        size="lg"
        closeCallback={() => {
          setBatchUploadErrors(undefined)
        }}
        renderHeader={() => labels.header}
        render={() => (
          <BulkUploadMethodModal
            helpImage={helpMedia}
            csvUploadText={labels.csv}
            manualUploadText={labels.manual}
            onManualUpload={onManualUpload}
            onCsvUpload={handleCsvUpload}
            errors={batchUploadErrors}
          />
        )}
      />
      <Modal
        id="batchUploadErrorsModal"
        size="lg"
        renderHeader={() => 'Upload Status'}
        render={(close: () => void) => (
          <BulkUploadErrorsModals
            errors={batchUploadErrors}
            onReUpload={handleCsvUpload}
            onClose={close}
            entitiesName={resourceType}
            errorsDictionary={errorsDictionary}
          />
        )}
      />
    </>
  )
}

export default BatchUpload
