import pluralize from 'pluralize'
import { PropsWithChildren } from 'react'
import { ID } from '~/common.interface'
import { capitalizeString } from '~/utils/string'
import ConfirmationModal from './ConfirmationModal'

type ElementType = 'Journey' | 'Campaign' | 'Segment' | 'Geofence' | 'Beacon'

type ActionType = 'delete' | 'stop' | 'hide from feed' | 'pause' | 'resume'

interface IProps {
  onClose: () => void
  onSubmit: () => void
  elements: { id: ID; [nameKey: string]: string }[]
  elementType: ElementType
  actionType: ActionType
  nameKey?: string
  okText?: string
}

export default function ConfirmationListModal({
  onClose,
  onSubmit,
  okText,
  elements,
  elementType,
  children,
  actionType,
  nameKey = 'name'
}: PropsWithChildren<IProps>): React.ReactElement {
  return (
    <ConfirmationModal
      onClose={onClose}
      onSubmit={onSubmit}
      okText={okText || capitalizeString(actionType)}>
      <p>
        Are you sure you want to {actionType} the following{' '}
        {pluralize(elementType.toLowerCase(), elements.length)}?
      </p>
      <ul>
        {elements.map((c) => (
          <li key={c.id} className="bold">
            {c[nameKey]}
          </li>
        ))}
      </ul>
      {children}
    </ConfirmationModal>
  )
}
