import { makeAutoObservable } from 'mobx'

export default class Ui {
  // We set visible to null because it's important to know if it was already
  // initialized or not (check the useEffect hook in AppDashboard to find out
  // why)

  modals: Map<string, { isOpen: boolean; params?: { [key: string]: any } }> =
    new Map([])

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  // Sidebar
  showSidebar = true

  setShowSidebar(yesOrNo: boolean): void {
    this.showSidebar = yesOrNo
  }

  toggleSidebar(): void {
    this.showSidebar = !this.showSidebar
  }

  public registerModal(id: string): void {
    this.modals.set(id, { isOpen: false })
  }

  public unregisterModal(id: string): void {
    this.modals.delete(id)
  }

  public unregisterAllModals(): void {
    this.modals = new Map([])
  }

  public showModal(id: string, params?: Record<string, any>): void {
    this.modals.set(id, { isOpen: true, params })
  }

  public hideModal(id: string): void {
    this.modals.set(id, { isOpen: false })
  }

  public getModalParams(id: string): unknown | null {
    const modal = this.modals.get(id)
    if (modal) {
      return modal.params
    }
    return null
  }

  public isModalOpen(id: string): boolean {
    return this.modals.get(id)?.isOpen || false
  }

  /// HintWindow

  private hintWindowActive = false

  public getHintWindowActive(): boolean {
    return this.hintWindowActive
  }

  public setHintWindowActive(yesOrNo: boolean): void {
    this.hintWindowActive = yesOrNo
  }

  public hintsNumber = 0

  public addHint(): void {
    this.hintsNumber += 1
  }

  public removeHint(): void {
    this.hintsNumber -= 1
  }
}
