import React from 'react'

import { Row, Col } from 'reactstrap'
import LineChart from '~/pages/App/Dashboard/UI/LineChart'

interface IProps {
  dau: { dataGraph: any[] }
  mau: { dataGraph: any[] }
}

export default function DauMauCharts({ dau, mau }: IProps) {
  return (
    <Row>
      <Col xl="6" className="d-flex">
        <LineChart
          title="Daily Active Users"
          chartData={[
            {
              label: 'DAU',
              data: dau.dataGraph
            }
          ]}
        />
      </Col>
      <Col xl="6" className="d-flex">
        <LineChart
          title="Monthly Active Users"
          chartData={[
            {
              label: 'MAU',
              data: mau.dataGraph,
              color: 'rgba(40,167,69, 0.8)'
            }
          ]}
        />
      </Col>
    </Row>
  )
}
