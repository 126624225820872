import React, { useEffect, useState } from 'react'
import { Card, Spinner } from 'reactstrap'
import { useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'
import { useStore, withStore } from '~/dataStore'
import useQueryParams from '~/hooks/useQueryParams'
import TableNew from '~/components/Table/TableNew'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import useUsersList, {
  useFetchSegmentDetails,
  ServerStateKeysEnum
} from './useUsersList'
import {
  IUsersListSegment,
  QUERY_PARAMS_DEFAULTS
} from '~/pages/Users/Users.interface'
import { ID } from '~/common.interface'
import SelectWithSearch from '~/components/forms/SelectWithSearch'
import { handleLongName } from '~/utils/string'
import SearchInput from '~/components/forms/SearchInput'
import UsersListHeader from './components/UsersListHeader'
import usersListColumns from './components/usersListColumns'
import UsersCSVUploadModal from './components/UsersCsvUploadModal'

import style from './UsersList.scss'

interface IProps {
  segmentId?: ID
  inModal?: boolean
  withSegmentFiltering?: boolean
}

const UsersList = ({
  segmentId: _segmentId,
  inModal,
  withSegmentFiltering
}: IProps) => {
  const {
    app: {
      currentAdmin: { isDemoAdmin },
      appDetails: { featureFlags }
    }
  } = useStore()
  const [segmentId, setSegmentId] = useState(_segmentId)
  const [segmentsList, setSegmentsList] = useState(_segmentId)

  const queryParams = useQueryParams(QUERY_PARAMS_DEFAULTS)
  const paginationQuery = usePaginationQuery({ perPage: 50 })
  const listColumns = featureFlags.geofences
    ? usersListColumns
    : usersListColumns.filter((column) => column.fieldName !== 'currentCity')
  const {
    data: users,
    fetchNextPage,
    hasNextPage,
    isRefetching,
    isFetchingNextPage,
    isLoading
  } = useUsersList(
    {
      ...queryParams,
      ...paginationQuery.getPaginationQuery()
    },
    segmentId
  )

  const cache = useQueryClient()
  const segmentDetails = useFetchSegmentDetails(_segmentId)

  useEffect(() => {
    segmentDetails?.refetch()
  }, [_segmentId])

  const handleFilterSegment = (
    selected: { label: string; value: string } | null
  ) => {
    if (selected !== null) {
      const newSegmentId =
        segmentId === selected.value ? undefined : selected.value
      setSegmentId(newSegmentId)

      queryParams.filterSegments = newSegmentId ?? ''
    }
  }

  useEffect(() => {
    return () => {
      cache.removeQueries([{ queryKey: ServerStateKeysEnum.Users }])
    }
  }, [])

  useEffect(() => {
    if (users?.metadata.segmentsList) {
      const segmentsOptions = users?.metadata.segmentsList.map(
        (segment: IUsersListSegment) => ({
          label: handleLongName(segment.name, 25),
          value: segment.id
        })
      )

      setSegmentsList(segmentsOptions)
    }
  }, [users?.metadata.segmentsList])

  return (
    <>
      {!isDemoAdmin && (
        <UsersListHeader
          segmentName={segmentDetails?.data?.name}
          segmentId={_segmentId}
          totalUsers={users?.metadata.totalUsers}
          exportUsersUrl={users?.metadata.exportUsers}
        />
      )}
      <Card
        className={cn({ 'shadow-none': inModal, 'px-3': !inModal })}
        aria-label="Users List Card">
        <div
          className={cn('pb-3', { 'pt-4': !inModal })}
          aria-label="Users List Filters">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <SearchInput
                placeholder="Search for a user by name or alias"
                initialValue={paginationQuery.searchQuery}
                onChange={paginationQuery.handleSearch}
                className={cn('me-3', style.searchInputUsers)}
              />
              {withSegmentFiltering && (
                <SelectWithSearch
                  placeholder="Segments"
                  selectPlaceholder="Search for an segment"
                  value={segmentId || queryParams.filterSegments}
                  options={segmentsList || []}
                  showAllOption
                  radioOption
                  closeMenuOnSelect={false}
                  onChange={handleFilterSegment}
                />
              )}
            </div>

            <div className="d-flex align-items-center">
              {isRefetching && (
                <Spinner
                  color="primary"
                  type="grow"
                  className="ms-auto me-3"
                  aria-label="Loading Spinner"
                />
              )}
            </div>
          </div>
        </div>
        <div className="pb-5" aria-label="Users List Table">
          <TableNew
            isLoadingMore={isFetchingNextPage}
            isLoading={isLoading}
            columns={listColumns}
            hasMore={hasNextPage}
            onLoadMore={() => fetchNextPage()}
            rows={users?.pages || []}
            sortOrder={paginationQuery.order}
            sortColumn={paginationQuery.sortField}
            onSort={paginationQuery.handleSort}
            ariaLabel="Users Table"
          />
        </div>
      </Card>
      {!_segmentId && <UsersCSVUploadModal aria-label="CSV Upload Modal" />}
    </>
  )
}

export default withStore(UsersList)
