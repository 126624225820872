import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import TableNew from '~/components/Table/TableNew'
import { useStore } from '~/dataStore'
import useIntegrationGoalFiles, {
  useUploadFile
} from './useIntegrationGoalFiles'
import integrationableGoalHelp from './integration-goal-csv.svg'
import UploadButton from '~/components/UploadButton'
import { Date } from '~/common.interface'
import { humanDateTime } from '~/utils/time'

const columns = [
  {
    fieldName: 'fileName',
    displayName: 'Name'
  },
  {
    fieldName: 'createdAt',
    displayName: 'Date uploaded',
    render: ({ row: { createdAt } }: { row: { createdAt: Date } }) =>
      humanDateTime(createdAt)
  }
]

export default function JourneySection(): React.ReactElement {
  const {
    app: {
      currentAdmin: { isDemoAdmin },
      currentApp: { id: appId }
    }
  } = useStore()
  const { data: rows, isLoading } = useIntegrationGoalFiles(appId)

  const { mutate: upload, isLoading: isMutationLoading } = useUploadFile(appId)

  function uploadFile(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!e.target.files) return
    upload(e.target.files[0])
    e.target.value = ''
  }

  return (
    <>
      <div className="ms-auto mt-5">
        <div className="bg-body rounded p-4">
          <h3 className="text-white mb-0">Journeys</h3>
        </div>
      </div>

      <div className="mt-4">
        <>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <CardTitle tag="h3" className="mb-0 mt-2">
                Integration Goal
              </CardTitle>
              <UploadButton
                onChange={uploadFile}
                label="Upload .CSV"
                accept=".csv"
                disabled={isMutationLoading || isDemoAdmin}
                buttonClassName="btn--wide btn--hover mt-2"
              />
            </CardHeader>
            <CardBody className="pt-0 pb-4">
              <div className="my-3">
                <p>Recreate those columns in your .CSV file</p>
                <img className="w-100" src={integrationableGoalHelp} alt="" />
              </div>

              <TableNew
                isLoading={isLoading}
                columns={columns}
                rows={rows || []}
              />
            </CardBody>
          </Card>
        </>
      </div>
    </>
  )
}
