import React from 'react'
import Deliver from '~/pages/Campaign/CampaignReview/Deliver'
import Section from '~/pages/Campaign/CampaignReview/Section'
import Target from '~/pages/Campaign/CampaignReview/Target'
import Header from '~/components/Header'
import HeaderTitle from '~/components/HeaderTitle'
import { ExpiryType } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import Beacons from '~/dataStore/emailBuilder/Targeting/Beacons'
import Geofences from '~/dataStore/emailBuilder/Targeting/Geofences'
import Segments from '~/dataStore/emailBuilder/Targeting/Segments'
import { Message } from '../../Blocks'
import ReviewTopBar from './ReviewTopBar'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import JourneyNotificationReview from './JourneyNotificationReview'

export interface IProps {
  name: string
  icon: string
  startAt: Date | string | null
  endAt: Date | string | null
  timeZoneName: string
  segments: Segments
  beacons: Beacons
  geofences: Geofences
  messages: Message[]
  details: boolean
  status?: JourneyStatus
}

export default function JourneyReview({
  name,
  icon,
  startAt,
  endAt,
  timeZoneName,
  segments,
  beacons,
  geofences,
  messages,
  details,
  status
}: IProps): React.ReactElement {
  return (
    <div className="d-flex flex-column align-items-center">
      <Header className="mb-3">
        <HeaderTitle className="review-container__header">
          {details ? 'Journey' : 'You are about to send this Journey'}
        </HeaderTitle>
      </Header>

      <ReviewTopBar
        name={name}
        icon={icon}
        displayType="Template"
        isDetailsInPast={details}
        status={status}
        deliver={{
          isCampaignStartingEndingSameDay: false,
          startAt,
          startNow: false,
          endAt,
          expiryType: ExpiryType.NEVER,
          expiryDate: '',
          expiryTimeFrame: '',
          expiryTimeValue: '',
          campaignExpiry: false,
          isDeliveryFuture: true
        }}
      />

      <Section withEdit={false} title="">
        <div className="d-flex justify-content-center flex-wrap">
          {messages.map((message) => (
            <JourneyNotificationReview message={message} key={message.id} />
          ))}
        </div>
      </Section>

      <Section
        title="Target"
        subTitle={`This Journey ${
          details ? 'was launched' : 'will be sent'
        } to the following targeting criteria`}>
        <Target
          targeting={{ segments, geofences, beacons }}
          mode="details"
          type="journey"
        />
      </Section>

      <Section
        title="Deliver"
        subTitle={`This Journey ${
          details ? 'was launched' : 'will be sent'
        } under the following conditions`}>
        <Deliver
          status={status}
          type="Journey"
          deliver={{
            timeZoneName,
            startAt,
            endAt,
            timeWindows: undefined,
            deliverUi: {
              isDeliveryFutureOrDefault: false,
              isTimeWindows: false,
              isEndDate: !!endAt,
              isStartEndSectionCollapsed: true
            },
            expiryType: ExpiryType.NEVER
          }}
          isDetailsInPast={details}
        />
      </Section>
    </div>
  )
}
