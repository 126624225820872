import React from 'react'
import cn from 'classnames'
import { Card } from 'reactstrap'
import { humanDateTime } from '~/utils/time'
import { useStore, withStore } from '~/dataStore'
import { IJourney } from './JourneysList.interface'
import TableHeader from '~/components/Table/TableHeader'
import TableNew from '~/components/Table/TableNew'
import routes from '~/routes'
import JourneysListActions from './components/JourneysListActions'
import DeleteElementsModal from '~/components/modals/DeleteElementsModal'
import JourneysListToolbar from './components/JourneysListToolbar'
import useQueryParams from '~/hooks/useQueryParams'
import usePaginationQuery from '~/hooks/usePaginationQuery'
import JourneyNameCell from './components/JourneyNameCell'
import { colWide } from '~/components/Table/Table.scss'
import ReportBtn from '~/pages/Campaign/CampaignReports/ReportBtn'
import StopElementsModal from '../../../components/modals/StopElementsModal'
import useJourneys, { useInvalidateJourneys } from './useJourneys'
import { ID } from '~/common.interface'
import StatusColumn from '~/components/Table/components/StatusColumn'
import {
  deleteJourney,
  pauseJourney,
  resumeJourney,
  stopJourney
} from '../Connector/Journeys.connector'
import PauseElementsModal from '~/components/modals/PauseElementsModal'
import ResumeElementsModal from '~/components/modals/ResumeElementsModal'
import { JourneyStatus } from '../Journeys.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'
import useSelectListItems from '~/hooks/useSelectListItems'
import { isEmpty } from '~/utils/utilities'

const COLUMNS = [
  {
    fieldName: 'name',
    displayName: 'Journey name',
    sortable: true,
    // eslint-disable-next-line react/display-name
    render: ({
      row: { name, id, childBlocksTypes, status }
    }: {
      row: IJourney
    }) => (
      <JourneyNameCell
        name={name}
        id={id}
        status={status}
        childBlocksTypes={childBlocksTypes}
      />
    )
  },
  {
    fieldName: 'createdAt',
    displayName: 'Date Created',
    sortable: true,
    render: ({ row: { createdAt } }: { row: IJourney }) =>
      humanDateTime(createdAt)
  },
  {
    fieldName: 'status',
    displayName: 'Status',
    render({
      row
    }: {
      row: IJourney & {
        pause: ({ id, name }: { id: ID; name: string }) => void
        resume: ({ id, name }: { id: ID; name: string }) => void
      }
    }) {
      return (
        <StatusColumn
          row={row}
          pauseStatues={[
            JourneyStatus.PAUSED,
            JourneyStatus.ACTIVE,
            JourneyStatus.SCHEDULED
          ]}
        />
      )
    }
  },
  {
    fieldName: 'notificationTypes',
    displayName: 'Notification Types',
    // eslint-disable-next-line react/display-name
    render: ({ row: { notificationTypes } }: { row: IJourney }) => (
      <div className={cn(colWide, 'truncate')}>
        {notificationTypes?.map((n) => MCampaignTypeToName.get(n)).join(', ') ||
          '- -'}
      </div>
    )
  },
  {
    displayName: 'Report',
    render({ row }: { row: IJourney }) {
      return (
        <ReportBtn
          {...row}
          paramName="journeyId"
          route={routes.journeysReport}
        />
      )
    }
  },
  {
    displayName: 'Actions',
    render: JourneysListActions
  }
]

const JourneysList = (): React.ReactElement => {
  const {
    app,
    ui: { showModal }
  } = useStore()

  const { selectListItems, selectedListItems, clearSelectedItems } =
    useSelectListItems<IJourney>()

  const queryParams = useQueryParams({ status: '' })
  const paginationQuery = usePaginationQuery({
    perPage: 50
  })

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useJourneys(app.currentApp.id, {
      ...paginationQuery.getPaginationQuery(),
      status: queryParams.status,
      page: 1
    })

  const invalidate = useInvalidateJourneys()

  const onFilterByStatus = (values: string[]) => {
    queryParams.status = values.join(',')
    paginationQuery.setPage(1)
  }

  const showRemoveJourneysModal = (elements: IJourney[]) => {
    showModal('deleteElementsModal', {
      elements,
      action: (resourceId: ID) => deleteJourney(app.currentApp.id, resourceId),
      url: data?.bulkActions?.delete,
      onSuccess: () => {
        clearSelectedItems()
        invalidate()
      }
    })
  }

  const showStopJourneysModal = (elements: IJourney[] | IJourney) => {
    showModal('stopElementsModal', {
      elements,
      action: (resourceId: ID) => stopJourney(app.currentApp.id, resourceId),
      url: data?.bulkActions?.stop,
      onSuccess: () => {
        clearSelectedItems()
        invalidate()
      }
    })
  }

  const showPauseJourneysModal = (elements: IJourney[] | IJourney) => {
    showModal('pauseElementsModal', {
      elements,
      action: (resourceId: ID) => pauseJourney(app.currentApp.id, resourceId),
      onSuccess: () => {
        clearSelectedItems()
        invalidate()
      }
    })
  }

  const showResumeJourneysModal = (elements: IJourney[] | IJourney) => {
    showModal('resumeElementsModal', {
      elements,
      action: (resourceId: ID) => resumeJourney(app.currentApp.id, resourceId),
      onSuccess: () => {
        clearSelectedItems()
        invalidate()
      }
    })
  }

  function getSelectedItems(): IJourney[] {
    return Array.from(selectedListItems, ([, value]) => value)
  }

  const onRemoveJourneys = () => showRemoveJourneysModal(getSelectedItems())
  const onStopJourneys = () => showStopJourneysModal(getSelectedItems())

  const onRemoveJourney = (journey: IJourney) =>
    showRemoveJourneysModal([journey])

  const onStopJourney = (journey: IJourney) => showStopJourneysModal([journey])

  const onPauseJourney = (journey: IJourney) =>
    showPauseJourneysModal([journey])

  const onResumeJourney = (journey: IJourney) =>
    showResumeJourneysModal([journey])

  return (
    <>
      <TableHeader
        text="New Journey"
        params={{ appId: app.currentApp.id }}
        route={routes.journeysNew}>
        Journeys{' '}
        {!isEmpty(data?.metadata.dataCount) && (
          <span className="text-muted">({data?.metadata.dataCount})</span>
        )}
      </TableHeader>
      <Card className="mt-4">
        <div className="p-3">
          <JourneysListToolbar
            selectedJourneys={selectedListItems}
            actions={{
              onDeleteRows: onRemoveJourneys,
              onStopRows: onStopJourneys
            }}
            onFilterByStatus={onFilterByStatus}
            status={queryParams.status?.split(',').filter(Boolean)}
            searchQuery={paginationQuery.searchQuery}
            handleSearch={paginationQuery.handleSearch}
          />
          <div className="journeys-list mt-3">
            <TableNew
              columns={COLUMNS}
              rows={
                data?.pages.map((journey) => ({
                  ...journey,
                  pause: onPauseJourney,
                  resume: onResumeJourney,
                  onStopClick: onStopJourney,
                  onDeleteClick: onRemoveJourney
                })) || []
              }
              onSelect={selectListItems}
              selectedRows={selectedListItems}
              hasMore={hasNextPage}
              onLoadMore={fetchNextPage}
              onDeleteRow={onRemoveJourney}
              onSort={paginationQuery.handleSort}
              sortColumn={paginationQuery.sortField}
              sortOrder={paginationQuery.order}
              isLoading={isLoading}
              isLoadingMore={isFetchingNextPage}
            />
          </div>
        </div>
      </Card>
      <DeleteElementsModal elementType="Journey" nameKey="name">
        Deleting action may take up to 15 min
      </DeleteElementsModal>
      <StopElementsModal elementType="Journey" />
      <PauseElementsModal elementType="Journey" />
      <ResumeElementsModal elementType="Journey" />
    </>
  )
}

export default withStore(JourneysList)
