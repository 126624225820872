import React from 'react'
import {
  faClone,
  faPen,
  faStop,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'

import { IJourney } from '../JourneysList.interface'
import IconButton from '~/components/IconButton'
import { useStore, withStore } from '~/dataStore'
import routes from '~/routes'
import { duplicateJourney } from '../../Connector/Journeys.connector'
import { showGeneralError } from '~/utils/validations'

const JourneysListActions = ({
  row
}: {
  row: IJourney & {
    onStopClick: (row: IJourney) => void
    onDeleteClick: (row: IJourney) => void
  }
}): React.ReactElement => {
  const {
    app: {
      currentApp,
      currentAdmin: { isDemoAdmin }
    },
    goTo
  } = useStore()

  async function handleDuplicate(setSpinner: (value: boolean) => void) {
    setSpinner(true)
    try {
      const duplicatedJourney = await duplicateJourney(currentApp.id, row.id)
      goTo(
        'journeysBuilder',
        {
          appId: currentApp.id,
          journeyId: duplicatedJourney.id,
          mode: 'builder'
        },
        {}
      )
    } catch (error) {
      console.error(error)
      showGeneralError()
    }
    setSpinner(false)
  }

  return (
    <div className="row-actions">
      <IconButton
        id={`edit-${row.id}`}
        dataTestId="editJourneyBtn"
        url={routes.journeysBuilder}
        params={{
          journeyId: row.id,
          mode: 'builder'
        }}
        icon={faPen}
        disabled={!row.actions.edit || (isDemoAdmin && row.status !== 'Draft')}
        className="me-1"
      />
      <UncontrolledTooltip placement="bottom" target={`edit-${row.id}`}>
        Edit
      </UncontrolledTooltip>

      <IconButton
        id={`duplicate-${row.id}`}
        dataTestId="duplicateJourneyBtn"
        onClick={handleDuplicate}
        icon={faClone}
        disabled={!row.actions.duplicate || isDemoAdmin}
        className="me-1"
      />
      <UncontrolledTooltip placement="bottom" target={`duplicate-${row.id}`}>
        Duplicate
      </UncontrolledTooltip>

      <IconButton
        onClick={() => row.onStopClick(row)}
        icon={faStop}
        disabled={!row.actions.stop || isDemoAdmin}
        className="me-1"
        dataTestId="stopJourneyBtn"
        id={`stop-${row.id}`}
      />
      <UncontrolledTooltip placement="top" target={`stop-${row.id}`}>
        Stop
      </UncontrolledTooltip>

      <IconButton
        dataTestId="deleteJourney"
        onClick={() => row.onDeleteClick(row)}
        icon={faTrash}
        className="me-n3"
        disabled={
          !row.actions.delete || (isDemoAdmin && row.status !== 'Draft')
        }
        id={`delete-${row.id}`}
      />
      <UncontrolledTooltip placement="bottom" target={`delete-${row.id}`}>
        Delete
      </UncontrolledTooltip>
    </div>
  )
}

export default withStore(JourneysListActions)
