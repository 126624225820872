import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export default function useExitPrompt(): {
  confirmedNavigation: boolean
  setConfirmedNavigation: (val: boolean) => void
  setNewLocation: (val: any) => void
} {
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [location, setNewLocation] = useState<any>(undefined)
  const history = useHistory()

  useEffect(() => {
    if (confirmedNavigation && location) {
      // Navigate to the previous blocked location with your navigate function
      history.push(location)
    }
  }, [confirmedNavigation, location])

  return {
    confirmedNavigation,
    setConfirmedNavigation,
    setNewLocation
  }
}
