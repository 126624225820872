import React from 'react'
import { Button } from 'reactstrap'
import cn from 'classnames'

import { useStore, withStore } from '~/dataStore'

import './Section.scss'

interface Props {
  withEdit?: boolean
  children: React.ReactNode
  title: string | React.ReactNode
  subTitle?: string | React.ReactNode
  stepNumber?: number
}

const Section = ({
  withEdit,
  children,
  title,
  subTitle,
  stepNumber
}: Props): React.ReactElement => {
  const { goTo } = useStore()

  function scrollInto(e: React.MouseEvent<HTMLButtonElement>) {
    ;(e.target as HTMLButtonElement).scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="section pb-6">
      <hr className="section__divider mt-0" />
      <button
        type="button"
        aria-label="Scroll to section"
        className="section__arrow cursor-pointer btn btn--hover"
        onClick={scrollInto}
      />
      <header
        className={cn('d-flex flex-column align-items-center mt-5', {
          'mb-5': !!title
        })}>
        <div className={`d-flex align-items-center ${subTitle ? 'mb-3' : ''}`}>
          <h2 className="me-3 fw-normal mb-0 text-body">{title}</h2>
          {withEdit && (
            <Button
              outline
              color="body"
              className="px-4 py-1 btn--hover"
              onClick={() => goTo(undefined, {}, { step: stepNumber })}>
              Edit
            </Button>
          )}
        </div>
        <span className="muted">{subTitle}</span>
      </header>
      <div className="section__card">{children}</div>
    </div>
  )
}

export default withStore(Section)
