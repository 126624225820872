import React, { useState } from 'react'
import { Autocomplete } from '@react-google-maps/api'
import reject from 'lodash/reject'
import {
  faObjectUngroup,
  faObjectGroup,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import Input from '~/components/forms/Input'
import mapPin from '~/components/icons/map_pin.svg'
import CustomDropdown from '~/components/CustomDropdown'
import SearchInput from '~/components/forms/SearchInput'
import { IGeofence } from '~/pages/Geofences/Store/GeofencesStore.interface'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { withStore } from '~/dataStore'
import MultiSelect from '~/components/forms/MultiSelect'
import GroupsOption from '~/components/Table/components/GroupsOption'
import radialGeofenceIcon from '~/pages/Geofences/components/radialGeofenceIcon.svg'
import polygonGeofenceIcon from '~/pages/Geofences/components/polygonGeofenceIcon.svg'
import GeofencesActions from '../GeofencesActions'

import './GeofencesListToolbar.scss'

interface IProps {
  selectedGeofences: Map<string, IGeofence>
  fullScreenMode: boolean
  goToUserLocation: () => void
  groups: IGroup[]
  handleSearch: (value: string) => void
  isGoogleMapsLoaded: boolean
  onAddToGroup: () => void
  onCreateGroup: () => void
  onDelete: () => void
  onEditGroup: () => void
  onRemoveFromGroup: () => void
  onRemoveGroup: () => void
  polygonsActive: boolean
  searchQuery: string
  setMapCenter: ({ lat, lng }: { lat: number; lng: number }) => void
  setPage: (page: number) => void
  setSelectedGroup: (group: IGroup) => void
  map: google.maps.Map | undefined
  queryParams: {
    shape: string
    groupIds: string
  }
}

const GeofencesListToolbar = ({
  selectedGeofences,
  fullScreenMode,
  goToUserLocation,
  groups,
  handleSearch,
  isGoogleMapsLoaded,
  polygonsActive,
  onAddToGroup,
  onCreateGroup,
  onDelete,
  onEditGroup,
  onRemoveFromGroup,
  onRemoveGroup,
  queryParams,
  searchQuery,
  setPage,
  setSelectedGroup,
  map
}: IProps) => {
  const [placesAutoComplete, setPlacesAutoComplete] =
    useState<google.maps.places.Autocomplete>()

  const goToPlace = () => {
    if (map && placesAutoComplete !== null) {
      const place = placesAutoComplete?.getPlace()
      if (place) {
        map.fitBounds(place.geometry.viewport)
      } else {
        map.setCenter(place.geometry.location)
        map.setZoom(14)
      }
    }
  }

  const batchActions = [
    {
      name: 'Add to a Group',
      icon: faObjectGroup,
      action: onAddToGroup
    },
    {
      name: 'Remove from a Group',
      icon: faObjectUngroup,
      action: onRemoveFromGroup,
      hidden:
        Array.from(selectedGeofences, ([key, value]) => value).flatMap(
          (c) => c.groups
        ).length === 0
    },
    {
      name: 'Delete Geofences',
      icon: faTrash,
      action: onDelete
    }
  ]

  const groupFilterOptions = groups.map((g) => ({
    name: (
      <GroupsOption
        {...g}
        setEditGroup={(group) => {
          setSelectedGroup(group)
          onEditGroup()
        }}
        setDeleteGroup={(group) => {
          setSelectedGroup(group)
          onRemoveGroup()
        }}
      />
    ),
    value: g.id
  }))

  const shapesOptions = [
    {
      name: (
        <div className="d-flex justify-content-between">
          <span>Circle fences</span>
          <img src={radialGeofenceIcon} alt="Circle fences" />
        </div>
      ),
      value: 'circle'
    },
    {
      name: (
        <div className="d-flex justify-content-between">
          <span>Polygon fences</span>
          <img
            className="ms-2"
            src={polygonGeofenceIcon}
            alt="Polygon fences"
          />
        </div>
      ),
      value: 'polygon'
    }
  ]

  const handleFilterGroups = (values: string[]) => {
    // eslint-disable-next-line no-param-reassign
    queryParams.groupIds = values.join(',')
    setPage(1)
  }

  const handleFilterShape = (values: string[]) => {
    // eslint-disable-next-line no-param-reassign
    queryParams.shape = values.join(',')
    setPage(1)
  }

  return (
    <div className="toolbar d-flex justify-content-between align-items-center">
      <div className="d-flex">
        <CustomDropdown
          options={batchActions}
          selectedItems={selectedGeofences}
          visible={selectedGeofences.size > 0}
          className="me-3"
        />
        <SearchInput
          placeholder="Search for a geofence"
          initialValue={searchQuery}
          onChange={handleSearch}
          className="toolbar__search-input me-3"
        />

        <MultiSelect
          title="Groups"
          selectedOptions={reject(
            queryParams.groupIds?.split(','),
            (s) => s === ''
          )}
          onSelect={handleFilterGroups}
          resetOptionName="Show All"
          options={groupFilterOptions}
          className="me-3"
          bottomChildren={
            <div className="d-flex">
              <Button
                onClick={onCreateGroup}
                data-testid="createGroupNameBtn"
                color="primary"
                className="mx-3 my-2 w-100 text-nowrap">
                + Create Group Name
              </Button>
            </div>
          }
        />
        {polygonsActive && (
          <MultiSelect
            title="Types"
            selectedOptions={reject(
              queryParams.shape?.split(','),
              (s) => s === ''
            )}
            onSelect={handleFilterShape}
            resetOptionName="Show All"
            options={shapesOptions}
            className="me-3"
          />
        )}
      </div>
      <div className="d-flex">
        {isGoogleMapsLoaded && (
          <div className="places-select">
            <Autocomplete
              onLoad={(place) => setPlacesAutoComplete(place)}
              onPlaceChanged={goToPlace}>
              <Input
                type="text"
                placeholder="Search for location"
                className="mb-0"
                suffix={
                  <Button
                    color=""
                    className="p-0"
                    type="button"
                    onClick={goToUserLocation}>
                    <img
                      className="cursor-pointer"
                      src={mapPin}
                      alt="Locate me"
                    />
                  </Button>
                }
              />
            </Autocomplete>
          </div>
        )}
        {fullScreenMode && (
          <div className="ms-3">
            <GeofencesActions />
          </div>
        )}
      </div>
    </div>
  )
}

export default withStore(GeofencesListToolbar)
