import { uniqueId } from 'lodash'
import React, { isValidElement, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { UncontrolledTooltip } from 'reactstrap'
import Tooltip from '~/components/reports/Tooltip'
import { isEmpty, isPrimitive } from '~/utils/utilities'
import SummaryFieldValue from './SummaryFieldValue'
import { withStore } from '~/dataStore'

interface IProps {
  label: string
  content?:
    | string
    | number
    | { value: string; tooltip: string }[]
    | React.ReactElement
  className?: string
  tooltip?: string
}

const SummaryField = ({
  label,
  content,
  className,
  tooltip
}: IProps): React.ReactElement => {
  const id = useRef(uniqueId('summary'))
  return (
    <div className={`summary-field ${className || ''}`}>
      <div className="d-flex">
        <h3 className="summary-field__label">{label}</h3>
        {tooltip && <Tooltip tooltip={tooltip} size="1x" />}
      </div>

      {isEmpty(content) && (
        <p className="summary-field__content">
          <Skeleton width={250} />
        </p>
      )}

      {isValidElement(content) && (
        <div className="summary-field__content">{content}</div>
      )}

      {isPrimitive(content) && (
        <>
          <p className="summary-field__content" id={id.current}>
            {content}
          </p>
          {className?.includes('truncate') && (
            <UncontrolledTooltip direction="up" target={id.current}>
              {content}
            </UncontrolledTooltip>
          )}
        </>
      )}

      {Array.isArray(content) && (
        <p className="summary-field__content">
          {content.map((item, index) => (
            <React.Fragment key={item.value}>
              {index > 0 && ', '}
              <SummaryFieldValue fieldValue={item} />
            </React.Fragment>
          ))}
        </p>
      )}
    </div>
  )
}

export default withStore(SummaryField)
