import React, { useEffect, useState } from 'react'
import { Button, Input, ModalBody, ModalFooter } from 'reactstrap'
import { withStore } from '~/dataStore'
import Modal from '~/components/modals/Modal/Modal'
import { IGroup } from '~/dataStore/Groups/Groups.interface'

interface IProps {
  onSubmit: (newName?: string | undefined, group?: IGroup | undefined) => void
  isSaving: boolean
  group: IGroup | undefined
}

const EditGroupModal = ({ onSubmit, group, isSaving }: IProps) => {
  const [name, setName] = useState<string | undefined>()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value)

  useEffect(() => {
    setName(group?.name)
  }, [group?.name])

  return (
    <Modal
      id="editGroupModal"
      size="sm"
      className="edit-group-modal"
      renderHeader={() => 'Edit Group Name'}
      render={(close: () => void) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(name, group)
          }}>
          <ModalBody className="m-3">
            <Input defaultValue={name} onChange={handleChange} />
          </ModalBody>
          <ModalFooter>
            <Button color="" onClick={close} disabled={isSaving}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isSaving || !name}>
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    />
  )
}

export default withStore(EditGroupModal)
