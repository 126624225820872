import React from 'react'
import cn from 'classnames'
import moment from 'moment/moment'
import Skeleton from 'react-loading-skeleton'
import { IChatMessage } from '~/pages/Messages/Chat/Chat.interface'
import defaultUserAvatar from '~/assets/img/default-user-avatar.svg'

import styles from '../../Messages.scss'

const ChatMessage = ({ message }: { message: IChatMessage }) => {
  return (
    <div
      className={cn(
        {
          'chat-message-right': message.sentByAdmin,
          'chat-message-left': !message.sentByAdmin
        },
        'pb-4'
      )}>
      <div>
        <img
          src={message.avatar || defaultUserAvatar}
          className="rounded-circle mx-auto d-block"
          alt={message.senderName}
          width="40"
          height="40"
        />
        {message.createdAt ? (
          <div className="text-muted small text-nowrap mt-2">
            {moment(message.createdAt).isSame(new Date(), 'day')
              ? moment(message.createdAt).utc().format('HH:mm a')
              : moment(message.createdAt).utc().format('MMM DD, YYYY')}
          </div>
        ) : (
          <Skeleton />
        )}
      </div>
      <div
        className={cn(styles.chatMessage, 'flex-shrink-1 rounded py-2 px-3', {
          'me-3': message.sentByAdmin,
          'ms-3': !message.sentByAdmin
        })}>
        <div className="fw-medium mb-1 text-capitalize">
          {message.senderName || <Skeleton width={100} />}
        </div>
        {message.content || <Skeleton width={500} />}
      </div>
    </div>
  )
}

export default ChatMessage
