import { useEffect, useRef } from 'react'

interface ReturnType {
  getAbortController: (name: string) => AbortController
  abort: (name: string) => void
}

export default function useAbortController(): ReturnType {
  const controllers = useRef<Map<string, AbortController>>(new Map())

  function getAbortController(name: string): AbortController {
    const current = controllers.current.get(name)
    if (current) {
      return current
    }

    const controller = new AbortController()
    controllers.current.set(name, controller)
    return controller
  }

  function abort(name: string): void {
    const current = controllers.current.get(name)
    if (current) {
      current.abort()
      controllers.current.delete(name)
    }
  }

  useEffect(() => {
    return () => {
      Array.from(controllers.current.values())?.forEach((controller) =>
        controller.abort()
      )
    }
  }, [])

  return { getAbortController, abort }
}
