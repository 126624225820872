import React, { ReactElement } from 'react'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import CardStep from './Card/CardStep'
import DeliveryStep from './DeliveryStep/DeliveryStep'
import EmailStep3 from './Email/Step3/EmailStep3'
import EmailStep4 from './Email/Step4/EmailStep4'
import InAppStep from './InApp/InAppStep'
import PushStep from './Push/PushStep'
import ReviewStep from './ReviewStep'
import SetupStep from './SetupStep/SetupStep'
import SMSStep from './SMS/SMSStep'
import TargetingStep from './TargetingStep/TargetingStep'

export default function CurrentStepComponent({
  name,
  setBeforeExitStep
}: {
  name?: string
  setBeforeExitStep: (beforeExitAction: () => void) => void
}): ReactElement {
  switch (name) {
    case 'setUp':
      return <SetupStep />
    case 'sms':
      return <SMSStep />
    case 'cardFront':
      return <CardStep type={CampaignType.CARD_FRONT} />
    case 'cardBack':
      return <CardStep type={CampaignType.CARD_BACK} />
    case 'inApp':
      return <InAppStep />
    case 'push':
      return <PushStep />
    case 'templates':
      return <EmailStep3 />
    case 'email':
      return <EmailStep4 setBeforeExitStep={setBeforeExitStep} />
    case 'targeting':
      return <TargetingStep />
    case 'deliver':
      return <DeliveryStep />
    case 'review':
      return <ReviewStep />
    default:
      return <SetupStep />
  }
}
