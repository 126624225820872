import { get } from '~/api/v2'

import {
  ICampaign,
  ICampaignsDashboardSummaryStatsPayload,
  IFilterResources
} from './CampaignsDashboard.interface'

import { IMetaData } from '~/common.interface'

export function fetchDashboardEmailStatistics(
  appId: string,
  params: {
    groupIds?: string
    campaignIds?: string
    ongoing: string
    type?: string
  },
  abortSignal?: AbortSignal
): Promise<ICampaignsDashboardSummaryStatsPayload> {
  return get(`${appId}/dashboard_statistics/email`, params, abortSignal)
}

export function fetchFilterResources(
  appId: string,
  params?: { ongoing: string },
  abortSignal?: AbortSignal
): Promise<IFilterResources> {
  return get(`${appId}/dashboard_statistics/resources`, params, abortSignal)
}

export function fetchCampaigns(
  appId: string,
  params: {
    groupIds?: string
    campaignIds?: string
    ongoing: string
    page?: number | undefined
    order?: string
    sort?: string
  },
  abortSignal?: AbortSignal
): Promise<{ data: ICampaign[]; metadata: IMetaData }> {
  return get(`${appId}/dashboard_statistics/campaigns`, params, abortSignal)
}
