import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { fetchEvents } from '~/api/apps'
import { fetchSegmentRules } from '~/api/segments'
import { ID } from '~/common.interface'
import { IGeofence } from '~/pages/Geofences/Geofences.interface'
import { fetchGeofences } from '~/pages/Geofences/Store/Geofences.connector'
import { RuleWithCategoriesDTO } from './SegmentBuilder.interface'

export enum ServerStateKeysEnum {
  SEGMENT_RULES = 'segmentRules',
  GEOFENCES = 'geofences',
  EVENTS = 'events'
}

export function useSegmentRules(appId: ID): UseQueryResult<unknown> & {
  rulesWithCategories: RuleWithCategoriesDTO
} {
  const query = useQuery<
    unknown,
    unknown,
    { rulesWithCategories: RuleWithCategoriesDTO }
  >({
    queryKey: [ServerStateKeysEnum.SEGMENT_RULES],
    queryFn: () => fetchSegmentRules(appId),
    staleTime: Infinity
  })

  return {
    ...query,
    rulesWithCategories: query.data || {}
  }
}

// TODO: rewrite geofences to react query?
export function useGeofences(
  appId: ID,
  params: Record<string, unknown> = {}
): UseQueryResult<{ data: IGeofence[] }> {
  return useQuery<{ data: IGeofence[] }>({
    queryKey: [ServerStateKeysEnum.GEOFENCES],
    queryFn: () => fetchGeofences(appId, params),
    staleTime: Infinity
  })
}

export function useEvents(appId: ID): UseQueryResult<{ data: string[] }> {
  return useQuery<{ data: string[] }>({
    queryKey: [ServerStateKeysEnum.EVENTS],
    queryFn: () => fetchEvents(appId),
    staleTime: Infinity
  })
}
