import React, { useState } from 'react'
import cn from 'classnames'
import usersIcon from '~/assets/img/users.svg'
import usersIconFuture from '~/assets/img/users-future.svg'
import { useStore, withStore } from '~/dataStore'
import OptionBox from './OptionBox'

const CampaignDeliverySelect = () => {
  const {
    campaign: {
      isSended,
      deliver: {
        setDeliveryCurrent,
        setDeliveryCurrentFuture,
        validateDelivery,
        deliverUi: {
          isDeliveryFuture,
          isDeliveryCurrentOrFuture,
          isDeliveryCurrent
        }
      }
    }
  } = useStore()

  const [isDeliverySelected, setDeliverySelected] = useState(
    isDeliveryCurrentOrFuture
  )

  return (
    <div className="d-flex">
      <OptionBox
        iconSrc={usersIcon}
        title="One Time Segment"
        description="A One time Segment includes users who are in this segment now"
        borderRight
        active={isDeliveryCurrent}
        isDeliverySelected={isDeliverySelected}
        className="pt-5"
        wrapperClassName={cn({
          'o-50': isDeliverySelected && !isDeliveryCurrent,
          'border-bottom': !isDeliveryCurrent && isDeliverySelected,
          'disabled-label': isSended
        })}
        onSelect={() => {
          if (!isSended) {
            setDeliveryCurrent()
            setDeliverySelected(true)
            validateDelivery()
          }
        }}
      />
      <OptionBox
        iconSrc={usersIconFuture}
        title="Live Segment"
        description="A Live segment includes users who are in this segment now and users who enter this segment in the future"
        active={isDeliveryFuture}
        isDeliverySelected={isDeliverySelected}
        wrapperClassName={cn({
          'o-50': isDeliverySelected && !isDeliveryFuture,
          'border-bottom': !isDeliveryFuture && isDeliverySelected,
          'disabled-label': isSended
        })}
        onSelect={() => {
          if (!isSended) {
            setDeliveryCurrentFuture()
            setDeliverySelected(true)
            validateDelivery()
          }
        }}
      />
    </div>
  )
}

export default withStore(CampaignDeliverySelect)
