import React from 'react'
import Chart from 'react-apexcharts'
import cloneDeep from 'lodash/cloneDeep'
import DEFAULT_OPTIONS from './chartOptions'
import { IRadialBarChartData } from '~/pages/Campaign/CampaignReports/Model/Report.interface'

const ApexRadialBarChart = ({
  chartData,
  options
}: {
  chartData: IRadialBarChartData
}): React.ReactElement | null => {
  const parseOptions = () => {
    const _options = cloneDeep(DEFAULT_OPTIONS)
    if (_options?.labels) {
      _options.labels.push(chartData.label)
    }

    return { ..._options, ...options }
  }

  return (
    <Chart
      options={parseOptions()}
      series={[chartData.value || 0]}
      type="radialBar"
      height={350}
    />
  )
}

export default ApexRadialBarChart
