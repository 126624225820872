import { uniqueId } from 'lodash'
import { useRef } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import Beacon from '~/dataStore/emailBuilder/Targeting/Beacon.model'

export default function BeaconNameCol({
  row,
  select,
  isSelected
}: {
  row: Beacon & { isSelected: boolean }
  select: (row: Beacon, selected: boolean) => void
  isSelected?: boolean
}): React.ReactElement {
  const id = useRef(uniqueId('beaconName'))

  return (
    <>
      <Button
        className="p-0 w-100 text-start shadow-none d-flex align-items-center mb-0 "
        onClick={() => select(row, !isSelected)}
        color="">
        <span tabIndex={0} role="button" className="truncate" id={id.current}>
          {row.nickname}
        </span>
        <UncontrolledTooltip placement="top" target={id.current}>
          {row.nickname}
        </UncontrolledTooltip>
      </Button>
    </>
  )
}
