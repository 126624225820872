import React from 'react'
import { MFieldsToInputsName } from '~/dataStore/emailBuilder/EmailBuilder.map'
import { isPrimitive } from '~/utils/utilities'

interface IProps {
  errors: { [x: string]: string[] | unknown[] }
}

// const sample = {
//   push_variants: [
//     {
//       '0': {
//         body_template: ['Text cannot contain <, >, [, ] or newlines.'],
//         subtitle_template: ['Text cannot contain <, >, [, ] or newlines.'],
//         title_template: ['Text cannot contain <, >, [, ] or newlines.']
//       },
//       '1': {
//         subtitle_template: ['Text cannot contain <, >, [, ] or newlines.'],
//         title_template: ['Text cannot contain <, >, [, ] or newlines.']
//       }
//     }
//   ]
// }

function parseErrors(
  entries: [string, string[] | unknown[]][]
): [string, string[]] | [] {
  if (!entries?.[0]) {
    return []
  }

  const [key, value] = entries[0]

  if (Array.isArray(value)) {
    if (isPrimitive(value[0])) {
      return [entries[0]]
    }
    return parseErrors(Object.entries(value[0]))
  }
  if (!isPrimitive(value)) {
    return parseErrors(Object.entries(value))
  }

  return [entries[0]]
}

export default function EmailErrors({
  errors = {}
}: IProps): React.ReactElement | null {
  // const errorList =   Object.entries<string[] | unknown[]>(sample)
  let errorList = []
  try {
    errorList = parseErrors(Object.entries<string[] | unknown[]>(errors))
  } catch (error) {
    return (
      <div>Something went wrong. Please try again or write to our support.</div>
    )
  }

  if (!Array.isArray(errorList)) {
    return (
      <div>Something went wrong. Please try again or write to our support.</div>
    )
  }

  return (
    <div>
      {errorList.map(([key, errorMessages]) => {
        const parsedKey = MFieldsToInputsName.get(key)
        return (
          <div key={key}>
            {Array.isArray(errorMessages) ? (
              <>
                <div className="bold">{parsedKey}</div>
                <ul>
                  {errorMessages.map(
                    (value, index) =>
                      isPrimitive(value) && <li key={index}>{value}</li>
                  )}
                </ul>
              </>
            ) : (
              <div>Something went wrong. Please try again.</div>
            )}
          </div>
        )
      })}
    </div>
  )
}
