import React from 'react'
import cn from 'classnames'
import PreviewMergeTagText from './PreviewMergeTagText'

const Text = ({
  text,
  onClick
}: {
  text: string
  onClick?: () => void
}): React.ReactElement => {
  return (
    <div
      data-testid="preview-part"
      className={cn('preview__text', { 'cursor-pointer': !!onClick })}
      onClick={onClick}>
      <PreviewMergeTagText text={text} />
    </div>
  )
}

export default Text
