import React, { useRef, useState } from 'react'
import { Circle } from '@react-google-maps/api'
import { CIRCLE_OPTIONS } from '~/pages/Geofences/consts'
import { IGeofence, IUnitSystem } from '~/pages/Geofences/Geofences.interface'
import CircleInfoWindow from './CircleInfoWindow'
import { withStore } from '~/dataStore'
import { feetToMeters } from '~/pages/Geofences/utils'

interface IProps {
  geofence: IGeofence
  saveGeofence: (geofence: IGeofence) => void
  selectGeofence: (id: string) => void
  isGeofenceSaving: boolean
  setInfoWindowOpen: (value: boolean) => void
  removeGeofence: (geofence: IGeofence) => void
  unitSystem: IUnitSystem
  minRadius: number | undefined
  maxRadius: number | undefined
  showInfoWindow: boolean
  setIsRadiusSliderDragged: (value: boolean) => void
  map: google.maps.Map | null
}

const CircleGeofence = ({
  saveGeofence,
  geofence,
  isGeofenceSaving,
  minRadius,
  maxRadius,
  showInfoWindow,
  unitSystem,
  removeGeofence,
  selectGeofence,
  setInfoWindowOpen,
  setIsRadiusSliderDragged,
  map
}: IProps): React.ReactElement => {
  const circleRef = useRef<Circle>(null)
  const [radius, setRadius] = useState<number>(geofence.radius)
  const [name, setName] = useState(geofence?.name || '')
  const onCircleClick = (e: google.maps.MapMouseEvent, g: IGeofence) => {
    selectGeofence(g.id)
  }

  const onCircleDragEnd = () => {
    if (circleRef.current !== null) {
      const payload = {
        ...geofence,
        radius,
        location: [
          circleRef.current.state.circle.getCenter().lat(),
          circleRef.current.state.circle.getCenter().lng()
        ]
      }
      saveGeofence(payload)
      map?.panTo(
        new google.maps.LatLng(
          circleRef.current.state.circle.getCenter().lat(),
          circleRef.current.state.circle.getCenter().lng()
        )
      )
      setInfoWindowOpen(true)
    }
  }

  return (
    <>
      <Circle
        ref={circleRef}
        radius={unitSystem === 'imperial' ? feetToMeters(radius) : radius}
        center={
          new google.maps.LatLng(geofence.location[0], geofence.location[1])
        }
        onClick={(e) => onCircleClick(e, geofence)}
        onDragStart={() => setInfoWindowOpen(false)}
        onDragEnd={onCircleDragEnd}
        options={{
          ...CIRCLE_OPTIONS,
          editable: geofence.isEditable,
          draggable: geofence.isDraggable,
          fillColor: geofence.color,
          strokeColor: geofence.color
        }}
      />
      {showInfoWindow && (
        <CircleInfoWindow
          saveGeofence={saveGeofence}
          geofence={geofence}
          isGeofenceSaving={isGeofenceSaving}
          minRadius={minRadius}
          maxRadius={maxRadius}
          unitSystem={unitSystem}
          name={name}
          radius={radius}
          removeGeofence={removeGeofence}
          setIsRadiusSliderDragged={setIsRadiusSliderDragged}
          setName={setName}
          setRadius={setRadius}
        />
      )}
    </>
  )
}

export default withStore(CircleGeofence)
