import { createContext, useContext, useRef } from 'react'
import GeofencesStore from './Store/GeofencesStore'
import { useStore } from '~/dataStore'

const GeofencesContext = createContext<GeofencesStore | undefined>(undefined)

export function GeofencesProvider({
  children
}: {
  children: React.ReactNode
}): React.ReactElement {
  const rootStore = useStore()
  const store = useRef(new GeofencesStore(rootStore))

  return (
    <GeofencesContext.Provider value={store.current}>
      {children}
    </GeofencesContext.Provider>
  )
}

const useGeofencesStore = (): GeofencesStore => {
  const context = useContext(GeofencesContext)
  if (!context) {
    throw new Error('There is no Provider for this context')
  }

  return context
}

export default useGeofencesStore
