import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useExportUsers } from '../useUsersList'

const ExportUsersBtn = ({
  exportUrl
}: {
  exportUrl: string
}): React.ReactElement => {
  const { mutate: exportUsers } = useExportUsers()

  return (
    <>
      <div
        id="export-users"
        className="flex-shrink-0"
        aria-label="Export Users Button Container">
        <Button
          onClick={() => exportUsers({ url: exportUrl })}
          color="body"
          className={cn('btn--hover btn--wide bg-white ', {
            'pe-none': !exportUrl
          })}
          size="lg"
          disabled={!exportUrl}
          outline
          aria-label="Export Users Button">
          <FontAwesomeIcon
            fixedWidth
            size="lg"
            icon={faFileDownload}
            className={cn('me-1', {
              'text-gray ': !exportUrl
            })}
            aria-label="Export Users Icon"
          />
          Export Users
        </Button>
      </div>
      {!exportUrl && (
        <UncontrolledTooltip
          placement="top"
          target="export-users"
          aria-label="Export Users Tooltip">
          Select a segment first
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default ExportUsersBtn
