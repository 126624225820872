import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStore, withStore } from '~/dataStore'
import CampaignReview from '~/pages/Campaign/CampaignReview'

function CampaignDetails(): React.ReactElement | null {
  const { campaign, resetCampaign } = useStore()
  const [isLoading, setIsLoading] = useState(false)

  const { appId, campaignId } = useParams<{
    appId: string
    campaignId: string
  }>()

  const initExistingCampaign = async () => {
    setIsLoading(true)
    await campaign.initExistingCampaign(appId, campaignId)
    setIsLoading(false)
  }

  useEffect(() => {
    campaign.mode = 'details'
    initExistingCampaign()
    campaign.fetchCampaignPreview(appId, campaignId)

    return () => {
      resetCampaign()
    }
  }, [campaignId, appId])

  return <CampaignReview {...campaign.reviewData} loading={isLoading} />
}

export default withStore(CampaignDetails)
