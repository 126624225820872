import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import camelCase from 'lodash/camelCase'

const ColHeader = ({
  fieldName,
  displayName,
  sortable,
  sortColumn,
  sortOrder,
  onSort,
  className,
  sufix,
  classes
}) => {
  const shouldSort = sortColumn === fieldName
  const isSortingUp = shouldSort && sortOrder === 'asc'
  const isSortingDown = shouldSort && sortOrder === 'desc'

  return (
    <th
      onClick={sortable ? () => onSort(fieldName) : null}
      scope="col"
      data-testid={`${camelCase(displayName)}ColHeader`}
      className={cn(
        'border-top-0 px-1 table__col table__col--header',
        { 'sort-container': sortable },
        classes
      )}>
      <div
        className={cn(
          className,
          'd-inline-flex align-items-center user-select-none',
          { 'position-relative pe-3': sortable }
        )}
        role={sortable ? 'button' : ''}>
        <span className="fw-medium">{displayName}</span>
        {sufix && sufix()}
        {sortable && (
          <div
            className={cn(
              'd-inline-flex flex-column justify-content-center position-absolute end-0'
            )}>
            <FontAwesomeIcon
              className={cn('sort-container__icon', {
                'sort-container__icon--active': isSortingUp
              })}
              icon={faSortUp}
            />
            <FontAwesomeIcon
              className={cn('sort-container__icon', {
                'sort-container__icon--active': isSortingDown
              })}
              icon={faSortDown}
            />
          </div>
        )}
      </div>
    </th>
  )
}

ColHeader.propTypes = {
  fieldName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  sufix: PropTypes.func,
  sortable: PropTypes.bool,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onSort: PropTypes.func,
  className: PropTypes.func
}

ColHeader.defaultProps = { onSort: () => {} }

export default ColHeader
