import React from 'react'
import ConfirmationModal from '~/components/modals/ConfirmationModal'
import Modal from '~/components/modals/Modal/Modal'
import { ID } from '~/common.interface'

const DeleteEmailAddressModal = ({
  removeEmailAddress
}: {
  removeEmailAddress: (id: ID, closeModal: () => void) => void
}) => {
  return (
    <Modal
      id="confirmEmailAddressDeletion"
      className=""
      renderHeader={() => 'Delete Email Address'}
      render={(close: () => void, params) => (
        <ConfirmationModal
          onSubmit={() => {
            removeEmailAddress(params?.id, close)
          }}
          onClose={close}
          okText="Delete"
          cancelText="Cancel"
          body="Are you sure you want to delete this email address?"
        />
      )}
    />
  )
}

export default DeleteEmailAddressModal
