import React from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import cn from 'classnames'
import moment from 'moment'

import { datepickerCalendar, datepickerInput } from './style.scss'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_CALENDAR = 'MMMM'
export const DISPLAY_DATE_FORMAT = 'dd MMM yy'

interface IProps extends Omit<ReactDatePickerProps, 'value'> {
  displayDateFormat?: string
  value: Date | null | undefined
}

const Datepicker = ({
  displayDateFormat,
  minDate = moment().toDate(),
  maxDate,
  value,
  showMonthYearDropdown = true,
  ...rest
}: IProps): React.ReactElement => {
  return (
    <DatePicker
      className={cn(datepickerInput, `form-control`)}
      selected={value || new Date()}
      calendarClassName={datepickerCalendar}
      dateFormat={displayDateFormat || DISPLAY_DATE_FORMAT}
      minDate={minDate || moment().toDate()}
      maxDate={maxDate || moment().add(12, 'month').toDate()}
      showMonthYearDropdown={showMonthYearDropdown}
      {...rest}
    />
  )
}

export default Datepicker
