import { Dictionary, mapValues } from 'lodash'
import { action, makeObservable, runInAction } from 'mobx'

import { fetchBeacons } from '~/api/beacons'
import { getGroups } from '~/api/groups'
import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'
import { Trigger } from '../EmailBuilder.interface'
import Beacon from './Beacon.model'
import Target from './Target'

export default class Beacons extends Target<Beacon> {
  targetName = TARGETS.BEACONS

  constructor() {
    super()

    makeObservable(this, {
      fetchBeacons: action.bound,
      fillBeacons: action.bound,
      selectBeacon: action.bound,
      fetchGroups: action.bound,
      setBatchTriger: action.bound
    })
  }

  async fetchBeacons(appId: string, reset = false): Promise<void> {
    const currentBeacons = reset ? [] : this.collection
    this.page = reset ? 1 : this.page + 1

    const {
      data: beacons,
      metadata: { totalPages, dataCount }
    } = await fetchBeacons(appId, {
      page: this.page,
      searchQuery: this.searchQuery,
      groupIds: this.selectedGroups
    })

    runInAction(() => {
      this.hasMore = this.page < totalPages

      this.collection = currentBeacons.concat(
        beacons.map(
          (beacon) =>
            this.selected.get(beacon.id) ||
            new Beacon(beacon, this.selectBeacon)
        )
      )

      if (!this.searchQuery && !this.selectedGroups.length) {
        this.totalCount = dataCount
      }
    })
  }

  fillBeacons(beaconEvents: Dictionary<Trigger | null | undefined>): boolean {
    if (!beaconEvents) {
      return false
    }

    Object.keys(beaconEvents).forEach((selectedBeacon) => {
      const id = selectedBeacon
      const beacon = this.collection.find((b) => id === b.id)
      if (beacon) {
        beacon.trigger = beaconEvents[id]
        this.selected.set(beacon.id, beacon)
        this.isActive = true
      }
    })

    return this.isActive
  }

  selectBeacon(
    beacon: Beacon,
    isSelected: boolean,
    trigger: Trigger = Trigger.ENTER
  ): void {
    if (!isSelected) {
      beacon.setTrigger(null)
      this.selected.delete(beacon.id)
    } else {
      beacon.setTrigger(trigger)
      this.selected.set(beacon.id, beacon)
    }
  }

  async fetchGroups(appId: string): Promise<void> {
    const groups = await getGroups(appId, 'beacons')
    runInAction(() => {
      this.groups = groups.data.map((g) => ({ ...g, value: g.id }))
    })
  }

  public setBatchTriger(trigger: Trigger): void {
    this.selectedList.forEach((beacon) => beacon.setTrigger(trigger))
  }

  public getBeaconEvents(): Dictionary<Trigger | null | undefined> {
    return this.isActive
      ? mapValues(Object.fromEntries(this.selected), 'trigger')
      : {}
  }
}
