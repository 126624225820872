import { useEffect } from 'react'
import { Row, Col } from 'reactstrap'

import { useParams } from 'react-router-dom'
import { withStore, useStore } from '~/dataStore'
import EmailTemplateBuilder from '~/pages/Campaign/Notification/EmailNotification/UI/EmaiTemplateBuilder'

const Step3 = () => {
  const {
    campaign: {
      isSended,
      shared: { mergeTags },
      email: { setEditorData },
      templates
    }
  } = useStore()

  const { appId }: { appId: string } = useParams()

  useEffect(() => {
    templates.fetchFromNames(appId)

    return () => {
      templates.setIsChanged(false)
    }
  }, [appId])

  return (
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <EmailTemplateBuilder
          templates={templates}
          mergeTags={mergeTags}
          setEditorData={setEditorData}
          templatesSelectionDisabled={isSended}
        />
      </Col>
    </Row>
  )
}

export default withStore(Step3)
