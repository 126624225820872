import { action, observable, computed, makeObservable } from 'mobx'

import { BLANK_TEMPLATE_ID } from '~/pages/CampaignBuilder/Email/consts'
import { getFromNames, addFromName } from '~/api/appSettings'
import {
  getEmailTemplates,
  getLayouts,
  deleteEmailTemplate
} from '~/api/emailTemplates'
import { createSelectOption } from '~/utils/createSelectOptions'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import {
  CampaignType,
  ICampaignModel,
  IEmailPayload
} from '~/dataStore/Campaign/Campaign.interface'
import StepStore from '~/dataStore/emailBuilder/StepStore'
import {
  IEmailTemplate,
  IOption,
  IRegisteredField,
  ValidationTypes
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { fetchDefaultNotificationTemplates } from '../../Notification.connector'
import { IEmailDTO } from '../../Notification.interface'

export default class EmailTemplates extends StepStore {
  currentTemplate = new NewRegisteredField<IEmailTemplate | null>(null)

  public originTemplateId: string | undefined = undefined

  isChanged: boolean | null = null

  subject = new NewRegisteredField<string>('')

  fromEmailAddress = new NewRegisteredField<IOption>(
    {
      value: '',
      label: ''
    },
    false
  )

  templates: IEmailTemplate[] = []

  layouts: IEmailTemplate[] = []

  fromName = new NewRegisteredField<IOption>(
    {
      value: '',
      label: ''
    },
    false
  )

  fromNames = []

  constructor(private campaign?: ICampaignModel) {
    super()

    makeObservable<EmailTemplates, 'setTemplateFromOrigin'>(this, {
      currentTemplate: observable,
      isChanged: observable,
      subject: observable,
      fromEmailAddress: observable,
      templates: observable,
      layouts: observable,
      fromName: observable,
      fromNames: observable,
      isCurrentTemplateBlank: computed,
      setIsChanged: action.bound,
      setCurrentTemplate: action.bound,
      setEmailSubject: action.bound,
      fetchEmailTemplates: action.bound,
      setTemplateFromOrigin: action,
      deleteEmailTemplate: action.bound,
      setFromName: action.bound,
      fetchFromNames: action.bound,
      addFromName: action.bound,
      fetchLayouts: action.bound,
      setFromEmailAddress: action.bound,
      validateEmailSubject: action.bound,
      validateFromName: action.bound,
      validateFromEmailAddress: action.bound,
      validateTemplate: action.bound,
      registeredFields: computed
    })

    this.getCurrentTemplateType = this.getCurrentTemplateType.bind(this)
  }

  get isCurrentTemplateBlank(): boolean {
    return this.currentTemplate.value?.id === BLANK_TEMPLATE_ID
  }

  setIsChanged(value: boolean): void {
    this.isChanged = value
  }

  setCurrentTemplate(template: IEmailTemplate): void {
    if (this.currentTemplate.value?.id === template.id) {
      this.currentTemplate.value.name = template.name
      this.currentTemplate.value.isDefault = template.isDefault
    } else {
      this.currentTemplate.value = template
    }
    this.setIsChanged(true)
  }

  setEmailSubject(value: string): void {
    this.subject.value = value
  }

  async fetchEmailTemplates(appId: string): Promise<void> {
    const { data } = await getEmailTemplates(appId)
    this.templates = data

    this.setTemplateFromOrigin(this.templates)
  }

  private setTemplateFromOrigin(collection: IEmailTemplate[]) {
    if (this.originTemplateId) {
      const origin = collection.find(
        (template) => template.id === this.originTemplateId
      )
      if (origin) {
        this.setCurrentTemplate(origin)
        this.originTemplateId = undefined
        this.setIsChanged(false)
      }
    }
  }

  async deleteEmailTemplate(appId: string, templateId: string): Promise<void> {
    await deleteEmailTemplate(appId, templateId)
    if (templateId === this.currentTemplate.value?.id) {
      this.currentTemplate.value = {
        id: BLANK_TEMPLATE_ID
      }
    }
    await this.fetchEmailTemplates(appId)
  }

  setFromName(value: IOption): void {
    this.fromName.value = value
  }

  async fetchFromNames(appId: string): Promise<void> {
    const data = await getFromNames(appId)
    this.fromNames = data
  }

  async addFromName(appId: string, name: string): Promise<void> {
    const fromName = await addFromName(appId, name)
    this.fromName.value = {
      value: fromName.emailFromName.name,
      label: fromName.emailFromName.name
    }
    await this.fetchFromNames(appId)
  }

  async fetchLayouts(): Promise<void> {
    //! workaround for journeys to fetch from new notification endpoints
    let response: { data: (IEmailTemplate | Required<IEmailPayload>)[] } = {
      data: []
    }
    if (!this.campaign) {
      const defaultTemplateResponse = await fetchDefaultNotificationTemplates<
        Required<IEmailDTO>
      >(CampaignType.EMAIL)

      response.data = defaultTemplateResponse.data.map((template) => ({
        ...template,
        ...template.frontParts.emailBody
      }))
    } else {
      response = await getLayouts()
    }

    this.layouts = response.data.map((template) => ({
      ...template,
      isDefault: true
    }))
    this.setTemplateFromOrigin(this.layouts)
  }

  public getCurrentTemplateType(): 'recent' | 'all' {
    if (this.currentTemplate.value?.id) {
      const currentTemplateId = this.currentTemplate.value.id

      if (this.layouts.some((template) => template.id === currentTemplateId)) {
        return 'all'
      }
    }

    return 'recent'
  }

  setFromEmailAddress(addr: IOption): void {
    this.fromEmailAddress.value = addr
  }

  public validateEmailSubject(): void {
    this.subject.validate('Enter email subject')
  }

  public validateFromName(): void {
    this.fromName.validate('Enter from name')
  }

  public validateFromEmailAddress(): void {
    this.fromEmailAddress.validate('Select from email address')
  }

  public validateTemplate(): void {
    this.currentTemplate.resetError()
    if (!this.currentTemplate.value && !this.originTemplateId) {
      this.currentTemplate.isValid = false
      this.currentTemplate.errors.push({
        message: 'Select template or layout',
        type: ValidationTypes.REQUIRED
      })
    }
  }

  public validateStep(): void {
    this.validateEmailSubject()
    this.validateFromEmailAddress()
    this.validateFromName()
    this.validateTemplate()

    this.beenValid = true
  }

  public get registeredFields(): IRegisteredField[] {
    return [
      this.subject,
      this.fromEmailAddress,
      this.fromName,
      this.currentTemplate
    ]
  }

  public fillStore({
    emailNotification
  }: {
    emailNotification?: IEmailPayload | IEmailDTO
  }): void {
    if (!emailNotification) {
      return
    }

    const notification =
      'frontParts' in emailNotification
        ? emailNotification.frontParts.emailBody
        : emailNotification

    this.subject.fillField({ value: notification.subject })
    this.fromEmailAddress.fillField({
      value: createSelectOption(notification.source)
    })
    this.fromName.fillField({
      value: {
        value: notification.fromName || '',
        label: notification.fromName || ''
      }
    })

    this.originTemplateId = emailNotification.template
      ? emailNotification.id
      : notification.originTemplateId
    if (this.originTemplateId) {
      this.currentTemplate.beenValid = true
      this.currentTemplate.value = { id: this.originTemplateId }
    }
  }

  public getPayload(): Pick<
    IEmailPayload,
    'fromName' | 'source' | 'subject' | 'originTemplateId'
  > {
    return {
      fromName: this.fromName.value.label,
      source: this.fromEmailAddress.value.label,
      subject: this.subject.value,
      originTemplateId: this.currentTemplate?.value?.id || this.originTemplateId
    }
  }
}
