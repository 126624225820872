const IconRedirect = ({
  className,
  fill
}: {
  className?: string
  fill: string | undefined
}): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 7V6V5H13C15.1217 5 17.1566 5.84285 18.6569 7.34315C20.1571 8.84344 21 10.8783 21 13C21 15.1217 20.1571 17.1566 18.6569 18.6569C17.1566 20.1571 15.1217 21 13 21H4V19H13C14.5913 19 16.1174 18.3679 17.2426 17.2426C18.3679 16.1174 19 14.5913 19 13C19 11.4087 18.3679 9.88258 17.2426 8.75736C16.1174 7.63214 14.5913 7 13 7H8Z"
        fill={fill}
        stroke={fill}
      />
      <path d="M3 6.125L7.5 1.625L7.5 10.625L3 6.125Z" fill={fill} />
    </svg>
  )
}

export default IconRedirect
