import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import sectionLogo from './section-logo.svg'
import { fetchSDKSettings } from '~/api/appSettings'

const SDKSection = (): React.ReactElement => {
  const { appId } = useParams<{ appId: string }>()
  const { data } = useQuery(['sdkSettings'], () => fetchSDKSettings(appId))

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className="mb-0 mt-2">
          SDK Connect
        </CardTitle>
      </CardHeader>
      <CardBody className="pb-5 pt-3">
        <Row>
          <Col xs={8}>
            <p className="fw-medium mb-1">SDK App ID</p>
            <p className="text-muted">{data?.appId}</p>

            <p className="fw-medium mb-1 mt-4 pt-1">SDK App Key</p>
            <p className="text-muted">{data?.appKey}</p>

            <p className="fw-medium mb-1 mt-4 pt-1">SDK Access Token</p>
            <p className="text-muted">{data?.accessToken}</p>
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center justify-content-center">
            <img src={sectionLogo} width={126} alt="SDK Connect" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default SDKSection
