import React, { useEffect, useState } from 'react'
import { Progress as ProgressBar } from 'reactstrap'
import cn from 'classnames'
import Tooltip from '~/components/reports/Tooltip'

const Progress = ({
  rateLabel,
  rateTooltip,
  rate
}: {
  rateLabel: string | undefined
  rate: string | number | undefined
  rateTooltip: string | undefined
}): React.ReactElement => {
  const [currentRate, setRate] = useState<string | number | undefined>('0')

  useEffect(() => {
    setRate(rate)
  }, [rate])

  return (
    <div className="mt-2">
      {rateLabel && (
        <div className="d-flex">
          <h3 className="summary-field__label ms-2">{rateLabel}</h3>
          {rateTooltip && <Tooltip tooltip={rateTooltip} size="1x" />}
        </div>
      )}
      <ProgressBar
        value={currentRate}
        max={100}
        min={0}
        className="reports__progress-box"
        barClassName="reports__progress-bar text-end">
        <span
          className={cn('reports__progress-bar-value', {
            'reports__progress-bar-value--sticky': Number(rate) < 25
          })}>
          {rate}%
        </span>
      </ProgressBar>
    </div>
  )
}

export default Progress
