import cn from 'classnames'
import { GoalType } from '../NotificationGoals.interface'
import style from './Goals.scss'

interface IProps {
  type: GoalType
  size?: 'md' | 'lg' | 'xl'
  className?: string
}

export default function GoalIcon({
  type,
  size = 'md',
  className
}: IProps): React.ReactElement {
  return (
    <span
      className={cn(
        style.goalIcon,
        className,
        { [style.goalIconPrimary]: type === 'primary' },
        { [style.goalIconSecondary]: type === 'secondary' },
        { [style.goalIconExternal]: type === 'external' },
        { [style.goalIconXl]: size === 'xl' },
        { [style.goalIconLg]: size === 'lg' }
      )}
    />
  )
}
