import { get } from '~/api/v2'
import {
  IEngagementSummary,
  IPerformanceTopCampaign,
  IPerformanceTopNotifications
} from '~/pages/OpportunitiesDashboard/OpportunitiesDashboard.interface'

export function fetchEngagementSummary(
  appId: string,
  queryParams: { range: number | undefined }
): Promise<IEngagementSummary> {
  return get(`${appId}/dashboard_statistics/engagement`, queryParams)
}

export function fetchTopPerformance(
  appId: string
): Promise<IPerformanceTopCampaign[]> {
  return get(`${appId}/dashboard_statistics/performance`)
}

export function fetchTopNotifications(
  appId: string
): Promise<IPerformanceTopNotifications[]> {
  return get(`${appId}/dashboard_statistics/notification_types`)
}

export function exportAppOpps(appId: string, range: number) {
  return get(`${appId}/dashboard_statistics/export_opportunities`, { range })
}
