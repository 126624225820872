import { ReactElement, useEffect } from 'react'
import { useStore, withStore } from '~/dataStore'
import {
  ActionTypes,
  CampaignType
} from '~/dataStore/Campaign/Campaign.interface'
import CardNotification from '../Model/CardNotification'
import CardBackStep from './CardBackStep'
import CardFrontStep from './CardFrontStep'

interface IProps {
  type: CampaignType.CARD_FRONT | CampaignType.CARD_BACK
  card: CardNotification
  isSended: boolean
  toggleCardBackStore: (destinationType: ActionTypes | undefined) => void
  onlyCarousel?: boolean
  withEvent?: boolean
  withGoals?: boolean
  colsWidth?: {
    left: { xxl: { size: number } }
    right: { xxl: { size: number } }
  }
  adminHeaderEditable?: boolean
}

function CardNotificationBuilder({
  type,
  card,
  isSended,
  toggleCardBackStore,
  onlyCarousel,
  colsWidth,
  withEvent,
  withGoals,
  adminHeaderEditable = true
}: IProps): ReactElement {
  const {
    app: { currentApp, currentAdmin }
  } = useStore()

  useEffect(() => {
    card.shared
      .getAdmins(currentApp.id)
      .then((response) => {
        card.setAdmin(
          response.find((admin) => admin.id === currentAdmin.id) || response[0]
        )
      })
      .catch((error) => console.error(error))

    card.shared
      .fetchMergeTags(currentApp.id)
      .catch((error) => console.error(error))

    card.shared
      .fetchDeeplinks(currentApp.id)
      .catch((error) => console.error(error))

    card.shared
      .fetchEvents(currentApp.id)
      .catch((error) => console.error(error))
  }, [currentApp.id, card, currentAdmin])

  switch (type) {
    case CampaignType.CARD_FRONT:
      return (
        <CardFrontStep
          card={card}
          isSended={isSended}
          toggleCardBackStore={toggleCardBackStore}
          onlyCarousel={onlyCarousel}
          colsWidth={colsWidth}
          withEvent={withEvent}
          withGoals={withGoals}
          adminHeaderEditable={adminHeaderEditable}
        />
      )
    case CampaignType.CARD_BACK:
      return (
        <CardBackStep
          card={card}
          isSended={isSended}
          colsWidth={colsWidth}
          onlyCarousel={onlyCarousel}
          withEvent={withEvent}
          withGoals={withGoals}
        />
      )
    default:
      throw new Error('Unsupported card campaign type')
  }
}

export default withStore(CardNotificationBuilder)
