import React from 'react'
import { useNodes, EdgeProps, SmoothStepEdge } from 'reactflow'
import {
  getSmartEdge,
  svgDrawSmoothLinePath,
  pathfindingJumpPointNoDiagonal
} from '@tisoap/react-flow-smart-edge'

export default function RedirectEdge(props: EdgeProps): React.ReactElement {
  const {
    data,
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    markerStart,
    markerEnd
  } = props

  const nodes = useNodes()

  const getSmartEdgeResponse = getSmartEdge({
    targetPosition,
    sourcePosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
    options: {
      nodePadding: 50,
      drawEdge: svgDrawSmoothLinePath,
      generatePath: pathfindingJumpPointNoDiagonal
    }
  })

  // If the value returned is null, it means "getSmartEdge" was unable to find
  // a valid path, and you should do something else instead
  if (getSmartEdgeResponse === null) {
    return <SmoothStepEdge {...props} />
  }

  const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse

  return (
    <>
      <path
        style={style}
        className="custom-edge react-flow__edge-path"
        d={svgPathString}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      {data?.label && (
        <g
          className="react-flow__edge-textwrapper"
          transform={`translate(${edgeCenterX - 10}, ${edgeCenterY})`}>
          <text className="react-flow__edge-text" dy="-0.3em">
            {data.label}
          </text>
        </g>
      )}
    </>
  )
}
