import { PropsWithChildren, RefObject, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import { Button, Card } from 'reactstrap'
import styles from './hintWindow.scss'
import { useStore, withStore } from '~/dataStore'
import Link from '~/components/Link'

interface IProps {
  link?: string
  mediaFile: string
  mediaFileHeight?: number
  offsetTop?: number
  offsetLeft?: number
  elem: RefObject<{
    getBoundingClientRect: () => { x: number; y: number }
  }>
}

function HintModal({
  children,
  link,
  mediaFile,
  mediaFileHeight = 220,
  elem,
  offsetTop = 0,
  offsetLeft = 0
}: PropsWithChildren<IProps>): React.ReactElement | null {
  const { ui } = useStore()

  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    ui.addHint()

    return () => {
      ui.removeHint()
    }
  }, [])

  const hintWindow = document.getElementById('hintWindow')

  useEffect(() => {
    function handleScroll() {
      if (elem.current) {
        setPosition(elem.current.getBoundingClientRect())
      }
    }

    if (ui.getHintWindowActive()) {
      handleScroll()
      document.addEventListener('scroll', handleScroll)
    }

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [elem.current, ui.getHintWindowActive()])

  if (!hintWindow) {
    return null
  }

  return (
    <>
      {createPortal(
        <Card
          className={cn(styles.hintWindowSingle)}
          style={{
            transform: `translate(${position.x - offsetLeft}px, ${
              position.y - offsetTop
            }px)`
          }}>
          <img src={mediaFile} alt="" width="100%" height={mediaFileHeight} />
          <div className={cn(styles.hintWindowContent)}>
            {children}
            <div className="d-flex justify-content-end">
              <Button
                color=""
                className="color-white"
                onClick={() => {
                  ui.setHintWindowActive(false)
                }}>
                Hide
              </Button>
              {link && (
                <Button
                  tag={Link}
                  color=""
                  target="_blank"
                  rel="noreferrer"
                  className={cn(styles.hintWindowActionBtn, 'color-white')}
                  route={link}>
                  Learn More
                </Button>
              )}
            </div>
          </div>
        </Card>,
        hintWindow
      )}
    </>
  )
}

export default withStore(HintModal)
