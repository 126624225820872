import cn from 'classnames'
import { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import styles from './hintWindow.scss'
import { useStore, withStore } from '~/dataStore'

function HintWindow(): React.ReactElement {
  const { ui } = useStore()

  const [activeIndex, setActiveIndex] = useState<number>(1)

  useEffect(() => {
    if (!ui.getHintWindowActive()) {
      setActiveIndex(1)
    }
  }, [ui.getHintWindowActive()])

  return (
    <div
      className={cn(
        styles.hintWindow,
        {
          [styles.hintWindowActive]: ui.getHintWindowActive()
        },
        {
          [styles[`hintWindowActive${activeIndex}`]]: ui.getHintWindowActive()
        }
      )}>
      <div id="hintWindow">
        {ui.hintsNumber > 1 && ui.hintsNumber > activeIndex && (
          <Button
            className="position-absolute"
            onClick={() => {
              setActiveIndex((prev) => prev + 1)
            }}>
            next
          </Button>
        )}
      </div>
    </div>
  )
}

export default withStore(HintWindow)
