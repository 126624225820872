import React, { useEffect, useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import { Button, FormGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfoWindow } from '@react-google-maps/api'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { getPolygonCenter } from '~/pages/Geofences/utils'
import Input from '~/components/forms/Input'
import { IGeofence } from '~/pages/Geofences/Geofences.interface'
import { withStore } from '~/dataStore'

interface IProps {
  saveGeofence: (geofence: IGeofence) => void
  removeGeofence: (geofence: IGeofence) => void
  geofence: IGeofence
  isGeofenceSaving: boolean
}

const PolygonInfoWindow = ({
  saveGeofence,
  removeGeofence,
  geofence,
  isGeofenceSaving
}: IProps) => {
  const [name, setName] = useState(geofence?.name || '')
  const debouncedOnNameChange = useCallback(
    debounce((nameValue) => {
      saveGeofence({ ...geofence, name: nameValue })
    }, 300),
    []
  )

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
    debouncedOnNameChange(e.target.value)
  }

  const polygonLocation = (): {
    lat: number | undefined
    lng: number | undefined
  } => {
    if (geofence.location) {
      return { lat: geofence.location[0], lng: geofence.location[1] }
    }
    return getPolygonCenter(geofence.path)
  }

  useEffect(() => {
    if (name !== geofence.name) setName(geofence.name)
  }, [geofence.name])

  return (
    <InfoWindow position={polygonLocation()}>
      <div className="info-window">
        <FormGroup
          className={`${
            geofence.error?.name?.length ? 'form-group--error' : ''
          }`}>
          <Input
            name="name"
            placeholder="Enter Geofence name"
            type="text"
            value={name}
            disabled={isGeofenceSaving}
            id="name"
            onChange={onNameChange}
            className="text-base mb-1"
          />
          {geofence.error?.name?.length && (
            <span className="error-message">{geofence.error?.name[0]}</span>
          )}
        </FormGroup>

        <Button
          className="btn--transparent mx-auto mt-4 d-block"
          color=""
          onClick={() => removeGeofence(geofence)}>
          <span className="color-body text-base font-medium">
            <FontAwesomeIcon icon={faTrash} className="me-2" />
            Delete
          </span>
        </Button>
      </div>
    </InfoWindow>
  )
}

export default withStore(PolygonInfoWindow)
