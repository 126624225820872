import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, ModalBody, ModalFooter, Label, FormGroup } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import cn from 'classnames'
import Modal from '~/components/modals/Modal/Modal'
import Input from '~/components/forms/Input'
import { fetchEvents } from '~/api/apps'
import CurrencySelect from '../../../CurrencySelect'
import {
  RevenueFormValues,
  IRevenueEvent
} from '../../../RevenueSection.interface'
import SelectWithSearch from '~/components/forms/SelectWithSearch'
import { createSelectOption } from '~/utils/createSelectOptions'
import {
  useEditRevenueEvent,
  useAddRevenueEvent
} from '../../../useRevenueEvents'

import styles from './EditAddRevenueEventModal.scss'

const EditAddRevenueEvent = ({
  revenueEvent,
  close
}: {
  revenueEvent?: IRevenueEvent
  close: () => void
}) => {
  const { appId } = useParams<{ appId: string }>()
  const { data: events } = useQuery(['events'], () => fetchEvents(appId))
  const {
    setError,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RevenueFormValues>({
    mode: 'onBlur',
    defaultValues: {
      eventName: revenueEvent?.eventName,
      currency: revenueEvent?.currency,
      amount: revenueEvent?.amount.toFixed(2)
    }
  })

  const { mutate: editRevenue, isLoading: isEditLoading } = useEditRevenueEvent(
    close,
    setError
  )
  const { mutate: addRevenue, isLoading: isAddLoading } = useAddRevenueEvent(
    close,
    setError
  )

  const onSubmitForm = async (data: RevenueFormValues) => {
    if (revenueEvent) {
      editRevenue({ id: revenueEvent.id, data })
    } else {
      addRevenue(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <ModalBody className="d-flex align-items-end">
        <div>
          <Label for="event-select" className="fw-medium">
            Event
          </Label>
          <Controller
            control={control}
            name="eventName"
            register={register}
            render={({ field: { onChange, value } }) => (
              <SelectWithSearch
                placeholder="Select event"
                selectPlaceholder="Search for an event"
                value={value}
                options={
                  events?.data.map((event: string) =>
                    createSelectOption(event)
                  ) || []
                }
                invalid={!!errors.eventName}
                errorTooltip={errors.eventName?.message}
                id="event-select"
                className={cn(styles.eventSelect)}
                onChange={(option) => onChange(option.value)}
              />
            )}
          />
        </div>
        <div className="mx-4">
          <Controller
            control={control}
            name="currency"
            register={register}
            render={({ field: { onChange, value } }) => (
              <CurrencySelect
                error={errors.currency?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <Input
          register={register}
          placeholder="Enter amount"
          onKeyPress={(e) => {
            if (e.key === ',') e.preventDefault()
          }}
          errorTooltip={errors.amount?.message}
          className={cn('mb-0', {
            'form-group--error': errors.amount
          })}
          label="Amount"
          name="amount"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={isAddLoading || isEditLoading}>
          Save
          {(isAddLoading || isEditLoading) && (
            <FontAwesomeIcon icon={faSpinner} spin size="xs" className="ms-2" />
          )}
        </Button>
      </ModalFooter>
    </form>
  )
}

const EditAddRevenueEventModalModal = () => {
  return (
    <Modal
      id="editAddRevenueEventModal"
      size="lg"
      contentClassName={styles.modalWrapper}
      renderHeader={(onClose, params) =>
        `${params?.revenueEvent ? 'Edit' : 'New'} Revenue Event`
      }
      render={(close: () => void, params) => (
        <EditAddRevenueEvent
          revenueEvent={params?.revenueEvent}
          close={close}
        />
      )}
    />
  )
}

export default EditAddRevenueEventModalModal
