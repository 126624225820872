import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import {
  ICampaignListItemDTO,
  ICampaignsListItem,
  ICampaignsListQueryParams
} from '~/pages/Campaign/CampaignsList/CampaignsList.interface'
import { fetchCampaignTemplates } from '~/api/campaigns'
import { useStore } from '~/dataStore'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'

export enum ServerStateKeysEnum {
  Campaigns = 'suggestedCampaigns'
}

export const useInvalidateCampaigns = () => {
  const cache = useQueryClient()
  return () => cache.invalidateQueries([ServerStateKeysEnum.Campaigns])
}

const useSuggestedCampaigns = (
  queryParams: Partial<Omit<ICampaignsListQueryParams, 'page' | 'per_page'>>
) => {
  const {
    app: {
      appDetails,
      currentApp: { id: appId }
    }
  } = useStore()

  return useInfiniteQuery<
    { data: ICampaignListItemDTO[]; bulkActions: any; metadata: any },
    unknown,
    ICampaignsListItem
  >(
    [
      ServerStateKeysEnum.Campaigns,
      {
        ...queryParams
      }
    ],
    ({ pageParam = 1 }) =>
      fetchCampaignTemplates(appId, {
        ...queryParams,
        page: pageParam
      }),
    {
      select: (data) => ({
        pages: data.pages
          .flatMap((page) => page.data)
          .map((campaign) => ({
            ...campaign,
            disabled:
              (campaign.typeList.includes(CampaignType.EMAIL) &&
                appDetails.featureFlags?.email === false) ||
              (campaign.typeList.includes(CampaignType.SMS) &&
                appDetails.featureFlags?.sms === false)
          })),
        pageParams: data.pageParams,
        metadata: data.pages[0].metadata,
        bulkActions: data.pages[0].bulkActions
      }),
      enabled: false,
      refetchOnMount: true,
      refetchInterval: 30000,
      staleTime: 3000,
      getNextPageParam: (lastPage, pages) =>
        lastPage.metadata?.totalPages > lastPage.metadata?.page
          ? lastPage.metadata?.page + 1
          : undefined
    }
  )
}

export default useSuggestedCampaigns
