import { PropsWithChildren } from 'react'
import { Col, Row } from 'reactstrap'
import cn from 'classnames'
import { isEmpty } from '~/utils/utilities'
import { IGoalPerformance } from '../../Store/JourneyReport.interface'
import styles from './GoalsPerformance.scss'

interface IProps {
  row: Pick<
    IGoalPerformance,
    'clicks' | 'conversions' | 'delivery' | 'impression' | 'name'
  >
  colClasses?: string
}

export default function GoalsPerformanceCols({
  row,
  colClasses,
  children
}: PropsWithChildren<IProps>): React.ReactElement {
  return (
    <>
      <Col xs="3" className={cn(colClasses, 'pe-0')}>
        <div className={cn(styles.tableStripedItem)}>
          {children || row.name || '- -'}
        </div>
      </Col>
      <Col xs="4" className={cn(styles.tableStripedItem)}>
        <Row>
          <Col xs="4">{row.delivery?.totalUniq ?? 0}</Col>
          <Col xs="4">{row.impression?.totalUniq ?? '- -'}</Col>
          <Col xs="4">{row.clicks?.totalUniq ?? 0}</Col>
        </Row>
      </Col>
      <Col xs="5" className={cn(styles.tableStripedItem)}>
        <Row>
          <Col xs="4">
            {!isEmpty(row.clicks?.totalUniqRate)
              ? `${row.clicks?.totalUniqRate}%`
              : '0%'}
          </Col>
          <Col xs="4" className="color-yellow" title="Primary Goal Conversion">
            <div className={cn(styles.tableConversionCol)}>
              <Row>
                <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                  {row.conversions.primary?.totalUniq ?? '- -'}
                </Col>
                <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                  {!isEmpty(row.conversions.primary?.totalUniqRate)
                    ? `${row.conversions.primary?.totalUniqRate}%`
                    : '- -'}
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            xs="4"
            className="color-silver-gray"
            title=" Secondary Goal Conversion">
            <div className={cn(styles.tableConversionCol)}>
              <Row>
                <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                  {row.conversions.secondary?.totalUniq ?? '- -'}
                </Col>
                <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                  {!isEmpty(row.conversions.secondary?.totalUniqRate)
                    ? `${row.conversions.secondary?.totalUniqRate}%`
                    : '- -'}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export function GoalsPerformanceActionCols({
  row,
  colClasses
}: {
  row: Pick<IGoalPerformance, 'conversions' | 'name'>
  colClasses?: string
}): React.ReactElement {
  return (
    <>
      <Col xs="3" className={cn(colClasses, 'pe-0')}>
        <div className={cn(styles.tableStripedItem)}>{row.name || '- -'}</div>
      </Col>
      <Col xs="4" className={cn(styles.tableStripedItem)}>
        <Row>
          <Col xs="4" />
          <Col xs="4" />
          <Col xs="4" />
        </Row>
      </Col>
      <Col xs="5" className={cn(styles.tableStripedItem)}>
        <Row>
          <Col xs="4" />
          <Col xs="4" className="color-yellow" title="Primary Goal Conversion">
            <div className={cn(styles.tableConversionCol)}>
              {row.conversions.primary && (
                <Row>
                  <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                    {row.conversions.primary?.totalUniq ?? '- -'}
                  </Col>
                  <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                    {!isEmpty(row.conversions.primary?.totalUniqRate)
                      ? `${row.conversions.primary?.totalUniqRate}%`
                      : '- -'}
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col
            xs="4"
            className="color-silver-gray"
            title=" Secondary Goal Conversion">
            <div className={cn(styles.tableConversionCol)}>
              {row.conversions.secondary && (
                <Row>
                  <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                    {row.conversions.secondary?.totalUniq ?? '- -'}
                  </Col>
                  <Col xs="6" hd="4" widths={['xs', 'hd']} className="no-wrap">
                    {!isEmpty(row.conversions.secondary?.totalUniqRate)
                      ? `${row.conversions.secondary?.totalUniqRate}%`
                      : '- -'}
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </>
  )
}
