import React from 'react'

const IconSegment = ({
  className,
  fill
}: {
  className?: string
  fill: string
}): React.ReactElement => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 25 21"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.3125 9.375C22.0361 9.375 23.4375 7.97363 23.4375 6.25C23.4375 4.52637 22.0361 3.125 20.3125 3.125C18.5889 3.125 17.1875 4.52637 17.1875 6.25C17.1875 7.97363 18.5889 9.375 20.3125 9.375ZM21.875 9.9375H18.75C17.8906 9.9375 17.1143 10.2842 16.5478 10.8457C18.5156 11.9248 19.9121 13.873 20.2148 16.1875H23.4375C24.3018 16.1875 25 15.4893 25 14.625V13.0625C25 11.3389 23.5986 9.9375 21.875 9.9375ZM9.375 10.9375C12.3975 10.9375 14.8437 8.49121 14.8437 5.46875C14.8437 2.44629 12.3975 0 9.375 0C6.35254 0 3.90625 2.44629 3.90625 5.46875C3.90625 8.49121 6.35254 10.9375 9.375 10.9375ZM13.125 11.5H12.7197C11.7041 11.9883 10.5762 12.2812 9.375 12.2812C8.17383 12.2812 7.05078 11.9883 6.03027 11.5H5.625C2.51953 11.5 0 14.0195 0 17.125V18.5312C0 19.8252 1.0498 20.875 2.34375 20.875H16.4062C17.7002 20.875 18.75 19.8252 18.75 18.5312V17.125C18.75 14.0195 16.2305 11.5 13.125 11.5Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconSegment
