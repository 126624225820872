import { Crop } from 'react-image-crop'

export function resizeImageToCroppedSize(
  croppedCanvas: HTMLCanvasElement,
  image: HTMLImageElement,
  crop: Crop
): string {
  const resizeCanvas = document.createElement('canvas')
  const aspectX = image.width / crop.width
  const aspectY = image.height / crop.height
  resizeCanvas.width = image.naturalWidth / aspectX
  resizeCanvas.height = image.naturalHeight / aspectY

  const resizeCtx = resizeCanvas.getContext('2d')

  resizeCtx?.drawImage(
    croppedCanvas,
    0,
    0,
    resizeCanvas.width,
    resizeCanvas.height
  )

  return resizeCanvas.toDataURL('image/jpeg')
}

export function getCroppedImg(image: HTMLImageElement, crop: Crop): string {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return ''
  }
  const pixelRatio = window.devicePixelRatio
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  canvas.width = crop.width * pixelRatio * scaleX
  canvas.height = crop.height * pixelRatio * scaleY
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  )

  return resizeImageToCroppedSize(canvas, image, crop)
}

export function getInitialCropArea(
  img: HTMLImageElement,
  minWidth: number,
  minHeight?: number,
  aspect?: number
): { crop: Crop; scaledMinWidth: number; scaledMinHeight?: number } {
  let scaledMinHeight = 0
  const scaleX = img.naturalWidth / img.width
  const scaledMinWidth = minWidth / scaleX
  const newCrop: Crop = {
    unit: 'px',
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }

  if (img.naturalWidth > minWidth) {
    const w = Math.max(img.width / 2, scaledMinWidth)
    newCrop.width = w
    newCrop.x = (img.width - w) / 2
  } else {
    newCrop.width = img.width
    newCrop.x = 0
  }

  if (minHeight) {
    const scaleY = img.naturalHeight / img.height
    scaledMinHeight = minHeight / scaleY

    if (img.naturalHeight > minHeight) {
      const h = Math.max(img.height / 2, scaledMinHeight)
      newCrop.height = h
      newCrop.y = (img.height - h) / 2
    } else {
      newCrop.height = img.height
      newCrop.y = 0
    }
  } else {
    newCrop.height = img.height / 2
    newCrop.y = img.height / 4
  }

  if (aspect) {
    newCrop.y = (img.height - newCrop.width / aspect) / 2
    newCrop.height = newCrop.width / aspect
  }

  return {
    crop: newCrop,
    scaledMinWidth,
    scaledMinHeight: minHeight ? scaledMinHeight : undefined
  }
}

export function getNewCropArea(
  crop: Crop,
  image: HTMLImageElement,
  minWidth: number,
  minHeight?: number
): Crop {
  const tempCrop = { ...crop }

  if (crop.width < minWidth) {
    const width = Math.min(minWidth, image.width)
    const x = width + crop.x > image.width ? image.width - width : crop.x

    tempCrop.width = width
    tempCrop.x = x
  }

  if (minHeight && crop.height < minHeight) {
    const height = Math.min(minHeight, image.height)
    const y = height + crop.y > image.height ? image.height - height : crop.y

    tempCrop.y = y
    tempCrop.height = height
  }

  return tempCrop
}
