import { uniqueId } from 'lodash'
import { useRef } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

interface IProps {
  fieldValue: { value: string; tooltip: string }
}

export default function SummaryFieldValue({
  fieldValue
}: IProps): React.ReactElement {
  const id = useRef(uniqueId('summaryValue'))
  const { value, tooltip } = fieldValue
  return (
    <>
      <span id={id.current}>{value}</span>
      <UncontrolledTooltip direction="up" target={id.current}>
        {tooltip}
      </UncontrolledTooltip>
    </>
  )
}
