import React from 'react'
import { observer } from 'mobx-react'

import { action, configure, observable, makeObservable } from 'mobx'
import { generatePath } from 'react-router-dom'
import Ui from './ui/ui'
import App from './App'
import Campaign from './Campaign/Campaign'
import { History, IStore, Routes } from './Store.interface'
import Groups from './Groups/Groups'
import { CampaignType } from './Campaign/Campaign.interface'
import routes from '~/routes'
import { paramsToStr } from '~/api/requests.service'
import Report from '~/pages/Campaign/CampaignReports/Model/Report'

configure({
  enforceActions: 'never'
})

export class RootStore implements IStore {
  ui: Ui

  app: App

  campaign: Campaign

  campaignReport: Report

  groups: Groups

  history: History | undefined

  constructor() {
    makeObservable(this, {
      campaign: observable,
      campaignReport: observable,
      groups: observable,
      history: observable,
      resetReport: action.bound,
      resetCampaign: action.bound
    })

    this.goTo = this.goTo.bind(this)
    this.goBack = this.goBack.bind(this)
    this.replaceTo = this.replaceTo.bind(this)

    this.ui = new Ui()
    this.app = new App()
    // TODO: divide stores into domain specific store
    this.campaign = new Campaign(this)
    this.campaignReport = new Report(this)
    this.groups = new Groups(this)
  }

  // eslint-disable-next-line class-methods-use-this
  private prepareHistoryState(
    route: keyof Routes | undefined,
    params: Record<string, string | number | boolean | undefined>,
    query: unknown,
    state?: unknown
  ): { pathname?: string; search?: string } {
    const historyState: {
      pathname?: string
      search?: string
      state?: unknown
    } = {
      search: paramsToStr(query)
    }

    if (route && routes) {
      historyState.pathname = generatePath(routes[route].path, params)
    }

    historyState.state = state

    return historyState
  }

  goTo(route?: keyof Routes, params = {}, query = {}, state = {}): void {
    this.history?.push(this.prepareHistoryState(route, params, query, state))
  }

  goBack(): void {
    this.history?.goBack()
  }

  replaceTo(route?: keyof Routes, params = {}, query = {}): void {
    this.history?.replace(this.prepareHistoryState(route, params, query))
  }

  public resetReport(): void {
    this.campaignReport = new Report()
  }

  resetCampaign(
    campaignType?: CampaignType,
    notificationType?: CampaignType
  ): Campaign {
    this.campaign.disposeReactions()
    this.campaign = new Campaign(this, campaignType, notificationType)
    return this.campaign
  }
}
export const store = new RootStore()

// For easier debugging, pretty print it in devtools
// JSON.stringify(window._____APP_STATE_____, null, 4)
// if (process.env.NODE_CUSTOM_ENV === 'development') {
//   window._____APP_STATE_____ = store
// }

export const storeContext = React.createContext(store)
export const useStore = (): RootStore => React.useContext(storeContext)
export const withStore = observer
