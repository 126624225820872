import React from 'react'
import cn from 'classnames'
import segmentIcon from '~/components/icons/segment.svg'
import beaconIcon from '~/components/icons/beacon.svg'
import geofencesIcon from '~/components/icons/geofence.svg'
import eventsIcon from '~/components/icons/event.svg'
import TooltipNoIcon from '~/components/TooltipNoIcon'
import SegmentDetailsBtnWithModal from '~/pages/Segment/SegmentDetailsBtnWIthModal'

import styles from './SummaryTarget.scss'

interface IProps {
  target: {
    segments: {
      details: { id: string; name: string }[]
      count: number
    }
    geofences: {
      details: string[]
      count: number
    }
    events?: {
      details: string[]
      count: number
    }
    beacons: {
      details: string[]
      count: number
    }
  }
}

const SummaryTarget = ({ target }: IProps) => {
  const { segments, geofences, events, beacons } = target

  return (
    <div>
      {segments.count > 0 && (
        <div className="d-flex">
          <div className={styles.iconContainer}>
            <TooltipNoIcon text="Segments">
              <img
                width={15}
                src={segmentIcon}
                alt="Segments"
                className={styles.icon}
              />
            </TooltipNoIcon>
          </div>
          <div>
            {segments.details.map((segment, index) => (
              <SegmentDetailsBtnWithModal
                segmentId={segment.id}
                withIcon={false}
                key={segment.id}>
                <span
                  className={cn(styles.segmentName, 'text-wrap text-break')}>
                  {segment.name}
                  {index + 1 !== segments.details.length ? ', ' : ''}
                </span>
              </SegmentDetailsBtnWithModal>
            ))}
          </div>
        </div>
      )}
      {beacons.count > 0 && (
        <div className="d-flex mt-3 me-2">
          <div className={styles.iconContainer}>
            <TooltipNoIcon text="Beacons">
              <img width={30} src={beaconIcon} alt="Beacons" />
            </TooltipNoIcon>
          </div>
          <p className="mb-0 truncate">
            <TooltipNoIcon text={beacons.details.join(', ')}>
              {beacons.details.join(', ')}
            </TooltipNoIcon>
          </p>
        </div>
      )}
      {geofences.count > 0 && (
        <div className="d-flex  mt-3">
          <div className={styles.iconContainer}>
            <TooltipNoIcon text="Geolocations">
              <img width={15} src={geofencesIcon} alt="Geolocations" />
            </TooltipNoIcon>
          </div>
          <p className="mb-0 truncate">
            <TooltipNoIcon text={geofences.details.join(', ')}>
              {geofences.details.join(', ')}
            </TooltipNoIcon>
          </p>
        </div>
      )}
      {events?.count > 0 && (
        <div className="d-flex mt-3">
          <div className={styles.iconContainer}>
            <TooltipNoIcon text="Events">
              <img
                width={15}
                src={eventsIcon}
                alt="Events"
                className={styles.icon}
              />
            </TooltipNoIcon>
          </div>
          <p className="mb-0 truncate">
            <TooltipNoIcon text={events.details.join(', ')}>
              {events.details.join(', ')}
            </TooltipNoIcon>
          </p>
        </div>
      )}
    </div>
  )
}

export default SummaryTarget
