import React from 'react'
import { Button } from 'reactstrap'
import Select from 'react-select/creatable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { withStore } from '~/dataStore'
import { IOption } from '~/dataStore/emailBuilder/EmailBuilder.interface'

type Options = ReadonlyArray<IOption>

interface IProps {
  handleCreate: (inputValue: any) => void
  options: Options
  placeholder: string
  isLoading: boolean
  onChange: (newValue: any, actionMeta: any) => void
  onBlur: () => void
  value: null | string
  isClearable?: boolean
}

export const STYLE_OVERRIDES = {
  dropdownIndicator: (provided, state) => {
    const opacity = !state.hasValue ? 0.5 : 1
    return { ...provided, opacity }
  }
}

const CreatableSelect = ({
  handleCreate,
  options,
  placeholder,
  isLoading,
  onChange,
  onBlur,
  value,
  isClearable = false
}: IProps) => {
  const findSelectOption = (value: string | null) => {
    if (!value) return null
    return options.find(
      (option) => option.value.toString() === value.toString()
    )
  }

  const createNewLabel = (inputValue: string) => (
    <div>
      <Button color="" className="btn-body px-3">
        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
        Add {inputValue} to list
      </Button>
    </div>
  )

  return (
    <Select
      classNamePrefix="custom-select"
      value={findSelectOption(value)}
      placeholder={placeholder}
      isClearable={isClearable}
      isDisabled={isLoading}
      styles={STYLE_OVERRIDES}
      isLoading={isLoading}
      onBlur={onBlur}
      createOptionPosition="first"
      formatCreateLabel={createNewLabel}
      components={{
        IndicatorSeparator: () => null
      }}
      onChange={onChange}
      onCreateOption={handleCreate}
      options={options}
      blurInputOnSelect
    />
  )
}

export default withStore(CreatableSelect)
