import React, { useState } from 'react'

const useInputCursorPosition = (inputRef: any): number | null => {
  const [cursorPosition, setCursorPosition] = useState<number | null>(0)

  if (!inputRef.current) return null
  inputRef.current.addEventListener(
    'change',
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setCursorPosition(e.target.selectionEnd)
  )

  inputRef.current.addEventListener(
    'blur',
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setCursorPosition(e.target.selectionEnd)
  )

  return cursorPosition
}

export default useInputCursorPosition
