import React from 'react'
import cn from 'classnames'

import style from './Checkbox.scss'

const CheckboxIcon = ({ className }: { className?: string }) => (
  <svg
    className={cn(style.checkbox, className)}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Checkbox icon"
    data-testid="checkbox-icon">
    <rect width="22" height="22" rx="3" fill="currentColor" />
    <rect fill="currentColor" x="0.5" y="0.5" width="21" height="21" rx="2.5" />
    <path
      d="M9.25348 15.5522L4.7847 11.0834C4.51623 10.8149 4.51623 10.3796 4.7847 10.1111L5.75695 9.13886C6.02543 8.87035 6.46075 8.87035 6.72922 9.13886L9.73959 12.1492L16.1874 5.70136C16.4559 5.43288 16.8912 5.43288 17.1597 5.70136L18.132 6.67363C18.4004 6.94211 18.4004 7.37741 18.132 7.64591L10.2257 15.5522C9.95721 15.8207 9.52188 15.8207 9.25348 15.5522Z"
      fill="white"
    />
  </svg>
)

export default CheckboxIcon
