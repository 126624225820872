import React from 'react'

import TimeFrameForm from '~/components/forms/TimeFrameForm'
import { useStore, withStore } from '~/dataStore'

const TimeAndFrameForm = () => {
  const {
    campaign: {
      isSended,
      deliver: {
        setExpiryTimeFrame,
        setExpiryTimeValue,
        expiryTimeValue,
        expiryTimeFrame
      }
    }
  } = useStore()

  return (
    <div className="d-flex align-items-center">
      <TimeFrameForm
        disabled={isSended}
        inputType="number"
        unitLabel={
          <span className="o-100">after a user gets this campaign</span>
        }
        initialTimeFrame={expiryTimeFrame.value || 'weeks'}
        initialTimeValue={expiryTimeValue.value ?? '1'}
        onTimeFrameChange={setExpiryTimeFrame}
        onTimeValueChange={setExpiryTimeValue}
        units={['hours', 'days', 'weeks', 'months', 'years']}
      />
    </div>
  )
}

export default withStore(TimeAndFrameForm)
